/**
 * Created by ashahab on 7/26/2017.
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import dummyImage from '../../../assets/images/dummyPerson.jpg';
import { s3Upload } from '../../activities/uploader/s3-uploader';
import $ from "jquery";
import apiClient from "../../../actions/api-client";
import ProgressIndicator from "../../progress-indicator/progress-indicator";
import { Button, Modal, Alert } from "react-bootstrap";
import SelectFieldGroup from "../../forms/select-field-group";
import setCurrentUser from '../../../actions/login-action';
import ClevertapReact from "clevertap-react";
import TextFieldGroup from "../../forms/text-field-group";
import Validator from 'validator';
import isEmpty from 'lodash/isEmpty';
import { changePassword } from "./change-password";
import { gapi, loadAuth2 } from 'gapi-script';
import {
    CognitoUserPool,
    CognitoUserAttribute
} from 'amazon-cognito-identity-js';
import config from '../../../config.js';
import "../student-profile/custom.css";
import classnames from "classnames";
import betaIcon from '../../../assets/images/beta-icon.png';
import googleIcon from '../../login/btn_google_light_normal_ios.svg';

class TeacherProfile extends Component {

    constructor(props) {
        super(props);
        this.showIndicator = this.showIndicator.bind(this);
        this.hideIndicator = this.hideIndicator.bind(this);
        this.enableInputs = this.enableInputs.bind(this);
        this.disableInputs = this.disableInputs.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleAlertDismiss = this.handleAlertDismiss.bind(this);
        this.getAllSubjects = this.getAllSubjects.bind(this);
        this.setSubjectsHtml = this.setSubjectsHtml.bind(this);
        this.deleteTags = this.deleteTags.bind(this);
        this.getAllDepartments = this.getAllDepartments.bind(this);
        this.cancelSubmit = this.cancelSubmit.bind(this);
        this.showDialog = this.showDialog.bind(this);
        this.getChangePasswordDialog = this.getChangePasswordDialog.bind(this);
        this.submitPassword = this.submitPassword.bind(this);
        this.submitAdminCode = this.submitAdminCode.bind(this);
        this.getAdminCodeDialog = this.getAdminCodeDialog.bind(this);
        this.getSchoolCodeDialog = this.getSchoolCodeDialog.bind(this);
        this.submitSchoolCode = this.submitSchoolCode.bind(this);
        this.allDeleteDialog = this.allDeleteDialog.bind(this);
        this.deleteAllClasses = this.deleteAllClasses.bind(this);
        this.onLanguageSelect = this.onLanguageSelect.bind(this);
        this.disabledUserMessage = this.disabledUserMessage.bind(this);
        this.linkLearnerPal = this.linkLearnerPal.bind(this);
        this.attachSignin = this.attachSignin.bind(this);
        this.linkAccount = this.linkAccount.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
        this.state = {
            userName: '', firstName: '', lastName: '', email: '', department: '', subjects: [], allDepartments: [], allSubjects: [], showAdminCodeDiaogBox: false, showSchoolCodeDiaogBox: false,
            school: '', showChangePasswordDialogBox: false, oldPassword: '', newPassword: '', confirmPassword: '', schoolCode: '', adminCode: '', errors: { schoolCode: '', adminCode: '', oldPassword: '', newPassword: '', confirmPassword: '', accountLinkingError: '' },
            alertText: "This is a sample alert", alertStyle: "danger", showAlert: false, languages: config.languages, isLanguage: 'English', langCode: 'en', multiLingualLicense: false,
            showGoogleLoginBox: false, showLearnerPalLoginBox: false, LpUsername: '', LpPassword: '',
        };

        this.editMode = false;

        this.state.isPrincipal = this.props.auth.user.isPrincipal;
    }



    componentDidMount() {
        const successCallback = this.onSuccess.bind(this);

        loadAuth2(gapi, config.googleClientId, config.googleClassRoomScopes).then((auth2) => {

            if (auth2.isSignedIn.get()) {
                console.log(auth2.currentUser.get());
            }

            this.attachSignin(document.getElementById('linkAccount'), auth2);
        }).catch((err) => {
            console.log(err);
        });

        let userObject = this.props.auth.user;
        this.setState(
            {
                userName: userObject.userName,
                profilePicture: userObject.profilePicture,
                firstName: userObject.firstName,
                lastName: userObject.lastName,
                email: userObject.email,
                department: userObject.department,
                subjects: userObject.subjects.sort((a, b) => {
                    a = a.toLowerCase().trim();
                    b = b.toLowerCase().trim();
                    // return a.localeCompare(b);
                    return (a < b) ? -1 : ((a > b) ? 1 : 0)
                }),
                school: userObject.schoolName,
                langCode: userObject.prefLanguage ? userObject.prefLanguage : 'en',
                isLanguage: userObject.prefLanguage ? this.state.languages.filter(l => l.id === userObject.prefLanguage)[0].name : "English",
                multiLingualLicense: userObject.multiLingualLicense,
                federatedIdentity: userObject.federatedIdentity ? true : false
            });
        if (userObject.federatedIdentity && userObject.linkedAccounts && userObject.linkedAccounts.learnerPal) {
            this.setState({ linkedAccount: userObject.linkedAccounts.learnerPal });
        } else if (userObject.linkedAccounts && userObject.linkedAccounts.gmail) {
            this.setState({ linkedAccount: userObject.linkedAccounts.gmail });
        }
        this.disabledUserMessage();
    }

    attachSignin(element, auth2) {
        auth2.attachClickHandler(element, {},
            (googleUser) => {
                let response = {};
                response.id_token = googleUser.getAuthResponse().id_token;
                this.onSuccess(response);
            }, (error) => {
                console.log(JSON.stringify(error))
            });
    }


    onSuccess(response) {


        const token = response['id_token'];
        console.log(response);
        this.showIndicator();
        // let auth2 = gapi.auth2.getAuthInstance();
        //         auth2.signOut().then(() => {
        //             console.log("Signing out the google user");
        //   })


        let pathTemplate = '/account/{linkAccount}';
        var method = 'POST';
        // var additionalParams = { 'accessToken': localStorage.getItem('access_token') };
        var additionalParams = {};
        var body = {
            "id_token": token
        };

        var params = {
            linkAccount: "gmail"
        }
        let that = this;
        apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, "7")
            .then(function (result) {

                var pathTemplate = '/user/{userName}';
                var method = 'GET';
                var additionalParams = {};
                var body = {};

                var params = {
                    userName: that.props.auth.user.userName,
                };

                apiClient.invokeApi(params, pathTemplate, method, additionalParams, body)
                    .then((result) => {
                        localStorage.setItem('userObj', JSON.stringify(result.data));
                        that.setState({ showAlert: true, alertText: "Account successfully Linked", alertStyle: "success" });
                        that.props.dispatch({
                            type: "SET_CURRENT_USER",
                            user: result.data
                        });
                        setTimeout(() => {
                            window.location.reload();
                        }, 300);
                    }).catch((err) => {
                        that.hideIndicator();
                        that.setState({ showAlert: true, alertText: "Account linked however something went wrong, please refresh the page", alertStyle: "success" });
                        console.log(err);
                    })

            })
            .catch(function (err) {
                that.hideIndicator();
                that.setState({ showAlert: true, alertText: "Something went wrong failed, Account linking failed", alertStyle: 'danger' });
            });



    }

    disabledUserMessage() {
        const user = this.props.auth.user.userName;
        const message = `The trial period or subscription has expired for user, ${user}. Please contact support@learnerpal.com`;
        if (this.props.auth.user.userStatus == 'Disable') {
            this.setState({
                showAlert: true,
                alertText: message,
                unsetHeight: true
            })
        }
    }


    googleButton() {
        return (
            <div className="gmailLink">
                <img className="google-login" src={googleIcon} /><button id="linkAccount">Login with Google</button>
            </div>
        )
    }

    showIndicator() {
        this.setState({
            ...this.state,
            loading: true
        });
    }

    hideIndicator() {
        this.setState({
            ...this.state,
            loading: false
        });
    }

    onLanguageSelect(e) {
        const code = e.target.value
        const lang = e.target.selectedOptions[0].text
        this.setState({ isLanguage: lang, langCode: code });
    }


    enableInputs() {
        $('.onEditHide,.defaultKeywords').hide();
        $('.showAble').show();
        $('#submitButton').removeClass('removeDisplay');
        $('#cancelButton').removeClass('removeDisplay');
        $('#editButton1').addClass('removeDisplay');
        $('#editButton2').addClass('removeDisplay');
        $('#editButton3').addClass('removeDisplay');
        $('#editButton4').addClass('removeDisplay');
        $('#editButton5').addClass('removeDisplay');
        $('#editButton6').addClass('removeDisplay');

        this.editMode = true;
        this.getAllSubjects();
        this.getAllDepartments();
    }
    cancelSubmit() {
        $('#submitButton').addClass('removeDisplay');
        $('#cancelButton').addClass('removeDisplay');
        $('#editButton1').removeClass('removeDisplay');
        $('#editButton2').removeClass('removeDisplay');
        $('#editButton3').removeClass('removeDisplay');
        $('#editButton4').removeClass('removeDisplay');
        $('#editButton5').removeClass('removeDisplay');
        $('#editButton6').removeClass('removeDisplay');
        this.disableInputs();
    }
    disableInputs() {
        $('.onEditHide,.defaultKeywords').show();
        $('.showAble').hide();
        this.editMode = false;
    }
    uploadFile = async (e) => {

        e.preventDefault();
        this.showIndicator();
        if (e.target.files[0] && e.target.files[0].size < 20971520 && (e.target.files[0].type === 'image/jpeg' || e.target.files[0].type === 'image/x-ms-bmp' || e.target.files[0].type !== 'image/png' || e.target.files[0].type !== 'image/gif')) {
            this.setState({ profilePicture: window.URL.createObjectURL(e.target.files[0]) });
            let fileName = e.target.files[0].name;
            try {
                const uploadedFilename = (e.target.files[0])
                    ? (await s3Upload(e.target.files[0], this.props.auth.user.userName)).Location
                    : null;
                let that = this;
                if (uploadedFilename) {
                    this.setState({ 'fileName': fileName, 'fileLink': uploadedFilename });
                    let body = {
                        userName: this.state.userName,
                        profilePicture: uploadedFilename
                    };
                    apiClient.invokeApi({}, '/user', 'PUT', {}, body, "2").then(function (result) {
                        ClevertapReact.event("User's profile updated", { 'userName': that.props.auth.user.userName });
                        apiClient.invokeApi({ userName: that.state.userName }, '/user/{userName}', 'GET', {}, {})
                            .then(function (userReturned) {
                                if (this.state.federatedIdentity) {
                                    userReturned.data.federatedIdentity = true;
                                }
                                localStorage.setItem('userObj', JSON.stringify(userReturned.data));
                                setCurrentUser(userReturned.data);
                            }).catch(function (result) {
                                //This is where you would put an error callback
                            });
                        that.setState({ showAlert: true, alertText: result.data, alertStyle: "success" });
                        that.disableInputs();
                        setTimeout(() => {
                            that.setState({ ...that.state, showAlert: false });
                        }, 2000);
                        that.hideIndicator();
                    }).catch(function (result) {
                        that.setState({ showAlert: true, alertText: result.response.data.errors[0].message, alertStyle: "danger" });
                        setTimeout(() => {
                            that.setState({ ...that.state, showAlert: false });
                        }, 2000);
                    });
                }
                else {
                    that.hideIndicator();
                    this.setState({ 'fileName': '', 'fileLink': '' });
                }
            }
            catch (err) {

                console.log(err);
                //    this.state.errors.fileName = err.message;
                setTimeout(() => {
                    this.setState({ ...this.state, showAlert: false });
                }, 5000);
            }
        }
        else {
            this.hideIndicator();
        }

    };

    getAllDepartments() {
        let that = this;
        apiClient.invokeApi({}, '/departments', 'GET', {}, {}).then(function (result) {
            let sortedDepartments = result.data;
            sortedDepartments.sort(function (a, b) {
                return a.id > b.id;
            });
            that.setState({ allDepartments: sortedDepartments });
        }).catch(function (result) {

        });
    }
    getAllSubjects() {
        let that = this;
        let sName = this.props.auth.user.schoolName ? this.props.auth.user.schoolName : 'trial';
        let params = {
            schoolName: sName,
            ownerName: this.props.auth.user.userName,
        };
        let pathTemplate = '/subjects/ownerName/{ownerName}/schoolName/{schoolName}';

        apiClient.invokeApi(params, pathTemplate, 'GET', {}, {}).then(function (result) {
            let sortedSubjects = result.data;
            sortedSubjects.sort(function (a, b) {
                return a.id.localeCompare(b.id);
            });

            that.setState({ allSubjects: sortedSubjects });
        }).catch(function (result) {

        });
    }
    onChange(event) {
        this.setState({ [event.target.id]: event.target.value });
    }
    handleAlertDismiss(e) {
        this.setState({ 'showAlert': false });
    }

    onSubmit() {
        this.showIndicator();
        let state = this.state;
        let body = {
            userName: state.userName,
            firstName: state.firstName,
            lastName: state.lastName,
            email: state.email,
            department: state.department,
            subjects: state.subjects,
            prefLanguage: state.langCode
        };
        let that = this;
        const { dispatch } = this.props;
        apiClient.invokeApi({}, '/user', 'PUT', {}, body, "2").then(function (result) {
            ClevertapReact.event("User's profile updated", { 'userName': that.props.auth.user.userName });
            apiClient.invokeApi({ userName: state.userName }, '/user/{userName}', 'GET', {}, {})
                .then(function (userReturned) {

                    if (state.email) {
                        let newUserPool = new CognitoUserPool({
                            UserPoolId: config.userPoolId,
                            ClientId: config.clientId
                        });
                        let cognitoUser = newUserPool.getCurrentUser();

                        let attributeList = [];
                        let attribute = {
                            Name: 'email',
                            Value: state.email
                        };
                        let cognitoAttribute = new CognitoUserAttribute(attribute);
                        attributeList.push(cognitoAttribute);
                        cognitoUser.getSession(function (err, session) {
                            if (session) {
                                cognitoUser.updateAttributes(attributeList, function (err, updatedResult) {
                                    if (err) {

                                    }
                                    else if (updatedResult) {
                                        let newUserPool2 = new CognitoUserPool({
                                            UserPoolId: config.userPoolId,
                                            ClientId: config.clientId
                                        });
                                        let cognitoUser2 = newUserPool2.getCurrentUser();
                                        cognitoUser2.getSession(function (err, session) {
                                            if (session) {
                                                cognitoUser2.getUserAttributes(function (err, result2) {
                                                    if (err) {

                                                    }
                                                    else {
                                                        let index = result2.findIndex(x => x.Name === 'email_verified');
                                                        if (index !== -1) {
                                                            if (result2[index].Value === "true") {
                                                                userReturned.data.emailVerified = true;
                                                            }
                                                            else {
                                                                userReturned.data.emailVerified = false;
                                                            }
                                                        }
                                                        else {
                                                            userReturned.data.emailVerified = false;
                                                        }
                                                    }
                                                })
                                            }
                                        });
                                        if (that.state.federatedIdentity) {
                                            userReturned.data.federatedIdentity = true;
                                        }
                                        localStorage.setItem('userObj', JSON.stringify(userReturned.data));

                                        dispatch({
                                            type: 'SET_CURRENT_USER',
                                            user: userReturned.data
                                        });
                                        that.loaderSettings(result)
                                    }
                                });
                            }
                        });

                    }
                    else {
                        let newUserPool2 = new CognitoUserPool({
                            UserPoolId: config.userPoolId,
                            ClientId: config.clientId
                        });
                        let cognitoUser2 = newUserPool2.getCurrentUser();
                        cognitoUser2.getSession(function (err, session) {
                            if (session) {
                                cognitoUser2.getUserAttributes(function (err, result2) {
                                    if (err) {

                                    }
                                    else {
                                        let index = result2.findIndex(x => x.Name === 'email_verified');
                                        if (index !== -1) {
                                            if (result2[index].Value === "true") {
                                                userReturned.data.emailVerified = true;
                                            }
                                            else {
                                                userReturned.data.emailVerified = false;
                                            }
                                        }
                                        else {
                                            userReturned.data.emailVerified = false;
                                        }
                                    }
                                });
                            }
                        });
                        if (that.state.federatedIdentity) {
                            userReturned.data.federatedIdentity = true;
                        }
                        localStorage.setItem('userObj', JSON.stringify(userReturned.data));
                        setCurrentUser(userReturned.data);
                        that.loaderSettings(result)
                    }
                }).catch(function (result) {
                    //This is where you would put an error callback
                });
            // that.setState({ showAlert: true, alertText: result.data, alertStyle: "success" });
            // that.cancelSubmit();
            // setTimeout(() => {
            //     that.setState({ ...that.state, showAlert: false });
            // }, 2000);
            // that.hideIndicator();

        }).catch(function (result) {
            that.setState({ showAlert: true, alertText: result.response.data.errors[0].message, alertStyle: "danger" });
            setTimeout(() => {
                that.setState({ ...that.state, showAlert: false });
            }, 2000);
            that.hideIndicator();

        });
    }

    loaderSettings(result) {
        this.setState({ showAlert: true, alertText: result.data, alertStyle: "success" });
        this.disableInputs();
        this.cancelSubmit();
        setTimeout(() => {
            this.setState({ showAlert: false });
        }, 2000);
        this.hideIndicator();
    }

    setSubjectsHtml(event) {
        let keyword = event.target.selectedOptions[0].value || event.nativeEvent.target.value || event.nativeEvent.srcElement.value || null;

        let tempStateSubjects = this.state.subjects;

        for (let i = 0; i < tempStateSubjects.length; i++) {
            if (tempStateSubjects[i] === keyword) {
                return;
            }
        }
        this.setState({ subjects: this.state.subjects.concat(keyword) });
    }

    deleteTags(event) {

        $('#userProfileSubjectDropDownId').prop('selectedIndex', 0);
        let tempTaggedSubjects = this.state.subjects;
        for (let a = 0; a < tempTaggedSubjects.length; a++) {
            if (tempTaggedSubjects[a] === event.target.parentNode.firstElementChild.value) {
                tempTaggedSubjects.splice(a, 1);
            }
        }
        this.setState({ subjects: tempTaggedSubjects });
    }

    submitPassword() {
        const { errors, isValid } = this.getValidationState();
        if (!isValid) {
            this.setState({ errors });
        }
        else {
            let that = this;
            that.setState({ errors });
            that.showIndicator();

            changePassword(that.state.oldPassword, that.state.newPassword, function (err, result) {

                if (result) {
                    let body = {
                        userName: that.props.auth.user.userName,
                        userPassword: that.state.newPassword
                    };
                    apiClient.invokeApi({}, '/user', 'PUT', {}, body, "2").then(function (result) {
                        ClevertapReact.event("User's password updated", { 'userName': that.props.auth.user.userName });
                        that.setState({
                            showAlert: true,
                            alertText: "Password changed successfully",
                            alertStyle: "success",
                            showChangePasswordDialogBox: false,
                            oldPassword: "",
                            newPassword: ""
                        });
                        setTimeout(() => {
                            that.setState({ ...that.state, showAlert: false });
                        }, 5000);
                    });
                } else {
                    that.setState({
                        showAlert: true,
                        alertText: err,
                        alertStyle: "danger",
                        showChangePasswordDialogBox: false,
                        oldPassword: "",
                        newPassword: ""
                    });
                    setTimeout(() => {
                        that.setState({ ...that.state, showAlert: false });
                    }, 5000);
                }
            });
            that.hideIndicator();
        }
    }

    showDialog(event) {

        if (event.target.id === 'changePassword') {
            this.setState({ showChangePasswordDialogBox: true });
        }
        else if (event.target.id === 'adminCode') {
            this.setState({ showAdminCodeDiaogBox: true });
        }
        else if (event.target.id === 'schoolCode') {
            this.setState({ showSchoolCodeDiaogBox: true });
        }
        else if (event.target.id === 'linkGC') {
            this.setState({ showGoogleLoginBox: true });
        }
        else if (event.target.id === 'linkLP') {
            this.setState({ showLearnerPalLoginBox: true });
        }

    }

    getValidationState() {
        let errors = {};
        if (this.state.newPassword.length < 6) {
            errors.newPassword = 'Your password must be at least 8 characters';
        }
        if (this.state.newPassword !== this.state.confirmPassword) {
            errors.newPassword = 'Passwords do not match';
            errors.confirmPassword = 'Passwords do not match';
        }
        if (Validator.isEmpty(this.state.oldPassword)) {
            errors.oldPassword = 'This field is required';
        }
        if (Validator.isEmpty(this.state.newPassword)) {
            errors.newPassword = 'This field is required';
        }
        if (Validator.isEmpty(this.state.confirmPassword)) {
            errors.confirmPassword = 'This field is required';
        }
        return {
            errors,
            isValid: isEmpty(errors)
        }
    }

    getChangePasswordDialog() {
        let errors = this.state.errors;
        let body =
            <div>
                <TextFieldGroup
                    type='password'
                    error={errors.oldPassword}
                    field="oldPassword"
                    id="oldPassword"
                    value={this.state.oldPassword}
                    placeholder="Enter Current Password"
                    onChange={this.onChange}
                />
                <TextFieldGroup
                    type='password'
                    error={errors.newPassword}
                    field="newPassword"
                    id="newPassword"
                    value={this.state.newPassword}
                    placeholder="Enter New Password"
                    onChange={this.onChange}
                />
                <TextFieldGroup
                    type='password'
                    error={errors.confirmPassword}
                    id="confirmPassword"
                    field="confirmPassword"
                    value={this.state.confirmPassword}
                    placeholder="Confirm New Password"
                    onChange={this.onChange}
                />
            </div>;
        return <Modal
            show={this.state.showChangePasswordDialogBox}
            onHide={() => {
                this.setState({ showChangePasswordDialogBox: false });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Change Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    this.setState({ showChangePasswordDialogBox: false });
                }}>Cancel</Button>
                <Button onClick={() => {
                    this.submitPassword();
                }} bsStyle='primary'>Submit</Button>
            </Modal.Footer>
        </Modal>;
    }

    submitAdminCode() {
        if (Validator.isEmpty(this.state.adminCode)) {
            let errors = {};
            errors.adminCode = 'This Field is required';
            this.setState(errors);
        }
        else {
            this.showIndicator();
            let that = this;
            let body = {
                userName: this.props.auth.user.userName,
                adminCode: this.state.adminCode
            };
            apiClient.invokeApi({}, '/user/licensecode', 'PUT', {}, body, '2').then(function (result) {

                apiClient.invokeApi({ userName: that.state.userName }, '/user/{userName}', 'GET', {}, {})
                    .then(function (userReturned) {
                        if (that.state.federatedIdentity) {
                            userReturned.data.federatedIdentity = true;
                        }
                        localStorage.setItem('userObj', JSON.stringify(userReturned.data));
                        that.setState({ 'school': userReturned.data.schoolName, 'multiLingualLicense': userReturned.data.multiLingualLicense });
                        setCurrentUser(userReturned.data);
                    }).catch(function (result) {
                        //This is where you would put an error callback
                    });
                that.setState({ showAlert: true, alertText: result.data, alertStyle: "success", showAdminCodeDiaogBox: false });
                setTimeout(() => {
                    that.setState({ ...that.state, showAlert: false });
                }, 5000);
                that.hideIndicator();
            }).catch(function (result) {

                that.hideIndicator();
                that.setState({ showAlert: true, alertText: result.response.data.errors[0].message, alertStyle: "danger", showAdminCodeDiaogBox: false });
                setTimeout(() => {
                    that.setState({ ...that.state, showAlert: false });
                }, 5000);
            });
        }
    }
    submitSchoolCode() {
        if (Validator.isEmpty(this.state.schoolCode)) {
            let errors = {};
            errors.schoolCode = 'This Field is required';
            this.setState(errors);
        }
        else {
            this.showIndicator();
            let that = this;
            let body = {
                userName: this.props.auth.user.userName,
                teacherCode: this.state.schoolCode
            };
            apiClient.invokeApi({}, '/user/licensecode', 'PUT', {}, body, '2').then(function (result) {

                apiClient.invokeApi({ userName: that.state.userName }, '/user/{userName}', 'GET', {}, {})
                    .then(function (userReturned) {
                        if (that.state.federatedIdentity) {
                            userReturned.data.federatedIdentity = true;
                        }
                        localStorage.setItem('userObj', JSON.stringify(userReturned.data));
                        that.setState({ 'school': userReturned.data.schoolName, 'multiLingualLicense': userReturned.data.multiLingualLicense });
                        setCurrentUser(userReturned.data);
                    }).catch(function (result) {
                        //This is where you would put an error callback
                    });
                that.setState({ showAlert: true, alertText: result.data, alertStyle: "success", showSchoolCodeDiaogBox: false });
                setTimeout(() => {
                    that.setState({ ...that.state, showAlert: false });
                }, 5000);
                that.hideIndicator();
            }).catch(function (result) {

                that.hideIndicator();
                that.setState({ showAlert: true, alertText: result.response.data.errors[0].message, alertStyle: "danger", showSchoolCodeDiaogBox: false });
                setTimeout(() => {
                    that.setState({ ...that.state, showAlert: false });
                }, 5000);
            });
        }
    }

    linkAccount() {

        this.showIndicator();

        let pathTemplate = '/account/{linkAccount}';
        var method = 'POST';
        // var additionalParams = { 'accessToken': localStorage.getItem('access_token') };
        var additionalParams = {};
        var body = {
            userName: this.state.LpUsername,
            password: this.state.LpPassword
        };

        var params = {
            linkAccount: "learnerPal"
        }
        let that = this;
        apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, "7")
            .then(function (result) {

                var pathTemplate = '/user/{userName}';
                var method = 'GET';
                var additionalParams = {};
                var body = {};

                var params = {
                    userName: that.props.auth.user.userName,
                };

                apiClient.invokeApi(params, pathTemplate, method, additionalParams, body)
                    .then((result) => {
                        result.data.federatedIdentity = true;
                        localStorage.setItem('userObj', JSON.stringify(result.data));
                        that.setState({ showAlert: true, alertText: "Account successfully Linked", alertStyle: "success" });
                        that.props.dispatch({
                            type: "SET_CURRENT_USER",
                            user: result.data
                        });
                        setTimeout(() => {
                            window.location.reload();
                        }, 300);
                    }).catch((err) => {
                        that.hideIndicator();
                        that.setState({ showAlert: true, alertText: "Account linked however something went wrong, please refresh the page", alertStyle: "success" });
                        console.log(err);
                    })
            })
            .catch(function (err) {
                that.setState({ showAlert: true, alertText: "Something went wrong failed, Account linking failed", alertStyle: 'danger', showLearnerPalLoginBox: false });
                that.hideIndicator();
            });

    }

    getAdminCodeDialog() {
        let errors = this.state.errors;
        let body =
            <div>
                <TextFieldGroup
                    error={errors.adminCode}
                    id="adminCode"
                    field="adminCode"
                    value={this.state.adminCode}
                    placeholder="Enter Admin Code"
                    onChange={this.onChange}
                />
            </div>;
        return <Modal
            show={this.state.showAdminCodeDiaogBox}
            onHide={() => {
                this.setState({ showAdminCodeDiaogBox: false });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Admin Code</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    this.setState({ showAdminCodeDiaogBox: false });
                }}>Cancel</Button>
                <Button onClick={() => {
                    this.submitAdminCode();
                }} bsStyle='primary'>Submit</Button>
            </Modal.Footer>
        </Modal>;
    }
    getSchoolCodeDialog() {
        let errors = this.state.errors;
        let body =
            <div>
                <TextFieldGroup
                    error={errors.schoolCode}
                    id="schoolCode"
                    field="schoolCode"
                    value={this.state.schoolCode}
                    placeholder="Enter School Code"
                    onChange={this.onChange}
                />
            </div>;
        return <Modal
            show={this.state.showSchoolCodeDiaogBox}
            onHide={() => {
                this.setState({ showSchoolCodeDiaogBox: false });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">School Code</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    this.setState({ showSchoolCodeDiaogBox: false });
                }}>Cancel</Button>
                <Button onClick={() => {
                    this.submitSchoolCode();
                }} bsStyle='primary'>Submit</Button>
            </Modal.Footer>
        </Modal>;
    }

    linkLearnerPal() {
        let errors = this.state.errors;
        let body =
            <div>
                <form className="form-signin" onSubmit={this.onSubmit}>
                    <h2 className="form-signin-heading">Sign In</h2>
                    <TextFieldGroup
                        error={errors.username}
                        label="Username"
                        field="username"
                        id="LpUsername"
                        value={this.state.LpUsername}
                        placeholder="Enter username"
                        onChange={this.onChange}
                        selectWidth={false}
                    />
                    <TextFieldGroup
                        error={errors.password}
                        label="Password"
                        field="password"
                        type="password"
                        id="LpPassword"
                        value={this.state.LpPassword}
                        placeholder="Enter password"
                        onChange={this.onChange}
                        selectWidth={false}
                    />
                    {errors && <span style={{ 'color': 'red' }}>{errors.message}</span>}
                </form>
            </div>;
        return <Modal
            show={this.state.showLearnerPalLoginBox}
            onHide={() => {
                this.setState({ showLearnerPalLoginBox: false });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">LearnerPal Login</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    this.setState({ showLearnerPalLoginBox: false });
                }}>Cancel</Button>
                <Button onClick={() => {
                    this.linkAccount();
                    this.setState({ showLearnerPalLoginBox: false });
                }} bsStyle='primary'>Submit</Button>
            </Modal.Footer>
        </Modal>;
    }

    render() {
        let state = this.state;
        let allSubjectsHtml = [];

        if (state.subjects && state.subjects.length > 0) {
            for (let i = 0; i < state.subjects.length; i++) {
                allSubjectsHtml.push(<span key={state.subjects[i]} className="keywordTag">
                    <input type="hidden" name="undefined[]" value={state.subjects[i]} />{state.subjects[i]}{this.editMode && <span className="text-cross-icon" onClick={this.deleteTags} >×</span>}
                </span>);
            }
        }
        return (
            <div>
                {this.getChangePasswordDialog()}
                {this.getAdminCodeDialog()}
                {this.getSchoolCodeDialog()}
                {this.linkLearnerPal()}
                <span>
                    <div className="searchCrtBar">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-xs-8">
                                    <h3 style={{marginTop:"17px",fontWeight: "bold"}}>Teacher Profile</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </span>
                <div className="container-fluid profilePage grey-background">
                    <div className="wrapperProfileBox">
                        <div className="row">
                            <div className="col-md-3 col-sm-3 col-xs-6"> <img className="img-circle profilePic" src={state.profilePicture ? state.profilePicture : dummyImage} /> </div>
                            <div className="col-md-4  col-sm-4 col-xs-6 ">
                                <div className="chngPicBtn">Change Picture
                                    <input type="file" onChange={this.uploadFile} />
                                </div>
                            </div>
                            <div className="hamza col-md-5  col-sm-5 col-xs-12" style={{ textAlign: "right" }}>
                                {/* <button className="reset" onClick={() => {
                                    alert("Nothing")
                                }}>System Reset
                                </button> */}
                                <span className="resetImageDiv" onClick={() => {
                                    this.setState({ showAllDeleteDialog: true })
                                }}>
                                    <span className="resetImage">
                                    </span> <span className="resetText"> System Reset</span>
                                </span>
                                <a className="actionButtonclass" style={{ top: "5px", transform: 'scale(0.8, 0.8)', webkitTransform: 'scale(0.8, 0.8)', webkitFilter: "hue-rotate(165deg)", filter: "hue-rotate(165deg)" }}>
                                    <span style={{ left: "-525px", top: "0px", zIndex: '99' }} className="WHSign">
                                        {/* <span className="tooltiptext"> */}
                                        <span className="hoverMessageHeading">System Reset</span>
                                        <br />
                                        Resetting the system will delete all classes, students and assignments and prepare the Teacher Portal for a new year or semester. Historical reporting on deleted classes/students will be archived and available only through request by an administrator.
                                  {/* </span> */}
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div className="profileRowBox">
                            {!this.props.auth.user.federatedIdentity && <div className="row">
                                <div className="col-md-3 col-sm-3 col-xs-3">
                                    <p className="lblText">Login Name:</p>
                                </div>
                                <div className="col-md-9  col-sm-9 col-xs-9">
                                    <p className="labelTextValue clrGrey">{state.userName}</p>
                                </div>
                            </div>}
                        </div>
                        <div className="profileRowBox">
                            <div className="row">
                                <div className="col-md-3  col-sm-3 col-xs-3">
                                    <p className="lblText">Password:</p>
                                </div>
                                <div className="col-md-9   col-sm-9 col-xs-9">
                                    <a href="javascript:;" id="changePassword" onClick={this.showDialog}>Reset Password</a>
                                </div>
                            </div>
                        </div>
                        <div className="profileRowBox">
                            <div className="row">
                                <div className="col-md-3 col-sm-3 col-xs-3">
                                    <p className="lblText">First Name:</p>
                                </div>
                                <div className="col-md-8  col-sm-7 col-xs-9">
                                    <p className="labelTextValue clrGrey onEditHide">{state.firstName}</p>
                                    <div className="form-group showAble">
                                        <div className="posRel fieldBg selectWidth">
                                            <input type="text" className="form-control" value={state.firstName} id="firstName" onChange={this.onChange} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-1 col-sm-2 col-xs-1"><span id="editButton1" className="edtBtn" onClick={this.enableInputs} /></div>
                            </div>
                        </div>
                        <div className="profileRowBox">
                            <div className="row">
                                <div className="col-md-3 col-sm-3 col-xs-3">
                                    <p className="lblText">Last Name:</p>
                                </div>
                                <div className="col-md-8  col-sm-7 col-xs-9">
                                    <p className="labelTextValue clrGrey onEditHide">{state.lastName}</p>
                                    <div className="form-group showAble">
                                        <div className="posRel fieldBg selectWidth">
                                            <input type="text" className="form-control" value={state.lastName} id="lastName" onChange={this.onChange} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-1 col-sm-2 col-xs-1"><span id="editButton2" className="edtBtn  hidden-xs" onClick={this.enableInputs} /></div>
                            </div>
                        </div>
                        <div className="profileRowBox">
                            <div className="row">
                                <div className="col-md-3  col-sm-3 col-xs-3">
                                    <p className="lblText">Email:</p>
                                </div>
                                <div className="col-md-8   col-sm-7 col-xs-9">
                                    <p className="labelTextValue clrGrey  onEditHide">{state.email ? state.email : 'email not found'}</p>
                                    <div className="form-group showAble">
                                        <div className="posRel fieldBg selectWidth">
                                            <input type="text" className="form-control" placeholder={state.email ? state.email : 'email not found'} value={state.email ? state.email : ''} id="email" onChange={this.onChange} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-1 col-sm-2 col-xs-1"><span id="editButton3" className="edtBtn  hidden-xs" onClick={this.enableInputs} /></div>
                            </div>
                        </div>
                        <div className="profileRowBox">
                            <div className="row">
                                <div className="col-md-3  col-sm-3 col-xs-3">
                                    <p className="lblText">Department:</p>
                                </div>
                                <div className="col-md-8    col-sm-7 col-xs-9">
                                    <p className="labelTextValue clrGrey  onEditHide">{state.isPrincipal ? "All departments" : state.department}</p>
                                    <SelectFieldGroup
                                        dropDownId="department"
                                        field="department"
                                        value={state.department}
                                        options={state.allDepartments}
                                        placeholder="Select Department"
                                        onChange={this.onChange}
                                    />
                                </div>
                                {!state.isPrincipal && <div className="col-md-1 col-sm-2 col-xs-1"><span id="editButton4" className="edtBtn  hidden-xs" onClick={this.enableInputs} /></div>}
                            </div>
                        </div>

                        {this.state.multiLingualLicense && <div className="profileRowBox">
                            <div className="row">
                                <div className="col-md-3 col-sm-3 col-xs-3 beta-img">
                                    <p className="lblText">Language:</p>
                                </div>
                                <div className="col-md-8 col-sm-8 col-xs-8 langStyle">
                                    <p className="labelTextValue clrGrey onEditHide">{state.isLanguage}</p>
                                    <SelectFieldGroup
                                        placeholder={this.state.isLanguage}
                                        options={this.state.languages}
                                        show={true}
                                        onChange={this.onLanguageSelect}
                                    />
                                    <img className="ml-beta" src={betaIcon} />
                                </div>
                                <div className="col-md-1 col-sm-2 col-xs-1"><span id="editButton6" className="edtBtn  hidden-xs" onClick={this.enableInputs} /></div>
                            </div>
                        </div>}

                        <div className="profileRowBox">
                            <div className="row">
                                <div className="col-md-3  col-sm-3 col-xs-3">
                                    <p className="lblText">School:</p>
                                </div>
                                <div className="col-md-9   col-sm-9 col-xs-9">
                                    {state.school && <p className="labelTextValue clrGrey">{state.school}</p>}
                                    {!state.school && <a href="javascript:;" id="schoolCode" onClick={this.showDialog}>Add School Code</a>}
                                </div>
                            </div>
                        </div>

                        <div className="profileRowBox">
                            <div className="row">
                                <div className="col-md-3  col-sm-3 col-xs-3">
                                    <p className="lblText">Subjects:</p>
                                </div>
                                <div className="col-md-8    col-sm-7 col-xs-9">
                                    <SelectFieldGroup
                                        dropDownId="userProfileSubjectDropDownId"
                                        field="keywordsDrop"
                                        options={state.allSubjects}
                                        placeholder="Select your subjects"
                                        onChange={this.setSubjectsHtml}
                                    />
                                    <div>
                                        {allSubjectsHtml}
                                    </div>
                                </div>
                                <div className="col-md-1 col-sm-2 col-xs-1"><span id="editButton5" className="edtBtn  hidden-xs" onClick={this.enableInputs} /></div>
                            </div>
                        </div>
                        <div className="profileRowBox">
                            <div className="row">
                                <div className="col-md-3  col-sm-3 col-xs-3">
                                    <p className="lblText">Link Account</p>
                                </div>
                                <div className="col-md-9   col-sm-9 col-xs-9">
                                    {state.linkedAccount && <p className="labelTextValue clrGrey">{state.linkedAccount}</p>}
                                    {!state.linkedAccount && this.props.auth.user && this.props.auth.user.federatedIdentity && <a href="javascript:;" id="linkLP" onClick={this.showDialog}>Add LearnerPal Account</a>}
                                    {!state.linkedAccount && this.props.auth.user && !this.props.auth.user.federatedIdentity && <a href="javascript:;" id="linkGC" onClick={this.showDialog}>{this.googleButton()}</a>}
                                </div>
                            </div>
                        </div>

                        {(this.props.auth.user.userType.toLowerCase() !== "admin" || !this.props.auth.user.schoolID) &&
                            <div className="profileRowBox">
                                <div className="row">
                                    <div className="col-md-3  col-sm-3 col-xs-3">
                                        <p className="lblText">Admin Access:</p>
                                    </div>
                                    <div className="col-md-9   col-sm-9 col-xs-9">
                                        <a href="javascript:;" id="adminCode" onClick={this.showDialog}>Add Admin Code</a>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="profileRowBox">
                            <div className="row">
                                <div className="col-md-12  col-sm-12 col-xs-12 text-right">
                                    <button id="cancelButton" className="btn-cancel removeDisplay" onClick={this.cancelSubmit}>Cancel</button>
                                    <button id="submitButton" className="btn-saveBtn removeDisplay" onClick={this.onSubmit}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ProgressIndicator show={this.state.loading} />
                    {this.state.showAlert &&
                        <div className="row">
                            <div className="col-md-3 col-xs-3" />
                            <div className="col-md-6 col-xs-3">
                                <Alert className={classnames({ 'unsetHeight': this.state.unsetHeight })} bsStyle={this.state.alertStyle}
                                    onDismiss={this.handleAlertDismiss}>
                                    {this.state.alertText}
                                </Alert>
                            </div>
                            <div className="col-md-3 col-xs-3" />
                        </div>
                    }
                </div>
                {this.allDeleteDialog()}
            </div>
        );
    }

    allDeleteDialog() {
        let body = <div id="deleteDialogContent">
            <p>
                Are you sure you want to do System Reset? Resetting the system will delete all classes, students and assignments and prepare the Teacher Portal for a new year or semester.
            </p>
            {/* {this.state.deleteError ? <Alert bsStyle="danger">
                {this.state.deleteErrorMessage}
            </Alert> : ''} */}
        </div>;
        return <Modal
            backdrop="static"
            show={this.state.showAllDeleteDialog}
            onHide={() => {
                this.setState({ showAllDeleteDialog: false/*, deleteError: false*/ });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton className="resetHeader">
                <Modal.Title id="delete-activity-modal">System Reset</Modal.Title>
            </Modal.Header>
            <Modal.Body className="resetHeaderBody">
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Button style={{ marginRight: "5px" }} onClick={() => {
                    this.setState({ showAllDeleteDialog: false/*, deleteError: false*/ });
                }}>Cancel</Button>
                <Button onClick={() => {
                    this.setState({ showAllDeleteDialog: false/*, deleteError: false*/ }, () => {
                        this.deleteAllClasses()
                    });
                    // this.setState({deleteError: true, deleteErrorMessage: error.statusMessage});
                }} bsStyle='danger'>Reset</Button>
            </Modal.Footer>
        </Modal>;
    }

    deleteAllClasses() {

        let context = this;
        context.showIndicator();
        let params = {
            teacher: context.props.auth.user.userName,
        };
        let pathTemplate = '/DeleteAllClasses/{teacher}';
        let method = 'DELETE';
        let additionalParams = {};
        let body = {};

        apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, '5').then((result) => {

            console.log(result)

            // const {dispatch} = this.props;

            // dispatch({
            //     type: RECEIVED_ASSIGNMENT_RESULTS,
            //     status: 200,
            //     data: JSON.parse(JSON.stringify([])),
            //     alertText: '',
            //     alertStyle: '',
            //     showAlert: false,
            //     totalRecords: 0,
            //     clearFilters: true
            // });

            context.setState({
                showAlert: true,
                alertStyle: 'success',
                alertText: "Your system has been reset successfully."
                // alertText: 'Unable to load data, please contact administrator.'
            }, context.hideIndicator);

        }).catch((error) => {
            let responseData = [];
            let combinedMessage = '';
            if (error.response && error.response.data.errors) {
                for (let err of error.response.data.errors)
                    responseData.push(err.message)

                combinedMessage = responseData.join(', ')
            }

            if (error.message == "No Classes Found" || combinedMessage == "No Classes Found") {
                context.setState({
                    showAlert: true,
                    alertStyle: 'success',
                    alertText: "Your system has been reset successfully."
                    // alertText: 'Unable to load data, please contact administrator.'
                }, context.hideIndicator);
            } else {
                context.setState({
                    showAlert: true,
                    alertStyle: 'danger',
                    alertText: responseData.length != 0 && combinedMessage ? combinedMessage : error.message
                    // alertText: 'Unable to load data, please contact administrator.'
                }, context.hideIndicator);
                // setTimeout(() => {
                //     context.setState({
                //         showAlert: false,
                //         alertStyle: '',
                //         alertText: ''
                //     })
                // }, 4000);
            }
        });

    }

}


function mapStateToProps(state) {
    return { ...state };
}

export default connect(mapStateToProps)(TeacherProfile);