/**
 * Created by ashahab on 8/25/2017.
 */

import React, {Component} from "react";
import {connect} from "react-redux";
import Heading from "../heading/heading";
import Filter from "../../components/filter/filter";
import MaintenanceTable from "../../components/maintenance-table/maintenance-table";
import ReactScrollPagination from 'react-scroll-pagination'
import ProgressIndicator from "../progress-indicator/progress-indicator";
import {Alert} from "react-bootstrap";
import ElasticSearchQuery from "../../actions/elastic-search-query";
import ElasticSearchActions from "../../actions/elastic-search-actions";
import {
    ElasticSearchTypes,
    ElasticSearchColumns,RECEIVED_USERS_RESULTS} from "../../actions/types";
import config from "../../config";
import LoginUtils from "../common/login-utils";
import {browserHistory} from "react-router";
import $ from "jquery";
import excelIcon from "../reports/microsoft-excel.png";
import excelIconHover from "../reports/microsoft-excebl.png";
import {ActionIcons} from "../actionIcons/action-Icons";
import {ActionIconTypes} from "../../actions/types";
import './manage-users.css';

class ManageUsers extends Component {

    constructor(props) {
        super(props);
        this.printTable = this.printTable.bind(this);
        this.exportToExcel = this.exportToExcel.bind(this);

        this.state = {showAlert:false, alertStyle:'', alertText:'',loadingNextPage:0, isPageable:true};

        this.viewActivityCssClassName = "activityLogBlock questionActivityMainBox";
        this.tableCssClassName = 'listViewBlock ';
        this.filterComponentCssClassName = 'filterBlock NiceScroll';
    }

    getNextPage() {

        const { screenWidth } = this.state;
        const isMobile = screenWidth <= 767;

        if(isMobile && this.state.loadingNextPage === 0)
        {
            let myID = Math.random() * 10000000000000000000;
            this.setState({loadingNextPage: myID});
            if (this.state.loadingNextPage === myID) {
                let esAttr = this.props.elasticSearchAttributes.map.get('allUsers');
                const {dispatch} = this.props;
                ElasticSearchActions.setElasticSearchAttributes(dispatch,'allUsers',esAttr.searchTerm, esAttr.pageSize+10, esAttr.activePage,esAttr.sortObj, esAttr.filterValues);
                let elasticSearchQuery  = new ElasticSearchQuery();
                elasticSearchQuery.query(ElasticSearchTypes.Users+config.stage, ElasticSearchColumns.Users, this.props,
                    esAttr.searchTerm, esAttr.pageSize+10, esAttr.activePage , esAttr.sortObj, esAttr.filterValues, function (results, totalRecords) {
                        dispatch({
                            type: RECEIVED_USERS_RESULTS,
                            status: 200,
                            data: JSON.parse(JSON.stringify(results)),
                            totalRecords:totalRecords
                        });
                    });
                this.setState({loadingNextPage: 0});
            }
        }
    }

    componentDidMount()
    { this.showIndicator();
        LoginUtils.isAuthenticated(this.props, browserHistory, '/users', '/');

        let that = this;
        if (this.props.location.state)
        {
            let customQuery = "schoolID : ("+this.props.location.state.searchBySchoolId+") AND userType2 : (Teacher OR teacher OR admin)";
            const {dispatch} = that.props;
            ElasticSearchActions.setElasticSearchAttributes(dispatch,'allUsers','', 200, 1 ,{_score:{order: "desc"}}, []);
            let elasticSearchQuery  = new ElasticSearchQuery();
            elasticSearchQuery.query(ElasticSearchTypes.Users+config.stage, ElasticSearchColumns.Users, that.props,
                '', 200, 1 ,{_score:{order: "desc"}, createdAt:{order: "desc"}}, [], function (results, totalRecords) {
                    dispatch({
                        type: RECEIVED_USERS_RESULTS,
                        status: 200,
                        data: JSON.parse(JSON.stringify(results)),
                        totalRecords:totalRecords
                    });
that.hideIndicator();
                }, true, customQuery);
            that.setState({'alertText': that.props.manageUsers.alertText,'alertStyle': that.props.manageUsers.alertStyle, 'showAlert': that.props.manageUsers.showAlert});
            setTimeout(()=>{
                that.setState({...that.state, showAlert: false});
            }, 5000);
        }
        else
        {
            const {dispatch} = this.props;
            ElasticSearchActions.setElasticSearchAttributes(dispatch,'allUsers','', 200, 1 ,{_score:{order: "desc"}}, []);
            let elasticSearchQuery  = new ElasticSearchQuery();
            elasticSearchQuery.query(ElasticSearchTypes.Users+config.stage, ElasticSearchColumns.Users, this.props,
                '', 200, 1 ,{_score:{order: "desc"}, createdAt:{order: "desc"}}, [], function (results, totalRecords) {
                    dispatch({
                        type: RECEIVED_USERS_RESULTS,
                        status: 200,
                        data: JSON.parse(JSON.stringify(results)),
                        totalRecords:totalRecords
                    });
that.hideIndicator();
                }, false);
            this.setState({'alertText': this.props.manageUsers.alertText,'alertStyle': this.props.manageUsers.alertStyle, 'showAlert': this.props.manageUsers.showAlert});
            setTimeout(()=>{
                this.setState({...this.state, showAlert: false});
            }, 5000);
        }
        $('#pageSizeDropDown').val(200);
    }

    exportToExcel(tableID, removeLastHeader)
    {
        let context =this;
        const {dispatch} = this.props;
        context.setState({isPageable:false});
        let esAttr = context.props.elasticSearchAttributes.map.get('allUsers');
        let elasticSearchQuery  = new ElasticSearchQuery();
        const customQuery = this.props.location.state  ? "schoolID : ("+this.props.location.state.searchBySchoolId+") AND userType2 : (teacher)" : undefined;
        elasticSearchQuery.query(ElasticSearchTypes.Users+config.stage, ElasticSearchColumns.Users, this.props,
            esAttr.searchTerm, context.props.manageUsers.totalRecords, 1 ,esAttr.sortObj, esAttr.filterValues, function (results, totalRecords) {
                dispatch({
                    type: RECEIVED_USERS_RESULTS,
                    status: 200,
                    data: JSON.parse(JSON.stringify(results)),
                    totalRecords:totalRecords
                });
                setTimeout(()=>{
                    //EXPORT TO EXCEL
                    let data_type = 'data:application/vnd.ms-excel';
                    let table_div = document.getElementById(tableID).cloneNode(true);

                    if(removeLastHeader)
                    {
                        table_div.firstElementChild.firstElementChild.firstElementChild.removeChild(table_div.firstElementChild.firstElementChild.firstElementChild.lastChild);
                    }
                    for(let i=0; i<table_div.firstElementChild.lastChild.children.length; i++)
                    {
                        for (let j=0; j<table_div.firstElementChild.lastChild.children[i].children.length; j++)
                        {
                            if (table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild &&
                                table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild.firstElementChild)
                            {
                                table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild.removeChild(table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild.firstElementChild);
                            }
                        }
                    }
                    let table_html = table_div.outerHTML.replace(/ /g, '%20').replace(/\#/g, encodeURIComponent('#'));
                    let a = document.createElement('a');
                    document.body.appendChild(a);
                    a.href = data_type + ', ' + table_html;
                    a.download = 'manage-users-'+Date.now()+'.xls';
                    a.click();
                    context.setState({isPageable:true});
                }, 100);
            }, customQuery ? true : false, customQuery);
    }

    printTable(tableID, removeLastHeader)
    {

        let context =this;
        context.showIndicator();

        let myWindow = window.open('', 'PRINT', 'height=600,width=800');

        const {dispatch} = this.props;
        context.setState({isPageable:false});
        let esAttr = context.props.elasticSearchAttributes.map.get('allUsers');
        let elasticSearchQuery  = new ElasticSearchQuery();
        elasticSearchQuery.query(ElasticSearchTypes.Users+config.stage, ElasticSearchColumns.Users, this.props,
            esAttr.searchTerm, context.props.manageUsers.totalRecords, 1 ,esAttr.sortObj, esAttr.filterValues,  (results, totalRecords) => {
                dispatch({
                    type: RECEIVED_USERS_RESULTS,
                    status: 200,
                    data: JSON.parse(JSON.stringify(results)),
                    totalRecords:totalRecords
                });

                setTimeout(() => { 
                
                    //PRINT TABLE
                    
                    let table_div = document.getElementById(tableID)?document.getElementById(tableID).cloneNode(true):null;
                    if (table_div)
                    {
                        if(removeLastHeader)
                        {
                            table_div.firstElementChild.firstElementChild.firstElementChild.removeChild(table_div.firstElementChild.firstElementChild.firstElementChild.lastChild);
                        }
                        for(let i=0; i<table_div.firstElementChild.lastChild.children.length; i++)
                        {
                            for (let j=0; j<table_div.firstElementChild.lastChild.children[i].children.length; j++)
                            {
                                if (table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild &&
                                    table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild.firstElementChild)
                                {
                                    table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild.removeChild(table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild.firstElementChild);
                                }
                            }
                        }
                        // let myWindow = window.open('', 'PRINT', 'height=600,width=800');
                        myWindow.document.write('<html><head><title>' + 'Learner Pal' + '</title>');
                        myWindow.document.write('<h1>' + 'Manage Users Report' + '</h1>');
                        myWindow.document.write(table_div.outerHTML);
                        myWindow.document.write('</body></html>');
                        myWindow.focus(); // necessary for IE >= 10*/
                        setTimeout(function () {

                            context.hideIndicator();
                            
                            myWindow.print();
                            myWindow.document.close(); // necessary for IE >= 10
                            myWindow.close();
                        }, 1000);
                    }
                    else
                    {
                        this.setState({
                            showAlert: true,
                            alertStyle: 'danger',
                            alertText: 'Content not available, can not print. Please contact administrator.'
                        });
                        setTimeout(()=> {

                            context.hideIndicator();

                            this.setState({
                                showAlert: false,
                                alertStyle: '',
                                alertText: ''
                            })
                        }, 5000);
                    }
                
                }, 100);
            }, false);
       
    }
    render() {
        
        // sort the users data on registered date
        // this.props.manageUsers.results.sort((a,b) => parseInt(b.createdAt) - parseInt(a.createdAt))

        let context = this;
        let esAttr = this.props.elasticSearchAttributes.map.get('allUsers');
        let maintenanceTableData = {
            emptyMessage: 'No records found.',
            showActionMenu: false,
            actions: [],
            columns: [
                {name: 'userName', type: 'text', label: 'Username', options: {sortable: true, customDom: (record) => {
                    return (<td width="15%"><div title={record.userName} >{record.userName}</div></td>);
                }}},
                {name: 'userPassword', type: 'text', label: 'Password', options: {sortable: true, customDom: (record) => {
                    return (<td width="10%"><div title={record.userPassword} >{record.userPassword}</div></td>);
                }}},
                {name: 'firstName', type: 'text', label: 'First Name', options: {sortable: true, width: '10%'}},
                {name: 'lastName', type: 'text', label: 'Last Name', options: {sortable: true, width: '10%'}},
                {name: 'email', type: 'text', label: 'Email', options: {sortable: true, width: '15%'}},
                {name: 'userType2', type: 'text', label: 'User Type', options: {sortable: true, width: '5%'}},
                {name: 'userStatus2', type: 'text', label: 'Status', options: {sortable: true, width: '5%'}},
                {name: 'schoolName', type: 'text', label: 'School Name', options: {sortable: true, width: '10%'}},
                {name: 'trialSchoolName', type: 'text', label: 'Trial School Name', options: {sortable: true, width: '10%'}},
                {name: 'createdAt', type: 'date', label: 'Registered On', options: {sortable: true, customDom: (record) => {
                    let dateValue = new Date(parseFloat(record["createdAt"])).toLocaleDateString();
                    return (<td width="10%"><div title={dateValue} >{dateValue}</div></td>);
                }}}
            ],
            items: this.props.manageUsers.results,
            pageable: this.state.isPageable,
            rowClickAction: null,
            totalRecords: this.props.manageUsers.totalRecords,
            pagination: {
                pageSize: esAttr && esAttr.pageSize?esAttr.pageSize:200,
                activePage: 1,
                showPageInput: true,
                showFirstButton: true,
                showLastButton: true,
                showNextButton: true,
                showPreviousButton: true,
                firstButtonLabel: '<<',
                lastButtonLabel: '>>',
                previousButtonLabel: '<',
                nextButtonLabel: '>',
                showPageInfo: true,
                showGotoInput: false,
                gotoInputLabel: 'Goto',
                pageLinkSize:5
            }
        };
        return(
            <div id="manageUsers">
                <Heading
                    isQuery={false}
                    isHideHeading={this.state.viewQuestionDetailsPage}
                    allButtons={[]}
                    isCreateButton={false}
                    heading="Manage Users"
                    isShowButton={true}
                    isSearch={true}
                    actionName = {RECEIVED_USERS_RESULTS}
                    screenNameForElasticSearch = {ElasticSearchTypes.Users}
                    allColumnsForElasticSearch = {ElasticSearchColumns.Users}
                    location = "allUsers"
                />
                <div id="tableFiltersAndSideView">
                    <div className="container-fluid noPad listMainBoxWrapper manageUsersScrollHeightIssue">
                        <Filter
                            isQuery={false}
                            actionName = {RECEIVED_USERS_RESULTS}
                            viewActivityCssClass={this.filterComponentCssClassName + " manageUsersFilterBoxZindex"}
                            clearFilters={'manageUsers.clearFilters'}
                            filterColumns={[
                                {name: 'userType2', type: 'text', label: 'User Type', sort: 'desc'},
                                {name: 'userStatus2', type: 'text', label: 'Status', sort: 'desc'},
                                {name: 'subjects', type: 'text', label: 'Subjects', sort: 'desc'},
                            ]}
                            screenNameForElasticSearch = {ElasticSearchTypes.Users}
                            allColumnsForElasticSearch = {ElasticSearchColumns.Users}
                            location = "allUsers"
                        />
                        <div className={this.tableCssClassName}>
                            <div className="listToBesslide NiceScroll">
                                <div className="blockForlist ">
                                    <div className="col-md-12" style={{marginBottom:'-5px'}}>
                                        <span onMouseOver={(event)=>{event.target.firstElementChild.src = excelIconHover;}} onMouseOut={(event)=>{event.target.firstElementChild.src = excelIcon;}}
                                              className="detailPrintForReport" data-toggle="tooltip" title="Export Report To Excel" data-placement="bottom" data-original-title="Print"
                                              onClick={()=>{
                                                  this.exportToExcel('manage-users-table', false);
                                              }}>
                                            <img src={excelIcon} style={{width:'43px'}} />
                                    </span>
                                        <ActionIcons iconType={ActionIconTypes.Print} action={function () {
                                            context.printTable('manage-users-table', false);
                                        }}/>
                                    </div>
                                    <MaintenanceTable
                                        tableId = "manage-users-table"
                                        isQuery={false}
                                        location = "allUsers"
                                        screenNameForElasticSearch = {ElasticSearchTypes.Users}
                                        allColumnsForElasticSearch = {ElasticSearchColumns.Users}
                                        actionName = {RECEIVED_USERS_RESULTS}
                                        options={maintenanceTableData}
                                        sortAction={{
                                            action: {RECEIVED_USERS_RESULTS},
                                            attributes: {
                                                skipAlterForAllRecords: true
                                            },
                                            dataPath: 'data'
                                        }}
                                    />
                                    <ReactScrollPagination
                                        fetchFunc={this.getNextPage}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ProgressIndicator show={this.state.loading}/>
                {this.state.showAlert &&
                <div className="row">
                    <div className="col-md-3 col-xs-3"/>
                    <div className="col-md-6 col-xs-3">
                        <Alert bsStyle={this.state.alertStyle}
                               onDismiss={this.handleAlertDismiss}>
                            {this.state.alertText}
                        </Alert>
                    </div>
                    <div className="col-md-3 col-xs-3"/>
                </div>
                }
            </div>
        );
    }

    
showIndicator() {
    this.setState({
        ...this.state,
        loading: true
    });
}

hideIndicator() {
    this.setState({
        ...this.state,
        loading: false
    });
}

}


function mapStateToProps(state) {
    return {...state};
}

export default connect(mapStateToProps)(ManageUsers);
