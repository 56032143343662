/**
 * Created by ashahab on 6/12/2017.
 */

export default function headingReducer(state = {pageSize:10 }, action) {
    switch (action.type) {
        case 'CHANGE_PAGE_SIZE':
            return {
                ...state,
                pageSize: action.pageSize
            };
        default:
            return state
    }
};
