import React, {Component} from "react";
import {connect} from "react-redux";
import Breadcrumb from "../../../breadcrumb/breadcrumb";
import ReactDOM, {findDOMNode} from "react-dom";
import TextFieldGroup from "../../../forms/text-field-group";
import SelectFieldGroup from "../../../forms/select-field-group";
import apiClient from "../../../../actions/api-client";
import Filter from "../../../filter/filter";
import $ from "jquery";
import LoginUtils from "../../../common/login-utils";
import ElasticSearchStore from "../../../../actions/elastic-search-store";
import {
    CREATE_ACTIVITY_FETCH_FLASHCARD_SUCCESS,
    CREATE_ACTIVITY_MOVE_FLASHCARD,
    CREATE_FLASHCARD_SET_INIT_INFO,
    ElasticSearchTypes,
    ElasticSearchColumns
} from "../../../../actions/types";
import {Modal, Button} from "react-bootstrap";
import {browserHistory} from "react-router";
import ManageActivityActions from "../../../../actions/manage-activities-actions";
import ProgressIndicator from "../../../progress-indicator/progress-indicator";
import Validator from "validator";
import config from "../../../../config";
import ManageCourseActions from "../../../../actions/manage-courses-actions";
import ElasticSearchActions from "../../../../actions/elastic-search-actions";
import ElasticSearchQuery from "../../../../actions/elastic-search-query";
import ClevertapReact from "clevertap-react";
import isEmpty from "lodash/isEmpty";


class CreateStudentFlashCardActivity extends Component {

    tableName = 'glossary'+ config.stage;
    activityTableName = 'activity' + config.stage;
    ElasticSearchStore = null;

    constructor(props) {
        super(props);
        this.flashCards = props.flashCards;
        this.getAllSubjects = this.getAllSubjects.bind(this);
        this.onActivityNameChange = this.onActivityNameChange.bind(this);
        this.onActivityStateChange = this.onActivityStateChange.bind(this);
        this.checkEnterKeyPress = this.checkEnterKeyPress.bind(this);
        this.inputTags = this.inputTags.bind(this);
        this.deleteTags = this.deleteTags.bind(this);
        this.selectFlashCardSearchOnChangeHandler = this.selectFlashCardSearchOnChangeHandler.bind(this);
        this.selectedFlashCardClearAllOnClickHandler = this.selectedFlashCardClearAllOnClickHandler.bind(this);
        this.selectedFlashCardPreviewOnClickHandler = this.selectedFlashCardPreviewOnClickHandler.bind(this);
        this.saveAndExitClickHandler = this.saveAndExitClickHandler.bind(this);
        this.saveAndAssignClickHandler = this.saveAndAssignClickHandler.bind(this);
        this.generateTagHtml = this.generateTagHtml.bind(this);
        this.showIndicator = this.showIndicator.bind(this);
        this.hideIndicator = this.hideIndicator.bind(this);
        this.cancelCreate = this.cancelCreate.bind(this);
        this.getRandomFlashcardBoxDialog = this.getRandomFlashcardBoxDialog.bind(this);
        this.showRandomFlashcardDialog = this.showRandomFlashcardDialog.bind(this);
        this.getSaveAndAssignDialog = this.getSaveAndAssignDialog.bind(this);
        this.onCourseChange = this.onCourseChange.bind(this);
        this.getCourse = this.getCourse.bind(this);
        this.onUnitChange = this.onUnitChange.bind(this);
        this.onAssessmentChange = this.onAssessmentChange.bind(this);
        this.getCreateActivityInfoDialog = this.getCreateActivityInfoDialog.bind(this);
        this.showCreateActivityDialog = this.showCreateActivityDialog.bind(this);
        this.saveAndExit = this.saveAndExit.bind(this);
        this.getNextPage = this.getNextPage.bind(this);
        this.getPreviewDialog = this.getPreviewDialog.bind(this);

        this.ElasticSearchStore = new ElasticSearchStore();

        this.state = {
            errors: {},
            activeOwnerFilter: 'learnerpal',
            showPreviewDialog: false,
            showFlashCardErrorDialog: false,
            loading: false,
            showRandomFlashcardDialogBox: false,
            showSaveAndAssignDialog:false,
            selectedCourse:null,
            showCreateInfoDialog:true,
            showAddToCourse: false,
            loadingNextPage:0,
            activityName:''
        }
    }

    getNextPage() {
        // const asd = ReactDOM.findDOMNode(this.refs.flashcardSelectionBoxRef);

        // if (asd.scrollHeight - asd.scrollTop < 300 )
        // {
        //     if (this.state.loadingNextPage === 0) {
                console.log("scrolling");
                let myID = Math.random() * 10000000000000000000;
                this.setState({'loadingNextPage': myID});
                if (this.state.loadingNextPage === myID) {
                    let esAttr = this.props.elasticSearchAttributes.map.get(" create-flashcard-activity-student");
                    const {dispatch} = this.props;
                    ElasticSearchActions.setElasticSearchAttributes(dispatch, " create-flashcard-activity-student", esAttr.searchTerm, esAttr.pageSize + 10, esAttr.activePage, esAttr.sortObj, esAttr.filterValues);
                    let elasticSearchQuery = new ElasticSearchQuery();
                    elasticSearchQuery.query(ElasticSearchTypes.Glossary + config.stage, ElasticSearchColumns.Glossary, this.props,
                        esAttr.searchTerm, esAttr.pageSize + 10, esAttr.activePage, esAttr.sortObj, esAttr.filterValues, function (results, totalRecords) {
                            dispatch({
                                type: CREATE_ACTIVITY_FETCH_FLASHCARD_SUCCESS,
                                status: 200,
                                data: JSON.parse(JSON.stringify(results)),
                                alertText: '',
                                alertStyle: '',
                                showAlert: false,
                                totalRecords: totalRecords
                            });
                        });
                    this.setState({'loadingNextPage': 0});
                }
        //     }
        // }
    }

    cancelCreate() {
        browserHistory.push('/activities');
    }

    showRandomFlashcardDialog()
    {

        this.setState({showRandomFlashcardDialogBox: true});
    }

    generateTagHtml(tags, tagType) {

        let context = this;
        let html = [];
        tags.forEach(tag => {
            html.push(<span className="keywordTag" key={"keyword" + tag}>
                        <input type="hidden" name={"keyWord" + tag} value={tag}/> {tag}
                <span className="text-cross-icon" onClick={ evt => {
                    context.deleteTags(evt, tagType, tag);
                }}>x</span>
            </span>)
        });
        return html;
    }


    componentDidMount() {

        let {dispatch} = this.props;
        ElasticSearchActions.setElasticSearchAttributes(dispatch," create-flashcard-activity-student",'', parseInt($('#pageSizeDropDown').val())?parseInt($('#pageSizeDropDown').val()):10, 1 ,{_score:{order: "desc"}}, []);

        let context = this;
        let visibility = '(public OR "' + this.props.auth.user.userName + '"';
        if ( this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName.toLowerCase() === "learnerpal") {
            visibility += " OR LPAdmin";
        }
        visibility += ')';
        ManageCourseActions.fetchAllCourses(dispatch, this.props.auth.user.userName, 'course'+config.stage, {
            'query_string' : {
                'fields' : ['visibleTo'],
                'query' : 'visibleTo : ' + visibility
            }
        });
        LoginUtils.isAuthenticated(context.props, browserHistory, '/activities/flashcard/new', '/');
        context.openSelected();
        if (!(context.props.manageActivities && context.props.manageActivities.createActivityFlashCardAllSubjects && context.props.manageActivities.createActivityFlashCardAllSubjects.length === 0)) {
            context.getAllSubjects();
        }
    }

    getAllFlashCards(callback) {

        let context = this;
        let {dispatch} = this.props;
        ElasticSearchActions.setElasticSearchAttributes(dispatch,'question'.toString(),'', parseInt($('#pageSizeDropDown').val())?parseInt($('#pageSizeDropDown').val()):10, 1 ,{_score:{order: "desc"}}, []);
        let elasticSearchQuery  = new ElasticSearchQuery();
        elasticSearchQuery.query(ElasticSearchTypes.Glossary+config.stage, ElasticSearchColumns.Glossary, this.props,
            '', parseInt($('#pageSizeDropDown').val())?parseInt($('#pageSizeDropDown').val()):10, 1 ,{_score:{order: "desc"}}, [], function (results, totalRecords) {
                dispatch({
                    type: CREATE_ACTIVITY_FETCH_FLASHCARD_SUCCESS,
                    data: results,
                    createActivitySelectedFlashCards: context.props.manageActivities && context.props.manageActivities.createActivitySelectedFlashCards ? context.props.manageActivities.createActivitySelectedFlashCards : [],
                    createActivityActiveOwnerFilter: 'LearnerPal',
                    skipAlterForAllRecords: false
                });
                if (callback && typeof callback === 'function') {
                    callback(results);
                }
            });
    }

    selectFlashCardSearchOnChangeHandler(evt) {
        let term = evt.nativeEvent.target.value;
        let esAttr = this.props.elasticSearchAttributes.map.get(" create-flashcard-activity-student");
        const {dispatch} = this.props;
        let elasticSearchQuery = new ElasticSearchQuery();
        ElasticSearchActions.setElasticSearchAttributes(dispatch," create-flashcard-activity-student",term, esAttr.pageSize, esAttr.activePage, esAttr.sortObj, esAttr.filterValues);
        elasticSearchQuery.query(ElasticSearchTypes.Glossary + config.stage, ElasticSearchColumns.Glossary, this.props,
            term, esAttr.pageSize, esAttr.activePage, esAttr.sortObj, [], function (results, totalRecords) {
                dispatch({
                    type: CREATE_ACTIVITY_FETCH_FLASHCARD_SUCCESS,
                    status: 200,
                    data: JSON.parse(JSON.stringify(results)),
                    alertText: '',
                    alertStyle: '',
                    showAlert: false,
                    totalRecords: totalRecords,
                    createActivityFlashCardClearFilters: true,
                });
            });
        // if (this.props.manageActivities && this.props.manageActivities.createActivityAllFlashCards) {
        //
        //     this.props.manageActivities.createActivityAllFlashCards.forEach(question => {
        //         if (question.keywords && question.keywords.toString().toLowerCase().indexOf(term.trim().toLowerCase()) > -1) {
        //             searchResults.push(question);
        //         }
        //     })
        // }
        //
        // let {dispatch} = this.props;
        // dispatch({
        //     type: CREATE_ACTIVITY_MOVE_FLASHCARD,
        //     skipAlterForAllRecords: true,
        //     createActivityFlashCardSearchResults: searchResults,
        //     createActivityFlashCardClearFilters: true,
        //     createActivityQuestions: searchResults
        // });
    }

    selectedFlashCardClearAllOnClickHandler() {
        let {dispatch} = this.props;
        let flashCards = this.props.manageActivities && this.props.manageActivities.createActivityFlashCards ? this.props.manageActivities.createActivityFlashCards : [];
        let selectedFlashCards = this.props.manageActivities && this.props.manageActivities.createActivitySelectedFlashCards ? this.props.manageActivities.createActivitySelectedFlashCards : [];
        flashCards = flashCards.concat(selectedFlashCards);
        dispatch({
            type: CREATE_ACTIVITY_MOVE_FLASHCARD,
            createActivityFlashCards: flashCards,
            createActivitySelectedFlashCards: []
        });
    }

    selectedFlashCardPreviewOnClickHandler() {
        this.setState({
            showPreviewDialog: true
        })
    }

    validateInput(data, allRecords, ownerName,editId) {
        let errors = {};

        let activityName = data.activityName ? data.activityName.trim().toLowerCase() : '';
        var id = activityName.replace(/\s|\W|[#$%^&*()'.]/g, "") + "_" + ownerName.toLowerCase();
        if (!data.activityName || Validator.isEmpty(data.activityName.trim())) {
            errors.activityName = 'This field is required';
        }

        if (!data.subjects || data.subjects.length === 0) {
            errors.subjectError = 'This field is required';
        }

        return {
            errors,
            isValid: isEmpty(errors)
        }
    }

    isValid(data) {
        let allRecords = this.props.manageActivities.createActivityFlashCards ? this.props.manageActivities.createActivityFlashCards : [];
        const {errors, isValid} = this.validateInput(data, allRecords, this.props.auth.user.userName, this.props.params && false ? this.props.params.id : null);
        if (!isValid) {
            this.setState({errors});
        }
        return isValid;
    }


    showIndicator() {
        this.setState({
            ...this.state,
            loading: true
        });
    }

    hideIndicator() {
        this.setState({
            ...this.state,
            loading: false
        });
    }

    saveAndExitClickHandler(skipTransition) {
        return new Promise((resolve, reject) => {

            console.log('Save and Exit.');
            let flashCards = this.props.manageActivities.createActivitySelectedFlashCards ? this.props.manageActivities.createActivitySelectedFlashCards : [];
            let qIds = [];
            flashCards.forEach(q => {
                qIds.push(q.word);
            });
            let data = {
                orderBy: '1',
                schoolName: this.props.auth.user.schoolName,
                ownerName: this.props.auth.user.userName,
                activityType: 'flashcards',
                activityName: this.state.activityName,
                tags: this.props.manageActivities.createActivityFlashCardTaggedKeywords ? this.props.manageActivities.createActivityFlashCardTaggedKeywords : [],
                subjects: this.props.manageActivities.createActivityFlashCardTaggedSubjects ? this.props.manageActivities.createActivityFlashCardTaggedSubjects.sort() : [],
                activityState: this.props.manageActivities.createActivityFlashCardActivityState ? this.props.manageActivities.createActivityFlashCardActivityState : 'In Progress',
                words: qIds
            };
            if (this.props.params.id) {
                //data.activityID = this.props.params.id;
            }
            if (this.isValid(data)) {

                console.log('Validation successful. saving activity.');
                let params = {};
                let pathTemplate = '/activity';
                let method = 'POST';
                let additionalParams = {};
                let context = this;
                let body = data;
                context.showIndicator();
                apiClient.invokeApi(params, pathTemplate, method, additionalParams, body).then(function (result) {

                    ClevertapReact.event("Create Flashcards Deck", {'userName' : context.props.auth.user.userName});
                    context.dispatchCreateFlashCardFields();
                    resolve({saved:true, status:'Success', data:result.data});
                    context.hideIndicator();
                    context.setState({alertStyle:'success', alertText:result.data, showAlert:true});
                }).catch(function (result) {

                    context.hideIndicator();
                    reject({saved:false, status:'Error'});
                    setTimeout(()=> {

                        const {dispatch}  = context.props;
                        ManageActivityActions.setMessage(dispatch,true,result.data.message,'success', function () {
                            context.hideIndicator();
                        });
                    }, 2000);
                });
            } else {
                this.setState({showCreateInfoDialog:true})
            }
        });
    }

    onCourseChange(evt){
        let value = evt.target.value;
        let that = this;
        this.setState({isMandotary:true}, function () {
            that.getCourse(value);
        });
    }

    getCourse(id) {
        let context = this;

        context.showIndicator();
        if (id) {
            let params = {
                courseID: id,
            };
            let pathTemplate = '/course/{courseID}';
            let method = 'GET';
            let additionalParams = {};
            let body = {};

            apiClient.invokeApi(params, pathTemplate, method, additionalParams, body).then(function (result) {

                context.setState({
                    ...context.state,
                    selectedCourse: result.data,
                    loading: false
                });
            }).catch(function (error) {
                context.hideIndicator();
            });
        }
    }

    onUnitChange(evt){

        if(evt.target.value){
            let selectedUnit = this.state.selectedCourse.units.filter(unit => unit.unitID === evt.target.value)[0];
            this.setState({...this.state, selectedUnit: selectedUnit});
        }
    }

    onAssessmentChange(evt){

        if(evt.target.value){
            this.setState({...this.state, selectedAssessment: evt.target.value});
        }
    }

    getSaveAndAssignDialog() {
        let courses = this.props.manageCourses.results;
        let courseList = courses.map(function (c) {
            return {name: c.courseName, id: c.id};
        });
        let selectedCourse = this.state.selectedCourse ? this.state.selectedCourse.courseID : '';
        let unitList = this.state.selectedCourse && this.state.selectedCourse.units ? this.state.selectedCourse.units.map(function (c) {
            return {name: c.unitName, id: c.unitID};
        }) : [];
        let selectedUnit = this.state.selectedUnit ? this.state.selectedUnit.unitID : '';

        let body = <div id="saveAndAssignDialogContent">
            {this.state.courseAssignError &&
            <p style={{color: 'red'}}>Please select a course and unit. For question activity type, Assessment is
                required.</p>}
            <div className="mainFormBox">
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <SelectFieldGroup
                            error={this.state.errors.selectedCourse}
                            field="selectedCourse"
                            value={selectedCourse}
                            options={courseList}
                            label="Select Course:"
                            placeholder="Select Course to Assign"
                            onChange={this.onCourseChange}
                        />
                    </div>
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <SelectFieldGroup
                            error={this.state.errors.selectedUnit}
                            field="selectedUnit"
                            value={selectedUnit}
                            options={unitList}
                            label="Select Unit:"
                            placeholder="Select Unit"
                            onChange={this.onUnitChange}
                        />
                    </div>
                </div>
            </div>
        </div>;
        return <Modal
            show={this.state.showSaveAndAssignDialog}
            onHide={()=> {
                this.setState({
                    showSaveAndAssignDialog: false
                });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Activity added, do you want to add this activity to a course? (optional)</Modal.Title>
            </Modal.Header>
            {this.state.showAddToCourse && <Modal.Body>
                {body}
            </Modal.Body>
            }
            <Modal.Footer>
                {!this.state.showAddToCourse && <Button className="rightMarginForModelButtons" onClick={()=> {
                    {

                        browserHistory.push({
                            pathname: '/assignments/assign',
                            state: {currentActivityObj: this.state.payloadForAssignCourse.activity, currentActivity: this.state.payloadForAssignCourse.activity.activityID, view:'activity'}
                        });
                    }
                }}>No</Button>}
                {this.state.showAddToCourse && <Button className="rightMarginForModelButtons" onClick={()=> {
                    browserHistory.push('/activities');
                }}>Cancel</Button>}
                {!this.state.showAddToCourse &&
                <Button className="rightMarginForModelButtons" onClick={()=> {
                    this.setState({'showAddToCourse': true});
                }} bsStyle='primary'>Yes</Button>
                }
                {this.state.showAddToCourse &&
                <Button className="rightMarginForModelButtons" disabled={!this.state.payloadForAssignCourse}
                        onClick={()=> {

                            if (this.state.isMandotary)
                            {
                                if (this.state.saveAndExit)
                                {
                                    let context = this;
                                    let payloadForAssignCourse = context.state.payloadForAssignCourse;

                                    if (!context.state.selectedCourse || !context.state.selectedUnit || ((payloadForAssignCourse.activity.activityType.toLowerCase() === 'question' || payloadForAssignCourse.activity.activityType.toLowerCase() === 'questions') && !context.state.selectedAssessment)) {
                                        context.setState({courseAssignError: true});
                                    } else {
                                        context.showIndicator();
                                        context.setState({courseAssignError: false});
                                        payloadForAssignCourse.courseID = context.state.selectedCourse.courseID;
                                        payloadForAssignCourse.unitID = context.state.selectedUnit.unitID;
                                        payloadForAssignCourse.assessmentType = context.state.selectedAssessment;
                                        payloadForAssignCourse.ownerName = context.props.auth.user.userName;

                                        let pathTemplate = '/course/assignment';
                                        let method = 'PUT';
                                        let additionalParams = {};
                                        apiClient.invokeApi(null, pathTemplate, method, additionalParams, payloadForAssignCourse,'2').then(function (result) {
                                            context.hideIndicator();
                                            const {dispatch} = this.props;
                                            ManageActivityActions.setMessage(dispatch, true, result.data, 'success', function () {
                                                browserHistory.push('/activities');
                                            });
                                        }).catch(function (result) {
                                            context.hideIndicator();
                                            const {dispatch} = this.props;
                                            ManageActivityActions.setMessage(dispatch, true, result.data, 'success', function () {
                                                browserHistory.push('/activities');
                                            });
                                        });
                                    }
                                }
                                else
                                {
                                    let context = this;
                                    let payloadForAssignCourse = context.state.payloadForAssignCourse;

                                    if (!context.state.selectedCourse || !context.state.selectedUnit || ((payloadForAssignCourse.activity.activityType.toLowerCase() === 'question' || payloadForAssignCourse.activity.activityType.toLowerCase() === 'questions') && !context.state.selectedAssessment)) {
                                        context.setState({courseAssignError: true});
                                    } else {
                                        context.showIndicator();
                                        context.setState({courseAssignError: false});
                                        payloadForAssignCourse.courseID = context.state.selectedCourse.courseID;
                                        payloadForAssignCourse.unitID = context.state.selectedUnit.unitID;
                                        payloadForAssignCourse.assessmentType = context.state.selectedAssessment;
                                        payloadForAssignCourse.ownerName = context.props.auth.user.userName;

                                        let pathTemplate = '/course/assignment';
                                        let method = 'PUT';
                                        let additionalParams = {};
                                        apiClient.invokeApi(null, pathTemplate, method, additionalParams, payloadForAssignCourse,'2').then(function (result) {
                                            context.hideIndicator();
                                            browserHistory.push({
                                                pathname: '/assignments/assign',
                                                state: {assessmentType:context.state.selectedAssessment,currentActivityObj: context.state.payloadForAssignCourse.activity, currentActivity: context.state.payloadForAssignCourse.activity.activityID, view:'activity'}
                                            });
                                        }).catch(function (result) {
                                            context.hideIndicator();
                                            browserHistory.push({
                                                pathname: '/assignments/assign',
                                                state: {assessmentType:context.state.selectedAssessment,currentActivityObj: context.state.payloadForAssignCourse.activity, currentActivity: context.state.payloadForAssignCourse.activity.activityID, view:'activity'}
                                            });
                                        });
                                    }
                                }
                            }
                            else
                            {
                                if (this.state.saveAndExit)
                                {
                                    const {dispatch} = this.props;
                                    ManageActivityActions.setMessage(dispatch, true, 'Activity has been created successfully', 'success', function () {
                                        browserHistory.push('/activities');
                                    });
                                }
                                else
                                {
                                    browserHistory.push({
                                        pathname: '/assignments/assign',
                                        state: {assessmentType:this.state.selectedAssessment,currentActivityObj: this.state.payloadForAssignCourse.activity, currentActivity: this.state.payloadForAssignCourse.activity.activityID, view:'activity'}
                                    });
                                }
                            }
                        }} bsStyle='primary'>{!this.state.isMandotary && this.state.saveAndExit && 'Exit'} {this.state.isMandotary && this.state.saveAndExit && 'Add to Course and Exit'} {this.state.isMandotary && !this.state.saveAndExit &&  'Add to Course and Assign'} {!this.state.isMandotary && !this.state.saveAndExit &&  'Assign'} </Button>}
            </Modal.Footer>
        </Modal>;
    }

    dispatchCreateFlashCardFields() {
        let {dispatch} = this.props;
        dispatch({
            type: CREATE_FLASHCARD_SET_INIT_INFO,
            taggedKeywordsHtml: [],
            taggedKeywords: [],
            taggedSubjectsHtml: [],
            taggedSubjects: [],
            allSubjects: this.state.allSubjects,
            activityType: '',
            activityName: '',
            selected: [],
            activityState: ''
        })
    }

    saveAndAssignClickHandler() {

        let context = this;
        //context.showIndicator();
        context.saveAndExitClickHandler(true).then(result => {

            context.setState({
                loading:false
            });
            browserHistory.push({pathname: '/student/selfstudy/'+'true', state:{showAlert:true, alertText:result.data.message, alertStyle:'success'}});
        }).catch(result=> {


        });
    }

    moveToSelectedFlashCard(flashCard) {
        let {dispatch} = this.props;
        let selected = this.props.manageActivities && this.props.manageActivities.createActivitySelectedFlashCards ? this.props.manageActivities.createActivitySelectedFlashCards : [];
        let flashCards = this.props.manageActivities && this.props.manageActivities.createActivityFlashCards ? this.props.manageActivities.createActivityFlashCards : [];
        let newFlashCards = [];
        flashCards.forEach(Q => {
            if (Q.word !== flashCard.word) {
                newFlashCards.push(Q);
            }
        });

        selected.push(flashCard);
        dispatch({
            type: CREATE_ACTIVITY_MOVE_FLASHCARD,
            createActivityFlashCards: newFlashCards,
            createActivitySelectedFlashCards: selected
        });
        $('.openSelBox').html('View Selected Flashcards');
    }

    moveToFlashCard(flashCard) {
        let {dispatch} = this.props;
        let flashCards = this.props.manageActivities && this.props.manageActivities.createActivityFlashCards ? this.props.manageActivities.createActivityFlashCards : [];
        let selectedFlashCards = this.props.manageActivities && this.props.manageActivities.createActivitySelectedFlashCards ? this.props.manageActivities.createActivitySelectedFlashCards : [];
        let newSelectedFlashCards = [];
        selectedFlashCards.forEach(Q => {
            if (Q.word !== flashCard.word) {
                newSelectedFlashCards.push(Q);
            }
        });
        flashCards.push(flashCard);
        dispatch({
            type: CREATE_ACTIVITY_MOVE_FLASHCARD,
            createActivityFlashCards: flashCards,
            createActivitySelectedFlashCards: newSelectedFlashCards
        });
        if (selectedFlashCards.length === 0) {
            $('.openSelBox').html('View Selected Flashcards');
        }
    }

    onActivityNameChange(e)
    {
        this.setState({activityName:e.target.value});
        // let context = this;
        // let {dispatch} = context.props;
        // dispatch({
        //     type: CREATE_ACTIVITY_MOVE_FLASHCARD,
        //     createActivityFlashCards: context.props.manageActivities.createActivityFlashCards,
        //     createActivitySelectedFlashCards: context.props.manageActivities.createActivitySelectedFlashCards,
        //     createActivityFlashCardActivityName: e.target.value
        // });
    }

    onActivityStateChange(e) {
        let context = this;
        let {dispatch} = context.props;
        dispatch({
            type: CREATE_ACTIVITY_MOVE_FLASHCARD,
            createActivityFlashCards: context.props.manageActivities.createActivityFlashCards,
            createActivitySelectedFlashCards: context.props.manageActivities.createActivitySelectedFlashCards,
            createActivityFlashCardActivityState: e.target.value
        });
    }

    checkEnterKeyPress(e) {
        if (e.key === 'Enter') {
            if (e.target.id === 'keywords') {
                this.inputTags(e);
            } else {
                this.selectFlashCardSearchOnChangeHandler(e);
            }

        }
    }

    inputTags(event) {

        let context = this;
        let {dispatch} = context.props;
        let taggedSubjectsHtml = context.props.manageActivities && context.props.manageActivities.createActivityFlashCardTaggedSubjectsHtml ? context.props.manageActivities.createActivityFlashCardTaggedSubjectsHtml : [];
        let taggedKeywordsHtml = context.props.manageActivities && context.props.manageActivities.createActivityFlashCardTaggedKeywordsHtml ? context.props.manageActivities.createActivityFlashCardTaggedKeywordsHtml : [];
        let taggedKeywords = context.props.manageActivities && context.props.manageActivities.createActivityFlashCardTaggedKeywords ? context.props.manageActivities.createActivityFlashCardTaggedKeywords : [];
        let taggedSubjects = context.props.manageActivities && context.props.manageActivities.createActivityFlashCardTaggedSubjects ? context.props.manageActivities.createActivityFlashCardTaggedSubjects : [];


        if (event.target.id === 'keywords' && event.target.value !== '') {
            let value = event.target.value;
            let uniqueKeyForTag = "keyword" + value + "_" + Math.random() * 10000000000000000000;
            if (!taggedKeywords.includes(value)) {
                dispatch({
                    type: CREATE_ACTIVITY_MOVE_FLASHCARD,
                    createActivityFlashCardTaggedKeywordsHtml: taggedKeywordsHtml.concat(
                        <span className="keywordTag" key={uniqueKeyForTag}>
                <input type="hidden" name={"keyWord" + value} value={value}/> {value}
                            <span className="text-cross-icon" onClick={ evt => {
                                context.deleteTags(evt, 'keyword', value);
                            }}>x</span>
                </span>),
                    createActivityFlashCardTaggedKeywords: taggedKeywords.concat(value)
                });
            }
            event.target.value = "";
        }
        else if (event.target.name === 'keywordsDrop') {
            //let keyword = event.target.selectedOptions[0].value;
            let keyword = event.nativeEvent.srcElement.value;
            let uniqueKeyForSubjectTag = "keyword" + keyword + "_" + Math.random() * 10000000000000000000;
            if (keyword !== '' && keyword !== 'Select Subject') {
                if (!taggedSubjects.includes(keyword)) {
                    dispatch({
                        type: CREATE_ACTIVITY_MOVE_FLASHCARD,
                        createActivityFlashCardTaggedSubjectsHtml: taggedSubjectsHtml.concat(
                            <span className="keywordTag" key={uniqueKeyForSubjectTag}>
                <input type="hidden" name={"keyWord" + keyword} value={keyword}/> {keyword}
                                <span className="text-cross-icon" onClick={evt => {
                                    context.deleteTags(evt, 'subject', keyword)
                                }}>x</span>
                </span>),
                        createActivityFlashCardTaggedSubjects: taggedSubjects.concat(keyword)
                    });
                }
            }
        }
    }

    deleteTags(event, tagType, value) {

        let {dispatch} = this.props;
        if (tagType === 'keyword') {
            let records = this.props.manageActivities.createActivityFlashCardTaggedKeywords;
            let htmls = this.props.manageActivities.createActivityFlashCardTaggedKeywordsHtml;
            let newKeywords = [];
            let newHtmls = [];
            records.forEach(record => {
                if (value.trim().toLowerCase() !== record.trim().toLowerCase()) {
                    newKeywords.push(record);
                }
            });
            htmls.forEach(html => {
                if (html.props.children[0].props.value.trim().toLowerCase() !== value.trim().toLowerCase()) {
                    newHtmls.push(html)
                }
            });
            dispatch({
                type: CREATE_ACTIVITY_MOVE_FLASHCARD,
                createActivityFlashCardTaggedKeywords: newKeywords,
                createActivityFlashCardTaggedKeywordsHtml: newHtmls
            });
        } else {
            let records = this.props.manageActivities.createActivityFlashCardTaggedSubjects;
            let htmls = this.props.manageActivities.createActivityFlashCardTaggedSubjectsHtml;
            let newKeywords = [];
            let newHtmls = [];
            records.forEach(record => {
                if (value.trim().toLowerCase() !== record.trim().toLowerCase()) {
                    newKeywords.push(record);
                }
            });
            htmls.forEach(html => {
                if (html.props.children[0].props.value.trim().toLowerCase() !== value.trim().toLowerCase()) {
                    newHtmls.push(html)
                }
            });
            dispatch({
                type: CREATE_ACTIVITY_MOVE_FLASHCARD,
                createActivityFlashCardTaggedSubjects: newKeywords,
                createActivityFlashCardTaggedSubjectsHtml: newHtmls
            });
        }
    }

    getPreviewDialog() {

        let words = [];
        let subjects = [];
        let flashCards = [];
        let kCount = 0;
        let sCount = 0;
        let qCount = 0;
        if (this.props.manageActivities && this.props.manageActivities.createActivityFlashCardTaggedKeywords) {
            this.props.manageActivities.createActivityFlashCardTaggedKeywords.forEach(keyword=> {
                words.push(<span className="keywordTag" key={'keyword-preview-' + kCount}>
                <input type="hidden" name="tags[]" value="ActionScript"/>{keyword}</span>);
                kCount++;
            });
        }
        if (this.props.manageActivities && this.props.manageActivities.createActivityFlashCardTaggedSubjects) {
            this.props.manageActivities.createActivityFlashCardTaggedSubjects.forEach(subject=> {
                subjects.push(<span className="keywordTag" key={'subject-preview-' + sCount}>
                <input type="hidden" name="tags[]" value="ActionScript"/>{subject}</span>);
                sCount++;
            });
        }
        if (this.props.manageActivities && this.props.manageActivities.createActivitySelectedFlashCards) {
            this.props.manageActivities.createActivitySelectedFlashCards.forEach(flashCard=> {
                let parser = new DOMParser();
                let text = <div className="listNumQuestion">
                    <h4>{flashCard.translatedWord ? flashCard.translatedWord : flashCard.word}</h4>
                    <p >{flashCard.definition}</p>
                </div>;
                let tags = [];
                if (flashCard.tags) {
                    flashCard.tags.forEach(tag=> {
                        tags.push(<li key={tag + Math.random()}>{tag}</li>)
                    });
                }
                flashCards.push(<div className="fieldBox FlashCardChoiceBox width_100" key={'flashCard-preview-' + qCount}>
                    {text}
                    <ul className="keyCourse">
                        {tags}
                    </ul>
                </div>);
                qCount++;
            });
        }
        let name = this.props.manageActivities && this.props.manageActivities.createActivityFlashCardActivityName ? this.props.manageActivities.createActivityFlashCardActivityName : '';
        let body = <div id="deleteDialogContent">
            <div className="modal-body">
                <div className="FrontQuestionBox">
                    <div className="questionBlock NiceScroll">
                        <h3 className="noteHeading">Selected Flashcards</h3>
                        {flashCards}
                    </div>
                    {/*<div className="clearfix"/>*/}
                    {/*<br/>*/}
                    {/*<h3>Keywords</h3>*/}
                    {/*{words}*/}
                    {/*<div className="clearfix"/>*/}
                    {/*<br/>*/}
                    <h3>Associated Subjects</h3>
                    {subjects}
                    <div className="clearfix"/>
                </div>
            </div>
        </div>;
        return <Modal id="PreviewModal"
                      show={this.state.showPreviewDialog}
                      onHide={()=> {
                          this.setState({
                              showPreviewDialog: false
                          })
                      }}
                      container={this}
                      aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title
                    id="delete-activity-modal">{name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div id="questionPreviewBox">
                    {flashCards}
                    <div className="row"></div>
                </div>
            </Modal.Body>
        </Modal>;
    }

    getFlashCardSelectionErrorDialog() {
        return <Modal id="FlashCardSelectionErrorModal"
                      show={this.state.showFlashCardErrorDialog}
                      onHide={()=> {
                          this.setState({
                              showFlashCardErrorDialog: false
                          })
                      }}
                      container={this}
                      aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title
                    id="delete-activity-modal">{'Error'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {<div className="text-danger">
                    <br />
                    All selected flashCards must be of same type (Essay or Multiple Choice).
                    <br />
                </div>}
            </Modal.Body>
        </Modal>;
    }

    selectRandomFlashcards(count) {
        let esAttr = this.props.elasticSearchAttributes.map.get(" create-flashcard-activity-student");
        let elasticSearchQuery = new ElasticSearchQuery();
        let that = this;
        elasticSearchQuery.random(ElasticSearchTypes.Glossary + config.stage, ElasticSearchColumns.Glossary, this.props,
            esAttr.searchTerm, count, esAttr.filterValues, null, function (results, totalRecords) {

                let selected = JSON.parse(JSON.stringify(results));
                for (let i = 0; i < selected.length; i++) {
                    setTimeout(()=> {
                        that.moveToSelectedFlashCard(selected[i]);
                    }, 200);
                }
            });

        //
        // let esAttr = this.props.elasticSearchAttributes.map.get(" create-flashcard-activity-student");
        // let elasticSearchQuery = new ElasticSearchQuery();
        // let that = this;
        // elasticSearchQuery.random(ElasticSearchTypes.Glossary + config.stage, ElasticSearchColumns.Glossary, this.props,
        //     esAttr.searchTerm, count, esAttr.filterValues, null, function (results, totalRecords) {
        //         let selected = JSON.parse(JSON.stringify(results));
        //         for (let i = 0; i < selected.length; i++) {
        //             setTimeout(()=> {
        //                 that.moveToSelectedFlashCard(selected[i]);
        //             }, 200);
        //         }
        //     });

    }

    openSelected() {
        let ctx = this;
        $('.openSelBox').click(function (evt) {
            $('.questionSelectedBox').addClass('showSelQuesTab');
            $(evt.target).addClass('closeSelBox');
            $(evt.target).html('Select Flashcards');
            if ($('.openSelBox').hasClass('closeSelBox'))
                ctx.openFlashcards();
        });

    }

    openFlashcards() {
        let ctx = this;
        $('.closeSelBox').click(function (evt) {
            $('.questionSelectedBox').removeClass('showSelQuesTab');
            $(evt.target).removeClass('closeSelBox');
            $(evt.target).html('View Selected Flashcards');
            ctx.openSelected();
        })
    }

    getRandomFlashcardBoxDialog()
    {

        let body = <div className="randomSelection">
            <p>Select number of random flashcards to add to the activity <input className="form-control" type="text" id="randomQuestionInput" maxLength="4"/></p>

        </div>;
        let that = this;
        return <Modal
            show={this.state.showRandomFlashcardDialogBox}
            onHide={()=> {
                this.setState({showRandomFlashcardDialogBox: false});
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Select Random Flashcards</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={()=> {
                    that.setState({showRandomFlashcardDialogBox: false});
                }}>Cancel</Button>
                <Button className="btn btn-primary crtBtn" onClick={()=> {
                    this.setState({showRandomFlashcardDialogBox: false});
                    let count = $("#randomQuestionInput").val();
                    if (count && Validator.isInt(count)) {
                        this.selectRandomFlashcards(count);
                    }
                }}>Select Flashcards</Button>
            </Modal.Footer>
        </Modal>;
    }

    getCreateActivityInfoDialog()
    {
        const {errors} = this.state;
        let generatedKeywords = this.generateTagHtml(this.props.manageActivities && this.props.manageActivities.createActivityFlashCardTaggedKeywords ? this.props.manageActivities.createActivityFlashCardTaggedKeywords : [], 'keyword');
        let generatedSubjects = this.generateTagHtml(this.props.manageActivities && this.props.manageActivities.createActivityFlashCardTaggedSubjects ? this.props.manageActivities.createActivityFlashCardTaggedSubjects : [], 'subject');

        let allSubjects = this.state.allSubjects && this.state.allSubjects ? this.state.allSubjects : [];
        let activityStates = [
            {
                "id": "In Progress",
                "name": "In Progress"
            },
            {
                "id": "In Review",
                "name": "In Review"
            }
        ];

        if (this.props.auth.user.userType.toLowerCase() === "admin") {
            activityStates.push({
                "id": "Published",
                "name": "Published"
            })
        }
        let body =  <div className="mainFormBox">
            <div className="col-lg-12 col-md-12">
                <TextFieldGroup
                    error={errors.activityName}
                    isDisabled={''}
                    label="Activity Name:"
                    field="activityName"
                    value={this.state.activityName}
                    placeholder="Enter Activity Name"
                    onChange={this.onActivityNameChange}
                />
                {/*<div className="form-group">*/}
                {/*<label htmlFor="selectUser">Keywords:</label>*/}
                {/*<div className="posRel  selectWidth ">*/}
                {/*<div className="ui-widget">*/}
                {/*<input id="keywords" className="fieldBg keyWords form-control"*/}
                {/*onKeyUp={this.checkEnterKeyPress} onBlur={this.inputTags}*/}
                {/*data-input-feild="tags" placeholder="Enter Keyword"/>*/}
                {/*<div className="appendDiv">*/}
                {/*{generatedKeywords}*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*</div>*/}
                <SelectFieldGroup
                    error={errors.subjectError}
                    label="Subject:"
                    field="keywordsDrop"
                    value={this.state.keywordsDrop}
                    options={allSubjects}
                    placeholder="Associate with Subjects"
                    onChange={this.inputTags}
                    multiSelect={generatedSubjects}
                />
                {this.props.auth.user.schoolName === "LearnerPal" &&
                <SelectFieldGroup
                    error={errors.activityState}
                    label="Activity State:"
                    field="activityState"
                    value={this.props.manageActivities.createActivityFlashCardActivityName}
                    options={activityStates}
                    onChange={this.onActivityStateChange}
                />
                }
            </div>
            <div className="row"/>
        </div>;
        return <Modal
            show={this.state.showCreateInfoDialog}
            onHide={()=> {
                this.setState({showCreateInfoDialog: false});
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Activity Info</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn btn-primary crtBtn" onClick={()=> {
                    this.setState({showCreateInfoDialog: false});
                }}>Close</Button>
            </Modal.Footer>
        </Modal>;

    }

    showCreateActivityDialog()
    {
        this.setState({showCreateInfoDialog:true});
    }

    saveAndExit()
    {
        let that = this;
        this.saveAndExitClickHandler().then(result => {
            setTimeout(()=> {

                const {dispatch}  = that.props;
                ManageActivityActions.setMessage(dispatch,true,result.data.message,'success', function () {
                    that.hideIndicator();
                    browserHistory.push('/activities');
                });
            }, 2000);
        }).catch(result=> {
            setTimeout(()=> {

                const {dispatch}  = that.props;
                ManageActivityActions.setMessage(dispatch,true,result.data,'success', function () {
                    that.hideIndicator();
                    browserHistory.push('/activities');
                });
            }, 2000);
        });
    }

    getAllSubjects() {
        let context = this;
        context.showIndicator();
        let params = {
            schoolName: this.props.auth.user.schoolName,
            ownerName: this.props.auth.user.userName,
        };
        let pathTemplate = '/subjects/ownerName/{ownerName}/schoolName/{schoolName}';
        let method = 'GET';
        let additionalParams = {};
        let body = {};
        apiClient.invokeApi(params, pathTemplate, method, additionalParams, body).then(function (result) {
            let sortedSubjects = [];
            result.data.forEach(sub => {
                sortedSubjects.push({"id": sub.id, "name": sub.name});
            });
            sortedSubjects = sortedSubjects.sort(function (a, b) {
                return a.id > b.id;
            });
            context.setState({allSubjects: sortedSubjects, loading: false});
        }).catch(function (error) {
            context.hideIndicator();
        });
    }

    render() {
        const {errors} = this.state;
        this.filterComponentCssClassName = 'filterBlock custScroll default-skin';
        let flashCards = [];
        let selectedFlashCards = [];
        let context = this;
        if (this.props.manageActivities && this.props.manageActivities.createActivityFlashCards)
        {
            let skipFlashCards = this.props.manageActivities && this.props.manageActivities.createActivitySelectedFlashCards ? this.props.manageActivities.createActivitySelectedFlashCards : [];
            for (let i = 0; i < this.props.manageActivities.createActivityFlashCards.length; i++) {
                let flashCard = this.props.manageActivities.createActivityFlashCards[i];
                let text = flashCard.translatedWord ? flashCard.translatedWord : flashCard.word;
                let definition = flashCard.definition;
                let chkbox = "çheckbox-id-" + i;

                let tags = [];
                if (flashCard.tags) {
                    flashCard.tags.forEach(tag=> {
                        tags.push(<li key={tag + Math.random()}>{tag}</li>)
                    });
                }
                if (!skipFlashCards.includes(flashCard)) {

                    // if (this.props.manageActivities && this.props.manageActivities.createActivityFlashCardSearchTerm) {
                    //
                    //     let term = this.props.manageActivities.createActivityFlashCardSearchTerm;
                    //     if (text.toLowerCase().indexOf(term.trim().toLowerCase()) > -1  || flashCard.keywords.toString().indexOf(term.trim().toLowerCase()) > -1) {
                    //         flashCards.push(
                    //             <div className="fieldBox QuestionChoiceBox width_100" key={'flash-card-id-' + flashCard.id}>
                    //                 <input type="checkbox" id={chkbox} className="css-checkbox select_question"
                    //                        name="authorize" onClick={function () {
                    //                     context.moveToSelectedFlashCard(flashCard, this);
                    //                 }}/>
                    //                 <label htmlFor={chkbox} className="pay_label css-label1"/>
                    //                 <p className="quesText"><h5>{text}</h5></p>
                    //                 <ul className="keyCourse">
                    //                     {tags}
                    //                 </ul>
                    //             </div>
                    //         )
                    //     }
                    // } else {

                    let randomQid = Math.ceil(Math.random() * 10000);
                    flashCards.push(
                        <div className="fieldBox QuestionChoiceBox width_100"
                             key={'flash-card-id-' + randomQid + flashCard.word}>
                            <input type="checkbox" id={chkbox} className="css-checkbox select_question"
                                   name="authorize" onClick={function () {
                                context.moveToSelectedFlashCard(flashCard, this);
                            }}/>
                            <label htmlFor={chkbox} className="pay_label css-label1"/>
                            <p className="quesText"><h5>{text}</h5>
                            {definition}</p>
                            <ul className="keyCourse">
                                {tags}
                            </ul>
                        </div>
                    )
                    // }
                }
            }
            flashCards.push(<div style={{textAlign: 'center', paddingTop: '22px', display: 'inline-block', width: '100%'}}>
                <a href="javascript:void 0" onClick={function() {context.getNextPage()}} style={{border: '1px solid',
                borderRadius: '3px',
                'padding':' 5px 30px'}}>Load More</a>
              </div>);
        }

        if (this.props.manageActivities && this.props.manageActivities.createActivitySelectedFlashCards) {
            for (let i = 0; i < this.props.manageActivities.createActivitySelectedFlashCards.length; i++) {

                let flashCard = this.props.manageActivities.createActivitySelectedFlashCards[i];
                let text = flashCard.translatedWord ? flashCard.translatedWord : flashCard.word;
                let definition = flashCard.definition;
                let chkbox = "çheckbox-selected-id-" + i;

                let tags = [];
                if (flashCard.tags) {
                    flashCard.tags.forEach(tag=> {
                        tags.push(<li key={tag + Math.random()}>{tag}</li>)
                    });
                }

                selectedFlashCards.push(
                    <div className="fieldBox QuestionChoiceBox width_100" key={'selected-flashcard-id-' + flashCard.word + Math.random()}>
                        <input defaultChecked type="checkbox" id={chkbox} className="css-checkbox select_question"
                               name="authorize" onClick={function () {
                            context.moveToFlashCard(flashCard);
                        }}
                        />
                        <label htmlFor={chkbox} className="pay_label css-label1"/>
                        <p className="quesText"><h5>{text}</h5>
                        {definition}</p>
                        <ul className="keyCourse">
                            {tags}
                        </ul>
                    </div>
                )
            }
        }

        let selectedCount = this.props.manageActivities && this.props.manageActivities.createActivitySelectedFlashCards ? this.props.manageActivities.createActivitySelectedFlashCards.length : 0;
        let createActivityFlashCardActivityName = this.props.manageActivities.createActivityFlashCardActivityName;


        let readonlyField = <div className="form-group"><label className="control-label">Activity Name:</label>
            <div
                className="posRel fieldBg selectWidth">{this.props.manageActivities.createActivityFlashCardActivityName}</div>
        </div>;

        let randomSelectionBox = selectedFlashCards.length === 0 ? <div className="randomSelection">
            <p>Select flashcards from left panel by clicking on checkbox, Or select random flashcards.</p>
            <div className="flash-inputs">
                <input type="text" id="randomQuestionInput" maxLength="4"/>
            </div>
            <a href="javascript:void 0" onClick={()=> {
                var count = $("#randomQuestionInput").val();
                if (count && Validator.isInt(count)) {
                    context.selectRandomFlashcards(count);
                }
            }}>Select Random Flashcards
            </a>
        </div> : '';

        return (
            <div>
                {this.state.showRandomFlashcardDialogBox && this.getRandomFlashcardBoxDialog()}
                <Breadcrumb
                    breadcrumbsData={
                        [
                            {
                                name: 'Self Study', action: ()=> {
                                browserHistory.push('/student/selfstudy');
                            }
                            },
                            {
                                name: this.props.params && false ? 'Edit Flashcard Activity':'Create Flashcard Activity', action: ()=> {
                            }
                            }
                        ]
                    }
                />
                <span id="expColBtn" className="glyphicon btn-lg collapseBtn glyphicon-menu-down" aria-hidden="true" onClick={this.showCreateActivityDialog}/>
                <div className="addQuestionBlock" style={{display: 'block'}}>
                    <div className="row hidden-md hidden-lg sercFilBtnBox">
                        <div className="col-xs-6 noPad">
                            <button className="ViewFilter-sm" onClick={function () {
                                $('.filterBlock').addClass('TopZero');
                                $('html').css({'overflow': 'hidden', 'position': 'fixed'});
                                $('.footer ').hide();
                            }}><span>Filter</span></button>
                        </div>
                        <div className="col-xs-6 noPad">
                            <button className="ViewSearch-sm" onClick={function () {
                                $('.search-sm-Box').addClass('TopZero');
                                $('html').css({
                                    'overflow': 'hidden',
                                    'position': 'fixed',
                                    'width': '100%',
                                    'height': '100%'
                                });
                            }}><span>Search</span></button>
                        </div>
                        <div className="search-sm-Box">
                <span className="srcHideBox" onClick={function () {
                    $('.search-sm-Box').removeClass('TopZero');
                    $('html').css({'overflow': 'auto', 'position': 'inherit'});
                }}/>
                            <div className="input-group">
                                <input type="text" className="form-control searchBar" placeholder="Search"
                                       id="searchBarIdX"/>
                                <span className="input-group-btn">
                <button className="btn btn-primary" type="button" onClick={function () {
                    context.selectFlashCardSearchOnChangeHandler({nativeEvent: {target: {value: $("#searchBarIdX").val()}}});
                    $('.search-sm-Box').removeClass('TopZero');
                    $('html').css({'overflow': 'auto', 'position': 'inherit'});
                }}><span className="searchBtn"/></button>
                </span>
                            </div>
                        </div>
                    </div>
                    <div className="filterBlock custScroll default-skin" id="default-skin-demo">
                        <Filter
                            isOverRideStage={true}
                            isQuery={false}
                            location = " create-flashcard-activity-student"
                            actionName={CREATE_ACTIVITY_FETCH_FLASHCARD_SUCCESS}
                            viewActivityCssClass={this.filterComponentCssClassName}
                            clearFilters={'manageActivities.createActivityFlashCardClearFilters'}
                            screenNameForElasticSearch = {ElasticSearchTypes.StudentGlossary}
                            allColumnsForElasticSearch = {ElasticSearchColumns.StudentGlossary}
                            filterColumns={[
                                {name: 'subjects', type: 'array', label: 'Subject', dependency: 'category'},
                                {name: 'category', type: 'text', label: 'Category', dependent: true}
                            ]}
                        />
                    </div>
                    <div className="selectQuestionBox custScroll default-skin">
                        <div className="select-question-heading-container">
                            <h2 className="select-question-search-h2">Select Flashcards</h2>
                            <div className="input-group filterSearchLeft select-question-search">
                                <input type="text" className="form-control" placeholder="Search"
                                       onKeyPress={this.checkEnterKeyPress}/>
                                <span className="input-group-btn">
                </span>
                            </div>
                        </div>
                        <ul className="quesBcrumb">
                            <li><a href="javascript:void 0">
                                LearnerPal Flashcards</a></li>
                            <li><a href="javascript:void 0" disabled="disabled">My
                                Flashcards</a></li>
                            { this.props.manageActivities && this.props.manageActivities.createActivityFlashCards &&
                            <li style={{float:'right'}}><a id="randomQuestionSelectionButton" href="javascript:void 0" onClick={this.showRandomFlashcardDialog} style={{border: '1px solid',
                                borderRadius: '3px',
                                'padding':' 5px'}}>Select Random Flashcards</a></li>
                            }
                        </ul>
                        <div className="boxForQuestionSelect" id="sel_before" ref="flashcardSelectionBoxRef">
                            {flashCards}
                        </div>
                        <div className="lastBlockEmptyLeft"/>
                    </div>
                    <div className="questionSelectedBox custScroll default-skin">
                        <h2 className="select-question-search-h2">Flashcards Selected <span className="countResFix"><a
                            className="openSelBox" onClick={this.viewSelected}>View Selected Flashcards</a>
                <span className="selectedCount">{selectedCount}</span>
                </span>
                        </h2>
                        <div className="selQuestions">
                            <h4 className="selectedHeadingActivity">{createActivityFlashCardActivityName}</h4>
                            <ul className="quesBcrumb">
                                <li style={{float:'right'}}><a href="javascript:void 0" data-toggle="modal" data-target="#PreviewModal" onClick={this.selectedFlashCardPreviewOnClickHandler} style={{border: '1px solid',
                                    borderRadius: '3px',
                                    'padding':' 5px'}}>Preview</a></li>
                                <li style={{float:'right'}}><a href="javascript:void 0" id="clearAllQues" onClick={this.selectedFlashCardClearAllOnClickHandler}>Clear All</a></li>
                            </ul>
                        </div>
                        <div className="boxForQuestionSelect" id="sel_after">
                            {selectedFlashCards}
                        </div>
                        <div className="lastBlockEmptyLeft text-right">
                            <Button onClick={function(){ browserHistory.push('/student/flashcard/new/'+ this.props.params.id);}.bind(this)}
                                    bsStyle="cancel">
                                Cancel
                            </Button>
                            <Button onClick={()=>{this.setState({saveAndExit:true},this.saveAndAssignClickHandler)}}
                                    bsStyle='saveExitBtn'
                                    title={(!this.props.manageActivities.createActivitySelectedFlashCards
                                    || this.props.manageActivities.createActivitySelectedFlashCards.length === 0) ? 'Please add flashcards to the activity' : 'Save & Exit'}
                                    disabled={!this.props.manageActivities.createActivitySelectedFlashCards
                                    || this.props.manageActivities.createActivitySelectedFlashCards.length === 0}>
                                Exit
                            </Button>

                        </div>
                    </div>
                </div>
                {this.state.showPreviewDialog && this.getPreviewDialog()}
                {this.state.showFlashCardErrorDialog && this.getFlashCardSelectionErrorDialog()}
                {this.state.showSaveAndAssignDialog && this.getSaveAndAssignDialog()}
                <ProgressIndicator show={this.state.loading}/>
                {this.state.showCreateInfoDialog && this.getCreateActivityInfoDialog()}
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {...state};
}
export default connect(mapStateToProps)(CreateStudentFlashCardActivity);
