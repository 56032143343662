import React, { Component } from "react";
import { connect } from "react-redux";
import Breadcrumb from "../../../breadcrumb/breadcrumb";
import TextFieldGroup from "../../../forms/text-field-group";
import SelectFieldGroup from "../../../forms/select-field-group";
import apiClient from "../../../../actions/api-client";
import Filter from "../../../filter/filter";
import $ from "jquery";
import LoginUtils from "../../../common/login-utils";
import ElasticSearchStore from "../../../../actions/elastic-search-store";
import {
    CREATE_ACTIVITY_FETCH_FLASHCARD_SUCCESS,
    CREATE_ACTIVITY_FETCH_FLASHCARD_FAILED,
    CREATE_ACTIVITY_MOVE_FLASHCARD,
    CREATE_FLASHCARD_SET_INIT_INFO, ElasticSearchTypes, ElasticSearchColumns
} from "../../../../actions/types";
import { Modal, Button } from "react-bootstrap";
import { browserHistory ,withRouter} from "react-router";
import validateInput from "../../../../validations/create-question-activity";
import ManageActivityActions from "../../../../actions/manage-activities-actions";
import ProgressIndicator from "../../../progress-indicator/progress-indicator";
import Validator from "validator";
import config from "../../../../config";
import ManageCourseActions from "../../../../actions/manage-courses-actions";
import ElasticSearchActions from "../../../../actions/elastic-search-actions";
import ElasticSearchQuery from "../../../../actions/elastic-search-query";
import ClevertapReact from "clevertap-react";
import MaterialIcon from "react-google-material-icons";
import "./icon.css";
import classnames from "classnames";
// import axios from "axios";


class CreateFlashCardActivity extends Component {

    tableName = 'glossary' + config.stage;
    activityTableName = 'activity' + config.stage;
    ElasticSearchStore = null;

    constructor(props) {
        super(props);
        this.flashCards = props.flashCards;
        this.getAllSubjects = this.getAllSubjects.bind(this);
        this.onActivityNameChange = this.onActivityNameChange.bind(this);
        this.onActivityStateChange = this.onActivityStateChange.bind(this);
        this.checkEnterKeyPress = this.checkEnterKeyPress.bind(this);
        this.inputTags = this.inputTags.bind(this);
        this.deleteTags = this.deleteTags.bind(this);
        this.selectFlashCardSearchOnChangeHandler = this.selectFlashCardSearchOnChangeHandler.bind(this);
        this.selectedFlashCardClearAllOnClickHandler = this.selectedFlashCardClearAllOnClickHandler.bind(this);
        this.selectedFlashCardPreviewOnClickHandler = this.selectedFlashCardPreviewOnClickHandler.bind(this);
        this.saveAndExitClickHandler = this.saveAndExitClickHandler.bind(this);
        this.saveAndAssignClickHandler = this.saveAndAssignClickHandler.bind(this);
        this.generateTagHtml = this.generateTagHtml.bind(this);
        this.showIndicator = this.showIndicator.bind(this);
        this.hideIndicator = this.hideIndicator.bind(this);
        this.cancelCreate = this.cancelCreate.bind(this);
        this.getRandomFlashcardBoxDialog = this.getRandomFlashcardBoxDialog.bind(this);
        this.showRandomFlashcardDialog = this.showRandomFlashcardDialog.bind(this);
        this.getSaveAndAssignDialog = this.getSaveAndAssignDialog.bind(this);
        this.onCourseChange = this.onCourseChange.bind(this);
        this.getCourse = this.getCourse.bind(this);
        this.onUnitChange = this.onUnitChange.bind(this);
        this.getCreateActivityInfoDialog = this.getCreateActivityInfoDialog.bind(this);
        this.showCreateActivityDialog = this.showCreateActivityDialog.bind(this);
        this.saveAndExit = this.saveAndExit.bind(this);
        this.getNextPage = this.getNextPage.bind(this);
        this.onActivityDescriptionChange = this.onActivityDescriptionChange.bind(this);
        this.resetFlashcard = this.resetFlashcard.bind(this);
        this.changeLang = this.changeLang.bind(this)
        this.changeSides = this.changeSides.bind(this)
        this.check = this.check.bind(this)
        this.keepOnPage = this.keepOnPage.bind(this);
        this.selectQuestionMyQuestionOnClickHandler = this.selectQuestionMyQuestionOnClickHandler.bind(this);
        this.selectQuestionLearnerPalOnClickHandler = this.selectQuestionLearnerPalOnClickHandler.bind(this);
        this.route= this.props.route;
        this.router= this.props.router;

        this.ElasticSearchStore = new ElasticSearchStore();

        this.state = {
            leave : false,
            errors: {},
            activeOwnerFilter: 'learnerpal',
            showPreviewDialog: false,
            showFlashCardErrorDialog: false,
            loading: false,
            showRandomFlashcardDialogBox: false,
            showSaveAndAssignDialog: false,
            selectedCourse: null,
            showCreateInfoDialog: true,
            showAddToCourse: false,
            loadingNextPage: 0,
            seeTranslation: "",
            seeEnglish: "",
            isTranslated: false,
            createActivityQuestionActivityName: this.props.manageActivities.createActivityQuestionActivityName,
            myisTranslated: false,
            showingLPAdminFlashCards: false,
            showingMyFlashCards: false,
            showingAllFlashCards: true,
            // switcher: true,
            // arr: [],
            // my: true
        }
    }

    getNextPage() {
        // const asd = ReactDOM.findDOMNode(this.refs.flashcardSelectionBoxRef);

        // if (asd.scrollHeight - asd.scrollTop < 300 )
        // {
        //     if (this.state.loadingNextPage === 0) {
        //         console.log("scrolling");
        //         let myID = Math.random() * 10000000000000000000;
        //         this.setState({'loadingNextPage': myID});
        //         if (this.state.loadingNextPage === myID) {
        let esAttr = this.props.elasticSearchAttributes.map.get("create-flashcard-activity");
        const { dispatch } = this.props;
        ElasticSearchActions.setElasticSearchAttributes(dispatch, "create-flashcard-activity", esAttr.searchTerm, esAttr.pageSize + 10, esAttr.activePage, esAttr.sortObj, esAttr.filterValues);
        let elasticSearchQuery = new ElasticSearchQuery();
        elasticSearchQuery.query(ElasticSearchTypes.Glossary + config.stage, ElasticSearchColumns.Glossary, this.props,
            esAttr.searchTerm, esAttr.pageSize + 10, esAttr.activePage, esAttr.sortObj, esAttr.filterValues, function (results, totalRecords) {
                dispatch({
                    type: CREATE_ACTIVITY_FETCH_FLASHCARD_SUCCESS,
                    status: 200,
                    data: JSON.parse(JSON.stringify(results)),
                    alertText: '',
                    alertStyle: '',
                    showAlert: false,
                    totalRecords: totalRecords
                });
            });
        this.setState({ 'loadingNextPage': 0 });
        //         }
        //     }
        // }
    }

    cancelCreate() {
         browserHistory.push('/activities');
    }

    showRandomFlashcardDialog() {

        this.setState({ showRandomFlashcardDialogBox: true });
    }

    generateTagHtml(tags, tagType) {

        let context = this;
        let html = [];
        tags.forEach(tag => {
            html.push(<span className="keywordTag" key={"keyword" + tag}>
                <input type="hidden" name={"keyWord" + tag} value={tag} /> {tag}
                <span className="text-cross-icon" onClick={evt => {
                    context.deleteTags(evt, tagType, tag);
                }}>x</span>
            </span>)
        });
        return html;
    }

    keepOnPage(e) {
        let message = 'Unsaved changes will be lost';
        e.returnValue = message;
        if (this.state.leave) {
            return null;
        } else {
            return message;
        }
    }

    componentWillReceiveProps(nextProps) {
        // if (this.state.my) {
        //     if (nextProps.manageActivities && nextProps.manageActivities.createActivityFlashCards) {
        //         var arr = [...nextProps.manageActivities.createActivityFlashCards]
        //         console.log(arr)
        //     }
        // }
        // if prefLang not found
        this.props.auth.user.prefLanguage = this.props.auth.user.prefLanguage || 'en'
        // if (this.state.switcher) {
        if (nextProps.manageActivities && nextProps.manageActivities.createActivityFlashCards) {
            const flashes = nextProps.manageActivities.createActivityFlashCards.map(flashcard => {
                this.resetFlashcard(this.props.manageActivities.createActivityFlashCards, this.props.manageActivities.createActivitySelectedFlashCards)
                flashcard['lang'] = this.props.auth.user.prefLanguage;
                return flashcard;
            })
            // console.log(flashes)
            // context.call(flashes);
        }
        if (nextProps.manageActivities && nextProps.manageActivities.createActivitySelectedFlashCards) {
            const flashes2 = nextProps.manageActivities.createActivitySelectedFlashCards.map(flashcard => {
                flashcard['lang'] = this.props.auth.user.prefLanguage;
                return flashcard;
            })
            // console.log(flashes2)
            // context.call(flashes);
        }

        if (this.props.manageActivities.createActivityFlashCards != nextProps.manageActivities.createActivityFlashCards) {
            this.resetFlashcard(this.props.manageActivities.createActivityFlashCards, this.props.manageActivities.createActivitySelectedFlashCards)
            // arr.push(nextProps.manageActivities.createActivityFlashCards)
            // console.log(arr)
            this.setState({ seeTranslation: "", seeEnglish: "", my: false, isTranslated: false }, () => {

                console.log("changed")
            })
        }
    }

    componentDidMount() {


        let selectedLang = config.languages.find(langname => langname.id == this.props.auth.user.prefLanguage || 'en')
        this.setState({ selectedLang: selectedLang.name })
        this.router.setRouteLeaveHook(this.route, this.keepOnPage);

        let { dispatch } = this.props;
        ElasticSearchActions.setElasticSearchAttributes(dispatch, "create-flashcard-activity", '', parseInt($('#pageSizeDropDown').val()) ? parseInt($('#pageSizeDropDown').val()) : 10, 1, { _score: { order: "desc" } }, []);

        let context = this;
        let visibility = '( "' + this.props.auth.user.userName + '" )';
        let subjectsToSearch = "(";
        if (this.props.auth.user.subjects) {
            this.props.auth.user.subjects.forEach(sub => {
                subjectsToSearch += "\"" + sub + "\" OR ";
            })
        }
        subjectsToSearch = subjectsToSearch.substring(0, subjectsToSearch.length - 3);
        subjectsToSearch += ")";
        ManageCourseActions.fetchAllCourses(dispatch, this.props.auth.user.userName, 'course' + config.stage, {
            'query_string': {
                'fields': ['visibleTo', 'subject'],
                'query': 'visibleTo : ' + visibility + ' AND subject : ' + subjectsToSearch
            }
        });
        LoginUtils.isAuthenticated(context.props, browserHistory, '/activities/flashcard/new', '/');
        context.openSelected();
        if (context.props.params && context.props.params.id) {

           
            let params = {
                activityID: context.props.params.id,
            };
            let pathTemplate = '/activity/{activityID}';
            let method = 'GET';
            let additionalParams = {};
            let body = {};
            let { dispatch } = context.props;
            apiClient.invokeApi(params, pathTemplate, method, additionalParams, body).then(function (result) {
                let activity = result.data;

                //handle edit record data.
                let subHtml = [], wordHtml = [];
                if (activity.subjects) {
                    subHtml = context.generateTagHtml(result.data.subjects, 'subject');
                }
                if (activity.keywords) {
                    wordHtml = context.generateTagHtml(result.data.keywords, 'keyword');
                }

                dispatch({
                    type: CREATE_ACTIVITY_MOVE_FLASHCARD,
                    createActivityFlashCardTaggedKeywordsHtml: wordHtml,
                    createActivityFlashCardTaggedKeywords: result.data.keywords ? result.data.keywords : [],
                    createActivityFlashCardTaggedSubjectsHtml: subHtml,
                    createActivityFlashCardTaggedSubjects: activity.subjects ? activity.subjects.sort() : [],
                    createActivityFlashCardActivityType: activity.activityType,
                    createActivityFlashCardActivityName: activity.activityName,
                    createActivitySelectedFlashCards: activity.words,
                    createActivityFlashCards: [],
                    createActivityCurrentActivity: activity,
                    createActivityFlashCardActivityState: activity.activityState,
                    createActivityFlashCardDescription: activity.description
                })
            }).catch(function (result) {
                console.error(result);
            });
        }

        if (!(context.props.manageActivities && context.props.manageActivities.createActivityFlashCardAllSubjects && context.props.manageActivities.createActivityFlashCardAllSubjects.length === 0)) {
            context.getAllSubjects();
        }
        context.selectQuestionLearnerPalOnClickHandler(false, true);

        window.addEventListener('beforeunload', this.keepOnPage);

    }
    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.keepOnPage);
    }

    getAllSubjects() {

        let sortedSubjects = [];
        this.props.auth.user.subjects.forEach(sub => {
            sortedSubjects.push({ "id": sub, "name": sub });
        });
        sortedSubjects.sort(function (a, b) {
            return a.id > b.id;
        });
        this.setState({ allSubjects: sortedSubjects });
    }

    selectFlashCardSearchOnChangeHandler(evt) {
        let term = evt.nativeEvent.target.value;
        if(term.trim().length > 0){
            term = term.trim() + '*'
        }
        let esAttr = this.props.elasticSearchAttributes.map.get("create-flashcard-activity");
        const { dispatch } = this.props;
        let extraVisibility = `"${this.props.auth.user.userName}"`; 
        if (this.props.auth.user.userType.toLowerCase() === "teacher") {
            extraVisibility += " OR LPAdmin";
        }
        let props = Object.assign({}, this.props, {extraVisibility: extraVisibility});

        let elasticSearchQuery = new ElasticSearchQuery();
        ElasticSearchActions.setElasticSearchAttributes(dispatch, "create-flashcard-activity", term, esAttr.pageSize, esAttr.activePage, esAttr.sortObj, esAttr.filterValues);
        elasticSearchQuery.query(ElasticSearchTypes.Glossary + config.stage, ElasticSearchColumns.Glossary, props,
            term, esAttr.pageSize, esAttr.activePage, esAttr.sortObj, [], function (results, totalRecords) {
                dispatch({
                    type: CREATE_ACTIVITY_FETCH_FLASHCARD_SUCCESS,
                    status: 200,
                    data: JSON.parse(JSON.stringify(results)),
                    alertText: '',
                    alertStyle: '',
                    showAlert: false,
                    totalRecords: totalRecords,
                    createActivityFlashCardClearFilters: true,
                });
            });
    }

    selectedFlashCardClearAllOnClickHandler() {
        let { dispatch } = this.props;
        let flashCards = this.props.manageActivities && this.props.manageActivities.createActivityFlashCards ? this.props.manageActivities.createActivityFlashCards : [];
        let selectedFlashCards = this.props.manageActivities && this.props.manageActivities.createActivitySelectedFlashCards ? this.props.manageActivities.createActivitySelectedFlashCards : [];
        flashCards = flashCards.concat(selectedFlashCards);
        dispatch({
            type: CREATE_ACTIVITY_MOVE_FLASHCARD,
            createActivityFlashCards: flashCards,
            createActivitySelectedFlashCards: []
        });
    }

    resetFlashcard(flashcards1, flashcards2) {
        if (this.state.seeTranslation) {
            if (flashcards1 != undefined) {
                let prevFlashcard = flashcards1.find(flashcard => flashcard.word == this.state.seeTranslation.word);
                if (prevFlashcard != undefined) {
                    prevFlashcard.translatedWord = this.state.seeTranslation.translatedWord;
                    prevFlashcard.definition = this.state.seeTranslation.definition;
                    prevFlashcard.lang = this.props.auth.user.prefLanguage
                }
                else {
                    let prevFlashcard = flashcards2.find(flashcard => flashcard.word == this.state.seeTranslation.word);
                    console.log(prevFlashcard)
                    prevFlashcard.translatedWord = this.state.seeTranslation.translatedWord;
                    prevFlashcard.definition = this.state.seeTranslation.definition;
                    prevFlashcard.lang = this.props.auth.user.prefLanguage
                }
            }
            else {
                let prevFlashcard = flashcards2.find(flashcard => flashcard.word == this.state.seeTranslation.word);
                console.log(prevFlashcard)
                prevFlashcard.translatedWord = this.state.seeTranslation.translatedWord;
                prevFlashcard.definition = this.state.seeTranslation.definition;
                prevFlashcard.lang = this.props.auth.user.prefLanguage
            }
        }
    }

    selectedFlashCardPreviewOnClickHandler() {
        let flashcards = this.props.manageActivities && this.props.manageActivities.createActivityFlashCards ? this.props.manageActivities.createActivityFlashCards : [];
        let selectedFlashCards = this.props.manageActivities && this.props.manageActivities.createActivitySelectedFlashCards ? this.props.manageActivities.createActivitySelectedFlashCards : [];
        this.resetFlashcard(flashcards, selectedFlashCards)
        this.setState({
            showPreviewDialog: true
        })
    }

    isValid(data) {
        let allRecords = this.props.manageActivities.allRecords ? this.props.manageActivities.allRecords : [];
        const { errors, isValid } = validateInput(data, allRecords, this.props.auth.user.userName, this.props.params && this.props.params.id ? this.props.params.id : null);
        if (!isValid) {
            this.setState({ errors });
        }
        return isValid;
    }


    showIndicator() {
        this.setState({
            ...this.state,
            loading: true
        });
    }

    hideIndicator() {
        this.setState({
            ...this.state,
            loading: false
        });
    }

    isNotDuplicatedName() {
        // let axiosInstance = axios.create({
        //     baseURL: 'https://search-learnerpal-eimkio3umgx5fnpuy7ypsrrsri.us-east-1.es.amazonaws.com/learnerpal',
        //     timeout: 60000,
        //     headers: {'Content-Type': 'application/json'},
        //     responseType: 'json',
        //     transformResponse: {function (data) {
        //         return data;
        //     }}
        // });
        // return axiosInstance({
        //     method: 'GET',
        //     url: '/' + ElasticSearchTypes.Activity+config.stage + '/' +  this.props.manageActivities.createActivityFlashCardActivityName.replace(/\s|\W|[#$%^&*()'.]/g, "") + "_" + this.props.auth.user.userName,
        // })

        return apiClient.invokeApi({}, '/getesdata', 'POST', {}, { id: "/" + this.props.manageActivities.createActivityFlashCardActivityName.replace(/\s|\W|[#$%^&*()'.]/g, "").toLocaleLowerCase() + "_" + this.props.auth.user.userName, index: 'learnerpal/', esDocumentType: ElasticSearchTypes.Activity + config.stage }, '5');

    }

    saveAndExitClickHandler(skipTransition) {
        let context = this;

        return new Promise((resolve, reject) => {
            console.log('Save and Exit.');
            let flashCards = this.props.manageActivities.createActivitySelectedFlashCards ? this.props.manageActivities.createActivitySelectedFlashCards : [];
            let qIds = [];
            flashCards.forEach(q => {
                qIds.push(q.word || q.id);
            });
            let data = {
                orderBy: '1',
                schoolName: this.props.auth.user.schoolName,
                ownerName: this.props.auth.user.userName,
                activityType: 'flashcards',
                activityName: this.props.manageActivities.createActivityFlashCardActivityName ? this.props.manageActivities.createActivityFlashCardActivityName : '',
                tags: this.props.manageActivities.createActivityFlashCardTaggedKeywords ? this.props.manageActivities.createActivityFlashCardTaggedKeywords : [],
                subjects: this.props.manageActivities.createActivityFlashCardTaggedSubjects ? this.props.manageActivities.createActivityFlashCardTaggedSubjects.sort() : [],
                activityState: this.props.manageActivities.createActivityFlashCardActivityState ? this.props.manageActivities.createActivityFlashCardActivityState : 'In Progress',
                words: qIds
            };
           
            if (this.props.manageActivities.createActivityFlashCardDescription) {
                data.description = this.props.manageActivities.createActivityFlashCardDescription;
            }

            if (this.props.params.id) {
                data.activityID = this.props.params.id;
            }
            if (this.isValid(data)) {

                if (this.props.params.id) {
                    let params = {};
                    let pathTemplate = '/activity';
                    let method = context.props.params.id ? 'PUT' : 'POST';
                    let additionalParams = {};
                    
                    let body = data;
                    context.showIndicator();
                    apiClient.invokeApi(params, pathTemplate, method, additionalParams, body).then(function (result) {
                        if (method === 'POST') {
                            ClevertapReact.event("Create Activity", { 'userName': context.props.auth.user.userName });
                        }
                        else {
                            ClevertapReact.event("Update Activity", { 'userName': context.props.auth.user.userName });
                        }
                       // context.dispatchCreateFlashCardFields();
                        context.setState({leave : true}, context.dispatchCreateFlashCardFields());
                        if (method !== 'PUT') {
                            resolve({ saved: true, status: 'Success', data: result.data });
                        } else {
                            reject({ saved: false, status: 'Error', data: result.data });
                        }
                    }).catch(function (result) {
                        context.hideIndicator();
                        reject({ saved: false, status: 'Error' });
                        setTimeout(() => {

                            const { dispatch } = context.props;
                            ManageActivityActions.setMessage(dispatch, true, result.data.message, 'success', function () {
                                context.hideIndicator();
                                browserHistory.push('/activities');
                            });
                        }, 2000);
                    });
                }
                else {
                    this.isNotDuplicatedName().then(response => {
                        if (response.data.found) {
                            context.hideIndicator();
                            let errors = {};
                            errors.activityName = 'Activity with the same name already exists';
                            context.setState({ errors });
                            context.setState({ showCreateInfoDialog: true });
                        }
                    }).catch(response => {
                        if (!response.response.data.found) {
                            let params = {};
                            let pathTemplate = '/activity';
                            let method = context.props.params.id ? 'PUT' : 'POST';
                            let additionalParams = {};
                          
                            let body = data;
                           
                            context.showIndicator();
                            apiClient.invokeApi(params, pathTemplate, method, additionalParams, body).then(function (result) {
                               // context.dispatchCreateFlashCardFields();
                                context.setState({leave : true}, context.dispatchCreateFlashCardFields());
                                if (method !== 'PUT') {
                                    resolve({ saved: true, status: 'Success', data: result.data });
                                } else {
                                    reject({ saved: false, status: 'Error', data: result.data });
                                }
                            }).catch(function (result) {
                                context.hideIndicator();
                                reject({ saved: false, status: 'Error' });
                                setTimeout(() => {

                                    const { dispatch } = context.props;
                                    ManageActivityActions.setMessage(dispatch, true, result.data.message, 'success', function () {
                                        context.hideIndicator();
                                        browserHistory.push('/activities');
                                    });
                                }, 2000);
                            });
                        }
                    });
                }
            }
            else {
                context.hideIndicator();
                let errors = {};
                errors.activityName = 'Activity with the same name already exists';
                context.setState({ errors });
                context.setState({ showCreateInfoDialog: true });
            }
        });
    }

    onCourseChange(evt) {
        let value = evt.target.value;
        let that = this;
        this.setState({ isMandotary: true }, function () {
            that.getCourse(value);
        });
    }

    getCourse(id) {
        let context = this;

        context.showIndicator();
        if (id) {
            let params = {
                courseID: id,
            };
            let pathTemplate = '/course/{courseID}';
            let method = 'GET';
            let additionalParams = {};
            let body = {};

            apiClient.invokeApi(params, pathTemplate, method, additionalParams, body).then(function (result) {

                context.setState({
                    ...context.state,
                    selectedCourse: result.data,
                    loading: false
                });
            }).catch(function (error) {
                context.hideIndicator();
            });
        }
    }

    onUnitChange(evt) {

        if (evt.target.value) {
            let selectedUnit = this.state.selectedCourse.units.filter(unit => unit.unitID === evt.target.value)[0];
            this.setState({ ...this.state, selectedUnit: selectedUnit });
        }
    }


    getSaveAndAssignDialog() {
        let courses = this.props.manageCourses.results;
        let courseList = courses.map(function (c) {
            return { name: c.courseName, id: c.id };
        });
        let selectedCourse = this.state.selectedCourse ? this.state.selectedCourse.courseID : '';
        let unitList = this.state.selectedCourse && this.state.selectedCourse.units ? this.state.selectedCourse.units.map(function (c) {
            return { name: c.unitName, id: c.unitID };
        }) : [];
        let selectedUnit = this.state.selectedUnit ? this.state.selectedUnit.unitID : '';

        let body = <div id="saveAndAssignDialogContent">
            {this.state.courseAssignError &&
                <p style={{ color: 'red' }}>Please select a course and unit. For question activity type.</p>}
            {courseList.length === 0 &&
                <div>
                    Please create a new course or duplicate an existing course to be able to add activities to the course from this screen.
            </div>
            }
            {courseList.length > 0 &&
                <div className="mainFormBox">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <SelectFieldGroup
                                error={this.state.errors.selectedCourse}
                                field="selectedCourse"
                                value={selectedCourse}
                                options={courseList}
                                label="Select Course in the box:"
                                placeholder="Select Course to Assign"
                                onChange={this.onCourseChange}
                            />
                        </div>
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <SelectFieldGroup
                                error={this.state.errors.selectedUnit}
                                field="selectedUnit"
                                value={selectedUnit}
                                options={unitList}
                                label="Select Unit:"
                                placeholder="Select Unit"
                                onChange={this.onUnitChange}
                            />
                        </div>
                    </div>
                </div>
            }
        </div>;
        return <Modal
            backdrop="static"
            show={this.state.showSaveAndAssignDialog}
            onHide={() => {
                this.setState({
                    showSaveAndAssignDialog: false
                });
                browserHistory.push('/activities');
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                {!this.state.saveAndExit && <Modal.Title>Do you want to add this activity to a course? (optional)</Modal.Title>}
                {this.state.saveAndExit && !this.props.params.id && <Modal.Title>Activity saved, you can access this activity under Manage Activities</Modal.Title>}
                {this.state.saveAndExit && this.props.params.id && <Modal.Title>Activity has been modified, you can access this activity under Manage Activities</Modal.Title>}
            </Modal.Header>
            {this.state.showAddToCourse && <Modal.Body>
                {body}
            </Modal.Body>
            }
            {!this.state.showAddToCourse && this.state.saveAndExit &&
                <Modal.Body>
                    <p>Do you want to add this activity to a course? (optional)</p>
                </Modal.Body>
            }
            <Modal.Footer>
                {!this.state.showAddToCourse && <Button className="rightMarginForModelButtons" onClick={() => {
                    {

                        if (this.state.saveAndExit) {
                            const { dispatch } = this.props;
                            ManageActivityActions.setMessage(dispatch, true, 'Activity has been created successfully', 'success', function () {

                                browserHistory.push('/activities');
                            });
                        }
                        else {
                            browserHistory.push({
                                pathname: '/assignments/assign',
                                state: { currentActivityObj: this.state.payloadForAssignCourse.activity, currentActivity: this.state.payloadForAssignCourse.activity.activityID, view: 'activity' }
                            });
                        }
                    }
                }}>No</Button>}
                {this.state.showAddToCourse && <Button className="rightMarginForModelButtons" onClick={() => {
                    if (this.state.saveAndExit) {
                        const { dispatch } = this.props;
                        ManageActivityActions.setMessage(dispatch, true, 'Activity has been created successfully', 'success', function () {

                            browserHistory.push('/activities');
                        });
                    }
                    else {
                        browserHistory.push({
                            pathname: '/assignments/assign',
                            state: { currentActivityObj: this.state.payloadForAssignCourse.activity, currentActivity: this.state.payloadForAssignCourse.activity.activityID, view: 'activity' }
                        });
                    }
                }}>Cancel</Button>}
                {!this.state.showAddToCourse &&
                    <Button className="rightMarginForModelButtons" onClick={() => {
                        this.setState({ 'showAddToCourse': true });
                    }} bsStyle='primary'>Yes</Button>
                }
                {this.state.showAddToCourse &&
                    <Button className="rightMarginForModelButtons" disabled={!this.state.payloadForAssignCourse}
                        onClick={() => {

                            if (this.state.isMandotary) {
                                if (this.state.saveAndExit) {
                                    let context = this;
                                    let payloadForAssignCourse = context.state.payloadForAssignCourse;

                                    if ((!context.state.selectedCourse || !context.state.selectedUnit) &&
                                        ((payloadForAssignCourse.activity.activityType.toLowerCase() === 'question' ||
                                            payloadForAssignCourse.activity.activityType.toLowerCase() === 'questions'))) {
                                        context.setState({ courseAssignError: true });
                                    } else {
                                        context.showIndicator();
                                        context.setState({ courseAssignError: false });
                                        payloadForAssignCourse.courseID = context.state.selectedCourse.courseID;
                                        payloadForAssignCourse.unitID = context.state.selectedUnit.unitID;
                                        payloadForAssignCourse.ownerName = context.props.auth.user.userName;

                                        let pathTemplate = '/course/assignment';
                                        let method = 'PUT';
                                        let additionalParams = {};
                                        apiClient.invokeApi(null, pathTemplate, method, additionalParams, payloadForAssignCourse, '2').then(function (result) {
                                            context.hideIndicator();
                                            const { dispatch } = context.props;
                                            ManageActivityActions.setMessage(dispatch, true, result.data, 'success', function () {
                                                browserHistory.push('/activities');
                                            });
                                        }).catch(function (result) {
                                            context.hideIndicator();
                                            const { dispatch } = context.props;
                                            ManageActivityActions.setMessage(dispatch, true, result.data, 'success', function () {
                                                browserHistory.push('/activities');
                                            });
                                        });
                                    }
                                }
                                else {
                                    let context = this;
                                    let payloadForAssignCourse = context.state.payloadForAssignCourse;

                                    if ((!context.state.selectedCourse || !context.state.selectedUnit) &&
                                        ((payloadForAssignCourse.activity.activityType.toLowerCase() === 'question' ||
                                            payloadForAssignCourse.activity.activityType.toLowerCase() === 'questions'))) {
                                        context.setState({ courseAssignError: true });
                                    } else {
                                        context.showIndicator();
                                        context.setState({ courseAssignError: false });
                                        payloadForAssignCourse.courseID = context.state.selectedCourse.courseID;
                                        payloadForAssignCourse.unitID = context.state.selectedUnit.unitID;
                                        payloadForAssignCourse.ownerName = context.props.auth.user.userName;

                                        let pathTemplate = '/course/assignment';
                                        let method = 'PUT';
                                        let additionalParams = {};
                                        apiClient.invokeApi(null, pathTemplate, method, additionalParams, payloadForAssignCourse, '2').then(function (result) {
                                            context.hideIndicator();
                                            browserHistory.push({
                                                pathname: '/assignments/assign',
                                                state: { currentActivityObj: context.state.payloadForAssignCourse.activity, currentActivity: context.state.payloadForAssignCourse.activity.activityID, view: 'activity' }
                                            });
                                        }).catch(function (result) {
                                            context.hideIndicator();
                                            browserHistory.push({
                                                pathname: '/assignments/assign',
                                                state: { currentActivityObj: context.state.payloadForAssignCourse.activity, currentActivity: context.state.payloadForAssignCourse.activity.activityID, view: 'activity' }
                                            });
                                        });
                                    }
                                }
                            }
                            else {
                                if (this.state.saveAndExit) {
                                    const { dispatch } = this.props;
                                    ManageActivityActions.setMessage(dispatch, true, 'Activity has been created successfully', 'success', function () {
                                        browserHistory.push('/activities');
                                    });
                                }
                                else {
                                    browserHistory.push({
                                        pathname: '/assignments/assign',
                                        state: { currentActivityObj: this.state.payloadForAssignCourse.activity, currentActivity: this.state.payloadForAssignCourse.activity.activityID, view: 'activity' }
                                    });
                                }
                            }
                        }} bsStyle='primary'>{!this.state.isMandotary && this.state.saveAndExit && 'Exit'} {this.state.isMandotary && this.state.saveAndExit && 'Add to Course and Exit'} {this.state.isMandotary && !this.state.saveAndExit && 'Add to Course and Assign'} {!this.state.isMandotary && !this.state.saveAndExit && 'Assign'} </Button>}
            </Modal.Footer>
        </Modal>;
    }

    dispatchCreateFlashCardFields() {
        let { dispatch } = this.props;
        dispatch({
            type: CREATE_FLASHCARD_SET_INIT_INFO,
            taggedKeywordsHtml: [],
            taggedKeywords: [],
            taggedSubjectsHtml: [],
            taggedSubjects: [],
            allSubjects: this.state.allSubjects,
            activityType: '',
            activityName: '',
            selected: [],
            activityState: ''
        })
    }

    saveAndAssignClickHandler() {
        let context = this;
        context.showIndicator();
        context.saveAndExitClickHandler(true).then(result => {
            delete result.data['message'];
            console.debug(result);
            context.setState({
                ...context.state,
                showSaveAndAssignDialog: true,
                showAddToCourse: false,
                payloadForAssignCourse: {
                    activity: result.data,
                    courseID: null,
                    unitID: null,
                    assessmentType: null
                }
            })
        }).catch(result => {
            delete result.data['message'];
            console.debug(result);
            context.setState({
                ...context.state,
                showSaveAndAssignDialog: true,
                showAddToCourse: false,
                payloadForAssignCourse: {
                    activity: result.data,
                    courseID: null,
                    unitID: null,
                    assessmentType: null
                }
            })
        });
    }

    check(flashcard, flashcards, selectedFlashcards) {
        let myflash = selectedFlashcards.find(flash => flash.word == flashcard.word)
        if (myflash == undefined) {
            myflash = flashcards.find(flash => flash.word == flashcard.word)
        }
        if (myflash && myflash.lang == this.props.auth.user.prefLanguage) {
            this.setState({ myisTranslated: true }, () => {
                if (this.state.isTranslated && this.state.seeTranslation) {
                    myflash.lang = "en"
                }
            })
            return false
        }
    }

    changeSides(flashcards) {
        if (this.state.isTranslated) {
            let Flash = flashcards.find(f => f.lang == "en");
            if (Flash) {
                Flash.lang = "en"
            }
            this.setState({ switcher: false })
            return
        }
    }

    changeLang(flashcard, flashcards, selectedFlashcards) {

        if (this.state.isTranslated != "") {
            let Flash = selectedFlashcards.find(flash => flash.lang == "en");
            // this.setState({ arr: arr.push(...Flash) }, () => {
            //     console.log(this.state.arr)
            // })
            if (Flash == undefined) {
                if (flashcards != undefined) {
                    Flash = flashcards.find(flash => flash.lang == "en");
                    // this.setState({ arr: arr.push(...Flash) }, () => {
                    //     console.log(this.state.arr)
                    // })
                }

            }
            if (Flash) {
                Flash.lang = this.props.auth.user.prefLanguage
            }
            if (this.check(flashcard, flashcards, selectedFlashcards)) {
                return
            }

            return
        }
        if (!this.state.isTranslated) {
            let myflash = selectedFlashcards.find(flash => flash.word == flashcard.word)
            if (myflash == undefined) {
                myflash = flashcards.find(flash => flash.word == flashcard.word)
            }
            myflash.lang = "en"
        }
    }

    moveToSelectedFlashCard(flashCard) {
        let { dispatch } = this.props;
        let selected = this.props.manageActivities && this.props.manageActivities.createActivitySelectedFlashCards ? this.props.manageActivities.createActivitySelectedFlashCards : [];
        let flashCards = this.props.manageActivities && this.props.manageActivities.createActivityFlashCards ? this.props.manageActivities.createActivityFlashCards : [];
        let newFlashCards = [];
        flashCards.forEach(Q => {
            if (Q.word !== flashCard.word) {
                newFlashCards.push(Q);
            }
        });
        selected.push(flashCard);
        // this.changeSides(selected)
        this.resetFlashcard(flashCards, selected)
        dispatch({
            type: CREATE_ACTIVITY_MOVE_FLASHCARD,
            createActivityFlashCards: newFlashCards,
            createActivitySelectedFlashCards: selected
        });
        $('.openSelBox').html('View Selected Flashcards');
    }

    moveToFlashCard(flashCard) {
        let { dispatch } = this.props;
        let flashCards = this.props.manageActivities && this.props.manageActivities.createActivityFlashCards ? this.props.manageActivities.createActivityFlashCards : [];
        let selectedFlashCards = this.props.manageActivities && this.props.manageActivities.createActivitySelectedFlashCards ? this.props.manageActivities.createActivitySelectedFlashCards : [];
        let newSelectedFlashCards = [];

        selectedFlashCards.forEach(Q => {
            if (Q.word !== flashCard.word) {
                newSelectedFlashCards.push(Q);
            }
        });
        flashCards.push(flashCard);
        this.resetFlashcard(selectedFlashCards, flashCards)
        // this.changeSides(selectedFlashCards)
        dispatch({
            type: CREATE_ACTIVITY_MOVE_FLASHCARD,
            createActivityFlashCards: flashCards,
            createActivitySelectedFlashCards: newSelectedFlashCards
        });
        if (selectedFlashCards.length === 0) {
            $('.openSelBox').html('View Selected Flashcards');
        }
    }

    onActivityNameChange(e) {
        let context = this;
        let { dispatch } = context.props;
        this.setState({ createActivityFlashCardActivityName: e.target.value })
        dispatch({
            type: CREATE_ACTIVITY_MOVE_FLASHCARD,
            createActivityFlashCards: context.props.manageActivities.createActivityFlashCards,
            createActivitySelectedFlashCards: context.props.manageActivities.createActivitySelectedFlashCards,
            createActivityFlashCardActivityName: e.target.value
        });
    }

    onActivityDescriptionChange(e) {
        let context = this;
        let { dispatch } = context.props;
        dispatch({
            type: CREATE_ACTIVITY_MOVE_FLASHCARD,
            createActivityFlashCards: context.props.manageActivities.createActivityFlashCards,
            createActivitySelectedFlashCards: context.props.manageActivities.createActivitySelectedFlashCards,
            createActivityFlashCardDescription: e.target.value === "" ? " " : e.target.value,
        });
    }

    onActivityStateChange(e) {
        let context = this;
        let { dispatch } = context.props;
        dispatch({
            type: CREATE_ACTIVITY_MOVE_FLASHCARD,
            createActivityFlashCards: context.props.manageActivities.createActivityFlashCards,
            createActivitySelectedFlashCards: context.props.manageActivities.createActivitySelectedFlashCards,
            createActivityFlashCardActivityState: e.target.value
        });
    }

    checkEnterKeyPress(e) {
        if (e.key === 'Enter') {
            if (e.target.id === 'keywords') {
                this.inputTags(e);
            } else {
                this.selectFlashCardSearchOnChangeHandler(e);
            }

        }
    }

    inputTags(event) {

        let context = this;
        let { dispatch } = context.props;
        let taggedSubjectsHtml = context.props.manageActivities && context.props.manageActivities.createActivityFlashCardTaggedSubjectsHtml ? context.props.manageActivities.createActivityFlashCardTaggedSubjectsHtml : [];
        let taggedKeywordsHtml = context.props.manageActivities && context.props.manageActivities.createActivityFlashCardTaggedKeywordsHtml ? context.props.manageActivities.createActivityFlashCardTaggedKeywordsHtml : [];
        let taggedKeywords = context.props.manageActivities && context.props.manageActivities.createActivityFlashCardTaggedKeywords ? context.props.manageActivities.createActivityFlashCardTaggedKeywords : [];
        let taggedSubjects = context.props.manageActivities && context.props.manageActivities.createActivityFlashCardTaggedSubjects ? context.props.manageActivities.createActivityFlashCardTaggedSubjects : [];


        if (event.target.id === 'keywords' && event.target.value !== '') {
            let value = event.target.value;
            let uniqueKeyForTag = "keyword" + value + "_" + Math.random() * 10000000000000000000;
            if (!taggedKeywords.includes(value)) {
                dispatch({
                    type: CREATE_ACTIVITY_MOVE_FLASHCARD,
                    createActivityFlashCardTaggedKeywordsHtml: taggedKeywordsHtml.concat(
                        <span className="keywordTag" key={uniqueKeyForTag}>
                            <input type="hidden" name={"keyWord" + value} value={value} /> {value}
                            <span className="text-cross-icon" onClick={evt => {
                                context.deleteTags(evt, 'keyword', value);
                            }}>x</span>
                        </span>),
                    createActivityFlashCardTaggedKeywords: taggedKeywords.concat(value)
                });
            }
            event.target.value = "";
        }
        else if (event.target.name === 'keywordsDrop') {
            let keyword = '';
            if (event.target.selectedOptions && event.target.selectedOptions[0]) {
                keyword = event.target.selectedOptions[0].value;
            } else {
                keyword = event.nativeEvent.srcElement.value;
            }            //let keyword = event.nativeEvent.srcElement.value;
            let uniqueKeyForSubjectTag = "keyword" + keyword + "_" + Math.random() * 10000000000000000000;
            if (keyword !== '' && keyword !== 'Select Subject') {
                if (!taggedSubjects.includes(keyword)) {
                    dispatch({
                        type: CREATE_ACTIVITY_MOVE_FLASHCARD,
                        createActivityFlashCardTaggedSubjectsHtml: taggedSubjectsHtml.concat(
                            <span className="keywordTag" key={uniqueKeyForSubjectTag}>
                                <input type="hidden" name={"keyWord" + keyword} value={keyword} /> {keyword}
                                <span className="text-cross-icon" onClick={evt => {
                                    context.deleteTags(evt, 'subject', keyword)
                                }}>x</span>
                            </span>),
                        createActivityFlashCardTaggedSubjects: taggedSubjects.concat(keyword)
                    });
                }
            }
        }
    }

    deleteTags(event, tagType, value) {

        let { dispatch } = this.props;
        if (tagType === 'keyword') {
            let records = this.props.manageActivities.createActivityFlashCardTaggedKeywords;
            let htmls = this.props.manageActivities.createActivityFlashCardTaggedKeywordsHtml;
            let newKeywords = [];
            let newHtmls = [];
            records.forEach(record => {
                if (value.trim().toLowerCase() !== record.trim().toLowerCase()) {
                    newKeywords.push(record);
                }
            });
            htmls.forEach(html => {
                if (html.props.children[0].props.value.trim().toLowerCase() !== value.trim().toLowerCase()) {
                    newHtmls.push(html)
                }
            });
            dispatch({
                type: CREATE_ACTIVITY_MOVE_FLASHCARD,
                createActivityFlashCardTaggedKeywords: newKeywords,
                createActivityFlashCardTaggedKeywordsHtml: newHtmls
            });
        } else {
            let records = this.props.manageActivities.createActivityFlashCardTaggedSubjects;
            let htmls = this.props.manageActivities.createActivityFlashCardTaggedSubjectsHtml;
            let newKeywords = [];
            let newHtmls = [];
            records.forEach(record => {
                if (value.trim().toLowerCase() !== record.trim().toLowerCase()) {
                    newKeywords.push(record);
                }
            });
            htmls.forEach(html => {
                if (html.props.children[0].props.value.trim().toLowerCase() !== value.trim().toLowerCase()) {
                    newHtmls.push(html)
                }
            });
            dispatch({
                type: CREATE_ACTIVITY_MOVE_FLASHCARD,
                createActivityFlashCardTaggedSubjects: newKeywords,
                createActivityFlashCardTaggedSubjectsHtml: newHtmls
            });
        }
    }

    getPreviewDialog() {

        let words = [];
        let subjects = [];
        let flashCards = [];
        let kCount = 0;
        let sCount = 0;
        let qCount = 0;
        if (this.props.manageActivities && this.props.manageActivities.createActivityFlashCardTaggedKeywords) {
            this.props.manageActivities.createActivityFlashCardTaggedKeywords.forEach(keyword => {
                words.push(<span className="keywordTag" key={'keyword-preview-' + kCount}>
                    <input type="hidden" name="tags[]" value="ActionScript" />{keyword}</span>);
                kCount++;
            });
        }
        if (this.props.manageActivities && this.props.manageActivities.createActivityFlashCardTaggedSubjects) {
            this.props.manageActivities.createActivityFlashCardTaggedSubjects.forEach(subject => {
                subjects.push(<span className="keywordTag" key={'subject-preview-' + sCount}>
                    <input type="hidden" name="tags[]" value="ActionScript" />{subject}</span>);
                sCount++;
            });
        }
        if (this.props.manageActivities && this.props.manageActivities.createActivitySelectedFlashCards) {
            this.props.manageActivities.createActivitySelectedFlashCards.forEach(flashCard => {
                let parser = new DOMParser();
                let text = <div className="listNumQuestion">
                    <h4><span dir lang={this.props.auth.user.prefLanguage}>{flashCard.translatedWord ? flashCard.translatedWord : flashCard.word}</span></h4>
                    <p><span dir lang={this.props.auth.user.prefLanguage}>{flashCard.translatedWord ? flashCard.translatedWord : flashCard.word}{flashCard.definition}</span></p>
                </div>;
                let tags = [];
                if (flashCard.tags) {
                    flashCard.tags.forEach(tag => {
                        tags.push(<li key={tag + Math.random()}>{tag}</li>)
                    });
                }
                flashCards.push(<div dir lang={this.props.auth.user.prefLanguage} className="fieldBox FlashCardChoiceBox width_100" key={'flashCard-preview-' + qCount}>
                    {text}
                    <ul className="keyCourse">
                        {tags}
                    </ul>
                </div>);
                qCount++;
            });
        }
        let name = this.props.manageActivities && this.props.manageActivities.createActivityFlashCardActivityName ? this.props.manageActivities.createActivityFlashCardActivityName : '';
        let body = <div id="deleteDialogContent">
            <div className="modal-body">
                <div className="FrontQuestionBox">
                    <div className="questionBlock NiceScroll">
                        <h3 className="noteHeading">Selected Flashcards</h3>
                        {flashCards}
                    </div>
                    {/*<div className="clearfix"/>*/}
                    {/*<br/>*/}
                    {/*<h3>Keywords</h3>*/}
                    {/*{words}*/}
                    {/*<div className="clearfix"/>*/}
                    {/*<br/>*/}
                    <h3>Associated Subjects</h3>
                    {subjects}
                    <div className="clearfix" />
                </div>
            </div>
        </div>;
        return <Modal id="PreviewModal"
            show={this.state.showPreviewDialog}
            onHide={() => {
                this.setState({
                    showPreviewDialog: false
                })
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title
                    id="delete-activity-modal">{name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div id="questionPreviewBox">
                    {flashCards}
                    <div className="row" />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => { this.setState({ showPreviewDialog: false }) }}
                    bsStyle="primary">
                    Okay
                </Button>
            </Modal.Footer>
        </Modal>;
    }

    getFlashCardSelectionErrorDialog() {
        return <Modal id="FlashCardSelectionErrorModal"
            show={this.state.showFlashCardErrorDialog}
            onHide={() => {
                this.setState({
                    showFlashCardErrorDialog: false
                })
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title
                    id="delete-activity-modal">{'Error'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {<div className="text-danger">
                    <br />
                    All selected flashCards must be of same type (Essay or Multiple Choice).
                    <br />
                </div>}
            </Modal.Body>
        </Modal>;
    }

    selectRandomFlashcards(count) {
        let esAttr = this.props.elasticSearchAttributes.map.get("create-flashcard-activity");
        let elasticSearchQuery = new ElasticSearchQuery();
        let that = this;
        elasticSearchQuery.random(ElasticSearchTypes.Glossary + config.stage, ElasticSearchColumns.Glossary, this.props,
            esAttr.searchTerm, count, esAttr.filterValues, null, function (results, totalRecords) {
                let selected = JSON.parse(JSON.stringify(results));
                for (let i = 0; i < selected.length; i++) {
                    setTimeout(() => {
                        that.moveToSelectedFlashCard(selected[i]);
                    }, 200);
                }
            });

    }

    openSelected() {
        let ctx = this;
        $('.openSelBox').click(function (evt) {
            $('.questionSelectedBox').addClass('showSelQuesTab');
            $(evt.target).addClass('closeSelBox');
            $(evt.target).html('Select Flashcards');
            if ($('.openSelBox').hasClass('closeSelBox'))
                ctx.openFlashcards();
        });

    }

    openFlashcards() {
        let ctx = this;
        $('.closeSelBox').click(function (evt) {
            $('.questionSelectedBox').removeClass('showSelQuesTab');
            $(evt.target).removeClass('closeSelBox');
            $(evt.target).html('View Selected Flashcards');
            ctx.openSelected();
        })
    }

    getRandomFlashcardBoxDialog() {

        let body = <div className="randomSelection">
            <p>Select number of random flashcards to add to the activity <input className="form-control" type="text" id="randomQuestionInput" maxLength="4" /></p>

        </div>;
        let that = this;
        return <Modal
            show={this.state.showRandomFlashcardDialogBox}
            onHide={() => {
                this.setState({ showRandomFlashcardDialogBox: false });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Select Random Flashcards</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    that.setState({ showRandomFlashcardDialogBox: false });
                }}>Cancel</Button>
                <Button className="btn btn-primary crtBtn" onClick={() => {
                    this.setState({ showRandomFlashcardDialogBox: false });
                    let count = $("#randomQuestionInput").val();
                    if (count && Validator.isInt(count)) {
                        this.selectRandomFlashcards(count);
                    }
                }}>Select Flashcards</Button>
            </Modal.Footer>
        </Modal>;
    }

    getCreateActivityInfoDialog() {
        const { errors } = this.state;
        let generatedKeywords = this.generateTagHtml(this.props.manageActivities && this.props.manageActivities.createActivityFlashCardTaggedKeywords ? this.props.manageActivities.createActivityFlashCardTaggedKeywords : [], 'keyword');
        let generatedSubjects = this.generateTagHtml(this.props.manageActivities && this.props.manageActivities.createActivityFlashCardTaggedSubjects ? this.props.manageActivities.createActivityFlashCardTaggedSubjects : [], 'subject');
        let allSubjects = this.state.allSubjects && this.state.allSubjects ? this.state.allSubjects : [];
        let activityStates = [
            {
                "id": "In Progress",
                "name": "In Progress"
            },
            {
                "id": "In Review",
                "name": "In Review"
            }
        ];

        if (this.props.auth.user.userType.toLowerCase() === "admin") {
            activityStates.push({
                "id": "Published",
                "name": "Published"
            })
        }
        let body = <div className="mainFormBox">
            <div className="col-lg-12 col-md-12">
                <TextFieldGroup
                    error={errors.activityName}
                    isDisabled={this.props.params.id}
                    label="Activity Name:"
                    field="activityName"
                    value={this.state.createActivityFlashCardActivityName || this.props.manageActivities.createActivityFlashCardActivityName || ''}
                    placeholder="Enter Activity Name"
                    onChange={this.onActivityNameChange}
                />
                <div className="form-group">
                    <label htmlFor="description">Description:</label>
                    <div className="posRel selectWidth">
                        <div className="ui-widget">
                            <textarea id="description" name="description" className="form-control commentareaField"
                                placeholder="Enter Description" onChange={this.onActivityDescriptionChange} value={this.props.manageActivities.createActivityFlashCardDescription} />
                        </div>
                    </div>
                </div>
                {/*<div className="form-group">*/}
                {/*<label htmlFor="selectUser">Keywords:</label>*/}
                {/*<div className="posRel  selectWidth ">*/}
                {/*<div className="ui-widget">*/}
                {/*<input id="keywords" className="fieldBg keyWords form-control"*/}
                {/*onKeyUp={this.checkEnterKeyPress} onBlur={this.inputTags}*/}
                {/*data-input-feild="tags" placeholder="Enter Keyword"/>*/}
                {/*<div className="appendDiv">*/}
                {/*{generatedKeywords}*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*</div>*/}
                <SelectFieldGroup
                    error={errors.subjectError}
                    label="Subject:"
                    field="keywordsDrop"
                    value={this.state.keywordsDrop}
                    options={allSubjects}
                    placeholder="Associate with Subjects"
                    onChange={this.inputTags}
                    multiSelect={generatedSubjects}
                />
                {this.props.auth.user.schoolName === "LearnerPal" &&
                    <SelectFieldGroup
                        error={errors.activityState}
                        label="Activity State:"
                        field="activityState"
                        value={this.props.manageActivities.createActivityFlashCardActivityState}
                        options={activityStates}
                        onChange={this.onActivityStateChange}
                    />
                }
            </div>
            <div className="row" />
        </div>;
        return <Modal
            show={this.state.showCreateInfoDialog}
            onHide={() => {
                this.setState({ showCreateInfoDialog: false }, function () {
                    browserHistory.push({
                        pathname: '/activities',
                        state: { isActivityTypeLandingPage: true }
                    });
                });
            }}
            backdrop='static'
            keyboard={false}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Activity Info</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    this.setState({ showCreateInfoDialog: false }, function () {
                        browserHistory.push({
                            pathname: '/activities',
                            state: { isActivityTypeLandingPage: true }
                        });
                    });
                }}>Cancel</Button>
                <Button className="btn btn-primary crtBtn" onClick={() => {
                    let flashCards = this.props.manageActivities.createActivitySelectedFlashCards ? this.props.manageActivities.createActivitySelectedFlashCards : [];
                    let qIds = [];
                    flashCards.forEach(q => {
                        qIds.push(q.id || q.word);
                    });
                    let data = {
                        orderBy: '1',
                        schoolName: this.props.auth.user.schoolName,
                        ownerName: this.props.auth.user.userName,
                        activityType: 'flashcards',
                        activityName: this.props.manageActivities.createActivityFlashCardActivityName ? this.props.manageActivities.createActivityFlashCardActivityName : '',
                        tags: this.props.manageActivities.createActivityFlashCardTaggedKeywords ? this.props.manageActivities.createActivityFlashCardTaggedKeywords : [],
                        subjects: this.props.manageActivities.createActivityFlashCardTaggedSubjects ? this.props.manageActivities.createActivityFlashCardTaggedSubjects.sort() : [],
                        activityState: this.props.manageActivities.createActivityFlashCardActivityState ? this.props.manageActivities.createActivityFlashCardActivityState : 'In Progress',
                        words: qIds
                    };
                    if (this.props.manageActivities.createActivityFlashCardDescription) {
                        data.description = this.props.manageActivities.createActivityFlashCardDescription;
                    }

                    if (this.props.params.id) {
                        data.activityID = this.props.params.id;
                    }
                    if (this.isValid(data)) {
                        this.setState({ showCreateInfoDialog: false });
                    }
                }}>Next</Button>
            </Modal.Footer>
        </Modal>;

    }

    showCreateActivityDialog() {
        this.setState({ showCreateInfoDialog: true });
    }

    saveAndExit() {
        let that = this;
        this.saveAndExitClickHandler().then(result => {
            setTimeout(() => {

                const { dispatch } = that.props;
                ManageActivityActions.setMessage(dispatch, true, result.data.message, 'success', function () {
                    that.hideIndicator();
                    browserHistory.push('/activities');
                });
            }, 2000);
        }).catch(result => {
            setTimeout(() => {

                const { dispatch } = that.props;
                ManageActivityActions.setMessage(dispatch, true, result.data, 'success', function () {
                    that.hideIndicator();
                    browserHistory.push('/activities');
                });
            }, 2000);
        });
    }

    getTranslationIcon(flashcard, flashcards, selectedFlashcards) {
        // this.setState({ count: 2 })
        return (<span style={{ padding: "5px" }} className={classnames("toggle-icon", "Activity-toolTip", { "hidden": !this.props.auth.user.multiLingualLicense || !this.props.auth.user.prefLanguage || (this.props.auth.user.prefLanguage && this.props.auth.user.prefLanguage === 'en') })} onClick={() => { this.toggleTranslate(flashcard, flashcards, selectedFlashcards); this.changeLang(flashcard, flashcards, selectedFlashcards) }}> <MaterialIcon icon="translate" size={14} /><span className={classnames({ "tool-tip": this.state.isTranslated || !this.state.isTranslated })} style={{ fontWeight: 'normal' }}>{this.state.isTranslated ? `In ${this.state.selectedLang}` : "In English"}</span></span>)
    }

    toggleTranslate(flashcard, flashcards, selectedFlashcards) {
        if (this.state.seeTranslation.word == flashcard.word) {
            if (this.state.seeTranslation && this.state.seeEnglish) {

                if (this.state.isTranslated) {
                    flashcard.translatedWord = this.state.seeTranslation.translatedWord
                    flashcard.definition = this.state.seeTranslation.definition
                } else {
                    flashcard.translatedWord = this.state.seeEnglish.word
                    flashcard.definition = this.state.seeEnglish.wordDefinition
                }
                this.setState({ isTranslated: !this.state.isTranslated });
            }
        } else {
            this.resetFlashcard(flashcards, selectedFlashcards)
            this.showIndicator();
            this.setState({ isTranslated: true, 'seeTranslation': JSON.parse(JSON.stringify(flashcard)) }, () => {
                const params = {
                    flashcardId: flashcard.word,
                };
                const method = 'GET';
                const pathTemplate = '/getFlashcardById/{flashcardId}';
                apiClient.invokeApi(params, pathTemplate, method, {}, {}, 6).then((result) => {
                    const { word, wordDefinition } = result.data;
                    flashcard.translatedWord = word ? word : "Translation not avaliable"
                    flashcard.definition = wordDefinition ? wordDefinition : "Translation not avaliable"
                    this.setState({ seeEnglish: result.data });
                    this.hideIndicator();
                }, (err) => {
                    console.error(err);
                    this.setState({
                        showAlert: true,
                        alertStyle: 'danger',
                        alertText: 'Cannot translate at the moment. Please try again later.'
                    });
                    setTimeout(() => {
                        this.setState({ showAlert: false });
                    }, 5000);
                    this.hideIndicator();
                });
            });
        }
    }

    // getUnique(arr, comp) {

    //     const unique = arr
    //         .map(e => e[comp])

    //         // store the keys of the unique objects
    //         .map((e, i, final) => final.indexOf(e) === i && i)

    //         // eliminate the dead keys & store unique objects
    //         .filter(e => arr[e]).map(e => arr[e]);

    //     return unique;
    // }

    // getUnique(arr) {
    //     let set = new Set();
    //     return arr.map((v, index) => {
    //         if (set.has(v.id)) {
    //             return false
    //         } else {
    //             set.add(v.id);
    //             return index;
    //         }
    //     }).filter(e => e).map(e => arr[e]);
    // }

    // noDuplicates(arr) {
    //     return [...new Set(arr.map(v => v.id))];
    // }
    selectQuestionLearnerPalOnClickHandler(doNotRefreshFilters, ForFilters) {
        if (this.props.manageActivities.createActivityActiveOwnerFilter !== 'LearnerPal') {
            !ForFilters && sessionStorage.setItem("restoreFilter", true);
            let { dispatch } = this.props;
            dispatch({
                type: CREATE_ACTIVITY_MOVE_FLASHCARD,
                createActivityActiveOwnerFilter: 'LearnerPal',
                refreshFilters: doNotRefreshFilters === true ? false : true
            });
        }
    }

    selectQuestionMyQuestionOnClickHandler() {
        if (this.props.manageActivities.createActivityActiveOwnerFilter === 'LearnerPal') {
            sessionStorage.setItem("restoreFilter", true);
            let { dispatch } = this.props;
            dispatch({
                type: CREATE_ACTIVITY_MOVE_FLASHCARD,
                createActivityActiveOwnerFilter: this.props.auth.user.userName,
                refreshFilters: true
            });
        }
    }

    render() {
        const { errors } = this.state;
        this.filterComponentCssClassName = 'filterBlock custScroll default-skin';
        let flashCards = [];
        let selectedFlashCards = [];
        let context = this;

        if (this.props.manageActivities && this.props.manageActivities.createActivityFlashCards) {
            let skipFlashCards = this.props.manageActivities && this.props.manageActivities.createActivitySelectedFlashCards ? this.props.manageActivities.createActivitySelectedFlashCards : [];
            let allFlashCards = this.props.manageActivities && this.props.manageActivities.createActivityFlashCards ? this.props.manageActivities.createActivityFlashCards : [];
            let selIds = skipFlashCards.map((flash) => flash.id)
            let allIds = allFlashCards.map((flash) => flash.id)

            // var filtered = allIds.filter(
            //     function (e) {
            //         return this.indexOf(e) < 0;
            //     },
            //     selIds
            // );

            var filtered = allIds.filter(function (item) {
                return !selIds.includes(item);
            })

            // console.log(filtered);
            if (filtered != []) {
                // FILTERING FLASHCARDS FROM FILTERED ID's
                var filteredFlashcards = this.props.manageActivities.createActivityFlashCards.filter(function (a) {
                    return this.indexOf(a.id) >= 0;
                }, filtered)
                // console.log(filteredFlashcards)

                // GET UNIQUE ARRAY OF OBJECTS FOR ORIGINAL LIST (UNSELECTED)
                var UniqueFlashcards = Array.from(new Set(filteredFlashcards.map(a => a.id)))
                    .map(id => {
                        return filteredFlashcards.find(a => a.id === id)
                    })
                // console.log(UniqueFlashcards)
                // var finale = this.getUnique(final, 'id')
            }


            for (let i = 0; i < UniqueFlashcards.length; i++) {
                let flashCard = UniqueFlashcards[i];
                let text = flashCard.translatedWord ? flashCard.translatedWord : flashCard.word;
                let definition = flashCard.definition;
                let lang = flashCard.lang
                let chkbox = "çheckbox-id-" + i;
                let tags = [];
                if (flashCard.tags) {
                    flashCard.tags.forEach(tag => {
                        tags.push(<li key={tag + Math.random()}>{tag}</li>)
                    });
                }
                if (!skipFlashCards.includes(flashCard)) {


                    let randomQid = Math.ceil(Math.random() * 10000);
                    flashCards.push(
                        <div dir lang={lang} className="fieldBox QuestionChoiceBox width_100"
                            key={'flash-card-id-' + randomQid + flashCard.id ? flashCard.id : flashCard.word}>
                            <input type="checkbox" id={chkbox} className="css-checkbox select_question"
                                name="authorize" onClick={function () {
                                    context.moveToSelectedFlashCard(flashCard, this);

                                }} />
                            <label htmlFor={chkbox} className="pay_label css-label1" />{this.getTranslationIcon(flashCard, this.props.manageActivities.createActivitySelectedFlashCards, this.props.manageActivities.createActivityFlashCards)}
                            <p className={classnames({ "quesText right": lang !== 'ar' }, { "quesText left": lang == 'ar' })}><h5><span dir lang={lang}>{text}</span></h5>
                                <span dir lang={lang}>{definition}</span></p>
                            {/* {this.getTranslationIcon(flashCard, this.props.manageActivities.createActivityFlashCards, this.props.manageActivities.createActivitySelectedFlashCards)} */}
                            <ul className="keyCourse">
                                {tags}
                            </ul>
                        </div>

                    )
                    // }
                }
            }
            flashCards.push(<div style={{ textAlign: 'center', paddingTop: '22px', display: 'inline-block', width: '100%' }} key='flashCard-load-more'>
                <a href="javascript:void 0" onClick={function () { context.getNextPage() }} style={{
                    border: '1px solid',
                    borderRadius: '3px',
                    'padding': ' 5px 30px'
                }}>Load More</a>
            </div>)
        }

        if (this.props.manageActivities && this.props.manageActivities.createActivitySelectedFlashCards) {
            for (let i = 0; i < this.props.manageActivities.createActivitySelectedFlashCards.length; i++) {

                let flashCard = this.props.manageActivities.createActivitySelectedFlashCards[i];
                let text = flashCard.translatedWord ? flashCard.translatedWord : flashCard.word;
                let definition = flashCard.definition;
                let lang = flashCard.lang
                let chkbox = "çheckbox-selected-id-" + i;
                let tags = [];
                if (flashCard.tags) {
                    flashCard.tags.forEach(tag => {
                        tags.push(<li key={tag + Math.random()}>{tag}</li>)
                    });
                }
                selectedFlashCards.push(
                    <div dir lang={lang} className="fieldBox QuestionChoiceBox width_100" key={'selected-flashcard-id-' + flashCard.word + (Math.random() * 10000)}>
                        <input defaultChecked type="checkbox" id={chkbox} className="css-checkbox select_question"
                            name="authorize" onClick={function () {
                                context.moveToFlashCard(flashCard);
                            }}
                        />
                        <label htmlFor={chkbox} className="pay_label css-label1" />{this.getTranslationIcon(flashCard, this.props.manageActivities.createActivitySelectedFlashCards, UniqueFlashcards)}
                        <p className={classnames({ "quesText right": lang !== 'ar' }, { "quesText left": lang == 'ar' })}><h5><span dir lang={lang}>{text}</span></h5>
                            <span dir lang={lang}>{definition}</span></p>
                        {/* {this.getTranslationIcon(flashCard, this.props.manageActivities.createActivitySelectedFlashCards, this.props.manageActivities.createActivityFlashCards)} */}
                        <ul className="keyCourse">
                            {tags}
                        </ul>
                    </div>
                )
            }
        }

        let selectedCount = this.props.manageActivities && this.props.manageActivities.createActivitySelectedFlashCards ? this.props.manageActivities.createActivitySelectedFlashCards.length : 0;
        let createActivityFlashCardActivityName = this.props.manageActivities.createActivityFlashCardActivityName;


        let readonlyField = <div className="form-group"><label className="control-label">Activity Name:</label>
            <div
                className="posRel fieldBg selectWidth">{this.props.manageActivities.createActivityFlashCardActivityName}</div>
        </div>;

        let randomSelectionBox = selectedFlashCards.length === 0 ? <div className="randomSelection">
            <p>Select flashcards from left panel by clicking on checkbox, Or select random flashcards.</p>
            <div className="flash-inputs">
                <input type="text" id="randomQuestionInput" maxLength="4" />
            </div>
            <a href="javascript:void 0" onClick={() => {
                var count = $("#randomQuestionInput").val();
                if (count && Validator.isInt(count)) {
                    context.selectRandomFlashcards(count);
                }
            }}>Select Random Flashcards
            </a>
        </div> : '';

        return (
            <div>
                {this.getRandomFlashcardBoxDialog()}
                <Breadcrumb
                    breadcrumbsData={
                        [
                            {
                                name: 'Activities', action: () => {
                                    browserHistory.push('/activities');
                                }
                            },
                            {
                                name: 'Create Activity', action: () => {
                                    browserHistory.push({
                                        pathname: '/activities',
                                        state: { isActivityTypeLandingPage: true }
                                    });
                                }
                            },
                            {
                                name: this.props.params && this.props.params.id ? 'Edit Flashcard Activity' : 'Create Flashcard Activity', action: () => {
                                }
                            }
                        ]
                    }
                />
                <span id="expColBtn" className="glyphicon btn-lg collapseBtn glyphicon-menu-down" aria-hidden="true" onClick={this.showCreateActivityDialog} />
                <div className="addQuestionBlock" style={{ display: 'block' }}>
                    <div className="row hidden-md hidden-lg sercFilBtnBox">
                        <div className="col-xs-6 noPad">
                            <button className="ViewFilter-sm" onClick={function () {
                                $('.filterBlock').addClass('TopZero');
                                $('html').css({ 'overflow': 'hidden', 'position': 'fixed' });
                                $('.footer ').hide();
                            }}><span>Filter</span></button>
                        </div>
                        <div className="col-xs-6 noPad">
                            <button className="ViewSearch-sm" onClick={function () {
                                $('.search-sm-Box').addClass('TopZero');
                                $('html').css({
                                    'overflow': 'hidden',
                                    'position': 'fixed',
                                    'width': '100%',
                                    'height': '100%'
                                });
                            }}><span>Search</span></button>
                        </div>
                        <div className="search-sm-Box">
                            <span className="srcHideBox" onClick={function () {
                                $('.search-sm-Box').removeClass('TopZero');
                                $('html').css({ 'overflow': 'auto', 'position': 'inherit' });
                            }} />
                            <div className="input-group">
                                <input type="text" className="form-control searchBar" placeholder="Search"
                                    id="searchBarIdX" />
                                <span className="input-group-btn">
                                    <button className="btn btn-primary" type="button" onClick={function () {
                                        context.selectFlashCardSearchOnChangeHandler({ nativeEvent: { target: { value: $("#searchBarIdX").val() } } });
                                        $('.search-sm-Box').removeClass('TopZero');
                                        $('html').css({ 'overflow': 'auto', 'position': 'inherit' });
                                    }}><span className="searchBtn" /></button>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="filterBlock custScroll default-skin" id="default-skin-demo">
                        <Filter
                            location="create-flashcard-activity"
                            actionName={CREATE_ACTIVITY_FETCH_FLASHCARD_SUCCESS}
                            viewActivityCssClass={this.filterComponentCssClassName}
                            clearFilters={'manageActivities.createActivityFlashCardClearFilters'}
                            refreshFilters={'manageActivities.refreshFilters'}
                            screenNameForElasticSearch={ElasticSearchTypes.Glossary}
                            allColumnsForElasticSearch={ElasticSearchColumns.Glossary}
                            filterColumns={[
                                { name: 'subjects', type: 'array', label: 'Subject', dependency: 'category' },
                                { name: 'category', type: 'text', label: 'Category', dependent: true }
                            ]} />
                    </div>
                    <div className="selectQuestionBox custScroll default-skin">
                        <div className="select-question-heading-container">
                            <h2 className="select-question-search-h2">Select Flashcards</h2>
                            <div className="input-group filterSearchLeft select-question-search">
                                <input type="text" className="form-control" placeholder="Search"
                                    onKeyPress={this.checkEnterKeyPress} />
                                <span className="input-group-btn">
                                </span>
                            </div>
                        </div>
                        <ul className="quesBcrumb">
                            <style>{`
                            .makeMeBold {
                                color: #0f6fb4 !important;
                                font-weight: bold !important;
                                text-decoration: underline !important;
                            }
                            .pointer{
                                cursor: pointer
                            }
                            `}</style>
                            <li><a className={this.props.manageActivities.createActivityActiveOwnerFilter === 'LearnerPal' ? 'makeMeBold' : 'pointer'} onClick={this.selectQuestionLearnerPalOnClickHandler}>
                                LearnerPal Flashcards</a></li>
                            <li><a className={this.props.manageActivities.createActivityActiveOwnerFilter !== 'LearnerPal' ? 'makeMeBold' : 'pointer'} onClick={this.selectQuestionMyQuestionOnClickHandler}>My
                                Flashcards</a></li>
                            {this.props.manageActivities && this.props.manageActivities.createActivityFlashCards &&
                                <li style={{ float: 'right' }}><a id="randomQuestionSelectionButton" onClick={this.showRandomFlashcardDialog} style={{
                                    border: '1px solid',
                                    borderRadius: '3px',
                                    'padding': ' 5px'
                                }}>Select Random Flashcards</a></li>
                            }
                        </ul>
                        <div className="boxForQuestionSelect" id="sel_before" ref="flashcardSelectionBoxRef">
                            {flashCards}
                        </div>
                        <div className="lastBlockEmptyLeft" />
                    </div>
                    <div className="questionSelectedBox custScroll default-skin">
                        <h2 className="select-question-search-h2">Flashcards Selected <span className="countResFix"><a
                            className="openSelBox" onClick={this.viewSelected}>View Selected Flashcards</a>
                            <span className="selectedCount">{selectedCount}</span>
                        </span>
                        </h2>
                        <div className="selQuestions">
                            <h4 className="selectedHeadingActivity">{createActivityFlashCardActivityName}</h4>
                            <ul className="quesBcrumb">
                                <li style={{ float: 'right' }}><a href="javascript:void 0" data-toggle="modal" data-target="#PreviewModal" onClick={this.selectedFlashCardPreviewOnClickHandler} style={{
                                    border: '1px solid',
                                    borderRadius: '3px',
                                    'padding': ' 5px'
                                }}>Preview</a></li>
                                <li style={{ float: 'right' }}><a href="javascript:void 0" id="clearAllQues" onClick={this.selectedFlashCardClearAllOnClickHandler}>Clear All</a></li>
                            </ul>
                        </div>
                        <div className="boxForQuestionSelect" id="sel_after">
                            {selectedFlashCards}
                        </div>
                        <div className="lastBlockEmptyLeft text-right">
                            <Button onClick={() => { this.cancelCreate(); this.resetFlashcard(this.props.manageActivities.createActivitySelectedFlashCards, this.props.manageActivities.createActivityFlashCards); }}
                                >
                                Cancel
                            </Button>
                            <Button onClick={() => { this.setState({ saveAndExit: true }, this.saveAndAssignClickHandler); this.resetFlashcard(this.props.manageActivities.createActivitySelectedFlashCards, this.props.manageActivities.createActivityFlashCards); }}
                                title={(!this.props.manageActivities.createActivitySelectedFlashCards
                                    || this.props.manageActivities.createActivitySelectedFlashCards.length === 0) ? 'Please add flashcards to the activity' : 'Save & Exit'}
                                disabled={!this.props.manageActivities.createActivitySelectedFlashCards
                                    || this.props.manageActivities.createActivitySelectedFlashCards.length === 0}>
                                Save &amp; Exit
                            </Button>
                            <Button onClick={() => { this.setState({ saveAndExit: false }, this.saveAndAssignClickHandler); this.resetFlashcard(this.props.manageActivities.createActivitySelectedFlashCards, this.props.manageActivities.createActivityFlashCards); }}
                                title={(!this.props.manageActivities.createActivitySelectedFlashCards
                                    || this.props.manageActivities.createActivitySelectedFlashCards.length === 0) ? 'Please add flashcards to the activity' : 'Save & Assign'}
                                disabled={!this.props.manageActivities.createActivitySelectedFlashCards
                                    || this.props.manageActivities.createActivitySelectedFlashCards.length === 0}>
                                Save &amp; Assign
                            </Button>

                        </div>
                    </div>
                </div>
                {this.getPreviewDialog()}
                {this.getFlashCardSelectionErrorDialog()}
                {this.getSaveAndAssignDialog()}
                <ProgressIndicator show={this.state.loading} />
                {this.getCreateActivityInfoDialog()}
            </div>
        )
    }
}
function mapStateToProps(state) {
    return { ...state };
}
export default connect(mapStateToProps)(withRouter(CreateFlashCardActivity));
