import config from '../config.js'
import apigClientFactory from 'aws-api-gateway-client';
import { loadAuth2 } from 'gapi-script';
import AWS from 'aws-sdk';
export default class AWSApiClient {

    static invokeApi(params, pathTemplate, method, additionalParams, body, urlID = "") {
        const userOb = JSON.parse(localStorage.getItem('userObj'));
        if (userOb && userOb.federatedIdentity) {
            loadAuth2(config.googleClientId, config.googleClassRoomScopes).then((auth2) => {
                console.log("auth2" + auth2)
                let token = auth2.currentUser.get().getAuthResponse()
                refreshIdentityToken(token);
                // this.setState({ "gToken": token });
            });

            const refreshIdentityToken = (response) => {

                const token = response['id_token'];
                const access_token = response['access_token'];
                AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                    IdentityPoolId: config.identityPoolId,
                    Logins: {
                        'accounts.google.com': token
                    }
                });
                AWS.config.region = config.region;
                AWS.config.credentials.clearCachedId();
                AWS.config.credentials.get(function (err, data) {
                    if (err) {
                        console.log("Error: " + err);
                    }
                    localStorage.setItem('accessToken', access_token);
                    localStorage.setItem('identityToken', token);
                    localStorage.setItem('accessKeyId', AWS.config.credentials.accessKeyId);
                    localStorage.setItem('secretAccessKey', AWS.config.credentials.secretAccessKey);
                    localStorage.setItem('sessionToken', AWS.config.credentials.sessionToken);

                });
                // additionalParams["headers"]["Authorization"] = 'Bearer ' + access_token

                return AWS.config.credentials.getPromise();
            }
        }
        const setUserName = (additionalParams) => {

            const paramsOption = 'headers';
            const userNameAttr = 'username-id';
            const langAttr = 'language';
            const userObj = JSON.parse(localStorage.getItem('userObj'));
            const accessToken = userObj && userObj.federatedIdentity ? localStorage.getItem('identityToken') : localStorage.getItem('token');


            if (!userObj)
                return additionalParams

            if (additionalParams === undefined) {
                additionalParams = {};
                additionalParams[paramsOption] = {};
                additionalParams[paramsOption][userNameAttr] = userObj.userName;
                additionalParams[paramsOption][langAttr] = userObj.prefLanguage ? userObj.multiLingualLicense ? userObj.prefLanguage : 'en' : 'en';
                additionalParams[paramsOption]["Authorization"] = 'Bearer ' + accessToken
            } else {
                if (additionalParams[paramsOption] === undefined)
                    additionalParams[paramsOption] = {};
                additionalParams[paramsOption][userNameAttr] = userObj.userName;
                additionalParams[paramsOption][langAttr] = userObj.prefLanguage ? userObj.multiLingualLicense ? userObj.prefLanguage : 'en' : 'en';
                additionalParams[paramsOption]["Authorization"] = 'Bearer ' + accessToken
            }

            if (additionalParams['accessToken']) {
                additionalParams[paramsOption]['accessToken'] = additionalParams.accessToken;
                delete additionalParams['accessToken'];
            }

            return additionalParams;
        }

        additionalParams = setUserName(additionalParams);

        var invokeUrl = config["invokeURL" + urlID];
        var awsConfig = {
            secretKey: localStorage.getItem('secretAccessKey'),
            sessionToken: localStorage.getItem('sessionToken'),
            region: config.region,
            apiKey: undefined,
            defaultContentType: 'application/json',
            defaultAcceptType: 'application/json',
            invokeUrl: invokeUrl
        };

        var apigClient = apigClientFactory.newClient(awsConfig);

        return new Promise((resolve, reject) => (
            apigClient.invokeApi(params, pathTemplate, method, additionalParams, body)
                .then(function (result) {
                    resolve(result);
                }).catch(function (result) {
                    reject(result);
                })
        ));
    }

}