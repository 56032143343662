import React from 'react';
import SignupForm from './signup-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import virtualLogo from '../../assets/images/virtual-logo.png'
import stemLabsLogo from '../../assets/images/stem-labs.png'
import { userLoginRequest, processRequestToCognito, getIdentityProfile } from '../../actions/login-action';
import "./custom.css";
import { PoweredByLearnerPal } from '../footer/PoweredByLearnerPal.js';


class TeacherSignup extends React.Component {

  render() {
    return (
      <div className="loginPage teacher-signup">
        <div className="">
          <div className="topBar"></div>
          <div className=""> 
            <a href="#" className="LogoAnch">
              <img src={virtualLogo} alt="-" className="logoClass" />
              <img src={stemLabsLogo} alt="-" className="logoClass" />
            </a>
            <SignupForm userLoginRequest={this.props.userLoginRequest} processRequestToCognito={processRequestToCognito} getIdentityProfile={getIdentityProfile} />
          </div>
          <PoweredByLearnerPal />

        </div>
      </div>
    );
  }
}

TeacherSignup.propTypes = {
  userLoginRequest: PropTypes.func.isRequired,
  processRequestToCognito: PropTypes.func.isRequired,
  getIdentityProfile : PropTypes.func.isRequired
}

export default connect(null, { userLoginRequest, processRequestToCognito, getIdentityProfile })(TeacherSignup);
