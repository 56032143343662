// import axios from "axios";
import apiClient from "./api-client";


export default class ElasticSearchStore {
    elasticSearchPostfixForSearch = '_search';
    axiosInstance=null;
    indexName="learnerpal";

    constructor(args = {esIndexName:'learnerpal/'}){
        this.indexName = args.esIndexName;
        // this.axiosInstance = axios.create({
        //     baseURL: 'https://search-learnerpal-eimkio3umgx5fnpuy7ypsrrsri.us-east-1.es.amazonaws.com/'+args.esIndexName+'/',
        //     timeout: 60000,
        //     headers: {'Content-Type': 'application/json'},
        //     responseType: 'json',
        //     // `transformRequest` allows changes to the request data before it is sent to the server
        //     // This is only applicable for request methods 'PUT', 'POST', and 'PATCH'
        //     // The last function in the array must return a string or an instance of Buffer, ArrayBuffer,
        //     // FormData or Stream
        //     transformRequest: [function (data) {
        //         // Do whatever you want to transform the data
        //
        //         return data;
        //     }],
        //
        //     // `transformResponse` allows changes to the response data to be made before
        //     // it is passed to then/catch
        //     transformResponse: [function (data) {
        //         // Do whatever you want to transform the data
        //         if(typeof data === 'string'){
        //             data = JSON.parse(data);
        //         }
        //         var results = [];
        //         if (data.hits && data.hits.hits.length > 0) {
        //             results = data.hits.hits.map(function (record) {
        //                 // do something with each record.
        //                 return record._source;
        //             });
        //         }
        //         if(data.aggregations){
        //             return {
        //                 aggregations: data.aggregations,
        //                 results: results,
        //                 total:data.hits.total
        //             };
        //         }else{
        //             return results;
        //         }
        //     }]
        // });
    }

    query(dispatch, type, options, columns, successCallback, failureCallback,aggregationOptions,sizeOverride) {
        // notify component that data loading is in progress.
        let data = {
            query: options,
            size: 10000,
            from:0
        };
        if(aggregationOptions){
            data.aggs = aggregationOptions;
        }
        if(sizeOverride){
            data.size = parseInt(sizeOverride);
        }
        if (columns && columns.length > 0) {
            data['_source'] = {
                'includes': columns
            }
        }

        let that = this;
        return new Promise((resolve, reject)=> {
            apiClient.invokeApi({}, '/getesdata', 'POST', {}, { payload: data, index: this.indexName, esDocumentType: type }, '5').then(function (result) {

                let reponse = {};
                reponse.results = [];
                if (result.data.hits && result.data.hits.hits.length > 0) {
                    for (var a = 0; a < result.data.hits.hits.length; a++) {
                        reponse.results.push(result.data.hits.hits[a]._source);
                    }
                }
                reponse.totalRecords = result.data.hits ? result.data.hits.total : 0;

                if(result.data.aggregations){
                    reponse.aggregations =  result.data.aggregations
                }
                console.log(reponse);

                // successCallback(reponse.results, reponse.totalRecords);
                resolve(that.handleSuccess(dispatch, reponse, successCallback))
            }, function(error) {
                reject(that.handleError(dispatch, error, failureCallback))
            });
            // this.axiosInstance({
            //     method: 'post',
            //     url: '/' + type + '/' + this.elasticSearchPostfixForSearch,
            //     data: JSON.stringify(data)
            // }).then(data=>(resolve(this.handleSuccess(dispatch, data, successCallback))), error=>(reject(this.handleError(dispatch, error, failureCallback))));
        });
    }

    getRecord(dispatch, type, id, columns, successCallback, failureCallback) {
        let data = {};
        if (columns && columns.length > 0) {
            data['_source'] = {
                'includes': columns
            }
        }
        if (columns && columns.length > 0) {
            data['_source'] = {
                'includes': columns
            }
        }
        let that = this;
        apiClient.invokeApi({}, '/getesdata', 'POST', {}, { id: "/"+id, index: this.indexName, esDocumentType: type }, '5').then(function (result) {

            let reponse = {};
            reponse.results = [];
            if (result.data.hits && result.data.hits.hits.length > 0) {
                for (var a = 0; a < result.data.hits.hits.length; a++) {
                    reponse.results.push(result.data.hits.hits[a]._source);
                }
            }
            reponse.totalRecords = result.data.hits ? result.data.hits.total : 0;
            if(result.data.aggregations){
                reponse.aggregations =  result.data.aggregations
            }

            console.log(reponse);

            // successCallback(reponse.results, reponse.totalRecords);
            that.handleSuccess(dispatch, reponse, successCallback)
        }, function(error) {
            that.handleError(dispatch, error, failureCallback)
        });
        // this.axiosInstance({
        //     method: 'get',
        //     url: '/' + type + '/' + id
        // }).then(data=>(this.handleSuccess(dispatch, data, successCallback)), error=>(this.handleError(dispatch, error, failureCallback)));
    }

    handleError(dispatch, error, failureCallback){
        let result = {
            status: 400,
            data: []
        };
        if (failureCallback && typeof  failureCallback === 'function') {
            failureCallback(result);
        }
        return result;
    }

    handleSuccess(dispatch, response, successCallback){
        let result = {
            status: 200,
            data: response
        };
        if (successCallback && typeof  successCallback === 'function') {
            successCallback(result);
        }
        return result;
    }
}
