export default {
  region: "us-east-1",
  stage: "dev",
  identityPoolId: "us-east-1:e8323964-5f26-43e2-978d-d679bfe1953a",
  userPoolId: "us-east-1_0rCK33LnM",
  clientId: "3liljitahhvath51fhmc6lufc0",
  invokeURL: "https://s96qc9agb9.execute-api.us-east-1.amazonaws.com/dev",
  invokeURL2: "https://s52vokms2h.execute-api.us-east-1.amazonaws.com/dev",
  invokeURL3: "https://id3rjzq9l5.execute-api.us-east-1.amazonaws.com/dev",
  invokeURL4: "https://ulwq41znze.execute-api.us-east-1.amazonaws.com/dev",
  invokeURL5: "https://0ew3xu005j.execute-api.us-east-1.amazonaws.com/dev",
  invokeURL6: "https://tuemedtqb9.execute-api.us-east-1.amazonaws.com/dev",
  invokeURL7: "https://bn9rabfnbl.execute-api.us-east-1.amazonaws.com/dev",
  cognitoURL: "cognito-idp.us-east-1.amazonaws.com/us-east-1_0rCK33LnM",
  esURL:
    "https://search-learnerpal-g2gaodza7m5uml5faj4m3le2su.us-east-1.es.amazonaws.com/",
  teacherGroupName: "LPTeacher",
  studentGroupName: "LPStudent",
  adminGroupName: "LPAdmin",
  googleClientId: '456481150122-bcqs65qu9p4d6be5ut2abvavvemba12m.apps.googleusercontent.com',
  googleClassRoomScopes: 'https://www.googleapis.com/auth/classroom.courses.readonly https://www.googleapis.com/auth/classroom.announcements.readonly https://www.googleapis.com/auth/classroom.student-submissions.students.readonly https://www.googleapis.com/auth/classroom.coursework.students https://www.googleapis.com/auth/classroom.coursework.me https://www.googleapis.com/auth/classroom.rosters.readonly https://www.googleapis.com/auth/classroom.student-submissions.me.readonly https://www.googleapis.com/auth/drive.readonly',
  languages: [
    { id: "ar", name: "Arabic" },
    // { id: "zh", name: "Chinese Simplified" },
    // { id: "zh-TW", name: "Chinese Traditional" },
    // { id: "cs", name: "Czech" },
    // { id: "da", name: "Danish" },
    // { id: "nl", name: "Dutch" },
    { id: "en", name: "English" },
    // { id: "fi", name: "Finnish" },
    // { id: "fr", name: "French" },
    // { id: "de", name: "German" },
    // { id: "he", name: "Hebrew" },
    // { id: "id", name: "Indonesian" },
    // { id: "it", name: "Italian" },
    // { id: "ja", name: "Japanese" },
    // { id: "ko", name: "Korean" },
    // { id: "pl", name: "Polish" },
    // { id: "pt", name: "Portuguese" },
    // { id: "ru", name: "Russian" },
    { id: "es", name: "Spanish" },
    // { id: "sv", name: "Swedish" },
    // { id: "tr", name: "Turkish" }
  ]
};
