import { gapi, loadAuth2 } from 'gapi-script';
import React from 'react';
import validateInput from '../../validations/login';
import TextFieldGroup from '../forms/text-field-group';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import ProgressIndicator from "../progress-indicator/progress-indicator";
import apiClient from "../../actions/api-client";
import ClevertapReact from "clevertap-react";
import config from '../../config';
import { Alert } from "react-bootstrap";
import AWS from 'aws-sdk';
import {
    CognitoUserPool,
    AuthenticationDetails,
    CognitoUser
} from 'amazon-cognito-identity-js';
import Signup from '../signup/signup-form';
import './identityButton.css'
import googleIcon from '../login/btn_google_light_normal_ios.svg';

class LoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            errors: {},
            isGoogleSignIn: false,
            routepath: props.route,
            isLoading: false,
            isSignedIn: false,
            isNew: true,
            autoSignIn: window.location.search.includes("?autoSignIn") || window.location.search.includes("?autosignin"),
            showAlert: false, alertText: '', alertStyle: '',
            screenWidth: window.innerWidth,
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.showIndicator = this.showIndicator.bind(this);
        this.hideIndicator = this.hideIndicator.bind(this);
        this.attachSignin = this.attachSignin.bind(this);
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    isValid() {
        const { errors, isValid } = validateInput(this.state);
        if (!isValid) {
            this.setState({ errors });
        }
        return isValid;
    }

    componentDidMount() {
        window.addEventListener('DOMContentLoaded', (event) => {
            if (this.state.autoSignIn) {
                setTimeout(function () {
                    document.getElementById('googleLogin').click();
                }, 1600);
            }
        });
        const successCallback = this.onSuccess.bind(this);
        let that = this;
        loadAuth2(gapi, config.googleClientId, config.googleClassRoomScopes).then((auth2) => {
            console.log("Google Login ->", auth2.isSignedIn.get());
            // if (auth2.isSignedIn.get()) {
            //     console.log(auth2.isSignedIn.get());
            //     console.log(that.props.routepath);
            //     if (that.props.routepath) {
            //         this.showIndicator()
            //         let response = {};
            //         let googleUser = auth2.currentUser.get();
            //         response.id_token = googleUser.getAuthResponse().id_token;
            //         response.access_token = googleUser.getAuthResponse().access_token;
            //         let profile = googleUser.getBasicProfile();
            //         response.firstName = profile.getGivenName();
            //         response.lastName = profile.getFamilyName();
            //         response.email = profile.getEmail();
            //         console.log(this.props.routepath);
            //         let that = this;
            //         that.props.processRequestToCognito(response, this.props.routepath).then(
            //             (res) => {
            //                 that.hideIndicator();
            //                 browserHistory.push(res);
            //             }
            //         ).catch(
            //             (err) => {
            //                 console.log(err);
            //             }
            //         )
            //     }
            // } else {
            //     console.log("Please log in to Google")
            // }
            this.attachSignin(document.getElementById('googleLogin'), auth2);
           if(document.getElementById('googleLogin') && this.state.autoSignIn ){ this.setState({ showAlert: true, alertText: 'AutoSign has been initiated. If you have NOT been signed in automatically, please select “Login with Google” to access your assignment.', alertStyle: 'success' });}

        });
    }

    attachSignin(element, auth2) {
        auth2.attachClickHandler(element, {},
            (googleUser) => {
                let response = {};
                response.id_token = googleUser.getAuthResponse().id_token;
                response.access_token = googleUser.getAuthResponse().access_token;
                let profile = googleUser.getBasicProfile();
                response.firstName = profile.getGivenName();
                response.lastName = profile.getFamilyName();
                response.email = profile.getEmail();
                this.onSuccess(response);

            }, (error) => {
                console.log(JSON.stringify(error))
            });
    }

    onSuccess(response) {
        console.log(response);
        this.showIndicator();
        this.setState({ errors: {}, isLoading: true });
        this.props.processRequestToCognito(response, this.props.routepath).then(
            (res) => {
                console.log(res);
                this.setState({showAlert : false, alertText: ''});
                browserHistory.push(res);
                this.hideIndicator();
            },
            (err) => {
                if (err == '/signup') {
                    this.hideIndicator();
                    browserHistory.push(err);
                    return (<Signup />);
                } else {
                    this.setState({ isLoading: false, errors: err });
                    this.hideIndicator();
                }
            }
        );
    }

    onLoginFailed(err) {
        this.setState({
            isSignedIn: false,
            error: err,
        })
    }


    googleButton() {
        return (
            <div className="g-loginDiv" style={{ paddingTop: "120px" }}>
                <img className="google-login" src={googleIcon} /><button id="googleLogin" type="button">Login with Google</button>
            </div>
        )
    }

    onSubmit(e) {
        e.preventDefault();
        this.showIndicator();
        if (this.isValid()) {
            this.setState({ errors: {}, isLoading: true });
            let that = this;
            this.props.userLoginRequest(this.state).then(
                (res) => {
                    // ClevertapReact.event("User Logged In", {'userName' : that.state.username});
                    let body = {
                        userName: that.state.username,
                        userPassword: that.state.password
                    };

                    apiClient.invokeApi({}, '/user', 'PUT', {}, body, "2").then(function (result) {

                    });
                    browserHistory.push(res);
                    this.hideIndicator();
                },
                (err) => {

                    if (err.message === 'Password attempts exceeded') {
                        err.message = "Password attempts exceeded, please try again in 1 minute"
                    }
                    this.setState({ isLoading: false, errors: err });
                    this.hideIndicator();
                }
            );
        } else {
            this.setState({ isLoading: false, loading: false });
            // this.hideIndicator();
        }
    }



    showIndicator() {
        this.setState({
            ...this.state,
            loading: true
        });
    }

    hideIndicator() {
        this.setState({
            ...this.state,
            loading: false
        });
    }

    render() {
        const { errors, isLoading } = this.state;
        const { screenWidth } = this.state;
        const isMobile = screenWidth <= 768;

        return (
            <div>
                <form className="form-signin" onSubmit={this.onSubmit}>
                    <h2 className="form-signin-heading">Sign In</h2>
                    <TextFieldGroup
                        error={errors.username}
                        label="Username"
                        field="username"
                        value={this.state.username}
                        placeholder="Enter username"
                        onChange={this.onChange}
                        selectWidth={false}
                    />
                    <TextFieldGroup
                        error={errors.password}
                        label="Password"
                        field="password"
                        type="password"
                        value={this.state.password}
                        placeholder="Enter password"
                        onChange={this.onChange}
                        selectWidth={false}
                    />
                    {errors && <span style={{ 'color': 'red' }}>{errors.message}</span>}
                    <button className="btn btn-lg btn-primary btn-block" disabled={isLoading}>Login</button>

                    {this.googleButton()}
                </form>

                {this.state.showAlert && !isMobile && this.state.autoSignIn &&
                    <div className="col">
                            <Alert bsStyle={this.state.alertStyle}
                            bsClass={"customAlert"}
                                onDismiss={this.handleAlertDismiss}>
                                {this.state.alertText}
                            </Alert>
                    </div>
                }
                {/* {this.CreateGoogleUser() } */}
                <ProgressIndicator show={this.state.loading} />
            </div>
        );
    }
}

LoginForm.propTypes = {
    userLoginRequest: PropTypes.func.isRequired,
    processRequestToCognito: PropTypes.func.isRequired,
    getIdentityProfile: PropTypes.func.isRequired
};

LoginForm.contextTypes = {
    router: PropTypes.object.isRequired
};


export default LoginForm;
