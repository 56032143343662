/**
 * Created by ashahab on 6/22/2017.
 */
import { gapi, loadAuth2 } from 'gapi-script';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import config from "../../config";
import AWS from 'aws-sdk';
import {
    CognitoUserPool,
    AuthenticationDetails,
    CognitoUser
} from 'amazon-cognito-identity-js';

class Breadcrumb extends Component {

    constructor(props) {
        super(props);
        this.getAwsCredentials = this.getAwsCredentials.bind(this);
        this.refreshIdentityToken = this.refreshIdentityToken.bind(this);
    }

    componentDidMount() {
        if (!this.props.auth.user.federatedIdentity) {
            this.getAwsCredentials();
        } else {

            loadAuth2(gapi, config.googleClientId, config.googleClassRoomScopes).then((auth2) => {
                console.log("auth2" + auth2)
                let token = auth2.currentUser.get().getAuthResponse()
                this.refreshIdentityToken(token);
                this.setState({ "gToken": token });
            });

            // window.gapi.load('auth2', () => {
            //     this.auth2 = gapi.auth2.init({
            //         client_id: config.googleClientId,
            //         // Scopes to request in addition to 'profile' and 'email'
            //         //scope: 'additional_scope'
            //     })

            //     this.auth2.then(() => {

            //         console.log('on init');
            //         let currentUser = this.auth2.currentUser.get();
            //         currentUser.reloadAuthResponse().then(
            //             (result)=> {
            //                 this.refreshIdentityToken(result);
            //             },
            //             (err) => {
            //                 console.log("Could Not refresh Idenity Token", err);
            //             }
            //         );

            //     });
            // });
        }
    }

    getAwsCredentials() {
        AWS.config.update({ region: 'us-east-1' });

        let userPool = new CognitoUserPool({
            UserPoolId: config.userPoolId,
            ClientId: config.clientId
        });
        let cognitoUser = userPool.getCurrentUser();
        cognitoUser.getSession(function (err, session) {
            if (session) {
                console.log('session validity: ' + session.isValid());
                localStorage.setItem('token', session.getIdToken().getJwtToken());
            }
        });

        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: config.identityPoolId,
            Logins: {
                [config.cognitoURL]: localStorage.getItem('token')
            }
        });

        // AWS.config.credentials.clearCachedId();
        // We can set the get method of the Credentials object to retrieve
        // the unique identifier for the end user (identityId) once the provider
        // has refreshed itself
        AWS.config.credentials.clearCachedId();
        AWS.config.credentials.get(function (err) {
            if (err) {
                console.log("Error: " + err);
            }
            // localStorage.setItem('token', result.getIdToken().getJwtToken());
            localStorage.setItem('accessKeyId', AWS.config.credentials.accessKeyId);
            localStorage.setItem('secretAccessKey', AWS.config.credentials.secretAccessKey);
            localStorage.setItem('sessionToken', AWS.config.credentials.sessionToken);
        });

        AWS.config.credentials.clearCachedId();
        return AWS.config.credentials.getPromise();
    }

    refreshIdentityToken(response) {

        const token = response['id_token'];
        const access_token = response['access_token'];
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: config.identityPoolId,
            Logins: {
                'accounts.google.com': token
            }
        });
        AWS.config.region = config.region;
        AWS.config.credentials.clearCachedId();
        AWS.config.credentials.get(function (err, data) {
            if (err) {
                console.log("Error: " + err);
            }
            localStorage.setItem('accessToken', access_token);
            localStorage.setItem('identityToken', token);
            localStorage.setItem('accessKeyId', AWS.config.credentials.accessKeyId);
            localStorage.setItem('secretAccessKey', AWS.config.credentials.secretAccessKey);
            localStorage.setItem('sessionToken', AWS.config.credentials.sessionToken);
        });

        return AWS.config.credentials.getPromise();
    }

    render() {

        let tempHolder = this.props.breadcrumbsData;
        let liArray = [];
        for (let i = 0; i < tempHolder.length - 1; i++) {
            let breadcrumbLink = tempHolder[i];
            liArray.push(<li key={i}><a href="javascript:void 0" className="linkAnch" onClick={breadcrumbLink.action}>{breadcrumbLink.name}</a></li>);
        }
        liArray.push(<li key={tempHolder.length - 1}><span className="currentPage">{tempHolder[tempHolder.length - 1].name}</span></li>);

        return (
            <div className="container-fluid BcrumNsteps" style={{ background: this.props.backColor }}>
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-lg-12">
                        <div className="breadCrumbs">
                            <ul style={{/* {"white-space": "nowrap"} */ }}>
                                {liArray}
                            </ul>
                        </div>

                    </div>

                </div>
            </div>
        )
    }
}
function mapStateToProps(state) {
    return { ...state };
}
export default connect(mapStateToProps)(Breadcrumb);
