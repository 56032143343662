// import axios from 'axios';
import {SET_MESSAGE_ACTIVITIES,FETCH_ALL_ACTIVITIES , RECEIVED_ACTIVITY_RESULTS , FETCH_ACTIVITY_FAILED } from './types';
import config from '../config.js'
import apiClient from "./api-client";

let elasticSearchPostfixForSearch = '_search';

// let axiosInstance = axios.create({
//     baseURL: config.esURL,
//     timeout: 60000,
//     headers: {'Content-Type': 'application/json'},
//     responseType: 'json',
//     // `transformRequest` allows changes to the request data before it is sent to the server
//     // This is only applicable for request methods 'PUT', 'POST', and 'PATCH'
//     // The last function in the array must return a string or an instance of Buffer, ArrayBuffer,
//     // FormData or Stream
//     transformRequest: [function (data) {
//         // Do whatever you want to transform the data
//         return data;
//     }],
//
//     // `transformResponse` allows changes to the response data to be made before
//     // it is passed to then/catch
//     transformResponse: {function (data) {
//         let reponse = {};
//         reponse.results = [];
//         if (data.hits && data.hits.hits.length > 0) {
//             reponse.results = data.hits.hits.map(function (record) {
//                 // do something with each record.
//                 return record._source;
//             });
//         }
//         reponse.totalRecords =  data.hits.total;
//         return reponse;
//     }}
// });

let handleSuccess = (dispatch, response, alertText, alertStyle, showAlert, totalRecords) => {
    dispatch({
        type: RECEIVED_ACTIVITY_RESULTS,
        status: 200,
        data: JSON.parse(JSON.stringify(response)),
        alertText: alertText,
        alertStyle: alertStyle,
        showAlert: showAlert,
        totalRecords:totalRecords
    });
};

let handleError = (dispatch, error, alertText, alertStyle, showAlert) => {
    dispatch({
        type: FETCH_ACTIVITY_FAILED,
        status: 400,
        data: error,
        alertText: alertText,
        alertStyle: alertStyle,
        showAlert: showAlert
    })
};

export default class ManageActivityActions {
    static fetchAllActivities(dispatch, owner, type = 'activity', options = {
        // TODO - get visible to value from logged in in user.
        'match': {
            'visibleTo': 'public,' + owner
        }
    }, columns = [
        'id', 'type', 'name', 'ownerName', 'createdAt', 'subjects', 'documentLink', 'activityType', 'keywords', 'questions', 'fileName', 'originalOwner', 'tags', 'activityState'
    ], alertText = "", alertStyle = "", showAlert = false)
    {
        // notify component that data loading is in progress.
        dispatch({
            type: FETCH_ALL_ACTIVITIES,
            options
        });

        let data = {
            query: options,
            size:10000
        };
        if (columns && columns.length > 0) {
            data['_source'] = {
                'includes': columns
            }
        }

        apiClient.invokeApi({}, '/getesdata', 'POST', {}, { payload: data, index: 'learnerpal/', esDocumentType: type }, '5').then(function (result) {

            let response = {};
            response.results = [];
            if (result.data.hits && result.data.hits.hits.length > 0) {
                for (var a = 0; a < result.data.hits.hits.length; a++) {
                    response.results.push(result.data.hits.hits[a]._source);
                }
            }
            response.totalRecords = result.data.hits ? result.data.hits.total : 0;

            console.log(response);

            handleSuccess(dispatch, response.results, alertText, alertStyle, showAlert, response.totalRecords)
        }).catch(function (error) {

            console.error(error);
            handleError(dispatch, error,alertText, alertStyle, showAlert)
        });

        // axiosInstance({
        //     method: 'post',
        //     url: '/' + type + '/' + elasticSearchPostfixForSearch,
        //     data: JSON.stringify(data)
        // }).then(data=>(), error=>());
    }

    static getActivity(dispatch, type = 'activity', id = 'act108', columns = [
        'id', 'type', 'name', 'ownerName', 'createdAt', 'subjects', 'documentLink', 'activityType', 'questions'
    ]) {
        // TODO - add visible to in query and take value from logged in user.
        let data = {};
        if (columns && columns.length > 0) {
            data['_source'] = {
                'includes': columns
            }
        }
        if (columns && columns.length > 0) {
            data['_source'] = {
                'includes': columns
            }
        }
        apiClient.invokeApi({}, '/getesdata', 'POST', {}, { id: "/" + id, index: 'learnerpal/', esDocumentType: type }, '5').then(function (result) {

            let response = {};
            response.results = [];
            if (result.data.hits && result.data.hits.hits.length > 0) {
                for (var a = 0; a < result.data.hits.hits.length; a++) {
                    response.results.push(result.data.hits.hits[a]._source);
                }
            }
            response.totalRecords = result.data.hits ? result.data.hits.total : 0;

            console.log(response);

            handleSuccess(dispatch, response.results)
        }).catch(function (error) {

            console.error(error);
            handleError(dispatch, error)
        });

        // .then(data=>(handleSuccess(dispatch, data.data.results)), error=>(handleError(dispatch, error)));
    }
    static viewActivity(dispatch, type){
        dispatch({
            type:type
        })
    }
    static showFullQuestion(dispatch, type, fullQuestion){
        dispatch({
            type:type,
            fullQuestion:fullQuestion
        })
    }
    static viewActivitySuccess(dispatch, type, viewActivityData){
        dispatch({
            type:type,
            viewActivityData:viewActivityData
        })
    }
    static viewActivityFailure(dispatch, type){
        dispatch({
            type:type
        })
    }
    static closeViewActivity(dispatch, type, data){
        dispatch({
            type:type,
            skipAlterForAllRecords:true,
            data: data
        })
    }

    static setSearchResult(dispatch, type, searchResult){
        dispatch({
            type:type,
            results:searchResult
        })
    }
    static setMessage(dispatch, showAlert, alertText,alertStyle, callBack){
        dispatch({
            type:SET_MESSAGE_ACTIVITIES,
            showAlert:showAlert,
            alertText:alertText,
            alertStyle:alertStyle
        });
        callBack();
    }
}
