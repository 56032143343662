import React, {Component} from "react";
import _404 from "./404.jpg";
export default class PageNotFound extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <div className="container-fluid" style={{'text-align': 'center'}}>
            <h1>404</h1>
            <br />
            <img src={_404}/>
        </div>
    }
}