import React, { Component } from "react";
import apiClient from "../../../actions/api-client";
import { browserHistory } from "react-router";
import LoginUtils from "../../common/login-utils";
import Breadcrumb from "../../breadcrumb/breadcrumb";
import ProgressIndicator from "../../progress-indicator/progress-indicator";
import ManageCourseActions from "../../../actions/manage-courses-actions";
import config from "../../../config";
import { connect } from "react-redux";
import "../assignments.css";
import $ from "jquery";
import SelectFieldGroup from "../../forms/select-field-group";
import { Alert, Button, Modal } from "react-bootstrap";
import CustomDatePicker from "../../datePicker/date-picker"
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import ManageAssignmentActions from '../../../actions/manage-assignments-actions';
import ClevertapReact from "clevertap-react";

class CreateAssignment extends Component {

    constructor(props) {
        super(props);
        this.getBreadcrumbs = this.getBreadcrumbs.bind(this);
        this.showIndicator = this.showIndicator.bind(this);
        this.hideIndicator = this.hideIndicator.bind(this);
        this.getCourse = this.getCourse.bind(this);
        this.onChange = this.onChange.bind(this);
        this.toggleSelectionAll = this.toggleSelectionAll.bind(this);
        this.toggleActivity = this.toggleActivity.bind(this);
        this.assign = this.assign.bind(this);
        this.getAssignDialog = this.getAssignDialog.bind(this);
        this.saveAssignment = this.saveAssignment.bind(this);
        this.studentSearchTermChange = this.studentSearchTermChange.bind(this);
        this.studentDueDateChange = this.studentDueDateChange.bind(this);
        this.assignPeriodChange = this.assignPeriodChange.bind(this);
        this.activityOnChange = this.activityOnChange.bind(this);
        this.selectAllStudents = this.selectAllStudents.bind(this);
        this.clearAllStudents = this.clearAllStudents.bind(this);
        this.removeTab = this.removeTab.bind(this);
        this.selectPeriodOnChange = this.selectPeriodOnChange.bind(this);
        this.selectAssessmentType = this.selectAssessmentType.bind(this);
        this.toggleAllStudents = this.toggleAllStudents.bind(this);
        this.checkEnterKeyPress = this.checkEnterKeyPress.bind(this);
        this.getHelpDialog = this.getHelpDialog.bind(this);
        this.setTimeStampStart = this.setTimeStampStart.bind(this);
        this.setTimeStampEnd = this.setTimeStampEnd.bind(this);
        this.setLockReport = this.setLockReport.bind(this);
        this.emptyAvailableFrom = this.emptyAvailableFrom.bind(this);
        this.emptyAvailableUntil = this.emptyAvailableUntil.bind(this);
        this.boolValue = false;

        this.state = {
            loading: true,
            errors: [],
            allCourses: [],
            currentCourse: null,
            currentActivity: null,
            showAssignDialog: false,
            assignmentError: false,
            assignmentErrorMsg: '',
            periods: [],
            assignmentData: {
                searchTerm: '',
                currentTab: 0,
                tabs: {},
                students: null,
                periods: []
            },
            selectionHistory: {},
            submitted: false,
            assessmentType: '',
            previousDate: moment(),
            isFromListScreen: false,
            teacherData: [],
            showHelpDialog: false,
            timeStampStart: "",
            timeStampEnd: "",
            lockAssignment: false,
            tempDate: "",
            lockReport: false,
            dateVal: '',
            timeVal: '',
            dateVal2: '',
            timeVal2: ''

        };
        if (this.props.location.state) {
            this.state = { ...this.props.location.state, ...this.state };
        }

        // This binding is necessary to make `this` work in the callback
        this.getValidationState = this.getValidationState.bind(this);
        this.handleAlertDismiss = this.handleAlertDismiss.bind(this);
    }

    componentDidMount() {



        LoginUtils.isAuthenticated(this.props, browserHistory, '/assignments/assign', '/');
        let that = this;
        this.getStudentsByTeacher(this.props.auth.user.userName, function () {
            if (that.state.currentActivity && that.state.view === 'activity') {
                that.assign();
            }
        });

        const { dispatch } = this.props;
        this.state = this.state ? this.state : {};
        this.state.isCourseAssignment = true;

        if (!this.state.view) {
            this.state.view = 'course';
        } else if (this.state.view === 'activity') {
            this.state.isCourseAssignment = false;
        }
        if (this.state.isCourseAssignment) {
            //coming from manage-courses or view-course.
            if (this.state.isCourseLoaded) {
                this.setState({
                    ...this.state,
                    currentCourse: this.state.course
                })
            } else if (this.state.course && this.state.course.id) {
                this.getCourse(this.state.course.id);
            }
            ManageCourseActions.fetchAllCourses(dispatch, this.props.auth.user.userName, 'course' + config.stage, {
                'match': {
                    'visibleTo': 'public, "' + this.props.auth.user.userName + '"'
                }
            });
        }

        let context = this;
        //attach onchange on choices.
        $("#assignment_choices input[type=radio]").on('click', function (evt) {

            context.setState({
                view: evt.target.value
            })
        });

        let inputs = $("#assignment_choices input[type=radio]");
        for (let i = 0; i < inputs.length; i++) {
            if (inputs[i].value === this.state.view) {
                inputs[i].checked = true;
                break;
            }
        }
        if (this.props.location.state) {
            let that = this;
            this.setState({ ...this.props.location.state }, function () {
            });
        }

        // remove empty strings for validationException: one or more parameter values were invalid: an attributevalue may not contain an empty string
        if (this.state.currentActivityObj && this.state.currentActivityObj.questions) {
            this.state.currentActivityObj = this.removeEmptyStrings(this.state.currentActivityObj)
        }
    }

    getCourse(id) {


        let context = this;
        context.showIndicator();
        if (id) {
            let params = {
                courseID: id,
            };
            let pathTemplate = '/course/{courseID}';
            let method = 'GET';
            let additionalParams = {};
            let body = {};
            apiClient.invokeApi(params, pathTemplate, method, additionalParams, body).then(function (result) {
                //context.hideIndicator();
                context.setState({
                    ...context.state,
                    currentCourse: result.data,
                    loading: false
                }, () => {
                    this.initialLoaderChecking();
                });
            }).catch(function (error) {
                context.hideIndicator();
            });
        }
    }

    setLockReport(e) {
        this.state.lockReport = e.target.checked
    }

    setTimeStampStart(e) {
        
        if(e.target.value.includes('-'))
        {
            this.setState({dateVal: e.target.value});
            this.state.dateVal = e.target.value;

        }
        else if(e.target.value.includes(':'))
        {
            this.setState({timeVal: e.target.value});
            this.state.timeVal = e.target.value;

        }
        if(this.state.timeVal!='' && this.state.dateVal!='')
        {
            let fullDateObj = this.state.dateVal + 'T' + this.state.timeVal
        
        let timeStampSt = new Date(fullDateObj)
        var setTim = new Date(timeStampSt.getFullYear(), timeStampSt.getMonth(), timeStampSt.getDate(), 0, 0, 0);
        this.state.timeStampStart = fullDateObj

        // if (timeStampSt < new Date()) {
        //     this.setState({
        //         assignmentError: true,
        //         assignmentErrorMsg: 'Sorry, you must make this assignment "Available From" a date equal or greater than "Current Date"',
        //     });
        //     setTimeout(() => {
        //         this.setState({
        //             assignmentError: false,
        //             assignmentErrorMsg: ''
        //         });
        //     }, 5000);

        //     // this.state.timeStampStart = e.target.value
        //     return;
        // }

        if (this.state.tempDate && new Date(this.state.tempDate) < setTim) {
            this.setState({
                assignmentError: true,
                assignmentErrorMsg: 'Sorry, you must make this assignment "Available From" a date less than the "Due Date"',
                dateVal: '',
                timeVal: '',
                timeStampStart: ''
            });
            setTimeout(() => {
                this.setState({
                    assignmentError: false,
                    assignmentErrorMsg: '',
                   
                });
            }, 5000);

            // this.state.timeStampStart = e.target.value
            return;
        }

        if (this.state.timeStampEnd && new Date(this.state.timeStampEnd) <= new Date( this.state.timeStampStart)) {
            this.setState({
                assignmentError: true,
                assignmentErrorMsg: 'Sorry, assignments must be "Available From" an earlier date and time than the "Available Until" date and time."',
            });
            setTimeout(() => {
                this.setState({
                    assignmentError: false,
                    assignmentErrorMsg: '',
                    dateVal: '',
                    timeVal: '',
                    timeStampStart: ''
                });
            }, 5000);

            // this.state.timeStampStart = e.target.value
            return;
        }

        // if (this.state.timeStampEnd && new Date(this.state.timeStampEnd) >= new Date(this.state.timeStampStart)) {
        //     this.setState({
        //         assignmentError: true,
        //         assignmentErrorMsg: 'Sorry, assignments must be "Available From" an earlier date and time than the "Available Until" date and time."',
        //     });
        //     setTimeout(() => {
        //         this.setState({
        //             assignmentError: false,
        //             assignmentErrorMsg: ''
        //         });
        //     }, 5000);

        //     // this.state.timeStampStart = e.target.value
        //     return;
        // }

        }
    }
    emptyAvailableUntil(e) {
        this.setState({timeVal2 : "",dateVal2: ""})
        this.setState({timeStampEnd : ""})
    }
    emptyAvailableFrom(e) {
        this.setState({timeVal : "",dateVal: ""})
        this.setState({timeStampStart : ""})

    }
    setTimeStampEnd(e) {
        if(e.target.value.includes('-'))
        {
            this.setState({dateVal2 :e.target.value});
            this.state.dateVal2 = e.target.value;
        }
        else if(e.target.value.includes(':'))
        {
            this.setState({timeVal2: e.target.value});
            this.state.timeVal2 = e.target.value;

        }
        if(this.state.timeVal2!='' && this.state.dateVal2!='')
        {
            let fullDateObj = this.state.dateVal2 + 'T' + this.state.timeVal2
        
        let timeStampSt = new Date(fullDateObj)
        this.state.timeStampEnd = fullDateObj
        var setTim = new Date(timeStampSt.getFullYear(), timeStampSt.getMonth(), timeStampSt.getDate(), 0, 0, 0);

        if (this.state.tempDate && new Date(this.state.tempDate) > setTim) {
            this.setState({
                assignmentError: true,
                assignmentErrorMsg: 'Sorry, assignments must be "Available Until" a date equal to or greater than the "Due Date"',
            });
            setTimeout(() => {
                this.setState({
                    assignmentError: false,
                    assignmentErrorMsg: '',
                    dateVal2: '',
                    timeVal2: '',
                    timeStampEnd: ''
                });
            }, 5000);

            // this.state.timeStampStart = e.target.value
            return;
        }

        if (this.state.timeStampStart && (new Date(this.state.timeStampEnd) < new Date(this.state.timeStampStart))) {
            this.setState({
                assignmentError: true,
                assignmentErrorMsg: 'Sorry, assignments must be "Available From" an earlier date and time than the "Available Until" date and time."',
                dateVal2: '',
                timeVal2: '',
                timeStampEnd: ''
            });
            setTimeout(() => {
                this.setState({
                    assignmentError: false,
                    assignmentErrorMsg: '',
                  

                });
            }, 5000);

            // this.state.timeStampStart = e.target.value
            return;
        }

    }
        // this.state.timeStampEnd = e.target.value
    }
    initialLoaderChecking() {
        if (this.state.currentCourse && this.state.teacherData) {
            this.hideIndicator();
        }
    }

    postAssignment(payload) {

        var d = new Date();
        var timestamp = Math.floor(d.getTime());
        let context = this;


        if (payload.activity) {
            payload.activity.lockReport = this.state.lockReport
            if (this.state.lockReport) { payload.activity.accessReport = false }
        }


        if (this.state.isRandomize) {
            payload.isRandomize = this.state.isRandomize;
        } else {
            payload.isRandomize = false;
        }
        if (this.state.timeStampStart != "") {
            var dateObj = new Date(this.state.timeStampStart);
            var epochForm = Math.floor(dateObj);
            if (payload.activity) { payload.activity.lockAssignment = true }
            else if (payload.course) {
                payload.course.lockAssignment = true
            }
            if (payload.activity) { payload.activity.timeStampStart = epochForm }
            else if (payload.course) {
                payload.course.timeStampStart = epochForm
            }
        }
        // else {
        //     if (payload.activity) { payload.activity.timeStampStart = timestamp }
        //     else if (payload.course) {
        //         payload.course.timeStampStart = timestamp
        //     }
        // }
        if (this.state.timeStampEnd != "") {
            var dateOb = new Date(this.state.timeStampEnd);
            var epoch = Math.floor(dateOb);
            if (payload.activity) { payload.activity.lockAssignment = true }
            else if (payload.course) {
                payload.course.lockAssignment = true
            }
            if (payload.activity) { payload.activity.timeStampEnd = epoch }
            else if (payload.course) {
                payload.course.timeStampEnd = epoch
            }
        }
        
        // context.state.createPayload.activity.activityName = activity;
        // createPayload.activity.activityName = activity;
        context.showIndicator();
        if (payload) {
            apiClient.invokeApi({}, '/assignment', 'POST', { 'accessToken': localStorage.getItem('accessToken') }, payload).then(function (result) {
                context.hideIndicator();
                ClevertapReact.event("Create Assignment", { 'userName': context.props.auth.user.userName });
                const { dispatch } = context.props;
                if (context.boolValue) {

                    let message = "";

                    if (context.state.view === 'activity') {
                        message = payload.activity.activityName;
                    }
                    else if (context.state.view === 'course') {
                        message = payload.course.courseName;
                    }

                    browserHistory.push({
                        pathname: '/assignments',
                        state: { showClassAssignmentMessage: true, Message: message }
                    })
                }
                else {
                    // message = result.data;
                    ManageAssignmentActions.setMessage(dispatch, true, result.data, 'success', function () {
                        browserHistory.push('/assignments');
                    })
                }

            }).catch(function (result) {
                // show error using alert.
                context.setState({ showAssignDialog: false, 'submitted': false, showAlert: true, alertText: result.message, alertStyle: 'danger' });
                context.hideIndicator();
                const { dispatch } = context.props;
                ManageAssignmentActions.setMessage(dispatch, true, result.message, 'danger', function () {
                    browserHistory.push('/assignments');
                })
            });
        }
    }

    getStudentsByTeacher(id, callback) {

        let context = this;
        context.showIndicator();
        if (id) {
            let params = {
                userName: this.props.auth.user.userName
            };
            let pathTemplate = '/user/students/{userName}';
            let method = 'GET';
            let additionalParams = {};
            let body = {};
            apiClient.invokeApi(params, pathTemplate, method, additionalParams, body).then(function (result) {
                // context.hideIndicator();
                context.setState({
                    ...context.state,
                    teacherData: result.data,
                    teacherDataLoaded: true,
                    loading: false
                }, function () {
                    callback();
                    this.initialLoaderChecking();
                });
            }).catch(function (error) {
                context.hideIndicator();
            });
        }
    }

    getBreadcrumbs() {

        let links = [];
        if (this.state.isCourseAssignment) {
            if (this.state.goBackToManageCourse) {
                links.push({
                    name: 'Manage Courses', action: () => {
                        browserHistory.push('/courses');
                    }
                });
            }
            else {
                links.push({
                    name: 'Manage Courses', action: () => {
                        browserHistory.push({ pathname: '/assignments/courses', state: { isFromAssignment: true } });
                    }
                });
            }

        } else {
            links.push({
                name: 'Manage Assignments', action: () => {
                    browserHistory.goBack();
                }
            });
        }
        links.push({
            name: 'Assign', action: () => {

            }
        });
        return links;
    }

    getActivities(activities, unitID) {

        let acts = [];
        if (activities) {
            activities.forEach(act => {
                switch (act.activityType.toLowerCase()) {
                    case 'learning':
                        act.className = ' col-lg-6 col-md-6 col-sm-8 col-xs-12 respWd30 learning';
                        break;
                    case 'flashcards':
                    case 'flashcard':
                        act.className = 'col-lg-6 col-md-6 col-sm-8 col-xs-12 respWd30 flashCard';
                        break;
                    case 'question':
                        act.className = 'col-lg-6 col-md-6 col-sm-8 col-xs-12 respWd30 questionRow';
                        break;
                    default:
                        act.className = 'col-lg-6 col-md-6 col-sm-8 col-xs-12 respWd30 learning';
                        break;
                }
                let html = <div className="row" key={'act-id-' + act.activityID} style={{ 'borderBottom': '1px solid #bababa', 'marginLeft': '10px', 'marginRight': '10px', 'marginBottom': '10px', 'paddingBottom': '2px' }}>
                    <div className={act.className}>
                        <input type="checkbox" value={act.activityID} id={unitID + '-activity-id-' + act.activityID}
                            className="css-checkbox"
                            name={act.activityName} onChange={this.toggleActivity} />
                        <label htmlFor={unitID + '-activity-id-' + act.activityID}
                            className="pay_label css-label1" />
                        <span className="typeIcon"> </span>
                        <span style={{ display: 'inline-block', position: 'relative' }}><p>{act.activityName}</p></span>
                    </div>
                    {act.activityType.toLowerCase() === "question" && <div className="col-lg-4  col-md-4 col-sm-4 col-xs-8  respWd70">
                        <p className="lableValue">
                            {act.activityType.toLowerCase() === "question" && <span>{act.questionActivityType === "practice" ? "Practice Activity" : "Assessment Activity"}</span>}
                        </p>
                    </div>}
                    {act.activityType.toLowerCase() !== "question" && <div className="col-lg-4  col-md-4 col-sm-4 col-xs-8  respWd70" style={{ 'height': '0px' }}>
                        <p className="lableValue">
                        </p>
                    </div>}
                </div>;


                acts.push(html);
            });
        }
        return acts;
    }

    getUnits(course) {

        let units = [];
        if (course && course.units) {
            units = course.units.map((unit) => {
                let acts = [];

                if (unit && unit.activities) {
                    acts = this.getActivities(unit.activities, unit.unitID);
                }
                let html = <div className="col-md-12" key={course.courseID + '-unit-id-' + unit.unitID}>
                    <div className="panelBox">
                        <h3><input type="checkbox" value={unit.unitID} id={course.courseID + '-unit-id-' + unit.unitID}
                            className="css-checkbox"
                            name={'unit-' + unit.unitName} onChange={this.toggleUnit} />
                            <label htmlFor={course.courseID + '-unit-id-' + unit.unitID}
                                className="pay_label css-label1" />{unit.unitName}
                        </h3>
                        {acts}
                    </div>
                </div>;
                return html;
            });
        }
        return units;
    }

    toggleSelectionAll(evt) {

        let units = [...document.querySelectorAll('[id*=unit-id-]')];
        let acts = [...document.querySelectorAll('[id*=activity-id-]')];
        if (evt.target.checked) {
            for (let i = 0; i < units.length; i++) {
                units[i].checked = true;
            }
            for (let j = 0; j < acts.length; j++) {
                acts[j].checked = true;
            }
        } else {
            for (let i = 0; i < units.length; i++) {
                units[i].checked = false;
            }
            for (let j = 0; j < acts.length; j++) {
                acts[j].checked = false;
            }
        }
    }

    toggleUnit(evt) {


        var id = '[id*=' + evt.target.getAttribute('id').substr(evt.target.id.indexOf('-') + 1).replace('unit-id-', '') + '-activity-id-]';
        id = id.replace('unit-id-', '');
        let acts = document.querySelectorAll(id);
        let course = document.querySelectorAll('[id=' + 'course-id-' + evt.target.id.split('-unit-id-')[0] + ']')[0];
        if (evt.target.checked) {
            for (let j = 0; j < acts.length; j++) {
                acts[j].checked = true;
            }
            course.checked = true;
        } else {
            for (let j = 0; j < acts.length; j++) {
                acts[j].checked = false;
            }
            if ([...document.querySelectorAll('[id*=' + course.id.replace('course-id-', '') + '-unit-id-' + ']:checked').length === 0]) {
                course.checked = false;
            }
        }
    }

    toggleActivity(evt) {

        let unitID = this.state.currentCourse.courseID + '-unit-id-' + evt.target.id.split('-')[0];
        let unit = document.querySelectorAll('[id=' + unitID + ']')[0];
        let course = document.querySelectorAll('[id=course-id-' + unitID.split('-')[0] + ']')[0];
        if (evt.target.checked) {
            unit.checked = true;
            course.checked = true;
        } else {
            if ([...document.querySelectorAll('[id*=' + unitID.substr(unit.id.indexOf('unit-id-')).replace('unit-id-', '') + '-activity-id-]:checked').length === 0]) {
                unit.checked = false;
            }
            if ([...document.querySelectorAll('[id*=' + course.id.replace('course-id-', '') + '-unit-id-]:checked').length === 0]) {
                course.checked = false;
            }
        }
    }

    assignStudentCheckBoxChange(evt) {

        let assignmentData = this.state.assignmentData;
        let selectionHistory = this.state.selectionHistory;
        if (evt.target.checked) {
            assignmentData.tabs[assignmentData.currentTab].students.add(evt.target.value);
            assignmentData.tabs[assignmentData.currentTab].studentCount = assignmentData.tabs[assignmentData.currentTab].students.size;
            if (!selectionHistory[assignmentData.currentTab]) {
                selectionHistory[assignmentData.currentTab] = {};
            }
            if (!selectionHistory[assignmentData.currentTab].students) {
                selectionHistory[assignmentData.currentTab].students = new Set();
            }
            selectionHistory[assignmentData.currentTab].students.add(evt.target.value);

        }
        else {
            assignmentData.tabs[assignmentData.currentTab].students.delete(evt.target.value);
            selectionHistory[assignmentData.currentTab].students.delete(evt.target.value);
            assignmentData.tabs[assignmentData.currentTab].studentCount = assignmentData.tabs[assignmentData.currentTab].students.size;
        }
        assignmentData.tabs[assignmentData.currentTab].stateModified = true;
        this.setState({ 'assignmentData': assignmentData });
        this.setState({ 'selectionHistory': selectionHistory });
    }

    studentSearchTermChange(evt) {


        let assignmentData = this.state.assignmentData;
        assignmentData.searchTerm = evt.target.value;
        this.setState({
            ...this.state,
            assignmentData: assignmentData
        })
    }

    studentDueDateChange(evt) {

        let inputDate = moment((evt._d.getUTCMonth() + 1) + '/' + evt._d.getUTCDate() + '/' + evt._d.getUTCFullYear()).format('MM/DD/YYYY');
        let todayDate = moment().format('MM/DD/YYYY');
        // let dueDateComparison = new Date(evt._d).setHours(23,59,59,0).valueOf();
        // let todayComparison = new Date().setHours(23,59,59,0).valueOf();
        console.log("inputDate: " + inputDate);
        console.log("todayDate: " + todayDate);
        let timeStampSt = new Date(this.state.timeStampStart)
        var setTim = new Date(timeStampSt.getFullYear(), timeStampSt.getMonth(), timeStampSt.getDate(), 0, 0, 0);
        if (this.state.timeStampStart != "") {
            if (new Date(inputDate) < setTim) {
                this.setState({
                    assignmentError: true,
                    assignmentErrorMsg: 'Sorry, you must make this assignment "Available From" a date less than the "Due Date"',
                });
                return;
            }
        }
        let curDate = new Date();
        let currentDat = new Date(curDate.getFullYear(), curDate.getMonth(), curDate.getDate(), 0, 0, 0);
        if (new Date(inputDate) < currentDat) {
            this.setState({
                assignmentError: true,
                assignmentErrorMsg: 'Please Enter "Due Date" greater than Current Date',
            });
            setTimeout(() => {
                this.setState({
                    assignmentError: false,
                    assignmentErrorMsg: ''
                });
            }, 5000);
            return;
        }

        let timeStampEn = new Date(this.state.timeStampEnd)
        var setTim2 = new Date(timeStampEn.getFullYear(), timeStampEn.getMonth(), timeStampEn.getDate(), 0, 0, 0);

        // if (new Date(inputDate) > new Date(todayDate)) {
        let assignmentData = this.state.assignmentData;
        let selectionHistory = this.state.selectionHistory;
        if (assignmentData.tabs[assignmentData.currentTab]) {
            assignmentData.tabs[assignmentData.currentTab].dueDate = evt._d.toUTCString();
            if (!selectionHistory[assignmentData.currentTab]) {
                selectionHistory[assignmentData.currentTab] = {};
            }
            selectionHistory[assignmentData.currentTab].dueDate = evt._d.toUTCString();
            for (let i = 0; i < assignmentData.classArray.length; i++) {
                if (!assignmentData.tabs[assignmentData.classArray[i]].dueDate) {
                    assignmentData.tabs[assignmentData.classArray[i]].dueDate = evt._d.toUTCString();
                }
            }

            this.setState({
                ...this.state,
                previousDate: evt._d.toUTCString(),
                assignmentData: assignmentData,
                selectionHistory: selectionHistory,
                assignmentError: false,
                assignmentErrorMsg: '',
            })
            this.setState({ tempDate: (inputDate) })
            if (this.state.timeStampEnd != "") {
                if (new Date(inputDate) > setTim2) {
                    this.setState({
                        assignmentError: true,
                        assignmentErrorMsg: 'Your original "Available Until" date has been removed. Do you want to set a new "Available Until" date?',
                        timeStampEnd: "",
                        dateVal2: '',
                        timeVal2: ''
                    });
                    setTimeout(() => {
                        this.setState({
                            assignmentError: false,
                            assignmentErrorMsg: ''
                        });
                    }, 9000);

                    return;
                }
            }
        }
        else {
            this.setState({
                assignmentError: true,
                assignmentErrorMsg: 'Sorry, you must select your students to assign to prior to selecting a "Due Date"',
            });
        }
        // }
        // else {
        //     this.setState({
        //         assignmentError: true,
        //         assignmentErrorMsg: 'Please provide due date for a day in the future',
        //     });
        // }



    }

    assignPeriodChange(evt, tab) {

        let context = this;
        let temp = tab.name;
        // add any illgeal chars for Jquery selector for tab.name
        temp = temp.replace(/[\&\!]/g, "");

        if ($('#' + temp.name)) {
            let assignmentData = context.state.assignmentData;

            if (assignmentData.currentTab !== tab.name) {
                context.state.selectionHistory[assignmentData.currentTab] = assignmentData.tabs[assignmentData.currentTab];
                assignmentData.currentTab = tab.name;
                let inputs = [...document.querySelectorAll('table#student_panel_students tbody tr td input[type=checkbox]')];
                let students = Array.from(context.state.selectionHistory[tab.name] ? context.state.selectionHistory[tab.name].students : new Set());
                inputs.forEach(inp => {
                    inp.checked = students.includes(inp.value);
                });
                context.setState({
                    ...context.state,
                    assignmentData: assignmentData
                }, function () {
                    if (!context.state.selectionHistory[assignmentData.currentTab].stateModified) {
                        context.selectAllStudents();
                    }
                });
            }
        }
    }

    toggleAllStudents(evt) {

        if (evt.target.checked) {
            this.selectAllStudents(evt);
        } else {
            this.clearAllStudents(evt);
        }
    }

    selectAllStudents(evt) {

        console.log('Select all students called.');
        let assignmentData = this.state.assignmentData;
        assignmentData.students.forEach(stud => {
            if (stud.className === assignmentData.currentTab) {
                assignmentData.tabs[assignmentData.currentTab].students.add(stud.students);
                this.state.selectionHistory[this.state.assignmentData.currentTab].students.add(stud.students);
            }
        });
        assignmentData.tabs[assignmentData.currentTab].studentCount = assignmentData.tabs[assignmentData.currentTab].students.size;
        // check all boxes in html.
        let studentTable = document.querySelector('#student_panel_students');
        let boxes = [...studentTable.querySelectorAll('input[type=checkbox]')];
        boxes.forEach(box => box.checked = true);
        this.setState({ 'assignmentData': assignmentData });
    }
    clearAllStudents(evt) {

        console.log('Clear all students called.');
        let assignmentData = this.state.assignmentData;
        assignmentData.tabs[assignmentData.currentTab].students.clear();
        assignmentData.tabs[assignmentData.currentTab].stateModified = true;
        this.state.selectionHistory[this.state.assignmentData.currentTab].students.clear();
        assignmentData.tabs[assignmentData.currentTab].studentCount = assignmentData.tabs[assignmentData.currentTab].students.size;
        let studentTable = document.querySelector('#student_panel_students');
        let boxes = [...studentTable.querySelectorAll('input[type=checkbox]')];
        boxes.forEach(box => box.checked = false);
        this.setState({ 'assignmentData': assignmentData });
    }

    removeTab(tab) {

        console.log(tab, 'Remove tab called.');
        let periods = this.state.periods;
        let newPeriods = [];
        if (tab && tab.name && periods.includes(tab.name)) {
            for (let i = 0; i < periods.length; i++) {
                if (periods[i] === tab.name) {
                    periods.splice(i, 1);
                }
            }
            delete this.state.selectionHistory[tab.name];
            let assignmentData = this.state.assignmentData;
            delete assignmentData.tabs[tab.name];
            if (periods.length > 0 && !document.querySelector('.tab_link.period_active')) {
                assignmentData.currentTab = periods[0];
                $('#' + assignmentData.currentTab).addClass('period_active');
            } else {
                if (periods.length === 0) {
                    assignmentData.currentTab = 0;
                }
            }
            // this.state.periods = periods;
            this.setState({
                ...this.state,
                periods: periods,
                assignmentData: assignmentData
            })
        }
    }

    checkEnterKeyPress(e) {
        if (e.key === 'Enter') {
            this.selectPeriodOnChange(e);
        }
    }

    selectAssessmentType(evt) {

        this.setState({ 'assessmentType': evt.target.value });
        this.state.createPayload.activity.assessmentType = evt.target.value;
    }

    selectPeriodOnChange(evt) {


    }

    getAssignDialog(data = {}) {

        let minDate = new Date()
        let minDate1 = `${minDate.getFullYear()}-${(minDate.getMonth() + 1) < 10 ? '0' + (minDate.getMonth() + 1) : (minDate.getMonth() + 1)}-${(minDate.getDate()) < 10 ? '0' + (minDate.getDate()) : (minDate.getDate())}T${(minDate.getHours()) < 10 ? '0' + (minDate.getHours()) : (minDate.getHours())}:${(minDate.getMinutes()) < 10 ? '0' + (minDate.getMinutes()) : (minDate.getMinutes())}`
        minDate1 = minDate1.split('T',2);
        let maxDate1 = ""
        if (this.state.timeStampStart) {
            let maxDate = new Date(this.state.timeStampStart)
            maxDate1 = `${maxDate.getFullYear()}-${(maxDate.getMonth() + 1) < 10 ? '0' + (maxDate.getMonth() + 1) : (maxDate.getMonth() + 1)}-${(maxDate.getDate()) < 10 ? '0' + (maxDate.getDate()) : (maxDate.getDate())}T${(maxDate.getHours()) < 10 ? '0' + (maxDate.getHours()) : (maxDate.getHours())}:${(maxDate.getMinutes()) < 10 ? '0' + (maxDate.getMinutes()) : (maxDate.getMinutes())}`
            maxDate1 = maxDate1.split('T',2);
        }

        // console.log(temp)
        // 2020 - 12 - 16T08: 30
        let previousDate = this.state.previousDate;

        let context = this;
        let students = [];
        let classes = [];
        let assessType = [{ "id": "assessment", "name": "Assessment" }, { "id": "practice", "name": "Practice" }];

        let selectedAssignmentType = context.state.view;
        let checkedActivities = [...document.querySelectorAll('.CourseView input[type=checkbox][id*=-activity-id-]:checked')];

        let assignmentData = this.state.assignmentData;
        assignmentData.students = context.state.teacherData;
        if (this.state.teacherDataLoaded && context.state.teacherData.length > 0) {
            assignmentData.classArray = context.state.periods;
            context.state.periods.forEach(period => {
                if (context.state.selectionHistory[period]) {
                    assignmentData.tabs[period] = context.state.selectionHistory[period];
                }
                else {
                    let periodData = {};
                    periodData.name = period;
                    // periodData.dueDate = '';
                    periodData.students = new Set();
                    assignmentData.tabs[period] = periodData;
                    context.state.selectionHistory[period] = periodData;
                }
            });

            assignmentData.selectedAssignmentType = selectedAssignmentType ? selectedAssignmentType : null;
            let createPayload = {};
            if (selectedAssignmentType === 'course') {
                createPayload.preserveOrder = !!(document.querySelector('#preserveOrder') && document.querySelector('#preserveOrder').checked);
                createPayload.teacherName = context.props.auth.user.userName;
                createPayload.ownerName = context.props.auth.user.userName;
                createPayload.activityId = null;
                // gather course activity selections.
                createPayload.course = {};
                createPayload.course.courseID = context.state.currentCourse ? context.state.currentCourse.courseID : '';
                createPayload.course.courseName = context.state.currentCourse ? context.state.currentCourse.courseName : '';
                createPayload.course.units = [];
                // prep units.
                if (context.state.currentCourse) {
                    context.state.currentCourse.units.forEach(unit => {
                        let pushUnits = false;
                        let unitEntry = {};
                        unitEntry.unitID = unit.unitID;
                        unitEntry.unitName = unit.unitName;
                        unitEntry.activities = [];
                        // createPayload.course.units.push(unitEntry);

                        if (unit) {
                            checkedActivities.forEach(act => {
                                if (act.id.indexOf(unit.unitID) > -1) {
                                    unit.activities.forEach(activity => {
                                        if (activity && activity.activityID === act.value) {
                                            unitEntry.activities.push(activity);
                                            pushUnits = true;
                                        }
                                    });
                                }
                            });
                            if (pushUnits) {
                                createPayload.course.units.push(unitEntry);
                            }
                        }
                    });

                    context.state.assignmentData = assignmentData;
                    context.state.createPayload = createPayload;
                }
            } else if (selectedAssignmentType === 'activity') {
                // set activity.
                createPayload.ownerName = context.props.auth.user.userName;
                let currentActivity = context.state.currentActivityObj;
                if (currentActivity) {
                    currentActivity.activityName = currentActivity ? currentActivity.activityName : '';
                    createPayload.activity = currentActivity;
                    if (!createPayload.activity.activityID) {
                        createPayload.activity.activityID = currentActivity.id;
                    }
                    createPayload.course = null;

                    context.state.assignmentData = assignmentData;
                    context.state.createPayload = createPayload;
                }
            }

            if (context.state.assignmentData) {
                /*if (!context.state.assignmentData.currentTab) {
                 context.state.assignmentData.currentTab = context.state.assignmentData.periods[0];
                 }*/
                if (context.state.assignmentData.currentTab) {
                    students = context.state.assignmentData.students.map(student => {
                        let searchTerm = context.state.assignmentData.searchTerm.trim().toLowerCase();
                        if (((student.students && student.students.trim().toLowerCase().indexOf(searchTerm) > -1) ||
                            (student.stdFirstName + ' ' + student.stdLastName).trim().toLowerCase().indexOf(searchTerm) > -1 ||
                            (student.students && student.students.trim().toLowerCase().indexOf(searchTerm) > -1)) &&
                            student.className === context.state.assignmentData.currentTab
                        ) {
                            let checked = Array.from(context.state.assignmentData.tabs[context.state.assignmentData.currentTab] ? context.state.assignmentData.tabs[context.state.assignmentData.currentTab].students : new Set()).includes(student.students);


                            return <tr key={student.students} id={'tr-' + student.students}>
                                <td className="student_panel_entry_checkbox">

                                    <input type="checkbox" id={student.students} value={student.students}
                                        className="css-checkbox"
                                        onChange={function (evt) {
                                            context.assignStudentCheckBoxChange(evt);
                                        }}
                                        defaultChecked={checked}
                                    />
                                    <label htmlFor={student.students}
                                        className="pay_label css-label1" style={{ 'paddingTop': '0px' }}>{student.stdFirstName + ' ' + student.stdLastName + ' (' + student.students + ')'}</label>
                                </td>
                            </tr>;
                        }
                    });
                }
                for (let key of context.state.periods) {
                    let tab = context.state.assignmentData.tabs[key];
                    let active = context.state.assignmentData.currentTab === tab.name ? 'period_active' : '';
                    let studentCount = context.state.assignmentData.tabs[tab.name].students ? context.state.assignmentData.tabs[tab.name].students.size : 0;
                    classes.push(
                        <div key={tab.name} className="tab_link " onClick={function (evt) {
                            context.assignPeriodChange(evt, tab);
                        }}>
                            <label htmlFor={tab.name} style={{ 'paddingTop': '0px', }}>{tab.name}</label>
                            {(context.state.assignmentData.tabs[tab.name].studentCount ? context.state.assignmentData.tabs[tab.name].studentCount : 0) + " student(s) selected"}
                            <br />
                            {context.state.assignmentData.tabs[tab.name].students.size > 0 ? "Due: " + (context.state.assignmentData.tabs[tab.name].dueDate ? ((new Date(context.state.assignmentData.tabs[tab.name].dueDate).getUTCMonth() + 1) + '/' + (new Date(context.state.assignmentData.tabs[tab.name].dueDate)).getUTCDate() + '/' + (new Date(context.state.assignmentData.tabs[tab.name].dueDate)).getUTCFullYear()) : "No date selected") : ""}
                            {/*<span onClick={(evt)=>{*/}
                            {/*let parent = evt.target.parentElement;*/}
                            {/*parent.remove();*/}
                            {/*this.removeTab(tab);*/}
                            {/*}} style={{marginLeft: '5px', cursor: 'pointer', zIndex:'10'}}>x</span>*/}
                        </div>
                    );
                }
            }

            let searchTerm = this.state.assignmentData ? this.state.assignmentData.searchTerm : '';
            let dueDate = this.state.assignmentData && this.state.assignmentData.tabs && this.state.assignmentData.tabs[this.state.assignmentData.currentTab] && this.state.assignmentData.tabs[this.state.assignmentData.currentTab].dueDate ? moment.utc(this.state.assignmentData.tabs[this.state.assignmentData.currentTab].dueDate) : null;
            let setSDate = new Date();
            let setSMonth = setSDate.getMonth() + 1;
            // if (!this.state.timeStampStart) {
            //     this.state.timeStampStart = setSDate && setSDate.getFullYear() + "-" + (setSMonth < 10 ? '0' + setSMonth : setSMonth) + "-" + (setSDate.getDate() < 10 ? '0' + setSDate.getDate() : setSDate.getDate()) + "T" + (setSDate.getHours() < 10 ? '0' + setSDate.getHours() : setSDate.getHours()) + ":" + (setSDate.getMinutes() < 10 ? '0' + setSDate.getMinutes() : setSDate.getMinutes())
            // }
            moment(dueDate);

            let body = <div>

                {/*<SelectFieldGroup*/}
                {/*field="classInput"*/}
                {/*options={classList}*/}
                {/*label="Class:"*/}
                {/*onChange={this.selectPeriodOnChange}*/}
                {/*placeholder="Select multiple classes"*/}
                {/*id="periodSelector"*/}
                {/*/>*/}
                {this.state.createPayload && this.state.createPayload.activity && this.state.createPayload.activity.activityType.toLowerCase() === "question" &&
                    <div>
                        <SelectFieldGroup
                            field="assessType"
                            options={assessType}
                            label="Question Activity Type:"
                            onChange={this.selectAssessmentType}
                            placeholder="Select question activity type"
                            value={this.state.assessmentType}
                            id="assessTypeId"
                            toolTip="Practice activities will provide your students with hints and feedback. Assessment activities do not include hints or feedback"
                            toolTipStyleForCreateAssignmentOnly={true}
                            makeLabelBold={true}
                        />
                        {/*<a className="Activity-toolTip toolTipCus" data-toggle="tooltip" title=/>*/}
                    </div>
                }
                {this.state.createPayload && ((this.state.createPayload.activity && (this.state.createPayload.activity.activityType.toLowerCase() == "question" || (this.state.createPayload.activity.activityType.toLowerCase() == "flashcards" || this.state.createPayload.activity.activityType.toLowerCase() == "flashcard"))) || this.state.createPayload.course) &&
                    <div>
                        <input type="checkbox" id="isRandomize" value="isRandomize"
                            className="css-checkbox"
                            onChange={(e) => { //console.log(this.state.createPayload.activity.activityType)
                                if (e.target.checked) {
                                    this.state.isRandomize = true;
                                } else {
                                    this.state.isRandomize = false;
                                }
                            }}
                        />
                        <label htmlFor="isRandomize" className="css-label1" style={{ marginBottom: "15px" }}>Randomize the questions for students</label>

                    </div>}
                {context.state.periods && context.state.periods.length > 0 &&
                    <div className="form-group">
                        <div className="posRel ">
                            <table id="assignTable" style={{ border: '1px solid lightgray' }}>
                                <tbody>
                                    <tr>
                                        <td style={{ borderRight: '1px solid lightgray', /*position:'relative',*/ top: '-6px', width: '35%' }}>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ 'paddingLeft': '5px' }}>
                                                            <div id="classes_container">
                                                                {classes}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                        <td style={{ verticalAlign: 'top' }}>
                                            <table>
                                                <tbody>
                                                    <tr style={{ background: '#fbfbfb', borderBottom: '1px solid lightgray' }}><td style={{ padding: '10px 0' }}>
                                                        <input type="text" placeholder="Search students..." value={searchTerm}
                                                            className="student_panel_search" onChange={this.studentSearchTermChange} />
                                                    </td></tr>
                                                    <tr style={{ background: '#fbfbfb' }}><td>
                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" id="student_table_container">
                                                            <table id="student_panel_students"
                                                                className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                                <tbody>
                                                                    <tr><td>
                                                                        <input type="checkbox" id="selectAll" value="selectAll"
                                                                            className="css-checkbox"
                                                                            onChange={this.toggleAllStudents}
                                                                        />
                                                                        <label htmlFor="selectAll" className="pay_label css-label1" style={{ 'paddingTop': '0px', 'width': '100%', 'borderBottom': '1px solid #cccccc' }}><b>Student Names</b></label>
                                                                    </td></tr>
                                                                    {students}</tbody>
                                                            </table>
                                                        </div>
                                                    </td></tr>
                                                    {
                                                        <tr style={{ borderTop: '1px solid lightgray' }}>
                                                            <td className="dueDateSelector" >
                                                                <strong style={{ paddingLeft: '10px', paddingRight: '5px' }}>Due Date:</strong>
                                                                {/*<input type="date" placeholder="Due date." value={dueDate} className="student_panel_due_date"*/}
                                                                {/*onChange={this.studentDueDateChange}/>*/}
                                                                <DatePicker
                                                                    customInput={<CustomDatePicker />}
                                                                    placeholderText="Click to select a date"
                                                                    selected={dueDate}
                                                                    minDate={new Date()}
                                                                    onChange={this.studentDueDateChange} />
                                                            </td>
                                                        </tr>
                                                    }
                                                </tbody>
                                                {/* {this.state.createPayload && this.state.createPayload.activity && this.state.createPayload.activity.activityType != "learning" && <div><b style={{ fontSize: "14px", marginLeft: "19px" }}>Available from: <input style={{ marginLeft: "5px" }} type="time" value={this.state.timeStampStart} onChange={this.setTimeStampStart} /> &nbsp; To :  &nbsp; <input type="time" value={this.state.timeStampEnd} onChange={this.setTimeStampEnd} /></b></div>} */}
                                                {this.state.createPayload && ((this.state.createPayload.activity && this.state.createPayload.activity.activityType != "learning") || this.state.createPayload.course) && <div><b style={{ fontSize: "14px", marginLeft: "19px" }}>Available From: <input value={this.state.dateVal} style={{ marginLeft: '5px' }} id='in1' type="date"  onChange={this.setTimeStampStart} min={minDate1[0]} required={this.state.timeVal != "" ? true : false}></input><input value={this.state.timeVal} type="time" id='in2'  onChange={this.setTimeStampStart} required={this.state.dateVal != "" ? true : false} ></input></b>{(this.state.timeStampStart || this.state.dateVal || this.state.timeVal)  && <button className="btn btn-primary clearBn" onClick={this.emptyAvailableFrom}>Clear</button>} &nbsp;
                                                <br /><b style={{ fontSize: "14px", marginLeft: "19px" }}>Available Until:  &nbsp; <input style={{ marginLeft: '1px' }}  id='in3' type="date"  value={this.state.dateVal2} onChange={this.setTimeStampEnd} min={maxDate1 !="" ?  maxDate1[0] : minDate1[0]} required={this.state.timeVal2 != "" ? true : false}></input><input type="time" id='in4' value={this.state.timeVal2} onChange={this.setTimeStampEnd} required={this.state.dateVal2 != "" ? true : false}></input></b>{(this.state.timeStampEnd || this.state.dateVal2 || this.state.timeVal2) && <button className="btn btn-primary clearBn" onClick={this.emptyAvailableUntil}>Clear</button>}</div>}
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>}
                {this.state.duplicateError ? <Alert bsStyle="danger">
                    {this.state.duplicateErrorMessage}
                </Alert> : ''}
                {context.state.assignmentError && <p className="overrideP" style={{ color: 'red' }}>{context.state.assignmentErrorMsg}</p>}
                <div className="row" />
                {this.state.currentActivity != null && <div>
                    <input type="checkbox" id="lock" className="css-checkbox" value={this.state.lockReport} onChange={this.setLockReport} />
                    <label htmlFor="lock" className="css-label1" style={{ marginBottom: "15px" }}>Lock Report</label>
                </div>}
            </div>;

            return <Modal id="AssignModal"
                backdrop="static"
                bsSize="large"
                show={this.state.showAssignDialog}
                onHide={() => {
                    let that = this;
                    this.setState({
                        showAssignDialog: false
                    }, function () {
                        if (that.state.view === 'activity') {
                            browserHistory.push('/activities')
                        }
                        else if (that.state.view === 'course') {
                            browserHistory.push('/courses')
                        }
                    });
                }}
                container={this}
                aria-labelledby="contained-modal-title">
                <Modal.Header closeButton>
                    <Modal.Title id="delete-activity-modal">Assign {this.state.createPayload.activity && this.state.createPayload.activity.activityName} {this.state.createPayload.course && this.state.createPayload.course.courseName}</Modal.Title>
                    <div className="actionBlockDiv ques">
                        <a className="actionButtonclass onlyForCreateAssignmentBigModalClass"> <span style={{
                            //    transform: 'scale(0.7, 0.7)',
                            //    WebkitTransform: 'scale(0.7, 0.7)',
                            //     transform: 'scale(0.7, 0.7)',
                            zIndex: '99',
                        }}
                            className="WHSign onlyForCreateAssignmentBigModal" >Select your class to assign. The students will display in the window. You can deselect students if you would like.
                           You can select multiple periods to assign. Select a Due Date and click assign</span></a>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    {body}
                    {/* {this.state.view === 'activity' ? <span style={{ color: 'red', textAlign: 'center', fontWeight: 'bold' }}>This activity assignment will not be counted towards the Course Report</span> : <span></span>} */}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => {
                        let that = this;
                        let assignmentData = this.state.assignmentData;
                        assignmentData.currentTab = null;
                        assignmentData.tabs = [];
                        this.setState({
                            showAssignDialog: false,
                            periods: [],
                            selectionHistory: [],
                            assignmentData: assignmentData
                        }, function () {
                            if (that.state.view === 'activity') {
                                browserHistory.push('/activities')
                            }
                            else if (that.state.view === 'course') {
                                browserHistory.push('/courses')
                            }

                        });
                    }}>Cancel</Button>
                    <Button onClick={() => {
                        this.setState({ showAssignDialog: false }, this.saveAssignment);
                    }} disabled={this.state.submitted} bsStyle='primary'>Assign</Button>
                </Modal.Footer>
            </Modal>;
        }
        else {
            return <Modal id="AssignModal"
                backdrop="static"
                show={this.state.showAssignDialog}
                onHide={() => {
                    this.setState({
                        showAssignDialog: false
                    });
                }}
                container={this}
                aria-labelledby="contained-modal-title">
                <Modal.Header closeButton>
                    <Modal.Title id="delete-activity-modal">Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>
                        <br />
                        There are no students enrolled with you
                    </h5>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => {
                        if (this.state.view === 'activity') {
                            browserHistory.push('/activities')
                        }
                        else if (this.state.view === 'course') {
                            browserHistory.push('/courses')
                        }
                    }}>Ok</Button>
                </Modal.Footer>
            </Modal>;
        }
    }

    assign() {

        let context = this;
        let selectedAssignmentType = context.state.view;
        let checkedActivities = [...document.querySelectorAll('.CourseView input[type=checkbox][id*=-activity-id-]:checked')];

        /*var classes = new Set();
         context.state.teacherData.forEach(student => {
                    classes.add(student.className);
         });
         */

        let assignmentData = context.state.assignmentData;
        assignmentData.students = context.state.teacherData;

        if (context.state.teacherData.length > 0) {
            assignmentData.classArray = context.state.periods;
            let selectionHistory = context.state.selectionHistory;
            context.state.periods.forEach(period => {
                let periodData = {};
                periodData.name = period;
                // periodData.dueDate = '';
                periodData.students = new Set();
                assignmentData.tabs[period] = periodData;
                selectionHistory[period] = periodData;
            });
            assignmentData.selectedAssignmentType = selectedAssignmentType ? selectedAssignmentType : null;
            let createPayload = {};

            if (selectedAssignmentType === 'course') {
                if (!context.state.currentCourse || !context.state.currentCourse.courseID || checkedActivities.length === 0) {
                    context.setState({
                        showAlert: true,
                        alertText: 'Please select a course and at least one activity to proceed to assignment.',
                        alertStyle: 'danger'
                    });
                    setTimeout(() => {
                        context.setState({
                            showAlert: false,
                            alertText: '',
                            alertStyle: ''
                        });
                    }, 5000);
                }
                else {
                    createPayload.preserveOrder = document.querySelector('#preserveOrder').checked;
                    createPayload.teacherName = context.props.auth.user.userName;
                    createPayload.ownerName = context.props.auth.user.userName;
                    createPayload.activityId = null;
                    // gather course activity selections.

                    createPayload.course = {};
                    createPayload.course.courseID = context.state.currentCourse.courseID;
                    createPayload.course.courseName = context.state.currentCourse.courseName;
                    createPayload.course.units = [];
                    // prep units.
                    context.state.currentCourse.units.forEach(unit => {
                        let unitEntry = {};
                        let pushUnit = false;
                        unitEntry.unitID = unit.unitID;
                        unitEntry.unitName = unit.unitName;
                        unitEntry.activities = [];
                        // createPayload.course.units.push(unitEntry);

                        if (unit) {
                            checkedActivities.forEach(act => {
                                if (act.id.indexOf(unit.unitID) > -1) {
                                    unit.activities.forEach(activity => {
                                        if (activity && activity.activityID === act.value) {
                                            unitEntry.activities.push(activity);
                                            pushUnit = true;
                                        }
                                    });
                                }
                            });
                            if (pushUnit) {
                                createPayload.course.units.push(unitEntry);
                            }
                        }
                    });
                    let tempList = [];
                    if (this.state.assignmentData && this.state.assignmentData.students) {
                        this.state.assignmentData.students.forEach(std => {
                            if (!tempList.includes(std.className)) {
                                tempList.push(std.className);
                            }
                        });
                    }
                    context.setState({
                        showAssignDialog: true,
                        assignmentData: assignmentData,
                        createPayload: createPayload,
                        selectionHistory: selectionHistory,
                        periods: tempList
                    });
                }
            } else if (selectedAssignmentType === 'activity') {
                if (!context.state.currentActivity) {
                    context.setState({
                        showAlert: true,
                        alertText: 'Please select an activity to proceed to assignment.',
                        alertStyle: 'danger'
                    });
                    setTimeout(() => {
                        context.setState({
                            showAlert: false,
                            alertText: '',
                            alertStyle: ''
                        });
                    }, 5000);
                }
                else {
                    // set activity.
                    createPayload.ownerName = context.props.auth.user.userName;
                    let currentActivity = {};

                    currentActivity.activityName = context.state.currentActivityObj.activityName;
                    createPayload.activity = context.state.currentActivityObj;
                    createPayload.course = null;
                    context.setState({
                        showAssignDialog: true,
                        assignmentData: assignmentData,
                        createPayload: createPayload,
                        selectionHistory: selectionHistory
                    });
                    let tempList = [];
                    if (this.state.assignmentData && this.state.assignmentData.students) {
                        this.state.assignmentData.students.forEach(std => {
                            if (!tempList.includes(std.className)) {
                                tempList.push(std.className);
                            }
                        });
                    }
                    context.setState({
                        showAssignDialog: true,
                        assignmentData: assignmentData,
                        createPayload: createPayload,
                        selectionHistory: selectionHistory,
                        periods: tempList
                    });
                }
            }
        }
        else {
            context.setState({
                showAssignDialog: true
            });
        }
    }

    saveAssignment() {
        // }
        let timeStampSt = new Date(this.state.timeStampStart)
        var setTim = new Date(timeStampSt.getFullYear(), timeStampSt.getMonth(), timeStampSt.getDate(), 0, 0, 0);

        if (!this.state.submitted) {
            this.setState({ 'submitted': true });
            let context = this;
            let periods = [];
            let today = moment().format('MMM D, YYYY');

            for (var key in this.state.assignmentData.tabs) {
                let dueDate = this.state.assignmentData.tabs[key].dueDate ? new Date(this.state.assignmentData.tabs[key].dueDate) : null
                if (dueDate) {
                    dueDate = ((dueDate.getUTCMonth() + 1) + '/' + dueDate.getUTCDate() + '/' + dueDate.getUTCFullYear()).toString();
                    let todayDate = moment().format('MM/DD/YYYY');
                    console.log("inputDate: " + dueDate);
                    console.log("todayDate: " + todayDate);

                    if (context.state.assignmentData.tabs[key].students.size > 0) {
                        // console.log(dueDate);
                        // console.log(moment(context.state.assignmentData.tabs[key].dueDate));
                        console.log(new Date(this.state.assignmentData.tabs[key].dueDate).valueOf());
                        periods.push({
                            periodID: key,
                            className: key,
                            dueDate: new Date(this.state.assignmentData.tabs[key].dueDate).valueOf().toString(),
                            students: Array.from(context.state.assignmentData.tabs[key].students)
                        });
                    }
                }
            }
            if (periods.length === 0) {
                context.setState({
                    showAssignDialog: true,
                    assignmentError: true,
                    assignmentErrorMsg: 'please enter a "Due Date" for this assignment (the due date cannot be in the past/blank) and select students to assign to',
                    submitted: false
                });
                setTimeout(() => {
                    context.setState({
                        assignmentError: false,
                        assignmentErrorMsg: ''
                    });
                }, 5000);
            }
            else if (this.state.assessmentType === "" && this.state.createPayload.activity && this.state.createPayload.activity.activityType && this.state.createPayload.activity.activityType.toLowerCase() === "question") {
                context.setState({
                    showAssignDialog: true,
                    assignmentError: true,
                    assignmentErrorMsg: 'Please Select Question Activity Type',
                    submitted: false
                });
                setTimeout(() => {
                    context.setState({
                        assignmentError: false,
                        assignmentErrorMsg: ''
                    });
                }, 5000);
            }
            else if (this.state.timeStampStart && this.state.timeStampEnd && this.state.timeStampStart > this.state.timeStampEnd) {
                this.setState({
                    showAssignDialog: true,
                    assignmentError: true,
                    assignmentErrorMsg: 'Sorry, assignments must be "Available From" an earlier date and time than the "Available Until" date and time',
                    submitted: false
                });
                setTimeout(() => {
                    this.setState({
                        assignmentError: false,
                        assignmentErrorMsg: ''
                    });
                }, 5000);
            }
            else if ((this.state.dateVal != "" && this.state.timeVal == "") ||(this.state.dateVal == "" && this.state.timeVal != "")) {
                this.setState({
                    showAssignDialog: true,
                    assignmentError: true,
                    assignmentErrorMsg: 'Sorry, you have to enter both (Date and Time) of Avaialable From field',
                    submitted: false
                });
                setTimeout(() => {
                    this.setState({
                        assignmentError: false,
                        assignmentErrorMsg: ''
                    });
                }, 5000);
            }
            else if ((this.state.dateVal2 != "" && this.state.timeVal2 == "") ||(this.state.dateVal2 == "" && this.state.timeVal2 != "")) {
                this.setState({
                    showAssignDialog: true,
                    assignmentError: true,
                    assignmentErrorMsg: 'Sorry, you have to enter both (Date and Time) of Avaialable Until field',
                    submitted: false
                });
                setTimeout(() => {
                    this.setState({
                        assignmentError: false,
                        assignmentErrorMsg: ''
                    });
                }, 5000);
            }
            else if (this.state.timeStampStart != "" && new Date(this.state.tempDate) < setTim) {
                this.setState({
                    showAssignDialog: true,
                    assignmentError: true,
                    assignmentErrorMsg: 'Sorry, you must make this assignment "Available From" a date less than the "Due Date"',
                    submitted: false,
                    dateVal: '',
                    timeVal: '',
                    timeStampStart: ""
                });
                setTimeout(() => {
                    this.setState({
                        assignmentError: false,
                        assignmentErrorMsg: '',
                    });
                }, 5000);
            }
            else if (this.state.timeStampEnd != "" && new Date(this.state.tempDate) > new Date(this.state.timeStampEnd)) {
                this.setState({
                    showAssignDialog: true,
                    assignmentError: true,
                    assignmentErrorMsg: 'Sorry, assignments must be "Available Until" a date equal to or greater than the "Due Date"',
                    submitted: false,
                    dateVal2: '',
                    timeVal2: '',
                    timeStampEnd: ""
                });
                setTimeout(() => {
                    this.setState({
                        assignmentError: false,
                        assignmentErrorMsg: ''
                    });
                }, 5000);
            }
            else {
                //send save call.
                let createPayload = context.state.createPayload;
                if (this.state.assessmentType) {
                    createPayload.activity.assessmentType = this.state.assessmentType;
                }
                createPayload.periods = periods;


                let teacherData = this.state.teacherData;
                createPayload.periods.forEach(period => {
                    let allMatchingPeriods = teacherData.filter(function (data) {
                        return data.className === period.className;
                    });
                    if (allMatchingPeriods.length === period.students.length) {

                        period.isType = "classAssignment";

                        context.boolValue = true;

                    }
                    else {
                        period.isType = "studentAssignment";
                    }
                });
                //period.isType
                if (createPayload.course && createPayload.course.units.length === 1 && createPayload.course.units[0].activities.length === 1) {
                    let params = {
                        activityID: createPayload.course.units[0].activities[0].activityID
                    };
                    let pathTemplate = '/activity/{activityID}';
                    let method = 'GET';
                    let additionalParams = {};
                    let body = {};
                    apiClient.invokeApi(params, pathTemplate, method, additionalParams, body).then(function (result) {
                        console.log("fetched activity")
                        console.log(JSON.stringify(result.data));
                        createPayload.course.units[0].activities[0] = result.data;
                        context.postAssignment(createPayload);
                    }).catch(function (err) {
                        console.log("fetched activity failed")
                        console.log(err);
                    });
                } else {
                    context.postAssignment(createPayload);
                }

            }
        }
    }

    activityOnChange(evt) {


        this.setState({
            ...this.state,
            currentActivity: evt.target.value
        });
    }

    render() {

        let courses = this.props.manageCourses.results;
        let activities = this.props.manageActivities.results;
        let courseList = courses.map(function (c) {
            return { name: c.courseName, id: c.id };
        });
        let activityList = activities.map(function (c) {
            return { name: c.name, id: c.id };
        });
        let selectedCourse = null;
        let selectedActivity = null;
        if (this.state.currentCourse) {
            selectedCourse = this.state.currentCourse.courseID;
        }
        if (this.state.currentActivity) {
            selectedActivity = this.state.currentActivity;
        }
        let units = this.getUnits(this.state.currentCourse);
        let courseID = this.state.currentCourse ? this.state.currentCourse.courseID : '';
        let courseName = this.state.currentCourse ? this.state.currentCourse.courseName : '';

        let courseView = this.state.view === 'course' ? <div className="CourseContainer">
            <div id="courseAssign">
                <div className="row">

                </div>
                <div className="CourseView">
                    <div className="row">
                        <div className="col-md-4 col-lg-4 col-sm-6 col-xs-12">
                            {this.state.currentCourse && <h1 style={{ color: '#0f6fb4' }}>
                                <input type="checkbox" value={courseID}
                                    id={'course-id-' + courseID} className="css-checkbox"
                                    name={courseName} onChange={this.toggleSelectionAll} />
                                <label htmlFor={'course-id-' + courseID}
                                    className="pay_label css-label1" />
                                {courseName}</h1>}
                        </div>
                        <div className="col-md-4 col-lg-4 col-sm-4 col-xs-12" style={{ 'marginTop': '10px' }}>
                            <input type="checkbox" value='preserve' id='preserveOrder' className="css-checkbox" />
                            <label htmlFor='preserveOrder' className="pay_label css-label1">Lock order for delivery of activities</label>
                        </div>
                        <div className="col-md-4 col-lg-4 col-sm-2 col-xs-12 text-right">
                            <div className="actionBlockDiv ques">
                                <span className="WHSign" onClick={() => { this.setState({ showHelpDialog: true }) }} />
                            </div>
                        </div>
                        {units}
                    </div>
                    <div className="row text-right">
                        <div className="col-md-3 col-sm-3 pull-right m-t-15">
                            <button className="save" onClick={this.assign}>Assign</button>
                        </div>
                    </div>
                </div>
            </div>
        </div> : '';
        let activityView = this.state.view === 'activity' && !this.state.currentActivity ? <div id="activityView">
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <label><strong>Select an Activity</strong></label>
                    <SelectFieldGroup
                        error={this.state.errors.selectedActivity}
                        field="selectedActivity"
                        value={selectedActivity}
                        options={activityList}
                        placeholder="Select Activity to Assign"
                        onChange={this.activityOnChange}
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <button className="save" onClick={this.assign} style={{ marginTop: '15px' }}>Assign</button>
                </div>
            </div>
        </div> : '';
        let newActivityView = this.state.view === 'newActivity' ? <div id="newActivityView">
            <div className="message">New Activity can be created in Manage Activities.</div>
            <a href="javascript:void 0" className="activityLink" onClick={() => {
                browserHistory.push({
                    pathname: '/assignments/activities',
                    state: { selectActivityForAssignment: true, view: 'course', selectedActivity: null }
                });
            }
            }>Go to Activities</a></div> : '';
        let choices = <div id="assignment_choices">
            <ul>
                <li>
                    <input id="course" type="radio" name="assignmentType" value="course" />
                    <label htmlFor="course">Course or Activities in a Course</label>
                </li>
                <li>
                    <input id="activity" type="radio" name="assignmentType" value="activity" />
                    <label htmlFor="activity">My Activity Listing</label>
                </li>
                <li>
                    <input id="newActivity" type="radio" name="assignmentType" value="newActivity" />
                    <label htmlFor="newActivity">Create New Activity</label>
                </li>
            </ul>
        </div>;

        return (
            <div>
                <Breadcrumb breadcrumbsData={this.getBreadcrumbs()} />
                {this.state.showAlert &&
                    <div className="row">
                        <div className="col-md-3 col-xs-3"></div>
                        <div className="col-md-6 col-xs-3">
                            <Alert bsStyle={this.state.alertStyle}
                                onDismiss={this.handleAlertDismiss}>
                                {this.state.alertText}
                            </Alert>
                        </div>
                        <div className="col-md-3 col-xs-3"></div>
                    </div>
                }
                <div className="container-fluid">
                    {courseView}
                    {activityView}
                    {newActivityView}
                </div>
                <ProgressIndicator show={this.state.loading} />
                {this.state.showAssignDialog && this.getAssignDialog()}
                {this.state.showHelpDialog && this.getHelpDialog()}
            </div>
        );
    }

    getHelpDialog() {

        let body = <div id="deleteDialogContent">
            <p>
                You can assign the entire course, units, or activities within this course.  You can "lock the order" so your students are required to finish the activites in sequence.
            </p>
        </div>;
        return <Modal
            show={this.state.showHelpDialog}
            onHide={() => {
                this.setState({
                    showHelpDialog: false
                });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Help</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    this.setState({
                        showHelpDialog: false,
                    });
                }}>Ok</Button>
            </Modal.Footer>
        </Modal>;
    }

    showIndicator() {
        this.setState({
            ...this.state,
            loading: true
        });
    }

    hideIndicator() {
        this.setState({
            ...this.state,
            loading: false
        });
    }

    handleAlertDismiss(e) {
        this.setState({ 'showAlert': false });
    }

    onChange(e) {

        this.getCourse(e.target.value);
    }

    getValidationState() {

        if (!this.state.duplicateDialogInput) {
            return 'error';
        } else if (this.props.manageActivities.allRecords.filter(function (record) {
            return record.name.trim() === this.state.duplicateDialogInput.trim();
        }.bind(this)).length > 0) {
            return 'error';
        }
        else {
            return 'success';
        }
    }

    /**
     * @function
     * @name removeEmptyStrings
     *
     * @description Removing the empty strings from string values in an object
     *
     * @param {*} object
     * @returns cleaned object
     * @memberof CreateAssignment
     */
    removeEmptyStrings(object) {
        for (let question in object.questions) {
            for (let questionData in object.questions[question]) {
                if (typeof object.questions[question][questionData] === "string" && object.questions[question][questionData] === "") {
                    delete object.questions[question][questionData]
                }
            }
        }
        return object;
    }
}

function mapStateToProps(state) {
    return { ...state };
}
export default connect(mapStateToProps)(CreateAssignment);
