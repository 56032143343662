import React, { Component } from "react";
import apiClient from "../../actions/api-client";
import {
    CREATE_QUESTION,
    MODIFY_QUESTION,
    RECEIVED_QUESTIONS_RESULTS,
    ElasticSearchTypes,
    ElasticSearchColumns,
    CLONE_QUESTION,
    SET_CURRENT_USER
} from "../../actions/types";
// import axios from "axios";
import config from "../../config";
import SelectFieldGroup from "../forms/select-field-group";
import { connect } from "react-redux";
import Breadcrumb from "../breadcrumb/breadcrumb";
import { Button } from "react-bootstrap";
import ProgressIndicator from "../progress-indicator/progress-indicator";
import { Alert } from "react-bootstrap";


class MoveQuestionsComponent extends Component {
    constructor(props) {
        super(props);
        this.getAllSubjects = this.getAllSubjects.bind(this);
        this.handleAlertDismiss = this.handleAlertDismiss.bind(this)
        this.propertyChangedHandler = this.propertyChangedHandler.bind(this);

        this.state = {
            customSubjects: [],
            allSubjects: [],
            toSubject: "",
            fromSubject: "",
            toCategory: "",
            fromCategory: "",
            toSubCategory: "",
            fromSubCategory: "",

            fromallCategories: [],
            fromallSubCategories: [],
            toallCategories: [],
            toallSubCategories: [],

        }

    }

    componentDidMount() {

        this.getAllSubjects();
    }

    //user subjects
    async getAllSubjects() {

        let context = this;

        let schoolName = this.props.auth.user.schoolName ? this.props.auth.user.schoolName : 'trial';
        let params = {
            schoolName: schoolName,
            ownerName: this.props.auth.user.userName,
        };

        let pathTemplate = '/subjects/ownerName/{ownerName}/schoolName/{schoolName}';
        let method = 'GET';
        let additionalParams = {};
        let body = {};

        apiClient.invokeApi(params, pathTemplate, method, additionalParams, body).then(function (result) {

            context.setState({
                ...context.state,
                customSubjects: result.data,
                loading: false
            }, function () {
                this.updateSubjectsList([]);
            });
        }).catch(function (error) {

        });

        let sortedSubjects = []
        this.props.auth.user.subjects.forEach(sub => {

            sortedSubjects.push({ "id": sub, "name": sub });
        })

        this.updateSubjectsList(sortedSubjects);
    }

    updateSubjectsList(sortedSubjects) {

        let { subjects } = this.props.auth.user;
        // if (subjects) {

        //     subjects.forEach(subject => {

        //         if (!sortedSubjects.find(x => x.name == subject)) {

        //             sortedSubjects.push({ "id": subject, "name": subject });
        //         }
        //     });
        // }


        if (this.state.customSubjects) {
            var lstSubjects = [];

            this.state.customSubjects.forEach(x => {

                let subjectName = x.name;

                // if (subjects.find(y => y == subjectName))
                //     return;

                lstSubjects.push({ "id": subjectName, "name": subjectName });
            });

            sortedSubjects = lstSubjects;
        }

        this.sortList(sortedSubjects);
        this.setState({ allSubjects: sortedSubjects });
    }


    sortList(sortSubject, shouldChronological) {

        sortSubject.sort(function (a, b) {
            return a.id.localeCompare(b.id);
        });

        if (shouldChronological) {

            var allMonths = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];
            var regexYear = /(19[0-9]{2}|20[0-9]{2})/;
            let chronoObj = [];
            let sortSubjectCopy = [];

            sortSubject.forEach(x => {

                let sourceId = x.id;

                let firstSpaceIndex = sourceId.indexOf(' ');
                let extractedMonth = sourceId.slice(0, firstSpaceIndex)

                if (!(extractedMonth) ? allMonths.includes(extractedMonth.toLowerCase()) : null)
                    return;

                let yearMatched = regexYear.exec(sourceId);
                if (!yearMatched)
                    return;

                let innerObj = chronoObj.find(x => x.year === yearMatched[0])
                if (!innerObj) {

                    let obj = {};

                    obj['year'] = yearMatched[0];

                    obj['months'] = [];
                    obj.months.push({
                        month: extractedMonth,
                        data: [x]
                    });
                    chronoObj.push(obj);
                } else {
                    let innerMonthsObj = innerObj.months.find(x => x.month === extractedMonth);
                    if (!innerMonthsObj) {
                        innerObj.months.push({
                            month: extractedMonth,
                            data: [x]
                        });

                    } else {
                        innerMonthsObj.data.push(x);
                    }
                }
            })


            chronoObj.sort((a, b) => {
                return a.year - b.year;
            });

            chronoObj.forEach(x => {
                x.months.sort((a, b) => {
                    return allMonths.indexOf(a.month.toLowerCase()) > allMonths.indexOf(b.month.toLowerCase());
                });
            });

            chronoObj.forEach(x => x.months.forEach(x => {
                x.data.forEach(x => {
                    sortSubjectCopy.push(x);
                })
            })
            );

            if (sortSubjectCopy.length>0) {
              sortSubject.sort((a, b) => {

                  var valA = a;
                  var valB = b;

                  if (sortSubjectCopy.indexOf(valA) < sortSubjectCopy.indexOf(valB)) {
                      return -1;
                  }

                  if (sortSubjectCopy.indexOf(valA) > sortSubjectCopy.indexOf(valB)) {
                      return 1;
                  }

                  return 0;

              });
            }
        }
    }


    requestElasticSearch(control, subjects, category) {

        subjects = Array.isArray(subjects) ? subjects : [subjects];
        // let axiosInstance = axios.create({
        //     baseURL: 'https://search-learnerpal-eimkio3umgx5fnpuy7ypsrrsri.us-east-1.es.amazonaws.com/learnerpal/',
        //     timeout: 60000,
        //     headers: { 'Content-Type': 'application/json' },
        //     responseType: 'json',
        //     transformRequest: [function (data) {
        //         return data;
        //     }],
        //     transformResponse: {
        //         function(data) {
        //             let reponse = [];
        //             if (data.aggregations) {
        //                 for (let key in data.aggregations) {
        //                     if (data.aggregations.hasOwnProperty(key)) {
        //                         let values = [];
        //                         data.aggregations[key].buckets.forEach(value => {
        //                             values.push({ name: value.key, count: value.doc_count })
        //                         });
        //
        //                         reponse.push({ label: key, allValues: values });
        //                         //console.log(key + " -> " + data.aggregations[key]);
        //                     }
        //                 }
        //             }
        //             return reponse;
        //         }
        //     }
        // });


        let visibility = '(public OR "' + this.props.auth.user.userName + '"';
        if (this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName === "LearnerPal") {
            visibility += " OR LPAdmin";
        }
        visibility += ')';

        let subjectsToSearch = "";
        if (subjects) {
            subjects.forEach(sub => {
                subjectsToSearch += "\"" + sub + "\" OR ";
            })
        }

        subjectsToSearch = subjectsToSearch.substring(0, subjectsToSearch.length - 3);



        let visibilityQuery = "visibleTo : " + visibility
        if (this.props.auth.user.schoolName === "LearnerPal")
            visibilityQuery = "( " + visibilityQuery + " OR questionState2: (\"In Review\") OR questionState2: (\"Shared\"))"

        let subjectsQueryString = visibilityQuery + " AND subjects : (" + subjectsToSearch + ")";

        let type = ElasticSearchTypes.Question + config.stage;

        let aggs = {
            category: { terms: { field: "category", size: 100 } },
        };

        if (category) {
            aggs.subcategory = { terms: { field: "subcategory", size: 100 } }

            subjectsQueryString += " AND category:(\"" + category + "\")";
        }

        let queryObject = {

            query: {
                bool: {
                    must: { query_string: { query: subjectsQueryString } },

                    filter: { bool: { must: [{ terms: { subjects: subjects } }] } }
                }
            },
            aggs: aggs,
        };

        apiClient.invokeApi({}, '/getesdata', 'POST', {}, { payload: queryObject, index: 'learnerpal/', esDocumentType: type }, '5').then(function (response) {
        // axiosInstance({
        //     method: 'post',
        //     url: '/' + type + '/_search',
        //     data: JSON.stringify(queryObject)
        // }).then(response => {
            let categories = [];
            let allSubCategories = [];

            if (response && response.data) {




                let dataforAgg = response.data;
                let reponseAgg = [];
                    if (dataforAgg.aggregations) {
                        for (let key in dataforAgg.aggregations) {
                            if (dataforAgg.aggregations.hasOwnProperty(key)) {
                                let values = [];
                                dataforAgg.aggregations[key].buckets.forEach(value => {
                                    values.push({ name: value.key, count: value.doc_count })
                                });
                                reponseAgg.push({ label: key, allValues: values });
                                //console.log(key + " -> " + dataforAgg.aggregations[key]);
                            }
                        }
                    }




                for (let data of reponseAgg) {

                    if (data.label === "category") {
                        for (let categ of data.allValues) {
                            categories.push({ id: categ.name, name: categ.name });
                        }

                        //this.setState({ allCategories: categories });
                    }
                    else if (data.label === "subcategory") {

                        for (let categ of data.allValues) {
                            allSubCategories.push({ id: categ.name, name: categ.name });
                        }

                    }
                }
            }

            // get custom subjects
            if (this.state.customSubjects) {

                let subject = this.state.customSubjects.find(x => x.name === subjects[0]);

                if (subject) {

                    let selectedCategory = null;
                    if (subject.categories) {

                        subject.categories.forEach(categ => {

                            if (categ.category === this.state.selectedCategory)
                                selectedCategory = categ;

                            if (categories.find(x => x.name == categ.category)) {
                                return;
                            }

                            categories.push({ id: categ.category, name: categ.category, state: categ.state, subCategories: categ.subCategories })
                        })
                    }

                    if (selectedCategory && selectedCategory.subCategories) {
                        selectedCategory.subCategories.forEach((subCateg) => {

                            if (allSubCategories.find(x => x.id == subCateg.subCategory)) {
                                return;
                            }

                            allSubCategories.push({ id: subCateg.subCategory, name: subCateg.subCategory, state: subCateg.state })
                        });
                    }

                }
            }

            //this.setState({ allCategories: categories, allSubCategories: allSubCategories });

            this.sortList(categories);
            this.sortList(allSubCategories);

            if (category) {
                this.setState({ [control + "allSubCategories"]: allSubCategories });
            } else {
                this.setState({ [control + "allCategories"]: categories, [control + "allSubCategories"]: allSubCategories });
            }


        }).catch();
    }


    renderSubjectControls(isSourceControl) {

        let source = isSourceControl ? "from" : "to";

        let subjectProp = source + "Subject",
            categProp = source + "Category",
            subCategProp = source + "SubCategory";

        let subjectsControl =
            <SelectFieldGroup
                dropDownId={source + "subjectDropDownId"}
                value={this.state[subjectProp]}
                field={subjectProp}
                options={this.state.allSubjects}
                placeholder="Select Subject"
                onChange={this.propertyChangedHandler}
            />

        let categoryControl =
            <SelectFieldGroup
                dropDownId="categoryDropDownId"
                field={categProp}
                value={this.state[categProp]}
                options={this.state[source + "allCategories"]}
                placeholder="Select Category"
                onChange={this.propertyChangedHandler}
            />

        let subCategoryControl =
            <SelectFieldGroup
                dropDownId="subcategoryDropDownId"
                field={subCategProp}
                value={this.state[subCategProp]}
                options={this.state[source + "allSubCategories"]}
                placeholder="Select Subcategory"
                onChange={this.propertyChangedHandler}
            />;


        return <div>
            <div className="col-md-4">{subjectsControl}</div>
            <div className="col-md-4">{categoryControl}</div>
            <div className="col-md-4">{subCategoryControl}</div>
        </div>


    }

    propertyChangedHandler(e) {

        let context = this;
        let update = { [e.target.name]: e.target.value };
        let propName = e.target.name;
        let propValue = e.target.value;


        let control = propName.startsWith("from") ? "from" : "to";

        if (control + "Subject" === propName) {

            this.state[control + 'Category'] = "";
            this.state[control + 'SubCategory'] = "";

            // this.requestElasticSearch(control, propValue, this.state[control + 'Category']);

            let subject = this.state.customSubjects.find(x => x.id == propValue);
            if (subject && subject.categories) {
                let lstCategories = [];
                for (let categ of subject.categories) {
                    lstCategories.push({ id: categ.category, name: categ.category });
                }

                this.state[control + "allCategories"] = lstCategories;
            }

            // [control + "allCategories"]: categories, [control + "allSubCategories"]: allSubCategories
        } else if (control + "Category" === propName) {
            this.state[control + 'SubCategory'] = "";
            // this.requestElasticSearch(control, this.state[control + "Subject"], propValue);


            let subject = this.state.customSubjects.find(x => x.id === this.state[control + "Subject"]);
            if (subject && subject.categories) {
                console.log(subject.categories);
                let category = subject.categories.find(y => y.category === propValue)
                if (category && category.subCategories) {

                    let lstSubCategories = [];
                    for (let categ of category.subCategories) {
                        lstSubCategories.push({ id: categ.subCategory, name: categ.subCategory });
                    }

                    this.sortList(lstSubCategories, true);

                    this.state[control + "allSubCategories"] = lstSubCategories;
                }
            }
        }

        this.setState(update);
    }




    handlePropertyChanged(propName, propValue) {


        let that = this;
        let update = { [propName]: propValue };

        if (propName == "selectedQuestionType") {
            this.setState({ showCreateQuestionDialogBox: true })
        }

        if (propName == "selectedSubject") {

            update.selectedCategory = "";
            update.selectedSubCategory = "";
            update.selectedQuestionType = "";

            this.state.selectedCategory = "";
            this.state.selectedSubCategory = "";

            this.state.allCategories = [];
            this.state.allSubCategories = [];

            let subject = this.state.customSubjects.find(x => x.id == propValue);
            if (subject && subject.categories) {
                let lstCategories = [];
                for (let categ of subject.categories) {
                    lstCategories.push({ id: categ.category, name: categ.category });
                }
                this.state.allCategories = lstCategories;
            }

        } else if (propName == "selectedCategory") {

            update.selectedSubCategory = "";
            update.selectedQuestionType = "";

            this.state.selectedSubCategory = "";
            this.state.allSubCategories = [];

            let subject = this.state.customSubjects.find(x => x.id === this.state.selectedSubject);
            if (subject && subject.categories) {
                console.log(subject.categories);
                let category = subject.categories.find(y => y.category === propValue)
                if (category && category.subCategories) {

                    let lstSubCategories = [];
                    for (let categ of category.subCategories) {
                        lstSubCategories.push({ id: categ.subCategory, name: categ.subCategory });
                    }

                    this.sortList(lstSubCategories, true);

                    this.state.allSubCategories = lstSubCategories;
                }
            }
        }

        this.setState(update, function () {

            if (propName !== "selectedSubCategory")
                return;

            this.requestAllQuestions();

        });
    }




































    handleAlertDismiss(e) {
        this.setState({ 'showAlert': false });
    }

    showIndicator() {
        this.setState({
            ...this.state,
            loading: true
        });
    }

    hideIndicator() {
        this.setState({
            ...this.state,
            loading: false
        });
    }



    updateQuestionState() {

        if (!(this.state['fromSubject'] && this.state['fromCategory'] && this.state['fromSubCategory'] && this.state['toSubject'] && this.state['toCategory'] && this.state['toSubCategory'])) {

            this.hideIndicator();

            this.setState({
                showAlert: true,
                alertStyle: 'danger',
                alertText: 'Select all fields to continue'
            });

            setTimeout(() => {
                this.setState({ showAlert: false });
            }, 4000);

            return;
        }


        //show indicator
        this.showIndicator();

        let Subject = this.state["fromSubject"],
            Category = this.state["fromCategory"],
            SubCategory = this.state["fromSubCategory"];

        let body = {
            ownerName: this.props.auth.user.userName,
            schoolName: this.props.auth.user.schoolName,
            subjectName: Subject,
        };

        //learnerpal - get all the subjects
        // let axiosInstance = axios.create({
        //     baseURL: 'https://search-learnerpal-eimkio3umgx5fnpuy7ypsrrsri.us-east-1.es.amazonaws.com/learnerpal/',
        //     timeout: 60000,
        //     headers: { 'Content-Type': 'application/json' },
        //     responseType: 'json',
        //     transformRequest: [function (data) {
        //         return data;
        //     }],
        //     transformResponse: {
        //         function(data) {
        //
        //             let questionIds = [];
        //             if (data && data.hits && data.hits.hits && data.hits.hits.length > 0) {
        //                 data.hits.hits.forEach(questionElement => {
        //                     questionIds.push(questionElement._source.id);
        //                 })
        //             }
        //
        //             return questionIds;
        //         }
        //     }
        // });

        let visibility = '("' + this.props.auth.user.userName + '"';
        if (this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName === "LearnerPal") {
            visibility += " OR public OR LPAdmin";
        }

        visibility += ')';

        let subjectsQuery = 'subjects: ("' + Subject + '") ';

        subjectsQuery += 'AND category: ("' + Category + '") ';

        subjectsQuery += 'AND subcategory: ("' + SubCategory + '") ';

        let subjectsQueryString = subjectsQuery + " AND visibleTo : " + visibility

        let queryObject = {

            "_source": ["subjects", "id"],
            "query": {
                "bool": {
                    "must": [
                        {
                            "query_string": {
                                "query": subjectsQueryString
                            }
                        }
                    ]
                }
            },
            "size": 1000
        };

        let type = ElasticSearchTypes.Question + config.stage;
        let context = this;

        apiClient.invokeApi({}, '/getesdata', 'POST', {}, { payload: queryObject, index: 'learnerpal/', esDocumentType: type }, '5').then( (response) => {
        // axiosInstance({ method: 'post', url: '/' + type + '/_search', data: JSON.stringify(queryObject) })
        //     .then(response => {

                console.log(response.data);

            let data = response.data;

            let questionIds = [];
            if (data && data.hits && data.hits.hits && data.hits.hits.length > 0) {
                data.hits.hits.forEach(questionElement => {
                    questionIds.push(questionElement._source.id);
                })
            }

            response.data = questionIds;

                let noOfQuestionsMoved = response.data.length;

                if(noOfQuestionsMoved == 0) {
                    context.hideIndicator();

                    context.setState({
                        showAlert: true,
                        alertStyle: 'warning',
                        alertText: 'No question found in SubCategory: ' + SubCategory
                    });

                    setTimeout(() => {
                        context.setState({ showAlert: false });
                    }, 4000);

                    return;
                }

                body.toSubject = this.state["toSubject"];
                body.toCategory = this.state["toCategory"];
                body.toSubCategory = this.state["toSubCategory"];

                body.questionIds = response.data;
                // body.questionState = questionState;

                apiClient.invokeApi({}, '/moveQuestions', "PUT", {}, body, "5")
                    .then(function (result) {

                        console.log(result);
                        // context.getAllSubjects();


                        context.hideIndicator();

                        context.setState({
                            showAlert: true,
                            alertStyle: 'success',
                            alertText: noOfQuestionsMoved + " " + result.data
                        });

                        setTimeout(() => {
                            context.setState({ showAlert: false });
                        }, 4000);

                    }).catch((error) => {
                        console.error(error);
                        context.hideIndicator();
                        context.setState({
                            showAlert: true,
                            alertStyle: 'danger',
                            alertText: error.data
                        });

                        setTimeout(() => {
                            context.setState({ showAlert: false });
                        }, 4000);
                    });

            }).catch((error) => {
                console.error(error);
                context.hideIndicator();
                context.setState({
                    showAlert: true,
                    alertStyle: 'danger',
                    alertText: 'Question can not moved'
                });

                setTimeout(() => {
                    context.setState({ showAlert: false });
                }, 4000);
            });

    }

    render() {
        return (


            <div>

                <Breadcrumb
                    breadcrumbsData={
                        [
                            {
                                name: 'Questions', action: () => {
                                    this.getAllSubjects();

                                    this.setState({ addCustomSubjects: false, selectedSubject: "", selectedCategory: "", selectedSubCategory: "" })
                                }
                            },
                            {
                                name: 'Move Questions', action: () => {

                                }
                            }
                        ]
                    }
                />



                    {/* alert box */}
                {this.state.showAlert &&
                    <div className="row">
                        <div className="col-md-3 col-xs-3" />
                        <div className="col-md-6 col-xs-3">
                            <Alert bsStyle={this.state.alertStyle}
                                onDismiss={this.handleAlertDismiss}>
                                {this.state.alertText}
                            </Alert>
                        </div>
                        <div className="col-md-3 col-xs-3" />
                    </div>
                }



                <h1 style={{ textAlign: 'center' }}>
                    <strong>Move questions</strong>
                </h1>

                <div className="mainFormBox" style={{ margin: '30px 6%' }}>
                    <div id="assignment-analysis" className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                            <div>


                                <div key="subjects">
                                    <div>
                                        {
                                            [
                                                <div className="col-md-12"> <h1>From:</h1> {this.renderSubjectControls(true)} </div>,
                                                <div className="col-md-12"> <h1> To: </h1> {this.renderSubjectControls(false)} </div>,
                                            ]
                                        }
                                    </div>

                                    <Button className="col-md-offset-10 col-md-1" onClick={() => {
                                        this.updateQuestionState();
                                    }} bsStyle='primary'>Move</Button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ProgressIndicator show={this.state.loading} />

            </div>



        );
    }
}



function mapStateToProps(state) {
    return { ...state };
}

export default connect(mapStateToProps)(MoveQuestionsComponent);
