import React, {Component} from 'react';
import {connect} from 'react-redux';
import GoogleAnalytics from 'react-g-analytics';

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            credentials: null
        };
    }

    updateCredentials = (userToken) => {
        this.setState({
            credentials: userToken
        });
    };


    render() {
        return (
            <div>
                <GoogleAnalytics id="UA-106849405-1"/>
                {this.props.children}
            </div>

        );
    };
}
function mapStateToProps(state) {
    return {...state};
}
export default connect(mapStateToProps)(App);
