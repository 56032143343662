import {
    FETCH_ALL_ACTIVITIES,
    CREATE_QUESTION_SET_INIT_INFO,
    SORT_ALL_ACTIVITIES,
    SEARCH_RESULT,
    RECEIVED_ACTIVITY_RESULTS,
    FETCH_ACTIVITY_FAILED,
    START_VIEW_ACTIVITY,
    FAILED_VIEW_ACTIVITY,
    RCEIVED_VIEW_ACTIVITY,
    SHOW_FULL_QUESTION,
    CREATE_ACTIVITY_MOVE_QUESTION,
    CREATE_ACTIVITY_FETCH_QUESTION_FAILED,
    CREATE_ACTIVITY_FETCH_QUESTION_SUCCESS,
    CREATE_ACTIVITY_FETCH_FLASHCARD_SUCCESS,
    CREATE_ACTIVITY_FETCH_FLASHCARD_FAILED,
    SET_MESSAGE_ACTIVITIES,
    CREATE_ACTIVITY_MOVE_FLASHCARD,
    CREATE_FLASHCARD_SET_INIT_INFO
} from "../actions/types";

export default function manageActivities(state = {results: [], paginatedResult:[], viewActivityData:{questions:[]}, viewActivityFetchingFailed: false,createActivityQuestionClearFilters:false, createActivityQuestionClearFiltersForSearch: false, refreshFilters: false}, action) {
    switch (action.type) {
        case FETCH_ALL_ACTIVITIES:
            return {
                ...state,
                isFetching: true,
                results: []
            };
        case SORT_ALL_ACTIVITIES:
            return {
                ...state,
                isFetching: false,
                results: action.results
            };
        case SEARCH_RESULT:
            return {
                ...state,
                results: action.results,
                searchResults: action.results,
                clearFilters: true
            };
        case RECEIVED_ACTIVITY_RESULTS:
            
            if (action.skipAlterForAllRecords) {
                return {
                    ...state,
                    isFetching: false,
                    results: action.data,
                    filteredRecords: action.data,
                    alertText: action.alertText,
                    alertStyle: action.alertStyle,
                    showAlert: action.showAlert,
                    clearFilters: action.clearFilters,
                    totalRecords:action.totalRecords,
                };
            } else {
                return {
                    ...state,
                    isFetching: false,
                    results: action.data,
                    allRecords: action.data,
                    searchResults: action.data,
                    filteredRecords: action.data,
                    totalRecords:action.totalRecords,
                    alertText: action.alertText,
                    alertStyle: action.alertStyle,
                    clearFilters: action.clearFilters,
                    showAlert: action.showAlert
                };
            }

        case FETCH_ACTIVITY_FAILED:
            const status = action.data && action.data.response && action.data.response.status ? action.data.response.status : '';
            const statusText = action.data && action.data.response && action.data.response.statusText ? action.data.response.statusText : '';
            return {
                ...state,
                isFetching: false,
                error: action.data.message,
                status: status,
                statusText: statusText,
                alertText: action.alertText,
                alertStyle: action.alertStyle,
                showAlert: action.showAlert

            };
        case START_VIEW_ACTIVITY:
            return {
                ...state,
                isFetchingViewActivity:true,
                viewActivityFetchingFailed: false,
                viewActivityData:{questions:[]}
            };
        case FAILED_VIEW_ACTIVITY:
            return {
                ...state,
                isFetchingViewActivity:true,
                viewActivityFetchingFailed: true
            };
        case RCEIVED_VIEW_ACTIVITY:
            return {
                ...state,
                isFetchingViewActivity:false,
                viewActivityFetchingFailed: false,
                viewActivityData: action.viewActivityData
            };
        case SHOW_FULL_QUESTION:
            return {
                ...state,
                fullQuestion:action.fullQuestion
            };
        case CREATE_ACTIVITY_FETCH_QUESTION_SUCCESS:
            
                return {
                    ...state,
                    createActivityQuestions: action.data,
                    createActivityQuestionClearFilters: action.createActivityQuestionClearFilters,
                    createActivityQuestionClearFiltersForSearch: action.createActivityQuestionClearFiltersForSearch
                };
        case CREATE_ACTIVITY_MOVE_QUESTION:
            let keysToBeUpdated = [
                'createActivityQuestions',
                'createActivitySelectedQuestions',
                'createActivityQuestionTaggedKeywordsHtml',
                'createActivityQuestionTaggedKeywords',
                'createActivityQuestionTaggedSubjectsHtml',
                'createActivityQuestionTaggedSubjects',
                'createActivityQuestionAllSubjects',
                'createActivityActiveOwnerFilter',
                'createActivityQuestionSearchTerm',
                'createActivityQuestionActivityName',
                'createActivityQuestionActivityType',
                'createActivityQuestionActivityState',
                'createActivityQuestionSearchResults',
                'createActivityQuestionClearFilters',
                'createActivityQuestionClearFiltersForSearch',
                'refreshFilters',
                'createActivityQuestionDescription'

            ];
            let newState = {
                ...state
            };
            keysToBeUpdated.forEach(key => {
                if (action[key]) {
                    newState[key] = action[key];
                }
            });
            return newState;
        case CREATE_QUESTION_SET_INIT_INFO:
            return {
                ...state,
                createActivityQuestionTaggedKeywordsHtml: action.taggedKeywordsHtml,
                createActivityQuestionTaggedKeywords: action.taggedKeywords,
                createActivityQuestionTaggedSubjectsHtml: action.taggedSubjectsHtml,
                createActivityQuestionTaggedSubjects: action.taggedSubjects,
                createActivityQuestionAllSubjects: action.allSubjects,
                createActivityQuestionActivityType: action.activityType,
                createActivityQuestionActivityName: action.activityName,
                createActivitySelectedQuestions:action.selected,
                createActivityQuestionActivityState: action.activityState,
                createActivityQuestionDescription: action.description
            };
        case CREATE_ACTIVITY_FETCH_QUESTION_FAILED:
            return {
                ...state,
                createActivityQuestions: action.data,
                createActivityAllQuestions: action.data,
            };
        case CREATE_ACTIVITY_FETCH_FLASHCARD_SUCCESS:
            if (action.skipAlterForAllRecords) {
                return {
                    ...state,
                    createActivityFlashCards: action.data,
                    createActivityFlashCardClearFilters: false
                };
            } else {
                return {
                    ...state,
                    createActivityFlashCards: action.data,
                    createActivityAllFlashCards: action.data,
                    createActivityFlashCardSearchResults: action.data,
                };
            }
        case CREATE_ACTIVITY_MOVE_FLASHCARD:
            let keysToBeUpdatedFlashCard = [
                'createActivityFlashCards',
                'createActivitySelectedFlashCards',
                'createActivityFlashCardTaggedKeywordsHtml',
                'createActivityFlashCardTaggedKeywords',
                'createActivityFlashCardTaggedSubjectsHtml',
                'createActivityFlashCardTaggedSubjects',
                'createActivityFlashCardAllSubjects',
                'createActivityActiveOwnerFilter',
                'createActivityFlashCardSearchTerm',
                'createActivityFlashCardActivityName',
                'createActivityFlashCardActivityType',
                'createActivityFlashCardActivityState',
                'createActivityFlashCardSearchResults',
                'createActivityFlashCardClearFilters',
                'refreshFilters',
                'createActivityFlashCardDescription'
            ];
            let newStateFlashcard = {
                ...state
            };
            keysToBeUpdatedFlashCard.forEach(key => {
                if (action[key]) {
                    newStateFlashcard[key] = action[key];
                }
            });
            return newStateFlashcard;
        case CREATE_FLASHCARD_SET_INIT_INFO:
            return {
                ...state,
                createActivityFlashCardTaggedKeywordsHtml: action.taggedKeywordsHtml,
                createActivityFlashCardTaggedKeywords: action.taggedKeywords,
                createActivityFlashCardTaggedSubjectsHtml: action.taggedSubjectsHtml,
                createActivityFlashCardTaggedSubjects: action.taggedSubjects,
                createActivityFlashCardAllSubjects: action.allSubjects,
                createActivityFlashCardActivityType: action.activityType,
                createActivityFlashCardActivityName: action.activityName,
                createActivitySelectedFlashCards:action.selected,
                createActivityFlashCardActivityState: action.activityState,
                createActivityFlashCardDescription: action.description
            };
        case CREATE_ACTIVITY_FETCH_FLASHCARD_FAILED:
            return {
                ...state,
                createActivityFlashCards: action.data,
                createActivityAllFlashCards: action.data,
                createActivityActiveOwnerFilter: 'LearnerPal'
            };

        case SET_MESSAGE_ACTIVITIES:
            return {
                ...state,
                alertText: action.alertText,
                alertStyle: action.alertStyle,
                showAlert: action.showAlert
            };
            default:
            return state
    }
};
