import React from "react";
import {connect} from "react-redux";
import {Alert} from "react-bootstrap";
import ProgressIndicator from "../../progress-indicator/progress-indicator";
import Heading from "../../heading/heading";
import apiClient from "../../../actions/api-client";
//import "./practice.css";
import CourseBox from "./course-box";

class StudentPractice extends React.Component {
    constructor(props) {
        super(props);
        this.getAllSubjects = this.getAllSubjects.bind(this);
        this.startOrResume = this.startOrResume.bind(this);

        this.state = {screenWidth: window.innerWidth, subjects:[], showAlert:false, alertText:'', alertStyle:'', allowAlert:true};


        this.filterComponentCssClassName = 'filterBlock NiceScroll';
        this.tableCssClassName = 'listViewBlock ';
    }

    componentDidMount() {
        this.getAllSubjects();
        let context = this;
        if (this.props.params.id)
        {
            
            this.setState({showAlert:this.props.location.state.showAlert, alertStyle:this.props.location.state.alertStyle, alertText:this.props.location.state.alertText});
            setTimeout(()=>{
                context.setState({showAlert: false, allowAlert:false});
            }, 5000);
        }
    }

    startOrResume(assignment) {
        console.log(assignment, 'Start/Resume called.');
    }

    render() {
        let context = this;
        let subjects = context.state.subjects.map(sub => {
            return <CourseBox subject={sub} key={'subject-' + sub.id} />
        });
        return (
            <div>
                <Heading
                        isHideHeading={false}
                        allButtons={[]}
                        isCreateButton={false}
                        dataPath={'studentPortal.assignments.results'}
                        allRecords={'studentPortal.assignments.allRecords'}
                        actionName={'SEARCH_RESULT'}
                        filteredData={'studentPortal.assignments.filteredRecords'}
                        listPage={'activities'}
                        heading="Self Study"
                        isShowButton={false}
                        isSearch={false}
                        breadcrumbsData={[]}
                        showFilterButtons={'false'}
                    />
                {this.state.showAlert && this.state.allowAlert &&
                <div className="row">
                    <div className="col-md-3 col-xs-3"/>
                    <div className="col-md-6 col-xs-3">
                        <Alert bsStyle={this.state.alertStyle}
                               onDismiss={this.handleAlertDismiss}>
                            {this.state.alertText}
                        </Alert>
                    </div>
                    <div className="col-md-3 col-xs-3"/>
                </div>
                }
                <div className="container-fluid noPad listMainBoxWrapper ">
                    {this.state.showAlert &&
                    <div className="row">
                        <div className="col-md-3 col-xs-3"/>
                        <div className="col-md-6 col-xs-3">
                            <Alert bsStyle={this.state.alertStyle}
                                   onDismiss={this.handleAlertDismiss}>
                                {this.state.alertText}
                            </Alert>
                        </div>
                        <div className="col-md-3 col-xs-3"/>
                    </div>
                    }
                    <div id="submission" className="tab-pane fade active in">
                        <div className="maincencl" style={{minHeight:'660px'}}>
                            <div className="quesAndIcon filterBlockss bordern">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center" style={{marginTop : '15px'}}>
                                        {subjects}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ProgressIndicator show={this.state.loading}/>
                </div>
            </div>
        );
    }

    showIndicator() {
        this.setState({
            ...this.state,
            loading: true
        });
    }

    hideIndicator() {
        this.setState({
            ...this.state,
            loading: false
        });
    }

    getAllSubjects() {
        let context = this;
        context.showIndicator();
        let sName = this.props.auth.user.schoolName ? this.props.auth.user.schoolName : 'trial';

        let params = {
            schoolName: sName,
            ownerName: this.props.auth.user.userName,
        };
        let pathTemplate = '/subjects/ownerName/{ownerName}/schoolName/{schoolName}';
        let method = 'GET';
        let additionalParams = {};
        let body = {};
        apiClient.invokeApi(params, pathTemplate, method, additionalParams, body).then(function (result) {
            context.setState({
                ...context.state,
                subjects: result.data,
                loading: false
            });
        }).catch(function (error) {
            context.hideIndicator();
        });
    }
}

function mapStateToProps(state) {
    return {
        ...state
    }
}

export default connect(mapStateToProps)(StudentPractice);
