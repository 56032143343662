import AWS from 'aws-sdk';
import {
    CognitoUserPool,
    AuthenticationDetails,
    CognitoUser
} from 'amazon-cognito-identity-js';
import config from '../../../config.js'


export function changePassword(oldPassword, newPassword, callback) {
  let userPool = new CognitoUserPool({
      UserPoolId: config.userPoolId,
      ClientId: config.clientId
  });
  let cognitoUser = userPool.getCurrentUser();
  
  cognitoUser.getSession(function(err, session) {
      if (session) {
        console.log('session validity: ' + session.isValid());
      }
  });
  cognitoUser.changePassword(oldPassword, newPassword, function (err, res) {
      
      if (err) {
          callback(err);
      } else {
          callback(null,"SUCCESS");
      }
  });


}
