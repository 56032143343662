import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import './style.css';
import $ from 'jquery';
import { Button, Modal } from 'react-bootstrap';


// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';

// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

// Require Font Awesome.
import 'font-awesome/css/font-awesome.css';

import FroalaEditor from 'react-froala-wysiwyg';
import FroalaEditorImg from 'react-froala-wysiwyg/FroalaEditorImg';

import { s3Upload } from '../activities/uploader/s3-uploader';
import ProgressIndicator from "../progress-indicator/progress-indicator";
import CustomIndicator from "../myindicator/indicator"


// import MouseDown from "/assets/mouseInteraction.js"
// require('../');

// import '/public/assets/equation-editor-master/src/app/js/mouseInteraction.js'

// import '../../../assets/equation-editor-master/src/app/js/mouseInteraction.js'

// recieves: a callback method for sending data

function dataURLtoFile(dataurl, filename) {
  let arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export default class EditorComponent extends Component {

  constructor(props) {
    super(props);
    // recieved params from outer world.
    this.params = this.props.params;
    this.updatedHTMLContent = this.props.HTMLContent;
    this.editorId = this.props.editorId;
    this.selectedDialog = this.props.selectedDialog

    // initialize state variables.
    this.state = {
      model: '',
      html: null,
      editor: null,
      formulaText: null,
      showMathEquationDialogBox: false,
      loading: false,
      load: false,
      init: true
    };

    // methods binding
    this.applyFormula = this.applyFormula.bind(this);
    this.fMathEditorLoaded = this.fMathEditorLoaded.bind(this);
    this.loader = this.loader.bind(this);
    this.handleModelChange = this.handleModelChange.bind(this);
    this.canvasCallBack = this.canvasCallBack.bind(this);
    this.showIndicator = this.showIndicator.bind(this);
    this.hideIndicator = this.hideIndicator.bind(this);
    // this.loadFrame = this.loadFrame.bind(this);

    this.addExtraButtons();
  }

  addExtraButtons() {

    // adding buttons
    $.FroalaEditor.DefineIcon(this.editorId + 'insertMath', { NAME: 'maxcdn' });
    $.FroalaEditor.RegisterCommand(this.editorId + 'insertMath', {
      title: 'insert Math',
      focus: true,
      undo: true,
      refreshAfterCallback: true,
      callback: function () {
        this.state.formulaText = null;

        this.setState({ showMathEquationDialogBox: true, load: true });
      }.bind(this)
    });

    $.FroalaEditor.DefineIcon(this.editorId + 'imageInfo', { NAME: 'pencil-square' });
    $.FroalaEditor.RegisterCommand(this.editorId + 'imageInfo', {
      title: 'Modify',
      focus: false,
      undo: false,
      refreshAfterCallback: false,
      callback: function () {
        var $img = this.state.editor.image.get();

        if ($img && $img.attr('alt')) {
          this.setState({ formulaText: $img.attr('alt'), showMathEquationDialogBox: true });
        }

      }.bind(this)
    });

    $.FroalaEditor.DefineIcon(this.editorId + 'addImage', { NAME: 'image' });
    $.FroalaEditor.RegisterCommand(this.editorId + 'addImage', {
      title: 'Insert Image',
      focus: true,
      undo: true,
      refreshAfterCallback: true,
      callback: function () {


        var inputElement = document.createElement("input");
        inputElement.type = "file";
        inputElement.accept = "image/*"; // Note Edge does not support this attribute
        inputElement.multiple = false;
        inputElement.dispatchEvent(new MouseEvent("click"));
        inputElement.addEventListener("change", this.onImageOpen.bind(this, inputElement));

      }.bind(this)
    });

    //$.FroalaEditor.DefineIcon(this.editorId + 'addAudio', { NAME: 'file-audio-o' });
    $.FroalaEditor.DefineIcon(this.editorId + 'addAudio', { NAME: 'volume-up' });
    $.FroalaEditor.RegisterCommand(this.editorId + 'addAudio', {
      title: 'Insert Audio',
      focus: true,
      undo: true,
      refreshAfterCallback: true,
      callback: function () {


        var inputElement = document.createElement("input");
        inputElement.type = "file";
        inputElement.accept = "audio/*"; // Note Edge does not support this attribute
        inputElement.multiple = false;
        inputElement.dispatchEvent(new MouseEvent("click"));
        inputElement.addEventListener("change", this.onAudioOpen.bind(this, inputElement));

      }.bind(this)
    });

    //$.FroalaEditor.DefineIcon(this.editorId + 'addVideo', { NAME: 'file-video-o' });
    $.FroalaEditor.DefineIcon(this.editorId + 'addVideo', { NAME: 'video-camera' });
    $.FroalaEditor.RegisterCommand(this.editorId + 'addVideo', {
      title: 'Insert Video',
      focus: true,
      undo: true,
      refreshAfterCallback: true,
      callback: function () {


        var inputElement = document.createElement("input");
        inputElement.type = "file";
        inputElement.accept = "video/*"; // Note Edge does not support this attribute
        inputElement.multiple = false;
        inputElement.dispatchEvent(new MouseEvent("click"));
        inputElement.addEventListener("change", this.onVideoOpen.bind(this, inputElement));

      }.bind(this)
    });


    //$.FroalaEditor.DefineIcon(this.editorId + 'addVideo', { NAME: 'file-video-o' });
    $.FroalaEditor.DefineIcon(this.editorId + 'addIFrame', { NAME: 'file-code-o' });
    $.FroalaEditor.RegisterCommand(this.editorId + 'addIFrame', {
      title: 'Insert IFrame',
      focus: true,
      undo: true,
      refreshAfterCallback: true,
      callback: function () {

        let videoHtml = `
        <div id="IFRAME_DIV" style="maxheight: 300px; height: 300px; vertical-align: top; overflow: auto; border-style: solid; border-width: 1px; display: inline-block; width: 95%; margin: 2px; padding: 0px 2px " >
        <p></p>
        </div>&nbsp;`;
        this.state.editor.html.insert(videoHtml, false);
      }.bind(this)
    });
  }

  async onVideoOpen(inputElement) {
    this.setState({ ...this.state, loading: true });

    const uploadedFilename = (inputElement.files[0])
      ? (await s3Upload(inputElement.files[0], "froalaUploads")).Location
      : null;


    if (uploadedFilename) {
      let videoHtml = "&nbsp;<span class='fr-video fr-dvb fr-draggable' contenteditable='false' draggable='true'><video id='k' controls autoplay='false' preload='none' controlsList='nodownload' class='fr-draggable' src='" + uploadedFilename + "' /></span>&nbsp;";
      this.state.editor.html.insert(videoHtml, false);
    }

    this.setState({ ...this.state, loading: false });

  }

  async onAudioOpen(inputElement) {
    this.setState({ ...this.state, loading: true });

    const uploadedFilename = (inputElement.files[0])
      ? (await s3Upload(inputElement.files[0], "froalaUploads")).Location
      : null;


    if (uploadedFilename) {
      let audioHtml = "<span class='fr-video fr-dvb fr-draggable' contenteditable='false' draggable='true'><audio controls autoplay='false' preload='none' controlsList='nodownload' class='fr-draggable fr-fvc fr-dvi' src='" + uploadedFilename + "' /></span>";
      this.state.editor.html.insert(audioHtml, false);
    }
    this.setState({ ...this.state, loading: false });

  }

  async onImageOpen(inputElement) {

    this.setState({ ...this.state, loading: true });

    const uploadedFilename = (inputElement.files[0])
      ? (await s3Upload(inputElement.files[0], "froalaUploads")).Location
      : null;


    if (uploadedFilename) {


      let imageHtml = "<img class='fr-fic fr-draggable fr-dii' src='" + uploadedFilename + "' /> " + "<br />";
      this.state.editor.html.insert(imageHtml, false);
    }

    this.setState({ ...this.state, loading: false });

  }

  canvasCallBack(imageData) {
    // ;
    let formula = "";
    // var parentWidth = document.getElementById('editorIFrame').contentWindow.document.getElementById('helloworld123').style.width
    // var parentHeight = document.getElementById('editorIFrame').contentWindow.document.getElementById('helloworld123').style.height
    // console.log(parentWidth, parentHeight)
    // var parent = $('.fr-view')
    // console.log(parent)
    // document.getElementById('editorIFrame').contentWindow.document.getElementById('helloworld123').style.transform = "scale(0.5)"
    var formulaHtml = "<img class='fr-f,ic fr-draggable fr-dii' style='vertical-align: middle;' src='" + imageData + "' alt='" + "MathML (base64):" + window.btoa(formula) + "'/>";
    console.log(formulaHtml)
    console.log(formulaHtml.parentNode)
    this.state.editor.html.insert(formulaHtml, false);

    this.handleModelChange(this.state.editor.html.get());
    this.setState({ showMathEquationDialogBox: false, load: false })

  }

  mysync() {
    var a = document.getElementById('editorIFrame').contentWindow.document.getElementById('toJSON')
    a.click();
  }

  applyFormula() {

    // console.log(window)
    // console.log(document.getElementById('editorIFrame').contentWindow)
    // document.getElementById('editorIFrame').contentWindow.mykey()
    // var a = document.getElementById('editorIFrame').contentWindow.document.getElementById('toJSON')
    // a.click();
    // step 1: get mathml from the fMathEditor
    //   var formulaWindow = document.getElementById('editorIFrame').contentWindow;
    //   var imageData = formulaWindow.getImage();//document.getElementById('renderedEq');
    //   var formula = formulaWindow.getFormula();
    //   var image;
    //   html2canvas(imageData).then(canvas => {

    //     imageData = canvas.toDataURL();
    //     var formulaHtml = "<img class='fr-fic fr-draggable fr-dii' style='vertical-align: middle' src='" + imageData + "' alt='" + "MathML (base64):" + window.btoa(formula) + "'/>";
    //     this.state.editor.html.insert(formulaHtml, false);
    // });


    //////////////////////////////////////////////////////////////////
    // window.mykey = function () {
    //   document.getElementById("taimoor").disabled = false
    //   document.getElementById("try").style.display = "none";
    // };


    //   ;
    //   let imageData = canvasa.toDataURL();
    let formula = "";
    //   var formulaHtml = "<img class='fr-fic fr-draggable fr-dii' style='vertical-align: middle' src='" + imageData + "' alt='" + "MathML (base64):" + window.btoa(formula) + "'/>";
    //   this.state.editor.html.insert(formulaHtml, false);

    var formulaWindow = document.getElementById('editorIFrame').contentWindow;
    // formulaWindow.my();
    var imageData = formulaWindow.getImage(this.canvasCallBack);


    //////////////////////////////////////////////////




    // onrendered: function (canvas) {
    //   canvas.id = "hello world"
    //   return canvas.toDataURL();
    // }


    // var formulaHtml = "<img class='fr-fic fr-draggable fr-dii' style='vertical-align: middle' src='" + imageData + "' alt='" + "MathML (base64):" + window.btoa(formula) + "'/>";
    // this.state.editor.html.insert(formulaHtml, false);
    // var tmpNode = document.createElement( "div" );
    // tmpNode.appendChild( mathml.cloneNode( true ) );
    // var str = tmpNode.innerHTML;

    //document.getElementById('editorIFrame').contentWindow.document.getElementById('ContentImage')
    // var child = mathml.children[0]
    // // step 2: get the Equation image.
    // var img;
    // //document.getElementById('editorIFrame').contentWindow.getBlobOrUrl(function (result) {
    // img = child;
    // //});
    // // $("#myContents").append(canvas);
    // // step 3: create an image with source and alternative text => which will be used to decode and modify the formula.
    //var formulaHtml = "<img class='fr-fic fr-draggable fr-dii' style='vertical-align: middle' src='" + img + "' alt='" + "MathML (base64):" + window.btoa(mathml) + "'/>";

    // var formulaHtml = '<div id="ContentImage" style="margin: 20px; border: solid 1px #000">&nbsp;' + child + '</div>'
    // step 4: remove the existing image, Froala would start showing the cursor.
    // if (this.state.formulaText != null || this.state.formulaText != undefined) {
    //   var $img = this.state.editor.image.get();
    //   this.state.editor.image.remove($img);
    // }

    // step 5: add the image in the editor on the cursor position.

    // this.state.editor.html.insert(str, false);
    // this.handleModelChange(this.state.editor.html.get());
    // this.setState({ showMathEquationDialogBox: false }) // update the state.
  }

  loader() {
    console.log("aya")
    this.setState({ load: false })
  }

  // loadFrame() {
  //   var iframe = document.getElementById("editorIFrame");
  //   // document.getElementById('indi').style.display = "none"
  //   iframe.src = "../../../assets/equation-editor-master/src/app/html/EquationEditor.html"
  //   setTimeout(() => {
  //     document.getElementById('indi').style.display = "none"
  //   }, 1000);
  //   // document.getElementById('indi').style.display = "none"
  //};


  fMathEditorLoaded() {
    // if (!this.state.my) {
    //   document.getElementById('indi').style.display = "none"
    // }
    // this.setState({ load: false })

    // if (this.state.init) {
    //   setTimeout(this.loadFrame, 2000)
    // }
    // this.setState({ init: false })


    // this.setState({ load: false })
    // this.loadFrame()
    // window.onload = setTimeout(loadFrame, 10000);
    console.log("loaded");
    //
    // document.getElementById("taimoor").disabled = true
    // window.mykey = () => {

    //   var formulaWindow = document.getElementById('editorIFrame').contentWindow;
    //   // formulaWindow.my();
    //   var imageData = formulaWindow.getImage(this.canvasCallBack);

    //   // document.getElementById("taimoor").disabled = false
    //   // document.getElementById("try").style.display = "none";
    // };
    // let button = document.getElementById('editorIFrame').contentWindow.document.getElementById('toJSON');
    // // let input = document.getElementById('editorIFrame').contentWindow.document.getElementById('toJSON').setAttribute('class', 'onclass')
    // let value = document.getElementById('editorIFrame').contentWindow.document.getElementById('toJSON').className;
    // // let button = document.getElementById('editorIFrame').contentWindow.document.getElementById('toJSON');
    // button.addEventListener('click', () => {
    //   emitter.emit('event:name-changed', { name: value });
    // });
    // let emitter = new EventEmitter();
    // emitter.subscribe('event:name-changed', data => {
    //   if (data == 'onclass') {
    //     document.getElementById("taimoor").disabled = false
    //   }
    // });
    // console.log(document.getElementsByClassName("onclass"))
    // var x = document.getElementById('editorIFrame').contentWindow.document.getElementById('toJSON').className
    // console.log(x)
    // document.getElementById("taimoor").disabled = true

    // if there's no formula text extracted from the image, don't try updateing the formula in the editor.
    if (this.state.formulaText == null || this.state.formulaText == undefined) {
      // setTimeout(this.loadFrame, 100)

      //document.getElementById('editorIFrame').contentWindow.setMathML(`<math><mglyph style="border:1px;" width="0.66em" height="1em"></mglyph></math>`);

      return;
    }

    var mathmlEnc = this.state.formulaText;
    if (mathmlEnc != null && mathmlEnc.length > 16) {
      mathmlEnc = mathmlEnc.substring(16, mathmlEnc.length);

      var mathml = window.atob(mathmlEnc);
      if (mathml.indexOf("<math") == 0) {
        // update the formula in the FMath editor.
        document.getElementById('editorIFrame').contentWindow.setMathML(mathml);
      }
    }
  }

  openFMathDialog() {
    return (
      <Modal id={"math-editor"} className='widthFixer' style={{ zIndex: 3000 }}
        show={this.state.showMathEquationDialogBox}
        onHide={() => {
          this.setState({ showMathEquationDialogBox: false });
        }}
        container={this}
        aria-labelledby="new-question-modal-title">

        <Modal.Header closeButton>
          <Modal.Title id="new-question-modal-title">Math Editor</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <CustomIndicator show={this.state.load} />
          {<iframe id="editorIFrame" onLoad={this.fMathEditorLoaded} onLoadCapture={this.loader} style={{ width: '100%', /*height: '65vh'*/ }} className="heightFixer" src={"https://login.learnerpal.com/matheditor/src/app/html/EquationEditor.html"}></iframe>}

        </Modal.Body>

        <Modal.Footer>
          <Button id="taimoor" onClick={() => {
            this.setState({ showMathEquationDialogBox: false });
          }}>Cancel</Button>
          <Button onClick={() => {
            this.setState({ load: true });
            this.applyFormula()
          }} bsStyle='primary' id="taimoor">Insert</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  handleModelChange(htmlData) {
    this.updatedHTMLContent = htmlData;
    this.params.updateContent.apply(this, [htmlData]);
  }

  updateHTML(html) {
    this.state.editor.html.set(html);
  }

  render() {

    // if (this.state.showMathEquationDialogBox) {

    //   // var a = document.getElementById('editorIFrame')
    //   // a.onload = setTimeout(this.loadFrame, 3000)
    // }

    let zIndex = 5;
    if (this.props.isModal) {

      zIndex = 2001;
    }

    let placeholderText = this.props.placeholderText;
    if (!placeholderText)
      placeholderText = " ";
    let fontSize = [];

    for (let count = 8; count <= 36; count++) {
      fontSize.push(count);
    }


    // $('#editor .modal-dialog #math-editor .modal-dialog .modal-content').froalaEditor({
    //   imageResize: false
    // })
    //key: '7F4C3C3F3cA5A4B3A1E4B2G2E3C1B2vDIG1QCYRWa1GPId1f1I1==',

    var config = {
      key: 'rB1C1I2B2B3D7E7nD5D4B3J4C3D7C7C2A4A4aQZMb1NJGG1HTMVANU==',
      heightMin: 200,
      imageMove: true,
      iframe: true,
      // imageResize: true,
      // imageSizeButtons: ['imageBack'],
      theme: 'gray',
      zIndex: zIndex,
      immediateReactModelUpdate: true,
      placeholderText: placeholderText,
      toolbarButtons: [
        'fullscreen', 'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', '|', 'fontFamily', 'fontSize', 'color',
        'inlineStyle', 'paragraphStyle', '|', 'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', 'quote', '-', 'insertLink',
        'embedly', 'insertTable', '|', 'emoticons', 'specialCharacters', 'insertHR', 'selectAll',
        'clearFormatting', '|', 'print', 'spellChecker', 'help', 'html', '|', 'undo', 'redo',

        this.editorId + 'insertMath', this.editorId + 'addImage', this.editorId + 'addAudio', this.editorId + 'addVideo', this.editorId + 'addIFrame'
      ],
      // htmlAllowedTags:['a', 'abbr', 'address', 'area', 'article', 'aside', 'audio', 'b', 'base', 'bdi', 'bdo', 'blockquote', 'br', 'button', 'canvas', 'caption', 'cite', 'code', 'col', 'colgroup', 'datalist', 'dd', 'del', 'details', 'dfn', 'dialog', 'div', 'dl', 'dt', 'em', 'embed', 'fieldset', 'figcaption', 'figure', 'footer', 'form', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'header', 'hgroup', 'hr', 'i', 'iframe', 'img', 'input', 'ins', 'kbd', 'keygen', 'label', 'legend', 'li', 'link', 'main', 'map', 'mark', 'menu', 'menuitem', 'meter', 'nav', 'noscript', 'object', 'ol', 'optgroup', 'option', 'output', 'p', 'param', 'pre', 'progress', 'queue', 'rp', 'rt', 'ruby', 's', 'samp', 'script', 'style', 'section', 'select', 'small', 'source', 'span', 'strike', 'strong', 'sub', 'summary', 'sup', 'table', 'tbody', 'td', 'textarea', 'tfoot', 'th', 'thead', 'time', 'title', 'tr', 'track', 'u', 'ul', 'var', 'video', 'wbr'],
      htmlAllowedAttrs: ['accept', 'accept-charset', 'accesskey', 'action', 'align', 'allowfullscreen', 'allowtransparency', 'alt', 'async', 'autocomplete', 'autofocus', 'autosave', 'background', 'bgcolor', 'border', 'charset', 'cellpadding', 'cellspacing', 'checked', 'cite', 'class', 'color', 'cols', 'colspan', 'content', 'contenteditable', 'contextmenu', 'controls', 'coords', 'data', 'data-.*', 'datetime', 'default', 'defer', 'dir', 'dirname', 'disabled', 'download', 'draggable', 'dropzone', 'enctype', 'for', 'form', 'formaction', 'frameborder', 'headers', 'height', 'hidden', 'high', 'href', 'hreflang', 'http-equiv', 'icon', 'id', 'ismap', 'itemprop', 'keytype', 'kind', 'label', 'lang', 'language', 'list', 'loop', 'low', 'max', 'maxlength', 'media', 'method', 'min', 'mozallowfullscreen', 'multiple', 'muted', 'name', 'novalidate', 'open', 'optimum', 'pattern', 'ping', 'placeholder', 'playsinline', 'poster', 'preload', 'pubdate', 'radiogroup', 'readonly', 'rel', 'required', 'reversed', 'rows', 'rowspan', 'sandbox', 'scope', 'scoped', 'scrolling', 'seamless', 'selected', 'shape', 'size', 'sizes', 'span', 'src', 'srcdoc', 'srclang', 'srcset', 'start', 'step', 'summary', 'spellcheck', 'style', 'tabindex', 'target', 'title', 'type', 'translate', 'usemap', 'value', 'valign', 'webkitallowfullscreen', 'width', 'wrap'],
      imageEditButtons: ['imageDisplay', 'imageAlign', this.editorId + 'imageInfo', 'imageRemove'],
      videoEditButtons: ['videoRemove', '|', 'videoDisplay', 'videoAlign', 'videoSize'],

      fontSize: fontSize,
      events: {
        'froalaEditor.initialized': function (e, editor) {

          this.state.editor = editor;
        }.bind(this),

        'froalaEditor.image.beforePasteUpload': async function (e, editor, img) {
          this.showIndicator();
          let fileToUpload = dataURLtoFile(img.src, 'image');
          let filename = (await s3Upload(fileToUpload, "clipboard-image")).Location
          editor.image.insert(filename, false, null, editor.image.get(), img);
          this.hideIndicator();
          return false

        }.bind(this),
      },
      enter: $.FroalaEditor.ENTER_BR,
      toolbarSticky: false
    };

    if (this.props.editorHeight)
      config.height = this.props.editorHeight;

    if (this.props.editorHeightMin)
      config.minHeight = this.props.editorHeightMin;

    if (this.props.editorHeightMax)
      config.heightMax = this.props.editorHeightMax;

    if (this.props.selectedDialog) {
      //
      // config.imageEditButtons = []
      config.imageResize = false
      // config.imageOutputSize = true
    }
    // console.log(config.imageEditButtons)
    return (
      <div>
        {this.openFMathDialog()}
        <FroalaEditor tag="editor" config={config} model={this.updatedHTMLContent} onModelChange={this.handleModelChange} />

        <ProgressIndicator show={this.state.loading} />
      </div>
    );
  }

  showIndicator() {
    this.setState({
      ...this.state,
      loading: true
    });
  }

  hideIndicator() {
    this.setState({
      ...this.state,
      loading: false
    });

  }
}
