import Validator from 'validator';
import isEmpty from 'lodash/isEmpty';

export default function validateInput(data) {
  let errors = {};

  if (Validator.isEmpty(data.username.trim())) {
    errors.username = 'This field is required';
  }

  if (data.username.trim().indexOf(" ") > -1) {
    errors.username = 'Username cannot contain spaces';
  }

  if (Validator.isEmpty(data.password.trim())) {
    errors.password = 'This field is required';
  }

  return {
    errors,
    isValid: isEmpty(errors)
  }
}
