/**
 * Created by ashahab on 5/31/2017.
 */
import React from 'react';
import LPlogo from '../../assets/images/logo2.png'

export const Footer = () =>(
            <div className="footer text-center">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-4 text-alignlft">
                            <p>
                                Powered by LearnerPal
                            </p>
                            <img src={LPlogo} alt="-" className="logoClass" height='25px' style={{marginBottom: '4px', marginInlineStart: '5px'}}/>

                        </div>
                        <div className="col-md-4 text-center">
                            <p>Copyright &copy; 2017 LearnerPal</p>
                        </div>
                        <div className="col-md-4">
                            <ul>
                                <li><a href="javascript:;">Terms of Use</a></li>
                                <li><a href="javascript:;">Privacy Policy</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
);