import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';


const SelectFieldGroup = ({dropDownId, isDisabled,field, options, value, label, error, onChange, placeholder, toolTip, multiSelect, selectWidth, isColored, makeLabelBold, toolTipStyleForCreateAssignmentOnly, title}) => {
    return (
        <div className={classnames("form-group showAble", {'has-error': error})}>
            {label && <label className={classnames("control-label", {makeLabelBold})}>{label}</label>}
          <div className={classnames("posRel", {"selectWidth" : selectWidth}, {"fieldBg": isDisabled})}>
            <select
                id={dropDownId}
                style={{color:isColored?'blue':'black'}}
                disabled={isDisabled}
                className="form-control"
                name={field}
                onChange={onChange}
                title={title}
                value={value ? value : placeholder}>
                    {placeholder && <option disabled>{placeholder}</option>}
                    {options && options.map(function (a) {
                        if (a.categoriesCourses) {
                            if (a.disabled) {
                                if (a.id == "---")
                                    return (<option disabled key={a.id+Math.random()} value={a.id} style={a.style}> &nbsp;&nbsp; {a.name}</option>);
                                else
                                    return (<option disabled key={a.id} value={a.id} style={a.style}>{a.name}</option>);
                            } else {
                                return (<option key={a.id} value={a.id}> &nbsp;&nbsp; {a.name}</option>);
                            }
                        } else {
                            return (<option key={a.id} value={a.id}>{a.name}</option>);
                        }
                    })}
            </select>
            {multiSelect && <div className="appendDiv">{multiSelect}</div>}
            </div>
            {toolTip && <a className={classnames("Activity-toolTip toolTipCus", {'toolTipStyleForCreateAssignmentOnly': toolTipStyleForCreateAssignmentOnly})}>
          <span className="tooltiptext">
              {toolTip}
          </span>
            </a>}
            {error && selectWidth && <div><label className="control-label"></label><div className={classnames("posRel ", {"selectWidth" : selectWidth})}><span className="help-block posRel">{error}</span></div></div>}
            {error && !selectWidth && <div><div className={classnames("posRel ", {"selectWidth" : selectWidth})}><span className="help-block posRel">{error}</span></div></div>}
        </div>
    )
}

SelectFieldGroup.propTypes = {
    isDisabled: PropTypes.bool,
    field: PropTypes.string.isRequired,
    value: PropTypes.string,
    options: PropTypes.array.isRequired,
    label: PropTypes.string,
    error: PropTypes.string,
    placholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    multiSelect: PropTypes.array,
    selectWidth: PropTypes.bool,
    title: PropTypes.string
}

SelectFieldGroup.defaultProps = {
    selectWidth: true
}

export default SelectFieldGroup;
