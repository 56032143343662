/**
 * Created by ashahab on 8/12/2017.
 */

import  {SET_ES_ATTRIBUTES} from '../actions/types';

export default function elasticSearchAttributes(state = {
    map: new Map()}, action) {
    switch (action.type) {
        case SET_ES_ATTRIBUTES:
            let map = state.map;
            map.set(action.location, {
                searchTerm: action.searchTerm,
                pageSize: action.pageSize,
                activePage: action.activePage,
                sortObj:action.sortObj,
                filterValues:action.filterValues,
                dateRange:action.dateRange
            });
            return {
                map:map
            };
        default:
            return state;
    }
};
