/**
 * Created by ashahab on 8/22/2017.
 */

import React, {Component} from "react";
import {connect} from "react-redux";
import {browserHistory} from "react-router";
import ClevertapReact from "clevertap-react";

class WidgetPractices extends Component {

    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
        this.headinText = props.headinText;
    }

    getSubjectClass(name)
    {
        if (name.toLowerCase().indexOf('geometry') > -1) {
            return 'subGeometry';
        }
        else if (name.toLowerCase().indexOf('algebra') > -1) {
            return 'subAlgebra';
        } else if (name.toLowerCase().indexOf('earth science') > -1) {
            return 'subErthSci';
        } else if (name.toLowerCase().indexOf('global history') > -1) {
            return 'subGlobHistory';
        } else if (name.toLowerCase().indexOf('environment') > -1) {
            return 'subLivEnvi';
        } else {
            return 'subUSHistory';
        }
    }

    onClick(event)
    {
        if (event.target.name === 'course')
        {
            ClevertapReact.event("Started Practice Question from "+event.target.id, {'userName' : this.props.auth.user.userName});
            browserHistory.push({
                pathname: '/student/selfstudy/course/' + encodeURIComponent(event.target.id)
            });
        }
        else  if (event.target.name === 'question')
        {
            ClevertapReact.event("Started Practice Questions from "+event.target.id, {'userName' : this.props.auth.user.userName});
            browserHistory.push({
                pathname: '/student/selfstudy/view/' + encodeURIComponent(event.target.id) + '[[question]]'
            });
        }
        else
        {
            ClevertapReact.event("Started Practice Flashcards from "+event.target.id, {'userName' : this.props.auth.user.userName});
            browserHistory.push({
                pathname: '/student/selfstudy/view/' + encodeURIComponent(event.target.id) + '[[flashcard]]'
            });
        }
    }

    render() {
        
        let allSubjectsHtml=[];
        let allSubjects = this.props.subjects;
        allSubjects = allSubjects.sort((a,b) => {
            return a.name.localeCompare(b.name);
        });
        if (allSubjects && allSubjects.length > 0)
        {
            for (let i=0; i < allSubjects.length; i++ )
            {
                let subject = allSubjects[i];

                console.log('debug : subject -> ',JSON.parse(JSON.stringify(subject)));
                

                allSubjectsHtml.push(
                    <div key={subject.id}>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12  ">
                            <div className="icondes">
                                <div className="limitFilters">
                                    <span className={this.getSubjectClass(subject.name)}/>
                                </div>
                            </div>
                            <div className="icondesheadin">
                                <a className="boardhead" href="#">{subject.name}</a>
                                <div className="lghtblfg" style={{textAlign: 'left',  marginLeft: '-4px', marginBottom: '-3px'}}>
                                    {/*<a style={{color:'#43a8ee', cursor: 'pointer'}} id={subject.name} name ="course" onClick={this.onClick}>Course | </a>*/}
                                    <a style={{color:'#43a8ee', cursor: 'pointer'}} id={subject.name} name="question" onClick={this.onClick}>Questions |</a>
                                    <a style={{color:'#43a8ee', cursor: 'pointer'}} id={subject.name} name="flashcard" onClick={this.onClick}>Flash Cards</a>
                                </div>
                            </div>
                        </div>
                        {i < (allSubjects.length -1) ? <div className="brdrds"/> : <div/>}
                    </div>
                );
            }
        }

        return (

            <div  className="dasboxss col-lg-4 col-md-6 col-sm-6 col-xs-12" style={{marginBottom:'0px', paddingBottom:'0px'}}>
                <div  className="col-lg-12 col-md-12 col-sm-12 col-xs-12  pacboxdes " style={{marginBottom:'0px' }}>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12  ">
                        <div className="icondes">
                            <div className="limitFiltersas minnine">
                                <span className="typeIconPrac"/>
                            </div>
                        </div>
                        <div className="icondeshead">
                            <a className="boardheadblf"href="#">{this.headinText}</a>
                        </div>
                    </div>
                    < div  className="brdrds"/>
                    <div className="dashboardWidgetContainer" style={{height: '100%', maxHeight: '525px'}}>
                        {allSubjectsHtml}
                    </div>
                </div>
            </div>
        );
    }
}


function mapStateToProps(state = {}) {
    return {...state};
}

export default connect(mapStateToProps)(WidgetPractices);
