import React, { Component } from "react";
import "./progress-indicator.css";
export default class ProgressIndicator extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let indicator = this.props.show ? <div>
            <div className="modal-backdrop fade in"></div>
            <div className="sk-fading-circle">
                <div className="sk-circle1 sk-circle"></div>
                <div className="sk-circle2 sk-circle"></div>
                <div className="sk-circle3 sk-circle"></div>
                <div className="sk-circle4 sk-circle"></div>
                <div className="sk-circle5 sk-circle"></div>
                <div className="sk-circle6 sk-circle"></div>
                <div className="sk-circle7 sk-circle"></div>
                <div className="sk-circle8 sk-circle"></div>
                <div className="sk-circle9 sk-circle"></div>
                <div className="sk-circle10 sk-circle"></div>
                <div className="sk-circle11 sk-circle"></div>
                <div className="sk-circle12 sk-circle"></div>

            </div></div> : null;
        return (indicator);
    }
}
