import React, {Component} from 'react';
import TableColumn from './table-data-column';
export default class TableRow extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let record = this.props.record;
        let itemClass = this.props.rowClass;
        let columns = this.props.columns;
        let rowClassName = record['className'] ? record['className'] : itemClass;
        let showViewFunc = this.props.rowClickAction ? this.props.rowClickAction : function () {};
        let cols = [];
        let count = 0;
        columns.forEach(col => {
            cols.push(<TableColumn record={record} column={col} key={'column-' + col.name + '-' + count}/>);
            count++;
        });

        // attaching actions.
        if (this.props.showActionMenu) {
            let actions = this.props.actionMenuActions;
            let actionsDom = [];
            let actionCount = 0;
            actions.forEach(actionItem => {
                let linkAction = actionItem.action;
                if (actionItem.isConditional) {
                    if (typeof actionItem.conditionExpression === 'function' && actionItem.conditionExpression(record)) {
                        // condition successful.
                        actionsDom.push(<li key={'column-action-menu-' + count + '-' + actionCount}><a
                            href="javascript:void 0" onClick={evt=> {
                            if (typeof linkAction === 'function') {
                                linkAction(record, evt);
                            }
                        }}>{actionItem.linkLabel}</a></li>);

                    }
                } else {
                    // not a conditional. just go with label and action.
                    actionsDom.push(<li key={'column-action-menu-' + count + '-' + actionCount}><a
                        href="javascript:void 0" onClick={evt=> {
                        if (typeof linkAction === 'function') {
                            linkAction(record, evt);
                        }
                    }}>{actionItem.linkLabel}</a></li>);
                }
                actionCount++;
            });

            let actionMenu = <td key={'column-action-menu' + '-' + count} width="9%">
                <div className="DropDivBlock">
                    <span className="bullets"/>
                    <span className="bullets"/>
                    <span className="bullets"/>
                    <div className="dropBoxDiv">
                        <ul>
                            {actionsDom.length === 0 ? <li><a>No Actions</a></li> : actionsDom}
                        </ul>
                    </div>
                </div>
            </td>;
            cols.push(actionMenu);
        } else if (this.props.actionButtons){
            let actions = this.props.actionButtons ? this.props.actionButtons:[];
            let actionsDom = [];
            let actionCount = 0;
            actions.forEach(actionItem => {
                let linkAction = actionItem.action;
                if (actionItem.isConditional) {
                    if (typeof actionItem.conditionExpression === 'function' && actionItem.conditionExpression(record)) {
                        actionsDom.push(<a id={'column-action-menu-' + count + '-' + actionCount}
                                href="javascript:void 0" style={{'border': '1px solid', 'border-radius': '3px', 'padding': '5px'}}
                                onClick={evt=> {
                                if (typeof linkAction === 'function') {
                                    linkAction(record, evt);
                                }}}>{actionItem.linkLabel}
                                </a>);
                    } else {
                        actionsDom.push(<a id={'column-action-menu-' + count + '-' + actionCount}
                                href="javascript:void 0" style={{'border': '1px solid grey', 'border-radius': '3px', 'padding': '5px', 'color': 'grey', 'cursor': 'not-allowed'}}>
                                Add</a>);
                    }

                } else {
                  actionsDom.push(<a id={'column-action-menu-' + count + '-' + actionCount}
                          href="javascript:void 0" style={{'border': '1px solid', 'border-radius': '3px', 'padding': '5px'}}
                          onClick={evt=> {
                          if (typeof linkAction === 'function') {
                              linkAction(record, evt);
                          }}}>{actionItem.linkLabel}
                          </a>);
                }

                actionCount++;
            });

            let actionMenu = <td key={'column-action-menu' + '-' + count} width="9%">
                <div>{actionsDom.length === 0 ? <li><a>No Actions</a></li> : actionsDom}</div>
            </td>;
            cols.push(actionMenu);
        }

        let tr = <tr data-parent-id={this.props.parentRecordId?this.props.parentRecordId:''} className={rowClassName !== '' ? rowClassName : ''} onClick={()=>{showViewFunc(record, this.event)}} id={this.props.id}>{cols}</tr>;
        return (tr);
    }
}
