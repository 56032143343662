import React, { Component } from "react";
import { connect } from "react-redux";
import $ from "jquery";
import classnames from "classnames";
import ElasticSearchQuery from "../../actions/elastic-search-query";
import config from "../../config"
import ElasticSearchActions from "../../actions/elastic-search-actions";
// import axios from "axios";
import { detect } from 'detect-browser';
import apiClient from '../../actions/api-client';

class Filter extends Component {

    constructor(props) {
        super(props);
        this.filterChangedHandler = this.filterChangedHandler.bind(this);
        this.filterChangedHandlerAgg = this.filterChangedHandlerAgg.bind(this);
        this.getDataSet = this.getDataSet.bind(this);
        this.filterRecords = this.filterRecords.bind(this);
        this.clearAll = this.clearAll.bind(this);
        this.getFilters = this.getFilters.bind(this);

        this.dataPath = props.dataPath;
        this.filterColumns = props.filterColumns;
        this.clearFilters = props.clearFilters;
        this.clearFiltersForSearch = props.clearFiltersForSearch;
        this.refreshFilters = props.refreshFilters;
        this.showLoader = props.showLoader;
        this.hideLoader = props.hideLoader;
        this.loaderCount = 0;
        this.boxStyle = props.boxStyle;
        this.appliedFilters = [];
        this.allColumnsForElasticSearch = props.allColumnsForElasticSearch;
        this.actionName = props.actionName;
        this.filterAggAction = props.filterAggAction;
        this.state = { allFilters: [] };
    }

    componentDidMount() {
       if(this.props.setInstance){
        this.props.setInstance(this);
       }

        //         if (this.props.appliedFilters && this.props.appliedFilters.length>0)
        //         {
        //
        //             this.getFilters(this.props.appliedFilters);
        //         }
        //         else
        //         {
        this.getFilters([]);
        // }
    }

    showLoaderHandler() {
        if (this.showLoader) {
            this.loaderCount++;
            this.showLoader()
        }
    }

    hideLoaderHandler() {
        if (this.hideLoader) {
            if (--this.loaderCount <= 0) {
                this.hideLoader()
            }
        }
    }

    filterChangedHandlerAgg(evt) {

        let val = evt.target.value;
        if (evt.target.checked) {
            let indexNum = this.appliedFilters.findIndex(x => x.terms[evt.target.id.split("-")[0]]);
            if (indexNum !== -1) {
                this.appliedFilters[indexNum].terms[evt.target.id.split("-")[0]].push(val);
            }
            else {
                let asd = [];
                asd.push(val);
                this.appliedFilters.push({ terms: { [evt.target.id.split("-")[0]]: asd } })
            }
        }
        else {
            let index = this.appliedFilters.findIndex(x => x.terms[evt.target.id.split("-")[0]]);
            if (index !== -1) {

                if (this.appliedFilters[index].terms[evt.target.id.split("-")[0]].length > 1) {
                    let index2 = this.appliedFilters[index].terms[evt.target.id.split("-")[0]].findIndex(x => x === val);
                    this.appliedFilters[index].terms[evt.target.id.split("-")[0]].splice(index2, 1);
                }
                else {
                    this.appliedFilters.splice(index, 1);
                }

                this.filterColumns.forEach(filterColumn => {
                    if (filterColumn.name && filterColumn.name === evt.target.id.split("-")[2]) {
                        this.appliedFilters.forEach(fltr => {
                            let keys = Object.keys(fltr.terms);
                            for (let i = 0; i < keys.length; i++) {
                                if (keys[i] === filterColumn.name) {
                                    this.filterChangedHandler({ "target": { "id": filterColumn.name + "-1-" + filterColumn.dependency, "checked": false, "value": fltr.terms[keys[i]] } })
                                }
                            }
                        })
                    }
                });
            }
        }
        // let axiosInstance = axios.create({
        //     baseURL: 'https://search-learnerpal-eimkio3umgx5fnpuy7ypsrrsri.us-east-1.es.amazonaws.com/learnerpal/',
        //     timeout: 60000,
        //     headers: {'Content-Type': 'application/json'},
        //     responseType: 'json',
        //     transformRequest: [function (data) {
        //         return data;
        //     }],
        //     transformResponse: {function (data) {
        //         return data;
        //     }}
        // });
        let visibility = '(public OR "' + this.props.auth.user.userName + '"';
        if (this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName === "LearnerPal") {
            visibility += " OR LPAdmin";
        }
        if ((this.props.auth.user.linkedAccounts && this.props.auth.user.linkedAccounts.learnerPal) && this.props.auth.user.federatedIdentity) {
            visibility += ` OR "${this.props.auth.user.linkedAccounts.learnerPal}"`
        }
        visibility += ')';
        let query = "visibleTo : " + visibility;
        if (this.props.auth.user.schoolName === "LearnerPal")
            query = query + " OR questionState2: (\"In Review\") OR questionState2: (\"Shared\") "

        let data =
        {
            query: { bool: { must: { query_string: { query: query } }, filter: { bool: { must: this.appliedFilters } } } },
            aggs: {
                subjects: {
                    terms: { field: "subjects", size: 100 },
                    aggs: { "category": { terms: { field: "category", size: 100 } } }
                }
            }
        };

        const { dispatch } = this.props;
        let that = this;
        apiClient.invokeApi({}, '/getesdata', 'POST', {}, { payload: data, index: 'learnerpal/', esDocumentType: "glossarydev" }, '5').then(function (result) {

            dispatch({
                type: that.filterAggAction,
                data: result.data.aggregations.subjects.buckets,
            });
        }).catch(function (error) {

        });

        // axiosInstance({
        //     method: 'post',
        //     url: '/glossarydev/_search',
        //     data: JSON.stringify(data)
        // }).then(response=>
        //     {
        //         dispatch({
        //             type: that.filterAggAction,
        //             data: response.data.aggregations.subjects.buckets,
        //         });
        //     }
        // ).catch();



        // elasticSearchQuery.getFilters(this.props.screenNameForElasticSearch + (this.props.isOverRideStage? config.stage:stage), this.props.allColumnsForElasticSearch,
        //     this.props, esAttr.searchTerm, allFilters, this.appliedFilters, function (results) {
        //
        //         dispatch({
        //             type: that.filterAggAction,
        //             status: 200,
        //             data: JSON.parse(JSON.stringify(results.data[0].allValues)),
        //             alertText: '',
        //             alertStyle: '',
        //             showAlert: false,
        //         });
        //     }, false, '');
        //
        // let dependency = evt.target.id.split("-");
        // if (dependency[2])
        // {
        //     this.setState({replaceFilter:dependency[2]});
        //
        //     if (this.filterColumns[this.filterColumns.findIndex(x=> x.name === dependency[0])].refreshFilter) {
        //         this.setState({replaceFilter2:dependency[0]});
        //     }
        //     this.getFilters(this.appliedFilters);
        // }
    }

    filterChangedHandler(evt) {
        let val = evt.target.value;
        if (evt.target.checked) {
            let indexNum = this.appliedFilters.findIndex(x => x.terms[evt.target.id.split("-")[0]]);
            if (indexNum !== -1) {
                this.appliedFilters[indexNum].terms[evt.target.id.split("-")[0]].push(val);
            }
            else {
                let asd = [];
                asd.push(val);
                this.appliedFilters.push({ terms: { [evt.target.id.split("-")[0]]: asd } })
            }
        }
        else {
            let index = this.appliedFilters.findIndex(x => x.terms[evt.target.id.split("-")[0]]);
            if (index !== -1) {

                if (this.appliedFilters[index].terms[evt.target.id.split("-")[0]].length > 1) {
                    let index2 = this.appliedFilters[index].terms[evt.target.id.split("-")[0]].findIndex(x => x === val);
                    this.appliedFilters[index].terms[evt.target.id.split("-")[0]].splice(index2, 1);
                }
                else {
                    this.appliedFilters.splice(index, 1);
                }

                this.filterColumns.forEach(filterColumn => {
                    if (filterColumn.name && filterColumn.name === evt.target.id.split("-")[2]) {
                        this.appliedFilters.forEach(fltr => {

                            let keys = Object.keys(fltr.terms);
                            for (let i = 0; i < keys.length; i++) {
                                if (keys[i] === filterColumn.name) {

                                    let len = fltr.terms[keys[i]].length;
                                    for (let j = 0; j < len; j++) {
                                        this.filterChangedHandler({ "target": { "id": filterColumn.name + "-1-" + filterColumn.dependency, "checked": false, "value": fltr.terms[keys[i]][j] } })
                                    }
                                }
                            }
                        })
                    }
                });
            }
        }
        this.filterRecords(this.appliedFilters);

        let dependency = evt.target.id.split("-");
        if (dependency[2]) {
            this.setState({ replaceFilter: dependency[2] });

            if (this.filterColumns[this.filterColumns.findIndex(x => x.name === dependency[0])].refreshFilter) {
                this.setState({ replaceFilter2: dependency[0] });
            }
            this.getFilters(this.appliedFilters);
        }
    }

    clearAll(dontRefresh) {
        let count = 0;
        this.filterColumns.forEach(colObject => {
            let filters = [...document.querySelectorAll("[id=" + colObject.name + "-section] div.fieldBox input[type=checkbox]")];
            for (let i = 0; i < filters.length; i++) {
                filters[i].checked = false;
            }
            count++;
        });
        this.appliedFilters = [];
        if (!dontRefresh) {
            this.filterRecords([]);
            this.getFilters([]);
        }
    }

    seeMore(evt) {
        $(evt.target).parent().children('.limitFilter').toggleClass('seeAll', 1000);
        if ($(evt.target).parent().children('.limitFilter').hasClass('seeAll')) {
            $(evt.target).html('See Less');
        }
        else {
            $(evt.target).html('See More');
        }
    }

    getDataSet(path) {
        // console.log(path);
        var levels = path.split(".");
        var records = this.props[levels[0]];
        for (let i = 1; i < levels.length; i++) {
            records = records[levels[i]];
        }
        if (records && records.length > 0) {
            return records;
        } else {
            return [];
        }
    }

    getFilterBatches() {
        let batchList = [];
        for (let columnCount = 0; columnCount < this.filterColumns.length; columnCount++) {
            let currentColumn = this.filterColumns[columnCount];
            let currentTypeFilters = Array.from(this.appliedFilters).filter((filter) => {
                return JSON.parse(filter).fieldName === currentColumn.name;
            });
            if (currentTypeFilters.length > 0) {
                batchList.push(currentTypeFilters);
            }
        }
        return batchList;
    }

    matchAFilterBatch(filterBatch, currentRecord) {
        let result = 0;
        let context = this;
        for (let currentBatchFilterCount = 0; currentBatchFilterCount < filterBatch.length; currentBatchFilterCount++) {
            let currentFilter = JSON.parse(filterBatch[currentBatchFilterCount]);

            if (currentFilter.fieldType === 'array') {
                let field = currentRecord[currentFilter.fieldName];
                if (field) {
                    field.forEach(item => {
                        if (item.toLowerCase() === currentFilter.filterValue.toLowerCase()) {
                            result = 1;
                        }
                    });
                }
            } else if (currentFilter.fieldType === 'text') {
                let recordData = context.getAttr(currentRecord, currentFilter.fieldName);
                if (recordData && recordData.toLowerCase() === currentFilter.filterValue.toLowerCase()) {
                    result = 1;
                }
            }
        }
        return result;
    }

    filterRecords(filters) {
        this.showLoaderHandler();

        let { dispatch, onlyPublishedQuestion, appendAdditionalQuery } = this.props;
        let stage = this.props.auth.user.userType.toLowerCase() === 'student' ? '' : config.stage;
        let esAttr = this.props.elasticSearchAttributes.map.get(this.props.location);
        let elasticSearchQuery = new ElasticSearchQuery();
        let that = this;
        let appendQuery = this.props.manageActivities.createActivityActiveOwnerFilter === 'LearnerPal' && onlyPublishedQuestion ? `questionState2: "Published"` : '';
        appendQuery += appendAdditionalQuery ? appendAdditionalQuery : '';
        ElasticSearchActions.setElasticSearchAttributes(dispatch, this.props.location, esAttr.searchTerm, esAttr.pageSize, esAttr.activePage, esAttr.sortObj, filters, esAttr.dateRange);
        let isQuery = this.props.isQuery;

        let restoreFilters = sessionStorage.getItem("restoreFilter");
        if (restoreFilters) {

            this.getFilters();
            this.clearAll();
        }
        if (this.props.customQuery) {
            elasticSearchQuery.query(this.props.screenNameForElasticSearch + (this.props.isOverRideStage ? config.stage : stage), this.allColumnsForElasticSearch, this.props,
                esAttr.searchTerm, esAttr.pageSize, 1, esAttr.sortObj, filters, function (results, totalRecords) {

                    dispatch({
                        type: that.actionName,
                        status: 200,
                        data: JSON.parse(JSON.stringify(results)),
                        alertText: '',
                        alertStyle: '',
                        showAlert: false,
                        totalRecords: totalRecords
                    });
                    that.hideLoaderHandler();
                }, isQuery, this.props.customQuery, this.props.customURL, null, appendQuery);
        }
        else {
            elasticSearchQuery.query(this.props.screenNameForElasticSearch + (this.props.isOverRideStage ? config.stage : stage), this.allColumnsForElasticSearch, this.props,
                esAttr.searchTerm, esAttr.pageSize, 1, esAttr.sortObj, filters, function (results, totalRecords) {

                    dispatch({
                        type: that.actionName,
                        status: 200,
                        data: JSON.parse(JSON.stringify(results)),
                        alertText: '',
                        alertStyle: '',
                        showAlert: false,
                        totalRecords: totalRecords
                    });
                    that.hideLoaderHandler();
                }, isQuery, null, this.props.customURL, null, appendQuery);
        }
    }

    ucFirst(text) {
        return text.charAt(0).toUpperCase() + text.substr(1);
    }

    getAttr(record, key) {
        let levels = key.split(".");
        let value = record;
        levels.forEach(level => {
            if (value) {
                value = value[level];
            }
        });
        return value;
    }

    getFilters(dependentFilters = []) {
        sessionStorage.removeItem("restoreFilter");
        this.showLoaderHandler();

        let allFilters = {};
        for (let a = 0; a < this.filterColumns.length; a++) {
            if (!this.filterColumns.dependency) {
                allFilters[this.filterColumns[a].name] = { terms: { field: this.filterColumns[a].name, size: 100 } }
            }
        }
        let stage = this.props.auth.user.userType.toLowerCase() === 'student' ? '' : config.stage;
        let { dispatch, onlyPublishedQuestion, appendAdditionalQuery } = this.props;
        let elasticSearchQuery = new ElasticSearchQuery();
        let that = this;
        let appendQuery = this.props.manageActivities && (this.props.manageActivities.createActivityActiveOwnerFilter == null || this.props.manageActivities.createActivityActiveOwnerFilter === 'LearnerPal') && onlyPublishedQuestion ? `questionState2: "Published"` : '';
        // let appendQuery = this.props.manageActivities.createActivityActiveOwnerFilter === 'LearnerPal' && onlyPublishedQuestion ? ` AND questionState2: "Published"` : '';
        appendQuery += appendAdditionalQuery ? appendAdditionalQuery : '';
        let esAttr = this.props.elasticSearchAttributes.map.get(this.props.location);
        if (dependentFilters.length > 0) {
            let isQuery = this.props.isQuery;
            if (this.props.customQuery) {
                elasticSearchQuery.getFilters(this.props.screenNameForElasticSearch + (this.props.isOverRideStage ? config.stage : stage), this.props.allColumnsForElasticSearch,
                    this.props, esAttr.searchTerm, allFilters, dependentFilters, function (results) {
                        let response = results.data;
                        response.forEach(filters => {
                            let index = that.filterColumns.findIndex(x => x.name === filters.label);
                            if (that.filterColumns[index].sort && that.filterColumns[index].sort === 'desc') {
                                // filters.allValues.sort(function (a,b) {
                                //     return b.name.localeCompare(a.name)
                                // })

                                that.sortList(filters.allValues, true)

                            }
                            else {
                                // filters.allValues.sort(function (a,b) {
                                //     return a.name.localeCompare(b.name)
                                // })

                                that.sortList(filters.allValues, true)
                            }
                        });
                        allFilters = that.state.allFilters;


                        let index = allFilters.findIndex(x => x.label === that.state.replaceFilter);
                        let index2 = response.findIndex(y => y.label === that.state.replaceFilter);
                        let depIndex = dependentFilters.findIndex(y => y.term && y.term.field === that.state.replaceFilter2)
                        if (depIndex > -1) {
                            allFilters.splice(index, 1);
                        } else {
                            allFilters[index] = response[index2];
                        }

                        if (that.state.replaceFilter2) {
                            index = allFilters.findIndex(x => x.label === that.state.replaceFilter2);
                            index2 = response.findIndex(y => y.label === that.state.replaceFilter2);
                            allFilters[index] = response[index2];
                        }

                        that.filterColumns.forEach(col => {
                            if (col.dependency) {
                                let depIndex = that.filterColumns.findIndex(z => z.name === col.dependency);
                                that.filterColumns[depIndex].dependent = true;

                                dependentFilters.forEach(fltr => {

                                    let keys = Object.keys(fltr.terms);
                                    if (keys[0] === col.name) {
                                        let dep = that.filterColumns[that.filterColumns.findIndex(z => z.name === keys[0])].dependency;
                                        let index3 = that.filterColumns.findIndex(z => z.name === dep);
                                        that.filterColumns[index3].dependent = false;
                                    }
                                })
                            }
                        });

                        that.setState({ allFilters: allFilters });
                        that.hideLoaderHandler();
                    }, isQuery, this.props.customQuery, this.props.customURL, appendQuery);
            }
            else {
                elasticSearchQuery.getFilters(this.props.screenNameForElasticSearch + (this.props.isOverRideStage ? config.stage : stage), this.props.allColumnsForElasticSearch,
                    this.props, esAttr.searchTerm, allFilters, dependentFilters, function (results) {

                        let response = results.data;
                        response.forEach(filters => {
                            let index = that.filterColumns.findIndex(x => x.name === filters.label);
                            if (that.filterColumns[index].sort && that.filterColumns[index].sort === 'desc') {
                                // filters.allValues.sort(function (a,b) {
                                //     return b.name.localeCompare(a.name)
                                // })

                                that.sortList(filters.allValues, true)

                            }
                            else {
                                // filters.allValues.sort(function (a,b) {
                                //     return a.name.localeCompare(b.name)
                                // })

                                that.sortList(filters.allValues, true)
                            }
                        });
                        allFilters = that.state.allFilters;


                        let index = allFilters.findIndex(x => x.label === that.state.replaceFilter);
                        let index2 = response.findIndex(y => y.label === that.state.replaceFilter);
                        allFilters[index] = response[index2];

                        if (that.state.replaceFilter2) {
                            index = allFilters.findIndex(x => x.label === that.state.replaceFilter2);
                            index2 = response.findIndex(y => y.label === that.state.replaceFilter2);
                            allFilters[index] = response[index2];
                        }

                        that.filterColumns.forEach(col => {
                            if (col.dependency) {
                                let depIndex = that.filterColumns.findIndex(z => z.name === col.dependency);
                                that.filterColumns[depIndex].dependent = true;

                                dependentFilters.forEach(fltr => {

                                    let keys = Object.keys(fltr.terms);
                                    if (keys[0] === col.name) {
                                        let dep = that.filterColumns[that.filterColumns.findIndex(z => z.name === keys[0])].dependency;
                                        let index3 = that.filterColumns.findIndex(z => z.name === dep);
                                        that.filterColumns[index3].dependent = false;
                                    }
                                })
                            }
                        });

                        that.setState({ allFilters: allFilters });
                        that.hideLoaderHandler();
                    }, isQuery, null, this.props.customURL, appendQuery);
            }
        }
        else {
            let isQuery = this.props.isQuery;
            if (this.props.customQuery) {
                elasticSearchQuery.getFilters(this.props.screenNameForElasticSearch + (this.props.isOverRideStage ? config.stage : stage), this.props.allColumnsForElasticSearch,
                    this.props, '', allFilters, [], function (results) {

                        let allFitlers = [];
                        for (let y = 0; y < that.filterColumns.length; y++) {
                            let tempIndex = results.data.findIndex(x => x.label === that.filterColumns[y].name);
                            allFitlers.push(results.data[tempIndex]);
                        }

                        allFitlers.forEach(filters => {
                            let index = that.filterColumns.findIndex(x => x.name === filters.label);
                            if (that.filterColumns[index].sort && that.filterColumns[index].sort === 'desc') {
                                // filters.allValues.sort(function (a,b) {
                                //     return b.name.localeCompare(a.name)
                                // })

                                that.sortList(filters.allValues, true)

                            }
                            else {
                                // filters.allValues.sort(function (a,b) {
                                //     return a.name.localeCompare(b.name)
                                // })

                                that.sortList(filters.allValues, true)
                            }
                        });

                        that.filterColumns.forEach(col => {
                            if (col.dependency) {
                                let depIndex = that.filterColumns.findIndex(z => z.name === col.dependency);
                                that.filterColumns[depIndex].dependent = true;

                                dependentFilters.forEach(fltr => {

                                    let keys = Object.keys(fltr.terms);
                                    if (keys[0] === col.name) {
                                        let dep = that.filterColumns[that.filterColumns.findIndex(z => z.name === keys[0])].dependency;
                                        let index3 = that.filterColumns.findIndex(z => z.name === dep);
                                        that.filterColumns[index3].dependent = false;
                                    }
                                })
                            }
                        });

                        that.setState({ allFilters: allFitlers });
                        that.hideLoaderHandler();
                    }, isQuery, this.props.customQuery, this.props.customURL, appendQuery);
            }
            else {
                elasticSearchQuery.getFilters(this.props.screenNameForElasticSearch + (this.props.isOverRideStage ? config.stage : stage), this.props.allColumnsForElasticSearch,
                    this.props, '', allFilters, [], function (results) {

                        let allFitlers = [];
                        for (let y = 0; y < that.filterColumns.length; y++) {
                            let tempIndex = results.data.findIndex(x => x.label === that.filterColumns[y].name);
                            allFitlers.push(results.data[tempIndex]);
                        }

                        allFitlers.forEach(filters => {
                            let index = that.filterColumns.findIndex(x => x.name === filters.label);
                            if (that.filterColumns[index].sort && that.filterColumns[index].sort === 'desc') {
                                // filters.allValues.sort(function (a,b) {
                                //     return b.name.localeCompare(a.name)
                                // })

                                that.sortList(filters.allValues, true)

                            }
                            else {
                                // filters.allValues.sort(function (a,b) {
                                //     return a.name.localeCompare(b.name)
                                // })

                                that.sortList(filters.allValues, true)
                            }
                        });

                        that.filterColumns.forEach(col => {
                            if (col.dependency) {
                                let depIndex = that.filterColumns.findIndex(z => z.name === col.dependency);
                                that.filterColumns[depIndex].dependent = true;

                                dependentFilters.forEach(fltr => {

                                    let keys = Object.keys(fltr.terms);
                                    if (keys[0] === col.name) {
                                        let dep = that.filterColumns[that.filterColumns.findIndex(z => z.name === keys[0])].dependency;
                                        let index3 = that.filterColumns.findIndex(z => z.name === dep);
                                        that.filterColumns[index3].dependent = false;
                                    }
                                })
                            }
                        });

                        that.setState({ allFilters: allFitlers });
                        that.hideLoaderHandler();
                    }, isQuery, null, this.props.customURL, appendQuery);
            }
        }

    }

    render() {
        const browser = detect();
        if (this.clearFilters) {
            var clrFltr = this.clearFilters.split(".");
            if (this.props[clrFltr[0]][clrFltr[1]]) {
                this.props[clrFltr[0]][clrFltr[1]] = false;
                if (this.clearFiltersForSearch) {
                    var clrFltrForSearch = this.clearFiltersForSearch.split(".");
                    if (this.props[clrFltrForSearch[0]][clrFltrForSearch[1]]) {
                        this.props[clrFltrForSearch[0]][clrFltrForSearch[1]] = false;
                        this.clearAll(true);
                    } else {
                        this.clearAll();
                    }
                }
            }
        }
        if (this.refreshFilters) {
            var clrFltr = this.refreshFilters.split(".");
            if (this.props[clrFltr[0]][clrFltr[1]]) {
                this.props[clrFltr[0]][clrFltr[1]] = false;
                this.filterRecords(this.appliedFilters);
            }
        }
        let filterSections = [];
        let filterSectionCount = 0;
        let context = this;

        this.state.allFilters.forEach(filterColumn => {
            let filterValues = [];
            let checkedFilters = new Set();

            this.appliedFilters.forEach(fltr => {
                let keys = Object.keys(fltr.terms);
                for (let i = 0; i < keys.length; i++) {
                    for (let k = 0; k < fltr.terms[keys[i]].length; k++) {
                        checkedFilters.add(fltr.terms[keys[i]][k]);
                    }
                }
            });
            filterColumn.allValues.forEach(value => {
                filterValues.push(value.name);
            });
            let index = context.filterColumns.findIndex(x => x.name === filterColumn.label);

            let filterValueDOMForColumn = [];
            let seeMoreVisible = "";
            let valCount = 0;
            if (!context.filterColumns[index].dependent) {
                filterValues.forEach(filterVal => {
                    if (filterVal.toLowerCase() === 'pdf') {
                        filterVal = 'PDF';
                    }
                    else if (filterVal.toLowerCase() === 'url') {
                        filterVal = 'URL';
                    }
                    else if (filterVal.toLowerCase() === 'video') {
                        filterVal = 'Video';
                    }
                    let id = '';
                    if (context.filterColumns[index].dependency) {
                        id = filterColumn.label + '-' + valCount + '-' + context.filterColumns[index].dependency;
                    }
                    else {
                        id = filterColumn.label + '-' + valCount;
                    }

                    if (checkedFilters.has(filterVal)) {
                        filterValueDOMForColumn.push(<div className="fieldBox" key={filterVal + '-' + valCount}>
                            <input type="checkbox" value={filterVal} id={id}
                                className="css-checkbox"
                                name={filterVal}
                                onChange={this.props.filterAgg ? this.filterChangedHandlerAgg : this.filterChangedHandler}
                                checked="true" />
                            <label htmlFor={id}
                                className="pay_label css-label1">{filterColumn.label == "questionType" ? filterVal : this.ucFirst(filterVal)}</label>
                        </div>);
                        seeMoreVisible += filterColumn.label == "questionType" ? filterVal : this.ucFirst(filterVal);
                    } else {
                        filterValueDOMForColumn.push(<div className="fieldBox" key={filterVal + '-' + valCount}>
                            <input type="checkbox" value={filterVal} id={id}
                                className="css-checkbox"
                                name={filterVal}
                                onChange={this.props.filterAgg ? this.filterChangedHandlerAgg : this.filterChangedHandler} />
                            <label htmlFor={id}
                                className="pay_label css-label1">{filterColumn.label == "questionType" ? filterVal : this.ucFirst(filterVal)}</label>
                        </div>);
                        seeMoreVisible += filterColumn.label == "questionType" ? filterVal : this.ucFirst(filterVal);
                    }
                    valCount++;
                });
                if (filterValueDOMForColumn.length > 0) {
                    filterSections.push(<div id={filterColumn.label.trim() + '-section'}
                        key={filterColumn.label.trim() + '-section'}>
                        <h4>{context.filterColumns[index].label}</h4>
                        <div className="SubjectBox">
                            <div className="limitFilter">
                                {filterValueDOMForColumn}
                            </div>
                            {(filterValueDOMForColumn.length > 3 || seeMoreVisible.length > 40) &&
                                <div className="seeMore" onClick={this.seeMore}> See More</div>
                            }
                            {(filterValueDOMForColumn.length < 4 || seeMoreVisible.length > 40) &&
                                <div className="seeMore" > </div>
                            }
                        </div>
                    </div>);
                }
                filterSectionCount++;
            }
        });
        let collapseFilter = this.props.viewActivityCssClass.indexOf("collapsableDiv") > 0;
        let boxStyleClass = this.boxStyle === true ? 'filterBoxStyle' : '';
        return (
            <div className={this.props.viewActivityCssClass} id="filterInnerContainer">
                <div className="smText hidden-lg">
                    <h3>Filter</h3>
                    <span className="HideCross" onClick={function (evt) {
                        $('.filterBlock').removeClass('TopZero');
                        $('html').css({ 'overflow': 'auto', 'position': 'inherit' });
                        $('.footer ').show();
                    }.bind(this)} />
                </div>
                <div className="FunnelFilterIcon" onClick={this.props.closeViewActivity} />
                <br />
                {browser.os.toLocaleLowerCase() === 'ios' && <strong>Select the filter(s) you want to refine by and click Apply at the bottom.</strong>}
                <div className={classnames(boxStyleClass + " overFlowScroll-sm", { "removeFilterDisplay": collapseFilter })}>
                    {filterSections}
                    {filterSections.length > 0 && <a hidden="javascript:;" className="unSelAll" id="filterClearAll" onClick={this.clearAll}>Clear All</a>}
                </div>

                <button className="ApplyFilter hidden-md hidden-lg" onClick={(evt) => {
                    $('.filterBlock').removeClass('TopZero');
                    $('html').css({ 'overflow': 'auto', 'position': 'inherit' });
                    $('.footer ').show();
                }}> Apply
                </button>
            </div>
        );
    }




    sortList(sortSubject, shouldChronological) {
        sortSubject.sort(function (a, b) {
            return a.name.localeCompare(b.name, undefined, {
                numeric: true,
                sensitivity: 'base'
            });
        });

        if (shouldChronological) {

            var allMonths = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];
            var regexYear = /(19[0-9]{2}|20[0-9]{2})/;
            let chronoObj = [];
            let sortSubjectCopy = [];

            sortSubject.forEach(x => {

                let sourceId = x.name;

                let firstSpaceIndex = sourceId.indexOf(' ');
                let extractedMonth = sourceId.slice(0, firstSpaceIndex)

                if (!(extractedMonth) ? allMonths.includes(extractedMonth.toLowerCase()) : null)
                    return;

                let yearMatched = regexYear.exec(sourceId);
                if (!yearMatched)
                    return;

                let innerObj = chronoObj.find(x => x.year === yearMatched[0])
                if (!innerObj) {

                    let obj = {};

                    obj['year'] = yearMatched[0];

                    obj['months'] = [];
                    obj.months.push({
                        month: extractedMonth,
                        data: [x]
                    });
                    chronoObj.push(obj);
                } else {
                    let innerMonthsObj = innerObj.months.find(x => x.month === extractedMonth);
                    if (!innerMonthsObj) {
                        innerObj.months.push({
                            month: extractedMonth,
                            data: [x]
                        });

                    } else {
                        innerMonthsObj.data.push(x);
                    }
                }
            })


            chronoObj.sort((a, b) => {
                return a.year - b.year;
            });

            chronoObj.forEach(x => {
                x.months.sort((a, b) => {
                    return allMonths.indexOf(a.month.toLowerCase()) > allMonths.indexOf(b.month.toLowerCase());
                });
            });

            chronoObj.forEach(x => x.months.forEach(x => {
                x.data.forEach(x => {
                    sortSubjectCopy.push(x);
                })
            })
            );

            if (sortSubjectCopy.length > 0) {
                sortSubject.sort((a, b) => {

                    var valA = a;
                    var valB = b;

                    if (sortSubjectCopy.indexOf(valA) < sortSubjectCopy.indexOf(valB)) {
                        return -1;
                    }

                    if (sortSubjectCopy.indexOf(valA) > sortSubjectCopy.indexOf(valB)) {
                        return 1;
                    }

                    return 0;

                });
            }
        }
    }






}

function mapStateToProps(state) {
    return { ...state };
}

Filter.defaultProps = {
    isQuery: true,
    filterAgg: false
}

export default connect(mapStateToProps)(Filter);
