import Validator from 'validator';
import isEmpty from 'lodash/isEmpty';

export default function validateInput(data, allRecords, ownerName) {
    let errors = {};

    
    if (Validator.isEmpty(data.activityType.trim())) {
        errors.activityType = 'This field is required';
    }
    if(!data.activityID) {

        var id = data.activityName.replace(/\s|\W|[#$%^&*()'.]/g, "") + "_" + ownerName.toLowerCase();

        if (Validator.isEmpty(data.activityName.trim())) {
            errors.activityName = 'This field is required';
        } else if (allRecords.filter(function (record) {
                return (record.id.toLowerCase() === id.toLowerCase());
            }).length > 0) {
            errors.activityName = 'Activity with the same name already exists';
        }
    }
    if (data.taggedSubjects.length === 0) {
        errors.taggedSubjects = 'This field is required';
    }
    if (data.activityType === 'learningURL')
    {
        if (Validator.isEmpty(data.externalURL.trim())) {
            errors.externalURL = 'This field is required';
        }

        if (!Validator.isEmpty(data.externalURL.trim()) && !Validator.isURL(data.externalURL.trim())) {
            errors.externalURL = 'Please enter a valid URL';
        }
    }

    if (data.activityType === 'learningPDF') {

        if (Validator.isEmpty(data.fileName)) {
            errors.fileName = 'This field is required';
        }

        if (data.fileUpload && data.fileUpload.type != 'application/pdf') {
            errors.fileName = "Only PDF files are allowed";
        }

        if (data.fileUpload && data.fileUpload.size > 20971520) {
            errors.fileName = "Only files less than 20 MB are allowed";
        }
    }

    if (data.activityType === 'learningVideo'){
        if (Validator.isEmpty(data.fileName)) {
            errors.fileName = 'This field is required';
        }

        if (data.fileUpload && data.fileUpload.type != 'video/webm') {
            errors.fileName = "Only PDF files are allowed";
        }
    }
    return {
        errors,
        isValid: isEmpty(errors)
    }
}
