// import axios from 'axios';
import { SET_ES_ATTRIBUTES, RECEIVE_SEARCH_RESULTS, SEARCH_FAILED, REQUEST_SEARCH_ALL } from './types';
import apiClient from "./api-client";

// let elasticSearchPostfixForSearch = '_search';

// let axiosInstance = axios.create({
//     baseURL: 'https://search-learnerpal-eimkio3umgx5fnpuy7ypsrrsri.us-east-1.es.amazonaws.com/learnerpal/',
//     timeout: 60000,
//     headers: { 'Content-Type': 'application/json' },
//     responseType: 'json',
//     // `transformRequest` allows changes to the request data before it is sent to the server
//     // This is only applicable for request methods 'PUT', 'POST', and 'PATCH'
//     // The last function in the array must return a string or an instance of Buffer, ArrayBuffer,
//     // FormData or Stream
//     transformRequest: [function (data) {
//         // Do whatever you want to transform the data

//         return data;
//     }],

//     // `transformResponse` allows changes to the response data to be made before
//     // it is passed to then/catch
//     transformResponse: [function (data) {
//         // Do whatever you want to transform the data

//         var results = [];
//         if (data.hits && data.hits.hits.length > 0) {
//             results = data.hits.hits.map(function (record) {
//                 // do something with each record.
//                 return record._source;
//             });
//         }
//         return results;
//     }]
// });

let handleSuccess = (dispatch, response) => {

    dispatch({
        type: RECEIVE_SEARCH_RESULTS,
        status: 200,
        data: JSON.parse(JSON.stringify(response))
    })
};

let handleError = (dispatch, error) => {

    dispatch({
        type: SEARCH_FAILED,
        status: 400,
        data: error
    })
};

export default class ElasticSearchActions {
    static query(dispatch, type = 'activity', options = {
        'match': {
            'visibleTo': 'public'
        }
    }, columns = [
        'id', 'type', 'name', 'ownerName', 'createdAt', 'subjects', 'documentLink', 'activityType', 'keywords'
    ]) {
        // notify component that data loading is in progress.
        dispatch({
            type: REQUEST_SEARCH_ALL,
            options
        });

        let data = {
            query: options
        };
        if (columns && columns.length > 0) {
            data['_source'] = {
                'includes': columns
            }
        }

        apiClient.invokeApi({}, '/getesdata', 'POST', {}, { payload: data, index: 'learnerpal/', esDocumentType: type }, '5').then(function (result) {

            let response = {};
            response.results = [];
            if (result.data.hits && result.data.hits.hits.length > 0) {
                for (var a = 0; a < result.data.hits.hits.length; a++) {
                    response.results.push(result.data.hits.hits[a]._source);
                }
            }
            response.totalRecords = result.data.hits ? result.data.hits.total : 0;

            console.log(response);

            handleSuccess(dispatch, response.results)
        }).catch(function (error) {

            console.error(error);
            handleError(dispatch, error)
        });


        // axiosInstance({
        //     method: 'post',
        //     url: '/' + type + '/' + elasticSearchPostfixForSearch,
        //     data: JSON.stringify(data)
        // }).then(data => (handleSuccess(dispatch, data)), error => (handleError(dispatch, error)));
    }

    static getRecord(dispatch, type = 'activity', id = 'act108', columns = ['id', 'type', 'name', 'ownerName', 'createdAt', 'subjects', 'documentLink', 'activityType', 'keywords']) {
        let data = {};
        if (columns && columns.length > 0) {
            data['_source'] = {
                'includes': columns
            }
        }
        if (columns && columns.length > 0) {
            data['_source'] = {
                'includes': columns
            }
        }

        apiClient.invokeApi({}, '/getesdata', 'POST', {}, { id: "/" + id, index: 'learnerpal/', esDocumentType: type }, '5').then(function (result) {
            let response = {};
            response.results = [];
            if (result.data.hits && result.data.hits.hits.length > 0) {
                for (var a = 0; a < result.data.hits.hits.length; a++) {
                    response.results.push(result.data.hits.hits[a]._source);
                }
            }
            response.totalRecords = result.data.hits ? result.data.hits.total : 0;
            console.log(response);
            handleSuccess(dispatch, response.results)
        }).catch(function (error) {
            console.error(error);
            handleError(dispatch, error)

        });

        // axiosInstance({
        //     method: 'get',
        //     url: '/' + type + '/' + id
        // }).then(data => (handleSuccess(dispatch, data)), error => (handleError(dispatch, error)));
    }

    static setElasticSearchAttributes(dispatch, location, searchTerm, pageSize, activePage, sortObj, filterValues, dateRange = null) {

        dispatch({
            type: SET_ES_ATTRIBUTES,
            location: location,
            searchTerm: searchTerm,
            pageSize: pageSize,
            activePage: activePage,
            sortObj: sortObj,
            filterValues: filterValues,
            dateRange: dateRange,
        });
    }
}
