import React, { Component } from "react";
import Heading from "../heading/heading";
import Filter from "../filter/filter";
import { connect } from "react-redux";
import ElasticSearchActions from "../../actions/elastic-search-actions";
import MaintenanceTable from "../maintenance-table/maintenance-table";
import AssignmentMaintenanceTableMobile from "../assignment-maintenance-table-mobile/assignment-maintenance-table-mobile";
import apiClient from "../../actions/api-client";
import { RECEIVED_ASSIGNMENT_RESULTS, ElasticSearchTypes, ElasticSearchColumns } from "../../actions/types";
import $ from "jquery";
import { Alert, Modal, Button } from "react-bootstrap";
import ProgressIndicator from "../progress-indicator/progress-indicator";
import config from "../../config";
import classnames from "classnames";
import ElasticSearchQuery from "../../actions/elastic-search-query";
import ReactScrollPagination from "react-scroll-pagination";
import "./report.css";
import { browserHistory } from "react-router";
import MaterialIcon from "react-google-material-icons";
import {
    parseHours,
    parseMinutes,
    parseSeconds
} from '../../utils/timeParser';

class TeacherReport extends React.Component {
    constructor(props) {
        super(props);
        this.getAssignmentStudentStats = this.getAssignmentStudentStats.bind(this);
        this.getViewProgressDialog = this.getViewProgressDialog.bind(this);
        this.releaseReport = this.releaseReport.bind(this);

        this.state = { screenWidth: window.innerWidth, subjects: [], viewProgressDialog: false };
        this.filterComponentCssClassName = 'filterBlock NiceScroll';
        this.tableCssClassName = 'listViewBlock ';
    }

    componentDidMount() {
        const { dispatch } = this.props;
        let context = this;
        ElasticSearchActions.setElasticSearchAttributes(dispatch, 'assignments', '', parseInt($('#pageSizeDropDown').val()) ? parseInt($('#pageSizeDropDown').val()) : 10, 1, { createdAt: { order: "desc" } }, []);
        let elasticSearchQuery = new ElasticSearchQuery();
        elasticSearchQuery.query(ElasticSearchTypes.Assignment + config.stage, ElasticSearchColumns.Assignment, this.props,
            '', parseInt($('#pageSizeDropDown').val()) ? parseInt($('#pageSizeDropDown').val()) : 10, 1, { createdAt: { order: "desc" } }, [], function (results, totalRecords) {

                let ids = results.map(ent => { return ent.id });
                context.getAssignmentStudentStats(ids).then(function (stats) {
                    for (let i = 0; i < stats.data.length; i++) {
                        for (let j = 0; j < results.length; j++) {
                            if (stats.data[i].assignmentID === results[j].id) {
                                results[j].studentsLength = stats.data[i].studentsLength | 0;
                                results[j].passStudents = stats.data[i].passStudents | 0;
                                results[j].failStudents = stats.data[i].failStudents | 0;
                                results[j].incompleteStudents = stats.data[i].incompleteStudents | 0;
                            }
                        }
                    }

                    dispatch({
                        type: RECEIVED_ASSIGNMENT_RESULTS,
                        status: 200,
                        data: JSON.parse(JSON.stringify(results)),
                        alertText: '',
                        alertStyle: '',
                        showAlert: false,
                        totalRecords: totalRecords
                    });
                    context.hideIndicator();
                }).catch(function (error) {
                    context.hideIndicator();
                });
            }, true, 'originalOwner : ("' + this.props.auth.user.userName + '")');

        let that = this;
        this.setState({
            'alertText': this.props.manageAssignments.alertText,
            'alertStyle': this.props.manageAssignments.alertStyle,
            'showAlert': this.props.manageAssignments.showAlert
        }, function () {
            setTimeout(() => {
                that.setState({ showAlert: false });
            }, 5000);
        });

        if (this.props.location.state) {
            this.setState({ ...this.props.location.state });
        }
    }

    releaseReport(record) {
        console.log('Update assignment called.');

        let assignment = record;
        let params = {
            assignmentID: assignment.id
        };
        let pathTemplate = '/assignment';
        let method = 'PUT';
        let additionalParams = { 'accessToken': localStorage.getItem('accessToken') };
        let body = assignment;
        let body2 = {};
        var rawData = assignment.assignedActivities.M;
        var userObject = {};
        for (var prop in rawData) {

            if (typeof rawData[prop].BOOL === 'boolean') {
                userObject[prop] = rawData[prop].BOOL;
            } else if (typeof rawData[prop].S === 'string') {
                userObject[prop] = rawData[prop].S;
            }
        }
        body2 = (userObject);
        if (body.assignedActivities) {
            body.assignedActivities = body2;
            body.assignedActivities.accessReport = true;
            body.assignedActivities.lockReport = false;
        }
        if (body.assignedCourses) {
            body.assignedCourses = body2;
            body.assignedCourses.accessReport = true;
        }
        body.assignmentID = params.assignmentID
        body.ownerName = body.originalOwner

        let context = this;
        context.showIndicator();
        apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, '2').then(function (result) {
            context.setState({ currentAssignment: null, showAlert: true, alertText: result.data, alertStyle: 'success' });
            setTimeout(() => {
                context.setState({ showAlert: false });
                window.location.reload(false);

            }, 1500);
            context.hideIndicator();
        }).catch(function (result) {
            console.error(result);
            context.setState({
                ...context.state,
                currentAssignment: null
            });
        });

    }
    getNextPage() {
        let context = this;
        const { screenWidth } = this.state;
        const isMobile = screenWidth <= 767;
        if (isMobile & this.state.loadingNextPage === 0) {
            let myID = Math.random() * 10000000000000000000;
            this.setState({ 'loadingNextPage': myID });
            console.log("getting more activities");
            if (this.state.loadingNextPage === myID) {
                let esAttr = this.props.elasticSearchAttributes.map.get('assignments');
                const { dispatch } = this.props;
                ElasticSearchActions.setElasticSearchAttributes(dispatch, 'assignments', esAttr.searchTerm, esAttr.pageSize + 10, esAttr.activePage, esAttr.sortObj, esAttr.filterValues);
                let elasticSearchQuery = new ElasticSearchQuery();
                elasticSearchQuery.query(ElasticSearchTypes.Assignment + config.stage, ElasticSearchColumns.Assignment, this.props,
                    esAttr.searchTerm, esAttr.pageSize + 10, esAttr.activePage, esAttr.sortObj, esAttr.filterValues, function (results, totalRecords) {

                        let ids = results.map(ent => { return ent.id });
                        context.getAssignmentStudentStats(ids).then(function (stats) {
                            for (let i = 0; i < stats.data.length; i++) {
                                for (let j = 0; j < results.length; j++) {
                                    if (stats.data[i].assignmentID === results[j].id) {
                                        results[j].studentsLength = stats.data[i].studentsLength | 0;
                                        results[j].passStudents = stats.data[i].passStudents | 0;
                                        results[j].failStudents = stats.data[i].failStudents | 0;
                                        results[j].incompleteStudents = stats.data[i].incompleteStudents | 0;
                                    }
                                }
                            }

                            dispatch({
                                type: RECEIVED_ASSIGNMENT_RESULTS,
                                status: 200,
                                data: JSON.parse(JSON.stringify(results)),
                                alertText: '',
                                alertStyle: '',
                                showAlert: false,
                                totalRecords: totalRecords
                            });
                            context.hideIndicator();
                        }).catch(function (error) {
                            context.hideIndicator();
                        });
                    }, true, 'originalOwner : ("' + this.props.auth.user.userName + '")');
                this.setState({ 'loadingNextPage': 0 });
            }
        }
    }

    viewDetails(assignment) {

        browserHistory.push({
            pathname: '/reports/view/' + encodeURIComponent(assignment.id)
        });
    }

    viewStudentProgress(assignmentID) {
        this.showIndicator();
        let params = {
            assignmentID: assignmentID,
        };
        let pathTemplate = '/assignment/progress/{assignmentID}';

        let context = this;
        apiClient.invokeApi(params, pathTemplate, 'GET', { 'accessToken': localStorage.getItem('accessToken') }, {}, '2').then(function (result) {
            result.data.students.sort(function (a, b) {
                return /* String( */(a.firstName + a.lastName)/* ) */.localeCompare((b.firstName + b.lastName));
            });
            context.setState({
                currentAssignment: result.data,
                viewProgressDialog: true
            });
            context.hideIndicator();
        }).catch(function (result) {
            context.hideIndicator();
            console.error(result);
        });
    }

    getViewProgressDialog() {
        let allStudents = this.state.currentAssignment.students;
        let allStudentsHtml = [];
        let completedCounter = 0;
        if (allStudents.length > 0) {
            allStudents.forEach(student => {

                let status = 'Not started';
                let className = 'text-danger';

                if (student.progress) {
                    if (student.progress === 100) {
                        status = 'Completed';
                        className = 'text-success';
                        completedCounter++;
                    } else if (student.progress > 0) {
                        status = "In progress";
                        className = 'text-warning';
                    } else {
                        status = "Not started";
                        className = 'text-danger';
                    }
                }
                let timer = 'N/A';
                if (student.timer) {
                    timer = parseHours(student.timer) + ':' +
                        parseMinutes(student.timer) + ':' +
                        parseSeconds(student.timer);
                }
                allStudentsHtml.push(
                    <tr>
                        <td>{student.firstName + " " + student.lastName}</td>
                        <td><span className={className}>{status}</span></td>
                        <td>{student.score ? student.score.toFixed(0) : 0}</td>
                        <td>{timer ? timer : 'N/A'}</td>
                    </tr>
                );
            });
        }
        let body =
            <div className="respScrollTable">
                <table className="table" style={{ border: '1px solid #ccc' }}>
                    <thead>
                        <tr>
                            <th><p className="limitTextRes"><b>Students</b></p></th>
                            <th><p className="limitTextRes" onClick={this.sortByProgress}><b>Progress</b><span className="caret caret-close"></span></p></th>
                            <th><p className="limitTextRes"><b>Score</b></p></th>
                            <th><p className="limitTextRes"><b>Time In</b></p></th>
                        </tr>
                    </thead>
                    <tbody>
                        {allStudentsHtml}
                    </tbody>
                </table>
            </div>;

        return <Modal
            backdrop="static"
            show={this.state.viewProgressDialog}
            onHide={() => {
                this.setState({ viewProgressDialog: false });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Assignment Progress: <strong>{(parseFloat(this.state.currentAssignment.assignmentProgress)).toFixed(0)}%</strong></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    this.setState({ viewProgressDialog: false });
                }} bsStyle='primary'>Close</Button>
            </Modal.Footer>
        </Modal>;
    }

    render() {
        let context = this;
        let tempAllResults = this.props.manageAssignments.results;
        for (let q = 0; q < tempAllResults.length; q++) {
            if (tempAllResults[q].activityType) {
                switch (tempAllResults[q].activityType.toLowerCase()) {
                    case 'learning':
                        tempAllResults[q].className = 'learning';
                        break;
                    case 'flashcards':
                    case 'flashcard':
                        tempAllResults[q].className = 'flashCard';
                        break;
                    case 'question':
                        tempAllResults[q].className = 'questionRow';
                        break;
                    case 'course':
                        tempAllResults[q].className = 'booksRow';
                        break;
                    default:
                        tempAllResults[q].className = 'learning';
                        break;
                }
            }
        }

        const { screenWidth } = this.state;
        const isMobile = screenWidth <= 767;
        let esAttr = this.props.elasticSearchAttributes.map.get('assignments');
        let maintenanceTableData = {
            emptyMessage: 'No records found.',
            showActionMenu: false,
            actions: [],
            columns: [
                {
                    name: 'assignmentName', type: 'text', label: 'Assignment Name', options: {
                        sortable: true, customDom: (record) => { // customDom is not applicable to array type attributes, ex: subjects in activity.

                            return (
                                <td width="23%">
                                    <span className="typeIcon"> </span>
                                    <span className="contentType ">
                                        <div style={{ fontStyle: 'bold', color: "#0f6fb4", 'width': '100%', 'white-space': 'nowrap', 'overflow': 'hidden', 'text-overflow': 'ellipsis' }} title={record.assignmentName}><strong>{record.assignmentName}</strong></div>
                                        {record.assignedActivities && record.assignedActivities.courseName ? <p>Course: {record.assignedActivities.courseName["S"]}, Unit: {record.assignedActivities.unitName["S"]}</p> : <p>&nbsp;</p>}
                                    </span>
                                </td>
                            );
                        }
                    }
                },
                { name: 'periodName', type: 'text', label: 'Assigned To', options: { sortable: true, width: '12%' } },
                {
                    name: 'createdAt', type: 'date', label: 'Created Date', options: {
                        sortable: true//, width: '12%'}},
                        , customDom: (record) => { // customDom is not applicable to array type attributes, ex: subjects in activity.
                            if (record.createdAt !== 'NaN') {
                                let rawDate = new Date(parseFloat(record.createdAt));
                                let dueDate = (rawDate.getUTCMonth() + 1) + '/' + rawDate.getUTCDate() + '/' + rawDate.getUTCFullYear();
                                return (
                                    <td width="10%">
                                        {dueDate}
                                    </td>
                                );
                            }
                            else {
                                return (
                                    <td width="10%">
                                        NaN
                                    </td>
                                );
                            }

                        }
                    }
                },
                {
                    name: 'dueDate', type: 'date', label: 'Due Date', options: {
                        sortable: true//, width: '12%'}},
                        , customDom: (record) => { // customDom is not applicable to array type attributes, ex: subjects in activity.
                            if (record.dueDate !== 'NaN') {
                                let rawDate = new Date(parseFloat(record.dueDate));
                                let dueDate = (rawDate.getUTCMonth() + 1) + '/' + rawDate.getUTCDate() + '/' + rawDate.getUTCFullYear();
                                return (
                                    <td width="10%">
                                        {dueDate}
                                    </td>
                                );
                            }
                            else {
                                return (
                                    <td width="10%">
                                        NaN
                                    </td>
                                );
                            }

                        }
                    }
                },
                // {name: 'studentsLength', type: 'text', label: 'Total Students', options: {sortable: true}},
                {
                    name: 'assignmentProgress', type: 'text', label: 'Progress', options: {
                        sortable: false, customDom: (record) => { // customDom is not applicable to array type attributes, ex: subjects in activity.

                            return (
                                <td width="11%">
                                    <a href="javascript:void 0" onClick={function () {
                                        this.viewStudentProgress(record.id);
                                    }.bind(this)}>
                                        {Math.round(parseInt(record.assignmentProgress)) + '%'}
                                        {/*<MaterialIcon icon="search" size={28}/>*/}
                                        {/*<img src={details}/>*/}
                                    </a>
                                </td>
                            );
                        }
                    }
                },
                {
                    name: 'passStudents', type: 'text', label: 'Passed Students', options: {
                        sortable: false, customDom: (record) => { // customDom is not applicable to array type attributes, ex: subjects in activity.

                            let percentage = ((parseInt(record.passStudents) / parseInt(record.studentsLength)) * 100).toFixed(0);
                            if (percentage.toString() === 'Infinity' || percentage.toString() === 'infinity') {
                                percentage = 0;
                            }
                            return (
                                <td width="11%">
                                    {percentage + '% (' + record.passStudents + ' out of ' + record.studentsLength + ')'}
                                </td>
                            );
                        }
                    }
                },
                {
                    name: 'failStudents', type: 'text', label: 'Failed Students', options: {
                        sortable: false, customDom: (record) => { // customDom is not applicable to array type attributes, ex: subjects in activity.

                            let percentage = ((parseInt(record.failStudents) / parseInt(record.studentsLength)) * 100).toFixed(0);
                            if (percentage.toString() === 'Infinity' || percentage.toString() === 'infinity') {
                                percentage = 0;
                            }
                            return (
                                <td width="12%">
                                    {percentage + '% (' + record.failStudents + ' out of ' + record.studentsLength + ')'}
                                </td>
                            );
                        }
                    }
                },
                // {name: 'incompleteStudents', type: 'text', label: 'Incomplete Students', options: {sortable: true}},
                {
                    name: 'id', type: 'text', label: 'View Details', options: {
                        sortable: false,
                        customDom: (record) => {
                            return (
                                <td width="6%" className="view-details">
                                    {(!isMobile && (record.originalOwner === this.props.auth.user.userName
                                        || (this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName === "LearnerPal")
                                        || record.originalOwner === this.props.auth.user.userName)) &&

                                        <a style={{ marginLeft: "5px" }} href="javascript:void 0" onClick={function () {
                                            this.viewDetails(record);
                                        }.bind(this)}>
                                            <MaterialIcon icon="search" size={32} />
                                            {/*<img src={details}/>*/}
                                        </a>
                                    }

                                </td>
                            );
                        }
                    }
                },
                {
                    name: '', type: 'text', label: '', options: {
                        sortable: false,
                        customDom: (record) => {
                            return (
                                <td width="5%" className="view-details">
                                    <a className="actionButtonclass" style={{ transform: 'scale(0.7, 0.7)', webkitTransform: 'scale(0.7, 0.7)' }}>
                                        <span style={{ left: "-525px", top: "0px", zIndex: '99' }} className="WHSign">
                                            Activities with Locked Reports hold back reporting from the students, and will require Teachers to “release reports” to their students from the Assignments tab.
                                        </span>
                                    </a>
                                    {record && record.assignedActivities && record.assignedActivities.M && (record.assignedActivities.M.lockReport) && record.assignedActivities.M.accessReport && <Button style={{ padding: "initial", fontSize: "12px", width: "85px", fontStretch: "condensed", marginLeft: "-3px" }} type="Button" onClick={() => { this.releaseReport(record) }} className="btn btn-primary" disabled={record.assignedActivities && record.assignedActivities.M && record.assignedActivities.M.accessReport && record.assignedActivities.M.accessReport.BOOL}>{record.assignedActivities && record.assignedActivities.M && record.assignedActivities.M.accessReport && record.assignedActivities.M.accessReport.BOOL ? "Released" : "Release Report"}</Button>}

                                </td>
                            );
                        }
                    }
                }
            ],
            items: this.props.manageAssignments.results,
            rowExpansion: {},
            pageable: true,
            rowClickAction: this.viewAssignmentOnRowClick,
            totalRecords: this.props.manageAssignments.totalRecords,
            pagination: {
                pageSize: esAttr && esAttr.pageSize ? esAttr.pageSize : 10,
                activePage: 1,
                showPageInput: true,
                showFirstButton: true,
                showLastButton: true,
                showNextButton: true,
                showPreviousButton: true,
                firstButtonLabel: '<<',
                lastButtonLabel: '>>',
                previousButtonLabel: '<',
                nextButtonLabel: '>',
                showPageInfo: true,
                showGotoInput: false,
                gotoInputLabel: 'Goto',
                pageLinkSize: 5
            }
        };
        return (
            <div className="container-fluid noPad listMainBoxWrapper " id="student-reports">
                <Heading
                    location="assignments"
                    actionName={RECEIVED_ASSIGNMENT_RESULTS}
                    isHideHeading={false}
                    allButtons={[]}
                    isCreateButton={true}
                    heading="Reports"
                    isShowButton={true}
                    isSearch={true}
                    screenNameForElasticSearch={ElasticSearchTypes.Assignment}
                    allColumnsForElasticSearch={ElasticSearchColumns.Assignment}
                    customQuery={'originalOwner : ("' + this.props.auth.user.userName + '")'}
                    breadcrumbsData={[
                        {
                            name: 'Reports', action: () => {
                                browserHistory.push('/reports');
                            }
                        },
                        {
                            name: 'Assignment Reports'
                        }
                    ]}
                />
                {this.state.showAlert &&
                    <div className="row">
                        <div className="col-md-3 col-xs-3" />
                        <div className="col-md-6 col-xs-3">
                            <Alert bsStyle={this.state.alertStyle}
                                onDismiss={this.handleAlertDismiss}>
                                {this.state.alertText}
                            </Alert>
                        </div>
                        <div className="col-md-3 col-xs-3" />
                    </div>
                }
                <div id="student-reports">
                    <div className={classnames("container-fluid noPad listMainBoxWrapper")}>
                        <Filter
                            customQuery={'originalOwner : ("' + this.props.auth.user.userName + '")'}
                            location="assignments"
                            viewActivityCssClass={this.filterComponentCssClassName}
                            closeViewActivity={this.closeManageAssignment}
                            clearFilters={'manageAssignments.clearFilters'}
                            actionName={RECEIVED_ASSIGNMENT_RESULTS}
                            filterColumns={[
                                { name: 'periodName', type: 'text', label: 'Class' }
                            ]}
                            screenNameForElasticSearch={ElasticSearchTypes.Assignment}
                            allColumnsForElasticSearch={ElasticSearchColumns.Assignment}
                        />
                        <div className={this.tableCssClassName} id="listViewMainTable">
                            <div className="listToBesslide NiceScroll  ">
                                <div className="blockForlist ">
                                    <MaintenanceTable
                                        customQuery={'originalOwner : ("' + this.props.auth.user.userName + '")'}
                                        location="assignments"
                                        actionName={RECEIVED_ASSIGNMENT_RESULTS}
                                        screenNameForElasticSearch={ElasticSearchTypes.Assignment}
                                        allColumnsForElasticSearch={ElasticSearchColumns.Assignment}
                                        options={maintenanceTableData}
                                        sortAction={{
                                            action: RECEIVED_ASSIGNMENT_RESULTS,
                                            attributes: {
                                                skipAlterForAllRecords: true
                                            },
                                            dataPath: 'data'
                                        }}
                                    />
                                    <AssignmentMaintenanceTableMobile items={this.props.manageAssignments.results}
                                        emptyMessage={maintenanceTableData.emptyMessage}
                                        columns={maintenanceTableData.columns}
                                        viewActivityMobile={this.openManageAssignmentPage}
                                    />
                                    <ReactScrollPagination
                                        fetchFunc={this.getNextPage}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ProgressIndicator show={this.state.loading} />
                {this.state.viewProgressDialog && this.getViewProgressDialog()}
            </div>
        );
    }

    showIndicator() {
        this.setState({
            ...this.state,
            loading: true
        });
    }

    hideIndicator() {
        this.setState({
            ...this.state,
            loading: false
        });
    }

    getAssignmentStudentStats(assignmentIds) {
        let context = this;
        context.showIndicator();

        return new Promise((resolve, reject) => {
            let params = {
                assignments: assignmentIds
            };
            let pathTemplate = '/assignment/report/{assignments}';
            let method = 'GET';
            let additionalParams = {};
            let body = {};
            apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, "3").then((result) => { resolve(result) }).catch((error) => { reject(error) });
        });
    }
}

function mapStateToProps(state) {
    return {
        ...state
    }
}

export default connect(mapStateToProps)(TeacherReport);
