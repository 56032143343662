
/**
 * Created by ashahab on 8/8/2017.
 */
/**
 * Created by ashahab on 7/21/2017.
 */
// import axios from 'axios';
import  {FETCH_ALL_SCHOOLS, RECEIVED_SCHOOLS_RESULTS, FETCH_SCHOOLS_FAILED,} from '../actions/types';
import config from '../config.js'
import apiClient from "./api-client";

let elasticSearchPostfixForSearch = '_search';

// let axiosInstance = axios.create({
//     baseURL: config.esURL,
//     timeout: 60000,
//     headers: {'Content-Type': 'application/json'},
//     responseType: 'json',
//     // `transformRequest` allows changes to the request data before it is sent to the server
//     // This is only applicable for request methods 'PUT', 'POST', and 'PATCH'
//     // The last function in the array must return a string or an instance of Buffer, ArrayBuffer,
//     // FormData or Stream
//     transformRequest: [function (data) {
//         // Do whatever you want to transform the data
//         return data;
//     }],
//
//     // `transformResponse` allows changes to the response data to be made before
//     // it is passed to then/catch
//     transformResponse: [function (data) {
//         // Do whatever you want to transform the data
//
//         let results = [];
//         if (data.hits && data.hits.hits.length > 0) {
//             results = data.hits.hits.map(function (record) {
//                 // do something with each record.
//                 return record._source;
//             });
//         }
//         return results;
//     }]
// });

let handleSuccess = (dispatch, response, alertText, alertStyle, showAlert) => {
    dispatch({
        type: RECEIVED_SCHOOLS_RESULTS,
        status: 200,
        data: JSON.parse(JSON.stringify(response.data)),
        alertText: alertText,
        alertStyle: alertStyle,
        showAlert: showAlert
    })
};

let handleError = (dispatch, error, alertText, alertStyle, showAlert) => {
    dispatch({
        type: FETCH_SCHOOLS_FAILED,
        status: 400,
        data: error,
        alertText: alertText,
        alertStyle: alertStyle,
        showAlert: showAlert
    })
};

export default class ManageSchoolsActions {
    static fetchAllSchools(dispatch, owner, type = 'school'+config.stage, options = {}, columns = [
        'state', 'licensedTeachers', 'email', 'adminName','customerSince','licenseExpiry','usedTeachers','usedStudents', 'adminCode','teacherCode','studentCode'
    ], alertText = "", alertStyle = "", showAlert = false) {
        // notify component that data loading is in progress.
        dispatch({
            type: FETCH_ALL_SCHOOLS,
            options
        });

        let data = {
            query: options,
            size:10000
        };
        if (columns && columns.length > 0) {
            data['_source'] = {
                'includes': columns
            }
        }

        apiClient.invokeApi({}, '/getesdata', 'POST', {}, { payload: data, index: 'learnerpal/', esDocumentType: type }, '5').then(function (result) {

            let response = {};
            response.results = [];
            if (result.data.hits && result.data.hits.hits.length > 0) {
                for (var a = 0; a < result.data.hits.hits.length; a++) {
                    response.results.push(result.data.hits.hits[a]._source);
                }
            }
            response.totalRecords = result.data.hits ? result.data.hits.total : 0;

            console.log(response);

            handleSuccess(dispatch, response.results)
        }).catch(function (error) {

            console.error(error);
            handleError(dispatch, error)
        });

        // axiosInstance({
        //     method: 'post',
        //     url: '/' + type + '/' + elasticSearchPostfixForSearch,
        //     data: JSON.stringify(data)
        // }).then(data=>(handleSuccess(dispatch, data, alertText, alertStyle, showAlert)), error=>(handleError(dispatch, error, alertText, alertStyle, showAlert)));
    }

    static setSearchResult(dispatch, type, searchResult){
        dispatch({
            type:type,
            results:searchResult
        })
    }
}
