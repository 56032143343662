import React, {Component, isValidElement} from 'react';
import createFragment from 'react-addons-create-fragment';
import './assignment-table-mobile.css';
import {connect} from 'react-redux';
import Utility from '../maintenance-table/utility';

class AssignmentMaintenanceTableMobile extends Component {
    constructor(props) {
        super(props);
        this.emptyMessage = props.emptyMessage;
        this.columns = props.columns;
        this.subCol = props.columns.filter((col)=> {
            return col.name === 'subjects'
        })[0];
    }

    getRecordIconClass(type) {
        switch (type.toLowerCase()) {

            case 'pdf':
            case 'url':
            case 'learning':
                return 'learningType';

            case 'flashcard':
            case 'flashcards':
                return 'flashcardType';

            case 'questions':
            case 'question':
                return 'questionType';

            default:
                return 'learningType';
        }
    }

    render() {
        let viewActivityMobile = this.props.viewActivityMobile;
        let context = this;
        let records = <div className=" mobileListView hidden-lg hidden-tab hidden-md" key={'maintenance-table-mobile'}>
            {this.props.items.map(record => {
                let name = record['name'] ? Utility.ucFirst(record['name']) : '';
                let ownerName = record['ownerName'] ? Utility.ucFirst(record['ownerName']) : '';

                let subjectsText = '';
                if (this.subCol && this.subCol.type.toLowerCase() === 'array') {
                    if (context.subCol.options.dynamoTyped) {
                        // process list as it comes from dynamoDB. expect L and S in data.
                        let array = record[context.subCol.name];

                        if (array && array.L) {
                            array.L.forEach(obj => {
                                if (obj.S.trim()) {
                                    subjectsText += Utility.ucFirst(obj.S.trim()) + ', ';
                                }
                            });
                        }
                        if (subjectsText.length > 2) {
                            subjectsText = subjectsText.substr(0, subjectsText.length - 2);
                        }
                    } else {
                        // normal array.
                        let array = record[context.subCol.name];
                        if (array && array.length > 0) {
                            array.forEach(obj => {
                                if (obj && obj.toString() && obj.toString().trim()) {
                                    subjectsText += Utility.ucFirst(obj.toString().trim()) + ', ';
                                }
                            });
                        }
                        if (subjectsText.length > 2) {
                            subjectsText = subjectsText.substr(0, subjectsText.length - 2);
                        }
                    }
                }
                let activityType = record['activityType'] ? Utility.ucFirst(record['activityType']) : '';

                let actClass = context.getRecordIconClass(activityType);
                let createdAt = new Date(record['createdAt']).toLocaleDateString() === 'Invalid Date' ? '' : new Date(record['createdAt']).toLocaleDateString();
                return createFragment({
                    key: <div className={"listviewblockBox " + actClass} onClick={()=>{viewActivityMobile(record)}}>
                        <div className="col-xs-2"><span className="typeIcon"/></div>
                        <div className="col-xs-5">
                          <span className="contentType ">
                            <h4>{name}</h4>
                            <p>By <strong>{ownerName}</strong></p>
                            <p>Subject <strong>{subjectsText}</strong></p>
                          </span>
                        </div>
                        <div className="col-xs-5 text-right">
                        <span className="contentType ">
                          <p>Created <strong>{createdAt}</strong></p>
                          <p className="m-t-20">Type <strong>{activityType}</strong></p>
                          </span>
                        </div>
                    </div>
                });
            })}
            {context.props.items.length === 0 ? <div className="listviewblockBox emptyMessage">
                {context.props.emptyMessage}
            </div> : ''}
        </div>;
        return (records);
    }
}

function mapStateToProps(state = {}) {
    return {...state};
}
export default connect(mapStateToProps)(AssignmentMaintenanceTableMobile);
