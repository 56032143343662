/**
 * Created by ashahab on 7/21/2017.
 */
import { CREATE_QUESTION, MODIFY_QUESTION, QUESTIONS_SEARCH_RESULT, FETCH_ALL_QUESTIONS, RECEIVED_QUESTIONS_RESULTS, FETCH_QUESTIONS_FAILED, CLONE_QUESTION} from '../actions/types';

export default function manageQuestions(state = { results: [] }, action) {
    switch (action.type) {
        case CREATE_QUESTION:
            return {
                ...state,
                questionType: action.questionType,
                subjects: action.subjects,
                category: action.category,
                subcategory: action.subcategory,
            };
        case MODIFY_QUESTION:
            return {
                ...state,
                questionData: action.questionData,
            };
        case CLONE_QUESTION:
            return {
                ...state,
                cloneQuestionData: action.cloneQuestionData,
            };

        case QUESTIONS_SEARCH_RESULT:
            
            return {
                ...state,
                results: action.results,
                searchResults: action.results,
                clearFilters: true
            };
        case FETCH_ALL_QUESTIONS:
            return {
                ...state,
                isFetching: true,
                results: []
            };
        case RECEIVED_QUESTIONS_RESULTS:
            if (action.skipAlterForAllRecords) {
                return {
                    ...state,
                    isFetching: false,
                    results: action.data,
                    filteredRecords: action.data,
                    alertText: action.alertText,
                    alertStyle: action.alertStyle,
                    showAlert: action.showAlert,
                    clearFilters: action.clearFilters,
                    totalRecords: action.totalRecords

                };
            } else {
                return {
                    ...state,
                    isFetching: false,
                    results: action.data,
                    allRecords: action.data,
                    searchResults: action.data,
                    filteredRecords: action.data,
                    alertText: action.alertText,
                    alertStyle: action.alertStyle,
                    showAlert: action.showAlert,
                    clearFilters: action.clearFilters,
                    totalRecords: action.totalRecords
                };
            }
        case FETCH_QUESTIONS_FAILED:
            const status = action.data && action.data.response && action.data.response.status ? action.data.response.status : '';
            const statusText = action.data && action.data.response && action.data.response.statusText ? action.data.response.statusText : '';
            return {
                ...state,
                isFetching: false,
                error: action.data.message,
                status: status,
                statusText: statusText,
                alertText: action.alertText,
                alertStyle: action.alertStyle,
                showAlert: action.showAlert
            };
        default:
            return state
    }
};
