import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';


const TextAreaFieldGroup = ({id, isDisabled,field, value, label, error, type, onChange, onKeyUp, placeholder, toolTip, className, selectWidth}) => {
  return (
    <div className={classnames("form-group", {'has-error': error})}>
      {label && <label className="control-label">{label}</label>}
      <div className={classnames("posRel", {"selectWidth" : selectWidth}, {"fieldBg": isDisabled})}>
        <textarea
            id={id}
          disabled={isDisabled}
          value={value}
          onChange={onChange}
          type={type}
          onKeyUp={onKeyUp}
          name={field}
          placeholder={placeholder}
          className={className ? className : "form-control"}
            style={{height:'160px'}}
        />
      </div>
      {toolTip && <a className="Activity-toolTip toolTipCus">
          <span className="tooltiptext">
              {toolTip}
          </span>
      </a>}
      {error && selectWidth && <div><label className="control-label"></label><div className={classnames("posRel ", {"selectWidth" : selectWidth})}><span className="help-block posRel">{error}</span></div></div>}
      {error && !selectWidth && <div><div className={classnames("posRel ", {"selectWidth" : selectWidth})}><span className="help-block posRel">{error}</span></div></div>}
    </div>
  )
}

TextAreaFieldGroup.propTypes ={
  isDisabled: PropTypes.bool,
  field: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  error: PropTypes.string,
  type: PropTypes.string.isRequired,
  placholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onKeyUp: PropTypes.func,
  className: PropTypes.string,
  selectWidth: PropTypes.bool
}


TextAreaFieldGroup.defaultProps = {
  type: 'text',
  selectWidth: true
}

export default TextAreaFieldGroup;
