import React from "react";
import {connect} from "react-redux";
import ProgressIndicator from "../progress-indicator/progress-indicator";
import Heading from "../heading/heading";
import "./manage-report.css";
import $ from "jquery";
import tick from "../../assets/images/tick.png";
import ElasticSearchStore from "../../actions/elastic-search-store";
import MaterialIcon from "react-google-material-icons";
import apiClient from "../../actions/api-client";
import {browserHistory} from "react-router";
import {Alert, Modal, Button} from "react-bootstrap";
import SelectFieldGroup from "../forms/select-field-group";
import ClevertapReact from "clevertap-react";



// have to change these according to the requirements
import {RECEIVED_COURSE_RESULTS,ActionIconTypes,ElasticSearchTypes,ElasticSearchColumns} from "../../actions/types";
import LoginUtils from "../common/login-utils";
import config from "../../config";
import ElasticSearchActions from "../../actions/elastic-search-actions";
import ElasticSearchQuery from "../../actions/elastic-search-query";



class ManageReport extends React.Component {
    constructor(props) {
        super(props);
        this.getAssignmentReportData = this.getAssignmentReportData.bind(this);
        this.getCourseSidePanel = this.getCourseSidePanel.bind(this);
        this.expandCollapseSideBar = this.expandCollapseSideBar.bind(this);
        this.unitHeadingToggle = this.unitHeadingToggle.bind(this);
        this.getSubjectDialog = this.getSubjectDialog.bind(this);
        this.params = this.props.params;
        this.estore = new ElasticSearchStore();
        this.state = {
            loading: false,
            activities:[
                {
                    activityID:1,
                    activityName:'Which activities are my students having difficulty with?',
                    icon:'pool'
                },
                {
                    activityID:2,
                    activityName:'which students are having difficulties?',
                    icon:'people'
                },
                {
                    activityID:3,
                    activityName:'Where have my students spent their time?',
                    icon:'query_builder'
                },
                {
                    activityID:4,
                    activityName:'How much practice have my students done?',
                    icon:'check'
                },
                {
                    activityID:5,
                    activityName:'Which students are not attempting their assignments?',
                    icon:'not_interested'
                }
            ],
            showCourseDialog: false,
            coursesOfUser: []
        };



    }

    componentDidMount() {

        this.showIndicator();


        // limit is 1000, change it if any issue arises

        let visibility = '(public OR "' + this.props.auth.user.userName + '"';
        if (this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName === "LearnerPal") {
            visibility += " OR LPAdmin";
        }
        visibility += ')';
        let subjectsToSearch = "(";
        if (this.props.auth.user.subjects) {
            this.props.auth.user.subjects.forEach(sub => {
                subjectsToSearch += "\"" + sub + "\" OR ";
            })
        }
        subjectsToSearch = subjectsToSearch.substring(0,subjectsToSearch.length-3);
        let query = "visibleTo : "+visibility+" AND subject : " + subjectsToSearch+")";

        //LoginUtils.isAuthenticated(this.props, browserHistory, '/courses', '/');
        //const {dispatch} = this.props;
        //ElasticSearchActions.setElasticSearchAttributes(dispatch,"courses",'', parseInt($('#pageSizeDropDown').val())?parseInt($('#pageSizeDropDown').val()):1000, 1 ,{_score:{order: "desc"}}, []);
        let elasticSearchQuery  = new ElasticSearchQuery();
        elasticSearchQuery.query(ElasticSearchTypes.Course+config.stage, ElasticSearchColumns.Course, this.props,
            '', parseInt($('#pageSizeDropDown').val())?parseInt($('#pageSizeDropDown').val()):1000, 1 ,{_score:{order: "desc"}}, [], (results, totalRecords) => {


                    // results.forEach( x => {
                    //     this.state.coursesOfUser.push({ id: x.id, name: x.courseName })
                    // })

                    this.state.coursesOfUser = results;

                    // for (result in results)
                    // this.state.coursesOfUser.push({ id: result[id], name: result[courseName] })

                // dispatch({
                //     type: RECEIVED_COURSE_RESULTS,
                //     status: 200,
                //     data: JSON.parse(JSON.stringify(results)),
                //     alertText: '',
                //     alertStyle: '',
                //     showAlert: false,
                //     totalRecords:totalRecords
                // });

                this.hideIndicator();

            }, true, query);

    }

    getSidePanelUnitHeadingActivities(subCategories, category) {
        let context = this;
        let result = [];
        if (subCategories) {
            result = subCategories.map(subCat => {
                return <div className="fieldBox" key={subCat} id={subCat} onClick={(evt)=> {

                    context.setState({
                        showCountDialog: true,
                        showCountCategory: category,
                        showCountSubCategory: subCat
                    });
                }} data-category={category}>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-alignlft paddnone fieldBoxFullWidth">
                        <label htmlFor="subject2" className="pay_label css-label1"><a
                            href="javascript:void 0">{subCat}</a> </label>
                        {  /* Completed status */
                            context.isActivityComplete(subCat) && context.state.isPractice !== true &&
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-alignlft" style={{float: 'right'}}>
                                <div className="maincolgreen"><img src={tick}/> Completed</div>
                            </div>
                        }
                        { /* Resume button only if its a practice course */
                            context.isResumeable(subCat) &&
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-alignlft paddnone">
                                <div className="maincolblus" onClick={context.resumeActivity(subCat, category)}>Resume
                                </div>
                            </div>
                        }
                    </div>
                </div>;
            });
        }
        return result;
    }

    unitHeadingToggle(evt, activityID) {
        console.log(evt, 'Unit heading toggle called.', activityID);
        let context = this;
        this.showIndicator();
        this.getActivityReport(activityID).then(result => {
            result.totalStudents = result.students.length;
            let st = {
                currentActivity: result,
                view:'activity'
            };
            if (!localStorage.getItem(this.props.auth.user.userName + '-dont-show-info-dialog')) {
                st.showActivityClickDialog = true;
            }
            context.setState(st);
            this.hideIndicator();
        }).catch(error => {
            context.setState({
                showAlert: true,
                alertStyle: 'danger',
                alertText: 'Unable to load sub categories, please contact administrator.'
            });
            setTimeout(()=> {
                context.setState({
                    showAlert: false,
                    alertStyle: '',
                    alertText: ''
                })
            }, 4000);
        });
    }

    getSidePanelUnitHeadings(activities) {
        let context = this;
        let results = [
        ];
        if (activities) {
            let unitCount = 1;
            activities.forEach(activity => {
                if (activity) {
                    results.push(
                        <button type="button" className="collapsed" key={'trigger-unit-' + activity.activityID}
                                id={'trigger-unit-' + activity.activityID}
                                onClick={function(evt){
                                    evt.persist();
                                    context.unitHeadingToggle(evt, activity.activityID);
                                }.bind(this)}><span
                            className=''>{activity.activityName} {<MaterialIcon icon={activity.icon} size={24}/>}</span>
                        </button>
                    );
                }
                unitCount++;
            });
        }
        return <div className="overFlowScroll-sm overflww" id={'all-units'}>{results}</div>;
    }

    expandCollapseSideBar(evt) {
        console.log(evt, 'Expand Collapse called.');
        let sidePanel = $("#sidePanel");
        let contentPanel = $("#contentPanel");
        sidePanel.toggleClass('leftBoxWidthMinimize');
        contentPanel.toggleClass('rightBoxWidthMinimize');
    }

    getCategorySidePanel(categories) {
        let context = this;
        if (categories) {
            return <div className="anchorLeftBox filterBlockss" id="sidePanel" style={{display:'none'}}>
                <div className="ArrowDivCollapse "><span className="ArrowExpand resmain" onClick={context.expandCollapseSideBar}/></div>

                {/* Course Heading and collapse arrow */}
                <div className="SubjectBox" style={{borderBottom: '1px solid #ccc', cursor: 'pointer'}} onClick={()=>{this.setState({view:'assignment', currentActivity:null})}}>
                    <div className="limitFilters">
                        <span className="contentType ">
                            <MaterialIcon icon="trending_up" size={32}/>
                        <h4>My "Quick" Reports</h4>
                        <div className=""/>
                    </span>
                    </div>
                </div>
                {/* Course Unit Headings */}
                {context.getSidePanelUnitHeadings(categories)}
            </div>;
        }
    }

    getCourseSidePanel(course) {
        let context = this;

        if (course) {
            let courseName = course.courseName;
            let dueDate = context.state.assignment ? context.state.assignment.dueDate : '';
            let units = course.units ? course.units : [];
            return <div className="anchorLeftBox filterBlockss" id="sidePanel">
                <div className="ArrowDivCollapse "><span className="ArrowExpand resmain"
                                                         onClick={context.expandCollapseSideBar}/></div>

                {/* Course Heading and collapse arrow */}
                <div className="SubjectBox">
                    <div className="limitFilters">
                        <span className="typeIcons"/>
                        <span className="contentType ">
                        <h4>{courseName}</h4>
                        <div className="">{dueDate}</div>
                    </span>
                    </div>
                </div>
                {/* Course Unit Headings */}
                {context.getSidePanelUnitHeadings(units)}

            </div>;
        }
    }

    getActivitySidePanel(act) {
        let context = this;
        if (act) {

            let courseName = context.state.assignment && context.state.assignment.assignedActivities && context.state.assignment.assignedActivities.activityName ? context.state.assignment.assignedActivities.activityName : '';
            let isPractice = context.state.assignment && context.state.assignment.assignedActivities && context.state.assignment.assignedActivities.assessmentType ? context.state.assignment.assignedActivities.assessmentType : '';
            let count = context.state.assignment && context.state.assignment.assignedActivities && context.state.assignment.assignedActivities.count ? context.state.assignment.assignedActivities.count : 0;
            let dueDate = context.state.assignment ? context.state.assignment.dueDate : '';

            return <div className="anchorLeftBox filterBlockss" id="sidePanel">

                <div className="ArrowDivCollapse "><span className="ArrowExpand resmain"
                                                         onClick={context.expandCollapseSideBar}/></div>

                {/* Course Heading and collapse arrow */}

                <div className="SubjectBox">
                    {isPractice.toLowerCase() === 'practice' && <div className="maincolblusr" style={{
                        float: 'right',
                        marginRight: '80px',
                        display: 'block'
                    }}>{count}</div>}
                    <div className="limitFilters">
                        <span className="typeIcons"/>
                        <span className="contentType ">
                        <h4>{courseName}</h4>
                        <div className="">{dueDate}</div>
                    </span>
                    </div>
                </div>
            </div>;
        }
    }

    loadStudentDetails(assignmentID, studentID, activityID){
        return new Promise((resolve, reject) => {
            let context = this;
            let params = {
                assignmentID: assignmentID,
                studentID: studentID,
                activityID: activityID
            };
            let pathTemplate = '/assignment/report/assignmentID/{assignmentID}/activity/{activityID}/student/{studentID}';
            let method = 'GET';
            let additionalParams = {};
            let body = {};
            apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, '3').then(function (result) {
                resolve(result.data);
            }).catch(function (error) {
                context.setState({
                    showAlert: true,
                    alertStyle: 'danger',
                    alertText: 'Unable to load data, please contact administrator.'
                });
                setTimeout(()=> {
                    context.setState({
                        showAlert: false,
                        alertStyle: '',
                        alertText: ''
                    })
                }, 4000);
                reject(error);
            });
        });
    }

    expandCollapse(evt) {
        let table = null;
        let icon = null;
        if (evt.target.nodeName === 'I') {
            table = evt.target.parentElement.nextElementSibling;
            icon = evt.target;
        } else {
            table = evt.target.nextElementSibling;
            icon = evt.target.firstElementChild;
        }
        if (table.style.display === 'none') {
            table.style.display = 'block';
        } else {
            table.style.display = 'none';
        }
        if (icon.innerHTML === 'add') {
            icon.innerHTML = 'remove';
        } else {
            icon.innerHTML = 'add';
        }
    }

    getMainContent() {
        let context = this;
        return (
            <div id="assignment-analysis" className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <hr />
                    <br />
                    <p style={{overflow: 'auto'}}>
                        <div className="row" style={{margin: 'auto'}}>
                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 report-box" onClick={()=> {
                                ClevertapReact.event("View Assignment Report", {'userName' : this.props.auth.user.userName});
                                browserHistory.push('/reports/assignments');
                            }}>
                                <span style={{color:'#4d6573'}}><MaterialIcon icon='assignment' size={48}/></span><strong>Assignments: </strong> View assignment and activity performance by class, student or question
                            </div>

                            {<div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 report-box" onClick={() => {
                                ClevertapReact.event("View Course Report", {'userName' : this.props.auth.user.userName});
                                //browserHistory.push('/reports/course-report');
                                context.setState({ showCourseDialog: true });
                            }}>
                                <span style={{color:'#66af47'}}><MaterialIcon icon='dashboard' size={48}/></span><strong>Course Report: </strong> View course report by student, or activity
                            </div>
                            }

                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 report-box" onClick={()=> {
                                ClevertapReact.event("View Activities Report", {'userName' : this.props.auth.user.userName});
                                browserHistory.push('/reports/activities');
                            }}>
                                <span style={{color:'#4d6573'}}><MaterialIcon icon='description' size={48}/></span><strong>Activities: </strong> View individual activity performance by class, student or question
                            </div>
                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 report-box" onClick={()=> {
                                ClevertapReact.event("View Grade Tracker Report", {'userName' : this.props.auth.user.userName});
                                if(context.props.auth.user.subjects.length > 1){
                                    context.setState({
                                        showSubjectDialog:true
                                    });
                                }else if(context.props.auth.user.subjects.length === 1){
                                    browserHistory.push('/reports/students/'+ context.props.auth.user.subjects[0]);
                                }
                            }}>
                                <span style={{color:'#66af47'}}><MaterialIcon icon='supervisor_account' size={48}/></span><strong>Grade Tracker: </strong> View class and student performance by subject, category and subcategory
                            </div>
                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 report-box" onClick={()=> {
                                ClevertapReact.event("View Student Report", {'userName' : this.props.auth.user.userName});
                                browserHistory.push('/reports/studentactivityreport');
                            }}>
                                <span className="custom-color-icon" style={{color:'#55a4f0'}}><MaterialIcon icon='assignment_ind' size={48}/></span><strong>Student Report: </strong> View student performance
                            </div>
                            {this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName === "LearnerPal" && <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 report-box" onClick={()=> {
                                ClevertapReact.event("View Student Report", {'userName' : this.props.auth.user.userName});
                                browserHistory.push('/reports/lpAdmin');
                            }}>
                                <span style={{color:'#f05557'}}><MaterialIcon icon='pageview' size={48}/></span><strong>Lp Admin Report: </strong> View Lp Admin Report
                            </div>}


                            {this.props.auth.user.userType.toLowerCase() === "admin" && <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 report-box" onClick={()=> {
                                ClevertapReact.event("View Admin Report", {'userName' : this.props.auth.user.userName});
                                if(context.props.auth.user.subjects.length > 1){
                                    context.setState({
                                        showSubjectDialog:true,
                                        forAdminReport: true
                                    });
                                } else if (context.props.auth.user.subjects.length === 1) {
                                    browserHistory.push({
                                        // pathname: '/reports/students/' + context.props.auth.user.subjects[0],
                                        // state: { forAdminReport: true }
                                        pathname: '/reports/admin-report',
                                        state: { selectedSubject: context.props.auth.user.subjects[0] }
                                    });
                                }
                            }}>
                                <span className="custom-color-icon" style={{color:'#44a4f0'}}><MaterialIcon icon='developer_board' size={48}/></span><strong>Admin Report: </strong> View data and performance by department, school, class or students
                            </div>}


                        </div>
                    </p>
                </div>
            </div>
        )
    }

    handleAlertDismiss(e) {
        this.setState({'showAlert': false});
    }

    render() {
        let context = this;
        let activities = context.state.activities;
        let  content = this.getMainContent();
        return (
            <div className="container-fluid noPad listMainBoxWrapper " id="manage-report">
                <Heading
                    isHideHeading={false}
                    allButtons={[]}
                    isCreateButton={false}
                    dataPath={'studentPortal.assignments.results'}
                    allRecords={'studentPortal.assignments.allRecords'}
                    actionName={'SEARCH_RESULT'}
                    filteredData={'studentPortal.assignments.filteredRecords'}
                    listPage={'activities'}
                    heading="Reports"
                    isShowButton={false}
                    isSearch={false}
                    breadcrumbsData={[
                    ]}
                    showFilterButtons={'false'}
                />
                {this.state.showAlert &&
                <div className="row">
                    <div className="col-md-3 col-xs-3"/>
                    <div className="col-md-6 col-xs-3">
                        <Alert bsStyle={this.state.alertStyle}
                               onDismiss={this.handleAlertDismiss}>
                            {this.state.alertText}
                        </Alert>
                    </div>
                    <div className="col-md-3 col-xs-3"/>
                </div>
                }
                <div id="submission" className="tab-pane fade active in">
                    {/* Side panel for Course view */}
                    {activities && this.getCategorySidePanel(activities) }
                    {/* Side panel for Activity view */}
                    <div className="mainActivBoxRight" id="contentPanel">
                        {/* Tabs. */}
                        <div className="" id="gray-box-container">
                            <div className="container-fluid questionMainBlock" style={{margin: '0 20px 108px 20px'}}>
                                <div className="quesAndIconStudent">
                                    {content}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <ProgressIndicator show={this.state.loading}/>
                {this.state.showSubjectDialog && this.getSubjectDialog()}
                {this.state.showCourseDialog && this.getCoursesDialog()}
            </div>
        );
    }

    getCoursesDialog() {

        let courses = this.state.coursesOfUser;

        courses.sort((a,b) =>
        {
            if(a.id < b.id) return -1;
            if(a.id > b.id) return 1;
            return 0;
        })

        let nodes = [];

        if (courses) {
            courses.forEach(sub => {
                nodes.push({ id: sub.id, name: sub.courseName });
            });
        }

        let context = this;
        let body = <div>
            <label style={{ padding: '10px' }}>Please select a Course for Course Report:</label>
            <SelectFieldGroup
                field="selectedCourse"
                value={this.state.selectedCourse}
                //options={this.state.coursesOfUser}
                options={nodes}
                placeholder="Select a Course"
                onChange={(evt) => {
                    context.setState({
                        selectedCourse: evt.target.value
                    })
                }}
            />
        </div>;

        return <Modal id="hint_modal"
            show={this.state.showCourseDialog}
            onHide={() => {
                this.setState({ showCourseDialog: false });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Select Course for Course Report</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    if (this.state.selectedCourse && this.state.selectedCourse !== 'none') {
                        this.setState({ showCourseDialog: false });

                        browserHistory.push({pathname:'/reports/course-report-students/' + this.state.selectedCourse, state: { courseName: courses.find(x=>x.id==this.state.selectedCourse).courseName }});

                    }
                }} bsStyle='primary'>Run</Button>
            </Modal.Footer>
        </Modal>;
    }

    getSubjectDialog() {

        let subjects = this.props.auth.user.subjects;
        let nodes = [];

        if(subjects){
            subjects.forEach(sub => {
                nodes.push({id:sub, name:sub});
            });
        }
        let context = this;
        let body = <div>
            <label style={{padding:'10px'}}>Please select a Subject for Report:</label>
            <SelectFieldGroup
                field="selectedSubject"
                value={this.state.selectedSubject}
                options={nodes}
                placeholder="Select a Subject"
                onChange={(evt) => {
                    context.setState({
                        selectedSubject: evt.target.value
                    })
                }}
            />
        </div>;

        return <Modal id="hint_modal"
                      show={this.state.showSubjectDialog}
                      onHide={()=> {
                          this.setState({showSubjectDialog: false});
                      }}
                      container={this}
                      aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Select Subject for Report</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={()=> {
                    if (this.state.selectedSubject && this.state.selectedSubject !== 'none') {
                        this.setState({showSubjectDialog: false});

                        this.state.forAdminReport == true ? browserHistory.push({
                            // pathname: '/reports/students/' + this.state.selectedSubject,
                            // state: { forAdminReport: true }
                            pathname: '/reports/admin-report',
                            state: { selectedSubject: this.state.selectedSubject }
                        }) : browserHistory.push('/reports/students/' + this.state.selectedSubject);
                    }
                }} bsStyle='primary'>Run</Button>
            </Modal.Footer>
        </Modal>;
    }

    showIndicator() {
        this.setState({
            ...this.state,
            loading: true
        });
    }

    hideIndicator() {
        this.setState({
            ...this.state,
            loading: false
        });
    }

    getAssignmentReportData(id) {
        let context = this;
        context.showIndicator();
        return new Promise((resolve, reject) => {
            let params = {
                assignmentID: id
                // studentID: this.props.auth.user.userName
            };
            let pathTemplate = '/assignment/report/assignmentID/{assignmentID}';
            let method = 'GET';
            let additionalParams = {};
            let body = {};
            apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, '3').then(function (result) {
                resolve(result.data);
            }).catch(function (error) {
                context.setState({
                    showAlert: true,
                    alertStyle: 'danger',
                    alertText: 'Unable to load data, please contact administrator.'
                });
                setTimeout(()=> {
                    context.setState({
                        showAlert: false,
                        alertStyle: '',
                        alertText: ''
                    })
                }, 4000);
                reject(error);
            });
        });
    }
}

function mapStateToProps(state) {
    return {
        ...state
    }
}

export default connect(mapStateToProps)(ManageReport);
