/**
 * Created by ashahab on 8/18/2017.
 */
import React, {Component} from "react";
import {connect} from "react-redux";
import {Alert, Button, Modal} from "react-bootstrap";
import Filter from "../../filter/filter";
import ProgressIndicator from "../../progress-indicator/progress-indicator";
import Heading from "../../heading/heading";
import SelectFieldGroup from "../../forms/select-field-group";
import TextFieldGroup from "../../forms/text-field-group";
import TextAreaFieldGroup from "../../forms/text-area-field-group";
import {
    ElasticSearchColumns,
    ElasticSearchTypes,
    RECEIVED_STUDENT_GLOSSARY_OVERVIEW_RESULT,
    RECEIVED_STUDENT_GLOSSARY_WORDS_RESULTS
} from "../../../actions/types";
import classnames from "classnames";
import $ from "jquery";
import {browserHistory} from "react-router";
import ElasticSearchActions from "../../../actions/elastic-search-actions";
import LoginUtils from "../../common/login-utils";
// import axios from "axios";
import apiClient from "../../../actions/api-client";
import ClevertapReact from "clevertap-react";
import "./custom.css";

class GlossaryOverview extends Component {
    constructor(props) {
        super(props);

        this.changeScreen = this.changeScreen.bind(this);
        this.createNewWord = this.createNewWord.bind(this);
        this.getAllSubjects = this.getAllSubjects.bind(this);
        this.showIndicator = this.showIndicator.bind(this);
        this.hideIndicator = this.hideIndicator.bind(this);
        this.getCreateWordDialog = this.getCreateWordDialog.bind(this);
        this.onChange = this.onChange.bind(this);
        this.saveWord = this.saveWord.bind(this);
        this.handleAlertDismiss = this.handleAlertDismiss.bind(this);

        this.state = {screenWidth: window.innerWidth,loadingNextPage: 0};
        this.filterComponentCssClassName = 'filterBlock NiceScroll';
        this.tableCssClassName = 'listViewBlock ';

        this.filterColumns = [
            {name: 'category', type: 'text', label: 'Category'}
        ];

        this.state = {allFlashcards:[],allFlashcardsWithSubjects:[], showCreateEditDialog:false, errors: {}, saveAndAddMore:false};
    }
    onChange(e) {
        this.setState({[e.target.name] : e.target.value});
        if(e.target.name == 'subject'){
            let allSubjects = this.state.allSubjects;
            const sub = allSubjects.find(obj=> obj.id == e.target.value);
            if(sub.categories){
                this.setState({categoriesOptions: sub.categories})
            } else {
              this.setState({ categoriesOptions: [] });
            }
        }
    }
    handleAlertDismiss(e) {
        this.setState({showAlert: false});
    }
    showIndicator() {
        this.setState({
            loading: true
        });
    }

    hideIndicator() {
        this.setState({
            loading: false
        });
    }

    getAllSubjects() {
        let context = this;
        context.showIndicator();
        let schoolName = this.props.auth.user.schoolName ? this.props.auth.user.schoolName : 'trial';
        let params = {
            schoolName: schoolName,
            ownerName: this.props.auth.user.userName,
        };
        let pathTemplate = '/subjects/ownerName/{ownerName}/schoolName/{schoolName}';
        let method = 'GET';
        let additionalParams = {};
        let body = {};
        apiClient.invokeApi(params, pathTemplate, method, additionalParams, body).then(function (result) {
            let sortedSubjects = [];
            let { subjects } = context.props.auth.user;
            result.data.forEach(x => {
                let subjectName = x.name;
                if (!subjects.find(y => y == subjectName))
                    return;
                let cat = [{id: 'My Words', name: 'My Words'}];
                cat.push(...x.categories.map(obj=>{ return {id: obj.category, name: obj.category}}));
                sortedSubjects.push({ "id": x.id, "name": subjectName, categories: cat });
            });
    
            sortedSubjects.sort(function (a, b) {
                return a.id.localeCompare(b.id, undefined, {
                    numeric: true,
                    sensitivity: 'base'
                });
            });
            context.setState({allSubjects: sortedSubjects, loading: false});
        }).catch(function (error) {
            context.hideIndicator();
        });
    }

    getCreateWordDialog() {
        let title = this.state.saveAndAddMore ? "word successfully added, add another": "Create Word";
        let buttonName = "Save and Exit";
        let allSubjects = this.state.allSubjects;
        let errors = this.state.errors;
        const isLPAdmin = this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName === "LearnerPal";
        return <Modal
            show={this.state.showCreateEditDialog}
            onHide={()=> {
                this.setState({showCreateEditDialog: false, errors:{}});
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="mainFormBox">
                    <div className="col-lg-12 col-md-12">
                        <SelectFieldGroup
                            dropDownId="subjectDropDownId"
                            error={errors.subject}
                            label="Subject:"
                            field="subject"
                            options={allSubjects}
                            value={this.state.subject}
                            placeholder="Associate with Subjects"
                            onChange={this.onChange}
                        />
                        {isLPAdmin && (<SelectFieldGroup
                            isDisabled={!this.state.subject}
                            dropDownId="categoryDropDownId"
                            error={errors.category}
                            label="Category:"
                            field="category"
                            options={this.state.categoriesOptions}
                            value={this.state.category }
                            placeholder="Select Category"
                            onChange={this.onChange}
                        />)}
                        <TextFieldGroup
                            error={errors.word}
                            label="Flashcard Word:"
                            field="word"
                            value={this.state.word}
                            placeholder="Enter a Word."
                            toolTip="Please enter a Word."
                            onChange={this.onChange}
                        />
                        <p style={{color:'maroon'}}>Words can be viewed when creating a new Flashcard Deck or within the Vocabulary page "My Words"</p>
                        <TextAreaFieldGroup
                            error={errors.definition}
                            label="Flashcard Definition:"
                            field="definition"
                            value={this.state.definition}
                            placeholder="Enter a definition for the word."
                            toolTip="Please enter a definition for the word."
                            onChange={this.onChange}
                        />
                    </div>
                    <div className="row"/>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={()=> {
                    this.setState({showCreateEditDialog: false, errors:{}, loading:false});
                }}>Cancel</Button>
                <Button bsStyle='primary' onClick={()=>{this.saveWord();this.setState({saveAndAddMore:false});}}>{buttonName}</Button>
                <Button bsStyle='primary' onClick={()=>{this.saveWord();this.setState({saveAndAddMore:true});}}>Save and Add More</Button>
            </Modal.Footer>
        </Modal>
    }

    saveWord() {

        let context = this;
        let error = {};
        let payload = {};
        const isLPAdmin = this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName === "LearnerPal";

        if (!context.state.word) {
            error.word = 'Word is required to create a flashcard.';
        }
        if (!context.state.definition) {
            error.definition = 'Definition is required to create a flashcard.';
        }
        if (!context.state.subject) {
            error.subject = 'Subject is required to create a flashcard.';
        }
        if (JSON.stringify(error).replace('{}', ""))
        {
            context.setState({errors: error});
        }
        else
        {
            this.setState({showCreateEditDialog:false,errors:{}});
            payload.word = context.state.word;
            payload.definition = context.state.definition;
            payload.subjects = context.state.subject;
            payload.ownerName = context.props.auth.user.userName;
            payload.category = isLPAdmin && context.state.category ? context.state.category: 'My Words';
            if(isLPAdmin){
                payload.learnerPalOwner = 'Public';
            }
            // save call.
            context.showIndicator();
            let params = {};
            let pathTemplate = '/word';
            let method = 'POST';
            let additionalParams = {};
            apiClient.invokeApi(params, pathTemplate, method, additionalParams, payload, "3").then(function (result) {
                ClevertapReact.event("Create Word", {'userName' : context.props.auth.user.userName});

                context.hideIndicator();
                if (context.state.saveAndAddMore)
                {
                    context.setState({ showCreateEditDialog:true, definition:'', word:'', subject:'', errors:{}});
                }
                else
                {
                    context.setState({loading: false, showAlert:true, alertText:result.data, alertStyle:'success', definition:'', word:'', subject:'', errors:{}});
                    setTimeout(()=>{
                        context.setState({showAlert: false});
                    }, 5000);
                }
            }).catch(function (error) {
                context.hideIndicator();
            });
        }
    }

    componentDidMount()
    {
        LoginUtils.isAuthenticated(this.props, browserHistory, '/student/glossary', '/');

        const {dispatch} = this.props;
        ElasticSearchActions.setElasticSearchAttributes(dispatch,"studentGlossary",'', 10, 1 ,{_score:{order: "desc"}}, []);


        this.getAllSubjects();

        // let axiosInstance = axios.create({
        //     baseURL: 'https://search-learnerpal-eimkio3umgx5fnpuy7ypsrrsri.us-east-1.es.amazonaws.com/learnerpal/',
        //     timeout: 60000,
        //     headers: {'Content-Type': 'application/json'},
        //     responseType: 'json',
        //     transformRequest: [function (data) {
        //         return data;
        //     }],
        //     transformResponse: {function (data) {
        //         return data;
        //     }}
        // });
        const isLPAdmin = this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName === "LearnerPal";

        let visibility = '(public OR "' + this.props.auth.user.userName + '"';
        visibility += ')';
        let query = "visibleTo : "+visibility;
        if(isLPAdmin){
            query='visibleTo : *';
        }
        let data=
            {
                query: {bool: {must: {query_string: {query:query}},filter:{bool:{must:[]}}}},
                aggs:{
                    subjects:{
                        terms:{field:"subjects",size:100},
                        aggs:{"category":{terms:{field:"category",size:100}}}
                    }
                }
            };

        let that = this;

        apiClient.invokeApi({}, '/getesdata', 'POST', {}, { payload: data, index: 'learnerpal/', esDocumentType: 'glossarydev' }, '5').then(function (response)
        // axiosInstance({
        //     method: 'post',
        //     url: '/glossarydev/_search',
        //     data: JSON.stringify(data)
        // }).then(response=>
            {
                dispatch({
                    type: RECEIVED_STUDENT_GLOSSARY_OVERVIEW_RESULT,
                    data: response.data.aggregations.subjects.buckets,
                });
            }
        ).catch();
    }

    changeScreen(evt)
    {

        if (evt.target.id === "overview")
        {
            let selector = '#' + 'screen2';
            $(selector).addClass('removeDisplay');
            let selector1 = '#' + 'screen1';
            $(selector1).removeClass('removeDisplay');

            let selector2 = '#' + 'tab1';
            $(selector2).addClass('active');
            let selector3 = '#' + 'tab2';
            $(selector3).removeClass('active');
        }
        else
        {
            let selector = '#' + 'screen1';
            $(selector).addClass('removeDisplay');
            let selector1 = '#' + 'screen2';
            $(selector1).removeClass('removeDisplay');

            let selector2 = '#' + 'tab2';
            $(selector2).addClass('active');
            let selector3 = '#' + 'tab1';
            $(selector3).removeClass('active');
        }
    }

    launchFlashcards(subject, category)
    {
        browserHistory.push({pathname: '/student/glossary-listing', state: {isFromOverview:true, selectedSubject:subject, selectedCategory:category}});
    }

    createNewWord() {

        this.setState({
            showCreateEditDialog: true
        });
    }

    render() {

        let allFlashcardsWithSubjects = this.props.studentPortal.words.allFlashcards;
        let allFlashcardsHtml = [];
        if (allFlashcardsWithSubjects && allFlashcardsWithSubjects.length>0)
        {
            allFlashcardsWithSubjects = allFlashcardsWithSubjects.sort(function (a,b) {
                return a.key.localeCompare(b.key);
            });
            allFlashcardsWithSubjects.forEach(subjects=>{
                let allCategories = subjects.category.buckets;
                allCategories = allCategories.sort(function(a,b) {
                    return a.key.localeCompare(b.key);
                });

                allCategories.forEach(category=>{
                    allFlashcardsHtml.push(
                        <div key={'boxes'+ (Math.random())} className="col-lg-3 col-md-3 col-sm-4 col-xs-12  " onClick={()=>{this.launchFlashcards(subjects.key,category.key)}} style={{cursor: 'pointer'}} >
                            <div className="pacboxcol collghtbg">
                                <div className="limitFilters" style={{margin:'0px'}}>
                                    <span className="facesmile"/>
                                    <span className="contentTypefd">
                                  {/*<span className="headsp " style={{cursor: 'pointer'}} >Launch Flashcards <span className="caretss caret-close"/></span>*/}
                                  </span>
                                </div>
                                <h2 style={{marginTop: '10px'}}>
                                    {category.key}
                                </h2>
                                <h4 style={{fontStyle: 'italic'}}>{subjects.key}</h4>
                                <span className="flashCardWordCount">{category.doc_count} Words</span>
                                <div style={{clear: 'both'}}/>
                            </div>
                        </div>
                    );
                });
            });
        }

        let esAttr = this.props.elasticSearchAttributes.map.get('studentGlossary');
        let actionButtons = [
            {
                linkLabel: 'Add to deck', action: (word, evt) => {
                //do something with record
            }
            }
        ];
        const isLPAdmin = this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName === "LearnerPal";
        let visibility = '(public OR "' + this.props.auth.user.userName + '"';
        visibility += ')';
        let query = "visibleTo : "+visibility;
        if(isLPAdmin){
            query='visibleTo : *';
        }

        return (
            <div>
                <Heading
                    customQuery={query}
                    location = "studentGlossary"
                    actionName = {RECEIVED_STUDENT_GLOSSARY_WORDS_RESULTS}
                    heading="Vocabulary"
                    isSearch={false}
                    screenNameForElasticSearch = {ElasticSearchTypes.StudentGlossary}
                    allColumnsForElasticSearch = {ElasticSearchColumns.StudentGlossary}
                />
                {this.state.showAlert &&
                <div className="row">
                    <div className="col-md-3 col-xs-3"/>
                    <div className="col-md-6 col-xs-3">
                        <Alert bsStyle={this.state.alertStyle}
                               onDismiss={this.handleAlertDismiss}>
                            {this.state.alertText}
                        </Alert>
                    </div>
                    <div className="col-md-3 col-xs-3"/>
                </div>
                }
                <div id="tableFiltersAndSideView">
                    <div className={classnames("container-fluid noPad listMainBoxWrapper")}>
                        <Filter
                            isOverRideStage={true}
                            isQuery={true}
                            customQuery={query}
                            location = "studentGlossary"
                            actionName={RECEIVED_STUDENT_GLOSSARY_WORDS_RESULTS}
                            viewActivityCssClass={this.filterComponentCssClassName}
                            filterColumns={[
                                {name: 'ownerName', type: 'text', label: 'Owner Name'},
                                {name: 'subjects', type: 'array', label: 'Subject'}
                            ]}
                            filterAgg={true}
                            filterAggAction={RECEIVED_STUDENT_GLOSSARY_OVERVIEW_RESULT}
                            screenNameForElasticSearch = {ElasticSearchTypes.StudentGlossary}
                            allColumnsForElasticSearch = {ElasticSearchColumns.StudentGlossary}
                        />
                        <div className="listViewBlock">
                            <div className="listToBesslide NiceScroll ">
                                <div className="eleRow">
                                    <div className="col-lg-6 col-md-4 col-sm-6 col-xs-12 paddnone xs-margin">
                                        <ul className="glossarytab">
                                            <li className="active"><a href="glossary-overview">Words overview</a></li>
                                            <li><a href="glossary-listing">Words Listing</a></li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-6 col-md-8 col-sm-6 col-xs-12 text-alignlftdesk paddnone">
                                        <a className="blubtnpops margnnone" href="#" style={{margin: '0px'}} onClick={()=>{this.createNewWord();this.setState({saveAndAddMore:false});}}>Create New Word</a>
                                    </div>
                                </div>
                                <div className="blockForlist " id="screen1">
                                    <table className="table table-hover">
                                        <div className="eleRow " style={{paddingLeft: '0px', paddingRight: '0px'}}>
                                            <div className="col-lg-9 col-md-8 col-sm-8 col-xs-12 text-alignlft">
                                                <div className="filterBlockun NiceScroll">
                                                    <div className="overFlowScroll-sm bordern">
                                                        <div className="SubjectBox">
                                                            <div className="limitFilter">
                                                                <div className="fieldBox">
                                                                    <input value="8" id="subject8" className="css-checkbox" name="authorize" type="checkbox"/>
                                                                    <label htmlFor="subject8" className="pay_label css-label8">Good</label>
                                                                </div>
                                                                <div className="fieldBox">
                                                                    <input value="6" id="subject6" className="css-checkbox" name="authorize" type="checkbox"/>
                                                                    <label htmlFor="subject6" className="pay_label css-label6">Attention</label>
                                                                </div>
                                                                <div className="fieldBox">
                                                                    <input value="7" id="subject7" className="css-checkbox" name="authorize" type="checkbox"/>
                                                                    <label htmlFor="subject7" className="pay_label css-label7">Struggling</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="quesAndIcongl filterBlockss" >
                                                <div className="row">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center clearfix">
                                                        {allFlashcardsHtml}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ProgressIndicator show={this.state.loading}/>
                {this.state.showCreateEditDialog && this.getCreateWordDialog()}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        ...state
    }
}

export default connect(mapStateToProps)(GlossaryOverview);
