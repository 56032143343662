import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';


const AutoCompleteGroup = ({id,value,field, options, label, error, onBlur, placeholder, toolTip, multiSelect, onKeyPress, isDisabled}) => {
  return (
    <div className={classnames("form-group", {'has-error': error})}>
      {label && <label className="control-label">{label}</label>}
      <div className="posRel fieldBg selectWidth">
        <input
          id={id}
          className="form-control"
          name={field}
          list={id +"classList"}
          placeholder={placeholder}
          onChange={onBlur}
          onKeyPress={onKeyPress}
          value={value}
          disabled={isDisabled}/>
        <span className="selectArrow"/>
        <datalist id={id +"classList"}>
          {options.map(function(a) {
                return(<option key={a.id} value={a.name}/>);
          })}
        </datalist>
        {multiSelect && <div className="appendDiv">{multiSelect}</div>}
      </div>
      {toolTip && <a className="Activity-toolTip toolTipCus">
          <span className="tooltiptext">
              {toolTip}
          </span>
      </a>}
      {error && <div><label className="control-label"/><span className="help-block posRel">{error}</span></div>}
    </div>
  )
}

AutoCompleteGroup.propTypes = {
  field: PropTypes.string.isRequired,
  value: PropTypes.string,
  options: PropTypes.array.isRequired,
  label: PropTypes.string,
  error: PropTypes.string,
  placholder: PropTypes.string,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
  toolTip: PropTypes.string,
  multiSelect: PropTypes.string,
  isDisabled: PropTypes.bool
}

export default AutoCompleteGroup;
