import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {Link} from 'react-router'
import MaterialIcon from "react-google-material-icons";

const MenuItem = ({path, label, router, onClick}) => {
  if (label === 'Dashboard') {
    return (
      <li><Link to={path} className={classnames("", {"active": router.isActive(path)})} style={{paddingTop: '11px', paddingBottom: '11px'}} onClick={onClick}><MaterialIcon icon="home" size={28}/></Link></li>
    );
  // } if (label === 'Question Editor') {
  //   return (
  //     <li><Link to={path} className={classnames("questionEditorFontWeight", {"active": router.isActive(path)})} onClick={onClick}>{label}</Link></li>
  //   );
  } else {
    return (
      <li><Link to={path} className={classnames("", {"active": router.isActive(path)})} onClick={onClick}>{label}</Link></li>
    );
  }
}

MenuItem.propTypes = {
  path: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  router: PropTypes.object.isRequired,
  onClick: PropTypes.func
}

export default MenuItem;
