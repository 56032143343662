/**
 * Created by ashahab on 7/4/2017.
 */
import Validator from 'validator';
import isEmpty from 'lodash/isEmpty';
import ManageCourseActions from "../actions/manage-courses-actions";

export default function validateInput(data, allRecords, ownerName, isEdit) {
    let errors = {};

    let id = data.courseName.replace(/\s|\W|[#$%^&*()'.]/g, "")+ "_" + ownerName;

    if (!isEdit)
    {
        if (Validator.isEmpty(data.courseName.trim()))
        {
            errors.courseName = 'This field is required';
        }
        else if (data.courseName.length > 150)
        {
            errors.courseName = 'Course name should not exceed to 150 Characters';
        }
        else if (allRecords.filter(function (record)
                {
                    return (record.id.toLowerCase() === id.toLowerCase());
                }
            ).length > 0)
        {
            errors.courseName = 'Course with the same name already exists';
        }
    }

    if (Validator.isEmpty(data.selectedSubject.trim()))
    {
        errors.selectedSubject = 'This field is required';
    }

    if (data.units && data.units.length > 0)
    {
        let unitErrors = 0;
        for (let a=0; a<data.units.length; a++)
        {
            
            if (Validator.isEmpty(data.units[a].unitValue))
            {
                data.units[a].error = 'This field is required';
                unitErrors += 1;
            } else {
                data.units[a].error = '';
            }
        }
        if (unitErrors > 0) {
            errors.units = "Error in units";
        }
        // callback(data);
    }

    return {
        errors,
        isValid: isEmpty(errors),
        data: data
    }
}
