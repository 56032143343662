/**
 * Created by ashahab on 7/27/2017.
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import Heading from "../heading/heading";
import TextFieldGroup from "../forms/text-field-group";
import SelectFieldGroup from "../forms/select-field-group";
import AutoCompleteGroup from "../forms/auto-complete-group";
import classnames from 'classnames';
import apiClient from "../../actions/api-client";
import ProgressIndicator from "../progress-indicator/progress-indicator";
import { Alert, Modal, Button } from "react-bootstrap";
import $ from "jquery";
import { detect } from 'detect-browser';
import ClevertapReact from "clevertap-react";
import "./custom.css"

class ManageStudents extends Component {

    constructor(props) {
        super(props);
        this.getAllClasses = this.getAllClasses.bind(this);
        this.onChange = this.onChange.bind(this);
        this.createClass = this.createClass.bind(this);
        this.removeClass = this.removeClass.bind(this);
        this.toggleTabs = this.toggleTabs.bind(this);
        this.getAllStudents = this.getAllStudents.bind(this);
        this.searchStudents = this.searchStudents.bind(this);
        this.filterStudents = this.filterStudents.bind(this);
        this.editPeriodForStudent = this.editPeriodForStudent.bind(this);
        this.showIndicator = this.showIndicator.bind(this);
        this.hideIndicator = this.hideIndicator.bind(this);
        this.handleAlertDismiss = this.handleAlertDismiss.bind(this);
        this.handleAlertDismiss2 = this.handleAlertDismiss2.bind(this);
        this.getDeleteClassDialog = this.getDeleteClassDialog.bind(this);
        this.showDeleteDialog = this.showDeleteDialog.bind(this);
        this.checkEnterKeyPress = this.checkEnterKeyPress.bind(this);
        this.changeStudentClass = this.changeStudentClass.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.allDeleteDialog = this.allDeleteDialog.bind(this);
        this.deleteAllClasses = this.deleteAllClasses.bind(this);
        this.deleteAllStudents = this.deleteAllStudents.bind(this);
        this.importClass = this.importClass.bind(this);

        this.state = {
            allClasses: [], dropdownValue: '', occupiedPeriods: [], isClassManagement: true, isGClassManagement: false, allStudents: [], loading: false, showDeleteDialogBox: false, selectedPeriodForStudent: '',
            allStudentsSearchResult: [], allStudentsFiltered: [], searchTerm: '', selectedPeriod: '', alertText: "This is a sample alert", alertStyle: "danger", showAlert: false, showAlert2: true
        };
    }

    componentDidMount() {
        this.getAllClasses();
        this.getAllStudents();
    }
    showIndicator() {
        this.setState({
            ...this.state,
            loading: true
        });
    }

    hideIndicator() {
        this.setState({
            ...this.state,
            loading: false
        });
    }
    handleAlertDismiss(e) {
        this.setState({ 'showAlert': false });
    }
    handleAlertDismiss2(e) {
        this.setState({ 'showAlert2': false });
    }

    createClass() {

        let newClass = {};
        newClass.classID = "class_" + Math.random() * 10000000000000000000;
        let allNewClasses = [];
        allNewClasses.push(newClass);
        this.state.allClasses.forEach(className => {
            allNewClasses.push(className);
        });
        this.setState({ allClasses: allNewClasses });
    }

    importClass() {
        this.showIndicator();
        let that = this;
        var additionalParams = {};
        var pathTemplate = '/googleClassroom/getClasses';
        var method = 'POST';
        var additionalParams = { 'accessToken': localStorage.getItem('accessToken') };
        var body = { "userName": this.props.auth.user.userName, "auth": localStorage.getItem('auth2') };

        var params = {

        };
        let count = 0
        let classCount = 0
        apiClient.invokeApi({}, '/class/' + this.props.auth.user.userName, 'GET', {}, {}, '2').then(function (result) {
            let allClasses = result.data.allClasses;
            let occupiedPeriods = [];
            classCount = allClasses.length
            for (let p = 0; p < allClasses.length; p++) {
                if (allClasses[p] && !allClasses[p].courseId) {
                    count++
                }
            }

            if (count !== classCount) {
                apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, "7")
                    .then(function (result) {

                        that.getAllClasses();
                        that.getAllStudents();
                        that.setState({ alertText: "Classes Synced", alertStyle: 'success', showAlert: true });
                        setTimeout(() => {
                            that.setState({ showAlert: false });
                        }, 5000);
                        that.hideIndicator();
                    })
                    .catch(function (err) {
                        that.getAllClasses();
                        that.getAllStudents();
                        that.hideIndicator()
                        that.handleAlertDismiss()
                    });
            } else {
                if (count === 0 && classCount === 0) {
                    apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, "7")
                        .then(function (result) {

                            that.getAllClasses();
                            that.getAllStudents();
                            that.setState({ alertText: "Classes Synced", alertStyle: 'success', showAlert: true });
                            setTimeout(() => {
                                that.setState({ showAlert: false });
                            }, 5000);
                            that.hideIndicator();
                        })
                        .catch(function (err) {
                            that.getAllClasses();
                            that.getAllStudents();
                            that.hideIndicator()
                            that.setState({ alertText: "No classes found", alertStyle: 'danger', showAlert: true });
                            setTimeout(() => {
                                that.setState({ showAlert: false });
                            }, 5000);
                        });
                } else {
                    that.setState({ alertText: "You are not able to sync Google Classroom with LearnerPal if you have already set up classes in LearnerPal.  To use your Google classes in LearnerPal, you would have to delete the current LearnerPal classes from this window.  Please note, once these classes are deleted, you will no longer have access to these classes, or associated reports", alertStyle: 'success', showAlert: true });
                    setTimeout(() => {
                        that.setState({ showAlert: false });
                    }, 5000);
                    that.hideIndicator();
                }

            }

        }).catch(function (result) {

        });


    }

    getDeleteClassDialog() {
        let message = null;
        if (this.state.deleteStudent) {
            message = <h3>Are you sure, you want to remove this student?</h3>;
        }
        else if (this.state.deleteAllStudent) {
            message = <h3>Are you sure, you want to remove all student at once?</h3>;
        }
        else {
            message = <div>
                <h3>
                    By deleting this class, you will be deleting all students from this class along with their reporting
                </h3>
                <h3>
                    Are you sue you want to delete this class?
                </h3>
            </div>;
        }
        return <Modal
            show={this.state.showDeleteDialogBox}
            onHide={() => {
                this.setState({ showDeleteDialogBox: false });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Delete Class</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {message}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    this.setState({ showDeleteDialogBox: false });
                }}>Cancel</Button>
                <Button onClick={() => {
                    this.removeClass();
                }} bsStyle='primary'>Delete</Button>
            </Modal.Footer>
        </Modal>;
    }

    showDeleteDialog(event) {

        if (event.target.id === 'deleteStudent') {
            this.setState({
                enrolmentToDelete: event.target.dataset.enrollment, studentToDelete: event.target.dataset.studentid,
                showDeleteDialogBox: true, deleteStudent: true, deleteAllStudent: false
            });
        }
        else if (event.target.id === 'removeAll') {
            this.setState({ showDeleteDialogBox: true, deleteStudent: false, deleteAllStudent: true });
        }
        else {
            if (event.target.dataset.period && event.target.dataset.enrollment) {
                this.setState({
                    showDeleteDialogBox: true, classToDelete: event.target.id,
                    periodToRemove: event.target.dataset.period, enrolmentToDelete: event.target.dataset.enrollment
                });
            }
            else {

                let allClasses = this.state.allClasses;
                let index = allClasses.findIndex(x => x.classID === event.target.id);
                allClasses.splice(index, 1);
                this.setState({ allClasses: allClasses });
            }
        }
    }

    removeClass() {
        this.showIndicator();
        let that = this;

        if (this.state.deleteStudent) {
            apiClient.invokeApi({}, '/class/' + this.props.auth.user.userName + '/enrollmentcode/' + this.state.enrolmentToDelete + '/student/' + this.state.studentToDelete, 'DELETE', {}, {}, '2').then(function (result) {

                that.hideIndicator();
                let allClasses = that.state.allStudents;
                let index = allClasses.findIndex(x => x.userName === that.state.studentToDelete);
                allClasses.splice(index, 1);
                that.setState({
                    enrolmentToDelete: '', studentToDelete: '',
                    showDeleteDialogBox: false, deleteStudent: false, deleteAllStudent: false, showAlert: true, alertText: result.data, alertStyle: 'success'
                });
                setTimeout(() => {
                    that.setState({ ...that.state, showAlert: false });
                }, 5000);
            }).catch(function (result) {
                that.hideIndicator();
            });
        }
        else if (this.state.deleteAllStudent) {
            apiClient.invokeApi({}, '/class/' + this.props.auth.user.userName + '/enrollmentcode/' + this.state.enrolmentToDelete + '/students', 'DELETE', {}, {}, '2').then(function (result) {

                that.hideIndicator();
                that.getAllStudents();
            }).catch(function (result) {
                that.hideIndicator();
            });
        }
        else {
            apiClient.invokeApi({}, '/class/' + this.props.auth.user.userName + '/enrollmentcode/' + this.state.enrolmentToDelete, 'DELETE', {}, {}, '2').then(function (result) {

                ClevertapReact.event("Delete Class", { 'userName': that.props.auth.user.userName });
                that.hideIndicator();
                let allClasses = that.state.allClasses;
                let index = allClasses.findIndex(x => x.classID === that.state.classToDelete);
                allClasses.splice(index, 1);

                let allOccupiedPeriods = that.state.occupiedPeriods;
                let index2 = allOccupiedPeriods.findIndex(x => x === that.state.periodToRemove);
                allOccupiedPeriods.splice(index2, 1);
                that.setState({ allClasses: allClasses, occupiedPeriods: allOccupiedPeriods, showDeleteDialogBox: false, showAlert: true, alertText: result.data, alertStyle: 'success' });

                setTimeout(() => {
                    that.setState({ ...that.state, showAlert: false });
                }, 5000);
            }).catch(function (result) {
                that.hideIndicator();
            });
        }
    }

    getAllClasses() {
        this.showIndicator();
        let that = this;
        apiClient.invokeApi({}, '/class/' + this.props.auth.user.userName, 'GET', {}, {}, '2').then(function (result) {
            that.hideIndicator();
            let allClasses = result.data.allClasses;
            let occupiedPeriods = [];
            for (let p = 0; p < allClasses.length; p++) {
                allClasses[p].classID = "class_" + Math.random() * 10000000000000000000;
                occupiedPeriods.push(allClasses[p].period);
            }
            var gClassFlag = false;
            for (let i = 0; i < allClasses.length; i++) {
                if (allClasses[i].courseId) {
                    gClassFlag = true;
                    break;
                }
            }
            if (gClassFlag) {
                that.setState({ allClasses: allClasses, occupiedPeriods: occupiedPeriods, isGClassManagement: true });
            } else {
                that.setState({ allClasses: allClasses, occupiedPeriods: occupiedPeriods });
            }

        }).catch(function (result) {
            that.hideIndicator();
        });
    }

    onInputChange(e) {

        this.setState({ [e.target.name]: e.target.value });
        if (e.key === 'Enter') {
            this.showIndicator();

            let body = {
                userName: this.props.auth.user.userName,
                period: e.target.value,
                schoolID: this.props.auth.user.schoolID,
                firstName: this.props.auth.user.firstName,
                lastName: this.props.auth.user.lastName
            };
            let that = this;

            let clasID = e.target.id;
            let period = e.target.value;
            apiClient.invokeApi({}, '/class', 'POST', {}, body, '2').then(function (result) {
                that.hideIndicator();
                let tempAllClasses = that.state.allClasses;
                let index = tempAllClasses.findIndex(x => x.classID === clasID);
                tempAllClasses[index].period = period;
                tempAllClasses[index].enrollmentCode = result.data.enrollmentCode;
                that.setState({ dropdownValue: '', allClasses: tempAllClasses, occupiedPeriods: that.state.occupiedPeriods.concat(period), alertText: result.data.message, alertStyle: 'success', showAlert: true });
                setTimeout(() => {
                    that.setState({ ...that.state, showAlert: false });
                }, 5000);
            }).catch(function (result) {
                that.hideIndicator();
            });
        }
    }

    checkEnterKeyPress(e) {

        if (e.key === 'Enter') {
            this.onChange(e, true);
        }
    }

    onChange(event, skipcheck) {

        this.setState({ dropdownValue: event.target.value });
        let allPeriods = ["Period 0", "Period 1", "Period 2", "Period 3", "Period 4", "Period 5", "Period 6", "Period 7", "Period 8", "Period 9", "Period 10", "Period 11"];
        if (allPeriods.indexOf(event.target.value) > -1 || skipcheck) {
            this.showIndicator();

            let body = {
                userName: this.props.auth.user.userName,
                period: event.target.value,
                schoolID: this.props.auth.user.schoolID,
                firstName: this.props.auth.user.firstName,
                lastName: this.props.auth.user.lastName
            };
            let that = this;

            let clasID = event.target.id;
            let period = event.target.value;
            apiClient.invokeApi({}, '/class', 'POST', {}, body, '2').then(function (result) {
                that.hideIndicator();
                ClevertapReact.event("Create Class", { 'userName': that.props.auth.user.userName });
                let tempAllClasses = that.state.allClasses;
                let index = tempAllClasses.findIndex(x => x.classID === clasID);
                tempAllClasses[index].period = period;
                tempAllClasses[index].enrollmentCode = result.data.enrollmentCode;
                that.setState({ dropdownValue: '', allClasses: tempAllClasses, occupiedPeriods: that.state.occupiedPeriods.concat(period), alertText: result.data.message, alertStyle: 'success', showAlert: true });
                setTimeout(() => {
                    that.setState({ ...that.state, showAlert: false });
                }, 5000);
            }).catch(function (result) {
                that.hideIndicator();
            });
        }
    }

    toggleTabs(event) {
        if (event.target.id === 'classManagement') {
            this.setState({ isClassManagement: true });
        }
        else {
            this.setState({ isClassManagement: false, allStudentsSearchResult: this.state.allStudents });
        }
    }

    getAllStudents() {
        this.showIndicator();
        let that = this;
        apiClient.invokeApi({}, '/user/class/students/' + this.props.auth.user.userName, 'GET', {}, {}, '2').then(function (result) {
            that.hideIndicator();
            that.setState({ allStudents: result.data, allStudentsSearchResult: result.data, allStudentsFiltered: result.data });
        }).catch(function (result) {
            that.hideIndicator();
        });
    }

    searchStudents(event) {
        let searchTerm = event.target.value.toLowerCase();
        let searchResult = [];
        let data = this.state.allStudentsFiltered;
        if (searchTerm === "") {
            searchResult = data;
        }
        else {
            if (data.length > 0) {
                for (let i = 0; i < data.length; i++) {
                    let tempVarName = data[i].students.toLowerCase();
                    if (tempVarName.search(searchTerm) !== -1) {
                        searchResult.push(data[i]);
                    }
                }
            }
        }
        this.setState({ allStudentsSearchResult: searchResult, searchTerm: searchTerm });
    }

    filterStudents(event) {
        if (event.target.id === 'viewButton') {
            this.setState({ isClassManagement: false });
        }
        let searchTerm = event.target.value;
        let searchResult = [];
        let data = this.state.allStudents;
        if (searchTerm === "" || searchTerm === "all") {
            searchResult = data;
        }
        else {
            if (data.length > 0) {
                for (let i = 0; i < data.length; i++) {
                    let tempVarName = data[i].className.toLowerCase();
                    // if (tempVarName.search(searchTerm.toLowerCase()) !== -1) {
                    if (tempVarName === searchTerm.toLowerCase()) {
                        searchResult.push(data[i]);
                    }
                }
            }
        }
        this.setState({ allStudentsFiltered: searchResult, allStudentsSearchResult: searchResult, selectedPeriod: searchTerm });
    }

    editPeriodForStudent(event) {

        console.log(JSON.stringify(event));
        $('#displayBoxForClassName').addClass('removeDisplay');
        $('#selectBoxForClassName').removeClass('removeDisplay');
    }

    changeStudentClass(event) {

        let data = event.target.value.split(':');
        let data2 = event.target.name.split(':');
        let that = this;
        let body = {
            studentID: data2[0],
            oldEnrollmentCode: data2[1],
            newEnrollmentCode: data[0],
            className: data[1]
        };
        that.showIndicator();
        apiClient.invokeApi({}, '/student/update', 'PUT', {}, body, '3').then(function (result) {

            that.hideIndicator();
            $('#displayBoxForClassName').removeClass('removeDisplay');
            $('#selectBoxForClassName').addClass('removeDisplay');
            that.getAllStudents();
            that.setState({ alertText: result.data, alertStyle: 'success', showAlert: true });
            setTimeout(() => {
                that.setState({ showAlert: false });
            }, 5000);
        }).catch(function (result) {

            that.hideIndicator();
        });
    }

    render() {
        let allClassesHtml = [];
        let allStudentsHtml = [];
        let filterDropDownList = [];
        const browser = detect();

        if (this.state.isClassManagement) {
            for (let i = 0; i < this.state.allClasses.length; i++) {
                let allPeriods = [];
                let disableDropDown = false;
                if (this.state.allClasses[i].period) {
                    disableDropDown = true;
                }
                else {
                    for (let j = 0; j < 11; j++) {
                        if (this.state.occupiedPeriods.indexOf('Period ' + j.toString()) === -1) {
                            allPeriods.push({ id: 'Period ' + j.toString(), name: 'Period ' + j.toString() })
                        }
                    }
                }
                if (browser.name === 'safari' || browser.os.toLowerCase() === 'ios' || browser.name === 'crios') {
                    allPeriods.push({ id: 'Custom Period', name: 'Custom Period' })
                }
                allClassesHtml.push(<tr key={this.state.allClasses[i].classID}>
                    <td>
                        {!disableDropDown && <div>
                            {browser.name !== 'safari' && browser.os.toLowerCase() !== 'ios' && browser.name !== 'crios' && <AutoCompleteGroup
                                field="periodInfo"
                                placeholder="Select Period from dropdown or enter class name"
                                options={allPeriods}
                                onBlur={this.onChange}
                                onKeyPress={this.checkEnterKeyPress}
                                value={this.state.allClasses[i].period}
                                id={this.state.allClasses[i].classID}
                                isDisabled={disableDropDown}
                            />}
                            {(browser.name === 'safari' || browser.name === 'ios' || browser.name === 'crios') && disableDropDown &&
                                <input
                                    id={this.state.allClasses[i].classID}
                                    className="form-control"
                                    name="periodInfoText"
                                    placeholder="Enter custom period"
                                    onChange={this.onChange}
                                    onKeyPress={this.checkEnterKeyPress}
                                    value={this.state.allClasses[i].period}
                                    disabled={disableDropDown} />
                            }
                            {(browser.name === 'safari' || browser.name === 'ios' || browser.name === 'crios') && !disableDropDown &&
                                <SelectFieldGroup
                                    field="periodInfo"
                                    placeholder="Select Period from dropdown or enter class name"
                                    options={allPeriods}
                                    onChange={this.onChange}
                                    value={this.state.allClasses[i].period}
                                    dropDownId={this.state.allClasses[i].classID}
                                    isDisabled={disableDropDown}
                                />
                            }
                            {(browser.name === 'safari' || browser.name === 'ios' || browser.name === 'crios') && !disableDropDown && this.state.dropdownValue === 'Custom Period' &&
                                <input
                                    id={this.state.allClasses[i].classID}
                                    className="form-control"
                                    name="periodInfoText"
                                    placeholder="Enter custom period"
                                    onChange={this.onInputChange}
                                    onKeyPress={this.checkEnterKeyPress}
                                    value={this.state.allClasses[i].period}
                                    disabled={disableDropDown} />
                            }
                            {!disableDropDown && this.state.dropdownValue &&
                                <p style={{ color: 'maroon' }}>Press Enter To Save</p>
                            }
                        </div>}
                        {disableDropDown && this.state.allClasses[i].period}
                    </td>
                    <td>
                        {this.state.allClasses[i].enrollmentCode && this.state.allClasses[i].enrollmentCode}
                        {!this.state.allClasses[i].enrollmentCode && "--"}
                    </td>
                    <td>
                        {this.state.allClasses[i].enrollmentCode &&
                            <button className="ViewBtn" id="viewButton" value={this.state.allClasses[i].period}
                                onClick={this.filterStudents}> View </button>}
                        {!this.state.allClasses[i].enrollmentCode && <button className="ViewBtn"> View </button>}
                    </td>
                    {!this.state.isGClassManagement && <td>
                        {this.state.allClasses[i].enrollmentCode &&
                            <a href="#" className="rmvBtn" data-enrollment={this.state.allClasses[i].enrollmentCode} data-period={this.state.allClasses[i].period}
                                id={this.state.allClasses[i].classID} onClick={this.showDeleteDialog}>Remove</a>}
                        {!this.state.allClasses[i].enrollmentCode &&
                            <a href="#" className="rmvBtn" id={this.state.allClasses[i].classID} onClick={this.showDeleteDialog}>Remove</a>}
                    </td>}
                </tr>);
            }
        }
        else {
            let tempAllClasses = this.state.allClasses;
            let allPeriods = [];
            for (let i = 0; i < tempAllClasses.length; i++) {
                if (tempAllClasses[i].period) {
                    allPeriods.push({ id: tempAllClasses[i].enrollmentCode + ':' + tempAllClasses[i].period, name: tempAllClasses[i].period });
                }
            }

            let tempAllStudentsForDropDown = this.state.allStudents;
            filterDropDownList.push({ id: 'all', name: 'All' });
            for (let g = 0; g < tempAllStudentsForDropDown.length; g++) {
                let tempListObj = {};
                tempListObj.id = tempAllStudentsForDropDown[g].className;
                tempListObj.name = tempAllStudentsForDropDown[g].className;
                filterDropDownList.push(tempListObj);
            }

            let tempAllStudents = this.state.allStudentsSearchResult;

            for (let j = 0; j < tempAllStudents.length; j++) {
                allStudentsHtml.push(<tr key={Math.random()}>
                    <th>{tempAllStudents[j].stdFirstName + " " + tempAllStudents[j].stdLastName}</th>
                    <td>{tempAllStudents[j].students}</td>
                    <td>{tempAllStudents[j].password}</td>
                    <td>{tempAllStudents[j].userEmail}</td>
                    <td>
                        <div id={"selectBoxForClassName" + tempAllStudents[j].students} className="posRel selectWidth periodsField removeDisplay">
                            <SelectFieldGroup
                                field={tempAllStudents[j].students + ':' + tempAllStudents[j].enrollmentCode}
                                value={this.state.selectedPeriodForStudent}
                                options={allPeriods}
                                placeholder="Move to class"
                                onChange={(event) => {
                                    let data = event.target.value.split(':');
                                    let data2 = event.target.name.split(':');
                                    let that = this;
                                    let body = {
                                        studentID: data2[0],
                                        oldEnrollmentCode: data2[1],
                                        newEnrollmentCode: data[0],
                                        className: data[1]
                                    };
                                    that.showIndicator();
                                    apiClient.invokeApi({}, '/student/update', 'PUT', {}, body, '3').then(function (result) {

                                        that.hideIndicator();
                                        $('#displayBoxForClassName' + tempAllStudents[j].students).removeClass('removeDisplay');
                                        $('#selectBoxForClassName' + tempAllStudents[j].students).addClass('removeDisplay');
                                        that.getAllStudents();
                                        that.setState({ alertText: result.data, alertStyle: 'success', showAlert: true });
                                        setTimeout(() => {
                                            that.setState({ showAlert: false });
                                        }, 5000);
                                    }).catch(function (result) {

                                        that.hideIndicator();
                                    });

                                }}
                            />
                        </div>
                        <p id={"displayBoxForClassName" + tempAllStudents[j].students} className="editPeriodText " onClick={() => {
                            $('#displayBoxForClassName' + tempAllStudents[j].students).addClass('removeDisplay');
                            $('#selectBoxForClassName' + tempAllStudents[j].students).removeClass('removeDisplay');
                        }}>{tempAllStudents[j].className}<span /></p>
                    </td>
                    {!this.state.isGClassManagement && <td className="text-right"><a href="javascript:;" className="rmvBtn" id="deleteStudent" data-enrollment={tempAllStudents[j].enrollmentCode}
                        data-studentid={tempAllStudents[j].userName} onClick={this.showDeleteDialog}>Remove</a></td>}
                </tr>)
            }
        }
        return (
            <div>
                {this.getDeleteClassDialog()}
                {!this.state.isGClassManagement && <Heading style={{ marginTop: '!important', fontWeight: "400 !important", width: "600px !important", marginLeft: "30px !important", fontFamily: "serif !important" }} headingClass={"smallHeading"} heading="Add new class and generate enrollment code for your Students"></Heading>}

                {this.state.isGClassManagement && <Heading style={{ marginTop: "5px", fontWeight: "400", width: "600px", marginLeft: "30px", fontFamily: "serif" }} headingClass={"smallHeading"} heading="Your classes are automatically synced with your Google Classroom every time you log into LearnerPal"></Heading>}
                <div className="container-fluid  grey-background">
                    <div className="mainFormBox manageClassTable" id="manageStudentBox">
                        <div className="row introRow">
                            <div className="col-md-4 col-sm-5 col-xs-12">
                                <ul >
                                    <li><span id="classManagement" className={classnames("", { "active": this.state.isClassManagement })} onClick={this.toggleTabs}>Manage Classes</span></li>
                                    <li> | </li>
                                    <li><span id="manageStudents" className={classnames("", { "active": !this.state.isClassManagement })} onClick={this.toggleTabs}>Manage Students</span></li>
                                </ul>
                            </div>
                            {this.state.isClassManagement &&
                                <div className="col-md-8 col-sm-7 text-right  col-xs-12">
                                    <a className="actionButtonclass" style={{ marginTop: "8px", top: "-3px", transform: 'scale(0.7, 0.7)', webkitTransform: 'scale(0.7, 0.7)' }}>
                                        <span style={{ left: "-525px", top: "0px", zIndex: '99' }} className="WHSign">
                                            {/* <span className="tooltiptext"> */}
                                            {!this.state.isGClassManagement && <div>1- To create new class, click on the Create New Class button at the top of the page</div> && <br />}

                                            {!this.state.isGClassManagement && <div>2- Once the Period/Class field appears, select a period from the dropdown or enter a class name and an enrollment code will be generated.</div> && <br />}
                                            1- Once the Period/Class field appears, select a period from the dropdown or enter a class name and an enrollment code will be generated.<br />
                                            {!this.state.isGClassManagement && <div>3- Give the enrollment codes out to your Students relative to their class that they are in.</div> && <br />}
                                            2- Give the enrollment codes out to your Students relative to their class that they are in.
                                  {/* </span> */}
                                        </span>
                                    </a>
                                    {this.props.auth.user.federatedIdentity && <button className="removeAllBtn" onClick={this.importClass} style={{ marginTop: "10px", marginRight: "5px" }}>Sync Google Class</button>}

                                    {!this.state.isGClassManagement && <button className="removeAllBtn" onClick={this.createClass} style={{ marginTop: "10px" }}>Create New Class</button>}
                                    {!this.state.isGClassManagement && <button className="removeAllBtn deleteBtn" style={{ marginTop: "10px" }} onClick={() => {
                                        this.setState({ showAllDeleteDialog: true, deleteFrom: "classes" })
                                    }}>Delete All</button>}
                                </div>}
                            {!this.state.isClassManagement && <div className="col-md-8 col-sm-7 text-right">
                                <div className="posRel" style={{ paddingRight: '70px', width: '300px' }}>
                                    <TextFieldGroup
                                        field="studentsSeachBox"
                                        value={this.state.searchTerm}
                                        placeholder="Search all Students"
                                        selectWidth={false}
                                        onChange={this.searchStudents}
                                    />
                                </div>
                                <div className="posRel selectWidth m-r-10" style={{ marginLeft: '10px' }}>
                                    <SelectFieldGroup
                                        field="selectPeriod"
                                        value={this.state.selectedPeriod}
                                        options={filterDropDownList}
                                        placeholder="Search by Period"
                                        onChange={this.filterStudents}
                                    />
                                </div>
                                {!this.state.isGClassManagement && <button className="removeAllBtn DeleteButton" onClick={() => {
                                    this.setState({ showAllDeleteDialog: true, deleteFrom: "students" })
                                }}>Delete All</button>}
                            </div>}
                        </div>
                        {this.state.isClassManagement && <div className="respScrollTable">
                            <table className="table manageclassNameTable" style={{ border: '1px', solid: '#ccc' }}>
                                <thead>
                                    <tr>
                                        <th style={{ width: "25%" }}><p className="limitTextRes">Period</p></th>
                                        <th><p className="limitTextRes">Enrollment Code  </p></th>
                                        <th><p className="limitTextRes">Students  </p></th>
                                        <th>&nbsp; </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allClassesHtml}
                                </tbody>
                            </table>
                        </div>}
                        {!this.state.isClassManagement && <div className="respScrollTable">
                            <table className="table manageClassTable" style={{ border: '1px', solid: '#ccc' }} >
                                <thead>
                                    <tr>
                                        <th style={{ width: '20%' }} ><p className="limitTextRes">Name  </p></th>
                                        <th ><p className="limitTextRes">Username </p></th>
                                        <th><p className="limitTextRes">Password  </p></th>
                                        <th style={{ width: '20%' }}><p className="limitTextRes">Email  </p></th>
                                        <th><p className="limitTextRes">Class  </p></th>
                                        {/*<th className="text-right"><button className="removeAllBtn" id="removeAll"  onClick={this.showDeleteDialog}> Remove all </button></th>*/}
                                    </tr>
                                </thead>
                                <tbody>
                                    {allStudentsHtml}
                                </tbody>
                            </table>
                        </div>}
                    </div>
                </div>
                <ProgressIndicator show={this.state.loading} />

                {this.state.showAlert2 && this.props.auth.user.federatedIdentity && this.state.isGClassManagement &&
                    <div className="row">
                        <div className="col-md-3 col-xs-3" />
                        <div className="col-md-6 col-xs-3">
                            <Alert bsStyle={"warning"}
                                onDismiss={this.handleAlertDismiss2}>
                                Please note your google classes may take up to one minute to load
                            </Alert>
                            {setTimeout(() => {
                                this.setState({ 'showAlert2': false });
                            }, 30000)}

                        </div>
                        <div className="col-md-3 col-xs-3" />
                    </div>
                }
                {this.state.showAlert &&
                    <div className="row">
                        <div className="col-md-3 col-xs-3" />
                        <div className="col-md-6 col-xs-3">
                            <Alert bsStyle={this.state.alertStyle}
                                onDismiss={this.handleAlertDismiss}>
                                {this.state.alertText}
                            </Alert>

                        </div>
                        <div className="col-md-3 col-xs-3" />
                    </div>
                }
                {this.allDeleteDialog()}
            </div>
        );
    }


    deleteAllStudents() {

        let context = this;
        context.showIndicator();
        let params = {
            teacher: context.props.auth.user.userName,
        };
        let pathTemplate = '/DeleteAllStudents/{teacher}';
        let method = 'DELETE';
        let additionalParams = {};
        let body = {};

        apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, '5').then((result) => {

            console.log(result)

            // const {dispatch} = this.props;

            // dispatch({
            //     type: RECEIVED_ASSIGNMENT_RESULTS,
            //     status: 200,
            //     data: JSON.parse(JSON.stringify([])),
            //     alertText: '',
            //     alertStyle: '',
            //     showAlert: false,
            //     totalRecords: 0,
            //     clearFilters: true
            // });

            context.setState({
                allStudentsSearchResult: [],
                showAlert: true,
                alertStyle: 'success',
                alertText: result.data
                // alertText: 'Unable to load data, please contact administrator.'
            }, context.hideIndicator);

        }).catch((error) => {

            let responseData = [];
            let combinedMessage = '';
            if (error.response && error.response.data.errors) {
                for (let err of error.response.data.errors)
                    responseData.push(err.message)

                combinedMessage = responseData.join(', ')
            }

            context.setState({
                showAlert: true,
                alertStyle: 'danger',
                alertText: responseData.length != 0 && combinedMessage ? combinedMessage : error.message
                // alertText: 'Unable to load data, please contact administrator.'
            }, context.hideIndicator);
            // setTimeout(() => {
            //     context.setState({
            //         showAlert: false,
            //         alertStyle: '',
            //         alertText: ''
            //     })
            // }, 4000);
        });

    }


    deleteAllClasses() {

        let context = this;
        context.showIndicator();
        let params = {
            teacher: context.props.auth.user.userName,
        };
        let pathTemplate = '/DeleteAllClasses/{teacher}';
        let method = 'DELETE';
        let additionalParams = {};
        let body = {};

        apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, '5').then((result) => {

            console.log(result)

            // const {dispatch} = this.props;

            // dispatch({
            //     type: RECEIVED_ASSIGNMENT_RESULTS,
            //     status: 200,
            //     data: JSON.parse(JSON.stringify([])),
            //     alertText: '',
            //     alertStyle: '',
            //     showAlert: false,
            //     totalRecords: 0,
            //     clearFilters: true
            // });

            context.setState({
                allStudentsSearchResult: [],
                allClasses: [],
                showAlert: true,
                alertStyle: 'success',
                alertText: result.data
                // alertText: 'Unable to load data, please contact administrator.'
            }, context.hideIndicator);

        }).catch((error) => {

            let responseData = [];
            let combinedMessage = '';
            if (error.response && error.response.data.errors) {
                for (let err of error.response.data.errors)
                    responseData.push(err.message)

                combinedMessage = responseData.join(', ')
            }

            context.setState({
                showAlert: true,
                alertStyle: 'danger',
                alertText: responseData.length != 0 && combinedMessage ? combinedMessage : error.message
                // alertText: 'Unable to load data, please contact administrator.'
            }, context.hideIndicator);
            // setTimeout(() => {
            //     context.setState({
            //         showAlert: false,
            //         alertStyle: '',
            //         alertText: ''
            //     })
            // }, 4000);
        });

    }

    allDeleteDialog() {
        let body = <div id="deleteDialogContent">
            <p>
                Are you sure you want to delete all the {this.state.deleteFrom == "classes" ? "classes? All students, assignments and" : "students? All assignments and"} reporting data will be lost.
            </p>
            {/* {this.state.deleteError ? <Alert bsStyle="danger">
                {this.state.deleteErrorMessage}
            </Alert> : ''} */}
        </div>;
        return <Modal
            backdrop="static"
            show={this.state.showAllDeleteDialog}
            onHide={() => {
                this.setState({ showAllDeleteDialog: false/*, deleteError: false*/ });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Delete All {this.state.deleteFrom == "classes" ? "Classes" : "Students"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Button style={{ marginRight: "5px" }} onClick={() => {
                    this.setState({ showAllDeleteDialog: false/*, deleteError: false*/ });
                }}>Cancel</Button>
                <Button onClick={() => {
                    this.setState({ showAllDeleteDialog: false/*, deleteError: false*/ }, () => {
                        { this.state.deleteFrom == "classes" ? this.deleteAllClasses() : this.deleteAllStudents() }
                    });
                    // this.setState({deleteError: true, deleteErrorMessage: error.statusMessage});
                }} bsStyle='danger'>Delete</Button>
            </Modal.Footer>
        </Modal>;
    }

}

function mapStateToProps(state) {
    return { ...state };
}

export default connect(mapStateToProps)(ManageStudents);
