
import React, { Component } from 'react';
import EditorComponent from '../common/editor-component';
import { Alert, Button, Modal } from 'react-bootstrap'
import { Parser } from "html-to-react";
import Breadcrumb from "../breadcrumb/breadcrumb";
import './style.css';
import { browserHistory } from "react-router";
import { connect } from "react-redux";
import apiClient from "../../actions/api-client";
import $ from 'jquery';
import SelectFieldGroup from "../forms/select-field-group";
import AutoCompleteGroup from "../forms/auto-complete-group";
import ProgressIndicator from "../progress-indicator/progress-indicator";
import { debug } from 'util';
//import ReactTags from 'react-tag-autocomplete';
import InputTags from '../InputTag';


import {
    CREATE_QUESTION,
    MODIFY_QUESTION,
    RECEIVED_QUESTIONS_RESULTS,
    ElasticSearchTypes,
    ElasticSearchColumns,
    CLONE_QUESTION,
    SET_CURRENT_USER
} from "../../actions/types";
import ElasticSearchQuery from "../../actions/elastic-search-query";
import config from "../../config";
// import axios from "axios";
import TextFieldGroup from "../forms/text-field-group";
import placeHolderImage from "./placeImageHere.png";
import { s3Upload } from '../activities/uploader/s3-uploader';

class NewQuestion extends Component {

    constructor(props) {
        super(props);

        this.onNewQuestionSubmit = this.onNewQuestionSubmit.bind(this);
        this.onModifyQuestionSubmit = this.onModifyQuestionSubmit.bind(this);
        this.updateHTMLContent = this.updateHTMLContent.bind(this);
        this.onChange = this.onChange.bind(this);
        this.getAllSubjects = this.getAllSubjects.bind(this);
        this.inputTags = this.inputTags.bind(this);
        this.deleteTags = this.deleteTags.bind(this);
        this.handleAlertDismiss = this.handleAlertDismiss.bind(this);
        this.previewQuestion = this.previewQuestion.bind(this);
        this.requestElasticSearch = this.requestElasticSearch.bind(this);
        this.AddMoreRecords = this.AddMoreRecords.bind(this);
        this.propertyChangedHandler = this.propertyChangedHandler.bind(this);
        this.onAddCustomChanged = this.onAddCustomChanged.bind(this);
        this.removeImage = this.removeImage.bind(this);
        this.addTags = this.addTags.bind(this);


        this.state = {
            showCreateQuestionDialogBox: false,
            showPreviewDialogBox: false,

            selectedDialog: "",
            question: '',
            hint: '',
            explanation: '',
            options: '',
            notes: '',
            isMultipleChoice: true,
            allSubjects: [],
            errors: {},

            questionState: 'In Progress',
            questionType: 'Multiple Choice',
            category: '',
            subCategory: '',
            questionTemplate: 'Normal',
            allCategories: [],
            allSubCategories: [],
            subjectsHtml: [], subjects: [],
            taggedKeywordsHtml: [], taggedKeywords: [],
            error: {},
            tempContent: '',

            alertText: '',
            showAlert: false,
            alertStyle: "danger",
            addedControlsCount: 0,
            addMoreHtmlArray: [],
            showCustomizedSubjectPopup: false,
            showCustomizedEntryPopupForSubject: false,
            showCustomizedEntryForCategory: false,
            showCustomizedEntryForSubCategory: false,
            customSubject: "",
            customCategory: "",
            customSubCategory: "",
            customEntryForID: 0,


            customSubjects: [],
            customCategories: [{ "id": "Add Custom", "name": "Add Custom", isCustom: true }],
            customSubCategories: [{ "id": "Add Custom", "name": "Add Custom", isCustom: true }],
            selectedSubject: "",
            selectedSchoolType: [],
            commonCoreIndicators: []
        };

        this.breadCrumbCurrentPage = "New Question";
        this.isModify = false
        let qd = null;
        let filter = [];

        if (this.props.questionData) {
            let questionData = this.props.questionData;
            this.state.questionType = questionData.questionType
            this.state.isMultipleChoice = questionData.questionType == "Multiple Choice"
            this.state.subjects = questionData.subjects;
            this.state.category = questionData.category;
            this.state.subCategory = questionData.subcategory;

            questionData.choices = [];
            this.state.question = questionData.question;
            this.state.hint = questionData.hint;
            this.state.explanation = questionData.explanation;
            this.state.options = questionData.choices;
            this.state.notes = questionData.notes;
            // this.state.tags = questionData.tags;
            this.state.questionID = questionData.questionID;


            this.state.selectedSubject = this.state.subjects[0];
        }

        this.isClone = false;




        if (this.props.isModify) {
            if (this.props.manageQuestions && this.props.manageQuestions.questionData) {

                this.isModify = true;
                this.breadCrumbCurrentPage = "Modify Question";

                qd = this.props.manageQuestions.questionData;
            }
        } else if (this.props.isClone) {

            this.isClone = true;
            let isClone = this.props.manageQuestions && this.props.manageQuestions.cloneQuestionData;
            if (isClone) {
                this.breadCrumbCurrentPage = "Clone Question";
                this.isModify = false;

                qd = this.props.manageQuestions.cloneQuestionData;
            }
        }

        if (qd) {
            this.state.answer = qd.answer;
            this.state.subjects = qd.subjects;
            this.state.question = qd.question;
            this.state.hint = qd.hint;
            this.state.explanation = qd.explanation;
            this.state.options = qd.choices;
            this.state.notes = qd.notes;
            // this.state.tags = qd.tags;
            this.state.isMultipleChoice = qd.choices && qd.choices.length > 0;

            this.state.questionType = qd.questionType;
            this.state.selectedSubject = qd.subjects[0];

            //if question is not multiple choice and questiontype is not defined, then set it as essay.
            if (!this.state.isMultipleChoice && qd.questionType == undefined) {
                this.state.questionType = 'essay';
            }

            this.state.questionID = qd.questionID;
            this.state.category = qd.category;
            this.state.subCategory = qd.subcategory;

            if (qd.questionState) {
                this.state.questionState = qd.questionState;
            } else {
                this.state.questionState = "Published"
            }

            if (qd.questionTemplate) {
                this.state.questionTemplate = qd.questionTemplate;
            }

        }

        if (this.props.isModify) {
            this.state.customSubjects = this.props.customSubjects
            this.requestElasticSearch(this.state.subjects, "");
            this.requestElasticSearch(this.state.subjects, this.state.category);
        }

        // if questionstate is null or empty and either we are openning for clone or modification,
        // we need to set the state to Published
        if ((!this.state.questionState) && (this.isClone || this.isModify)) {
            this.state.questionState = "Published"
        }

        // if user is duplicating the question state should be 'in progress'
        // if (this.isClone && this.props.auth.user.schoolName === "LearnerPal") {
        //     this.state.questionState = "In Progress"
        // }
    }

    componentDidMount() {

        let id = null;
        if (!this.isModify)
            id = 1;

        // providing id so that can identify the propery whether it's just allSubjects/allCategory or allCategory1
        this.getAllSubjects(id);

        this.AddMoreRecords();
        if ((this.props.manageQuestions && this.props.manageQuestions.questionData)
            || (this.props.manageQuestions && this.props.manageQuestions.cloneQuestionData)) {

            if (this.state.isMultipleChoice && this.state.options) {
                for (var chkCount = 0; chkCount < this.state.options.length; chkCount++) {
                    var charName = String.fromCharCode(65 + chkCount);
                    var editorName = "OptionEditor" + "  " + charName;
                    var choiceElement = document.getElementById(editorName);
                    if (choiceElement) {
                        choiceElement.innerHTML = this.state.options[chkCount];

                        if (this.state.options[chkCount] == this.state.answer) {

                            var answerCheckBox = document.getElementById("choice" + chkCount);
                            if (answerCheckBox) {
                                answerCheckBox.checked = true;
                            }
                        }
                    }
                }
            }

            var hintElement = document.getElementById("Hint");
            if (hintElement && this.state.hint && this.state.hint !== '') {
                hintElement.innerHTML = this.state.hint;
            }

            var explanationElement = document.getElementById("Explanation");
            if (explanationElement && this.state.explanation && this.state.explanation !== '') {
                explanationElement.innerHTML = this.state.explanation.replace('autoplay', '');
            }

            var notesElement = document.getElementById("notes");
            if (notesElement && this.state.notes && this.state.notes !== '') {
                notesElement.innerHTML = this.state.notes;
            }

            if (this.state.subjects) {
                let htmlSubjects = []
                for (let i = 0; i < this.state.subjects.length; i++) {
                    htmlSubjects.push(this.getHtmlForTagSubjects(this.state.subjects[i]));
                }
                this.setState({ subjectsHtml: htmlSubjects });

                // if (this.isModify) {
                //     this.requestElasticSearch(this.state.subjects, "");
                //     this.requestElasticSearch(this.state.subjects, this.state.category);
                // } else {
                //     this.requestElasticSearchForMultipleControls(1, this.state.subjects[0], "");
                //     this.requestElasticSearchForMultipleControls(1, this.state.subjects[0], this.state.category);
                // }



            }
        }

        if (!this.isModify) {
            this.requestElasticSearchForMultipleControls(1, this.state.subjects[0], "");
            this.requestElasticSearchForMultipleControls(1, this.state.subjects[0], this.state.category);
        }
    }

    addAutoplayTag(questionData) {

        if (questionData.question) {
            questionData.question = questionData.question.toString().replace(/<audio/gi, '<audio autoplay');
            questionData.question = questionData.question.toString().replace(/<video/gi, '<video autoplay');
        }

        if (questionData.choices) {

            for (let i = 0; i < questionData.choices.length; i++) {
                if (questionData.choices[i]) {
                    questionData.choices[i] = questionData.choices[i].toString().replace(/<audio/gi, '<audio autoplay');
                    questionData.choices[i] = questionData.choices[i].toString().replace(/<video/gi, '<video autoplay');
                }
            }
        }

        if (questionData.hint) {
            questionData.hint = questionData.hint.toString().replace(/<audio/gi, '<audio autoplay');
            questionData.hint = questionData.hint.toString().replace(/<video/gi, '<video autoplay');
        }

        if (questionData.explanation) {
            questionData.explanation = questionData.explanation.toString().replace(/<audio/gi, '<audio autoplay');
            questionData.explanation = questionData.explanation.toString().replace(/<video/gi, '<video autoplay');
        }

    }

    createQuestionDialog() {
        //
        // let question = this.getQuestionData();
        // let quesText = question.question;

        // let choicesText = "";
        // let showChoices = quesText ? quesText.indexOf('LP_ANSWERS') < 0 : false;
        // if (question.choices) {
        //     for (let j = 1; j < question.choices.length + 1; j++) {
        //
        //         let choice = question.choices[j - 1];
        //         if (choice.toLowerCase() === question.answer.toLowerCase()) {
        //             choicesText += "<div class='fieldBox'><input type='radio' class='css-checkbox css-label1 bc_info card_side' value='choice" + j + "' name='choice" + j + "' checked/><label htmlFor='choice" + j + "' class='css-label radGroup1' >" + j + ") &nbsp; " + choice + "</label></div>"
        //         }
        //         else {
        //             choicesText += "<div class='fieldBox'><input type='radio' class='css-checkbox css-label1 bc_info card_side' value='choice" + j + "' name='choice" + j + "' /><label htmlFor='choice" + j + "' class='css-label radGroup1' >" + j + ") &nbsp; " + choice + "</label></div>"
        //         }
        //     }
        // }
        // quesText = quesText ? quesText.replace('[[[LP_ANSWERS]]]', choicesText) : "";
        // let expln = question.explanation ? question.explanation : "";
        // let htmlToReactParser = new Parser();
        //

        let editorContent = this.state.latestContent;


        var selectedElement = document.getElementById(this.state.selectedDialog);
        if (selectedElement) {
            editorContent = selectedElement.innerHTML;
        } else {
            editorContent = null;
        }

        let that = this;
        let params = {
            updateContent: function (data) {

                this.setState({
                    tempContent: data
                });

            }.bind(this)
        };

        return <Modal
            show={this.state.showCreateQuestionDialogBox}
            id="createQuestion"
            onHide={() => {
                this.setState({ showCreateQuestionDialogBox: false });
            }}

            container={this}
            aria-labelledby="new-question-modal-title"
            id={"editor"}>

            <Modal.Header closeButton>
                <Modal.Title id="new-question-modal-title">{this.state.selectedDialog}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <EditorComponent editorHeight='50vh' isModal='true' params={params} HTMLContent={editorContent} editorId={'editorPopup'} selectedDialog={this.state.selectedDialog} />

                {/* if ENTER_BR is optional not global*/}
                {/* <EditorComponent editorHeight='50vh' isModal='true' newLineStyle={newLineStyle} params={params} HTMLContent={editorContent} editorId={'editorPopup'} /> */}
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={() => {
                    this.setState({ showCreateQuestionDialogBox: false });
                }}>Cancel</Button>
                <Button onClick={this.updateHTMLContent} bsStyle='primary'>Done</Button>
            </Modal.Footer>

        </Modal>;
    }

    removeTag = (i) => {
        const newTags = [...this.state.commonCoreIndicators];
        newTags.splice(i, 1);
        this.setState({ commonCoreIndicators: newTags });
    }

    previewDialogBox() {

        let question = this.getQuestionData();
        let quesText = question.question;
        let choicesText = "";
        let showChoices = quesText ? quesText.indexOf('LP_ANSWERS') < 0 : false;
        if (question.choices) {
            for (let j = 1; j < question.choices.length + 1; j++) {


                let choice = question.choices[j - 1];
                if (choice.toLowerCase() === question.answer.toLowerCase()) {
                    choicesText += "<div class='fieldBox spacer'><input type='radio' class='css-checkbox css-label1 bc_info card_side' value='choice" + j + "' name='choice" + j + "' checked/><label htmlFor='choice" + j + "' class='css-label c-handler radGroup1' >" + j + ") &nbsp;</label>" + `<span class='text-fix' dir lang=${this.props.auth.user.prefLanguage} >${question.choices[j - 1]}</span>` + "</div>"
                    // "<div class='fieldBox'><input type='radio' class='css-checkbox css-label1 bc_info card_side' value='choice" + j + "' name='choice" + j + "' checked/><label htmlFor='choice" + j + "' class='css-label radGroup1 space-align' >" + j + ") &nbsp;</label>" + choice + "</div>"
                }
                else {
                    choicesText += "<div class='fieldBox spacer'><input type='radio' class='css-checkbox css-label1 bc_info card_side' value='choice" + j + "' name='choice" + j + "' /><label htmlFor='choice" + j + "' class='css-label c-handler radGroup1' >" + j + ") &nbsp;</label>" + `<span class='text-fix' dir lang=${this.props.auth.user.prefLanguage} >${question.choices[j - 1]}</span>` + "</div>"
                    // "<div class='fieldBox'><input type='radio' class='css-checkbox css-label1 bc_info card_side' value='choice" + j + "' name='choice" + j + "' /><label htmlFor='choice" + j + "' class='css-label radGroup1 space-align'>" + j + ") &nbsp;</label>" + choice + "</div>"
                }
            }
        }
        quesText = quesText ? quesText.replace('[[[LP_ANSWERS]]]', choicesText) : "";
        let expln = question.explanation ? question.explanation : "";
        let htmlToReactParser = new Parser();

        let panel = <div className="innerQuestionBox NiceScroll" style={{ display: 'block' }}>
            <span className="cross" onClick={this.hideViewQuestionPage} />
            <h4>Question:</h4>
            <p className="space-align2">{htmlToReactParser.parse(quesText)}</p>
            <div className="question-and-choice">
                {showChoices &&
                    <p>{htmlToReactParser.parse(choicesText)}</p>
                }
            </div>
            <h4>Hint</h4>
            <p>{htmlToReactParser.parse(question.hint)}</p>
            <h4>Explanation: </h4>
            <p>{htmlToReactParser.parse(expln.replace('autoplay', ''))}</p>
        </div>;


        let sideViewPanel = <div className="topRowHaedingAnch">
            <span className="scrollBar" />
            {panel}
        </div>


        return <Modal
            show={this.state.showPreviewDialogBox}
            id='createQuestion'
            onHide={() => {
                this.setState({ showPreviewDialogBox: false });
            }}

            container={this}
            //aria-labelledby="new-question-modal-title"
            id="QuestionPreviewModal"

            aria-labelledby="contained-modal-title">

            <Modal.Header closeButton>
                <Modal.Title id="new-question-modal-title">Question Preview</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {/*sideViewPanel*/}

                <div id="questionPreviewBox">
                    {sideViewPanel}
                    <br /><br />

                    <div className="row"></div>
                </div>

            </Modal.Body>

            <Modal.Footer>
                {/* <Button className="btn btn-primary crtBtn" onClick={() => {
                    this.setState({ showPreviewDialogBox: false });
                }}>OK</Button>
                <Button onClick={() => {
                    this.setState({ showPreviewDialogBox: false });
                }}>Cancel</Button> */}
            </Modal.Footer>

        </Modal>;
    }

    updateHTMLContent() {
        var selectedElement = document.getElementById(this.state.selectedDialog);

        let htmlData = this.state.tempContent;
        if (!htmlData)
            htmlData = "";

        if (selectedElement) {
            selectedElement.innerHTML = htmlData;
        }

        this.setState({ showCreateQuestionDialogBox: false });
    }

    openChoicesChecks(currentCheck) {
        var tempContent = "";
        var selectedElement = document.getElementById(currentCheck);
        if (selectedElement) {
            tempContent = selectedElement.innerHTML;
        }

        this.setState({ latestContent: "", tempContent: tempContent, selectedDialog: currentCheck, showCreateQuestionDialogBox: true })
    }

    getQuestionData() {

        let questionData = {};
        if (this.state.questionType)
            questionData.questionType = this.state.questionType;

        if (this.isModify) {

            if (this.state.selectedSubject)
                questionData.subjects = Array.isArray(this.state.selectedSubject) ? this.state.selectedSubject : [this.state.selectedSubject];
            // if (this.state.subjects)
            //     questionData.subjects = this.state.subjects;
            if (this.state.category)
                questionData.category = this.state.category;
            if (this.state.subCategory)
                questionData.subcategory = this.state.subCategory;
        } else {

            var subjects = [];
            var categories = [];
            var subcategories = [];

            this.state.addMoreHtmlArray.forEach(html => {
                subjects.push(this.state["selectedSubject" + html.key]);
                categories.push(this.state["selectedCategory" + html.key]);
                subcategories.push(this.state["selectedSubCategory" + html.key]);

            });

            // for (var html in this.state.addMoreHtmlArray) {
            //     subjects.push(this.state["selectedSubject" + html.key]);
            //     categories.push(this.state["selectedCategory" + html.key]);
            //     subcategories.push(this.state["selectedSubCategory" + html.key]);
            // }


            questionData.subjects = subjects;
            questionData.category = categories;
            questionData.subcategory = subcategories;

        }
        if (this.state.question)
            questionData.question = this.state.question;

        // it's create scenario
        // if (!this.isModify && !this.isClone && this.state.selectedImage) {

        // }
        if (this.state.selectedImage) {

            questionData.question = questionData.question + (questionData.question.indexOf('LP_ANSWERS') > -1 ?
                `<table style="width: 100%;">
                        <tbody>
                            <tr>
                                <td style="width: 50.0000%;"><img src="`+ this.state.selectedImage + `"/><br></td>
                            </tr>
                        </tbody>
                    </table>`
                :
                `<table style="width: 100%;">
                        <tbody>
                            <tr>
                                <td style="width: 50.0000%;">[[[LP_ANSWERS]]]</td>
                                <td style="width: 50.0000%;"><img src="`+ this.state.selectedImage + `"/><br></td>
                            </tr>
                        </tbody>
                    </table>`);
        }
        if (this.state.questionTemplate)
            questionData.questionTemplate = this.state.questionTemplate;
        if (this.props.auth.user.userName)
            questionData.ownerName = this.props.auth.user.userName;

        let schoolName = this.props.auth.user.schoolName ? this.props.auth.user.schoolName : 'trial';

        if (schoolName)
            questionData.schoolName = schoolName;

        if (schoolName === "LearnerPal" || (schoolName === "LearnerPal" && this.props.auth.user.userType.toLowerCase() === "admin")) {
            questionData.questionState = this.state.questionState;
        }

        if (this.state.questionID)
            questionData.questionID = this.state.questionID;

        if (this.refs.explanation && this.refs.explanation.innerHTML)
            questionData.explanation = this.refs.explanation.innerHTML;
        if (this.refs.hint && this.refs.hint.innerHTML)
            questionData.hint = this.refs.hint.innerHTML;
        if (this.refs.notes && this.refs.notes.innerHTML)
            questionData.notes = this.refs.notes.innerHTML;
        // ;
        if (this.state.commonCoreIndicators) {
            questionData.commonCoreIndicators = this.state.commonCoreIndicators;
        }

        //step1: need to compile all the question elements in one object
        if (this.state.isMultipleChoice) {

            let choices = []
            let answer = ""

            for (var chkCount = 0; chkCount < 7; chkCount++) {

                // should be "OptionEditor_" .. _= A/B/C
                var choiceEditorId = "OptionEditor" + "  " + String.fromCharCode(65 + chkCount);

                // take out the editor div element and push in the choices list
                var choiceElement = document.getElementById(choiceEditorId);
                var withoutbreaks = choiceElement.innerHTML.replace(/<br>/g, "");
                if (withoutbreaks && choiceElement && choiceElement.innerHTML != "") {

                    let choiceText = choiceElement.innerHTML;
                    // check if the checkbox is checked? if then set the value as the correct answer
                    var answerCheckBox = document.getElementById("choice" + chkCount);
                    if (answerCheckBox && answerCheckBox.checked)
                        answer = choiceText;

                    choices.push(choiceText);
                }
            }

            questionData.choices = choices;
            questionData.answer = answer;
        }

        return questionData;
    }

    onNewQuestionSubmit() {
        //step1: get consolidated object
        let questionData = this.getQuestionData();

        if (!this.validateQuestion(questionData)) {
            return;
        }


        this.setState({ loading: true });
        this.addAutoplayTag(questionData);


        let that = this;
        //step2: need to upload JSON object on the server
        apiClient.invokeApi({}, '/question', 'POST', {}, [questionData]).then(function (result) {

            that.setState({
                showAlert: true,
                alertStyle: 'success',
                alertText: result.data
            });

            setTimeout(() => {
                that.setState({ showAlert: false });
            }, 5000);

            // step3: after uploading the question, navigate to the mannage-questions page.
            //browserHistory.push('/questions');


            let customSubjects = [];

            if (that.state.customSubjects) {

                that.state.customSubjects.forEach(subject => {

                    if (questionData.subjects.includes(subject)) {

                        if (!that.props.auth.user.subjects.includes(subject)) {

                            that.props.auth.user.subjects.push(subject);
                        }
                    }
                });
            }

            if (that.props.onSubmit) {
                that.props.onSubmit(questionData);
            }
            that.setState({ loading: false });
        }).catch(function (error) {
            that.setState({ loading: false });
        });
    }

    onModifyQuestionSubmit() {

        this.setState({ loading: true });

        //step1: get consolidated object
        let questionData = this.getQuestionData();

        if (!this.validateQuestion(questionData)) {
            return;
        }

        this.addAutoplayTag(questionData);
        let that = this;


        // console.log("Question Details: ", questionData)

        //step2: need to upload JSON object on the server
        apiClient.invokeApi({}, '/question/update', 'PUT', {}, questionData, '4').then(function (result) {
            // that.setState({
            //     showAlert: true,
            //     alertStyle: 'success',
            //     alertText: result.data
            // });

            // setTimeout(() => {
            //     that.setState({ showAlert: false });
            // }, 3000);

            // step3: after uploading the question, navigate to the mannage-questions page.
            //browserHistory.push('/questions');

            if (that.props.onSubmit) {
                that.props.onSubmit(questionData);
            }
            that.setState({ loading: false });

        }).catch(function (error) {
            //console.log("Error occured posting question " + questionData)
            that.setState({ loading: false });

        });
    }

    validateQuestion(qd) {

        let isValid = true;

        let error = {};
        error.hasError = false
        let isAdminOrLPMember = (this.props.auth.user.schoolName === "LearnerPal" || this.props.auth.user.userType.toLowerCase() === "admin");

        if (isAdminOrLPMember && (qd.questionState == undefined || qd.questionState === '')) {
            error.questionState = "Question state is required.";
            error.title = "Question State";
            isValid = false;
        }

        if (qd.questionType == undefined || qd.questionType === "") {
            error.questionType = "Question type is required.";
            error.title = "Question Type";
            isValid = false;
        }

        if (qd.questionTemplate == undefined || qd.questionTemplate === "") {
            error.questionTemplate = "Question template is required.";
            error.title = "Question State";
            isValid = false;
        }

        if (qd.subjects == undefined || qd.subjects.length < 1) {
            error.subjects = "Atleast one subject is required";
            error.title = "Subjects";
            isValid = false;
        } else if (qd.subjects.length === 1 && qd.subjects[0] === "") {
            error.subjects = "Atleast one subject is required";
            error.title = "Subjects";
            isValid = false;
        }

        if (qd.category == undefined || qd.category === '') {
            error.category = "Atleast one category is required";
            isValid = false;
        } else if (qd.category.length === 1 && qd.category[0] === "") {
            error.category = "Atleast one category is required";
            isValid = false;
        }

        if (qd.subcategory == undefined || qd.subcategory === '') {
            error.subCategory = "Atleast one sub-category is required";
            isValid = false;
        } else if (qd.subcategory.length === 1 && qd.subcategory[0] === "") {
            error.subCategory = "Atleast one sub-category is required";
            isValid = false;
        }

        if (qd.question == undefined || qd.question === '' || qd.question === '<div></div>' || qd.question === '<p></p>') {
            error.question = "Question is required.";
            error.title = "Question";
            isValid = false;
        }

        if (this.state.isMultipleChoice && (qd.choices == undefined || qd.choices.length < 2)) {
            error.choices = "There must be at least 2 choices to make it Multiple Choice.";
            error.title = "Question Choices";
            isValid = false;
        }

        if (this.state.isMultipleChoice && (qd.answer == undefined || qd.answer === "")) {
            error.choices = "One choice must be marked as answer.";
            error.title = "Question Choices";
            isValid = false;
        }

        if (qd.hint == undefined || qd.hint === "" || qd.hint === '<div></div>' || qd.hint === '<p></p>') {
            error.message = "Hint is required.";
            error.title = "Question Hint";
        }

        if (qd.explanation == undefined || qd.explanation === "" || qd.explanation === '<div></div>' || qd.explanation === '<p></p>') {
            error.message = "Explanation is required.";
            error.title = "Question Explanation";
        }

        if (qd.notes == undefined || qd.notes === "" || qd.notes === '<div></div>' || qd.notes === '<p></p>') {
            error.message = "Notes is required.";
            error.title = "Question Notes";
        }

        // if (qd.tags == undefined || qd.tags === "" || qd.tags === '<div></div>' || qd.tags === '<p></p>') {
        //     error.message = "CCC is required.";
        //     error.title = "Question CCC";
        // }

        if (qd.ownerName == undefined || qd.ownerName === "") {
            error.ownerName = "Owner name is required.";
            error.title = "Question";
            isValid = false;
        }

        if (qd.schoolName == undefined || qd.schoolName === "") {
            error.schoolName = "School name is required.";
            error.title = "Question";
            isValid = false;
        }

        if (!isValid) {
            error.hasError = true;
            this.setState({ error: error });
        }

        return isValid;
    }

    onChange(e) {

        let isMultipleChoice = this.state.isMultipleChoice;
        let questionTemplate = "";

        if (e.target.name === "questionType") {
            if (e.target.value.toLowerCase() == "multiple choice") {
                isMultipleChoice = true;
            } else {
                isMultipleChoice = false;
                if (this.refs.questionEditor) {
                    this.refs.questionEditor.updateHTML(questionTemplate);
                    this.state.questionTemplate = "Normal";
                }
            }
        }

        if (e.target.name === "questionTemplate") {

            switch (e.target.value.toLowerCase()) {
                case "normal": {
                    questionTemplate = "";
                    break;
                }
                case "image on right": {
                    questionTemplate = `
                    <p>Question here..</p>
                    <table style="width: 100%;">
                        <tbody>
                            <tr>
                                <td style="width: 50.0000%;">[[[LP_ANSWERS]]]</td>
                                <td style="width: 50.0000%;">Place image here<br></td>
                            </tr>
                        </tbody>
                    </table>`;
                    break;
                }
                case "image on left": {
                    questionTemplate = `
                    <p>Question here..</p>
                    <table style="width: 100%;">
                        <tbody>
                            <tr>
                                <td style="width: 50.0000%;">Place image here<br></td>
                                <td style="width: 50.0000%;">[[[LP_ANSWERS]]]</td>
                            </tr>
                        </tbody>
                    </table>`;
                    break;
                }
            }

            if (this.refs.questionEditor) {
                this.refs.questionEditor.updateHTML(questionTemplate);
            }

        }

        let value = e.target.value;
        if (e.target.name === "category") {
            this.state.subCategory = "";

            let subjects = this.state.selectedSubject;
            subjects = Array.isArray(subjects) ? subjects : [subjects];

            this.requestElasticSearch(Object.assign(subjects), value);
        }
        if (e.target.name === "selectedSubject") {

            value = [value];
            this.state.category = "";
            this.state.subCategory = "";
            this.requestElasticSearch(value, null);
        }

        if (e.target.value == "Add Custom") {

            this.state.showCustomizedEntryPopupForSubject = true;
            this.state.showCustomizedEntryForCategory = true;
            this.state.showCustomizedEntryForSubCategory = true;

            if (e.target.name.startsWith("selectedCategory")) {

                this.state.showCustomizedEntryPopupForSubject = false;
            } else if (e.target.name.startsWith("selectedSubCategory")) {
                
                this.state.showCustomizedEntryPopupForSubject = false;
                this.state.showCustomizedEntryForCategory = false;
            }

            this.setState({ showCustomizedSubjectPopup: true, customEntryForID: 1});
            return;
        }


        this.setState({ [e.target.name]: value, isMultipleChoice: isMultipleChoice });
    }

    // Start: Select multiple subjects

    // getAllSubjects() {



    //     if (this.props.auth.user.schoolName === "LearnerPal" || this.props.auth.user.userType.toLowerCase() === "lpadmin") {

    //         this.requestElasticSearchForAllSubjects();
    //     } else {

    //         let sortedSubjects = [];
    //         this.props.auth.user.subjects.forEach(sub => {
    //             sortedSubjects.push({ "id": sub, "name": sub });
    //         });

    //         this.updateSubjectsList(sortedSubjects);
    //     }

    // }

    getAllUserSubjects() {

        // if (this.props.auth.user.schoolName === "LearnerPal" || this.props.auth.user.userType.toLowerCase() === "lpadmin") {

        //     this.requestElasticSearchForAllSubjects();
        // } else {

        let sortedSubjects = []
        this.props.auth.user.subjects.forEach(sub => {

            sortedSubjects.push({ "id": sub, "name": sub });
        })

        this.updateSubjectsList(sortedSubjects);
        // }
    }


    updateCustomCategories(lstCustomSubjects, selectedSubject, category) {

        let categories = []//this.state.allCategories;
        let allSubCategories = [];// this.state.allSubCategories;

        if (lstCustomSubjects) {

            let subject = lstCustomSubjects.find(x => x.name === selectedSubject);
            if (subject) {

                let selectedCategory = null;
                if (subject.categories) {

                    subject.categories.forEach(categ => {

                        if (categ.category === category)
                            selectedCategory = categ;

                        if (categories.find(x => x.name == categ.category)) {
                            return;
                        }

                        categories.push({ id: categ.category, name: categ.category, state: categ.state, subCategories: categ.subCategories })
                    })
                }

                if (selectedCategory) {
                    selectedCategory.subCategories.forEach((subCateg) => {

                        if (allSubCategories.find(x => x.name == subCateg.subCategory)) {
                            return;
                        }

                        allSubCategories.push({ id: subCateg.subCategory, name: subCateg.subCategory, state: subCateg.state })
                    });
                }
            }
        }


        return { allCategories: categories, allSubCategories };
    }

    getAllSubjects(id) {
        let context = this;
        // context.showIndicator();
        let schoolName = this.props.auth.user.schoolName ? this.props.auth.user.schoolName : 'trial';
        let params = {
            schoolName: schoolName,
            ownerName: this.props.auth.user.userName,
        };

        let pathTemplate = '/subjects/ownerName/{ownerName}/schoolName/{schoolName}';
        let method = 'GET';
        let additionalParams = {};
        let body = {};


        let subject = this.state.subjects[0];
        let category = this.state.category;

        let categories = this.state.allCategories;
        let allSubCategories = this.state.allSubCategories;
        apiClient.invokeApi(params, pathTemplate, method, additionalParams, body).then(function (result) {

            // context.hideIndicator();

            let customSubjects = result.data;

            if (id) {
                category = context.state["selectedCategory" + id];
            }

            let update = context.updateCustomCategories(customSubjects, subject, category)

            let updatedState = {
                ...context.state,
                customSubjects,
                // allCategories: update.allCategories,
                // allSubCategories: update.allSubCategories,
                loading: false
            };

            if (id) {

                updatedState["allCategories" + id] = context.state["allCategories" + id].concat(update.allCategories);
                updatedState["allSubCategories" + id] = context.state["allSubCategories" + id].concat(update.allSubCategories);

            } else {

                updatedState.allCategories = context.state.allCategories.concat(update.allCategories);
                updatedState.allSubCategories = context.state.allSubCategories.concat(update.allSubCategories);

            }

            context.setState(updatedState, function () {
                context.updateSubjectsList([]);
            });

        }).catch(function (error) {
            // context.hideIndicator();
        });

        this.getAllUserSubjects();
    }

    updateSubjectsList(sortedSubjects) {


        let { subjects } = this.props.auth.user;
        // if (subjects) {

        //     subjects.forEach(subject => {

        //         if (!sortedSubjects.find(x => x.name == subject)) {

        //             sortedSubjects.push({ "id": subject, "name": subject });
        //         }
        //     });
        // }


        if (this.state.customSubjects) {
            var lstSubjects = [];

            this.state.customSubjects.forEach(x => {

                let subjectName = x.name;

                if (!subjects.find(y => y == subjectName))
                    return;

                lstSubjects.push({ "id": subjectName, "name": subjectName });
            });

            sortedSubjects = lstSubjects;
        }



        // sortedSubjects.sort(function (a, b) {
        //     return a.id.localeCompare(b.id);
        // });



        this.sortList(sortedSubjects);

        if (!this.isModify)
            sortedSubjects.splice(0, 0, { "id": "Add Custom", "name": "Add Custom", isCustom: true });

        this.setState({ allSubjects: sortedSubjects });
    }

    inputTags(event) {
        let keyword = '';
        if (event.target.id === 'keywords' && event.target.value !== '') {
            let value = event.target.value;
            this.setKeywordsHtml(value);
            event.target.value = "";
        }
        else if (event.target.name === 'keywordsDrop') {
            if (event.target.selectedOptions && event.target.selectedOptions[0]) {
                keyword = event.target.selectedOptions[0].value;
            } else {
                keyword = event.nativeEvent.srcElement.value;
            }

            if (keyword !== '' && keyword !== 'Associate with Subjects') {
                this.setSubjectsHtml(keyword);
            }
        }

        let subjects = Object.assign(this.state.subjects);

        if (subjects.indexOf(keyword) < 0) {
            subjects.push(keyword);

            this.requestElasticSearch(subjects, null);
        }
    }

    addTags(e) {
        console.log("agyaaaaaaaaaaaaa", e);

        const val = e.target.value;
        let that = this;
        if (e.key === 'Enter' && val) {
            if (that.state.commonCoreIndicators.find(tag => tag.toLowerCase() === val.toLowerCase())) {
                return;
            }
            that.setState({ commonCoreIndicators: [...that.state.commonCoreIndicators, val] });
            console.log(that.tagInput);

            that.tagInput.value = null;
        } else if (e.key === 'Backspace' && !val) {
            that.removeTag(that.state.commonCoreIndicators.length - 1);
        }
    }


    requestElasticSearch(subjects, category) {

        if (subjects && subjects.length < 1) {
            this.setState({ allCategories: [], allSubCategories: [] });
            return;
        }

        // let axiosInstance = axios.create({
        //     baseURL: 'https://search-learnerpal-eimkio3umgx5fnpuy7ypsrrsri.us-east-1.es.amazonaws.com/learnerpal/',
        //     timeout: 60000,
        //     headers: { 'Content-Type': 'application/json' },
        //     responseType: 'json',
        //     transformRequest: [function (data) {
        //         return data;
        //     }],
        //     transformResponse: {
        //         function(data) {
        //             let reponse = [];
        //             if (data.aggregations) {
        //                 for (let key in data.aggregations) {
        //                     if (data.aggregations.hasOwnProperty(key)) {
        //                         let values = [];
        //                         data.aggregations[key].buckets.forEach(value => {
        //                             values.push({ name: value.key, count: value.doc_count })
        //                         });
        //                         reponse.push({ label: key, allValues: values });
        //                         //console.log(key + " -> " + data.aggregations[key]);
        //                     }
        //                 }
        //             }
        //             return reponse;
        //         }
        //     }
        // });


        let visibility = '(public OR "' + this.props.auth.user.userName + '"';
        if (this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName === "LearnerPal") {
            visibility += " OR LPAdmin";
        }
        visibility += ')';

        let subjectsToSearch = "(";
        if (subjects) {
            subjects.forEach(sub => {
                subjectsToSearch += "\"" + sub + "\" OR ";
            })
        }

        subjectsToSearch = subjectsToSearch.substring(0, subjectsToSearch.length - 3);


        let subjectsQueryString = "visibleTo : " + visibility + " AND subjects : " + subjectsToSearch + ")";
        let type = ElasticSearchTypes.Question + config.stage;

        let aggs = {
            category: { terms: { field: "category", size: 100 } },
        };

        if (category) {
            aggs.subcategory = { terms: { field: "subcategory", size: 100 } }

            subjectsQueryString += " AND  category: (\"" + category + "\")";
        }

        let queryObject = {

            query: {
                bool: {
                    must: { query_string: { query: subjectsQueryString } },

                    filter: { bool: { must: [{ terms: { subjects: subjects } }] } }
                }
            },
            aggs: aggs,
        };

        let that = this;
        apiClient.invokeApi({}, '/getesdata', 'POST', {}, { payload: queryObject, index: 'learnerpal/', esDocumentType: type }, '5').then((response) => {
            // axiosInstance({
            //     method: 'post',
            //     url: '/' + type + '/_search',
            //     data: JSON.stringify(queryObject)
            // }).then(response => {

            let categories = [];
            let allSubCategories = [];

            if (response && response.data) {


                let dataforAgg = response.data;
                let reponseAgg = [];
                if (dataforAgg.aggregations) {
                    for (let key in dataforAgg.aggregations) {
                        if (dataforAgg.aggregations.hasOwnProperty(key)) {
                            let values = [];
                            dataforAgg.aggregations[key].buckets.forEach(value => {
                                values.push({ name: value.key, count: value.doc_count })
                            });
                            reponseAgg.push({ label: key, allValues: values });
                            //console.log(key + " -> " + dataforAgg.aggregations[key]);
                        }
                    }
                }


                for (let data of reponseAgg) {

                    if (data.label === "category") {
                        for (let categ of data.allValues) {
                            categories.push({ id: categ.name, name: categ.name });
                        }

                        //this.setState({ allCategories: categories });
                    }
                    else if (data.label === "subcategory") {

                        for (let categ of data.allValues) {
                            allSubCategories.push({ id: categ.name, name: categ.name });
                        }

                    }
                }
            }


            //append custom subject categories
            // if (that.state.customSubjects) {

            //     let subject = that.state.customSubjects.find(x => x.name === subjects[0]);

            //     if (subject) {

            //         let selectedCategory = null;
            //         if (subject.categories) {

            //             subject.categories.forEach(categ => {

            //                 if (categ.category === category)
            //                     selectedCategory = categ;

            //                 if (categories.find(x => x.name == categ.category)) {
            //                     return;
            //                 }

            //                 categories.push({ id: categ.category, name: categ.category, state: categ.state, subCategories: categ.subCategories })
            //             })
            //         }

            //         if (selectedCategory) {
            //             selectedCategory.subCategories.forEach((subCateg) => {

            //                 if (allSubCategories.find(x => x.name == subCateg.subCategory)) {
            //                     return;
            //                 }

            //                 allSubCategories.push({ id: subCateg.subCategory, name: subCateg.subCategory, state: subCateg.state })
            //             });
            //         }

            //     }
            // }


            let update = that.updateCustomCategories(that.state.customSubjects, subjects[0], category)
            categories = categories.concat(update.allCategories);
            allSubCategories = allSubCategories.concat(update.allSubCategories);


            this.sortList(categories);
            this.sortList(allSubCategories, true);

            if (!this.isModify) {

                categories.splice(0, 0, { "id": "Add Custom", "name": "Add Custom", isCustom: true });
                allSubCategories.splice(0, 0, { "id": "Add Custom", "name": "Add Custom", isCustom: true });
            }

            // categories.sort(function (a, b) {
            //     return a.id.localeCompare(b.id);
            // });

            // allSubCategories.sort(function (a, b) {
            //     return a.id.localeCompare(b.id);
            // });


            if (category) {
                allSubCategories.splice(0, 0, { "id": "Add Custom", "name": "Add Custom", isCustom: true });
                that.setState({ allSubCategories: allSubCategories });
            } else {
                // that.setState({ allCategories: categories, allSubCategories: allSubCategories });
                that.setState({ allCategories: categories });
            }

        }).catch();
    }

    setKeywordsHtml(value) {
        let tempStateKeywords = this.state.taggedKeywords;

        for (let i = 0; i < tempStateKeywords.length; i++) {
            if (tempStateKeywords[i] === value) {
                return;
            }
        }
        this.setState({
            taggedKeywordsHtml: this.state.taggedKeywordsHtml.concat(
                this.getHtmlForTag(value)),
            taggedKeywords: this.state.taggedKeywords.concat(value)
        });
    }

    getHtmlForTag(tag) {
        return <span id="keywordsSpan" className="keywordTag" key={tag}>
            <input type="hidden" name={"keyWord" + tag} value={tag} /> {tag}
            <span className="text-cross-icon" onClick={this.deleteTags}>x</span>
        </span>
    }

    setSubjectsHtml(keyword) {
        let tempStateSubjects = this.state.subjects;

        for (let i = 0; i < tempStateSubjects.length; i++) {
            if (tempStateSubjects[i] === keyword) {
                return;
            }
        }
        this.setState({
            subjectsHtml: this.state.subjectsHtml.concat(
                this.getHtmlForTagSubjects(keyword)
            ), subjects: this.state.subjects.concat(keyword)
        });
    }

    getHtmlForTagSubjects(tags) {
        return <span id="subjectsSpan" className="keywordTag" key={tags}>
            <input type="hidden" name={"keyWord" + tags} value={tags} /> {tags}
            <span className="text-cross-icon" onClick={this.deleteTags}>x</span>
        </span>
    }

    deleteTags(event) {
        if (event.target.parentNode.id === "subjectsSpan") {
            $('#subjectDropDownId').prop('selectedIndex', 0);
            let tempTaggedSubjectsHtml = this.state.subjectsHtml;
            let tempTaggedSubjects = this.state.subjects;
            for (let a = 0; a < tempTaggedSubjectsHtml.length; a++) {
                if (tempTaggedSubjectsHtml[a].key === event.target.parentNode.firstElementChild.value) {
                    tempTaggedSubjectsHtml.splice(a, 1);
                    tempTaggedSubjects.splice(a, 1);
                }
            }
            this.setState({ taggedSubjectsHtml: tempTaggedSubjectsHtml, taggedSubjects: tempTaggedSubjects });
        }
        else {
            let tempTaggedKeywordsHtml = this.state.taggedKeywordsHtml;
            let tempTaggedKeywords = this.state.taggedKeywords;
            for (let a = 0; a < tempTaggedKeywordsHtml.length; a++) {
                if (tempTaggedKeywordsHtml[a].key === event.target.parentNode.firstElementChild.value) {
                    tempTaggedKeywordsHtml.splice(a, 1);
                    tempTaggedKeywords.splice(a, 1);
                }
            }
            this.setState({ taggedKeywordsHtml: tempTaggedKeywordsHtml, taggedKeywords: tempTaggedKeywords });
        }


        let subjects = Object.assign(this.state.subjects);
        this.requestElasticSearch(subjects, null);
    }

    //End: Select multiple subjects

    handleAlertDismiss(e) {
        this.setState({ 'showAlert': false });
        browserHistory.push('/questions');
    }

    previewQuestion() {
        this.setState({ showPreviewDialogBox: true });
    }


    propertyChangedHandler(e) {

        let that = this;
        let update = { [e.target.name]: e.target.value };
        let propName = e.target.name;
        let propValue = e.target.value;

        let id = e.target.name[e.target.name.length - 1];

        if (e.target.name.startsWith("selectedSubject")) {

            this.state.showCustomizedEntryPopupForSubject = true;
            this.state.showCustomizedEntryForCategory = true;
            this.state.showCustomizedEntryForSubCategory = true;


            update["selectedCategory" + id] = "";
            update["selectedSubCategory" + id] = "";
            this.state['selectedCategory' + id] = "";

            update["allCategories" + id] = [];
            update["allSubCategories" + id] = [];

            let subject = this.state.customSubjects.find(x => x.id === propValue);
            if (subject && subject.categories) {
                let lstCategories = [];

                lstCategories.push({ "id": "Add Custom", "name": "Add Custom", isCustom: true });
                for (let categ of subject.categories) {
                    lstCategories.push({ id: categ.category, name: categ.category });
                }

                this.sortList(lstCategories);

                update["allCategories" + id] = lstCategories;
            }

        } else if (e.target.name.startsWith("selectedCategory")) {

            update["selectedSubCategory" + id] = "";
            // this.state['selectedSubject' + id] = "";

            update["allSubCategories" + id] = [];

            let subject = this.state.customSubjects.find(x => x.id === this.state['selectedSubject' + id]);
            if (subject && subject.categories) {
                console.log(subject.categories);
                let category = subject.categories.find(y => y.category === propValue)
                if (category && category.subCategories) {

                    let lstSubCategories = [];
                    lstSubCategories.push({ "id": "Add Custom", "name": "Add Custom", isCustom: true });
                    for (let categ of category.subCategories) {
                        lstSubCategories.push({ id: categ.subCategory, name: categ.subCategory });
                    }

                    this.sortList(lstSubCategories, true);
                    update["allSubCategories" + id] = lstSubCategories;
                }
            }

        }

        if (e.target.value == "Add Custom") {

            delete update[propName];
            this.state.showCustomizedEntryPopupForSubject = true;
            this.state.showCustomizedEntryForCategory = true;
            this.state.showCustomizedEntryForSubCategory = true;

            if (e.target.name.startsWith("selectedCategory")) {

                this.state.showCustomizedEntryPopupForSubject = false;
            } else if (e.target.name.startsWith("selectedSubCategory")) {

                this.state.showCustomizedEntryPopupForSubject = false;
                this.state.showCustomizedEntryForCategory = false;
            }

            that.setState({ showCustomizedSubjectPopup: true, customEntryForID: id });
        }

        this.setState(update, function () {

            if (propName !== "selectedSubCategory")
                return;

            let filters = [];

            if (that.state.selectedSubject)

                filters.push({ terms: { subjects: [that.state.selectedSubject] } });



            if (that.state.selectedCategory)

                filters.push({ terms: { category: [that.state.selectedCategory] } });

            if (propValue)

                filters.push({ terms: { subcategory: [propValue] } });
        });
    }


    requestElasticSearchForMultipleControls(id, subjects, category) {

        subjects = [subjects];

        // let axiosInstance = axios.create({
        //     baseURL: 'https://search-learnerpal-eimkio3umgx5fnpuy7ypsrrsri.us-east-1.es.amazonaws.com/learnerpal/',
        //     timeout: 60000,
        //     headers: { 'Content-Type': 'application/json' },
        //     responseType: 'json',
        //     transformRequest: [function (data) {
        //         return data;
        //     }],
        //     transformResponse: {
        //         function(data) {
        //
        //             let reponse = [];
        //             if (data.aggregations) {
        //                 for (let key in data.aggregations) {
        //                     if (data.aggregations.hasOwnProperty(key)) {
        //                         let values = [];
        //                         data.aggregations[key].buckets.forEach(value => {
        //                             values.push({ name: value.key, count: value.doc_count })
        //                         });
        //
        //                         reponse.push({ label: key, allValues: values });
        //                     }
        //                 }
        //             }
        //             return reponse;
        //         }
        //     }
        // });


        let visibility = '(public OR "' + this.props.auth.user.userName + '"';
        if (this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName === "LearnerPal") {
            visibility += " OR LPAdmin";
        }
        visibility += ')';

        let subjectsToSearch = "(";
        if (subjects) {
            subjects.forEach(sub => {
                subjectsToSearch += "\"" + sub + "\" OR ";
            })
        }

        subjectsToSearch = subjectsToSearch.substring(0, subjectsToSearch.length - 3);


        let subjectsQueryString = "visibleTo : " + visibility + " AND subjects : " + subjectsToSearch + ")";
        let type = ElasticSearchTypes.Question + config.stage;

        let aggs = {
            category: { terms: { field: "category", size: 100 } },
        };

        if (category) {
            aggs.subcategory = { terms: { field: "subcategory", size: 100 } }

            subjectsQueryString += " AND category: (\"" + category + "\")";
        }

        let queryObject = {

            query: {
                bool: {
                    must: { query_string: { query: subjectsQueryString } },

                    filter: { bool: { must: [{ terms: { subjects: subjects } }] } }
                }
            },
            aggs: aggs,
        };

        let context = this;
        apiClient.invokeApi({}, '/getesdata', 'POST', {}, { payload: queryObject, index: 'learnerpal/', esDocumentType: type }, '5').then((response) => {
            // axiosInstance({
            //     method: 'post',
            //     url: '/' + type + '/_search',
            //     data: JSON.stringify(queryObject)
            // }).then(response => {
            let categories = [];
            let allSubCategories = [];

            if (response && response.data) {


                let dataforAgg = response.data;
                let reponseAgg = [];
                if (dataforAgg.aggregations) {
                    for (let key in dataforAgg.aggregations) {
                        if (dataforAgg.aggregations.hasOwnProperty(key)) {
                            let values = [];
                            dataforAgg.aggregations[key].buckets.forEach(value => {
                                values.push({ name: value.key, count: value.doc_count })
                            });
                            reponseAgg.push({ label: key, allValues: values });
                            //console.log(key + " -> " + dataforAgg.aggregations[key]);
                        }
                    }
                }


                for (let data of reponseAgg) {

                    if (data.label === "category") {
                        for (let categ of data.allValues) {
                            categories.push({ id: categ.name, name: categ.name });
                        }

                        //this.setState({ allCategories: categories });
                    }
                    else if (data.label === "subcategory") {

                        for (let categ of data.allValues) {
                            allSubCategories.push({ id: categ.name, name: categ.name });
                        }

                    }
                }
            }

            let updatedState = {}

            let update = context.updateCustomCategories(context.state.customSubjects, subjects[0], context.state['selectedCategory' + id])
            categories = categories.concat(update.allCategories);
            allSubCategories = allSubCategories.concat(update.allSubCategories);


            this.sortList(categories);
            this.sortList(allSubCategories, true);


            if (!this.isModify) {

                categories.splice(0, 0, { "id": "Add Custom", "name": "Add Custom", isCustom: true });
                allSubCategories.splice(0, 0, { "id": "Add Custom", "name": "Add Custom", isCustom: true });
            }

            if (category) {

                updatedState["allSubCategories" + id] = allSubCategories;
            } else {

                updatedState["allCategories" + id] = categories;
                //updatedState["allSubCategories" + id] = allSubCategories;
            }

            context.setState(updatedState);

        }).catch();
    }


    //learnerpal - get all the subjects
    requestElasticSearchForAllSubjects() {

        // let axiosInstance = axios.create({
        //     baseURL: 'https://search-learnerpal-eimkio3umgx5fnpuy7ypsrrsri.us-east-1.es.amazonaws.com/learnerpal/',
        //     timeout: 60000,
        //     headers: { 'Content-Type': 'application/json' },
        //     responseType: 'json',
        //     transformRequest: [function (data) {
        //         return data;
        //     }],
        //     transformResponse: {
        //         function(data) {
        //
        //             let reponse = [];
        //             if (data.aggregations.subject) {
        //
        //                 data.aggregations.subject.buckets.forEach(value => {
        //                     reponse.push({ name: value.key, count: value.doc_count })
        //                 });
        //             }
        //
        //             return reponse;
        //         }
        //     }
        // });

        let type = ElasticSearchTypes.Question + config.stage;


        let visibility = '(public OR "' + this.props.auth.user.userName + '"';
        if (this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName === "LearnerPal") {
            visibility += " OR LPAdmin";
        }
        visibility += ')';

        let subjectsQueryString = "visibleTo : " + visibility

        let queryObject = {

            query: {
                bool: {
                    must: { query_string: { query: subjectsQueryString } },
                }
            },
            aggs: { subject: { terms: { field: "subjects", size: 100 } } },
        };

        let context = this;
        apiClient.invokeApi({}, '/getesdata', 'POST', {}, { payload: queryObject, index: 'learnerpal/', esDocumentType: type }, '5').then((response) => {
            // axiosInstance({
            //     method: 'post',
            //     url: '/' + type + '/_search',
            //     data: JSON.stringify(queryObject)
            // }).then(response => {

            let data = response.data;

            let reponse = [];
            if (data.aggregations.subject) {

                data.aggregations.subject.buckets.forEach(value => {
                    reponse.push({ name: value.key, count: value.doc_count })
                });
            }

            response.data = reponse


            var subjects = [];
            response.data.forEach(subject => {
                subjects.push({ id: subject.name, name: subject.name });
            })

            context.updateSubjectsList(subjects.concat(context.state.customSubjects));
            //context.setState({ allSubjects: subjects.concat(this.state.customSubjects) });

        }).catch();
    }

    AddMoreRecords() {

        console.log("hello there")
        this.state.addedControlsCount++;

        console.log("Adding Controls with ID: " + this.state.addedControlsCount);
        let controlsID = this.state.addedControlsCount;

        let selectedSubject = "", selectedCategory = "", selectedSubCategory = "";
        if (this.state.addedControlsCount == 1) {
            selectedSubject = this.state.subjects[0];
            selectedCategory = this.state.category;
            selectedSubCategory = this.state.subCategory;
        }

        this.state['selectedSubject' + controlsID] = selectedSubject

        let subjectsControl1 =
            <AutoCompleteGroup

                // label="Subject:"
                dropDownId="subjectDropDownId"
                placeholder="Select or enter Subject"
                field={"selectedSubject" + controlsID}
                value={this.state['selectedSubject' + controlsID]}
                options={this.state.allSubjects}
                onBlur={this.propertyChangedHandler}
                id='subjectInfo'
                isDisabled={false}
            />;


        this.state['selectedCategory' + controlsID] = selectedCategory;

        if (!this.state["allCategories" + controlsID])
            this.state["allCategories" + controlsID] = [];
        let categoryControl1 =
            <AutoCompleteGroup
                dropDownId="categoryDropDownId"
                placeholder="Select or enter Category"
                field={"selectedCategory" + controlsID}
                value={this.state['selectedCategory' + controlsID]}
                options={this.state["allCategories" + controlsID]}
                onBlur={this.propertyChangedHandler}
                id='categoryInfo'
                isDisabled={false}

            />


        this.state['selectedSubCategory' + controlsID] = selectedSubCategory;

        let subCategListName = "allSubCategories" + controlsID;

        if (!this.state[subCategListName])
            this.state[subCategListName] = [];

        let subCategoryControl1 =
            <AutoCompleteGroup
                dropDownId="subcategoryDropDownId"
                placeholder="Select or enter Subcategory"
                field={"selectedSubCategory" + controlsID}
                value={this.state['selectedSubCategory' + controlsID]}
                options={this.state[subCategListName]}
                onBlur={this.propertyChangedHandler}
                id='subCategoryInfo'
                isDisabled={false}
            />;

        let removeControl = this.state.addMoreHtmlArray.length > 1 ?
            <Button onClick={
                () => {

                    console.log("selected ID: ", controlsID)
                    this.removeRecords(controlsID);
                }}

                style={{ padding: '4px', marginTop: '14px', fontSize: 'large', fontWeight: 'bold', marginBottom: '19px', width: '3%' }}
                bsStyle='danger'>-</Button>
            : null;


        let finalHtml =

        {
            key: controlsID,
            subjectsControl1,
            categoryControl1,
            subCategoryControl1,
            removeControl
        }


        this.state.addMoreHtmlArray.push(finalHtml);
        this.setState({ addMoreHtmlArray: this.state.addMoreHtmlArray })
    }

    removeRecords(id) {

        let tempArray = this.state.addMoreHtmlArray;
        let index = tempArray.findIndex(x => x.key === id);
        tempArray.splice(index, 1);

        this.setState({ addMoreHtmlArray: tempArray })
    }

    renderSubjectCategorySubCategory(controlsID) {

        let subjectsControl1 =

            <SelectFieldGroup
                key={"subjectDropDownId" + controlsID}
                dropDownId={"subjectDropDownId" + controlsID}
                value={this.state["selectedSubject" + controlsID]}
                field={"selectedSubject" + controlsID}
                options={this.state.allSubjects}
                placeholder="Select Subject"
                onChange={this.propertyChangedHandler}
                error={this.state.error.subjects}
            />;

        let categoryControl1 =

            <SelectFieldGroup
                key={"categoryDropDownId" + controlsID}
                dropDownId={"categoryDropDownId" + controlsID}
                value={this.state["selectedCategory" + controlsID]}
                field={"selectedCategory" + controlsID}
                options={this.state["allCategories" + controlsID]}
                placeholder="Select Category"
                onChange={this.propertyChangedHandler}
                error={this.state.error.category}

            />;

        this.sortList(this.state["allSubCategories" + controlsID], true);

        let subCategoryControl1 =
            <SelectFieldGroup
                key={"subcategoryDropDownId" + controlsID}
                dropDownId={"subcategoryDropDownId" + controlsID}
                value={this.state["selectedSubCategory" + controlsID]}
                field={"selectedSubCategory" + controlsID}
                options={this.state["allSubCategories" + controlsID]}
                placeholder="Select Subcategory"
                onChange={this.propertyChangedHandler}
                error={this.state.error.subCategory}

            />;



        //Button Type: ["success","warning","danger","info","default","primary","link"].
        let removeControl = controlsID > 1 ?
            <Button key={controlsID + "button"} onClick={
                () => {

                    console.log("selected ID: ", controlsID)
                    this.removeRecords(controlsID);
                }}

                style={{ padding: '4px', fontSize: 'large', fontWeight: 'bold', width: "50px", height: "40px" }}
                bsStyle='danger'>-</Button>
            : < Button key={controlsID + "button"} title="Add question to another subject" onClick={this.AddMoreRecords}
                style={{ padding: '4px', fontSize: 'large', fontWeight: 'bold', width: "50px", height: "40px" }}
                bsStyle='primary'>+</Button>;


        let finalHtml =
            <div key={controlsID + 'div'} className="grid">
                {[
                    subjectsControl1,
                    categoryControl1,
                    subCategoryControl1,
                    removeControl
                ]}
            </div>

        return finalHtml;
    }

    customizedSubjectpopup() {

        const schoolTypes = [
            {id: 'Lower School', name: 'Lower School'},
            {id: 'Middle School', name: 'Middle School'},
            {id: 'Upper School', name: 'Upper School'}
        ]
        
        const setSchoolTypesHtml = (event) => {
            let keyword = '';
            if (event.target.selectedOptions && event.target.selectedOptions[0]) {
                keyword = event.target.selectedOptions[0].value;
            } else {
                keyword = event.nativeEvent.srcElement.value;
            }
            let selectedSchoolType = this.state.selectedSchoolType;
    
            for (let i = 0; i < selectedSchoolType.length; i++) {
                if (selectedSchoolType[i] === keyword) {
                    return;
                }
            }
            this.setState({ selectedSchoolType: this.state.selectedSchoolType.concat(keyword) });
        }

        const deleteTags = (event) =>{

            $('#subjects').prop('selectedIndex', 0);
            let tempTaggedSubjects = this.state.selectedSchoolType;
            for (let a = 0; a < tempTaggedSubjects.length; a++) {
                if (tempTaggedSubjects[a] === event.target.parentNode.firstElementChild.value) {
                    tempTaggedSubjects.splice(a, 1);
                }
            }
            this.setState({ selectedSchoolType: tempTaggedSubjects });
        }
    

        const allSchoolTypesHtml = []
        if (this.state.selectedSchoolType && this.state.selectedSchoolType.length > 0) {
            for (let i = 0; i < this.state.selectedSchoolType.length; i++) {
                allSchoolTypesHtml.push(<span key={this.state.selectedSchoolType[i]} className="keywordTag">
                    <input type="hidden" name="undefined[]" value={this.state.selectedSchoolType[i]} />{this.state.selectedSchoolType[i]}<span className="text-cross-icon" onClick={deleteTags} > ×</span>
                </span>);
            }
        }

        const isLPSchool = this.props.auth.user.schoolName == "LearnerPal";

        return <Modal id="dialogCustomEntry"
            show={this.state.showCustomizedSubjectPopup}
            style={{ zIndex: '10000' }}
            onHide={() => {

                this.setState({ showCustomizedSubjectPopup: false, errors: {} });
            }}

            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Add custom value(s)</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="newone">

                    {this.state.showCustomizedEntryPopupForSubject && <TextFieldGroup
                        error={this.state.errors.customSubject}
                        field="customSubject"
                        value={this.state.customSubject}
                        placeholder="Enter Subject"
                        onChange={this.onAddCustomChanged}
                    />}
                    {this.state.showCustomizedEntryForCategory && <TextFieldGroup
                        error={this.state.errors.customCategory}
                        field="customCategory"
                        value={this.state.customCategory}
                        placeholder="Enter Category"
                        onChange={this.onAddCustomChanged}
                    />}
                    {this.state.showCustomizedEntryForSubCategory && <TextFieldGroup
                        error={this.state.errors.customSubCategory}
                        field="customSubCategory"
                        value={this.state.customSubCategory}
                        placeholder="Enter Subcategory"
                        onChange={this.onAddCustomChanged}
                    />}
                    {this.state.showCustomizedEntryPopupForSubject && isLPSchool && <div>
                        <SelectFieldGroup
                            error={this.state.errors.schoolType}
                            dropDownId="schoolType"
                            placeholder="Select School Type"
                            field="schoolType"
                            options={schoolTypes}
                            selectWidth={false}
                            onChange={setSchoolTypesHtml}
                        />
                        {allSchoolTypesHtml}
                    </div>}

                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {


                    let isValid = true;
                    this.state.errors = {};
                    if (this.state.showCustomizedEntryPopupForSubject && !this.state.customSubject) {
                        isValid = false
                        this.state.errors.customSubject = "Subject is required";
                    }

                    if (this.state.showCustomizedEntryPopupForSubject && isLPSchool && this.state.selectedSchoolType.length == 0) {
                        isValid = false
                        this.state.errors.schoolType = "School Type is required";
                    }

                    if (this.state.showCustomizedEntryForCategory && !this.state.customCategory) {
                        isValid = false
                        this.state.errors.customCategory = "Category is required";
                    }

                    if (this.state.showCustomizedEntryForSubCategory && !this.state.customSubCategory) {
                        isValid = false
                        this.state.errors.customSubCategory = "Subcategory is required";
                    }

                    if (!isValid) {

                        this.setState({ errors: this.state.errors });
                        return;
                    }


                    var { customEntryForID } = this.state;
                    if (this.state.showCustomizedEntryPopupForSubject) {

                        this.state["selectedSubject" + customEntryForID] = this.state.customSubject;

                        this.state.customSubjects.push(this.state.customSubject);
                        this.state.allSubjects.push({ id: this.state.customSubject, name: this.state.customSubject, isCustom: true });
                    }

                    if (this.state.showCustomizedEntryForCategory) {


                        this.state["selectedCategory" + customEntryForID] = this.state.customCategory;

                        this.state.customCategories.push({ id: this.state.customCategory, name: this.state.customCategory, isCustom: true });

                        if (!this.state["allCategories" + customEntryForID])
                            this.state["allCategories" + customEntryForID] = [];


                        this.state["allCategories" + customEntryForID].push({ id: this.state.customCategory, name: this.state.customCategory, isCustom: true });
                    }
                    if (this.state.showCustomizedEntryForSubCategory) {
                        this.state.customCategory = this.state["selectedCategory" + customEntryForID];
                        if(!this.state.customCategory){
                            this.state.customCategory = this.state.selectedCategory1;
                        }
                    }

                    if (!this.state["allSubCategories" + customEntryForID])
                        this.state["allSubCategories" + customEntryForID] = [];

                    this.state.customSubCategories.push({ id: this.state.customSubCategory, name: this.state.customSubCategory, isCustom: true });
                    this.state["allSubCategories" + customEntryForID].push({ id: this.state.customSubCategory, name: this.state.customSubCategory, isCustom: true });

                    this.state["selectedSubCategory" + customEntryForID] = this.state.customSubCategory;
                    let subCategory_ = { subCategory: this.state.customSubCategory };
                    let category_ = { category: this.state.customCategory, subCategories: [subCategory_] };

                    let customSubject_ = { name: this.state.customSubject, categories: [category_] }

                    if(this.state.selectedSchoolType.length > 0){
                        customSubject_.tags = this.state.selectedSchoolType;
                    }
                    
                    if(!isLPSchool){
                        customSubject_.tags = [];
                    }

                    if (this.props.auth.user.schoolName === "LearnerPal" && this.props.auth.user.userType.toLowerCase() === "admin") {

                        customSubject_.subjectState = "Pending";
                        customSubject_.categories[0].state = "Pending"
                        customSubject_.categories[0].subCategories[0].state = "Pending"
                    }

                    this.updateSubjectInUserProfile(customSubject_);

                    if (this.state.showCustomizedEntryPopupForSubject || this.state.showCustomizedEntryForCategory || this.state.showCustomizedEntryForSubCategory)
                        this.updateSubjectsTable(customSubject_, true);
                    this.setState({ showCustomizedSubjectPopup: false, customSubject: "", customCategory: "", customSubCategory: "", errors: {} });
                }} bsStyle='primary'>Add</Button>
            </Modal.Footer>
        </Modal>;

    }


    updateSubjectsTable(customSubject, isUpdate) {
        let categoryT = [];
        this.state.customSubjects.forEach(x => {
            let subjectName = x.name;
            if (subjectName === this.state.selectedSubject1)
                categoryT = [x];
        });
        categoryT.length > 0  && categoryT[0].categories.push(customSubject.categories[0])
        let schoolName = this.props.auth.user.schoolName ? this.props.auth.user.schoolName : "Trial"
        let body = {
            ownerName: this.props.auth.user.userName,
            schoolName: schoolName,
            subjectName: this.state.selectedSubject1,
            categories: categoryT.length > 0 ?  categoryT[0].categories : customSubject.categories,
        };


        if (customSubject.updatedSubject)
            body.updatedSubject = customSubject.updatedSubject;
        else if (customSubject.updatedCategory)
            body.updatedCategory = customSubject.updatedCategory;
        else if (customSubject.updatedSubCategory)
            body.updatedSubCategory = customSubject.updatedSubCategory;


        if (customSubject.subjectState)
            body.subjectState = customSubject.subjectState;
        if (customSubject.questionIds)
            body.questionIds = customSubject.questionIds;
        if (customSubject.tags)
            body.tags = customSubject.tags || [];

                                
                    
        if(body.categories){
            body.categories = body.categories.reduce((pv,v)=>{
                if(!v.category) {
                    return pv;
                }
                let index = pv.findIndex(obj=>obj.category == v.category);
                if(index >= 0){
                    const obj = pv[index];
                    if(obj) {
                        obj.subCategories.push(...v.subCategories);
                        pv[index] = obj;
                    }
                } else {
                    pv.push(v);
                }
                return pv;
            },[])
        }

        // if (customSubject.subjectState)
        //     body.subjectState = customSubject.subjectState;
        let context = this;
        let method = "POST";
        if (isUpdate)
            method = "PUT";
        apiClient.invokeApi({}, '/subject', method, {}, body, "5")
            .then(function (result) {

                // console.log(result);
                context.getAllSubjects();
                context.resetAllCustomFields();
            })
    }

    resetAllCustomFields(){
        this.setState({
            schoolType: null,
            selectedSchoolType: [],
            customSubCategory: null, 
            customCategory: null,
            customSubject: null
        })
    }


    updateSubjectInUserProfile(subject) {

        // step 1: get the subjects array ref in userSubjects
        let userSubjects = this.props.auth.user.subjects;

        // step 2: userSubjects is not defined, initialize it.
        if (!userSubjects)
            userSubjects = [];

        // step 3: add subject in userSubjects if it doesn't already exist.
        if (!userSubjects.find(x => x === subject.name))
            userSubjects.push(subject.name);

        let subjectsList = [];
        for (let sub of userSubjects) {
            if (sub) {
                subjectsList.push(sub);
            }
        }

        // step 4: add subject in userSubjects if it doesn't already exist.
        let context = this;

        let body = {
            userName: this.props.auth.user.userName,
            subjects: subjectsList
        };

        apiClient.invokeApi({}, '/user', 'PUT', {}, body, "2")
            .then(function (result) {

                localStorage.setItem('userObj', JSON.stringify(context.props.auth.user));

                const { dispatch } = context.props;
                dispatch({
                    type: SET_CURRENT_USER,
                    user: context.props.auth.user
                })
            })
    }

    onAddCustomChanged(e) {

        let that = this;
        let update = { [e.target.name]: e.target.value };

        this.setState(update);
    }

    render() {

        // set question state.
        let params = {
            updateContent: function (data) {
                this.setState({ question: data });
            }.bind(this)
        };

        var editorContent = "";


        var arrOptions = [];

        const { errors, allSubjects, allCategories, allSubCategories } = this.state;

        this.sortList(allSubCategories, true)

        let questionTemplates = [
            {
                "id": "Normal",
                "name": "Normal"
            }
        ];

        if (this.state.isMultipleChoice) {
            questionTemplates.push({
                "id": "Image On Left",
                "name": "Image On Left"
            });

            questionTemplates.push({
                "id": "Image On Right",
                "name": "Image On Right"
            });
        }

        let questionStates = [
            {
                "id": "In Progress",
                "name": "In Progress"
            },
            {
                "id": "Shared",
                "name": "Shared"
            },
            {
                "id": "In Review",
                "name": "In Review"
            }
        ];
        if (this.props.auth.user.schoolName === "LearnerPal" && this.props.auth.user.userType.toLowerCase() === "admin") {
            questionStates.push({
                "id": "Published",
                "name": "Published"
            })
        }


        let questionTypes = [
            {
                "id": "Multiple Choice",
                "name": "Multiple Choice"
            },
            {
                "id": "essay",
                "name": "Essay"
            },
        ];

        if (this.state.isMultipleChoice) {
            for (var chkCount = 0; chkCount <= 7; chkCount++) {
                var charName = String.fromCharCode(65 + chkCount);
                var editorName = "OptionEditor" + "  " + charName;

                arrOptions.push(
                    <div className="fieldBox" key={"" + chkCount} style={{ margin: '3px', marginTop: '10px', verticalAlign: 'center' }}>
                        <label style={{ verticalAlign: 'top' }}>{charName}</label>

                        <input type="radio" name="choices" className="css-checkbox css-label1 bc_info card_side" id={"choice" + chkCount} style={{ verticalAlign: 'top' }} />
                        <label htmlFor={"choice" + chkCount} className="css-label radGroup1" style={{ verticalAlign: 'top', marginLeft: '7px' }} />

                        <div id={editorName} className="divEditable selectWidth" contentEditable="true" style={{ verticalAlign: 'top', overflow: 'auto', borderStyle: 'solid', borderWidth: '1px', display: 'inline-block', width: '84%' }} />

                        <div className="respFixBot" style={{ display: 'inline-flex' }}>
                            <a href="javascript:void 0" className="courseBtnAssign" style={{ padding: '6px', float: 'left', display: 'inline-flex' }} onClick={this.openChoicesChecks.bind(this, editorName)}>Editor</a>
                        </div>

                    </div>);
            }

        }

        let subjectsControl = <SelectFieldGroup
            key='subjectDropDownId'
            dropDownId="subjectDropDownId"
            // label="Subject:"
            value={this.state.selectedSubject}
            field="selectedSubject1"
            options={allSubjects}
            placeholder="Select Subject"
            onChange={this.onChange}
            isDisabled={false}
            error={this.state.error.subjects}

        />
        let categoryControl = <SelectFieldGroup
            key='categoryInfo'
            error={this.state.error.category}
            dropDownId="categoryInfo"
            // label="Subject:"
            value={this.state.category}
            field="selectedCategory1"
            options={allCategories}
            placeholder="Select or enter Category"
            onChange={this.onChange}
            error={this.state.error.category}

        />;

        let subCategoryControl = <SelectFieldGroup
            key='subCategoryInfo'
            error={this.state.error.subCategory}
            dropDownId="subCategoryInfo"
            // label="Subject:"
            value={this.state.subCategory}
            field="selectedSubCategory1"
            options={allSubCategories}
            placeholder="Select or enter Subcategory"
            onChange={this.onChange}
            error={this.state.error.subCategory}

        />;


        let headingStyle = { display: 'inline', marginRight: '10px', fontSize: 'initial !important' };
        let choicesHeadingStyle = Object.assign({}, headingStyle);
        if (this.state.error.choices)
            choicesHeadingStyle.color = '#a94442';

        let questionsHeadingStyle = Object.assign({}, headingStyle);
        if (this.state.error.question)
            questionsHeadingStyle.color = '#a94442';

        let questionStateControl = this.props.auth.user.schoolName === "LearnerPal" &&
            <SelectFieldGroup
                key='questionState'
                error={this.state.error.questionState}
                field="questionState"
                value={this.state.questionState}
                options={questionStates}
                placeholder="Select Question State"
                onChange={this.onChange}
            />;

        let questionTemplateControl = null;
        // this.state.isMultipleChoice && <SelectFieldGroup
        //     dropDownId="selectedQuestionTypeId"
        //     field="questionTemplate"
        //     value={this.state.questionTemplate}
        //     options={questionTemplates}
        //     onChange={this.onChange}
        // />;

        let imageControl = (
            <div><div className="col-md-3" style={{
                height: 'inherit',
                minHeight: '303px',
                padding: 0,
                textAlign: 'center',
                verticalAlign: 'middle',
                border: '1px solid rgb(198, 198, 198)',
                paddingTop: '83px',
                background: 'white',
                marginTop: '5px',
                paddingRight: '5px',
                cursor: 'pointer'
            }} onClick={this.getPlaceHolderImage.bind(this)} >
                <img src={this.state.selectedImage ? this.state.selectedImage : placeHolderImage} style={{ height: "inherit", width: "87%" }} />

            </div>
                {this.state.selectedImage &&
                    <div className="col-md-3" style={{ textAlign: 'center' }}>
                        <a href="javascript:void 0" className="courseBtnAssign" style={{ padding: '6px', display: 'inline-flex' }} onClick={this.removeImage}>Remove Image</a>
                    </div>
                }</div>);

        return (
            <div>
                <div className="row" style={{ marginLeft: '20px' }}>

                    {/* following empty div would adjust the display */}
                    <div className="col-md-4 col-lg-4 col-sm-0">

                    </div>

                    <div className="col-md-8 col-lg-8 col-sm-12 text-right">

                    </div>

                    <div className="courseInfo" style={{ padding: "0px 0px 0px 15px", margin: "3px" }}>
                        {this.isModify ?
                            <div className="newQuestionSelectField" style={{ padding: '0px', margin: '0px', border: '0px' }} >
                                <div className="grid">

                                    {subjectsControl}

                                    {categoryControl}

                                    {subCategoryControl}

                                    {//!this.isModify ?
                                        //     [
                                        //         this.state.addMoreHtmlArray,
                                        //         < Button title="Add question to another subject" onClick={this.AddMoreRecords}
                                        //             // style={{padding: '4px', marginRight: '3px',    marginLeft: '52px', marginTop: '14px', fontSize: 'large', fontWeight:'bold', width: '4%'}}
                                        //             style={{ padding: '4px', marginTop: '14px', fontSize: 'large', fontWeight: 'bold', width: '4%' }}
                                        //             bsStyle='primary'>+</Button>
                                        //     ]
                                        //     : null
                                    }

                                </div>
                                <div className="grid">
                                    {questionStateControl}
                                    {questionTemplateControl}
                                </div>

                            </div>
                            :

                            <div className="newQuestionSelectField" style={{ padding: '0px', margin: '0px', border: '0px' }} >

                                {
                                    this.state.addMoreHtmlArray.map((item, index) => {

                                        return this.renderSubjectCategorySubCategory(item.key);
                                    })
                                }

                                {
                                    <div className="grid">
                                        {questionStateControl}
                                        {questionTemplateControl}
                                    </div>
                                }
                            </div>
                        }
                    </div>

                    <div className="col-md-12">


                        <div className="row" style={{ width: "95%", border: "1px solid rgb(198, 198, 198)", background: "rgb(244, 244, 244)", marginTop: '12px' }} >
                            {/*<label style={questionsHeadingStyle} >Question Layout and Content</label>*/}

                            {/* {this.state.questionTemplate == "Image On Left" ? imageControl  : null} */}

                            <label className="col-md-12" style={{ paddingTop: '10px' }}><strong>Question Layout and Content </strong></label>

                            <div className="col-md-9" style={{ padding: '5px' }}>
                                <EditorComponent ref="questionEditor" id="questionEditor" placeholderText="Question here.." params={params} HTMLContent={this.state.question} editorId={'editor1'} />
                            </div>

                            {imageControl}
                            {/* {this.state.questionTemplate == "Image On Right" ? imageControl : null} */}

                            {/* <div>
                                <EditorComponent ref="questionEditor" id="questionEditor" placeholderText="Question here.." params={params} HTMLContent={this.state.question} editorId={'editor1'} />
                            </div> */}

                            {this.state.error.question && <label className="errorMessage">{this.state.error.question}</label>}
                        </div>

                        {this.state.isMultipleChoice &&
                            <div style={{ marginTop: '40px' }} >
                                <label style={choicesHeadingStyle}><strong>Choices [ place checkmark next to the correct answer ]</strong></label>
                                <div className="innerQuestionBox NiceScroll" style={{ display: 'block' }}>
                                    <div className="topRowHaedingAnch">
                                        <span className="scrollBar" />
                                        {arrOptions}
                                    </div>
                                </div>

                                {this.state.error.choices && <label className="errorMessage" >{this.state.error.choices}</label>}
                            </div>
                        }

                        <div className="editor" style={{ width: '95%', marginTop: '30px' }}>
                            <div style={{ display: 'inline-block' }}>
                                <label style={headingStyle}><strong>Hint</strong></label>
                                <div className="respFixBot" style={{ display: 'inline-flex' }}>
                                    ( For more advance features, use the &nbsp;<a href="javascript:void 0" className="courseBtnAssign" style={{ padding: '0px', margin: '0px' }} onClick={this.openChoicesChecks.bind(this, "Hint")}>Editor</a>)
                                </div>

                            </div>
                            <div id="Hint" ref="hint" className="divEditable" contentEditable="true" style={{ overflow: 'auto', borderStyle: 'solid', borderWidth: '1px', width: '100%', height: '85px' }} />
                        </div>

                        <div className="editor" style={{ width: '95%', marginTop: '20px' }}>
                            <div style={{ display: 'inline-block' }}>
                                <label style={headingStyle}><strong>Explanation</strong></label>


                                <div className="respFixBot" style={{ display: 'inline-flex' }}>
                                    ( For more advance features, use the &nbsp;<a href="javascript:void 0" className="courseBtnAssign" style={{ padding: '0px', margin: '0px' }} onClick={this.openChoicesChecks.bind(this, "Explanation")}>Editor</a>)
                                </div>

                            </div>
                            <div id="Explanation" ref="explanation" className="divEditable" contentEditable="true" style={{ overflow: 'auto', borderStyle: 'solid', borderWidth: '1px', width: '100%', minHeight: '225px' }} />
                        </div>

                        <div style={{ width: '95%', marginTop: '20px' }}>
                            <label style={headingStyle}><strong>Author Notes</strong></label>

                            <div id="notes" ref="notes" className="divEditable" contentEditable="true" style={{ overflow: 'auto', borderStyle: 'solid', borderWidth: '1px', width: '100%', height: '100%' }} />
                        </div>

                        <InputTags
                            onTagsInput={this.addTags}
                            onRemoveTag={this.removeTag}
                            commonCoreIndicators={this.state.commonCoreIndicators}
                            handleTagInput={(c) => {
                                this.tagInput = c
                            }}
                        />
                    </div>

                    <div className="col-md-12" style={{ width: '95%', textAlign: 'right' }}>
                        {<button className="save submitButton" onClick={this.previewQuestion}>Preview</button>}
                        {
                            this.isModify ?
                                <button className="save submitButton" onClick={this.onModifyQuestionSubmit}>Save</button>
                                :
                                <button className="save submitButton" onClick={this.onNewQuestionSubmit}>{this.isClone ? "Save As" : "Save"}</button>
                        }
                    </div>

                </div>
                <ProgressIndicator show={this.state.loading} />
                {/* </div>
                </div> */}

                {this.state.showCreateQuestionDialogBox && this.createQuestionDialog()}
                {this.state.showPreviewDialogBox && this.previewDialogBox()}
                {this.state.showCustomizedSubjectPopup && this.customizedSubjectpopup()}

                {this.state.showAlert &&
                    <div className="row">
                        <div className="col-md-3 col-xs-3" />
                        <div className="col-md-6 col-xs-3">
                            <Alert bsStyle={this.state.alertStyle}
                                onDismiss={this.handleAlertDismiss}>
                                {this.state.alertText}
                            </Alert>
                        </div>
                        <div className="col-md-3 col-xs-3" />
                    </div>
                }
            </div>
        );
    }

    getPlaceHolderImage() {
        console.log("getPlaceHolderImage")
        var inputElement = document.createElement("input");
        inputElement.type = "file";
        inputElement.accept = "image/*"; // Note Edge does not support this attribute
        inputElement.multiple = false;

        inputElement.dispatchEvent(new MouseEvent("click"));
        inputElement.addEventListener("change", this.onImageOpen.bind(this, inputElement));


    }

    removeImage() {
        console.log("removeImage")
        this.setState({ selectedImage: null });
    }

    async onImageOpen(inputElement) {
        console.log("onImageOpen")
        this.setState({ ...this.state, loading: true });

        const uploadedFilename = (inputElement.files[0])
            ? (await s3Upload(inputElement.files[0], "froalaUploads")).Location
            : null;

        this.setState({ ...this.state, selectedImage: uploadedFilename, loading: false });

    }

    sortList(sortSubject, shouldChronological) {

        sortSubject.sort(function (a, b) {
            return a.id.localeCompare(b.id);
        });

        if (shouldChronological) {

            var allMonths = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];
            var regexYear = /(19[0-9]{2}|20[0-9]{2})/;
            let chronoObj = [];
            let sortSubjectCopy = [];

            sortSubject.forEach(x => {

                let sourceId = x.id;

                let firstSpaceIndex = sourceId.indexOf(' ');
                let extractedMonth = sourceId.slice(0, firstSpaceIndex)

                if (!(extractedMonth) ? allMonths.includes(extractedMonth.toLowerCase()) : null)
                    return;

                let yearMatched = regexYear.exec(sourceId);
                if (!yearMatched)
                    return;

                let innerObj = chronoObj.find(x => x.year === yearMatched[0])
                if (!innerObj) {

                    let obj = {};

                    obj['year'] = yearMatched[0];

                    obj['months'] = [];
                    obj.months.push({
                        month: extractedMonth,
                        data: [x]
                    });
                    chronoObj.push(obj);
                } else {
                    let innerMonthsObj = innerObj.months.find(x => x.month === extractedMonth);
                    if (!innerMonthsObj) {
                        innerObj.months.push({
                            month: extractedMonth,
                            data: [x]
                        });

                    } else {
                        innerMonthsObj.data.push(x);
                    }
                }
            })


            chronoObj.sort((a, b) => {
                return a.year - b.year;
            });

            chronoObj.forEach(x => {
                x.months.sort((a, b) => {
                    return allMonths.indexOf(a.month.toLowerCase()) > allMonths.indexOf(b.month.toLowerCase());
                });
            });

            chronoObj.forEach(x => x.months.forEach(x => {
                x.data.forEach(x => {
                    sortSubjectCopy.push(x);
                })
            })
            );

            if (sortSubjectCopy.length > 0) {
                sortSubject.sort((a, b) => {

                    var valA = a;
                    var valB = b;

                    if (sortSubjectCopy.indexOf(valA) < sortSubjectCopy.indexOf(valB)) {
                        return -1;
                    }

                    if (sortSubjectCopy.indexOf(valA) > sortSubjectCopy.indexOf(valB)) {
                        return 1;
                    }

                    return 0;

                });
            }
        }
    }
}

function mapStateToProps(state) {
    return { ...state };
}

export default connect(mapStateToProps)(NewQuestion);
