/**
 * Created by ashahab on 8/24/2017.
 */

import React, {Component} from "react";
import {connect} from "react-redux";
import activityIco from "./activitylog.png";

class WidgetActivityLog extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        let allActivityLog = this.props.data?this.props.data:[];
        if(Array.isArray(allActivityLog)){
            allActivityLog.sort(function (a, b) {
                return new Date(parseFloat(b.creationTime)) - new Date(parseFloat(a.creationTime));
            });
        }
        let allLogsHtml = [];
        
        if(Array.isArray(allActivityLog)){
            allActivityLog.forEach(activity=>{
                let id  = Math.random() * 10000000000;
                if (activity.value && activity.value !== 'You logged in.') {
                    allLogsHtml.push(
                        <div key={id}>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                {/*<div className="icondes">*/}
                                {/*<div className="limitFilters">*/}
                                {/*<span className="typeIcondesk"/>*/}
                                {/*<span className="contentType float-lft"/>*/}
                                {/*</div>*/}
                                {/*</div>*/}
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-alignrghdesk paddnone">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12   paddnone ">
                                        <a className="boardhead" style={{color:'#333'}}>{activity.value}</a>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 paddnone">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 paddnone text-right">
                                        <a className="boardheadgrey text-alignrghdesk" href="#">{new Date (parseFloat(activity.creationTime)).toLocaleString()}</a>
                                    </div>
                                </div>
                            </div>
                            <div className="brdrds"/>
                        </div>
                    );
                }
            });
        }
        return (
            <div  className="dasboxss col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12  pacboxdes smalcont" style={{marginRight: '0px'}} >
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 paddnone ">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12  ">
                            <div className="icondes">
                                <div className="limitFiltersas">
                                    <img src={activityIco} style={{height: '45px'}}/>
                                </div>
                            </div>
                            <div className="icondeshead">
                                <a className="boardheadblf noHover"href="#">Activity Log</a>
                            </div>
                        </div>
                        <div  className="brdrds"/>
                    </div>
                    <div className="dashboardWidgetContainer">
                        {allLogsHtml}
                    </div>

                    {/*<a className="lsthed" href="#">View Past Assingment</a>*/}
                </div>
            </div>
        );
    }
}


function mapStateToProps(state = {}) {
    return {...state};
}

export default connect(mapStateToProps)(WidgetActivityLog);
