import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "../components/header/header";
import { Footer } from "../components/footer/footer";
import FullSreenHeader from "./../components/header/fullScreenHeader"
import { ElasticSearchColumns, ElasticSearchTypes, SET_THEME, SET_THEME_STEM_LAB, SET_THEME_VIRTUAL } from "../actions/types";
import './virtual-theme.css'
import './stem-labs-theme.css'
import logo2 from '../assets/images/logo2.png'
import sprite from '../assets/images/sprite.png'
import sprite2 from '../assets/images/sprite-2x.png'
import spriteStudent from '../assets/images/sprite-student-portal.png'

import spriteVirtual from '../assets/images/virtual-sprite.png'
import sprite2Virtual from '../assets/images/virtual-sprite-2x.png'
import spriteStudentVirtual from '../assets/images/virtual-sprite-student-portal.png'

import spriteStem from '../assets/images/stem-sprite.png'
import sprite2Stem from '../assets/images/stem-sprite-2x.png'
import spriteStudentStem from '../assets/images/stem-sprite-student-portal.png'

import virtualLogo2 from '../assets/images/virtual-logo2.png'
import stemLabsLogo2 from '../assets/images/stem-labs-logo2.png'
import ElasticSearchQuery from "../actions/elastic-search-query";
import config from "../config";
import ProgressIndicator from "../components/progress-indicator/progress-indicator";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      credentials: null,
      themeSelected: false
    };
  }

  updateCredentials = (userToken) => {
    this.setState({
      credentials: userToken,
    });
  };

  componentDidMount(){
    let theme = localStorage.getItem('theme');
    if(theme){
      this.props.dispatch({
          type: SET_THEME,
          theme: theme
      })
      this.setState({themeSelected: true})
    }
    let userObj = JSON.parse(localStorage.getItem('userObj'));
    if(userObj && userObj.isUserTrail == 'true' && userObj.trialSchoolTheme && userObj.trialSchoolTheme != theme){
      this.props.dispatch({
          type: SET_THEME,
          theme: userObj.trialSchoolTheme
        })
      this.setState({themeSelected: true})
    } else if(userObj) {
      let elasticSearchQuery  = new ElasticSearchQuery();
        elasticSearchQuery.query(ElasticSearchTypes.School+config.stage, ElasticSearchColumns.School, this.props,
            '', 10, 1 , {}, [] , (results, totalRecords) =>{
                console.log(JSON.parse(JSON.stringify(results)),);;
              if(results){
                let school = results.find(obj=> obj.id === userObj.schoolID);
                if(school && school.schoolTheme ){
                  if(school.schoolTheme != theme){
                    localStorage.setItem('theme', school.schoolTheme)
                    this.props.dispatch({
                      type: SET_THEME,
                      theme: school.schoolTheme
                    })
                  }
                } else {
                  this.props.dispatch({
                    type: SET_THEME,
                    theme: ''
                  })
                  localStorage.setItem('theme','lp')
                }
              }
              this.setState({themeSelected: true})

            }, true, ' id: (' + userObj.schoolID+')');
    } else {
      this.props.dispatch({
          type: SET_THEME,
          theme: ''
      })
      this.setState({themeSelected: true})
    }
    
  }

  render() {
    const { isFullScreen, theme } = this.props.appSettings;
    let headerClass = '';
    let  spriteLocal = sprite;
    let  sprite2Local = sprite2;
    let  spriteStudentLocal = spriteStudent;
    let  logo2Local = logo2;
    console.log(theme);
    if(theme === 'stemLabs'){
      headerClass = 'stem-labs'
      document.getElementById('favicon').setAttribute('href', stemLabsLogo2)
      spriteLocal = spriteStem;
      sprite2Local = sprite2Stem;
      spriteStudentLocal = spriteStudentStem;
      logo2Local = stemLabsLogo2;
    } else if(theme === 'virtual'){
      headerClass = 'virtual'
      document.getElementById('favicon').setAttribute('href', virtualLogo2)
      spriteLocal = spriteVirtual;
      sprite2Local = sprite2Virtual;
      spriteStudentLocal = spriteStudentVirtual;
      logo2Local = virtualLogo2;
    } else {
      document.getElementById('favicon').setAttribute('href', logo2)
    }
    return (
      <div>
        <ProgressIndicator show={!this.state.themeSelected} />
        { this.state.themeSelected && <div className={headerClass}  style={{'--sprite2-url': `url(${sprite2Local})`, '--sprite-url':`url(${spriteLocal})`, '--student-sprite-url':`url(${spriteStudentLocal})`, '--logo2':`url(${logo2Local})`}}>
          {!isFullScreen && <Header />}
          {this.props.children}
          {!isFullScreen && <Footer />}
        </div>}
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { appSettings, auth } = state;
  return { appSettings, auth};
}
export default connect(mapStateToProps)(App);
