import  { REQUEST_SEARCH_ALL, RECEIVE_SEARCH_RESULTS , SEARCH_FAILED } from '../actions/types';

export default function searchBar(state = {results: []}, action) {
    switch (action.type) {
        case REQUEST_SEARCH_ALL:
            return {
                ...state,
                isFetching: true,
                results: []
            };
        case RECEIVE_SEARCH_RESULTS:
            return {
                ...state,
                isFetching: false,
                results: action.data
            };
        case SEARCH_FAILED:
            const status = action.data && action.data.response && action.data.response.status ? action.data.response.status : '';
            const statusText = action.data && action.data.response && action.data.response.statusText ? action.data.response.statusText : '';
            return {
                ...state,
                isFetching: false,
                error: action.data.message,
                status: status,
                statusText: statusText
            };
        default:
            return state
    }
};
