import {RECEIVED_STUDENT_ASSIGNMENT_RESULTS, FETCH_STUDENT_ASSIGNMENT_FAILED} from "./types";
import ElasticSearchStore from "./elastic-search-store";

export default class StudentPortalActions {

    static store = new ElasticSearchStore({esIndexName: 'lp-student'});

    static fetchAllStudentAssignments(dispatch, owner, type = '', options = {}
        , columns = [
            'studentID', 'type', 'keywords', 'ownerName', 'visibleTo', 'dueDate', 'assignmentStatus', 'teacher', 'assignmentName', 'assignmentID', 'assignmentType', 'activityID', 'activityType', 'activityName', 'progress'
        ], success, failure, alertText, alertStyle, showAlert) {
        StudentPortalActions.store.query(dispatch, type, options, columns, success, failure).then(results => {
            
            dispatch({
                type: RECEIVED_STUDENT_ASSIGNMENT_RESULTS,
                status: 200,
                data: JSON.parse(JSON.stringify(results.data)),
                alertText: alertText,
                alertStyle: alertStyle,
                showAlert: showAlert
            });
        }).catch(error => {
            console.error(error);
            dispatch({
                type: FETCH_STUDENT_ASSIGNMENT_FAILED,
                status: 400,
                data: error,
                alertText: alertText,
                alertStyle: alertStyle,
                showAlert: showAlert
            })
        })
    }

    static getStudentAssignment(dispatch, type = '', id = null, columns = [
        'studentID', 'type', 'keywords', 'ownerName', 'visibleTo', 'dueDate', 'assignmentStatus', 'teacher', 'assignmentName', 'assignmentID', 'assignmentType', 'activityID', 'activityType', 'activityName', 'progress'
    ], success, failure) {
        let data = {};
        if (columns && columns.length > 0) {
            data['_source'] = {
                'includes': columns
            }
        }
        if (columns && columns.length > 0) {
            data['_source'] = {
                'includes': columns
            }
        }
        StudentPortalActions.store.getRecord(dispatch, type, id, columns, success, failure);
    }

    static setSearchResult(dispatch, type, searchResult){
        dispatch({
            type:type,
            results:searchResult
        })
    }

}
