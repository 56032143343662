import  {SET_MESSAGE,SET_CREATE_COURSE_OBJ, IS_EDIT_COURSE, SEARCH_RESULT, FETCH_ALL_COURSES , RECEIVED_COURSE_RESULTS , FETCH_COURSE_FAILED,} from '../actions/types';

export default function manageCourses(state = {results: [], courseObj:{units : [],selectedSubject:'', activityState: "In Progress", courseName:''}, editCourseObj:{units:[],selectedSubject:'', activityState: "In Progress"}, isFromCourse:false, courseName:''}, action) {
    switch (action.type) {
        case SEARCH_RESULT:
            return {
                ...state,
                results: action.results,
                searchResults: action.results,
                clearFilters: true
            };
        case FETCH_ALL_COURSES:
            return {
                ...state,
                isFetching: true,
                results: []
            };
        case SET_CREATE_COURSE_OBJ:
                return {
                    ...state,
                    isFromCourse: action.isFromCourse,
                    courseObj:action.courseObj,
                    selectedUnit: action.selectedUnit
                };
        case IS_EDIT_COURSE:
            return {
                ...state,
                isEditCourse: action.isEditCourse,
                editCourseObj: action.editCourseObj
            };
        case RECEIVED_COURSE_RESULTS:
            
            if (action.skipAlterForAllRecords) {
                return {
                    ...state,
                    isFetching: false,
                    results: action.data,
                    filteredRecords: action.data,
                    alertText: action.alertText,
                    alertStyle: action.alertStyle,
                    showAlert: action.showAlert,
                    clearFilters: action.clearFilters,
                    totalRecords:action.totalRecords

                };
            } else {
                return {
                    ...state,
                    isFetching: false,
                    results: action.data,
                    allRecords: action.data,
                    searchResults: action.data,
                    filteredRecords: action.data,
                    alertText: action.alertText,
                    alertStyle: action.alertStyle,
                    showAlert: action.showAlert,
                    clearFilters: action.clearFilters,
                    totalRecords:action.totalRecords
                };
            }
        case FETCH_COURSE_FAILED:
            const status = action.data && action.data.response && action.data.response.status ? action.data.response.status : '';
            const statusText = action.data && action.data.response && action.data.response.statusText ? action.data.response.statusText : '';
            return {
                ...state,
                isFetching: false,
                error: action.data.message,
                status: status,
                statusText: statusText,
                alertText: action.alertText,
                alertStyle: action.alertStyle,
                showAlert: action.showAlert

            };
        case SET_MESSAGE:
            return {
                ...state,
                alertText: action.alertText,
                alertStyle: action.alertStyle,
                showAlert: action.showAlert
            };
        default:
            return state
    }
};
