export default class LoginUtils {
    static isAuthenticated(props, browserHistory, homeUrl, loginUrl) {

        // // not allowed pages
        // // if user is student, some pages are not allowed
        // if( props.auth.user.userType == "student" && homeUrl == "/users" ) {
        //     browserHistory.push("/student/dashboard")
        // }

        if ((props.auth && props.auth.isAuthenticated) || localStorage.getItem('userObj')) {
            // Do nothing.
        } else {
            browserHistory.push(loginUrl);
        }
    }

    static getUserAttribute(key, props) {
        
        if (localStorage.getItem('userObj')) {
            return JSON.parse(localStorage.getItem('userObj'))[key];
        } else if (props.auth.user) {
            return props.auth.user[key];
        }
        return null;
    }
}
