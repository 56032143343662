/**
 * Created by ashahab on 8/22/2017.
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import TextFieldGroup from "../../../forms/text-field-group";
import Validator from 'validator';
import apiClient from "../../../../actions/api-client";
import ProgressIndicator from "../../../progress-indicator/progress-indicator";
import './dashboard.css';
import ClevertapReact from "clevertap-react";
import { browserHistory } from 'react-router';
import { Alert } from "react-bootstrap";
import classnames from "classnames";

class WidgetRegistration extends Component {

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.handleAlertDismiss = this.handleAlertDismiss.bind(this);
        this.showIndicator = this.showIndicator.bind(this);
        this.hideIndicator = this.hideIndicator.bind(this);

        this.state = { errors: {}, userName: '', enrollmentCode: '', loading: false, showAlert: false, alertText: '', alertStyle: '' }
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    submit() {
        if (Validator.isEmpty(this.state.enrollmentCode)) {
            let errors = {};
            errors.enrollmentCode = 'This Field is required';
            this.setState(errors);
        }
        else {
            this.showIndicator();
            let that = this;
            let body = {
                userName: this.props.auth.user.userName,
                enrollmentCode: this.state.enrollmentCode,
                schoolID: this.props.auth.user.schoolID,
                firstName: this.props.auth.user.firstName,
                lastName: this.props.auth.user.lastName,
            };
            apiClient.invokeApi({}, '/user/enrollmentCode', 'PUT', {}, body, '2').then(function (result) {

                let body2 = {
                    userName: that.props.auth.user.userName,
                    enrollmentCode: that.state.enrollmentCode,
                    isNotRegistered: "true"
                };
                apiClient.invokeApi({}, '/assignment/student', 'POST', {}, body2, "5").then((result) => {

                    that.props.onRegistration(result.data.assignmentCount);

                    that.setState({ showAlert: true, alertText: result.data.message, alertStyle: "success", enrollmentCode: '' });


                    setTimeout(() => {
                        that.setState({ ...that.state, showAlert: false });
                    }, 5000);
                    that.hideIndicator();
                }).catch(function (result) {

                    that.hideIndicator();
                    that.setState({ enrollmentCode: '', showAlert: true, alertText: result.response.data, alertStyle: "danger", showEnrollmentDialogBox: false });
                    setTimeout(() => {
                        that.setState({ ...that.state, showAlert: false });
                    }, 7000);

                    that.hideIndicator();
                });
            }).catch(function (result) {
                that.hideIndicator();
                let msg = typeof result.response.data === "string" ? result.response.data : "You are already enrolled in this class";
                that.setState({ enrollmentCode: '', showAlert: true, alertText: msg, alertStyle: "danger", showEnrollmentDialogBox: false });
            });
            setTimeout(() => {
                that.setState({ ...that.state, showAlert: false });
            }, 10000);
        }
    }
    handleAlertDismiss(e) {
        this.setState({ 'showAlert': false });
    }
    showIndicator() {
        this.setState({
            ...this.state,
            loading: true
        });
    }

    hideIndicator() {
        this.setState({
            ...this.state,
            loading: false
        });
    }

    handleAlertDismiss() {
        this.setState({ showAlert: false });
    }

    render() {
        let context = this;
        return (
            <div>
                <div className="dasboxss col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12  pacboxdes">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 paddnone ">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12  ">
                                <div className="icondeshead">
                                    <a className="boardheadblf" href="#">Enrollment Code</a>
                                </div>
                            </div>
                            <div className="brdrds" />
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12  ">
                                <p>Enter your registration code below to enroll yourself into a teacher&apos;s class or activate access</p>
                                <TextFieldGroup
                                    error={this.state.errors.enrollmentCode}
                                    id="enrollmentCode"
                                    field="enrollmentCode"
                                    value={this.state.enrollmentCode}
                                    placeholder="Enter Enrollment Code and Press Enter"
                                    onChange={this.onChange}
                                    onKeyUp={function (e) {
                                        if (e.key === 'Enter') {
                                            context.submit();
                                        }
                                    }}
                                />
                                {this.state.showAlert && this.state.alertStyle === 'danger' && <div style={{ paddingBottom: '15px', color: 'Red' }}>{this.state.alertText}</div>}
                                {this.state.showAlert && this.state.alertStyle === 'success' && <div style={{ paddingBottom: '15px', color: 'Green' }}>{this.state.alertText}</div>}
                                <div style={{ textAlign: 'right', paddingBottom: '15px' }}>
                                    <a style={{ width: 'auto' }} href="/student/profile">Manage Codes</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ProgressIndicator show={this.state.loading} />
            </div>
        );
    }
}


function mapStateToProps(state = {}) {
    return { ...state };
}

export default connect(mapStateToProps)(WidgetRegistration);
