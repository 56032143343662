/**
 * Created by ashahab on 8/23/2017.
 */

import React, {Component} from "react";
import {connect} from "react-redux";
import {browserHistory} from "react-router";
import ElasticSearchStore from "../../../../actions/elastic-search-store";
import config from "../../../../config";
import ProgressIndicator from "../../../progress-indicator/progress-indicator";
import "./widget.css";
import icon from "./Martz90-Circle-Addon1-Text-plus.ico";
import courseIco from "./courseicon.png";


class WidgetCourseStudentView extends Component {

    constructor(props) {
        super(props);
        this.estore = new ElasticSearchStore();
        this.headinText = props.headinText;

        this.showIndicator = this.showIndicator.bind(this);
        this.hideIndicator = this.hideIndicator.bind(this);
        this.loadCourses = this.loadCourses.bind(this);

        this.state = {
            loading:false,

        };
    }

    componentWillMount(){
        this.loadCourses();
    }

    loadCourses() {

        let context = this;
         let visibility = '(public OR "' + this.props.auth.user.userName + '"';
         if (this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName === "LearnerPal") {
             visibility += " OR LPAdmin";
         }
         visibility += ')';
       // let visibility = 'LPAdmin';
        let subjectsToSearch = "(";
        if (this.props.auth.user.subjects) {
            this.props.auth.user.subjects.forEach(sub => {
                subjectsToSearch += "\"" + sub + "\" OR ";
            })
        }
        subjectsToSearch = subjectsToSearch.substring(0, subjectsToSearch.length - 3);
        let query = "visibleTo : " + visibility + " AND subject : " + subjectsToSearch + ")";
        this.estore.query(null, 'course' + config.stage, {
            "bool": {
                "must": {
                    "query_string": {
                        "query": query
                    }
                }
            }
        }, null, null, null, null, null).then((filterData) => {

            context.setState({
                courses: filterData.data,
                coursesSearchResult:filterData.data
            });
        }).catch(error=> {
            context.setState({
                showAlert: true,
                alertStyle: 'danger',
                alertText: 'Unable to perform search, please contact administrator.',
                loading: false
            });
            setTimeout(()=> {
                context.setState({
                    showAlert: false,
                    alertStyle: '',
                    alertText: ''
                })
            }, 4000);
        })
    }

    showIndicator() {
        this.setState({
            ...this.state,
            loading: true
        });
    }

    hideIndicator() {
        this.setState({
            ...this.state,
            loading: false
        });
    }

    render() {
        let allCourseHtml = [];
        if (this.state.courses && this.state.courses.length > 0)
        {
            let allCourses = this.state.courses;
            allCourses.forEach(course=>{
                allCourseHtml.push(<div key={Math.random()} className="row" style={{padding: '10px 0', marginLeft: '0px', marginRight: '0px',     borderBottom: '1px solid #ddd'}}>
                    <div className="icondeshead" style={{width:'60%'}}>
                        <a className="boardhead" style={{margin: '10px', marginLeft: '28px', color:'#333'}}>{course.courseName}<br /><span className="smallOnwerTag">By <strong>{course.ownerName}</strong></span></a>
                    </div>
                    <div className="icondesheadsel" style={{marginTop: '7px',textAlign: 'left'}}>
                        <a style={{margin: '-10px'}} className="dashboardCreateAssignmentBtn" onClick={()=>{
                            browserHistory.push("/courses/preview/" + course.id);
                        }} id="newActivity">View</a>
                    </div>
                </div>);
            });
        }
        return (
            <div  className="dasboxss col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{marginBottom:'0px', paddingBottom:'0px'}}>
                <div  className="col-lg-12 col-md-12 col-sm-12 col-xs-12  pacboxdes" style={{marginBottom:'0px' }}>
                    <div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12  ">
                           {/* <div className="booksRow typeIcon" style={{
                                marginTop: '10px',
                                float: 'left',
                                display: 'inline-block'
                            }}>
                            </div>*/}
                            <div className="icondes">
                                <div className="limitFiltersas">
                                    <img src={courseIco} style={{height: '45px'}}/>
                                </div>
                            </div>
                            <div className="icondeshead" style={{width:'70%'}}>
                                <a className="boardheadblf noHover">Student Course View
                                <br/>
                                    <span>(view course from Student’s view)</span>
                                </a>
                            </div>
                        </div>
                        < div  className="brdrds"/>
                    </div>
                    <div className="dashboardWidgetContainer" style={{maxHeight:'311px'}}>
                        {allCourseHtml}
                        <div className="row"/>
                    </div>
                </div>
                <ProgressIndicator show={this.state.loading}/>
            </div>
        );
    }
}


function mapStateToProps(state = {}) {
    return {...state};
}

export default connect(mapStateToProps)(WidgetCourseStudentView);
