import Validator from "validator";
import isEmpty from "lodash/isEmpty";

export default function validateInput(data, allRecords, ownerName,editId) {
    let errors = {};
    if (!data.activityType || Validator.isEmpty(data.activityType.trim())) {
        errors.activityType = 'This field is required';
    }
    let activityName = data.activityName ? data.activityName.trim().toLowerCase() : '';
    var id = activityName.replace(/\s|\W|[#$%^&*()'.]/g, "") + "_" + ownerName.toLowerCase();
    if (!data.activityName || Validator.isEmpty(data.activityName.trim())) {
        errors.activityName = 'This field is required';
    } else if (allRecords.filter(function (record) {
            editId = editId ? editId.trim().toLowerCase():'';
            return (record.id.toLowerCase() === id.toLowerCase() && record.id.toLowerCase() !== editId.toLowerCase());
        }).length > 0) {
        errors.activityName = 'Activity with the same name already exists';
    }

    if (!data.subjects || data.subjects.length === 0) {
        errors.subjectError = 'This field is required';
    }

    return {
        errors,
        isValid: isEmpty(errors)
    }
}
