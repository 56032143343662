import {
    SEARCH_RESULT,
    FETCH_ALL_ASSIGNMENTS,
    FETCH_ASSIGNMENT_FAILED,
    RECEIVED_ASSIGNMENT_RESULTS,
    SET_MESSAGE_ASSIGNMENTS
} from "../actions/types";

export default function manageAssignments(state = {results: []}, action) {
    switch (action.type) {
        case SEARCH_RESULT:
            return {
                ...state,
                results: action.results,
                searchResults: action.results,
                clearFilters: true
            };
        case FETCH_ALL_ASSIGNMENTS:
            return {
                ...state,
                isFetching: true,
                results: []
            };
        case FETCH_ASSIGNMENT_FAILED:
            return {
                ...state,
                isFetching: true,
                results: []
            };
        case RECEIVED_ASSIGNMENT_RESULTS:
            if (action.skipAlterForAllRecords) {
                return {
                    ...state,
                    isFetching: false,
                    results: action.data,
                    filteredRecords: action.data,
                    clearFilters: action.clearFilters,
                    totalRecords:action.totalRecords
                };
            } else {
                return {
                    ...state,
                    isFetching: false,
                    results: action.data,
                    searchResults: action.data,
                    allRecords: action.data,
                    filteredRecords: action.data,
                    clearFilters: action.clearFilters,
                    totalRecords:action.totalRecords
                };
            }
        case SET_MESSAGE_ASSIGNMENTS:
            return {
                ...state,
                alertText: action.alertText,
                alertStyle: action.alertStyle,
                showAlert: action.showAlert
            };
        default:
            return state
    }
};
