import React from "react";
import { connect } from "react-redux";
import ProgressIndicator from "../../progress-indicator/progress-indicator";
import Heading from "../../heading/heading";
import { RECEIVED_STUDENT_ASSIGNMENT_RESULTS, ElasticSearchTypes, ElasticSearchColumns } from "../../../actions/types";
import classnames from "classnames";
import { browserHistory } from "react-router";
import LoginUtils from "../../common/login-utils";
import MaterialIcon from "react-google-material-icons";
import { Alert, Button, Modal } from "react-bootstrap";
import apiClient from "../../../actions/api-client";
import SelectFieldGroup from "../../forms/select-field-group";
import ElasticSearchActions from "../../../actions/elastic-search-actions";
import { Parser } from "html-to-react";
import Utility from "../../maintenance-table/utility";
import excelIcon from "../microsoft-excel.png";
import excelIconHover from "../microsoft-excebl.png";
import { ActionIcons } from "../../actionIcons/action-Icons";
import { ActionIconTypes } from "../../../actions/types";
import Progress from 'react-progressbar';
// import "./activity-report-clone-student-assignment.css"
// import "./activity-report-clone-view-report.css"
import "./custom.css"
import { log } from "util";
import { 
    parseHours,
    parseMinutes,
    parseSeconds 
} from '../../../utils/timeParser';

class CourseReportActivityReport extends React.Component {

    constructor(props) {
        super(props);
        this.loadAllActivities = this.loadAllActivities.bind(this);
        this.generateStudentAnalysis = this.generateStudentAnalysis.bind(this);
        this.generateClassAnalysis = this.generateClassAnalysis.bind(this);
        this.loadClassWiseDetails = this.loadClassWiseDetails.bind(this);
        this.getStudentDetails = this.getStudentDetails.bind(this);
        // this.getActivityAttemptsDialog = this.getActivityAttemptsDialog.bind(this);
        this.getAttemptWiseAnalysis = this.getAttemptWiseAnalysis.bind(this);
        this.getUserDetailsDialog = this.getUserDetailsDialog.bind(this);
        this.exportToExcel = this.exportToExcel.bind(this);
        this.printTable = this.printTable.bind(this);
        this.i = 0;

        this.localLoaded = false;

        
        this.Loadingfunction = this.Loadingfunction.bind(this);
        this.state = {
            screenWidth: window.innerWidth, allActivities: [], selectedFilter: '', allStudentsDataSearchResult: [],
            activityAttemptsDialog: false, allActivitiesSearchResult: [], allStudentsData: {}, selectedClass: 'all', currentAttempt: 0,
            detailedView: false, contentView: 'student', currentActivity: {}, allClasses: [], userRecord: [], intervalcount: 0, loading: false, loadingReport: false
        };
        
        this.params = this.props.params;
        this.state.courseName = this.props.params.id;

        if (this.props.location.state) {
            this.state.CourseInfoBoxByStudent = this.props.location.state.CourseInfoBoxByStudent;
            this.state.courseName = this.props.location.state.courseName;
            this.state.forAdminReport = this.props.location.state.forAdminReport;
            this.state.teacherForAdminReport = this.props.location.state.teacherForAdminReport;
            this.state.allTeachersForAdminReport = this.props.location.state.allTeachersForAdminReport;
            this.state.courseForAdminReport = this.props.location.state.courseForAdminReport;
            this.state.subjectForAdminReport = this.props.location.state.subjectForAdminReport
        }

        console.log(this.state);
    }

    Loadingfunction() {
        {
            let context = this;

            setTimeout(function () {

                let tempNum = Math.floor(Math.random() * 10) + 5;

                context.i += tempNum;
                if (context.localLoaded)
                    context.i = 101;

                if (context.i < 100) {
                    context.setState({ intervalcount: context.i })

                    context.Loadingfunction();
                }
            }, 2000)
        }
    }


    componentDidMount() {
        LoginUtils.isAuthenticated(this.props, browserHistory, '/student/assignments', '/');
        const { dispatch } = this.props;
        ElasticSearchActions.setElasticSearchAttributes(dispatch, "studentActivityReport", '', 10, 1, { _score: { order: "desc" } }, []);
        this.loadAllActivities();

        // checking the re-render problem
        // setInterval( () => {
        //     console.log('ERROR-------------------------->')
        //     this.setState({})
        // }, 3000)
    }

    getMainContent(contentView) {

        if (contentView === 'student') {
            return (
                this.generateStudentAnalysis()
            )
        } else if (contentView === 'classes' && this.state.classAnalysisData) {
            return (
                this.generateClassAnalysis(this.state.classAnalysisData)
            )
        }
    }

    // getActivityAttemptsDialog() {
    //     let record = this.state.userRecord;
    //     let activityName = this.state.currentActivity.activityName;
    //     let studentName = record[0].studentName;
    //
    //     let rows = null;
    //     let rowCount = 0;
    //     if (record) {
    //         rows = record.map(attempt => {
    //             rowCount++;
    //             return <tr key={Math.random()}>
    //                 <td>
    //                     {attempt.score && parseFloat(attempt.score).toFixed(0) + '%'}
    //                 </td>
    //                 <td>
    //                     {attempt.progress}
    //                 </td>
    //                 <td>
    //                     {attempt.timer && <td>{Math.floor(attempt.timer / 3600) + ':' + Math.floor(((attempt.timer % 3600) / 60)) + ':' + ((attempt.timer % 3600) % 60)}</td>}
    //                 </td>
    //                 <td>
    //                     {new Date(attempt.completionDate).toLocaleString()}
    //                 </td>
    //             </tr>;
    //         })
    //     }
    //
    //     let activityStats = <table className="table" id="act-stats-user">
    //         <tbody>
    //         <tr>
    //             <td><MaterialIcon icon="assignment" size={24}/> Activity Name:</td>
    //             <td>{activityName}</td>
    //         </tr>
    //         <tr>
    //             <td><MaterialIcon icon="assignment" size={24}/> Student Name:</td>
    //             <td>{studentName}</td>
    //         </tr>
    //         </tbody>
    //     </table>;
    //     let studentStats = <table className="table" id="student-attempt-history" style={{ border:'1px solid #555555'}}>
    //         <thead>
    //         <tr style={{background: '#f7f8f9', border:'1px solid #555555'}}>
    //             <th>
    //                 Score
    //             </th>
    //             <th>
    //                 Progress
    //             </th>
    //             <th>
    //                 Time In
    //             </th>
    //             <th>
    //                 Completion Date
    //             </th>
    //         </tr>
    //         </thead>
    //         <tbody>
    //         {rows}
    //         </tbody>
    //     </table>;
    //
    //     let table = <div id="user-activity-analysis" className="row">
    //         <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
    //             <p style={{overflow:'auto'}}>
    //                 {activityStats}
    //             </p>
    //         </div>
    //         <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
    //             <h1 className="reportHeading">Attempt Details </h1>
    //             <p style={{overflow: 'auto'}}>
    //                 {studentStats}
    //             </p>
    //         </div>
    //     </div>;
    //     return <Modal id="user-attempt-history-dialog"
    //                   show={this.state.activityAttemptsDialog}
    //                   onHide={()=> {
    //                       this.setState({activityAttemptsDialog: false, userRecord: []});
    //                   }}
    //                   container={this}
    //                   aria-labelledby="contained-modal-title">
    //         <Modal.Header closeButton>
    //             <Modal.Title id="delete-activity-modal">Attempt History</Modal.Title>
    //         </Modal.Header>
    //         <Modal.Body>
    //             <div id="choice-dialog-content">
    //                 {table}
    //             </div>
    //         </Modal.Body>
    //         <Modal.Footer>
    //             <Button onClick={()=> {
    //                 this.setState({activityAttemptsDialog: false, userRecord: []});
    //             }}>Close</Button>
    //         </Modal.Footer>
    //     </Modal>
    // }

    getUserDetailsDialog() {
        let context = this;
        let record = this.state.userRecord[this.state.currentAttempt];
        let userRecordFromAssignmentScreen = this.state.userRecordFromAssignmentScreen;
        let userRecordActivityID = this.state.userRecordActivityID;
        let dialogViewActivity = null;
        let actType = null;
        if (userRecordFromAssignmentScreen) {
            // from main assignment snapshot screen.
            dialogViewActivity = this.state.assignment.activities.filter(act => { return act.activityID === userRecordActivityID })[0];
            actType = dialogViewActivity.activityType;
        } else {
            // from inner student details table.
            dialogViewActivity = this.state.currentActivity;
            actType = dialogViewActivity.activityType;
        }
        let categories = null;
        let catCount = 0;
        if (record.categoryStats) {
            categories = record.categoryStats.map(cat => {
                catCount++;
                let label = cat.category + (cat.subCategory ? ' - ' + cat.subCategory : '');
                return <tr key={cat.category + '-' + catCount} id={cat.category + '-' + catCount}>
                    <td>{label}:</td>
                    <td>
                        {/*{(cat.Dinominator?cat.Dinominator.length:0)  + ' out of ' + cat.Numanator + ' questions correct (' + cat.score.toFixed(0) + '%)'}*/}
                        {cat.score.toFixed(0) + '%' + ' (' + cat.correctAnswers + ' out of ' + cat.total + ' questions correct)'}
                        {/*{cat.score.toFixed(0) + '%'}*/}
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12   paddnone barchrtlghtbl"
                            style={{ marginRight: '10px', marginTop: '5px', background: 'white' }}>
                            <div className="filled" style={{ width: (cat.score ? cat.score : 0) + '%', background: (cat.score && cat.score > 64 ? 'green' : 'red') }} />
                        </div>
                    </td>
                </tr>;
            })
        }

        let catStats = <table className="table" id="act-cat-stats-user">
            <tbody>
                {categories}
            </tbody>
        </table>;
        let rows = null;
        let correctNumbers = 0;
        let rowCount = 0;
        let htmlToReactParser = new Parser();
        if (record.questionStats) {
            rows = record.questionStats.map(question => {
                rowCount++;

                if (question.essayType && question.essayType == "true") {
                    if (question.essayScore != "N/A") {
                        question.questionResult = question.essayScore < 65 ? false : true
                    }
                    if (!question.essayComments && question.essayScore && question.essayScore != "N/A") {
                        question.essayComments = "<i>No Comments</i>"
                    }
                }

                if (question.questionResult) {
                    correctNumbers += 1;
                }

                let answerString = undefined;

                if (actType === 'question') {
                    answerString = (question.studentAnswer.trim() !== 'empty-result' && question.studentAnswer.trim() !== 'empty-answer') ? question.studentAnswer : 'No Answer Submitted';
                }
                return <tr dir lang = { this.props.auth.user.prefLanguage }  className="" id={rowCount + "-user-row-" + rowCount}
                    key={rowCount + "-user-row-" + question.questionID}>
                    <td style={{ border: '1px solid #555555' }}>
                        <p className="QuestionNoBorder">
                            <strong style={{ float: 'left' }}>{rowCount + '. '} {question.questionResult ? <span style={{ color: 'green' }}><MaterialIcon icon="check" size={32} /></span> : <span style={{ color: 'red' }}><MaterialIcon icon="close" size={32} /></span>}</strong>{htmlToReactParser.parse('<span>' + question.question.replace('[[[LP_ANSWERS]]]', '') + '</span>')}
                        </p>
                        <br />

                        {question.answer && <div>
                            <div className="std-q-heading">Correct Answer</div>
                            <p>
                                {htmlToReactParser.parse('<span>' + question.answer + '</span>')}
                            </p>
                        </div>
                        }

                        <div className="std-q-heading">Student&apos;s Response</div>
                        <p style={{ whiteSpace: "pre-line", textAlign: "justify" }}>
                            {htmlToReactParser.parse('<span>' + answerString + '</span>')}
                        </p>

                        {question.essayType && question.essayType == "true" && question.essayComments ? <div>
                            <div className="std-q-heading">Teacher&apos;s Comment</div>
                            <p>
                                {htmlToReactParser.parse('<span>' + question.essayComments + '</span>')}
                            </p>
                        </div> : ""
                        }

                        {question.essayType && question.essayType == "true" && question.essayScore ? <div>
                            <div className="std-q-heading">Score</div>
                            <p>
                                {question.essayScore == "N/A" ? htmlToReactParser.parse('<span>' + question.essayScore + '</span>') : ( question.essayScore < 65 ? (htmlToReactParser.parse('<span style=" color: red; ">' + question.essayScore + '</span>')) : (htmlToReactParser.parse('<span style=" color: green; ">' + question.essayScore + '</span>')) )}
                            </p>
                        </div> : ""
                        }

                    </td>

                    {false && dialogViewActivity.activityType === 'question' &&
                        <td>
                            <a href="javascript:void 0" onClick={function () {
                                this.userQuestionDetails(question)
                            }.bind(this)}><MaterialIcon icon="search" size={24} /></a>
                        </td>
                    }
                </tr>;
            })
        }

        let activityStats = <table className="table" id="act-stats-user">
            <tbody>
                <tr>
                    <td><MaterialIcon icon="assignment" size={24} /> Activity Name:</td>
                    <td>{dialogViewActivity.activityName}</td>
                </tr>
                <tr>
                    <td><MaterialIcon icon="accessibility" size={24} /> Student Name:</td>
                    <td>{record.studentName}</td>
                </tr>
                <tr>
                    <td><MaterialIcon icon="assessment" size={24} /> Score:</td>
                    <td>{record.score.toFixed(0) + '% (' + correctNumbers + ' out of ' + (record.questionStats ? record.questionStats.length : 0) + ' questions correct)'}</td>
                </tr>
                <tr>
                    <td><MaterialIcon icon="alarm" size={24} /> Time In:</td>
                    <td>
                        {parseHours(record.timeIn)}{' hours '}
                        {parseMinutes(record.timeIn)}{' mins '}
                        {parseSeconds(record.timeIn)}{' seconds'}
                    </td>
                </tr>
                <tr>
                    <td><MaterialIcon icon="date_range" size={24} /> Completion Date:</td>
                    {record.completionDate !== "N/A" && <td>{new Date(record.completionDate).toLocaleString()}</td>}
                    {record.completionDate === "N/A" && <td>{record.completionDate}</td>}
                </tr>
            </tbody>
        </table>;

        let studentStats = <table className="table" id="student-stat">
            <thead>
                <tr>
                    <th style={{ background: '#f7f8f9', border: '1px solid #555555' }}>
                        Questions
                </th>
                    {dialogViewActivity.activityType === 'question' && false && <th>
                    </th>}
                </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </table>;

        let table = <div id="user-activity-analysis" className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <h1 className="reportHeading">Student Details </h1>
                <p style={{ overflow: 'auto' }}>
                    {activityStats}
                </p>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <h1 className="reportHeading"  >Category Analysis </h1>
                <p style={{ overflow: 'auto' }}>
                    {catStats}
                </p>
            </div>
            {actType === 'question' &&
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <h1 className="reportHeading">Student Question Analysis </h1>
                    <p style={{ overflow: 'auto' }}>
                        {studentStats}
                    </p>
                </div>}
        </div>;

        // let attemptsArray = [];
        // let allStudentsData = this.state.allStudentsData.students;
        
        // let index = allStudentsData.findIndex(x=> x[0].studentID === record.studentID);
        //
        // allStudentsData[index].sort(function (a,b) {
        //     return new Date(b.completionDate) - new Date(a.completionDate);
        // });
        //
        // if (index !== -1)
        // {
        //     allStudentsData[index].forEach(attempt=>{
        //         attemptsArray.push({id:attempt.assignmentID+':'+attempt.studentID, name:new Date(attempt.completionDate).toLocaleString()})
        //     });
        // }
        let attemptsArray = [];
        let allStudentsData = this.state.userRecord;

        allStudentsData.sort(function (a, b) {
            return new Date(b.completionDate) - new Date(a.completionDate);
        });
        let counter = 0;

        allStudentsData.forEach(attempt => {
            attemptsArray.push({ id: counter, name: new Date(attempt.completionDate).toLocaleString() });
            counter++;
        });

        return <Modal id="activity-report-dialog" style={{ 'margin-top': "-92px" }}
            show={this.state.showUserDetailsDialog}
            onHide={() => {
                this.setState({ showUserDetailsDialog: false, userRecord: [], userRecordFromAssignmentScreen: null, userRecordActivityID: null });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="activity-report-dialog-Title">Student Details</Modal.Title>
                {attemptsArray.length > 1 && <div className="col-md-4" style={{ marginTop: '20px' }}>
                    <SelectFieldGroup
                        field="currentAttempt"
                        value={this.state.currentAttempt}
                        options={attemptsArray}
                        onChange={(event) => {
                            context.setState({ currentAttempt: event.target.value });
                            // context.setState({showUserDetailsDialog:false,currentAttempt:value}, function () {
                            //     let rawData = value.split(':');
                            //     let assignmentID = rawData[0];
                            //     let studentID = rawData[1];
                            //     context.getAttemptWiseAnalysis(assignmentID, studentID, context.state.currentActivity.activityID);
                            // });
                        }}
                    />
                </div>}
            </Modal.Header>
            <Modal.Body >
                {table}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    this.setState({ showUserDetailsDialog: false, userRecord: [], userRecordFromAssignmentScreen: null, userRecordActivityID: null });
                }}>Close</Button>
            </Modal.Footer>
        </Modal>
    }

    getAttemptWiseAnalysis(studentObj, activityID, index = 0) {
        let context = this;

        if (studentObj[index].assignmentID) {
            this.showIndicator();
            let params = {
                assignmentID: studentObj[index].assignmentID,
                studentID: studentObj[index].studentID,
                activityID: activityID
            };
            let pathTemplate = '/assignment/report/assignmentID/{assignmentID}/activity/{activityID}/student/{studentID}';
            let method = 'GET';
            let additionalParams = {};
            let body = {};
            apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, '3').then(function (result) {
                context.hideIndicator();
                let userRecord = context.state.userRecord;
                result.data.forEach(attempt => {
                    userRecord.push(attempt);
                });
                context.setState({
                    userRecord: userRecord,
                    showUserDetailsDialog: true,
                    userRecordFromAssignmentScreen: false,
                    userRecordActivityID: activityID,
                    classAnalysisData: null, contentView: 'student'
                }, function () {
                    index++;
                    if (index < studentObj.length) {
                        context.getAttemptWiseAnalysis(studentObj, activityID, index);
                    }
                });
            }).catch(function (error) {
                context.hideIndicator();
                context.setState({ loadingReport: false });
                context.setState({
                    showAlert: true,
                    alertStyle: 'danger',
                    alertText: 'Unable to load data, please contact administrator.'
                });
                setTimeout(() => {
                    context.setState({
                        showAlert: false,
                        alertStyle: '',
                        alertText: ''
                    })
                }, 4000);
            });
        }
        else {
            context.setState({
                showAlert: true,
                alertStyle: 'danger',
                alertText: studentObj[index].studentName + ' has not started/completed the activity'
            });
            setTimeout(() => {
                context.setState({
                    showAlert: false,
                    alertStyle: '',
                    alertText: ''
                })
            }, 4000);
        }
    }

    generateStudentAnalysis() {
        let context = this;
        let rows = [];

        let passedStudents = 0;
        let failedStudents = 0;
        let incompleteStudents = 0;
        let numberOfStudents = 0;
        let averageScore = 0;
        let averageTime = 0;

        let averageCounter = 0;

        this.state.allStudentsDataSearchResult.forEach(student => { 
            numberOfStudents++;
            if (student.length > 0) {
                if (student[0].progress === 100) {
                    averageCounter++;
                    averageScore += student[0].score;
                    averageTime += student[0].timer;
                }
                if (student[0].score && student[0].score > 0) {
                    if (student[0].score.toFixed(0) < 65) {
                        failedStudents++;
                    }
                    else if (student[0].score.toFixed(0) >= 65) {
                        passedStudents++;
                    }
                }
                else {
                    incompleteStudents++;
                }
            }
            else {
                incompleteStudents++;
            }

            student = student.sort(function (a, b) {
                return new Date(b.completionDate) - new Date(a.completionDate);
            });

            if (student.length > 0) {

                rows.push(<tr key={Math.random()}>
                    <td>
                        {student[0].studentName}
                    </td>
                    <td>
                        {student[0].periodName ? student[0].periodName : ''}
                    </td>
                    <td>
                        {student[0].progress ? student[0].progress.toFixed(0) : '0'}{'%'}
                    </td>
                    <td>
                        {student[0].score ? student[0].score.toFixed(0) : '0'}{'%'}
                    </td>
                    <td>
                        {(student[0].timer ? 
                        (
                            parseHours(student[0].timer) + ' hours ' +
                            parseMinutes(student[0].timer) + ' mins ' +
                            parseSeconds(student[0].timer) + ' seconds'
                        ) : 'N/A')}
                    </td>
                    <td>{student[0].completionDate && student[0].completionDate !== 'N/A' ? new Date(parseFloat(student[0].completionDate)).toLocaleString() : 'N/A'}</td>
                    <td style={{ width: '25%' }}>
                        {
                            <a href="javascript:void 0" onClick={() => {
                                context.getAttemptWiseAnalysis(student, this.state.currentActivity.activityID);
                            }}><MaterialIcon icon="search" size={24} /></a>
                        }
                    </td>
                </tr>);
            }
        });

        // averageScore = averageScore / averageCounter;
        // averageTime = averageTime / averageCounter;

        averageScore = averageScore / numberOfStudents;
        averageTime = averageTime / numberOfStudents;


        let studentStats =
            <div id="studentActivityAnalysis">
                <table className="table" id="student-stat">
                    <thead>
                        <tr>
                            <th width='20%' data-activity-id='studentName' data-order="asc" onClick={function (evt) {
                                let allData = this.state.allStudentsDataSearchResult;
                                let order = evt.target.getAttribute('data-order');
                                if (order === 'asc') {
                                    evt.target.setAttribute('data-order', 'dsc');
                                    allData.sort(function (a, b) {
                                        return (a[0].studentName.localeCompare(b[0].studentName));
                                    });
                                } else {
                                    evt.target.setAttribute('data-order', 'asc');
                                    allData.sort(function (a, b) {
                                        return (b[0].studentName.localeCompare(a[0].studentName));
                                    });
                                }
                                context.setState({ allStudentsDataSearchResult: allData });
                            }.bind(this)}>
                                Student<span className="caret caret-close" />
                            </th>
                            <th width='10%' data-activity-id='studentName' data-order="asc" onClick={function (evt) {
                                let allData = this.state.allStudentsDataSearchResult;
                                let order = evt.target.getAttribute('data-order');
                                if (order === 'asc') {
                                    evt.target.setAttribute('data-order', 'dsc');
                                    allData.sort(function (a, b) {
                                        return (a[0].periodName.localeCompare(b[0].periodName));
                                    });
                                } else {
                                    evt.target.setAttribute('data-order', 'asc');
                                    allData.sort(function (a, b) {
                                        return (b[0].periodName.localeCompare(a[0].periodName));
                                    });
                                }
                                context.setState({ allStudentsDataSearchResult: allData });
                            }.bind(this)}>
                                Period<span className="caret caret-close" />
                            </th>
                            <th width='10%' data-activity-id='progress' data-order="asc" onClick={function (evt) {
                                let allData = this.state.allStudentsDataSearchResult;
                                let order = evt.target.getAttribute('data-order');
                                if (order === 'asc') {
                                    evt.target.setAttribute('data-order', 'dsc');
                                    allData.sort(function (a, b) {
                                        return (a[0].progress - b[0].progress);
                                    });
                                } else {
                                    evt.target.setAttribute('data-order', 'asc');
                                    allData.sort(function (a, b) {
                                        return (b[0].progress - a[0].progress);
                                    });
                                }
                                context.setState({ allStudentsDataSearchResult: allData });
                            }.bind(this)}>
                                Progress<span className="caret caret-close" />
                            </th>
                            <th width='10%' data-activity-id='score' data-order="asc" onClick={function (evt) {
                                let allData = this.state.allStudentsDataSearchResult;
                                let order = evt.target.getAttribute('data-order');
                                if (order === 'asc') {
                                    evt.target.setAttribute('data-order', 'dsc');
                                    allData.sort(function (a, b) {
                                        return (a[0].score - b[0].score);
                                    });
                                } else {
                                    evt.target.setAttribute('data-order', 'asc');
                                    allData.sort(function (a, b) {
                                        return (b[0].score - a[0].score);
                                    });
                                }
                                context.setState({ allStudentsDataSearchResult: allData });
                            }.bind(this)}>
                                Score<span className="caret caret-close" />
                            </th>
                            <th width='15%' data-activity-id='score' data-order="asc" onClick={function (evt) {
                                let allData = this.state.allStudentsDataSearchResult;
                                let order = evt.target.getAttribute('data-order');
                                if (order === 'asc') {
                                    evt.target.setAttribute('data-order', 'dsc');
                                    allData.sort(function (a, b) {
                                        return (a[0].timer - b[0].timer);
                                    });
                                } else {
                                    evt.target.setAttribute('data-order', 'asc');
                                    allData.sort(function (a, b) {
                                        return (b[0].timer - a[0].timer);
                                    });
                                }
                                context.setState({ allStudentsDataSearchResult: allData });
                            }.bind(this)}>
                                Time In<span className="caret caret-close" />
                            </th>
                            <th width='15%' data-activity-id='timeIn' data-order="asc" onClick={function (evt) {
                                let allData = this.state.allStudentsDataSearchResult;
                                let order = evt.target.getAttribute('data-order');
                                if (order === 'asc') {
                                    evt.target.setAttribute('data-order', 'dsc');
                                    allData.sort(function (a, b) {
                                        return (new Date(a[0].completionDate) - new Date(b[0].completionDate));
                                    });
                                } else {
                                    evt.target.setAttribute('data-order', 'asc');
                                    allData.sort(function (a, b) {
                                        return (new Date(b[0].completionDate) - new Date(a[0].completionDate));
                                    });
                                }
                                context.setState({ allStudentsDataSearchResult: allData });
                            }.bind(this)}>
                                Completion Date<span className="caret caret-close" />
                            </th>
                            <th width="10%">
                                View Details
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                </table>
            </div>;

        let activityStats = <table className="table" id="act-stat">
            <tbody>
                <tr>
                    <td><MaterialIcon icon="assignment" size={24} /> Activity Name:</td>
                    <td>{this.state.currentActivity.activityName}</td>
                    <td width="35%"><MaterialIcon icon="supervisor_account" size={24} /><strong>Total # of students:</strong></td>
                    <td width="10%">{numberOfStudents}</td>
                </tr>
                <tr>
                    <td><MaterialIcon icon="timer" size={24} /> Period:</td>
                    <td>{this.state.selectedClass === 'all' ? this.state.assignedClass : this.state.selectedClass}</td>
                    <td width="35%"><MaterialIcon icon="done" size={24} /><strong># of passed students:</strong></td>
                    <td width="10%">{passedStudents}</td>
                </tr>
                <tr>
                    <td><MaterialIcon icon="assessment" size={24} /> Avg Score:</td>
                    {this.state.selectedClass === 'all' && <td>{this.state.currentActivity.avgScore ? this.state.currentActivity.avgScore.toFixed(0) : '0'}{'%'}</td>}
                    {this.state.selectedClass !== 'all' && <td>{averageScore ? averageScore.toFixed(0) : 0}%</td>}
                    <td width="35%"><MaterialIcon icon="highlight_off" size={24} /><strong># of failed students:</strong></td>
                    <td width="10%">{failedStudents}</td>
                </tr>
                <tr>
                    <td><MaterialIcon icon="alarm" size={24} /> Avg Time In:</td>
                    <td>
                        {averageTime ? (parseHours(averageTime) + ' hours '
                            + parseMinutes(averageTime) + ' mins '
                            + parseSeconds(averageTime) + ' seconds')
                            : 'N/A'}</td>
                    <td width="35%"><MaterialIcon icon="eject" size={24} /><strong># of incomplete students:</strong></td>
                    <td width="10%">{incompleteStudents}</td>
                </tr>
            </tbody>
        </table>;
        return (
            <div id="student-activity-analysis" className="row" style={{ zIndex: '-100' }}>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <a className="boardheadblff noHover">Activity Overview </a>
                    <p style={{ overflow: 'auto' }}>
                        {activityStats}
                    </p>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12" style={{ marginTop: '-12px' }}>
                        <a className="boardheadblff noHover">Student Activity Analysis </a>
                    <div style={{ float: 'right', marginBottom: '10px' }}>
                        <span onMouseOver={(event) => { event.target.firstElementChild.src = excelIconHover; }} onMouseOut={(event) => { event.target.firstElementChild.src = excelIcon; }}
                            className="detailPrintForReport" data-toggle="tooltip" title="Export Report To Excel" data-placement="bottom" data-original-title="Print"
                            onClick={() => {
                                this.exportToExcel('studentActivityAnalysis', true);
                            }}>
                            <img src={excelIcon} style={{ width: '43px' }} />
                        </span>
                        <ActionIcons iconType={ActionIconTypes.Print} action={function () {
                            context.printTable('studentActivityAnalysis', true);
                        }} />
                    </div>
                    <p style={{ overflow: 'auto', marginTop: '20px' }}>
                        {studentStats}
                    </p>
                </div>
            </div>
        )
    }

    exportToExcel(tableID, removeLastHeader) {
        //EXPORT TO EXCEL
        let data_type = 'data:application/vnd.ms-excel';
        let table_div = document.getElementById(tableID).cloneNode(true);

        if (removeLastHeader) {
            table_div.firstElementChild.firstElementChild.firstElementChild.removeChild(table_div.firstElementChild.firstElementChild.firstElementChild.lastChild);
        }
        for (let i = 0; i < table_div.firstElementChild.lastChild.children.length; i++) {
            for (let j = 0; j < table_div.firstElementChild.lastChild.children[i].children.length; j++) {
                if (table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild &&
                    table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild.firstElementChild) {
                    table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild.removeChild(table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild.firstElementChild);
                }
            }
        }
        let table_html = table_div.outerHTML.replace(/ /g, '%20');
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.href = data_type + ', ' + table_html;
        a.download = 'Student Activity Analysis-' + this.state.currentActivity.activityName + '.xls';
        a.click();
    }

    printTable(tableID, removeLastHeader) {
        //PRINT TABLE
        let table_div = document.getElementById(tableID) ? document.getElementById(tableID).cloneNode(true) : null;
        if (table_div) {
            if (removeLastHeader) {
                table_div.firstElementChild.firstElementChild.firstElementChild.removeChild(table_div.firstElementChild.firstElementChild.firstElementChild.lastChild);
            }
            for (let i = 0; i < table_div.firstElementChild.lastChild.children.length; i++) {
                for (let j = 0; j < table_div.firstElementChild.lastChild.children[i].children.length; j++) {
                    if (table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild &&
                        table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild.firstElementChild) {
                        table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild.removeChild(table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild.firstElementChild);
                    }
                }
            }
            let myWindow = window.open('', 'PRINT', 'height=600,width=800');
            myWindow.document.write('<html><head><title>' + 'Learner Pal' + '</title>');
            myWindow.document.write('<h1>' + 'Activity Report' + '</h1>');
            myWindow.document.write(table_div.outerHTML);
            myWindow.document.write('</body></html>');
            myWindow.focus(); // necessary for IE >= 10*/
            setTimeout(function () {
                myWindow.print();
                myWindow.document.close(); // necessary for IE >= 10
                myWindow.close();
            }, 1000);
        }
        else {
            this.setState({
                showAlert: true,
                alertStyle: 'danger',
                alertText: 'Content not available, can not print. Please contact administrator.'
            });
            setTimeout(() => {
                this.setState({
                    showAlert: false,
                    alertStyle: '',
                    alertText: ''
                })
            }, 5000);
        }
    }

    getStudentDetails(activity) {
        let context = this;
        context.showIndicator();
        let params = {
            userName: context.state.teacherForAdminReport ? context.state.teacherForAdminReport.id : this.props.auth.user.userName,
            activityID: activity.activityID,
            courseID: context.state.courseForAdminReport ? context.state.courseForAdminReport.id : this.params.id
        };
        let pathTemplate = '/activity/report/username/{userName}/activityID/{activityID}/courseID/{courseID}';
        let method = 'GET';
        let additionalParams = {};
        let body = {};
        apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, '4').then(function (result) {
            let allStudentsData = result.data;


            for (let a = 0; a < allStudentsData.students.length; a++) {
                if (allStudentsData.students[a].length) {

                }
                else {
                    allStudentsData.students.splice(a, 1);
                    a--;
                }
            }
            result.data.assignStds.forEach(student => {
                if (allStudentsData.students.findIndex(x => x[0].studentID === student.studentID) === -1) {
                    let tempArray = [];
                    tempArray.push(student);
                    allStudentsData.students.push(tempArray);
                }
            });
            allStudentsData.students.sort(function (a, b) {
                return a[0].studentName.localeCompare(b[0].studentName);
            });
            context.setState({ allStudentsData: allStudentsData, allStudentsDataSearchResult: allStudentsData.students, detailedView: true, currentActivity: activity });

            context.hideIndicator();
        }).catch(function (error) {
            context.hideIndicator();
            context.setState({
                showAlert: true,
                alertStyle: 'danger',
                alertText: error.response ? error.response.data.errors[0].message : error.message
            });
            setTimeout(() => {
                context.setState({
                    showAlert: false,
                    alertStyle: '',
                    alertText: ''
                })
            }, 4000);
        });
    }

    generateClassAnalysis(classAnalysisData) {
        let htmlParser = new Parser();

        let categories = null;
        let catCount = 0;
        let allStudentsData = this.state.allStudentsDataSearchResult;

        let passedStudents = 0;
        let failedStudents = 0;
        let incompleteStudents = 0;
        let numberOfStudents = 0;
        let averageScore = 0;
        let averageTime = 0;
        let averageCounter = 0;

        allStudentsData.forEach(student => {
            numberOfStudents++;
            if (student.length > 0) {
                if (student[0].progress === 100) {
                    averageCounter++;
                    averageScore += student[0].score;
                    averageTime += student[0].timer;
                }
                if (student[0].score && student[0].score > 0) {
                    if (student[0].score.toFixed(0) < 65) {
                        failedStudents++;
                    }
                    else if (student[0].score.toFixed(0) >= 65) {
                        passedStudents++;
                    }
                }
                else {
                    incompleteStudents++;
                }
            }
            else {
                incompleteStudents++;
            }
        });

        if (classAnalysisData.categoryStats) {
            categories = classAnalysisData.categoryStats.map(cat => {
                catCount++;
                let label = cat.category + (cat.subCategory ? ' - ' + cat.subCategory : '');
                return <tr key={cat.category + '-' + catCount} id={cat.category + '-' + catCount}>
                    <td>{label}:</td>
                    <td>
                        {/*{(cat.Dinominator?cat.Dinominator.length:0) + ' out of ' + cat.Numanator + ' questions correct (' + cat.score.toFixed(0) + '%)'}*/}
                        {cat.score.toFixed(0) + '%'}
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12   paddnone barchrtlghtbl"
                            style={{ marginRight: '10px', marginTop: '5px', background: 'white' }}>
                            <div className="filled" style={{ width: (cat.score ? cat.score : 0) + '%', background: (cat.score && cat.score > 64 ? 'green' : 'red') }} />
                        </div>
                    </td>
                </tr>;
            })
        }

        let catStats = <table className="table" id="act-cat-stats-user">
            <tbody>
                {categories}
            </tbody>
        </table>;

        let rows = null;
        let rowCount = 0;
        if (classAnalysisData.questionStats) {
            rows = classAnalysisData.questionStats.map(question => {
                rowCount++;
                let choiceCount = 0;
                let correctChoice = null;
                let choices = question.choiceStats.map(ch => {
                    choiceCount++;
                    if (ch.isCorrect) {
                        correctChoice = ch;
                    }
                    return <li key={ch.choice} style={{ padding: '15px' }}><strong>{choiceCount + '. '}</strong>{htmlParser.parse(ch.choice)} <span className={ch.isCorrect ? 'correctChoice' : 'wrongChoice'} style={{ float: 'right', marginRight: '20px' }}>{ch.answerCount} Answers</span></li>;
                });

                return <tr className="" id={rowCount + "-row-" + rowCount} key={rowCount + "-row-" + question.questionID}>
                    <td className="questionTD">
                        <div className="correctAnswerStatement">
                            <span>{correctChoice && correctChoice.answerCount ? correctChoice.answerCount : 0} students answered correctly</span>
                        </div>
                        <strong style={{ float: 'left', position: 'absolute', marginTop: '28px' }}>{rowCount + '. '}</strong><span style={{ display: 'block', marginLeft: '20px', marginTop: '28px' }}>{htmlParser.parse("" + question.question.replace('[[[LP_ANSWERS]]]', ''))}</span>
                        {/* <br /> */}
                        <p>
                            <ol>
                                {choices}
                            </ol>
                        </p>
                    </td>
                </tr>;
            })
        }

        let studentStats = <table className="table" id="student-stat">
            <thead>
                <tr style={{ background: '#f7f8f9' }}>
                    <th style={{ border: '1px solid #555555' }} data-activity-id='question' width="70%" data-order="asc" onClick={function (evt) {

                    }.bind(this)}>
                        Question
                </th>
                </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </table>;

        // averageScore = averageScore / averageCounter;
        // averageTime = averageTime / averageCounter;

        averageScore = averageScore / numberOfStudents;        
        averageTime = averageTime / numberOfStudents;


        let activityStats = <table className="table" id="act-stat">
            <tbody>
                <tr>
                    <td><MaterialIcon icon="assignment" size={24} /> Activity Name:</td>
                    <td>{this.state.currentActivity.activityName}</td>
                    <td width="35%"><MaterialIcon icon="supervisor_account" size={24} /><strong>Total # of students:</strong></td>
                    <td width="10%">{numberOfStudents}</td>
                </tr>
                <tr>
                    <td><MaterialIcon icon="timer" size={24} /> Period:</td>
                    <td>{this.state.selectedClass === 'all' ? this.state.assignedClass : this.state.selectedClass}</td>
                    <td width="35%"><MaterialIcon icon="done" size={24} /><strong># of passed students:</strong></td>
                    <td width="10%">{passedStudents}</td>
                </tr>
                <tr>
                    <td><MaterialIcon icon="assessment" size={24} /> Avg Score:</td>
                    {this.state.selectedClass === 'all' && <td>{this.state.currentActivity.avgScore ? this.state.currentActivity.avgScore.toFixed(0) : '0'}{'%'}</td>}
                    {this.state.selectedClass !== 'all' && <td>{averageScore ? averageScore.toFixed(0) : 0}%</td>}
                    <td width="35%"><MaterialIcon icon="highlight_off" size={24} /><strong># of failed students:</strong></td>
                    <td width="10%">{failedStudents}</td>
                </tr>
                <tr>
                    <td><MaterialIcon icon="alarm" size={24} /> Avg Time In:</td>
                    <td>{averageTime ? 
                        (
                            parseHours(averageTime) + ' hours '
                            + parseMinutes(averageTime) + ' mins '
                            + parseSeconds(averageTime) + ' seconds'
                        )
                        : 'N/A'}</td>
                    <td width="35%"><MaterialIcon icon="eject" size={24} /><strong># of incomplete students:</strong></td>
                    <td width="10%">{incompleteStudents}</td>
                </tr>
            </tbody>
        </table>;

        return (
            <div id="activity-analysiss" className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <a className="boardheadblff noHover">Activity Overview </a>
                    <p style={{ overflow: 'auto' }}>
                        {activityStats}
                    </p>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <a className="boardheadblff noHover">Category Analysis </a>
                    <p style={{ overflow: 'auto' }}>
                        {catStats}
                    </p>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <a className="boardheadblff noHover">Class Question Analysis </a>
                    <p style={{ overflow: 'auto' }}>
                        {studentStats}
                    </p>
                </div>
            </div>
        )
    }


    sortObject(obj, order) {
        return Object.keys(obj)
          .sort((a,b) => order == 'desc' ? b.localeCompare(a) : a.localeCompare(b))
          .reduce((accumulatorObject, currentValue) => {
          accumulatorObject[currentValue] = obj[currentValue];
          return accumulatorObject; }, {});
      }

    // anotherSortObject(obj, order) {
    //     return Object
    //         .keys(obj)
    //         .sort((a, b) => order == 'desc' ? b.localeCompare(a) : a.localeCompare(b))
    //         .reduce((_sortedObj, key) => ({
    //             ..._sortedObj,
    //             [key]: obj[key]
    //         }), {})
    // }

    sortActivitiesInUnit(obj, order, attributeName) {
        for (var activities in obj) {
            if (obj.hasOwnProperty(activities)) {
                obj[activities].sort((a, b) => order == 'desc' ? (typeof b[attributeName] == 'string' && typeof a[attributeName] == 'string') ? b[attributeName].localeCompare(a[attributeName]) : b[attributeName] - a[attributeName] : (typeof a[attributeName] == 'string' && typeof b[attributeName] == 'string') ? a[attributeName].localeCompare(b[attributeName]) : a[attributeName] - b[attributeName])
            }
        }
        return obj;
    }

    render() {

        let context = this;

        let tempFilters = [];

        this.state.allActivities.forEach(activity => {
            if(activity.notAssigned)
                return;
            if (activity.assignTo && activity.assignTo.length > 0) {
                for (let a = 0; a < activity.assignTo.length; a++) {
                    tempFilters.push(activity.assignTo[a]);
                }
            }
        });

        let rows = [];



        for (let unit in this.state.allActivitiesSearchResult) {
            if (!this.state.allActivitiesSearchResult.hasOwnProperty(unit)) {
                continue;
            }
            // console.log(unit, ' -> ', this.state.allActivitiesSearchResult[unit])


            rows.push(
                <tr key={Math.random()} id={Math.random() * 1000}>
                    <td colSpan="7" style={{ width: '25%', textAlign: 'left', padding: "15px", backgroundColor: "#f1f9ff" }}>
                        <div style={{ fontStyle: 'bold', color: "#0f6fb4", 'width': '100%', 'white-space': 'nowrap', 'overflow': 'hidden', 'text-overflow': 'ellipsis' }} title={unit}>
                            <span style={{ fontSize: "1.3em", color: "#222" }}>{unit}</span>
                        </div>
                    </td>
                </tr>
            );


            this.state.allActivitiesSearchResult[unit].forEach(activity => {

                let assignedClass = '';

                if (!activity.notAssigned) {

                    // if (activity.assignTo && activity.assignTo.length > 0) {
                    //     for (let a = 0; a < activity.assignTo.length; a++) {
                    //         if (a === activity.assignTo.length - 1) {
                    //             assignedClass += activity.assignTo[a];
                    //         }
                    //         else {
                    //             assignedClass += activity.assignTo[a] + ', ';
                    //         }
                    //     }
                    // }

                    assignedClass = activity.assignTo.join(', ')

                }

                rows.push(
                    <tr key={Math.random()}>
                        <td style={{ width: '15%' }}>
                            <div style={{ /*fontStyle: 'bold',*/ color: "#0f6fb4", 'width': '100%', 'white-space': 'nowrap', 'overflow': 'hidden', 'text-overflow': 'ellipsis', textTransform: 'capitalize ', marginLeft: "30px" }} title={activity.activityName}>
                                {activity.activityName}
                            </div>
                        </td>
                        <td style={{ width: '15%' }}>
                            {Utility.ucFirst(activity.activityType)}
                        </td>
                        <td style={{ width: '15%' }}>
                            {activity.notAssigned ? <span className="text-muted"><i>N/A</i></span> : activity.progress ? activity.progress.toFixed(0) + '%' : '0%'}
                        </td>
                        <td style={{ width: '15%' }}>
                            {activity.notAssigned ? <span className="text-muted"><i>N/A</i></span> : activity.avgScore ? activity.avgScore.toFixed(0) < 65 ? <span style={{ color: "red" }}>{activity.avgScore.toFixed(0)}%</span> : activity.avgScore.toFixed(0) + '%' : <span style={{ color: 'red' }}>0%</span>}
                        </td>
                        <td style={{ width: '15%' }}>
                            {activity.notAssigned ? <span className="text-muted"><i>N/A</i></span> : activity.completedAverage ? activity.completedAverage.toFixed(0) < 65 ? <span style={{ color: "red" }}>{activity.completedAverage.toFixed(0)}%</span> : activity.completedAverage.toFixed(0) + '%' : <span style={{ color: 'red' }}>0%</span>}
                        </td>
                        <td style={{ width: '15%' }}>
                            {activity.notAssigned ? <span className="text-muted"><i>N/A</i></span> : assignedClass}
                        </td>
                        <td style={{ width: '15%' }}>
                            {
                                activity.notAssigned ? <span className='text-danger'>Not Assigned Yet</span>
                                    :
                                    <a href="javascript:void 0" onClick={() => {
                                        context.getStudentDetails(activity);
                                        let allClasses = [];
                                        allClasses.push({ id: 'all', name: 'All Classes' });
                                        activity.assignTo.forEach(className => {
                                            allClasses.push({ id: className, name: className });
                                        });
                                        context.setState({ selectedClass: 'all', allClasses: allClasses, assignedClass: assignedClass, contentView: 'student' });
                                    }}><MaterialIcon icon="search" size={32} /></a>
                            }
                        </td>
                    </tr>);


            })

        }

        // Object.keys(this.state.allActivitiesSearchResult).forEach(unit => {
            // console.log('momin', unit, ' -> ', this.state.allActivitiesSearchResult[unit])

        //     rows.push(
        //         <tr key={Math.random()} id={Math.random() * 1000}>
        //             <td colSpan="7" style={{ width: '25%', textAlign: 'left', padding: "15px", backgroundColor: "#f1f9ff" }}>
        //                 <div style={{ fontStyle: 'bold', color: "#0f6fb4", 'width': '100%', 'white-space': 'nowrap', 'overflow': 'hidden', 'text-overflow': 'ellipsis' }} title={unit}>
        //                     <span style={{ fontSize: "1.3em", color: "#222" }}>{unit}</span>
        //                 </div>
        //             </td>
        //         </tr>
        //     );


        //     this.state.allActivitiesSearchResult[unit].forEach(activity => {

        //         let assignedClass = '';

        //         if (!activity.notAssigned) {

        //             if (activity.assignTo && activity.assignTo.length > 0) {
        //                 for (let a = 0; a < activity.assignTo.length; a++) {
        //                     if (a === activity.assignTo.length - 1) {
        //                         assignedClass += activity.assignTo[a];
        //                     }
        //                     else {
        //                         assignedClass += activity.assignTo[a] + ', ';
        //                     }
        //                 }
        //             }

        //         }

        //         rows.push(
        //             <tr key={Math.random()}>
        //                 <td style={{ width: '15%' }}>
        //                     <div style={{ /*fontStyle: 'bold',*/ color: "#0f6fb4", 'width': '100%', 'white-space': 'nowrap', 'overflow': 'hidden', 'text-overflow': 'ellipsis', textTransform: 'capitalize ', marginLeft: "30px" }} title={activity.activityName}>
        //                         {activity.activityName}
        //                     </div>
        //                 </td>
        //                 <td style={{ width: '15%' }}>
        //                     {Utility.ucFirst(activity.activityType)}
        //                 </td>
        //                 <td style={{ width: '15%' }}>
        //                     {activity.notAssigned ? <span className="text-muted"><i>N/A</i></span> : activity.progress ? activity.progress.toFixed(0) + '%' : '0%'}
        //                 </td>
        //                 <td style={{ width: '15%' }}>
        //                     {activity.notAssigned ? <span className="text-muted"><i>N/A</i></span> : activity.avgScore ? activity.avgScore.toFixed(0) < 65 ? <span style={{ color: "red" }}>{activity.avgScore.toFixed(0)}%</span> : activity.avgScore.toFixed(0) + '%' : <span style={{ color: 'red' }}>0%</span>}
        //                 </td>
        //                 <td style={{ width: '15%' }}>
        //                     {activity.notAssigned ? <span className="text-muted"><i>N/A</i></span> : activity.completedAverage ? activity.completedAverage.toFixed(0) < 65 ? <span style={{ color: "red" }}>{activity.completedAverage.toFixed(0)}%</span> : activity.completedAverage.toFixed(0) + '%' : <span style={{ color: 'red' }}>0%</span>}
        //                 </td>
        //                 <td style={{ width: '15%' }}>
        //                     {activity.notAssigned ? <span className="text-muted"><i>N/A</i></span> : assignedClass}
        //                 </td>
        //                 <td style={{ width: '15%' }}>
        //                     {
        //                         activity.notAssigned ? <span className='text-danger'>Not Assigned Yet</span>
        //                             :
        //                             <a href="javascript:void 0" onClick={() => {
        //                                 context.getStudentDetails(activity);
        //                                 let allClasses = [];
        //                                 allClasses.push({ id: 'all', name: 'All Classes' });
        //                                 activity.assignTo.forEach(className => {
        //                                     allClasses.push({ id: className, name: className });
        //                                 });
        //                                 context.setState({ selectedClass: 'all', allClasses: allClasses, assignedClass: assignedClass, contentView: 'student' });
        //                             }}><MaterialIcon icon="search" size={32} /></a>
        //                     }
        //                 </td>
        //             </tr>);


        //     })

        // })




        










        // let allActivities = this.state.allActivitiesSearchResult;


        // let unitName = "";
        // // for (var activity of allActivities) {    // this creats a big problem last activity will be the latest
        // allActivities.forEach((activity) => {

        //     let assignedClass = '';

        //     if (!activity.notAssigned) {

        //         if (activity.assignTo && activity.assignTo.length > 0) {
        //             for (let a = 0; a < activity.assignTo.length; a++) {
        //                 if (a === activity.assignTo.length - 1) {
        //                     assignedClass += activity.assignTo[a];
        //                 }
        //                 else {
        //                     assignedClass += activity.assignTo[a] + ', ';
        //                 }
        //             }
        //         }

        //     }

        //     let that = this;


        //     if (unitName != activity.unitName) {

        //         unitName = activity.unitName;

        //         rows.push(
        //             <tr key={Math.random()} id={Math.random() * 1000}>
        //                 <td colSpan="7" style={{ width: '25%', textAlign: 'left', padding: "15px", backgroundColor: "#f1f9ff" }}>
        //                     <div style={{ fontStyle: 'bold', color: "#0f6fb4", 'width': '100%', 'white-space': 'nowrap', 'overflow': 'hidden', 'text-overflow': 'ellipsis' }} title={activity.unitName}>
        //                         <span style={{ fontSize: "1.3em", color: "#222" }}>{activity.unitName}</span>
        //                     </div>
        //                 </td>
        //             </tr>
        //         );

        //     }

        //     rows.push(
        //         <tr key={Math.random()}>
        //             <td style={{ width: '15%' }}>
        //                 <div style={{ /*fontStyle: 'bold',*/ color: "#0f6fb4", 'width': '100%', 'white-space': 'nowrap', 'overflow': 'hidden', 'text-overflow': 'ellipsis', textTransform: 'capitalize ', marginLeft: "30px" }} title={activity.activityName}>
        //                     {activity.activityName}
        //                 </div>
        //             </td>
        //             <td style={{ width: '15%' }}>
        //                 {Utility.ucFirst(activity.activityType)}
        //             </td>
        //             <td style={{ width: '15%' }}>
        //                 {activity.notAssigned ? <span className="text-muted"><i>N/A</i></span> : activity.progress ? activity.progress.toFixed(0) + '%' : '0%'}
        //             </td>
        //             <td style={{ width: '15%' }}>
        //                 {activity.notAssigned ? <span className="text-muted"><i>N/A</i></span> : activity.avgScore ? activity.avgScore.toFixed(0) < 65 ? <span style={{ color: "red" }}>{activity.avgScore.toFixed(0)}%</span> : activity.avgScore.toFixed(0) + '%' : <span style={{ color: 'red' }}>0%</span>}
        //             </td>
        //             <td style={{ width: '15%' }}>
        //                 {activity.notAssigned ? <span className="text-muted"><i>N/A</i></span> : activity.completedAverage ? activity.completedAverage.toFixed(0) < 65 ? <span style={{ color: "red" }}>{activity.completedAverage.toFixed(0)}%</span> : activity.completedAverage.toFixed(0) + '%' : <span style={{ color: 'red' }}>0%</span>}
        //             </td>
        //             <td style={{ width: '15%' }}>
        //                 {activity.notAssigned ? <span className="text-muted"><i>N/A</i></span> : assignedClass}
        //             </td>
        //             <td style={{ width: '15%' }}>
        //                 {
        //                     activity.notAssigned ? <span className='text-danger'>Not Assigned Yet</span>
        //                         :
        //                         <a href="javascript:void 0" onClick={() => {
        //                             context.getStudentDetails(activity);
        //                             let allClasses = [];
        //                             allClasses.push({ id: 'all', name: 'All Classes' });
        //                             activity.assignTo.forEach(className => {
        //                                 allClasses.push({ id: className, name: className });
        //                             });
        //                             context.setState({ selectedClass: 'all', allClasses: allClasses, assignedClass: assignedClass, contentView: 'student' });
        //                         }}><MaterialIcon icon="search" size={32} /></a>
        //                 }
        //             </td>
        //         </tr>);




        // });





        let studentStats = <table className="table table-bordered table-hover" id="student-attempt-history">
            <thead>
                <tr>
                    <th className="dropdown" data-order="dsc" onClick={function (evt) {
                        let allData = this.state.allActivitiesSearchResult;
                        let order = evt.target.getAttribute('data-order');
                        if (order === 'asc') {
                            evt.target.setAttribute('data-order', 'dsc');
                            evt.target.setAttribute('class', 'dropdown');                            
                            allData = this.sortActivitiesInUnit(allData, 'asc', 'activityName')
                        } else {
                            evt.target.setAttribute('data-order', 'asc');
                            evt.target.setAttribute('class', 'dropup');                            
                            allData = this.sortActivitiesInUnit(allData, 'desc', 'activityName')
                        }
                        context.setState({ allActivitiesSearchResult: allData });
                    }.bind(this)}>
                        Activity Name<span className="caret caret-close" />
                    </th>
                    <th className="dropdown" data-order="dsc" onClick={function (evt) {
                        let allData = this.state.allActivitiesSearchResult;
                        let order = evt.target.getAttribute('data-order');
                        if (order === 'asc') {
                            evt.target.setAttribute('data-order', 'dsc');
                            evt.target.setAttribute('class', 'dropdown');                            
                            allData = this.sortActivitiesInUnit(allData, 'asc', 'activityType')
                        } else {
                            evt.target.setAttribute('data-order', 'asc');
                            evt.target.setAttribute('class', 'dropup');                            
                            allData = this.sortActivitiesInUnit(allData, 'desc', 'activityType')
                        }
                        context.setState({ allActivitiesSearchResult: allData });
                    }.bind(this)}>
                        Activity Type<span className="caret caret-close" />
                    </th>
                    <th className="dropdown" data-order="dsc" onClick={function (evt) {
                        let allData = this.state.allActivitiesSearchResult;
                        let order = evt.target.getAttribute('data-order');
                        if (order === 'asc') {
                            evt.target.setAttribute('data-order', 'dsc');
                            evt.target.setAttribute('class', 'dropdown');                            
                            allData = this.sortActivitiesInUnit(allData, 'asc', 'progress')
                        } else {
                            evt.target.setAttribute('data-order', 'asc');
                            evt.target.setAttribute('class', 'dropup');                            
                            allData = this.sortActivitiesInUnit(allData, 'desc', 'progress')
                        }
                        context.setState({ allActivitiesSearchResult: allData });
                    }.bind(this)}>
                        Progress<span className="caret caret-close" />
                    </th>
                    <th className="dropdown" data-order="dsc" onClick={function (evt) {
                        let allData = this.state.allActivitiesSearchResult;
                        let order = evt.target.getAttribute('data-order');
                        if (order === 'asc') {
                            evt.target.setAttribute('data-order', 'dsc');
                            evt.target.setAttribute('class', 'dropdown');                            
                            allData = this.sortActivitiesInUnit(allData, 'asc', 'avgScore')
                        } else {
                            evt.target.setAttribute('data-order', 'asc');
                            evt.target.setAttribute('class', 'dropup');                            
                            allData = this.sortActivitiesInUnit(allData, 'desc', 'avgScore')
                        }
                        context.setState({ allActivitiesSearchResult: allData });
                    }.bind(this)}>
                        Average Score<span className="caret caret-close" />
                    </th>
                    <th className="dropdown" data-order="dsc" onClick={function (evt) {
                        let allData = this.state.allActivitiesSearchResult;
                        let order = evt.target.getAttribute('data-order');
                        if (order === 'asc') {
                            evt.target.setAttribute('data-order', 'dsc');
                            evt.target.setAttribute('class', 'dropdown');                            
                            allData = this.sortActivitiesInUnit(allData, 'asc', 'completedAverage')
                        } else {
                            evt.target.setAttribute('data-order', 'asc');
                            evt.target.setAttribute('class', 'dropup');                            
                            allData = this.sortActivitiesInUnit(allData, 'desc', 'completedAverage')
                        }
                        context.setState({ allActivitiesSearchResult: allData });
                    }.bind(this)}>
                        Completed Average<span className="caret caret-close" />
                </th>
                    <th>
                        Classes Assigned to
                </th>
                    <th>
                        View Details
                </th>
                </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </table>;
        let table = <div id="user-activity-analysis" className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                {/*<p className="subjectScoreHeading" style={{marginTop:"-10px"}}>*/}
                {/*/!*{this.props.params.subject} : <span style={{color:subjectScore < 65 ? 'red':'green'}} className="subjectScoreHeadingSpan">{subjectScore===NaN ? 0:subjectScore}{'%'}</span>*!/*/}
                {/*</p>*/}
                <p style={{ overflow: 'auto' }}>
                    {studentStats}
                </p>
            </div>
        </div>;

        let filtersHtml = [];
        let allFiltersValues = [...new Set(tempFilters.map(item => item))];
        allFiltersValues.sort(function (a, b) {
            return (a.localeCompare(b));
        });
        allFiltersValues.forEach(filter => {
            let isChecked = false;
            if (filter === this.state.selectedFilter) {
                isChecked = true;
            }
            filtersHtml.push(<div className="fieldBox" key={Math.random()}>
                <input type="radio" value={filter} checked={isChecked} className="css-checkbox css-label1 bc_info card_side" onClick={(event) => {
                    let value = event.target.value;
                    if (this.state.selectedFilter === value) {
                        value = '';
                    }
                    this.setState({ selectedFilter: value }, function () {
                        let searchTerm = value.toLowerCase();
                        let searchResult = [];
                        let data = context.state.allActivities;
                        if (searchTerm === "") {
                            searchResult = data;
                        }
                        else {
                            if (data.length > 0) {
                                for (let i = 0; i < data.length; i++) {
                                    if (data[i].assignTo && data[i].assignTo.length > 0) {
                                        for (let j = 0; j < data[i].assignTo.length; j++) {
                                            let tempVarName = data[i].assignTo[j].toLowerCase();
                                            if (tempVarName.search(searchTerm) !== -1) {
                                                searchResult.push(data[i]);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        context.setState({ allActivitiesSearchResult: searchResult });
                    });
                }} id={filter} name="filter" />
                <label className="css-label " htmlFor={filter}>{filter}</label>
            </div>);
        });


        let content = null;
        if (context.state.detailedView) {
            content = this.getMainContent(this.state.contentView);
        }

        // let breadcrumbs = [
        //     {
        //         name: 'Reports', action: ()=> {
        //         browserHistory.push('/reports');
        //     }
        //     },
        //     {
        //         name: 'Activity Report'
        //     }
        // ];

        // if (this.state.detailedView)
        // {
        //     breadcrumbs = [
        //         {
        //             name: 'Reports', action: ()=> {
        //             browserHistory.push('/reports');
        //         }
        //         },
        //         {
        //             name: 'Activity Report', action:()=>{
        //             this.setState({detailedView:false});
        //         }
        //         },
        //         {
        //             name:'Details'
        //         }
        //     ]
        // }


        let breadcrumbs = [
            {
                name: 'Reports', action: () => {
                    browserHistory.push('/reports');
                }
            },
            {
                name: "Admin Report", action: () => {
                    browserHistory.push({
                        pathname: '/reports/admin-report',
                        state: { selectedSubject: this.state.subjectForAdminReport }
                    })
                }
            },
            {
                name: 'Course Report - ' + (this.state.teacherForAdminReport ? this.state.teacherForAdminReport.name : this.state.courseName)
            }
        ];

        if (this.state.detailedView) {
            breadcrumbs = [
                {
                    name: 'Reports', action: () => {
                        browserHistory.push('/reports');
                    }
                },
                {
                    name: "Admin Report", action: () => {
                        browserHistory.push({
                            pathname: '/reports/admin-report',
                            state: { selectedSubject: this.state.subjectForAdminReport }
                        })
                    }
                },
                {
                    name: 'Course Report - ' + (this.state.teacherForAdminReport ? this.state.teacherForAdminReport.name : this.state.courseName), action: () => {
                        this.setState({ detailedView: false });
                    }
                },
                {
                    name: 'Detail view' //details of activity
                }
            ]
        }

        let courseAnalysisReport = null;
        if (!context.state.detailedView) {
            courseAnalysisReport = this.viewCourseReportStudentAnalysis();
        }

        return (
            <div className="container-fluid noPad listMainBoxWrapper " id="activity-analysis" style={{ overflowX: 'hidden' }}>
                <Heading
                    isQuery={false}
                    customURL={ElasticSearchTypes.StudentAssignment}
                    location="studentActivityReport"
                    actionName={RECEIVED_STUDENT_ASSIGNMENT_RESULTS}
                    isSearch={false}
                    isShowButton={false}
                    screenNameForElasticSearch={context.state.teacherForAdminReport ? context.state.teacherForAdminReport.id : this.props.auth.user.userName}
                    allColumnsForElasticSearch={ElasticSearchColumns.StudentAssignment}
                    breadcrumbsData={breadcrumbs}
                //customSearch={
                //    function (searchTerm) {
                //        let searchResult = [];
                //        let data = context.state.allActivities;
                //        if (searchTerm === ""){
                //            searchResult = data;
                //        }
                //        else{
                //            if (data.length >0){
                //                for (let i = 0; i < data.length; i++){
                //                    let tempVarName = data[i].activityName.toLowerCase();
                //                    if (tempVarName.search(searchTerm) !== -1) {
                //                        searchResult.push(data[i]);
                //                    }
                //                }
                //            }
                //        }
                //        context.setState({allActivitiesSearchResult:searchResult});
                //    }
                //}
                />
                {this.state.showAlert &&
                    <div className="row">
                        <div className="col-md-3 col-xs-3" />
                        <div className="col-md-6 col-xs-3">
                            <Alert bsStyle={this.state.alertStyle}
                                onDismiss={this.handleAlertDismiss}>
                                {this.state.alertText}
                            </Alert>
                        </div>
                        <div className="col-md-3 col-xs-3" />
                    </div>
                }
                <div id="tableFiltersAndSideView">
                    <div className={classnames("container-fluid noPad listMainBoxWrapper")}>

                        <div id="submission" className="tab-pane fade active in">
                            <div id="contentPanel" style={{
                                margin: '1.5% 10.5%',
                                width: '80%',
                                padding: '35px',
                                border: '1px solid rgb(204, 204, 204)'
                            }}>

                                {!this.state.detailedView && <div className="selectSubjectDropdown" style={{
                                    float: 'right',
                                    "margin-right": '20px',
                                    //"margin-top": '20px',
                                    //"margin-left": '20px'
                                }}>
                                    <SelectFieldGroup
                                        field="selectedByStudentOrByActivity"
                                        value={this.state.selectedByStudentOrByActivity}
                                        options={[{ id: 'by-activity', name: 'By Activity' }, { id: 'by-student', name: 'By Student' }]}
                                        onChange={function (event) {
                                            let value = event.target.value;
                                            context.setState({ selectedByStudentOrByActivity: value }, function () {
                                                if (context.state.selectedByStudentOrByActivity === 'by-activity') {
                                                    console.info('render by activity');

                                                } else if (context.state.selectedByStudentOrByActivity === 'by-student') {
                                                    console.info('render by student');
                                                    browserHistory.push({
                                                        pathname: '/reports/course-report-students/' + encodeURIComponent(this.params.id),
                                                        state: { courseName : this.state.courseName, courseForAdminReport: this.state.courseForAdminReport , forAdminReport: this.state.forAdminReport, teacher: this.state.teacherForAdminReport, allTeachers:  this.state.allTeachersForAdminReport, subjectForAdminReport: this.state.subjectForAdminReport}
                                                    });
                                                }

                                            });
                                        }.bind(this)}
                                    />
                                </div>}


                                {
                                    !this.state.detailedView && <div>
                                        {/* <div className="radioElementBoxActivityAnalysis">
                                    <strong>Periods</strong>
                                    <div className="radioElement" style={{marginTop: '15px'}}>
                                        {filtersHtml}
                                    </div>
                                </div> */}

                                        <div className="" id="gray-box-container">
                                            <div id="ReportCardTableStudent" style={{ background: 'white' }}>
                                                {courseAnalysisReport}
                                            </div>
                                        </div>

                                        <div id="ReportCardTable" style={{ background: 'white', marginLeft: "0", padding: "2% 0%" }}>
                                            {table}
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.detailedView &&

                                    <div>

                                        <div>
                                            <div className="col-md-3">
                                                <SelectFieldGroup
                                                    isDisabled={false}
                                                    field="departments"
                                                    value={this.state.contentView}
                                                    options={[{ id: 'student', name: 'Student Analysis' }, {
                                                        id: 'classes',
                                                        name: 'Class Analysis'
                                                    }]}
                                                    onChange={(event) => {
                                                        let value = event.target.value;
                                                        this.setState({ contentView: value }, function () {
                                                            if (value === 'classes') {
                                                                context.loadClassWiseDetails(context.state.currentActivity.activityID);
                                                            }
                                                        });
                                                    }}
                                                    isColored={true}
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <SelectFieldGroup
                                                    isDisabled={false}
                                                    field="departments"
                                                    value={this.state.selectedClass}
                                                    options={this.state.allClasses}
                                                    onChange={(event) => {
                                                        let value = event.target.value;
                                                        this.setState({ selectedClass: value }, function () {
                                                            let searchTerm = value.toLowerCase();
                                                            let searchResult = [];
                                                            let data = context.state.allStudentsData.students;
                                                            if (searchTerm === "" || searchTerm === "all") {
                                                                searchResult = data;
                                                            }
                                                            else {
                                                                if (data.length > 0) {
                                                                    for (let i = 0; i < data.length; i++) {
                                                                        if (data[i][0] && data[i][0].periodName) {
                                                                            let tempVarName = data[i][0].periodName//.toLowerCase();
                                                                            {/* if (tempVarName.search(searchTerm) !== -1) { */}
                                                                            if (tempVarName === value) {                                                                            
                                                                                searchResult.push(data[i]);
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            context.setState({ allStudentsDataSearchResult: searchResult }, function () {
                                                                if (context.state.contentView === 'classes') {
                                                                    if (value === 'all') {
                                                                        context.loadClassWiseDetails(context.state.currentActivity.activityID);
                                                                    }
                                                                    else {
                                                                        context.loadClassWiseDetails(context.state.currentActivity.activityID, value);
                                                                    }
                                                                }
                                                            });
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {this.state.contentView === 'student' && <div className="" id="gray-box-container">
                                            <div id="ReportCardTableStudent" style={{ background: 'white' }}>
                                                {content}
                                            </div>
                                        </div>}
                                        {this.state.contentView === 'classes' && <div className="" id="gray-box-container">
                                            <div className="container-fluid questionMainBlock" style={{ margin: '0 20px 108px 20px' }}>
                                                <div className="quesAndIconStudent">
                                                    {content}
                                                </div>
                                            </div>
                                        </div>
                                        }

                                    </div>

                                }
                            </div>
                        </div>

                    </div>
                </div>
                <div>

                    {(this.state.loadingReport) && <div>
                        <div className="modal-backdrop fade in"></div>
                        <div className="sk-fading-circle-main">
                            <div className="sk-fading-bar">


                                <h1>
                                    {this.state.intervalcount + '%'}
                                </h1>

                                <div id="myProgress">

                                    <Progress completed={this.state.intervalcount} />

                                </div>

                                <h2>
                                    Loading please wait...
                        </h2>
                            </div>
                        </div>

                    </div>}

                    <ProgressIndicator show={this.state.loading} />
                </div>
                {this.state.showUserDetailsDialog && this.getUserDetailsDialog()}
                {/*{this.state.activityAttemptsDialog && this.getActivityAttemptsDialog()}*/}
            </div>
        );
    }

    viewCourseReportStudentAnalysis() {

        console.count("Rendering:")


        let passedStudents = 0;
        let failedStudents = 0;
        let unStartedStudents = 0;
        let completedStudents = 0;
        let inProgressStudents = 0;
        let totalStudentsAssigned = 0;
        let averageScore = 0;
        let averageTime = 0;
        let averageScoreCompletedActivities = 0;
    


        let period = [];


        let allActivities = this.state.allActivities;
        let totalActivities = allActivities.length;
        let totalCompletedActivities = 0;
        let totalAverage = 0
        let totalCompletedAverage = 0

        allActivities.forEach( activity => {

            if(activity.notAssigned)
                return;
            
            activity.assignTo.forEach( periodName => {
                period.push(periodName);
            })
            totalAverage += activity.avgScore ? activity.avgScore : 0;
            totalCompletedAverage += activity.completedAverage ? activity.completedAverage : 0;
            if( activity.progress == 100 )
                totalCompletedActivities++;
        })

        averageScore = totalAverage / totalActivities;
        averageScoreCompletedActivities = totalCompletedActivities ? totalCompletedAverage / totalCompletedActivities : 0; //without the check it would be Infinity in some cases



        
        if(this.state.CourseInfoBoxByStudent) {

            passedStudents = this.state.CourseInfoBoxByStudent.passedStudents;
            failedStudents = this.state.CourseInfoBoxByStudent.failedStudents;
            unStartedStudents = this.state.CourseInfoBoxByStudent.unStartedStudents;
            completedStudents = this.state.CourseInfoBoxByStudent.completedStudents;
            inProgressStudents = this.state.CourseInfoBoxByStudent.inProgressStudents;
            totalStudentsAssigned = this.state.CourseInfoBoxByStudent.totalStudentsAssigned;
            averageTime = this.state.CourseInfoBoxByStudent.averageTime;
            averageScore = this.state.CourseInfoBoxByStudent.averageScore;
            averageScoreCompletedActivities = this.state.CourseInfoBoxByStudent.averageScoreCompletedActivities;
            
        }




        // let averageCounter = 0;

        // this.state.allStudentsDataSearchResult.forEach(student => {
            
        //     console.log(student);

        //     numberOfStudents++;
        //     if (student.length > 0) {
        //         if (student[0].progress === 100)
        //         { 
                
        //             averageCounter++;
        //             averageScore+= student[0].score;
        //             averageTime+= student[0].timer;
        //         }
        //         if (student[0].score && student[0].score > 0) {
        //             if (student[0].score.toFixed(0) < 65) {
        //                 failedStudents++;
        //             }
        //             else if (student[0].score.toFixed(0) >= 65) {
        //                 passedStudents++;
        //             }
        //         }
        //         else {
        //             incompleteStudents++;
        //         }
        //     }
        //     else
        //     {
        //         incompleteStudents++;
        //     }

        //     student = student.sort(function(a,b){
        //         return new Date(b.completionDate) - new Date(a.completionDate);
        //     });

        //     // if (student.length > 0)
        //     // {

        //     //     rows.push(<tr key={Math.random()}>
        //     //         <td>
        //     //             {student[0].studentName}
        //     //         </td>
        //     //         <td>
        //     //             {student[0].periodName?student[0].periodName:''}
        //     //         </td>
        //     //         <td>
        //     //             {student[0].progress?student[0].progress.toFixed(0):'0'}{'%'}
        //     //         </td>
        //     //         <td>
        //     //             {student[0].score?student[0].score.toFixed(0):'0'}{'%'}
        //     //         </td>
        //     //         <td>
        //     //             {(student[0].timer?((student[0].timer/3600).toFixed(0)+' hours '+(student[0].timer/60).toFixed(0)+' mins '+(student[0].timer % 60).toFixed(0)+' seconds'):'N/A')}
        //     //         </td>
        //     //         <td>{student[0].completionDate && student[0].completionDate!=='N/A'? new Date(parseFloat(student[0].completionDate)).toLocaleString():'N/A'}</td>
        //     //         <td style={{width:'20%'}}>
        //     //             {
        //     //                 <a href="javascript:void 0" onClick={()=> {
        //     //                     context.getAttemptWiseAnalysis(student, this.state.currentActivity.activityID);
        //     //                 }}><MaterialIcon icon="search" size={24}/></a>
        //     //             }
        //     //         </td>
        //     //     </tr>);
        //     // }
        // });

        // averageScore = averageScore / averageCounter;
        // averageTime = averageTime / averageCounter;


        let analysisStats = <table className="table" id="act-stat">
             <tbody>
                 <tr>
                     <td><MaterialIcon icon="assignment" size={24} /> Average <span style={{ fontWeight: "normal" }}>(Assigned Activities)</span>:</td>
                     <td><span className='fontSizeOfTableValues'>{averageScore ? averageScore < 65 ? <span style={{ color:'red' }}>{averageScore.toFixed(0)}{'%'}</span> : averageScore.toFixed(0)+'%' : <span style={{ color:'red' }}>0%</span>}</span></td>
                     <td><MaterialIcon icon="timer" size={24} /><strong> Period:</strong></td>
                     {/* <td>{period.length != 0 ? [...new Set(period)].toString() : "Not Assigned To Any Class"}</td> */}
                     <td><span className='fontSizeOfTableValues'>{period.length != 0 ? [...new Set(period)].join(", ") : <span className="text-danger">Not Assigned To Any Class</span>}</span></td>
                 </tr>
                 <tr>
                     <td><MaterialIcon icon="assessment" size={24} /> Completed Average <span style={{ fontWeight: 'normal' }}>(Completed Activities)</span>:</td>
                     <td><span className='fontSizeOfTableValues'>{averageScoreCompletedActivities ? averageScoreCompletedActivities < 65 ? <span style={{ color:'red' }}>{averageScoreCompletedActivities.toFixed(0)}{'%'}</span> : averageScoreCompletedActivities.toFixed(0)+'%' : <span style={{ color:'red' }}>0%</span>}</span></td>
                     <td width="25%"><MaterialIcon icon="supervisor_account" size={24} /><strong> Total Students Assigned:</strong></td>
                     <td width="20%"><span className='fontSizeOfTableValues'>{totalStudentsAssigned}</span></td>
                 </tr>
                 <tr>
                     <td width="25%"><MaterialIcon icon="done" size={24} /><strong> Passing Students:</strong></td>
                     <td width="20%"><span className='fontSizeOfTableValues'>{passedStudents}</span></td>
                     <td width="25%"><MaterialIcon icon="highlight_off" size={24} /><strong> Completed Students:</strong></td>
                     <td width="20%"><span className='fontSizeOfTableValues'>{completedStudents}</span></td>
                 </tr>
                 <tr>
                     <td width="25%"><MaterialIcon icon="highlight_off" size={24} /><strong> Failing Students:</strong></td>
                     <td width="20%"><span className='fontSizeOfTableValues'>{failedStudents}</span></td>
                     <td><MaterialIcon icon="alarm" size={24} /><strong> In-Progress Students:</strong></td>
                     <td><span className='fontSizeOfTableValues'>{inProgressStudents}</span></td>
                 </tr>
                 <tr>
                     <td><MaterialIcon icon="alarm" size={24} /> Average Time In Course:</td>
                     <td><span className='fontSizeOfTableValues'>{averageTime ? 
                        (
                            parseHours(averageTime) + ' hours '
                            + parseMinutes(averageTime) + ' mins '
                            + parseSeconds(averageTime) + ' seconds'
                        )
                        : 'N/A'}</span></td>
                     <td width="25%"><MaterialIcon icon="eject" size={24} /><strong> Un-started Students:</strong></td>
                     <td width="20%"><span className='fontSizeOfTableValues'>{unStartedStudents}</span></td>
                     {/* <td width="35%"><MaterialIcon icon="eject" size={24}/><strong> # of incomplete students:</strong></td>
                     <td width="10%">{incompleteStudents}</td> */}
                 </tr>
             </tbody>
         </table>;


        return (
            <div id="student-activity-analysis" className="row" style={{ zIndex: '-100' }}>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <span className="boardheadblff1 makeMyFontBig">{this.state.courseForAdminReport ? this.state.courseForAdminReport.name : this.state.courseName}</span>
                    <br />
                    <span className="boardheadblff1 makeMyFontSmall marginBottom10px">{this.state.teacherForAdminReport ? this.state.teacherForAdminReport.name : this.props.auth.user.firstName + ' ' + this.props.auth.user.lastName}</span>
                    <p style={{ overflow: 'auto' }}>
                        {analysisStats}
                    </p>
                </div>


            </div>
        );
    }

    loadAllActivities() {
        let context = this;
        
        //context.showIndicator();
        context.setState({ loadingReport: true });

        

        // THIS WAS CAUSING SO MANY RERENDER REQUESTS WOW
        this.Loadingfunction();
        
        
        
        
        
        let params = {
            userName: context.state.teacherForAdminReport ? context.state.teacherForAdminReport.id : this.props.auth.user.userName,
            courseID: context.state.courseForAdminReport ? context.state.courseForAdminReport.id : this.params.id
        };
        // let pathTemplate = '/activity/report/username/{userName}';
        let pathTemplate = '/course/activity/report/username/{userName}/courseID/{courseID}';
        let method = 'GET';
        let additionalParams = {};
        let body = {};
        // apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, '4').then(function (result) {
        apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, '5').then(function (result) {
            
            // let allActivities = result.data;

            let assignedActivities = result.data;
            
                let params = {
                    userName: context.state.teacherForAdminReport ? context.state.teacherForAdminReport.id : context.props.auth.user.userName,
                    courseID: context.state.courseForAdminReport ? context.state.courseForAdminReport.id : context.params.id,
                    studentID: 'null'
                };
                let pathTemplate = '/course/assignmentIDsAndAllActivities/report/username/{userName}/courseID/{courseID}/studentID/{studentID}';
                let method = 'GET';
                let additionalParams = {};
                let body = {};

            apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, '5').then(function (allActivityResult) {

                let allActivities = allActivityResult.data.activities;

                let allActivitiesToShow = assignedActivities;

                allActivities.forEach(activity => {

                    let searchIndex = allActivitiesToShow.findIndex(x => {
                        return activity.activityID == x.activityID;
                    })

                    if (searchIndex == -1) {
                        activity.notAssigned = true;
                        allActivitiesToShow.push(activity)
                    } else {
                        allActivitiesToShow[searchIndex].unitOrderID = activity.unitOrderID;
                        allActivitiesToShow[searchIndex].activityOrderID = activity.activityOrderID;
                    }

                })

                //sort units as per given in course
                allActivitiesToShow.sort( (a,b) => a.unitOrderID - b.unitOrderID )

                let resultantObject = {}
                let sortUnitName = [];

                allActivitiesToShow.forEach(act => {
                    let indexNo = sortUnitName.indexOf(act.unitName);
                    if (indexNo == -1) {
                        sortUnitName.push(act.unitName);

                        resultantObject[act.unitName] = []
                        resultantObject[act.unitName].push(act)
                    }
                    else {
                        resultantObject[act.unitName].push(act)
                    }
                })
                
                
                resultantObject = context.sortActivitiesInUnit(resultantObject, 'asc', 'activityOrderID')                

                // resultantObject = context.sortObject(resultantObject, 'asc')
                // resultantObject = context.anotherSortObject(resultantObject, 'asc')
                // resultantObject = context.sortActivitiesInUnit(resultantObject, 'asc', 'activityName')


                context.setState({ allActivities: allActivitiesToShow, allActivitiesSearchResult: resultantObject, intervalcount: 100 });

                setTimeout(function () {
                    context.setState({ loadingReport: false });
                }, 100);


            }).catch(function (error) {

                context.setState({ loadingReport: false });

                // context.hideIndicator();
                context.setState({
                    showAlert: true,
                    alertStyle: 'danger',
                    alertText: 'Unable to get all activities for this course. Error: ' + error.message
                });
            });


        }).catch(function (error) {


            

            let responseData = [];
            let combinedMessage = '';
            if(error.response && error.response.data.errors)
            {
                for (let err of error.response.data.errors)
                    responseData.push(err.message)
            
                combinedMessage = responseData.join(', ')
            }

            context.setState({ loadingReport: false });
            // context.hideIndicator();
            context.setState({
                showAlert: true,
                alertStyle: 'danger',
                alertText: responseData.length != 0 && combinedMessage ? combinedMessage : error.message
                // alertText: 'Unable to load data, please contact administrator.'
            });
            // setTimeout(() => {
            //     context.setState({
            //         showAlert: false,
            //         alertStyle: '',
            //         alertText: ''
            //     })
            // }, 4000);
        






            

            // context.hideIndicator();
            // context.setState({ loadingReport: false });
            // context.setState({
            //     showAlert: true,
            //     alertStyle: 'danger',
            //     alertText: 'Unable to load data, please contact administrator.'
            // });
            // setTimeout(() => {
            //     context.setState({
            //         showAlert: false,
            //         alertStyle: '',
            //         alertText: ''
            //     })
            // }, 4000);
        });
    }

    loadClassWiseDetails(activityId, className) {
        let context = this;
        context.showIndicator();
        if (className) {
            let params = {
                userName: context.state.teacherForAdminReport ? context.state.teacherForAdminReport.id : this.props.auth.user.userName,
                activityID: activityId,
                className: className,
                courseID: context.state.courseForAdminReport ? context.state.courseForAdminReport.id : this.params.id
            };
            let pathTemplate = '/activity/report/username/{userName}/activityID/{activityID}/class/{className}/courseID/{courseID}';
            apiClient.invokeApi(params, pathTemplate, 'GET', {}, {}, '4').then(function (result) {
                context.setState({ classAnalysisData: result.data });
                context.hideIndicator();
            }).catch(function (error) {
                context.hideIndicator();
                context.setState({ loadingReport: false });
                context.setState({
                    showAlert: true,
                    alertStyle: 'danger',
                    alertText: 'Unable to load data, please contact administrator.'
                });
                setTimeout(() => {
                    context.setState({
                        showAlert: false,
                        alertStyle: '',
                        alertText: ''
                    })
                }, 4000);
            });
        }
        else {
            let params = {
                userName: context.state.teacherForAdminReport ? context.state.teacherForAdminReport.id : this.props.auth.user.userName,
                activityID: activityId,
                CLASSEScourseID: context.state.courseForAdminReport ? context.state.courseForAdminReport.id : this.params.id
            };
            // let pathTemplate = '/activity/report/classes/username/{userName}/activityID/{activityID}/courseID/{courseID}';
            let pathTemplate = '/activity/report/username/{userName}/activityID/{activityID}/CLASSEScourseID/{CLASSEScourseID}';
            apiClient.invokeApi(params, pathTemplate, 'GET', {}, {}, '4').then(function (result) {
                context.setState({ classAnalysisData: result.data });
                context.hideIndicator();
            }).catch(function (error) {
                context.hideIndicator();
                context.setState({ loadingReport: false });
                context.setState({
                    showAlert: true,
                    alertStyle: 'danger',
                    alertText: 'Unable to load data, please contact administrator.'
                });
                setTimeout(() => {
                    context.setState({
                        showAlert: false,
                        alertStyle: '',
                        alertText: ''
                    })
                }, 4000);
            });
        }
    }
    showIndicator() {
        this.setState({
            loading: true
        });
    }

    hideIndicator() {
        this.localLoaded = true;
        this.setState({
            loading: false
        });
    }
}

function mapStateToProps(state) {
    return {
        ...state
    }
}

export default connect(mapStateToProps)(CourseReportActivityReport);
