/**
 * Created by ashahab on 8/22/2017.
 */
import React, {Component} from "react";
import {connect} from "react-redux";
import TextFieldGroup from "../forms/text-field-group";
import SelectFieldGroup from "../forms/select-field-group";
import apiClient from "../../actions/api-client";
import ProgressIndicator from "../progress-indicator/progress-indicator";
import {Alert,Modal,Button} from "react-bootstrap";
import Heading from "../heading/heading";
import classnames from 'classnames';
import ElasticSearchQuery from "../../actions/elastic-search-query";
import ElasticSearchActions from "../../actions/elastic-search-actions";
import {ElasticSearchTypes, ElasticSearchColumns} from "../../actions/types";
import config from "../../config";
import $ from "jquery";
// import axios from "axios";

class SchoolAdministration extends Component {

    constructor(props) {
        super(props);
        this.getSchoolDetails = this.getSchoolDetails.bind(this);
        this.getRemoveTeacherDialog = this.getRemoveTeacherDialog.bind(this);
        this.getAllTeachersBySchool = this.getAllTeachersBySchool.bind(this);
        this.searchTeachers = this.searchTeachers.bind(this);
        this.toggleTabs = this.toggleTabs.bind(this);

        this.state = {isSchoolDetails:true,searchTerm:'', allTeachersSearchResult:[], allTeachers:[], showDeleteDialogBox:false, selectedTeacher:''};
    }

    componentDidMount()
    {
        this.getSchoolDetails();
        this.getAllTeachersBySchool();
    }

    toggleTabs(event)
    {

        if (event.target.id === 'classManagement')
        {
            this.setState({isSchoolDetails:true});
        }
        else
        {
            this.setState({isSchoolDetails:false, allTeachersSearchResult:this.state.allTeachers});
        }
    }

    getRemoveTeacherDialog()
    {
        let message = 'Are you sure, you want to remove this teacher from School?';
        let body =
            <h3>
                {message}
            </h3>;
        return <Modal
            show={this.state.showDeleteDialogBox}
            onHide={()=> {
                this.setState({showDeleteDialogBox: false});
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Remove Teacher</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={()=> {
                    this.setState({showDeleteDialogBox: false});
                }}>Cancel</Button>
                <Button onClick={()=> {
                    this.removeTeacher();
                }} bsStyle='primary'>Delete</Button>
            </Modal.Footer>
        </Modal>;
    }

    removeTeacher()
    {
        let that = this;
        let pathTemplate = '/user/remove/'+this.state.selectedTeacher;
        let method = 'DELETE';
        apiClient.invokeApi({}, pathTemplate, method, {}, {},'3').then(function(result){
            that.getAllTeachersBySchool();
            that.setState({showDeleteDialogBox:false});
        });
    }

    getSchoolDetails()
    {
        let that = this;
        // let axiosInstance = axios.create(
        //     {
        //         baseURL: 'https://search-learnerpal-eimkio3umgx5fnpuy7ypsrrsri.us-east-1.es.amazonaws.com/learnerpal/'+ElasticSearchTypes.School+config.stage+'/'+this.props.auth.user.schoolID,
        //         timeout: 60000,
        //         headers: {'Content-Type': 'application/json'},
        //         responseType: 'json',
        //         transformRequest: [function (data) {
        //             return data;
        //         }],
        //         transformResponse: {function (data) {
        //             let reponse = [];
        //             if (data._source ) {
        //                 reponse = data._source;
        //             };
        //             return reponse;
        //         }}
        //     });

        const payload = {    
            "query": {
               "term": {
                   "id": this.props.auth.user.schoolID
               }
           },
           "size": 10000,
           "from": 0
        }
        
        apiClient.invokeApi({}, '/getesdata', 'POST', {}, {
            payload /* id: "/" + this.props.auth.user.schoolID */,
            index: 'learnerpal/', 
            esDocumentType: ElasticSearchTypes.School + config.stage 
        }, '5').then(function (result)

            {
              let response = [];
              if (result.data && result.data.hits && result.data.hits.hits && result.data.hits.hits[0] && result.data.hits.hits[0]._source ) {
                  response = result.data.hits.hits[0]._source;
              };
              // return reponse;
                that.setState({schoolObj : response});
            }
        ).catch(e => {
            console.error(e)
        });
    }

    getAllTeachersBySchool()
    {
        let that = this;
        apiClient.invokeApi({},'/user/schoolname/'+this.props.auth.user.schoolID,'GET',{},{}).then(function (result) {
            that.setState({allTeachersSearchResult:result.data, allTeachers:result.data});
        });
    }

    searchTeachers(event)
    {
        let searchTerm = event.target.value.toLowerCase();
        let searchResult = [];
        let data = this.state.allTeachers;
        if (searchTerm === "")
        {
            searchResult = data;
        }
        else
        {
            if (data.length >0)
            {
                for (let i = 0; i < data.length; i++)
                {

                    let tempVarName = data[i].name.toLowerCase();
                    if (tempVarName.search(searchTerm) !== -1) {
                        searchResult.push(data[i]);
                    }
                }
            }
        }
        this.setState({allTeachersSearchResult:searchResult, searchTerm:searchTerm});
    }


    render() {
        let that = this;
        let allTeachers = that.state.allTeachersSearchResult;
        let allTeachersHtml = [];
        if (this.state.isSchoolDetails)
        {

        }
        else
        {
            allTeachers.forEach(teacher=>{
                allTeachersHtml.push(
                    <tr>
                        <td> <strong>{teacher.name}</strong> {' (' + teacher.id +')'}</td>
                        <td  className=""><a href="javascript:;" className="rmvBtn"
                                             onClick={()=>{that.setState({showDeleteDialogBox:true, selectedTeacher:teacher.id})}}
                        >Remove</a></td>
                    </tr>
                );
            });
        }
        let schoolObj = this.state.schoolObj;
        return(
            <div>
                {this.getRemoveTeacherDialog()}
                <Heading
                    heading="Manage Teachers & School Detail"
                />
                <div className="container-fluid  grey-background">
                    <div className="mainFormBox manageClassTable" id="manageStudentBox">
                        <div className="row introRow">
                            <div className="col-md-4 col-sm-5 col-xs-6">
                                <ul >
                                    <li><span id="classManagement" className={classnames("", {"active": this.state.isSchoolDetails})} onClick={this.toggleTabs}>School Detail</span></li>
                                    <li> | </li>
                                    <li><span id="manageStudents" className={classnames("", {"active": !this.state.isSchoolDetails})} onClick={this.toggleTabs}>Manage Teachers</span></li>
                                </ul>
                            </div>
                            {!this.state.isSchoolDetails && <div className="col-md-8 col-sm-7 text-right">
                                <div className="posRel selectWidth">
                                    <TextFieldGroup
                                        field="teachersSeachBox"
                                        value={this.state.searchTerm}
                                        placeholder="Search Teachers"
                                        selectWidth={false}
                                        onChange={this.searchTeachers}
                                    />
                                </div>
                            </div>}
                        </div>
                        {this.state.isSchoolDetails && schoolObj &&
                        <div className="respScrollTable">
                            <table className="table manageclassNameTable" style={{border:'1px', solid:'#ccc'}}>
                                <thead>
                                <th style={{width:"25%"}}><p className="limitTextRes">&nbsp; </p></th>
                                <th ><p className="limitTextRes">&nbsp; </p></th>
                                </thead>
                                <tbody>
                                <tr>
                                    <td><strong>School Name: </strong> </td><td>{schoolObj.schoolName}</td>
                                </tr>
                                <tr>
                                    <td><strong>Total Teacher Licenses: </strong> </td><td>{schoolObj.licensedTeachers}</td>
                                </tr>
                                <tr>
                                    <td><strong>Utilized Teacher License(s): </strong> </td><td>{schoolObj.usedTeachers}</td>
                                </tr>
                                <tr>
                                    <td><strong>Available Teacher License(s): </strong> </td><td>{(schoolObj.licensedTeachers - schoolObj.usedTeachers) >=0 ? (schoolObj.licensedTeachers - schoolObj.usedTeachers) : 0}</td>
                                </tr>
                                <tr>
                                    <td><strong>Active License(s): </strong> </td><td>{schoolObj.usedStudents}</td>
                                </tr>
                                <tr>
                                    <td><strong>License Expiry: </strong> </td><td>{schoolObj.licenseExpiry? (new Date(parseFloat(schoolObj.licenseExpiry))).toLocaleDateString() : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td><strong>Administrative Code: </strong> </td><td>{schoolObj.adminCode}
                                    <a className="Activity-toolTip toolTipCus">
                                        <span className="tooltiptext">
                                            Administrators can view reports by Departments and School by entering this code upon registering as an administrator. If administrator is already a registered LearnerPal teacher, this code can be entered under the teacher profile
                                        </span>
                                    </a>

                                    </td>
                                </tr>
                                <tr>
                                    <td><strong>School Code: </strong> </td><td>{schoolObj.teacherCode}
                                    <a className="Activity-toolTip toolTipCus">
                                        <span className="tooltiptext">
                                            All teachers are required to enter a school code upon registering in order to gain access to the schools licenses
                                        </span>
                                    </a>
                                    </td>

                                </tr>
                                <tr>
                                    <td><strong>Alternate Student Enrollment Code: </strong> </td><td>{schoolObj.studentCode}
                                    <a className="Activity-toolTip toolTipCus">
                                        <span className="tooltiptext">
                                            Students, who do not have Teachers that are using LearnerPal, can get access to LearnerPal content by registering with this code
                                        </span>
                                    </a>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>}
                        {!this.state.isSchoolDetails &&  <div className="respScrollTable">
                            <table className="table manageClassTable" style={{border:'1px', solid:'#ccc'}} >
                                <thead>
                                <th style={{width:"25%"}}><p className="limitTextRes"><strong>Teacher Name</strong> </p></th>
                                <th ><p className="limitTextRes">&nbsp; </p></th>
                                </thead>
                                <tbody>
                                {allTeachersHtml}
                                </tbody>
                            </table>
                        </div>}
                    </div>
                </div>
                <ProgressIndicator show={this.state.loading}/>
                {this.state.showAlert &&
                <div className="row">
                    <div className="col-md-3 col-xs-3"/>
                    <div className="col-md-6 col-xs-3">
                        <Alert bsStyle={this.state.alertStyle}
                               onDismiss={this.handleAlertDismiss}>
                            {this.state.alertText}
                        </Alert>
                    </div>
                    <div className="col-md-3 col-xs-3"/>
                </div>
                }
            </div>
        );
    }
}


function mapStateToProps(state = {}) {
    return {...state};
}

export default connect(mapStateToProps)(SchoolAdministration);
