/**
 * Created by ashahab on 8/12/2017.
 */
// import axios from "axios";
import React from 'react';
import apiClient from "./api-client";


export default class ElasticSearchQuery {

    query(type, columns, props, searchTerm = '', pageSize, activePage, sortObj, filterValue, successCallback, isQuery = true, customQuery, url = 'learnerpal/', dateRange = null, appendQuery = false) {

        if (sortObj && Object.keys(sortObj) <= 0) {
            sortObj = { _uid: { order: "asc" } }
        }



        const isLPAdmin = props.auth.user.userType.toLowerCase() === "admin" && props.auth.user.schoolName === "LearnerPal";

        let visibility = props.manageActivities && props.manageActivities.createActivityActiveOwnerFilter ? props.manageActivities.createActivityActiveOwnerFilter !== 'LearnerPal' ? `("${props.manageActivities.createActivityActiveOwnerFilter}"` : '(public OR "' + props.manageActivities.createActivityActiveOwnerFilter +'"': '(public OR "' + props.auth.user.userName+'"';
        if (isLPAdmin) {
            visibility += " OR LPAdmin";
        }
        if ((props.auth.user.linkedAccounts && props.auth.user.linkedAccounts.learnerPal) && props.auth.user.federatedIdentity) {
            visibility += ` OR (\"${props.auth.user.linkedAccounts.learnerPal}\")`
        }
        visibility += ')';
        let subjectsToSearch = "(";
        if (props.auth.user.subjects) {
            props.auth.user.subjects.forEach(sub => {
                subjectsToSearch += "\"" + sub + "\" OR ";
            })
        }
        subjectsToSearch = subjectsToSearch.substring(0, subjectsToSearch.length - 3);


        let visibilityQuery = "visibleTo : " + visibility
        if (props.auth.user.schoolName === "LearnerPal")
            visibilityQuery = "( " + visibilityQuery + " OR questionState2: (\"In Review\") OR questionState2: (\"Shared\"))"

        let query = visibilityQuery + " AND subjects : " + subjectsToSearch + ")";
        if(isLPAdmin){
            query = ''
        }

        if (appendQuery) {
            query += appendQuery;
        }

        if (customQuery) {
            query = customQuery;
        }
        if (!isQuery) {
            query = '';
        }

        if (searchTerm !== "") {
            if (query !== '') { query += " AND " }
            query += " keywords: (" + searchTerm + ")";
        }
        let from = activePage === 1 ? 0 : pageSize * (activePage - 1);

        let data = '';

        if (query === '' && filterValue.length === 0) {
            data =
            {
                sort: [sortObj],
                size: pageSize,
                from: from,
            };
        }
        else if (query === '' && filterValue.length > 0) {
            data =
            {
                query: { bool: { filter: { bool: { must: filterValue } } } },
                sort: [sortObj],
                size: pageSize,
                from: from,
            };
        }
        else if (query !== '' && filterValue.length > 0) {
            data =
            {
                query: { bool: { must: { query_string: { query: query } }, filter: { bool: { must: filterValue } } } },
                sort: [sortObj],
                size: pageSize,
                from: from,
            };
        }
        else if (query !== '' && filterValue.length === 0) {
            data =
            {
                query: { bool: { must: { query_string: { query: query } } } },
                sort: [sortObj],
                size: pageSize,
                from: from,
            };
        }

        if (type === 'audit_traildev' || type === 'student_audit_traildev') {

            let searchQuery = "";
            if (searchTerm !== "") {
                searchQuery += " keywords: (" + searchTerm + ")";
            }
            let esAttr = props.elasticSearchAttributes.map.get('lpAdminReport') ? props.elasticSearchAttributes.map.get('lpAdminReport') : null;
            let newDateRange = dateRange ? dateRange : esAttr.dateRange;

            if (searchQuery !== "" && newDateRange && filterValue.length === 0) {
                data = {
                    query: {
                        bool: {
                            must: [
                                // { exists: { field: "activityType" } },
                                {
                                    range: {
                                        "creationTime": {
                                            "gte": newDateRange.startDate,
                                            "lte": newDateRange.endDate
                                        }
                                    }
                                },
                                { query_string: { query: searchQuery } }
                            ]
                        }
                    }, "sort": sortObj, "size": pageSize, "from": from
                };
            }
            else if (searchQuery === "" && newDateRange && filterValue.length === 0) {
                data = {
                    query: {
                        bool: {
                            must: [
                                // { exists: { field: "activityType" } },
                                {
                                    range: {
                                        "creationTime": {
                                            "gte": newDateRange.startDate,
                                            "lte": newDateRange.endDate
                                        }
                                    }
                                }
                            ]
                        }
                    }, "sort": sortObj, "size": pageSize, "from": from
                };
            }
            else if (searchQuery !== "" && newDateRange && filterValue.length > 0) {
                data = {
                    "query": {
                        "bool": {
                            "must": [
                                // { "exists": { "field": "activityType" } },
                                {
                                    "range": {
                                        "creationTime": {
                                            "gte": newDateRange.startDate,
                                            "lte": newDateRange.endDate
                                        }
                                    }
                                },
                                { query_string: { query: searchQuery } }
                            ],
                            filter: { bool: { must: filterValue } }
                        }
                    }, "sort": sortObj, "size": pageSize, "from": from
                };
            }
            else if (searchQuery === "" && newDateRange && filterValue.length > 0) {
                data = {
                    "query": {
                        "bool": {
                            "must": [
                                // { "exists": { "field": "activityType" } },
                                {
                                    "range": {
                                        "creationTime": {
                                            "gte": newDateRange.startDate,
                                            "lte": newDateRange.endDate
                                        }
                                    }
                                }
                            ],
                            filter: { bool: { must: filterValue } }
                        }
                    }, "sort": sortObj, "size": pageSize, "from": from
                };
            }
            else {
                if (searchQuery === "" && filterValue.length === 0) {
                    data = {
                        query: {
                            // exists: { field: "activityType" }
                        },
                        sort: [sortObj],
                        size: pageSize,
                        from: from,
                    }
                }
                else if (searchQuery !== "" && filterValue.length === 0) {
                    data = {
                        query: {
                            "bool": {
                                "must": [
                                    // { "exists": { "field": "activityType" } },
                                    { query_string: { query: searchQuery } }
                                ]
                            }
                        },
                        sort: [sortObj],
                        size: pageSize,
                        from: from,
                    }
                }
                else if (searchQuery === "" && filterValue.length > 0) {
                    data = {
                        query: {
                            "bool": {
                                "must": [
                                    // { "exists": { "field": "activityType" } }
                                ],
                                filter: { bool: { must: filterValue } }
                            }
                        },
                        sort: [sortObj],
                        size: pageSize,
                        from: from,
                    }
                }
                else if (searchQuery !== "" && filterValue.length > 0) {
                    data = {
                        query: {
                            "bool": {
                                "must": [
                                    // { "exists": { "field": "activityType" } },
                                    { query_string: { query: searchQuery } }
                                ],
                                filter: { bool: { must: filterValue } }
                            }
                        },
                        sort: [sortObj],
                        size: pageSize,
                        from: from,
                    }
                }
            }
        }

        if (columns && columns.length > 0) {
            data['_source'] = {
                'includes': columns
            }
        }
        apiClient.invokeApi({}, '/getesdata', 'POST', {}, { payload: data, index: url, esDocumentType: type }, '5').then(function (result) {

            let reponse = {};
            reponse.results = [];
            if (result.data.hits && result.data.hits.hits.length > 0) {
                for (var a = 0; a < result.data.hits.hits.length; a++) {
                    reponse.results.push(result.data.hits.hits[a]._source);
                }
            }
            reponse.totalRecords = result.data.hits ? result.data.hits.total : 0;

            console.log(reponse);

            successCallback(reponse.results, reponse.totalRecords);
        });

        // axiosInstance({
        //     method: 'post',
        //     url: '/' + type + '/_search',
        //     data: JSON.stringify(data)
        // }).then(response => {

        //     console.log(response)
        //     successCallback(response.data.results, response.data.totalRecords);
        // }
        // ).catch();
    }

    async queryPagination(type, columns, props, searchTerm = '', pageSize, activePage, sortObj, filterValue, successCallback, isQuery = true, customQuery, url = 'learnerpal/', dateRange = null, appendQuery = false) {

        if (sortObj && Object.keys(sortObj) <= 0) {
            sortObj = { _uid: { order: "asc" } }
        }

        let visibility = props.manageActivities && props.manageActivities.createActivityActiveOwnerFilter ? props.manageActivities.createActivityActiveOwnerFilter !== 'LearnerPal' ? `("${props.manageActivities.createActivityActiveOwnerFilter}"` : '(public OR "' + props.manageActivities.createActivityActiveOwnerFilter + '"' : '(public OR "' + props.auth.user.userName+ '"';
        if (props.auth.user.userType.toLowerCase() === "admin" && props.auth.user.schoolName === "LearnerPal") {
            visibility += " OR LPAdmin";
        }
        visibility += ')';
        let subjectsToSearch = "(";
        if (props.auth.user.subjects) {
            props.auth.user.subjects.forEach(sub => {
                subjectsToSearch += "\"" + sub + "\" OR ";
            })
        }
        subjectsToSearch = subjectsToSearch.substring(0, subjectsToSearch.length - 3);


        let visibilityQuery = "visibleTo : " + visibility
        if (props.auth.user.schoolName === "LearnerPal")
            visibilityQuery = "( " + visibilityQuery + " OR questionState2: (\"In Review\") OR questionState2: (\"Shared\"))"

        let query = visibilityQuery + " AND subjects : " + subjectsToSearch + ")";

        if (appendQuery) {
            query += appendQuery;
        }

        if (customQuery) {
            query = customQuery;
        }
        if (!isQuery) {
            query = '';
        }

        if (searchTerm !== "") {
            if (query !== '') { query += " AND " }
            query += " keywords: (" + searchTerm + ")";
        }
        let from = activePage === 1 ? 0 : pageSize * (activePage - 1);

        let data = '';

        if (query === '' && filterValue.length === 0) {
            data =
            {
                sort: [sortObj],
                size: pageSize,
                from: from,
            };
        }
        else if (query === '' && filterValue.length > 0) {
            data =
            {
                query: { bool: { filter: { bool: { must: filterValue } } } },
                sort: [sortObj],
                size: pageSize,
                from: from,
            };
        }
        else if (query !== '' && filterValue.length > 0) {
            data =
            {
                query: { bool: { must: { query_string: { query: query } }, filter: { bool: { must: filterValue } } } },
                sort: [sortObj],
                size: pageSize,
                from: from,
            };
        }
        else if (query !== '' && filterValue.length === 0) {
            data =
            {
                query: { bool: { must: { query_string: { query: query } } } },
                sort: [sortObj],
                size: pageSize,
                from: from,
            };
        }

        if (type === 'audit_traildev' || type === 'student_audit_traildev') {

            let searchQuery = "";
            if (searchTerm !== "") {
                searchQuery += " keywords: (" + searchTerm + ")";
            }
            let esAttr = props.elasticSearchAttributes.map.get('lpAdminReport') ? props.elasticSearchAttributes.map.get('lpAdminReport') : null;
            let newDateRange = dateRange ? dateRange : esAttr.dateRange;

            if (searchQuery !== "" && newDateRange && filterValue.length === 0) {
                data = {
                    query: {
                        bool: {
                            must: [
                                // { exists: { field: "activityType" } },
                                {
                                    range: {
                                        "creationTime": {
                                            "gte": newDateRange.startDate,
                                            "lte": newDateRange.endDate
                                        }
                                    }
                                },
                                { query_string: { query: searchQuery } }
                            ]
                        }
                    }, "sort": sortObj, "size": pageSize, "from": from
                };
            }
            else if (searchQuery === "" && newDateRange && filterValue.length === 0) {
                data = {
                    query: {
                        bool: {
                            must: [
                                // { exists: { field: "activityType" } },
                                {
                                    range: {
                                        "creationTime": {
                                            "gte": newDateRange.startDate,
                                            "lte": newDateRange.endDate
                                        }
                                    }
                                }
                            ]
                        }
                    }, "sort": sortObj, "size": pageSize, "from": from
                };
            }
            else if (searchQuery !== "" && newDateRange && filterValue.length > 0) {
                data = {
                    "query": {
                        "bool": {
                            "must": [
                                // { "exists": { "field": "activityType" } },
                                {
                                    "range": {
                                        "creationTime": {
                                            "gte": newDateRange.startDate,
                                            "lte": newDateRange.endDate
                                        }
                                    }
                                },
                                { query_string: { query: searchQuery } }
                            ],
                            filter: { bool: { must: filterValue } }
                        }
                    }, "sort": sortObj, "size": pageSize, "from": from
                };
            }
            else if (searchQuery === "" && newDateRange && filterValue.length > 0) {
                data = {
                    "query": {
                        "bool": {
                            "must": [
                                // { "exists": { "field": "activityType" } },
                                {
                                    "range": {
                                        "creationTime": {
                                            "gte": newDateRange.startDate,
                                            "lte": newDateRange.endDate
                                        }
                                    }
                                }
                            ],
                            filter: { bool: { must: filterValue } }
                        }
                    }, "sort": sortObj, "size": pageSize, "from": from
                };
            }
            else {
                if (searchQuery === "" && filterValue.length === 0) {
                    data = {
                        query: {
                            // exists: { field: "activityType" }
                        },
                        sort: [sortObj],
                        size: pageSize,
                        from: from,
                    }
                }
                else if (searchQuery !== "" && filterValue.length === 0) {
                    data = {
                        query: {
                            "bool": {
                                "must": [
                                    // { "exists": { "field": "activityType" } },
                                    { query_string: { query: searchQuery } }
                                ]
                            }
                        },
                        sort: [sortObj],
                        size: pageSize,
                        from: from,
                    }
                }
                else if (searchQuery === "" && filterValue.length > 0) {
                    data = {
                        query: {
                            "bool": {
                                "must": [
                                    // { "exists": { "field": "activityType" } }
                                ],
                                filter: { bool: { must: filterValue } }
                            }
                        },
                        sort: [sortObj],
                        size: pageSize,
                        from: from,
                    }
                }
                else if (searchQuery !== "" && filterValue.length > 0) {
                    data = {
                        query: {
                            "bool": {
                                "must": [
                                    // { "exists": { "field": "activityType" } },
                                    { query_string: { query: searchQuery } }
                                ],
                                filter: { bool: { must: filterValue } }
                            }
                        },
                        sort: [sortObj],
                        size: pageSize,
                        from: from,
                    }
                }
            }
        }

        if (columns && columns.length > 0) {
            data['_source'] = {
                'includes': columns
            }
        }
        data.from = 0;
        data.size = 5000;
        let response = {};
        response.results = [];
        while (response.results.length < (pageSize - 1)) {
            let result = await apiClient.invokeApi({}, '/getesdata', 'POST', {}, { payload: data, index: url, esDocumentType: type }, '5')

            if (result.data.hits && result.data.hits.hits.length > 0) {
                for (var a = 0; a < result.data.hits.hits.length; a++) {
                    response.results.push(result.data.hits.hits[a]._source);
                }
            }
            data.from = response.results.length + 1
            response.totalRecords = result.data.hits ? result.data.hits.total : 0;
        }


        console.log(response);

        successCallback(response.results, response.totalRecords);

    }

    random(type, columns, props, searchTerm = '', pageSize, filterValue, questionType, successCallback) {



        let query = '';

        let visibility = props.manageActivities && props.manageActivities.createActivityActiveOwnerFilter ? props.manageActivities.createActivityActiveOwnerFilter !== 'LearnerPal' ? `("${props.manageActivities.createActivityActiveOwnerFilter}"` : '(public OR "' + props.manageActivities.createActivityActiveOwnerFilter + '"' : '(public OR "' + props.auth.user.userName + '"';
        if (props.auth.user.userType.toLowerCase() === "admin" && props.auth.user.schoolName === "LearnerPal") {
            visibility += " OR LPAdmin";
        }
        if ((props.auth.user.linkedAccounts && props.auth.user.linkedAccounts.learnerPal) && props.auth.user.federatedIdentity) {
            visibility += ` OR (\"${props.auth.user.linkedAccounts.learnerPal}\")`
        }
        visibility += ')';

        // query = "visibleTo : " + visibility
        let visibilityQuery = "visibleTo : " + visibility
        if (props.auth.user.schoolName === "LearnerPal")
            visibilityQuery = "( " + visibilityQuery + " OR questionState2: (\"In Review\") OR questionState2: (\"Shared\"))"

        query = visibilityQuery;

        if (props.auth.user.userType !== 'student') {
            let subjectsToSearch = "(";
            if (props.auth.user.subjects) {
                props.auth.user.subjects.forEach(sub => {
                    subjectsToSearch += "\"" + sub + "\" OR ";
                })
            }
            subjectsToSearch = subjectsToSearch.substring(0, subjectsToSearch.length - 3);
            query = "visibleTo : " + visibility + " AND subjects : " + subjectsToSearch + ")";
        }




        if (searchTerm !== "") {
            if (query !== '') { query += " AND " }
            query += " keywords: (" + searchTerm + ")";
        }

        let data = '';
        let fValue = []
        if (questionType) {
            fValue.push({ "terms": { "questionType": [questionType] } });
        }
        if (filterValue.length > 0) {
            fValue.push(filterValue);
        }

        let seedValue = Math.random * 1000000000;

        if (query === '' && fValue.length > 0) {
            data =
            {
                query: { function_score: { filter: { bool: { must: fValue } } }, functions: [{ random_score: { seed: seedValue } }] },
                size: pageSize
            };
        }
        else if (query !== '' && fValue.length > 0) {
            data =
            {
                query: {
                    function_score: {
                        query: {
                            bool: {
                                must: { query_string: { query: query } }, filter: {
                                    bool: { must: fValue }
                                }
                            }
                        }, functions: [{ random_score: { seed: seedValue } }]
                    }
                },
                size: pageSize
            };
        }


        if (columns && columns.length > 0) {
            data['_source'] = {
                'includes': columns
            }
        }

        if(data === '' && query !== ''){
            data = {
                query: {
                    function_score: {
                        query: {
                            bool: {
                                must: { query_string: { query: query } }
                            }
                        }, functions: [{ random_score: { seed: seedValue } }]
                    }
                },
                size: pageSize
            };
        }

        apiClient.invokeApi({}, '/getesdata', 'POST', {}, { payload: data, index: 'learnerpal/', esDocumentType: type }, '5').then(function (result) {

            let reponse = {};
            reponse.results = [];
            if (result.data.hits && result.data.hits.hits.length > 0) {
                for (var a = 0; a < result.data.hits.hits.length; a++) {
                    reponse.results.push(result.data.hits.hits[a]._source);
                }
            }
            reponse.totalRecords = result.data.hits ? result.data.hits.total : 0;

            console.log(reponse);

            successCallback(reponse.results, reponse.totalRecords);
        });

        // axiosInstance({
        //     method: 'post',
        //     url: '/' + type + '/_search',
        //     data: JSON.stringify(data)
        // }).then(response => {
        //     successCallback(response.data.results, response.data.totalRecords);
        // }
        // ).catch();
    }

    getFilters(type, columns, props, searchTerm = '', allFilters, dependentFilters, successCallback, isQuery = true, customQuery, url = 'learnerpal/', appendQuery = false) {


        const isLPAdmin = props.auth.user.userType.toLowerCase() === "admin" && props.auth.user.schoolName === "LearnerPal";

        let visibility = props.manageActivities && props.manageActivities.createActivityActiveOwnerFilter ? props.manageActivities.createActivityActiveOwnerFilter !== 'LearnerPal' ? `("${props.manageActivities.createActivityActiveOwnerFilter}"` : '(public OR "' + props.manageActivities.createActivityActiveOwnerFilter + '"': '(public OR "' + props.auth.user.userName + '"';
        if (isLPAdmin) {
            visibility += " OR LPAdmin";
        }
        if ((props.auth.user.linkedAccounts && props.auth.user.linkedAccounts.learnerPal) && props.auth.user.federatedIdentity) {
            visibility += ` OR (\"${props.auth.user.linkedAccounts.learnerPal}\")`
        }
        visibility += ')';
        let subjectsToSearch = "";
        if (props.auth.user.subjects) {
            props.auth.user.subjects.forEach(sub => {
                subjectsToSearch += "\"" + sub + "\" OR ";
            })
        }

        subjectsToSearch = subjectsToSearch.substring(0, subjectsToSearch.length - 3);

        let visibilityQuery = "visibleTo : " + visibility
        if (props.auth.user.schoolName === "LearnerPal")
            visibilityQuery = "( " + visibilityQuery + " OR questionState2: (\"In Review\") OR questionState2: (\"Shared\"))"

        let query = visibilityQuery + " AND subjects : (" + subjectsToSearch + ")";

        if(isLPAdmin){
            query = '';
            isQuery = false;
        }

        if (appendQuery) {
            query += appendQuery;
        }

        if (customQuery) {
            query = customQuery;
        }

        if (!isQuery) {
            query = '';
        }

        if (searchTerm !== "") {
            if (query !== '') { query += " AND " }
            query += " keywords: (" + searchTerm + ")";
        }
        let data = '';

        if (isQuery && dependentFilters.length > 0) {
            data =
            {
                query: { bool: { must: { query_string: { query: query } }, filter: { bool: { must: dependentFilters } } } },
                aggs: allFilters,
            };
        }
        else if (!isQuery && dependentFilters.length > 0) {
            data =
            {
                query: { bool: { filter: { bool: { must: dependentFilters } } } },
                aggs: allFilters,
            };
        }
        else if (isQuery && !dependentFilters.length > 0) {
            data =
            {
                query: { bool: { must: { query_string: { query: query } } } },
                aggs: allFilters,
            };
        }
        else if (!isQuery && !dependentFilters.length > 0) {
            data =
            {
                aggs: allFilters,
            };
        }

        if (columns && columns.length > 0) {
            data['_source'] = {
                'includes': columns
            }
        }


        apiClient.invokeApi({}, '/getesdata', 'POST', {}, { payload: data, index: url, esDocumentType: type }, '5').then(function (data) {

            let reponse = [];

            if (data.data.aggregations) {

                for (let key in data.data.aggregations) {
                    if (data.data.aggregations.hasOwnProperty(key)) {
                        let values = [];
                        data.data.aggregations[key].buckets.forEach(value => {
                            values.push({ name: value.key, count: value.doc_count })
                        });
                        reponse.push({ label: key, allValues: values });
                    }
                }
            }

            console.log(reponse);

            successCallback({ data: reponse });

        });

        // axiosInstance({
        //     method: 'post',
        //     url: '/' + type + '/_search',
        //     data: JSON.stringify(data)
        // }).then(response => {

        //     console.log(response);

        //     successCallback(response);
        // }
        // ).catch();
    }

    checkEmail(type, email, successCallback) {
        let data = {}
        data = {
            "query": {
                "match_phrase_prefix": {
                    "email": email
                }
            }
        }

        apiClient.invokeApi({}, '/getesdata', 'POST', {}, { payload: data, index: 'learnerpal/', esDocumentType: type }, '5').then(function (result) {

            let reponse = false;
            if (result.data.hits && result.data.hits.hits.length > 0) {
                reponse = true
            }
            console.log(reponse);

            successCallback(reponse);
        });
    }
}
