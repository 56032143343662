import React from 'react';
import apiClient from '../../../actions/api-client';
import SelectFieldGroup from '../../forms/select-field-group';
import Unit from './unit';
import {connect} from 'react-redux';
import TextFieldGroup from '../../forms/text-field-group';
import DraggableList from 'react-draggable-list';
import validateInput from "../../../validations/create-course";
import {Button} from "react-bootstrap";
import {browserHistory , withRouter} from "react-router";
import LoginUtils from "../../common/login-utils";
import ManageCourseActions from "../../../actions/manage-courses-actions";
import Breadcrumb from "../../breadcrumb/breadcrumb";
import ProgressIndicator from "../../progress-indicator/progress-indicator";
import $ from "jquery";
// import axios from "axios";
import {ElasticSearchTypes} from "../../../actions/types";
import config from "../../../config";
import {detect} from 'detect-browser';
import ClevertapReact from "clevertap-react";

class CreateCourse extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            leave : false,
            courseName : '',
            courseDesc : '',
            allSubjects : [],
            allUnits: [],
            selectedSubject : '',
            units : [],
            errors : {},
            courseState: "In Progress",
            studentCourse: "No",
            loading: false,
            editCourseName: false
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.getAllSubjects = this.getAllSubjects.bind(this);
        this.getUnits = this.getUnits.bind(this);
        this.addUnit = this.addUnit.bind(this);
        this.deleteUnit = this.deleteUnit.bind(this);
        this.addUnitInfo = this.addUnitInfo.bind(this);
        this.addCustomUnitInfo = this.addCustomUnitInfo.bind(this);
        this.updateItem = this.updateItem.bind(this);
        this.onSortingChange = this.onSortingChange.bind(this);
        this.isValid = this.isValid.bind(this);
        this.getAllCourses = this.getAllCourses.bind(this);
        this.addActivity = this.addActivity.bind(this);
        this.addActivityInUnit = this.addActivityInUnit.bind(this);
        this.onSortingChangeActivity = this.onSortingChangeActivity.bind(this);
        this.onDeleteActivity = this.onDeleteActivity.bind(this);
        this.onActivityOptionsChange = this.onActivityOptionsChange.bind(this);
        this.callbackFunction = this.callbackFunction.bind(this);
        this.backToManageCourses = this.backToManageCourses.bind(this);
        this.showIndicator = this.showIndicator.bind(this);
        this.hideIndicator = this.hideIndicator.bind(this);
        this.emptyState = this.emptyState.bind(this);
        this.handleAlertDismiss = this.handleAlertDismiss.bind(this);
        this.cancelCourse = this.cancelCourse.bind(this);
        this.keepOnPage = this.keepOnPage.bind(this);
        this.route= this.props.route;
        this.router= this.props.router;
        this.setTrueForLeave = this.setTrueForLeave.bind(this);
    }

    handleAlertDismiss(e) {
        this.setState({'showAlert': false, 'alertDisplayed': true});
    }
    showIndicator() {
        this.setState({
            ...this.state,
            loading: true
        });
    }

    hideIndicator() {
        this.setState({
            ...this.state,
            loading: false
        });
    }

    keepOnPage(e) {
        let message = 'Unsaved changes will be lost';
        e.returnValue = message;
        if (this.state.leave || e.pathname === "/courses/activities" ) {
            return null;
        } else {
            return message;
        }
    }
    componentDidMount() {
        LoginUtils.isAuthenticated(this.props, browserHistory, '/courses/create-course', '/');
        this.router.setRouteLeaveHook(this.route, this.keepOnPage);
        this.getAllSubjects();
        let that = this;
        this.getUnits(function(err, data) {
            if (that.props.manageCourses.isEditCourse && !that.props.manageCourses.isFromCourse) {
                try{
                    let editCourseObj = that.props.manageCourses.editCourseObj;
                    let newCourseObj = {};
                    let newUnits = [];
                    if (editCourseObj.units && editCourseObj.units.length > 0) {
                        for (let i = 0; i < editCourseObj.units.length; i++) {
                            let tempUnit = {};
                            let id = Math.random() * 10000000000000000000;
                            tempUnit.unitDesc = editCourseObj.units[i].keywords;
                            tempUnit.unitValue = editCourseObj.units[i].unitName;
                            tempUnit.unitID = editCourseObj.units[i].unitID;
                            tempUnit.name = "unit_" + id; //needed for sorting (drag n drop)
                            tempUnit.ID = "unit_" + id;
                            tempUnit.allUnits = data;
                            tempUnit.onChange = that.addUnitInfo;
                            tempUnit.onCustomUnitChange = that.addCustomUnitInfo
                            tempUnit.onDelete = that.deleteUnit;
                            tempUnit.onAddActivity = that.addActivity;
                            tempUnit.onSortingChange = that.onSortingChange;
                            tempUnit.onSortingChangeActivity = that.onSortingChangeActivity;
                            tempUnit.activityOnDeleteActivity = that.onDeleteActivity;
                            tempUnit.activityOnActivityOptionsChange = that.onActivityOptionsChange;
                            tempUnit.activityOnChange = that.onChange;
                            tempUnit.activities = [];
                            if (editCourseObj.units[i].activities && editCourseObj.units[i].activities.length > 0) {
                                for (let j = 0; j < editCourseObj.units[i].activities.length; j++) {
                                    let activityObj = {};
                                    let id = Math.random() * 10000000000000000000;
                                    activityObj.ID = "activity_" + id;
                                    activityObj.name = "activity_" + id; //needed for sorting (drag n drop)
                                    activityObj.onDeleteActivity = that.onDeleteActivity;
                                    activityObj.onActivityOptionsChange = that.onActivityOptionsChange;
                                    activityObj.unitID = tempUnit.ID;
                                    activityObj.onChange = that.onChange;
                                    activityObj.activityName = editCourseObj.units[i].activities[j].activityName;
                                    activityObj.activityID = editCourseObj.units[i].activities[j].activityID;
                                    activityObj.activityType = editCourseObj.units[i].activities[j].activityType;
                                    activityObj.questionActivityType = editCourseObj.units[i].activities[j].questionActivityType;
                                    tempUnit.activities.push(activityObj);
                                }
                            }
                            newUnits.push(
                                tempUnit
                            );
                        }
                        newCourseObj.units = newUnits;
                    }
                    newCourseObj.courseName = editCourseObj.courseName;
                    newCourseObj.courseDesc = editCourseObj.description;
                    newCourseObj.selectedSubject = editCourseObj.subject;
                    newCourseObj.courseID = editCourseObj.courseID;
                    newCourseObj.units = newUnits;
                    if (editCourseObj.courseState)
                    {
                        newCourseObj.courseState = editCourseObj.courseState;
                        that.setState({courseState: editCourseObj.courseState});
                    }
                    if (editCourseObj.studentPractice)
                    {
                        newCourseObj.studentCourse = editCourseObj.studentPractice;
                        that.setState({studentCourse: editCourseObj.studentPractice});
                    }
                    that.setState({courseName: editCourseObj.courseName,
                        courseDesc: editCourseObj.description, selectedSubject: editCourseObj.subject,
                        units: newUnits
                    });
                    const {dispatch} = that.props;
                    ManageCourseActions.setCreateCourseData(dispatch, newCourseObj, false, null);
                } catch(err) {
                    console.log(err);
                }
            }
            if (!that.props.manageCourses.isEditCourse && that.props.manageCourses.courseObj.units.length ===0) {
                that.addUnit();
            }
        });

        window.addEventListener('beforeunload', this.keepOnPage);

    }
    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.keepOnPage);
    }
    cancelCourse()
    {
        this.backToManageCourses('', '');
    }
    backToManageCourses(message = '', messageType)
    {

        let showAlert = false;

        if (message !== '')
        {
            showAlert = true;
        }
        this.showIndicator();
        if (this.props.manageCourses.isEditCourse)
        {

            let thats = this;
            let body3 ={
                lockStatus:false,
                ownerName:thats.props.auth.user.userName,
                courseID:thats.props.manageCourses.courseObj.courseID
            };
            apiClient.invokeApi({},'/course/lock', 'PUT', {},body3, "2").then(function (result) {
                setTimeout(()=>{
                    const {dispatch} = thats.props;
                    ManageCourseActions.setMessage(dispatch, showAlert, message, messageType, function () {
                        thats.hideIndicator();
                        browserHistory.push('/courses');
                    });
                }, 3000);

            }).catch(function (result) {

            });
        }
        else {
            let thats = this;
            setTimeout(()=>{
                thats.hideIndicator();
                const {dispatch} = thats.props;
                ManageCourseActions.setMessage(dispatch, showAlert, message, messageType, function () {
                    thats.hideIndicator();
                    browserHistory.push('/courses');
                });
            }, 2000);
        }
    }

    getAllSubjects()
    {
        let sortedSubjects = [];
        this.props.auth.user.subjects.forEach(sub => {
            sortedSubjects.push({"id": sub, "name": sub});
        });
        sortedSubjects.sort(function(a, b) {
            return a.id > b.id;
        });
        this.setState({allSubjects:sortedSubjects});
    }

    addActivityInUnit()
    {

        let tempProps = this.props.manageCourses;
        if (tempProps.courseObj && this.refs.createCourse)
        {
            for (let i =0; i< tempProps.courseObj.units.length; i++)
            {
                if (tempProps.courseObj.units[i].ID === tempProps.selectedUnit)
                {
                    if (tempProps.courseObj.units[i].activities && tempProps.courseObj.units[i].activities.length > 0)
                    {
                        for (let j=0; j<tempProps.courseObj.units[i].activities.length; j++)
                        {
                            let activityObj =  tempProps.courseObj.units[i].activities[j];
                            let id = Math.random() * 10000000000000000000;
                            activityObj.ID = "activity_" + id;
                            activityObj.name = "activity_" +id; //needed for sorting (drag n drop)
                            activityObj.onDeleteActivity = this.onDeleteActivity;
                            activityObj.onActivityOptionsChange = this.onActivityOptionsChange;
                            activityObj.unitID = tempProps.courseObj.units[i].ID;
                            activityObj.onChange = this.onChange;
                            activityObj.questionActivityType = 'practice';
                            tempProps.courseObj.units[i].activities[j] = activityObj;
                        }
                    }
                }
            }
        }
        if (!tempProps.courseObj.courseState)
        {
            tempProps.courseObj.courseState = 'In Progress';
        }
        this.setState({selectedSubject: tempProps.courseObj.selectedSubject, units: tempProps.courseObj.units, courseState: tempProps.courseObj.courseState});
        const {dispatch} =this.props;
        ManageCourseActions.setCreateCourseData(dispatch, tempProps.courseObj, false, null);
    }

    addUnit() {
        let unit = {};
        let id = Math.random() * 10000000000000000000;
        unit.name = "unit_" +id; //needed for sorting (drag n drop)
        unit.ID = "unit_" + id;
        unit.unitValue = '';
        unit.allUnits = this.state.allUnits;
        unit.onChange = this.addUnitInfo;
        unit.onCustomUnitChange = this.addCustomUnitInfo;
        unit.onDelete = this.deleteUnit;
        unit.onAddActivity = this.addActivity;
        unit.onSortingChange = this.onSortingChange;
        unit.onSortingChangeActivity = this.onSortingChangeActivity;
        unit.activityOnDeleteActivity = this.onDeleteActivity;
        unit.activityOnActivityOptionsChange = this.onActivityOptionsChange;
        unit.activityOnChange = this.onChange;
        let tempCourseObj = this.props.manageCourses.courseObj;
        tempCourseObj.units.push(unit);
        this.setState({units:tempCourseObj.units});
        const {dispatch} = this.props;
        ManageCourseActions.setCreateCourseData(dispatch, tempCourseObj, false, null);
    }

    addActivity(event)
    {
        const {dispatch} = this.props;
        ManageCourseActions.setCreateCourseData(dispatch, this.props.manageCourses.courseObj, true, event.target.id, this.props.manageCourses.isEditCourse);
        browserHistory.push('/courses/activities');
    }

    onDeleteActivity(e)
    {

        let courseObj = this.props.manageCourses.courseObj;
        let currentUnits = this.props.manageCourses.courseObj.units;
        let newUnits = [];
        let unitIndex = currentUnits.findIndex(x=> x.ID === e.target.id);
        if (unitIndex !== -1)
        {
            let activityIndex = currentUnits[unitIndex].activities.findIndex(y=> y.ID === e.target.name);

            if (activityIndex !== -1)
            {
                let acts = [
                    ...this.props.manageCourses.courseObj.units[unitIndex].activities.slice(0,activityIndex),
                    ...this.props.manageCourses.courseObj.units[unitIndex].activities.slice(activityIndex+1)
                ];

                newUnits = [
                    ...this.props.manageCourses.courseObj.units.slice(0,unitIndex),
                    Object.assign({}, this.props.manageCourses.courseObj.units[unitIndex], {'activities': acts}),
                    ...this.props.manageCourses.courseObj.units.slice(unitIndex+1)
                ];
            }
        }

        courseObj.units = [...newUnits];

        const {dispatch} = this.props;
        ManageCourseActions.setCreateCourseData(dispatch, courseObj, false, null);

    }
    callbackFunction()
    {

        let courseObj = this.props.manageCourses.courseObj;
        courseObj.units = this.state.tempUnits;
        this.setState({units: courseObj.units});
        const {dispatch} = this.props;
        ManageCourseActions.setCreateCourseData(dispatch, courseObj, false, null);
    }

    deleteUnit(e) {
        let currentUnits = this.props.manageCourses.courseObj.units;
        for (let i=0; i<currentUnits.length; i++) {
            let unit = currentUnits[i];
            if (unit.ID === e.target.name) {
                currentUnits.splice(i,1);
                break;
            }
        }
        this.setState({units: currentUnits});
        let courseObj = this.props.manageCourses.courseObj;
        courseObj.units = currentUnits;
        const {dispatch} = this.props;
        ManageCourseActions.setCreateCourseData(dispatch, courseObj, false, null);
    }

    getUnits(callback) {
        let that = this;
        apiClient.invokeApi({ownerName:  this.props.auth.user.userName}, '/unit/owner/{ownerName}', 'GET', {}, {}, '3').then(function(result){
            that.setState({allUnits: result.data});
            callback(null, result.data);
        }).catch( function(result){
        });
    }

    onChange(e) {

        if (e.target.name === 'selectedSubject')
        {
            this.setState({[e.target.name] : e.target.value});
        }
        const {dispatch} = this.props;
        let tempCourseObj = this.props.manageCourses.courseObj;
        tempCourseObj[e.target.name] = e.target.value;
        ManageCourseActions.setCreateCourseData(dispatch, tempCourseObj, false, null);
    }

    onActivityOptionsChange(e)
    {

        let courseObj = this.props.manageCourses.courseObj;
        let currentUnits = this.props.manageCourses.courseObj.units;
        let newUnits = [];
        let unitIndex = currentUnits.findIndex(x=> x.ID === e.target.id);
        if (unitIndex !== -1)
        {
            let activityIndex = currentUnits[unitIndex].activities.findIndex(y=> y.ID === e.target.name);

            if (activityIndex !== -1)
            {
                let acts = [
                    ...this.props.manageCourses.courseObj.units[unitIndex].activities.slice(0,activityIndex),
                    Object.assign({}, this.props.manageCourses.courseObj.units[unitIndex].activities[activityIndex], {'questionActivityType': e.target.value}),
                    ...this.props.manageCourses.courseObj.units[unitIndex].activities.slice(activityIndex+1)
                ];

                newUnits = [
                    ...this.props.manageCourses.courseObj.units.slice(0,unitIndex),
                    Object.assign({}, this.props.manageCourses.courseObj.units[unitIndex], {'activities': acts}),
                    ...this.props.manageCourses.courseObj.units.slice(unitIndex+1)
                ];
            }
        }

        courseObj.units = [...newUnits];

        const {dispatch} = this.props;
        ManageCourseActions.setCreateCourseData(dispatch, courseObj, false, null);
    }

    isNotDuplicatedName(callback)
    {
        // let axiosInstance = axios.create({
        //     baseURL: 'https://search-learnerpal-eimkio3umgx5fnpuy7ypsrrsri.us-east-1.es.amazonaws.com/learnerpal',
        //     timeout: 60000,
        //     headers: {'Content-Type': 'application/json'},
        //     responseType: 'json',
        //     transformResponse: {function (data) {
        //         return data;
        //     }}
        // });
        // axiosInstance({
        //     method: 'GET',
        //     url: '/' + ElasticSearchTypes.Course+config.stage + '/' +  this.props.manageCourses.courseObj.courseName.replace(/\s|\W|[#$%^&*()'.]/g, "")+ "_" + this.props.auth.user.userName,
        // }).then(response=>
        //     {
        //
        //         callback(response.data.found);
        //     }
        // ).catch(response=>{
        //
        //     callback(response.response.data.found);
        // });
        apiClient.invokeApi({}, '/getesdata', 'POST', {}, { id: "/" + this.props.manageCourses.courseObj.courseName.replace(/\s|\W|[#$%^&*()'.]/g, "").toLocaleLowerCase() + "_" + this.props.auth.user.userName, index: 'learnerpal/', esDocumentType: ElasticSearchTypes.Course + config.stage }, '5').then(function (result) {

            callback(result.data.found);
        }).catch(function (error) {

            callback(false);
        });
    }

    setTrueForLeave(e) {
        this.setState({leave : true}, this.onSubmit(e));
    }

    onSubmit(e) {
        let that = this;
        let tempState = this.props.manageCourses.courseObj;
        let tempUnit = tempState.units;
        let allUnits =[];
        for(let a=0; a<tempUnit.length; a++)
        {
            let temp = {};
            let tempActivites = [];
            if (tempUnit[a].activities && tempUnit[a].activities.length>0)
            {
                for (let g =0; g<tempUnit[a].activities.length; g++)
                {
                    let tempAct = {};
                    tempAct.activityName = tempUnit[a].activities[g].activityName;
                    tempAct.activityID = tempUnit[a].activities[g].activityID;
                    tempAct.orderID = g+1;
                    tempAct.questionActivityType =  tempUnit[a].activities[g].questionActivityType;
                    tempActivites.push(tempAct);
                }
            }
            temp.activities = tempActivites;
            temp.orderID = a+1;
            temp.keywords = tempUnit[a].unitDesc;
            temp.unitName = tempUnit[a].unitValue;
            if(tempUnit[a].unitID)
            {
                temp.unitID = tempUnit[a].unitID;
            }

            allUnits.push(temp);
        }
        if (this.isValid())
        {
            if (that.props.manageCourses.isEditCourse)
            {

                that.showIndicator();
                if (that.props.manageCourses.isEditCourse) {

                    let body1 = {
                        ownerName: that.props.auth.user.userName,
                        courseID: tempState.courseID,
                        courseName: tempState.courseName,
                        courseState: tempState.courseState,
                        studentPractice: tempState.studentCourse,
                        units: allUnits,
                        description: tempState.courseDesc,
                        subjectName: tempState.selectedSubject,
                        department: that.props.auth.user.department,
                        schoolName: that.props.auth.user.schoolName,
                        assignTo: that.props.manageCourses.editCourseObj.assignTo,
                        sharedWith: that.props.manageCourses.editCourseObj.sharedWith,
                    };
                    if (that.props.manageCourses.editCourseObj.ownerName === that.props.auth.user.userName) {
                        let thats = that;
                        apiClient.invokeApi({}, '/course', 'PUT', {}, body1).then(function (result2) {
                            ClevertapReact.event("Update Course", {'userName' : that.props.auth.user.userName});
                            let body2 = {
                                lockStatus: false,
                                ownerName: thats.props.auth.user.userName,
                                courseID: thats.props.manageCourses.courseObj.courseID
                            };
                            apiClient.invokeApi({}, '/course/lock', 'PUT', {}, body2, "2").then(function (result) {
                                // that.emptyState();
                                // that.backToManageCourses(result2.data, 'success');
                            }).catch(function (result) {

                            });

                            if (thats.state.assignCourse) {
                                setTimeout(() => {
                                    apiClient.invokeApi({courseID: thats.props.manageCourses.courseObj.courseID}, '/course/{courseID}', 'GET', {}, {}).then(function (result) {

                                        browserHistory.push({
                                            pathname: '/assignments/assign',
                                            state: {
                                                isCourseAssignment: true,
                                                course: result.data,
                                                isCourseLoaded: true,
                                                view: 'course'
                                            }
                                        });
                                    }).catch(function (error) {

                                    });
                                }, 1000);
                            }
                            else {
                                thats.emptyState();
                                thats.backToManageCourses(result2.data, 'success');
                            }

                        }).catch(function (result) {

                            thats.backToManageCourses(result.data, 'danger');
                        });
                    }
                    else {


                        let thats = that;
                        apiClient.invokeApi({courseID: tempState.courseID}, '/course/{courseID}', 'GET', {}, {}).then(function (result) {
                            let tempIndex = result.data.sharedWith.findIndex(x => x.id === thats.props.auth.user.userName);
                            if ((tempIndex === -1 || result.data.sharedWith[tempIndex].permission !== 'WRITE') &&
                                ((thats.props.auth.user.userType.toLowerCase() !== 'admin' && thats.props.auth.user.schoolName !== 'LearnerPal') ||
                                (thats.props.auth.user.userType.toLowerCase() !== 'admin' && thats.props.auth.user.schoolName === 'LearnerPal'))) {
                                thats.backToManageCourses('Can not save, your "WRITE" access has been revoked', 'danger');
                            }
                            else {
                                if (result.data.lockStatus && result.data.lockedBy !== thats.props.auth.user.userName) {

                                    thats.backToManageCourses('Can not save, that course is locked by owner', 'danger');
                                }
                                else if (result.data.lockStatus && result.data.lockedBy === thats.props.auth.user.userName) {
                                    let message = '';
                                    apiClient.invokeApi({}, '/course', 'PUT', {}, body1).then(function (result) {
                                        ClevertapReact.event("Update Course", {'userName' : that.props.auth.user.userName});
                                        message = result.data;
                                        let body3 = {
                                            lockStatus: false,
                                            ownerName: thats.props.auth.user.userName,
                                            courseID: thats.props.manageCourses.courseObj.courseID
                                        };
                                        apiClient.invokeApi({}, '/course/lock', 'PUT', {}, body3, "2").then(function (result) {
                                            if (thats.state.assignCourse) {
                                                setTimeout(() => {
                                                    apiClient.invokeApi({courseID: thats.props.manageCourses.courseObj.courseID}, '/course/{courseID}', 'GET', {}, {}).then(function (result) {

                                                        browserHistory.push({
                                                            pathname: '/assignments/assign',
                                                            state: {
                                                                isCourseAssignment: true,
                                                                course: result.data,
                                                                isCourseLoaded: true,
                                                                view: 'course'
                                                            }
                                                        });
                                                    }).catch(function (error) {

                                                    });
                                                }, 1000);
                                            }
                                            else {
                                                thats.emptyState();
                                                thats.backToManageCourses(message, 'success');
                                            }
                                        }).catch(function (result) {

                                        });

                                    }).catch(function (result) {
                                        thats.backToManageCourses(result.data, 'danger');
                                    });
                                }
                                else {
                                    apiClient.invokeApi({}, '/course', 'PUT', {}, body1).then(function (result) {
                                        ClevertapReact.event("Update Course", {'userName' : that.props.auth.user.userName});
                                        let body3 = {
                                            lockStatus: false,
                                            ownerName: thats.props.auth.user.userName,
                                            courseID: thats.props.manageCourses.courseObj.courseID
                                        };
                                        apiClient.invokeApi({}, '/course/lock', 'PUT', {}, body3, "2").then(function (result) {

                                        }).catch(function (result) {

                                        });
                                        if (thats.state.assignCourse) {
                                            setTimeout(() => {
                                                apiClient.invokeApi({courseID: thats.props.manageCourses.courseObj.courseID}, '/course/{courseID}', 'GET', {}, {}).then(function (result) {

                                                    browserHistory.push({
                                                        pathname: '/assignments/assign',
                                                        state: {
                                                            isCourseAssignment: true,
                                                            course: result.data,
                                                            isCourseLoaded: true,
                                                            view: 'course'
                                                        }
                                                    });
                                                }).catch(function (error) {

                                                });
                                            }, 1000);
                                        }
                                        else {
                                            thats.emptyState();
                                            thats.backToManageCourses(result.data, 'success');
                                        }
                                    }).catch(function (result) {
                                        thats.backToManageCourses(result.data, 'danger');
                                    });
                                }
                            }

                        });
                    }

                }
                else {
                    let body = {
                        ownerName: that.props.auth.user.userName,
                        courseName: tempState.courseName,
                        courseState: tempState.courseState,
                        studentPractice: tempState.studentCourse,
                        units: allUnits,
                        description: tempState.courseDesc,
                        subjectName: tempState.selectedSubject,
                        department: that.props.auth.user.department,
                        schoolName: that.props.auth.user.schoolName,
                    };
                    apiClient.invokeApi({}, '/course', 'POST', {}, body).then(function (result) {
                        ClevertapReact.event("Create Course", {'userName' : that.props.auth.user.userName});
                        that.emptyState();
                        if (that.state.assignCourse) {

                            setTimeout(() => {
                                let courseId = tempState.courseName.replace(/\s|\W|[#$%^&*()'.]/g, "") + '_' + that.props.auth.user.userName;
                                apiClient.invokeApi({courseID: courseId.toLowerCase()}, '/course/{courseID}', 'GET', {}, {}).then(function (result) {

                                    browserHistory.push({
                                        pathname: '/assignments/assign',
                                        state: {
                                            isCourseAssignment: true,
                                            course: result.data,
                                            isCourseLoaded: true,
                                            view: 'course'
                                        }
                                    });
                                }).catch(function (error) {

                                });
                            }, 1000);
                        }
                        else {
                            that.backToManageCourses(result.data, 'success');
                        }
                    }).catch(function (result) {
                        that.emptyState();
                        that.backToManageCourses(result.data, 'danger');
                    });
                }
            }
            else
            {
                this.isNotDuplicatedName(function (response) {
                    if (!response)
                    {

                        that.showIndicator();
                        if (that.props.manageCourses.isEditCourse) {

                            let body1 = {
                                ownerName: that.props.auth.user.userName,
                                courseID: tempState.courseID,
                                courseName: tempState.courseName,
                                courseState: tempState.courseState,
                                studentPractice: tempState.studentCourse,
                                units: allUnits,
                                description: tempState.courseDesc,
                                subjectName: tempState.selectedSubject,
                                department: that.props.auth.user.department,
                                schoolName: that.props.auth.user.schoolName,
                                assignTo: that.props.manageCourses.editCourseObj.assignTo,
                                sharedWith: that.props.manageCourses.editCourseObj.sharedWith,
                            };
                            if (that.props.manageCourses.editCourseObj.ownerName === that.props.auth.user.userName) {
                                let thats = that;
                                apiClient.invokeApi({}, '/course', 'PUT', {}, body1).then(function (result2) {
                                    ClevertapReact.event("Update Course", {'userName' : that.props.auth.user.userName});
                                    let body2 = {
                                        lockStatus: false,
                                        ownerName: thats.props.auth.user.userName,
                                        courseID: thats.props.manageCourses.courseObj.courseID
                                    };
                                    apiClient.invokeApi({}, '/course/lock', 'PUT', {}, body2, "2").then(function (result) {
                                        // that.emptyState();
                                        // that.backToManageCourses(result2.data, 'success');
                                    }).catch(function (result) {

                                    });

                                    if (thats.state.assignCourse) {
                                        setTimeout(() => {
                                            apiClient.invokeApi({courseID: thats.props.manageCourses.courseObj.courseID}, '/course/{courseID}', 'GET', {}, {}).then(function (result) {

                                                browserHistory.push({
                                                    pathname: '/assignments/assign',
                                                    state: {
                                                        isCourseAssignment: true,
                                                        course: result.data,
                                                        isCourseLoaded: true,
                                                        view: 'course'
                                                    }
                                                });
                                            }).catch(function (error) {

                                            });
                                        }, 1000);
                                    }
                                    else {
                                        thats.emptyState();
                                        thats.backToManageCourses(result2.data, 'success');
                                    }

                                }).catch(function (result) {

                                    thats.backToManageCourses(result.data, 'danger');
                                });
                            }
                            else {


                                let thats = that;
                                apiClient.invokeApi({courseID: tempState.courseID}, '/course/{courseID}', 'GET', {}, {}).then(function (result) {
                                    let tempIndex = result.data.sharedWith.findIndex(x => x.id === thats.props.auth.user.userName);
                                    if ((tempIndex === -1 || result.data.sharedWith[tempIndex].permission !== 'WRITE') &&
                                        ((thats.props.auth.user.userType.toLowerCase() !== 'admin' && thats.props.auth.user.schoolName !== 'LearnerPal') ||
                                        (thats.props.auth.user.userType.toLowerCase() !== 'admin' && thats.props.auth.user.schoolName === 'LearnerPal'))) {
                                        thats.backToManageCourses('Can not save, your "WRITE" access has been revoked', 'danger');
                                    }
                                    else {
                                        if (result.data.lockStatus && result.data.lockedBy !== thats.props.auth.user.userName) {

                                            thats.backToManageCourses('Can not save, that course is locked by owner', 'danger');
                                        }
                                        else if (result.data.lockStatus && result.data.lockedBy === thats.props.auth.user.userName) {
                                            let message = '';
                                            apiClient.invokeApi({}, '/course', 'PUT', {}, body1).then(function (result) {
                                                ClevertapReact.event("Update Course", {'userName' : that.props.auth.user.userName});
                                                message = result.data;
                                                let body3 = {
                                                    lockStatus: false,
                                                    ownerName: thats.props.auth.user.userName,
                                                    courseID: thats.props.manageCourses.courseObj.courseID
                                                };
                                                apiClient.invokeApi({}, '/course/lock', 'PUT', {}, body3, "2").then(function (result) {
                                                    if (thats.state.assignCourse) {
                                                        setTimeout(() => {
                                                            apiClient.invokeApi({courseID: thats.props.manageCourses.courseObj.courseID}, '/course/{courseID}', 'GET', {}, {}).then(function (result) {

                                                                browserHistory.push({
                                                                    pathname: '/assignments/assign',
                                                                    state: {
                                                                        isCourseAssignment: true,
                                                                        course: result.data,
                                                                        isCourseLoaded: true,
                                                                        view: 'course'
                                                                    }
                                                                });
                                                            }).catch(function (error) {

                                                            });
                                                        }, 1000);
                                                    }
                                                    else {
                                                        thats.emptyState();
                                                        thats.backToManageCourses(message, 'success');
                                                    }
                                                }).catch(function (result) {

                                                });

                                            }).catch(function (result) {
                                                thats.backToManageCourses(result.data, 'danger');
                                            });
                                        }
                                        else {
                                            apiClient.invokeApi({}, '/course', 'PUT', {}, body1).then(function (result) {
                                                ClevertapReact.event("Update Course", {'userName' : that.props.auth.user.userName});
                                                let body3 = {
                                                    lockStatus: false,
                                                    ownerName: thats.props.auth.user.userName,
                                                    courseID: thats.props.manageCourses.courseObj.courseID
                                                };
                                                apiClient.invokeApi({}, '/course/lock', 'PUT', {}, body3, "2").then(function (result) {

                                                }).catch(function (result) {

                                                });
                                                if (thats.state.assignCourse) {
                                                    setTimeout(() => {
                                                        apiClient.invokeApi({courseID: thats.props.manageCourses.courseObj.courseID}, '/course/{courseID}', 'GET', {}, {}).then(function (result) {

                                                            browserHistory.push({
                                                                pathname: '/assignments/assign',
                                                                state: {
                                                                    isCourseAssignment: true,
                                                                    course: result.data,
                                                                    isCourseLoaded: true,
                                                                    view: 'course'
                                                                }
                                                            });
                                                        }).catch(function (error) {

                                                        });
                                                    }, 1000);
                                                }
                                                else {
                                                    thats.emptyState();
                                                    thats.backToManageCourses(result.data, 'success');
                                                }
                                            }).catch(function (result) {
                                                thats.backToManageCourses(result.data, 'danger');
                                            });
                                        }
                                    }

                                });
                            }

                        }
                        else {
                            let body = {
                                ownerName: that.props.auth.user.userName,
                                courseName: tempState.courseName,
                                courseState: tempState.courseState,
                                studentPractice: tempState.studentCourse,
                                units: allUnits,
                                description: tempState.courseDesc,
                                subjectName: tempState.selectedSubject,
                                department: that.props.auth.user.department,
                                schoolName: that.props.auth.user.schoolName,
                            };
                            apiClient.invokeApi({}, '/course', 'POST', {}, body).then(function (result) {
                                that.emptyState();
                                ClevertapReact.event("Create Course", {'userName' : that.props.auth.user.userName});
                                if (that.state.assignCourse) {

                                    setTimeout(() => {
                                        let courseId = tempState.courseName.replace(/\s|\W|[#$%^&*()'.]/g, "") + '_' + that.props.auth.user.userName;
                                        apiClient.invokeApi({courseID: courseId.toLowerCase()}, '/course/{courseID}', 'GET', {}, {}).then(function (result) {

                                            browserHistory.push({
                                                pathname: '/assignments/assign',
                                                state: {
                                                    isCourseAssignment: true,
                                                    course: result.data,
                                                    isCourseLoaded: true,
                                                    view: 'course'
                                                }
                                            });
                                        }).catch(function (error) {

                                        });
                                    }, 1000);
                                }
                                else {
                                    that.backToManageCourses(result.data, 'success');
                                }
                            }).catch(function (result) {
                                that.emptyState();
                                that.backToManageCourses(result.data, 'danger');
                            });
                        }
                    }
                    else
                    {
                        let errors = {};
                        errors.courseName = 'Record already exist';
                        that.setState({errors})
                    }
                });
            }

        }
    }

    emptyState()
    {
        this.setState({courseName : '',
            courseDesc : '',
            allUnits: [],
            selectedSubject : '',
            units : [],
            errors : {} });
    }

    getAllCourses() {
        return this.props.manageCourses.results;
    }

    isValid() {
        let allRecords = this.getAllCourses() ? this.getAllCourses() : [];
        let that = this;

        const {errors, isValid, data} = validateInput(this.props.manageCourses.courseObj, allRecords, this.props.auth.user.userName, this.props.manageCourses.isEditCourse)
        const {dispatch} = that.props;
        let tempObj = that.props.manageCourses.courseObj;
        for (let i=0; i<data.units.length; i++) {
            tempObj.units[i] = Object.assign({}, data.units[i], {});
        }
        ManageCourseActions.setCreateCourseData(dispatch, tempObj, false, null);
        that.setState({errors});
        return isValid;
    }

    addCustomUnitInfo(e)
    {


        this.updateItem(e.target.id, {'unitValue': e.target.value, 'customUnitValue': e.target.value, unitID:undefined});
    }

    addUnitInfo(e)
    {

        const browser = detect();

        let index = this.state.allUnits.findIndex(x=> x.name.toLowerCase() === e.target.value.toLowerCase());
        if (browser.name === 'safari') {
            index = this.state.allUnits.findIndex(x=> x.id.toLowerCase() === e.target.value.toLowerCase());
        }
        if (index === -1)
        {
            if (e.target.value === "custom_unit") {
                this.updateItem(e.target.id, {[e.target.name]: e.target.value, unitID:undefined, hideDropDown: true});
            } else {
                this.updateItem(e.target.id, {[e.target.name]: e.target.value, unitID:undefined});
            }

        }
        else
        {
            let temp = this.state.allUnits[index];
            if (e.target.value === "custom_unit") {
                this.updateItem(e.target.id, {[e.target.name]: e.target.value, unitID:temp.id, unitDesc:temp.description, hideDropDown: true});
            } else {
                this.updateItem(e.target.id, {[e.target.name]: e.target.value, unitID:temp.id, unitDesc:temp.description});
            }

        }
    }

    updateItem(id, itemAttributes) {

        let index = this.props.manageCourses.courseObj.units.findIndex(x=> x.ID === id);
        if (index === -1)
        {
            // handle error
        }
        else
        {
            const {dispatch} = this.props;
            let tempObj = this.props.manageCourses.courseObj;
            tempObj.units = [
                ...this.props.manageCourses.courseObj.units.slice(0,index),
                Object.assign({}, this.props.manageCourses.courseObj.units[index], itemAttributes),
                ...this.props.manageCourses.courseObj.units.slice(index+1)
            ];

            ManageCourseActions.setCreateCourseData(dispatch, tempObj, false, null);
        }
    }

    onSortingChange(newList = []) {

        this.setState({units: newList});

        let courseObj = this.props.manageCourses.courseObj;
        courseObj.units = newList;
        const {dispatch} = this.props;
        ManageCourseActions.setCreateCourseData(dispatch, courseObj, false, null);
    }
    onSortingChangeActivity(newList = [], ID)
    {

        let courseObj = this.props.manageCourses.courseObj;

        let unitIndex = courseObj.units.findIndex(x=> x.ID === ID);
        if (unitIndex !== -1)
        {
            courseObj.units[unitIndex].activities = newList;
            this.setState({units:courseObj.units});
            const {dispatch} = this.props;
            ManageCourseActions.setCreateCourseData(dispatch, courseObj, false, null);
        }
    }
    render() {
        const {errors} = this.state;
        let selectedSubject = this.props.manageCourses.courseObj.selectedSubject;
        let studentCourses = [
            {
                "id": "Yes",
                "name": "Practice Course for Student - Yes"
            },
            {
                "id": "No",
                "name": "Practice Course for Student - No"
            }
        ];
        let courseStates = [
            {
                "id": "In Progress",
                "name": "In Progress"
            },
            {
                "id": "In Review",
                "name": "In Review"
            }
        ];
        if (this.props.auth.user.userType.toLowerCase() === "admin") {
            courseStates.push({
                "id": "Published",
                "name": "Published"
            })
        }
        let addUnitButtonText = null;
        if (this.props.manageCourses.courseObj.units && this.props.manageCourses.courseObj.units.length===0)
        {
            addUnitButtonText = 'Add Unit';
        }
        else
        {
            addUnitButtonText = 'Add Another Unit';
        }
        let createCourseBC = "Create Course";
        if (this.props.manageCourses.isEditCourse) {
            createCourseBC = "Edit Course";
        }


        return(
            <div ref="createCourse">
                <Breadcrumb
                    breadcrumbsData={
                        [
                            {
                                name: 'Manage Courses', action: ()=> {
                                browserHistory.push('/courses');
                            }
                            },
                            {
                                name: createCourseBC, action: ()=> {
                            }
                            }
                        ]
                    }
                />
                <div className="container-fluid grey-background CourseContainer">
                    <div className="CourseView editPage">
                        {this.state.editCourseName &&
                        <div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="posRel fieldBg headingMainField">
                                        <TextFieldGroup
                                            type={'text'}
                                            label={''}
                                            isDisabled={this.props.manageCourses.isEditCourse}
                                            field="courseName"
                                            error={errors.courseName}
                                            value={this.props.manageCourses.courseObj.courseName}
                                            placeholder="Please Enter Course Name"
                                            onChange={this.onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                  <textarea
                                      className="form-control editTextArea textEditTop"
                                      rows="2"
                                      name="courseDesc"
                                      onChange={this.onChange}
                                      placeholder="Please Enter Course Description"
                                      value = {this.props.manageCourses.courseObj.courseDesc}
                                  />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-sm-6 col-xs-6">
                                    <div className="posRel fieldBg selectWidth col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <SelectFieldGroup
                                            error={errors.selectedSubject}
                                            field="selectedSubject"
                                            value={selectedSubject}
                                            options={this.state.allSubjects}
                                            placeholder="Associate with Subject"
                                            onChange={this.onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                        {!this.state.editCourseName &&
                        <div>
                            <div className="row">
                                <div className="col-md-4 col-sm-4 col-xs-11">
                                    <h1 style={{color: '#0f6fb4'}}>{this.props.manageCourses.courseObj.courseName}</h1>
                                </div>
                                <div className="col-md-1 col-sm-1 col-xs-1">
                                  <span className="edtBtn  hidden-xs pull-right" onClick={()=> {
                                      this.setState({editCourseName: true})
                                  }}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-sm-6 col-xs-6">
                                    <h4 style={{color: '#0f6fb4'}}>{selectedSubject}</h4>
                                    <a id="viewdesc" onClick={()=> {
                                        $("#desc").removeClass('removeDisplay');
                                        $("#viewdesc").addClass('removeDisplay');
                                    }}>View Description</a>
                                    <p id="desc" className="removeDisplay">{this.props.manageCourses.courseObj.courseDesc}</p>
                                </div>
                            </div>
                        </div>
                        }
                        <div className="row">
                            <div className="col-md-6 col-sm-6 col-xs-6">
                                <div className="posRel fieldBg selectWidth col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    {this.props.auth.user.schoolName === "LearnerPal" &&
                                    <SelectFieldGroup
                                        error={errors.courseState}
                                        field="courseState"
                                        value={this.props.manageCourses.courseObj.courseState}
                                        options={courseStates}
                                        placeholder="Select Course State"
                                        onChange={this.onChange}
                                    />
                                    }
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 col-xs-6">
                                <div className="posRel fieldBg selectWidth col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    {this.props.auth.user.schoolName === "LearnerPal" &&
                                    this.props.auth.user.userType.toLowerCase() === "admin" &&
                                    <SelectFieldGroup
                                        error={errors.studentCourse}
                                        field="studentCourse"
                                        value={this.props.manageCourses.courseObj.studentCourse}
                                        options={studentCourses}
                                        placeholder="Practice Course for Student?"
                                        onChange={this.onChange}
                                    />}
                                </div>
                            </div>
                        </div>
                        <ul id="sortable">
                            <DraggableList
                                itemKey="name"
                                template={Unit}
                                list={this.props.manageCourses.courseObj.units}
                                onMoveEnd={newList => this.onSortingChange(newList)}
                            />
                        </ul>
                        <div className="row">
                            <div className="col-md-12">
                                <button className="addUnitBtn" onClick={this.addUnit}>{addUnitButtonText}</button>
                            </div>
                        </div>
                        <div className="row text-right">
                            <Button onClick={this.cancelCourse}
                                    bsStyle="cancelBtn">
                                Cancel
                            </Button>
                            <Button onClick={this.setTrueForLeave}
                                    bsStyle='saveBtn'>
                                Save &amp; Exit
                            </Button>
                            <Button onClick={()=>{
                                this.setState({assignCourse:true }, this.setTrueForLeave);
                            }}
                                    bsStyle='saveBtn'>
                                Save &amp; Assign
                            </Button>
                        </div>
                        <ProgressIndicator show={this.state.loading}/>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {...state};
}

export default connect(mapStateToProps)(withRouter(CreateCourse));
