/**
 * Created by ashahab on 11/7/2017.
 */
import React from "react";
import { connect } from "react-redux";
import LoginUtils from "../../common/login-utils";
import { browserHistory } from "react-router";
import ElasticSearchActions from "../../../actions/elastic-search-actions";
import ElasticSearchQuery from "../../../actions/elastic-search-query";
import {
    RECEIVED_LP_ADMIN_REPORT_RESULT,
    ElasticSearchTypes,
    ElasticSearchColumns
} from "../../../actions/types";
import config from "../../../config";
import $ from "jquery";
import Heading from "../../heading/heading";
import Filter from "../../../components/filter/filter";
import MaintenanceTable from "../../../components/maintenance-table/maintenance-table";
import SelectFieldGroup from "../../forms/select-field-group";
import Utility from "../../maintenance-table/utility";
import excelIcon from "../microsoft-excel.png";
import excelIconHover from "../microsoft-excebl.png";
import { ActionIcons } from "../../actionIcons/action-Icons";
import { ActionIconTypes } from "../../../actions/types";
import "./lp-admin-report.css";
import CustomDatePicker from "../../datePicker/date-picker"
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { Button } from "react-bootstrap";
import XLSX from 'xlsx';
import ProgressIndicator from "../../../../src/components/progress-indicator/progress-indicator";


class LpAdminReport extends React.Component {
    constructor(props) {
        super(props);

        this.handleAlertDismiss = this.handleAlertDismiss.bind(this);
        this.onChange = this.onChange.bind(this);
        this.showIndicator = this.showIndicator.bind(this);
        this.hideIndicator = this.hideIndicator.bind(this);
        this.printTable = this.printTable.bind(this);
        this.exportToExcel = this.exportToExcel.bind(this);

        this.state = {
            screenWidth: window.innerWidth, alertText: "This is a sample alert", alertStyle: "danger", showAlert: false, loadingNextPage: 0,
            selectedRow: {}, selectedStream: 'teachers', isPageable: true, loading: false
        };

        this.filterComponentCssClassName = 'filterBlock NiceScroll';
        this.tableCssClassName = 'listViewBlock ';
    }

    componentDidMount() {
        LoginUtils.isAuthenticated(this.props, browserHistory, '/reports/lpAdmin', '/');
        const { dispatch } = this.props;
        ElasticSearchActions.setElasticSearchAttributes(dispatch, 'lpAdminReport', '', 200, 1, { creationTime: { order: "desc" } }, [], null);
        let elasticSearchQuery = new ElasticSearchQuery();
        elasticSearchQuery.query(this.state.selectedStream === 'teachers' ? ElasticSearchTypes.LpAdminReportForTeachers + config.stage : ElasticSearchTypes.LpAdminReportForStudents + config.stage, this.state.selectedStream === 'teachers' ? ElasticSearchColumns.LpAdminReportForTeachers : ElasticSearchColumns.LpAdminReportForStudents, this.props,
            '', 200, 1, { creationTime: { order: "desc" } }, [], function (results, totalRecords) {
                dispatch({
                    type: RECEIVED_LP_ADMIN_REPORT_RESULT,
                    status: 200,
                    data: JSON.parse(JSON.stringify(results)),
                    alertText: '',
                    alertStyle: '',
                    showAlert: false,
                    totalRecords: totalRecords
                });
            }, false);
        $('#pageSizeDropDown').val(200);
    }

    handleAlertDismiss() {
        this.setState({ 'showAlert': false });
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }
    handleWindowSizeChange = () => {
        this.setState({ screenWidth: window.innerWidth });
    };

    showIndicator() {
        this.setState({
            ...this.state,
            loading: true
        });
    }

    hideIndicator() {
        this.setState({
            ...this.state,
            loading: false
        });
    }

    exportToExcel(tableID, removeLastHeader) {
        let context = this;
        context.showIndicator();
        const { dispatch } = this.props;
        let elasticSearchQuery = new ElasticSearchQuery();
        let esAttr = context.props.elasticSearchAttributes.map.get('lpAdminReport');

        context.setState({ isPageable: false });
        elasticSearchQuery.queryPagination(context.state.selectedStream === 'teachers' ? ElasticSearchTypes.LpAdminReportForTeachers + config.stage : ElasticSearchTypes.LpAdminReportForStudents + config.stage, context.state.selectedStream === 'teachers' ? ElasticSearchColumns.LpAdminReportForTeachers : ElasticSearchColumns.LpAdminReportForStudents, context.props,
            esAttr.searchTerm, context.props.lpAdminReport.totalRecords, 1, esAttr.sortObj, esAttr.filterValues, function (results, totalRecords) {
                for (var i = 0, len = results.length; i < len; i++) {
                    delete results[i].type;
                    delete results[i].id;
                    delete results[i].keywords;
                    delete results[i].visibleTo;
                    delete results[i].changeType;
                    delete results[i].value;
                    delete results[i].createdTrial;
                    results[i].creationTime = new Date(parseFloat(results[i].creationTime)).toLocaleString();
                    results[i].Username = results[i].userName;
                    delete results[i].userName;
                    results[i].TeacherName = results[i].teacherName;
                    delete results[i].teacherName;
                    if (results[i].schoolName) {
                        results[i].SchoolName = results[i].schoolName;
                        delete results[i].schoolName;
                    } else if (results[i].schoolName2) {
                        results[i].SchoolName = results[i].schoolName2;
                        delete results[i].schoolName2;
                    } else if (results[i].schoolName3) {
                        results[i].SchoolName = results[i].schoolName3;
                        delete results[i].schoolName3;
                    }
                    if (results[i].activityType) {
                        results[i].Activity = results[i].activityType;
                        delete results[i].activityType;
                    }
                    if (results[i].subjects) {
                        results[i].Subjects = results[i].subjects.toString();
                        delete results[i].subjects;
                    }
                    results[i].TimeStamp = results[i].creationTime;
                    delete results[i].creationTime;
                }
                const newWB = XLSX.utils.book_new();
                const newWS = XLSX.utils.json_to_sheet(results);
                XLSX.utils.book_append_sheet(newWB, newWS, "Sheet1");
                XLSX.writeFile(newWB, "Lp admin report.xlsx");
                context.hideIndicator();
                context.setState({ isPageable: true });
            }, false, '', 'learnerpal/', esAttr.dateRange);
    }

    printTable(tableID, removeLastHeader) {
        //PRINT TABLE

        let table_div = document.getElementById(tableID) ? document.getElementById(tableID).cloneNode(true) : null;
        if (table_div) {
            if (removeLastHeader) {
                table_div.firstElementChild.firstElementChild.firstElementChild.removeChild(table_div.firstElementChild.firstElementChild.firstElementChild.lastChild);
            }
            for (let i = 0; i < table_div.firstElementChild.lastChild.children.length; i++) {
                for (let j = 0; j < table_div.firstElementChild.lastChild.children[i].children.length; j++) {
                    if (table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild &&
                        table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild.firstElementChild) {
                        table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild.removeChild(table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild.firstElementChild);
                    }
                }
            }
            let myWindow = window.open('', 'PRINT', 'height=600,width=800');
            myWindow.document.write('<html><head><title>' + 'Learner Pal' + '</title>');
            myWindow.document.write('<h1>' + 'Lp Admin Report' + '</h1>');
            myWindow.document.write(table_div.outerHTML);
            myWindow.document.write('</body></html>');
            myWindow.focus(); // necessary for IE >= 10*/
            setTimeout(function () {
                myWindow.print();
                myWindow.document.close(); // necessary for IE >= 10
                myWindow.close();
            }, 1000);
        }
        else {
            this.setState({
                showAlert: true,
                alertStyle: 'danger',
                alertText: 'Content not available, can not print. Please contact administrator.'
            });
            setTimeout(() => {
                this.setState({
                    showAlert: false,
                    alertStyle: '',
                    alertText: ''
                })
            }, 5000);
        }
    }

    render() {
        let context = this;
        let esAttr = this.props.elasticSearchAttributes.map.get('lpAdminReport');
        let maintenanceTableData = {};
        if (this.state.selectedStream === 'teachers') {
            maintenanceTableData = {
                emptyMessage: 'No records found.',
                showActionMenu: false,
                actions: [],
                columns: [
                    {
                        name: 'userName', type: 'text', label: 'Username', options: {
                            sortable: true, customDom: (record) => { // customDom is not applicable to array type attributes, ex: subjects in activity.
                                return (
                                    <td width="10%">
                                        {record.userName}
                                    </td>
                                );
                            }
                        }
                    },
                    { name: 'teacherName', type: 'text', label: 'Teacher Name', options: { sortable: true, width: '10%' } },
                    { name: 'schoolName3', type: 'text', label: 'School Name', options: { sortable: true, width: '10%' } },
                    {
                        name: 'value', type: 'text', label: 'Activity', options: {
                            sortable: false, customDom: (record) => { // customDom is not applicable to array type attributes, ex: subjects in activity.
                                let value = record.value;
                                if (value.startsWith('You have')) {
                                    value = value.replace(/You have /, '')
                                }
                                else if (value.startsWith('You')) {
                                    value = value.replace(/You /, '')
                                }
                                return (
                                    <td width="10%">
                                        {Utility.ucFirst(value)}
                                    </td>
                                );
                            }
                        }
                    },
                    { name: 'subjects', type: 'array', label: 'Subjects', options: { sortable: true, width: '10%' } },
                    {
                        name: 'creationTime', type: 'text', label: 'Time Stamp', options: {
                            sortable: true, customDom: (record) => { // customDom is not applicable to array type attributes, ex: subjects in activity.
                                return (
                                    <td width="10%">
                                        {record.creationTime ? new Date(parseFloat(record.creationTime)).toLocaleString() : 'N/A'}
                                    </td>
                                );
                            }
                        }
                    }
                ],
                items: this.props.lpAdminReport.results,
                pageable: this.state.isPageable,
                totalRecords: this.props.lpAdminReport.totalRecords,
                pagination: {
                    pageSize: esAttr && esAttr.pageSize ? esAttr.pageSize : 200,
                    activePage: 1,
                    showPageInput: true,
                    showFirstButton: true,
                    showLastButton: true,
                    showNextButton: true,
                    showPreviousButton: true,
                    firstButtonLabel: '<<',
                    lastButtonLabel: '>>',
                    previousButtonLabel: '<',
                    nextButtonLabel: '>',
                    showPageInfo: true,
                    showGotoInput: false,
                    gotoInputLabel: 'Goto',
                    pageLinkSize: 5
                }
            };
        }
        else {
            maintenanceTableData = {
                emptyMessage: 'No records found.',
                showActionMenu: false,
                actions: [],
                columns: [
                    {
                        name: 'userName', type: 'text', label: 'Username', options: {
                            sortable: true, customDom: (record) => { // customDom is not applicable to array type attributes, ex: subjects in activity.
                                return (
                                    <td width="10%">
                                        {record.userName}
                                    </td>
                                );
                            }
                        }
                    },
                    { name: 'schoolName3', type: 'text', label: 'School Name', options: { sortable: true, width: '10%' } },
                    { name: 'teacherName', type: 'text', label: 'Teacher Name', options: { sortable: true, width: '10%' } },
                    {
                        name: 'value', type: 'text', label: 'Activity', options: {
                            sortable: false, customDom: (record) => { // customDom is not applicable to array type attributes, ex: subjects in activity.
                                let value = record.value;
                                if (value.startsWith('You have')) {
                                    value = value.replace(/You have /, '')
                                }
                                else if (value.startsWith('You')) {
                                    value = value.replace(/You /, '')
                                }
                                return (
                                    <td width="10%">
                                        {Utility.ucFirst(value)}
                                    </td>
                                );
                            }
                        }
                    },
                    { name: 'subjects', type: 'array', label: 'Subjects', options: { sortable: true, width: '10%' } },
                    {
                        name: 'creationTime', type: 'text', label: 'Time Stamp', options: {
                            sortable: true, customDom: (record) => { // customDom is not applicable to array type attributes, ex: subjects in activity.

                                return (
                                    <td width="10%">
                                        {record.creationTime ? new Date(parseFloat(record.creationTime)).toLocaleString() : 'N/A'}
                                    </td>
                                );
                            }
                        }
                    },
                ],
                items: this.props.lpAdminReport.results,
                pageable: this.state.isPageable,
                totalRecords: this.props.lpAdminReport.totalRecords,
                pagination: {
                    pageSize: esAttr && esAttr.pageSize ? esAttr.pageSize : 200,
                    activePage: 1,
                    showPageInput: true,
                    showFirstButton: true,
                    showLastButton: true,
                    showNextButton: true,
                    showPreviousButton: true,
                    firstButtonLabel: '<<',
                    lastButtonLabel: '>>',
                    previousButtonLabel: '<',
                    nextButtonLabel: '>',
                    showPageInfo: true,
                    showGotoInput: false,
                    gotoInputLabel: 'Goto',
                    pageLinkSize: 5
                }
            };
        }
        let startDate = this.state.startDate ? moment(new Date(this.state.startDate)) : null;
        let endDate = this.state.endDate ? moment(new Date(this.state.endDate)) : null;
        return (<div>
            {
                this.state.selectedStream === 'teachers' &&
                <Heading
                    isHideHeading={false}
                    allButtons={[]}
                    isCreateButton={false}
                    heading="LP Admin Report"
                    isShowButton={true}
                    isSearch={true}
                    actionName={RECEIVED_LP_ADMIN_REPORT_RESULT}
                    screenNameForElasticSearch={ElasticSearchTypes.LpAdminReportForTeachers}
                    allColumnsForElasticSearch={ElasticSearchColumns.LpAdminReportForTeachers}
                    location="lpAdminReport"
                />
            }
            {
                this.state.selectedStream === 'student' &&
                <Heading
                    isHideHeading={false}
                    allButtons={[]}
                    isCreateButton={false}
                    heading="LP Admin Report"
                    isShowButton={true}
                    isSearch={true}
                    actionName={RECEIVED_LP_ADMIN_REPORT_RESULT}
                    screenNameForElasticSearch={ElasticSearchTypes.LpAdminReportForStudents}
                    allColumnsForElasticSearch={ElasticSearchColumns.LpAdminReportForStudents}
                    location="lpAdminReport"
                />
            }
            <div id="tableFiltersAndSideView">
                <div className="container-fluid noPad listMainBoxWrapper">
                    {
                        this.state.selectedStream === 'teachers' &&
                        <Filter
                            actionName={RECEIVED_LP_ADMIN_REPORT_RESULT}
                            viewActivityCssClass={this.filterComponentCssClassName}
                            clearFilters={'lpAdminReport.clearFilters'}
                            filterColumns={[
                                { name: 'activityType', type: 'text', label: 'Activity Type' },
                                { name: 'schoolName3', type: 'text', label: 'School Name' },
                                { name: 'createdTrial', type: 'text', label: 'Created Trial' }
                            ]}
                            screenNameForElasticSearch={ElasticSearchTypes.LpAdminReportForTeachers}
                            allColumnsForElasticSearch={ElasticSearchColumns.LpAdminReportForTeachers}
                            location="lpAdminReport"
                            isQuery={false}
                        />
                    }
                    {
                        this.state.selectedStream === 'student' &&
                        <Filter
                            actionName={RECEIVED_LP_ADMIN_REPORT_RESULT}
                            viewActivityCssClass={this.filterComponentCssClassName}
                            clearFilters={'lpAdminReport.clearFilters'}
                            filterColumns={[
                                { name: 'activityType', type: 'text', label: 'Activity Type' },
                                { name: 'schoolName3', type: 'text', label: 'School Name' },
                                { name: 'createdTrial', type: 'text', label: 'Created Trial' }
                            ]}
                            screenNameForElasticSearch={ElasticSearchTypes.LpAdminReportForStudents}
                            allColumnsForElasticSearch={ElasticSearchColumns.LpAdminReportForStudents}
                            location="lpAdminReport"
                            isQuery={false}
                        />
                    }
                    <div id="lp-admin-report" className={this.tableCssClassName}>
                        <div className="listToBesslide NiceScroll">
                            <div className="blockForlist ">
                                <div className="col-md-12" style={{ marginTop: '10px' }}>
                                    <div className="float-lft">
                                        <SelectFieldGroup
                                            isDisabled={false}
                                            field="dates"
                                            value={this.state.selectedStream}
                                            options={[{ id: 'teachers', name: 'Teachers' }, { id: 'student', name: 'Students' }]}
                                            onChange={(event) => {
                                                let value = event.target.value;
                                                this.setState({ selectedStream: value }, function () {
                                                    const { dispatch } = this.props;
                                                    ElasticSearchActions.setElasticSearchAttributes(dispatch, 'lpAdminReport', '', 200, 1, { creationTime: { order: "desc" } }, [], null);
                                                    let elasticSearchQuery = new ElasticSearchQuery();
                                                    elasticSearchQuery.query(this.state.selectedStream === 'teachers' ? ElasticSearchTypes.LpAdminReportForTeachers + config.stage : ElasticSearchTypes.LpAdminReportForStudents + config.stage, this.state.selectedStream === 'teachers' ? ElasticSearchColumns.LpAdminReportForTeachers : ElasticSearchColumns.LpAdminReportForStudents, this.props,
                                                        '', 200, 1, { creationTime: { order: "desc" } }, [], function (results, totalRecords) {
                                                            dispatch({
                                                                type: RECEIVED_LP_ADMIN_REPORT_RESULT,
                                                                status: 200,
                                                                data: JSON.parse(JSON.stringify(results)),
                                                                alertText: '',
                                                                alertStyle: '',
                                                                showAlert: false,
                                                                totalRecords: totalRecords
                                                            });
                                                        }, false);
                                                });
                                            }}
                                        />
                                    </div>
                                    <div className="float-lft" style={{ marginLeft: '15px' }}>
                                        <SelectFieldGroup
                                            isDisabled={false}
                                            field="dates"
                                            value={this.state.selectedDate}
                                            placeholder="Filter By Day"
                                            options={[{ id: 'between', name: 'Between' }, { id: 'today', name: 'Today' }, { id: 'yesterday', name: 'Yesterday' }, { id: 'week', name: 'This Week' }, { id: 'month', name: 'This Month' }, { id: 'year', name: 'This Year' }]}
                                            onChange={(event) => {
                                                let value = event.target.value;
                                                let dateRange = {};
                                                if (value === 'today') {
                                                    dateRange.startDate = new Date().setHours(0, 0, 0, 0);
                                                    dateRange.endDate = new Date().setHours(23, 59, 59, 0);
                                                }
                                                else if (value === 'yesterday') {
                                                    dateRange.startDate = new Date(Date.now() - 86400000).setHours(0, 0, 0, 0);
                                                    dateRange.endDate = new Date(Date.now() - 86400000).setHours(23, 59, 59, 0);
                                                }
                                                else if (value === 'week') {

                                                    dateRange.startDate = new Date(moment().startOf('isoweek').toDate()).setHours(0, 0, 0, 0);
                                                    dateRange.endDate = new Date(moment().endOf('isoweek').toDate()).setHours(23, 59, 59, 0);
                                                }
                                                else if (value === 'month') {

                                                    dateRange.startDate = new Date(moment().startOf('month').toDate()).setHours(0, 0, 0, 0);
                                                    dateRange.endDate = new Date(moment().endOf('month').toDate()).setHours(23, 59, 59, 0);
                                                }
                                                else if (value === 'year') {

                                                    dateRange.startDate = new Date(moment().startOf('year').toDate()).setHours(0, 0, 0, 0);
                                                    dateRange.endDate = new Date(moment().endOf('year').toDate()).setHours(23, 59, 59, 0);
                                                }
                                                else {
                                                    dateRange = null;
                                                }
                                                this.setState({ selectedDate: value }, function () {
                                                    const { dispatch } = this.props;
                                                    let elasticSearchQuery = new ElasticSearchQuery();
                                                    let esAttr = context.props.elasticSearchAttributes.map.get('lpAdminReport');

                                                    ElasticSearchActions.setElasticSearchAttributes(dispatch, 'lpAdminReport', esAttr.searchTerm, esAttr.pageSize, esAttr.activePage, esAttr.sortObj, esAttr.filterValues, dateRange);
                                                    elasticSearchQuery.query(context.state.selectedStream === 'teachers' ? ElasticSearchTypes.LpAdminReportForTeachers + config.stage : ElasticSearchTypes.LpAdminReportForStudents + config.stage, context.state.selectedStream === 'teachers' ? ElasticSearchColumns.LpAdminReportForTeachers : ElasticSearchColumns.LpAdminReportForStudents, context.props,
                                                        esAttr.searchTerm, esAttr.pageSize, esAttr.activePage, esAttr.sortObj, esAttr.filterValues, function (results, totalRecords) {
                                                            dispatch({
                                                                type: RECEIVED_LP_ADMIN_REPORT_RESULT,
                                                                status: 200,
                                                                data: JSON.parse(JSON.stringify(results)),
                                                                alertText: '',
                                                                alertStyle: '',
                                                                showAlert: false,
                                                                totalRecords: totalRecords
                                                            });
                                                        }, false, '', 'learnerpal/', dateRange);
                                                });
                                            }}
                                        />
                                    </div>
                                    {
                                        this.state.selectedDate === "between" &&
                                        <div className="float-lft" style={{ marginLeft: '15px' }}>
                                            <div className="float-lft">
                                                <DatePicker
                                                    customInput={<CustomDatePicker />}
                                                    placeholderText="From"
                                                    selected={startDate}
                                                    onChange={(evt) => {
                                                        this.setState({ startDate: new Date(evt).setHours(0, 0, 0, 0) });
                                                    }} />
                                            </div>
                                            <div className="float-lft">
                                                <DatePicker
                                                    customInput={<CustomDatePicker />}
                                                    placeholderText="To"
                                                    selected={endDate}
                                                    onChange={(evt) => {
                                                        this.setState({ endDate: new Date(evt).setHours(23, 59, 59, 0) });
                                                    }} />
                                            </div>
                                            {this.state.startDate && this.state.endDate &&
                                                <div className="float-lft" style={{ marginLeft: '15px' }}>
                                                    <Button onClick={() => {
                                                        let dateRange = {};
                                                        dateRange.startDate = this.state.startDate;
                                                        dateRange.endDate = this.state.endDate;
                                                        const { dispatch } = this.props;
                                                        let elasticSearchQuery = new ElasticSearchQuery();
                                                        let esAttr = context.props.elasticSearchAttributes.map.get('lpAdminReport');

                                                        ElasticSearchActions.setElasticSearchAttributes(dispatch, 'lpAdminReport', esAttr.searchTerm, esAttr.pageSize, esAttr.activePage, esAttr.sortObj, esAttr.filterValues, dateRange);
                                                        elasticSearchQuery.query(context.state.selectedStream === 'teachers' ? ElasticSearchTypes.LpAdminReportForTeachers + config.stage : ElasticSearchTypes.LpAdminReportForStudents + config.stage, context.state.selectedStream === 'teachers' ? ElasticSearchColumns.LpAdminReportForTeachers : ElasticSearchColumns.LpAdminReportForStudents, context.props,
                                                            esAttr.searchTerm, esAttr.pageSize, esAttr.activePage, esAttr.sortObj, esAttr.filterValues, function (results, totalRecords) {
                                                                dispatch({
                                                                    type: RECEIVED_LP_ADMIN_REPORT_RESULT,
                                                                    status: 200,
                                                                    data: JSON.parse(JSON.stringify(results)),
                                                                    alertText: '',
                                                                    alertStyle: '',
                                                                    showAlert: false,
                                                                    totalRecords: totalRecords
                                                                });
                                                            }, false, '', 'learnerpal/', dateRange);
                                                    }}>Search</Button>
                                                </div>}
                                        </div>
                                    }
                                    <div className="float-lft" style={{ marginLeft: '15px' }}>
                                        <span onMouseOver={(event) => { event.target.firstElementChild.src = excelIconHover; }} onMouseOut={(event) => { event.target.firstElementChild.src = excelIcon; }}
                                            className="detailPrintForReport" data-toggle="tooltip" title="Export Report To Excel" data-placement="bottom" data-original-title="Print"
                                            onClick={() => {
                                                this.exportToExcel('lpAdminReport', false);
                                            }}>
                                            <img src={excelIcon} style={{ width: '43px', marginTop: '-45px' }} />
                                        </span>
                                        <ActionIcons iconType={ActionIconTypes.Print} action={function () {
                                            context.printTable('lpAdminReport', false);
                                        }} />
                                    </div>
                                </div>
                                {
                                    this.state.selectedStream === 'teachers' &&
                                    <MaintenanceTable
                                        tableId="lpAdminReport"
                                        location="lpAdminReport"
                                        screenNameForElasticSearch={ElasticSearchTypes.LpAdminReportForTeachers}
                                        allColumnsForElasticSearch={ElasticSearchColumns.LpAdminReportForTeachers}
                                        actionName={RECEIVED_LP_ADMIN_REPORT_RESULT}
                                        options={maintenanceTableData}
                                        sortAction={{
                                            action: { RECEIVED_LP_ADMIN_REPORT_RESULT },
                                            attributes: {
                                                skipAlterForAllRecords: true
                                            },
                                            dataPath: 'data'
                                        }}
                                    />
                                }
                                {
                                    this.state.selectedStream === 'student' &&
                                    <MaintenanceTable
                                        tableId="lpAdminReport"
                                        location="lpAdminReport"
                                        screenNameForElasticSearch={ElasticSearchTypes.LpAdminReportForStudents}
                                        allColumnsForElasticSearch={ElasticSearchColumns.LpAdminReportForStudents}
                                        actionName={RECEIVED_LP_ADMIN_REPORT_RESULT}
                                        options={maintenanceTableData}
                                        sortAction={{
                                            action: { RECEIVED_LP_ADMIN_REPORT_RESULT },
                                            attributes: {
                                                skipAlterForAllRecords: true
                                            },
                                            dataPath: 'data'
                                        }}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ProgressIndicator show={this.state.loading} />
        </div>);
    }
}

function mapStateToProps(state) {
    return { ...state };
}

export default connect(mapStateToProps)(LpAdminReport);
