import React, { Component } from "react";
import Heading from "../../heading/heading";
import { browserHistory } from "react-router";
import { connect } from "react-redux";
import { RECEIVED_COURSE_RESULTS, ActionIconTypes, ElasticSearchTypes, ElasticSearchColumns } from "../../../actions/types";
import MaintenanceTable from "../../../components/maintenance-table/maintenance-table";
import Filter from "../../../components/filter/filter";
import LoginUtils from "../../common/login-utils";
import ManageCourseActions from "../../../actions/manage-courses-actions";
import apiClient from "../../../actions/api-client";
import $ from "jquery";
import Breadcrumb from "../../breadcrumb/breadcrumb";
import { Alert, Button, ControlLabel, FormControl, FormGroup, HelpBlock, Modal } from "react-bootstrap";
import SelectFieldGroup from "../../forms/select-field-group";
import TextFieldGroup from "../../forms/text-field-group";
import config from "../../../config";
import ProgressIndicator from "../../progress-indicator/progress-indicator";
import CourseMaintenanceTableMobile from "../../course-maintenance-table-mobile/course-maintenance-table-mobile";
import ClevertapReact from "clevertap-react";
import ReactPDF from "react-pdf";
import validateInput from "../../../validations/create-course";
import { ActionIcons } from "../../actionIcons/action-Icons";
import ElasticSearchActions from "../../../actions/elastic-search-actions";
import ElasticSearchQuery from "../../../actions/elastic-search-query";
import ReactScrollPagination from 'react-scroll-pagination'
import { Parser } from "html-to-react";
import axios from "axios";
import { detect } from 'detect-browser';
import MaterialIcon from "react-google-material-icons";
import icon2 from '../../../assets/images/icon2.png'
import virtualIcon2 from '../../../assets/images/virtual-icon2.png'
import stemIcon2 from '../../../assets/images/stem-icon-2.png'
import ReactPlayer from 'react-player';

import './custom.css';

class ManageCourses extends Component {

    constructor(props) {
        super(props);

        this.createCourse = this.createCourse.bind(this);
        this.viewCourse = this.viewCourse.bind(this);
        this.closeViewQuestionPage = this.closeViewQuestionPage.bind(this);
        this.getDuplicateDialog = this.getDuplicateDialog.bind(this);
        this.getDeleteDialog = this.getDeleteDialog.bind(this);
        this.getValidationState = this.getValidationState.bind(this);
        this.handleDuplicateInputChange = this.handleDuplicateInputChange.bind(this);
        this.getShareCourseDialog = this.getShareCourseDialog.bind(this);
        this.shareCourse = this.shareCourse.bind(this);
        this.selectActiveTab = this.selectActiveTab.bind(this);
        this.getAllDepartmentsAndTeachers = this.getAllDepartmentsAndTeachers.bind(this);
        this.onDepartmentSelect = this.onDepartmentSelect.bind(this);
        this.searchTeacher = this.searchTeacher.bind(this);
        this.toggleSelectAll = this.toggleSelectAll.bind(this);
        this.onSubmitShareCourse = this.onSubmitShareCourse.bind(this);
        this.toggleSelectTeacher = this.toggleSelectTeacher.bind(this);
        this.deleteCourse = this.deleteCourse.bind(this);
        this.showDeleteDialog = this.showDeleteDialog.bind(this);
        this.showIndicator = this.showIndicator.bind(this);
        this.hideIndicator = this.hideIndicator.bind(this);
        this.duplicateCourse = this.duplicateCourse.bind(this);
        this.editCourse = this.editCourse.bind(this);
        this.viewCourseOnRowClick = this.viewCourseOnRowClick.bind(this);
        this.onChange = this.onChange.bind(this);
        this.checkEnterKeyPress = this.checkEnterKeyPress.bind(this);
        this.handleAlertDismiss = this.handleAlertDismiss.bind(this);
        this.getViewActivityDialog = this.getViewActivityDialog.bind(this);
        this.viewActivity = this.viewActivity.bind(this);
        this.showFullQuestion = this.showFullQuestion.bind(this);
        this.closeViewActivity = this.closeViewActivity.bind(this);
        this.lockAndEditCourse = this.lockAndEditCourse.bind(this);
        this.getEditCourseConfirmationDialog = this.getEditCourseConfirmationDialog.bind(this);
        this.onChangeCreateCourse = this.onChangeCreateCourse.bind(this);
        this.getCreateCourseDialog = this.getCreateCourseDialog.bind(this);
        this.showCreateCourseDialog = this.showCreateCourseDialog.bind(this);
        this.cancelCreateCourse = this.cancelCreateCourse.bind(this);
        this.isValid = this.isValid.bind(this);
        this.getAllCourses = this.getAllCourses.bind(this);
        this.getNextPage = this.getNextPage.bind(this);
        this.closeHelpText = this.closeHelpText.bind(this);
        this.openHelpText = this.openHelpText.bind(this);

        this.filterComponentCssClassName = 'filterBlock NiceScroll';
        this.tableCssClassName = 'listViewBlock ';
        this.viewActivityCssClassName = "activityLogBlock questionActivityMainBox";

        this.state = {
            viewQuestionDetailsPage: false, viewCoursePage: false, showCreateCourseDialogBox: false,
            showDeleteDialog: false, showDuplicateDialog: false, showShareCourseDialog: false, showViewActivityDialog: false, showEditLockDialog: false,
            duplicateDialogInput: "", selectedDepartment: "", teacherName: "",
            errors: {}, currentRecord: {}, viewActivityData: {}, isFromAssignment: false,
            screenWidth: window.innerWidth, searchTeacherName: '',
            allTeachersSearchResult: [], allSharedWithTeachers: [], sharedWith: [], allDepartments: [], allTeachers: [], allFilteredTeachers: [], allSubjects: [],
            alertText: "This is a sample alert", alertStyle: "danger", showAlert: false, alertDisplayed: false,
            loadingNextPage: 0, viewHelpText: false,
            isLandingPage: true
        };

        this.viewQuestionPanel = false;
        this.viewURLPanel = false;
        this.viewPDFPanel = false;
        this.viewVIDEOPanel = false;
        this.viewFlashcardsPanel = false;
        this.isShowFullQuestion = false;
    }
    getNextPage() {

        const { screenWidth } = this.state;
        const isMobile = screenWidth <= 767;

        if (isMobile & this.state.loadingNextPage === 0) {
            let myID = Math.random() * 10000000000000000000;
            this.setState({ 'loadingNextPage': myID });
            console.log("getting more activities");
            if (this.state.loadingNextPage === myID) {
                let esAttr = this.props.elasticSearchAttributes.map.get('courses');
                const { dispatch } = this.props;
                ElasticSearchActions.setElasticSearchAttributes(dispatch, 'courses', esAttr.searchTerm, esAttr.pageSize + 50, esAttr.activePage, esAttr.sortObj, esAttr.filterValues);
                let elasticSearchQuery = new ElasticSearchQuery();

                let visibility = '(public OR \"' + this.props.auth.user.userName + '\"';
                if (this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName === "LearnerPal") {
                    visibility += " OR LPAdmin";
                }
                if ((this.props.auth.user.linkedAccounts && this.props.auth.user.linkedAccounts.learnerPal) && this.props.auth.user.federatedIdentity) {
                    visibility += ` OR (\"${this.props.auth.user.linkedAccounts.learnerPal}\")`
                }
                visibility += ')';
                let subjectsToSearch = "(";
                if (this.props.auth.user.subjects) {
                    this.props.auth.user.subjects.forEach(sub => {
                        subjectsToSearch += "\"" + sub + "\" OR ";
                    })
                }
                subjectsToSearch = subjectsToSearch.substring(0, subjectsToSearch.length - 3);
                let query = "visibleTo : " + visibility + " AND subject : " + subjectsToSearch + ")";

                elasticSearchQuery.query(ElasticSearchTypes.Course + config.stage, ElasticSearchColumns.Course, this.props,
                    esAttr.searchTerm, esAttr.pageSize + 50, esAttr.activePage, esAttr.sortObj, esAttr.filterValues, function (results, totalRecords) {

                        dispatch({
                            type: RECEIVED_COURSE_RESULTS,
                            status: 200,
                            data: JSON.parse(JSON.stringify(results)),
                            alertText: '',
                            alertStyle: '',
                            showAlert: false,
                            totalRecords: totalRecords
                        });
                    }, true, query);
                this.setState({ 'loadingNextPage': 0 });
            }

        }

    }

    componentDidMount() {

        let visibility = '(public OR \"' + this.props.auth.user.userName + '\"';
        if ((this.props.auth.user.linkedAccounts && this.props.auth.user.linkedAccounts.learnerPal) && this.props.auth.user.federatedIdentity) {
            visibility += ` OR (\"${this.props.auth.user.linkedAccounts.learnerPal}\")`
        }
        if (this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName === "LearnerPal") {
            visibility += " OR LPAdmin";
        }
        visibility += ')';
        let subjectsToSearch = "(";
        if (this.props.auth.user.subjects) {
            this.props.auth.user.subjects.forEach(sub => {
                subjectsToSearch += "\"" + sub + "\" OR ";
            })
        }
        subjectsToSearch = subjectsToSearch.substring(0, subjectsToSearch.length - 3);
        let query = "visibleTo : " + visibility + " AND subject : " + subjectsToSearch + ")";

        LoginUtils.isAuthenticated(this.props, browserHistory, '/courses', '/');
        const { dispatch } = this.props;
        ElasticSearchActions.setElasticSearchAttributes(dispatch, "courses", '', parseInt($('#pageSizeDropDown').val()) ? parseInt($('#pageSizeDropDown').val()) : 50, 1, { _score: { order: "desc" } }, []);
        let elasticSearchQuery = new ElasticSearchQuery();
        elasticSearchQuery.query(ElasticSearchTypes.Course + config.stage, ElasticSearchColumns.Course, this.props,
            '', parseInt($('#pageSizeDropDown').val()) ? parseInt($('#pageSizeDropDown').val()) : 50, 1, { _score: { order: "desc" } }, [], function (results, totalRecords) {

                dispatch({
                    type: RECEIVED_COURSE_RESULTS,
                    status: 200,
                    data: JSON.parse(JSON.stringify(results)),
                    alertText: '',
                    alertStyle: '',
                    showAlert: false,
                    totalRecords: totalRecords
                });
            }, true, query);

        if (this.props.manageCourses.showAlert && !this.state.showAlert) {
            this.setState({ 'alertText': this.props.manageCourses.alertText, 'alertStyle': this.props.manageCourses.alertStyle, 'showAlert': this.props.manageCourses.showAlert, 'alertDisplayed': false });
            setTimeout(() => {
                this.setState({ ...this.state, showAlert: false, alertDisplayed: true });
                this.props.manageCourses.showAlert = false;
            }, 5000);
        }

        if (this.props.location.state) {
            this.setState({ ...this.props.location.state });
        }
        const browser = detect();
        if (browser.os.toLocaleLowerCase() !== 'ios') {
            // Opening Help Text
            // this.openHelpText();
        }

    }

    openHelpText() {
        let selector = '#' + 'sidePanelContainer';
        $(selector).addClass('DivShow');
        $(selector).removeClass('questionActivityMainBox');
        this.viewActivityCssClassName = "activityLogBlock DivShow";
        this.tableCssClassName = 'listViewBlock pulLeftClass';
        this.filterComponentCssClassName = 'filterBlock NiceScroll collapsableDiv';
        this.setState({ viewHelpText: true });
    }

    closeHelpText() {
        this.viewActivityCssClassName = "activityLogBlock questionActivityMainBox";
        this.tableCssClassName = 'listViewBlock';
        this.filterComponentCssClassName = 'filterBlock NiceScroll';
        this.setState({ viewHelpText: false });
    }

    handleAlertDismiss(e) {
        this.setState({ showAlert: false, 'alertDisplayed': true });
        const { dispatch } = this.props;
        ManageCourseActions.setMessage(dispatch, false, '', '', function () {

        });
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleWindowSizeChange = () => {
        this.setState({ screenWidth: window.innerWidth });
    };

    createCourse(e) {
        let that = this;
        if (this.isValid()) {
            that.showIndicator();
            this.isNotDuplicatedName(function (response) {
                if (!response) {
                    that.hideIndicator();
                    that.setState({
                        showCreateCourseDialogBox: false
                    });
                    const { dispatch } = that.props;
                    ManageCourseActions.isEditCourseFunc(dispatch, false, { units: [], selectedSubject: '', courseState: "In Progress" }, function () {
                        ManageCourseActions.setCreateCourseData(dispatch, that.props.manageCourses.courseObj, false, null);
                        browserHistory.push('/courses/create-course');
                    });
                }
                else {
                    let errors = {};
                    errors.courseName = 'Record already exist';
                    that.setState({ errors });
                }

            });
        }
    }
    isNotDuplicatedName(callback) {
        apiClient.invokeApi({}, '/getesdata', 'POST', {}, { id: "/" + this.state.duplicateDialogInput.replace(/\s|\W|[#$%^&*()'.]/g, "").toLocaleLowerCase() + "_" + this.props.auth.user.userName, index: 'learnerpal/', esDocumentType: ElasticSearchTypes.Course + config.stage }, '5').then(function (result) {

            callback(result.data.found);
        }).catch(function (error) {

            callback(false);
        });

        // let axiosInstance = axios.create({
        //     baseURL: 'https://search-learnerpal-eimkio3umgx5fnpuy7ypsrrsri.us-east-1.es.amazonaws.com/learnerpal',
        //     timeout: 60000,
        //     headers: { 'Content-Type': 'application/json' },
        //     responseType: 'json',
        //     transformResponse: {
        //         function(data) {
        //             return data;
        //         }
        //     }
        // });
        // axiosInstance({
        //     method: 'GET',
        //     url: '/' + ElasticSearchTypes.Course + config.stage + '/' + this.props.manageCourses.courseObj.courseName.replace(/\s|\W|[#$%^&*()'.]/g, "") + "_" + this.props.auth.user.userName,
        // }).then(response => {
        //
        //     callback(response.data.found);
        // }
        // ).catch(response => {
        //
        //     callback(response.response.data.found);
        // });
    }
    viewCourse(courseData) {

        this.setState({ viewCoursePage: true, currentRecord: courseData, course: [] });
        let selector = '#' + 'tableFiltersAndSideView';
        $(selector).addClass('removeDisplay');
        let that = this;
        if (courseData.id) {
            apiClient.invokeApi({ courseID: courseData.id }, '/course/{courseID}', 'GET', {}, {}).then(function (result) {
                let tempShare = [];
                for (let i = 0; i < result.data.sharedWith.length; i++) {
                    let temp = result.data.sharedWith[i];
                    if (result.data.sharedWith[i].permission === 'WRITE' || result.data.sharedWith[i].permission === 'READ') {
                        tempShare.push(temp);
                    }
                }
                that.setState({ course: result.data, viewCoursePageSuccess: true, allSharedWithTeachers: tempShare, sharedWith: tempShare });
            });
        }
    }

    closeViewQuestionPage() {
        this.setState({ viewCoursePage: false });
        let selector = '#' + 'tableFiltersAndSideView';
        $(selector).removeClass('removeDisplay');
    }

    checkEnterKeyPress(e) {
        if (e.key === 'Enter' && e.target.name === 'duplicateDialogInput'
            && this.state.duplicateDialogInput && this.state.duplicateDialogInput !== "") {
            this.duplicateCourse();
        }
    }

    getDeleteDialog() {
        let body = <div id="deleteDialogContent">
            <p>
                Are you sure you want to delete the course? All reporting data will be lost.
            </p>
            {this.state.deleteError ? <Alert bsStyle="danger">
                {this.state.deleteErrorMessage}
            </Alert> : ''}
        </div>;
        let that = this;
        return <Modal
            backdrop="static"
            show={this.state.showDeleteDialog}
            onHide={() => {
                this.setState({ showDeleteDialog: false, currentRecord: null, deleteError: false });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Delete Course</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    that.setState({ showDeleteDialog: false, currentRecord: null, deleteError: false });
                }}>Cancel</Button>
                <Button onClick={() => {
                    that.deleteCourse(that.state.currentRecord);
                }} bsStyle='primary'>Delete</Button>
            </Modal.Footer>
        </Modal>;
    }

    showDeleteDialog(selectedRecord) {

        if (selectedRecord) {
            this.setState({ showDeleteDialog: true, currentRecord: selectedRecord });
        }
        else {
            this.setState({ showDeleteDialog: true });
        }
    }

    deleteCourse(selectedRecord) {

        this.showIndicator();
        let context = this;
        if (context.props.auth.isAuthenticated && context.props.auth.user &&
            context.props.auth.user.hasOwnProperty('userName')) {
            let pathTemplate = '/course/' + selectedRecord.id + '/ownername/' + context.props.auth.user.userName;
            apiClient.invokeApi({}, pathTemplate, 'DELETE', {}, {}).then(function (result) {
                ClevertapReact.event("Delete Course", { 'userName': context.props.auth.user.userName });

                context.setState({
                    showAlert: true,
                    alertStyle: 'success',
                    alertText: result.data
                });
                setTimeout(() => {
                    context.setState({ showAlert: false });
                }, 5000);

                setTimeout(() => {
                    context.closeViewQuestionPage();
                    context.hideIndicator();
                    context.setState({
                        showDeleteDialog: false,
                        currentRecord: null,
                        deleteError: false
                    });
                    let esAttr = context.props.elasticSearchAttributes.map.get('courses');
                    const { dispatch } = context.props;
                    ElasticSearchActions.setElasticSearchAttributes(dispatch, 'courses', esAttr.searchTerm, esAttr.pageSize + 50, esAttr.activePage, esAttr.sortObj, esAttr.filterValues);
                    let elasticSearchQuery = new ElasticSearchQuery();
                    let visibility = '(public OR \"' + context.props.auth.user.userName + '\"';
                    if (context.props.auth.user.userType.toLowerCase() === "admin" && context.props.auth.user.schoolName === "LearnerPal") {
                        visibility += " OR LPAdmin";
                    }
                    if ((context.props.auth.user.linkedAccounts && context.props.auth.user.linkedAccounts.learnerPal) && context.props.auth.user.federatedIdentity) {
                        visibility += ` OR (\"${context.props.auth.user.linkedAccounts.learnerPal}\")`
                    }
                    visibility += ')';
                    let subjectsToSearch = "(";
                    if (context.props.auth.user.subjects) {
                        context.props.auth.user.subjects.forEach(sub => {
                            subjectsToSearch += "\"" + sub + "\" OR ";
                        })
                    }
                    subjectsToSearch = subjectsToSearch.substring(0, subjectsToSearch.length - 3);
                    let query = "visibleTo : " + visibility + " AND subject : " + subjectsToSearch + ")";

                    elasticSearchQuery.query(ElasticSearchTypes.Course + config.stage, ElasticSearchColumns.Course, context.props,
                        esAttr.searchTerm, esAttr.pageSize + 50, esAttr.activePage, esAttr.sortObj, esAttr.filterValues, function (results, totalRecords) {

                            dispatch({
                                type: RECEIVED_COURSE_RESULTS,
                                status: 200,
                                data: JSON.parse(JSON.stringify(results)),
                                alertText: '',
                                alertStyle: '',
                                showAlert: false,
                                totalRecords: totalRecords
                            });
                        }, true, query);
                }, 2000);

            }).catch(function (result) {
                context.setState({ duplicateError: true, deleteErrorMessage: result.statusMessage });
            });
        }
    }

    getValidationState() {
        var id = this.state.duplicateDialogInput.trim().toLowerCase().replace(/\s|\W|[#$%^&*()'.]/g, "") + "_" + this.props.auth.user.userName.toLowerCase();

        if (this.state.duplicateDialogInput && this.props.manageCourses.allRecords.filter(function (record) {
            return (record.id.trim().toLowerCase() === id);
        }).length > 0) {
            return 'Course with the same name already exists';
        } else {
            return null;
        }
    }

    handleDuplicateInputChange(e) {
        this.state.duplicateDialogInput = e.target.value;
    }


    getDuplicateDialog(data = {}) {
        let validationState = this.getValidationState();
        let body = <div id="deleteDialogContent">
            <p>
                Please enter new Course name below:
            </p>
            <TextFieldGroup
                error={validationState ? validationState : this.state.errors.duplicateDialogInput}
                field="duplicateDialogInput"
                value={this.state.duplicateDialogInput}
                placeholder="Enter New Course Name"
                onChange={this.onChange}
                onKeyUp={this.checkEnterKeyPress}
            />
        </div>;
        return <Modal
            backdrop="static"
            show={this.state.showDuplicateDialog}
            onHide={() => {
                this.setState({
                    showDuplicateDialog: false,
                    duplicateRecord: null,
                    duplicateError: false,
                    duplicateErrorMessage: ''
                });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Duplicate Course</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    this.setState({
                        showDuplicateDialog: false,
                        duplicateRecord: null,
                        duplicateError: false,
                        duplicateErrorMessage: ''
                    });
                }}>Cancel</Button>
                <Button disabled={!this.state.duplicateDialogInput || this.state.duplicateDialogInput.trim() === ""}
                    onClick={() => {
                        this.duplicateCourse();
                    }} bsStyle='primary'>Duplicate</Button>
            </Modal.Footer>
        </Modal>;
    }
    showIndicator() {
        this.setState({
            ...this.state,
            loading: true
        });
    }

    hideIndicator() {
        this.setState({
            ...this.state,
            loading: false
        });
    }
    isNotDuplicatedNameForDuplicate() {


        return apiClient.invokeApi({}, '/getesdata', 'POST', {}, { id: "/" + this.state.duplicateDialogInput.replace(/\s|\W|[#$%^&*()'.]/g, "").toLocaleLowerCase() + "_" + this.props.auth.user.userName, index: 'learnerpal/', esDocumentType: ElasticSearchTypes.Course + config.stage }, '5');

        // let reponse = {};
        // reponse.results = [];
        // if (result.data.hits && result.data.hits.hits.length > 0) {
        //     for (var a = 0; a < result.data.hits.hits.length; a++) {
        //         reponse.results.push(result.data.hits.hits[a]._source);
        //     }
        // }
        // reponse.totalRecords = result.data.hits ? result.data.hits.total : 0;

        // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!", reponse);

        //  (reponse.results, reponse.totalRecords);

        // let axiosInstance = axios.create({
        //     baseURL: 'https://search-learnerpal-eimkio3umgx5fnpuy7ypsrrsri.us-east-1.es.amazonaws.com/learnerpal',
        //     timeout: 60000,
        //     headers: { 'Content-Type': 'application/json' },
        //     responseType: 'json',
        //     transformResponse: {
        //         function(data) {
        //             return data;
        //         }
        //     }
        // });
        // return axiosInstance({
        //     method: 'GET',
        //     url: '/' + ElasticSearchTypes.Course + config.stage + '/' + this.state.duplicateDialogInput.replace(/\s|\W|[#$%^&*()'.]/g, "") + "_" + this.props.auth.user.userName,
        // })
    }
    duplicateCourse(selectedRecord) {
        let redirectToList = false;
        if (selectedRecord === undefined || selectedRecord === null || selectedRecord.target) {
            this.setState({ showDuplicateDialog: true });
            redirectToList = true;
        }
        else {
            this.setState({ showDuplicateDialog: true, currentRecord: selectedRecord });
        }

        let context = this;
        if (!this.getValidationState() && this.state.duplicateDialogInput) {
            this.showIndicator();
            this.setState({ showDuplicateDialog: false });
            this.isNotDuplicatedNameForDuplicate().then(response => {
                context.hideIndicator();
                if (response.data.found) {
                    let errors = {};
                    errors.duplicateDialogInput = 'Course with the same name already exists';
                    context.setState({ errors });
                    context.setState({ showDuplicateDialog: true });
                }
            }).catch(response => {
                if (!response.response.data.found) {
                    if (context.props.auth.isAuthenticated && context.props.auth.user &&
                        context.props.auth.user.hasOwnProperty('userName')) {
                        let pathTemplate = '/course/clone';
                        let method = 'POST';
                        let additionalParams = {};
                        let body = {
                            courseID: this.state.currentRecord.id,
                            courseName: this.state.duplicateDialogInput,
                            ownerName: context.props.auth.user.userName,
                            schoolName: context.props.auth.user.schoolName
                        };
                        apiClient.invokeApi(null, pathTemplate, method, additionalParams, body).then(function (result) {
                            ClevertapReact.event("Duplicate Course", { 'userName': context.props.auth.user.userName });
                            context.setState({
                                showDuplicateDialog: false,
                                duplicateRecord: null,
                                duplicateError: false,
                                duplicateDialogInput: '',
                                duplicateErrorMessage: ''
                            });
                            setTimeout(() => {
                                context.hideIndicator();
                                if (redirectToList) {
                                    context.closeViewQuestionPage();
                                }
                                let esAttr = context.props.elasticSearchAttributes.map.get('courses');
                                const { dispatch } = context.props;
                                ElasticSearchActions.setElasticSearchAttributes(dispatch, 'courses', esAttr.searchTerm, esAttr.pageSize + 50, esAttr.activePage, esAttr.sortObj, esAttr.filterValues);
                                let elasticSearchQuery = new ElasticSearchQuery();
                                let visibility = '(public OR \"' + context.props.auth.user.userName + '\"';
                                if (context.props.auth.user.userType.toLowerCase() === "admin" && context.props.auth.user.schoolName === "LearnerPal") {
                                    visibility += " OR LPAdmin";
                                }
                                if ((context.props.auth.user.linkedAccounts && context.props.auth.user.linkedAccounts.learnerPal) && context.props.auth.user.federatedIdentity) {
                                    visibility += ` OR (\"${context.props.auth.user.linkedAccounts.learnerPal}\")`
                                }
                                visibility += ')';
                                let subjectsToSearch = "(";
                                if (context.props.auth.user.subjects) {
                                    context.props.auth.user.subjects.forEach(sub => {
                                        subjectsToSearch += "\"" + sub + "\" OR ";
                                    })
                                }
                                subjectsToSearch = subjectsToSearch.substring(0, subjectsToSearch.length - 3);
                                let query = "visibleTo : " + visibility + " AND subject : " + subjectsToSearch + ")";

                                elasticSearchQuery.query(ElasticSearchTypes.Course + config.stage, ElasticSearchColumns.Course, context.props,
                                    esAttr.searchTerm, esAttr.pageSize + 50, esAttr.activePage, esAttr.sortObj, esAttr.filterValues, function (results, totalRecords) {

                                        dispatch({
                                            type: RECEIVED_COURSE_RESULTS,
                                            status: 200,
                                            data: JSON.parse(JSON.stringify(results)),
                                            alertText: '',
                                            alertStyle: '',
                                            showAlert: false,
                                            totalRecords: totalRecords
                                        });
                                    }, true, query);
                            }, 2000);
                        }).catch(function (result) {
                            setTimeout(() => {
                                context.hideIndicator();
                                context.setState({
                                    showDuplicateDialog: false,
                                    duplicateRecord: null,
                                    duplicateError: false,
                                    duplicateDialogInput: '',
                                    duplicateErrorMessage: ''
                                });
                                const { dispatch } = context.props;
                                let visibility = '(public OR "' + context.props.auth.user.userName + '"';
                                if (context.props.auth.user.userType.toLowerCase() === "admin" && context.props.auth.user.schoolName === "LearnerPal") {
                                    visibility += " OR LPAdmin";
                                }
                                if ((context.props.auth.user.linkedAccounts && context.props.auth.user.linkedAccounts.learnerPal) && context.props.auth.user.federatedIdentity) {
                                    visibility += ` OR \"${context.props.auth.user.linkedAccounts.learnerPal}\"`
                                }
                                visibility += ')';
                                let subjectsToSearch = "(";
                                if (context.props.auth.user.subjects) {
                                    context.props.auth.user.subjects.forEach(sub => {
                                        subjectsToSearch += "\"" + sub + "\" OR ";
                                    })
                                }
                                subjectsToSearch = subjectsToSearch.substring(0, subjectsToSearch.length - 3);
                                subjectsToSearch += ")";
                                ManageCourseActions.fetchAllCourses(dispatch, context.props.auth.user.userName, 'course' + config.stage, {
                                    'query_string': {
                                        'fields': ['visibleTo', 'subject'],
                                        'query': 'visibleTo : ' + visibility + ' AND subject : ' + subjectsToSearch
                                    }
                                }, [
                                    'id', 'type', 'keywords', 'ownerName', 'visibleTo', 'courseName', 'description', 'subject', 'units', 'originalOwner', 'lastUpdated', 'sharedWith', 'courseState', 'createdAt'
                                ], result.data, "success", true);
                            }, 2000);
                        });
                    }
                }
            });
        }
    }

    selectActiveTab(evt) {
        if (evt.target.id === "collaborateTab") {
            let selector = '#' + 'collaborateTabLi';
            $(selector).addClass('active');
            let selector1 = '#' + 'sharedWithTabLi';
            $(selector1).removeClass('active');

            let selector2 = '#' + 'tab1';
            $(selector2).addClass('active');
            let selector3 = '#' + 'tab2';
            $(selector3).removeClass('active');
        }
        else {
            let selector = '#' + 'collaborateTabLi';
            $(selector).removeClass('active');
            let selector1 = '#' + 'sharedWithTabLi';
            $(selector1).addClass('active');

            let selector2 = '#' + 'tab2';
            $(selector2).addClass('active');
            let selector3 = '#' + 'tab1';
            $(selector3).removeClass('active');
        }
    }

    getAllDepartmentsAndTeachers() {
        this.showIndicator();
        let that = this;
        apiClient.invokeApi({}, '/departments', 'GET', {}, {}).then(function (result) {
            that.setState({ allDepartments: result.data });
        });
        apiClient.invokeApi({}, '/user/schoolname/' + this.props.auth.user.schoolID, 'GET', {}, {}).then(function (result) {
            let tempTeachersWithoutAccess = [];
            let tempTeachersWithAccess = [];
            outerloop:
            for (let i = 0; i < result.data.length; i++) {
                for (let j = 0; j < that.state.allSharedWithTeachers.length; j++) {
                    if (that.state.allSharedWithTeachers[j].id === result.data[i].id) {
                        let temp = that.state.allSharedWithTeachers[j];
                        temp.department = result.data[i].department;
                        tempTeachersWithAccess.push(temp);
                        tempTeachersWithoutAccess.push(temp);
                        continue outerloop;
                    }
                }
                tempTeachersWithoutAccess.push(result.data[i]);
            }
            that.setState({ allTeachers: tempTeachersWithoutAccess, sharedWith: tempTeachersWithAccess });
            that.hideIndicator();
        });
    }

    onDepartmentSelect(e) {

        this.setState({ selectedDepartment: e.target.value });
        let searchTerm = e.target.value;

        let searchResult = [];
        let data = this.state.allTeachers;
        if (searchTerm === "") {
            searchResult = data;
        }
        else {
            if (data.length > 0) {
                for (let i = 0; i < data.length; i++) {
                    let tempVarName = data[i].department;
                    if (tempVarName && tempVarName.search(searchTerm) !== -1) {
                        searchResult.push(data[i]);
                    }
                }
            }
        }
        this.setState({ allTeachersSearchResult: searchResult, allFilteredTeachers: searchResult });
        $('#searchTextBox').prop('value', '');
    }

    searchTeacher(event) {
        let searchTerm = event.target.value.toLowerCase();
        let searchResult = [];
        let data = this.state.allFilteredTeachers;
        if (searchTerm === "") {
            searchResult = data;
        }
        else {
            if (data.length > 0) {
                for (let i = 0; i < data.length; i++) {
                    let tempVarName = data[i].name.toLowerCase();
                    if (tempVarName.search(searchTerm) !== -1) {
                        searchResult.push(data[i]);
                    }
                }
            }
        }
        this.setState({ allTeachersSearchResult: searchResult, searchTeacherName: searchTerm });
    }

    toggleSelectTeacher(event) {
        let value = event.target.value.split(":");
        let id = value[0];
        let department = value[1];
        if (event.target.checked) {
            let tempState = this.state.sharedWith;
            let permission = "";
            if (event.target.id.startsWith("rd")) {
                for (let j = 0; j < tempState.length; j++) {
                    if (tempState[j].id === id && tempState[j].permission === "EMPTY") {
                        tempState.splice(j, 1);
                    }
                }
                permission = "READ";
            }
            else if (event.target.id.startsWith("wrt")) {

                for (let j = 0; j < tempState.length; j++) {
                    if (tempState[j].id === id && (tempState[j].permission === "READ" || tempState[j].permission === "EMPTY")) {
                        tempState.splice(j, 1);
                    }
                }
                permission = "WRITE";
            }

            let tempAllTeachersSearchResult = this.state.allTeachersSearchResult;
            let index = tempAllTeachersSearchResult.findIndex(x => x.id === id);
            if (index !== -1) {
                tempAllTeachersSearchResult[index].permission = permission;
            }

            let tempAllTeachersAlreadyShared = this.state.allSharedWithTeachers;
            let index2 = tempAllTeachersAlreadyShared.findIndex(x => x.id === id);
            if (index2 !== -1) {
                tempAllTeachersAlreadyShared[index2].permission = permission;
            }
            this.setState({
                allTeachersSearchResult: tempAllTeachersSearchResult, allSharedWithTeachers: tempAllTeachersAlreadyShared,
                sharedWith: tempState.concat({
                    id: id,
                    name: event.target.name,
                    permission: permission,
                    department: department
                })
            });
        }
        else {
            let tempStateNew = this.state.sharedWith;
            let tempAllTeachersSearchResult2 = this.state.allTeachersSearchResult;
            let tempAlreadyShared = this.state.allSharedWithTeachers;
            if (event.target.id.startsWith("wrt")) {

                let index1 = tempStateNew.findIndex(x => x.id === id);
                if (index1 !== -1) {
                    tempStateNew[index1].permission = "READ";
                }
                let index2 = tempAllTeachersSearchResult2.findIndex(x => x.id === id);
                if (index2 !== -1) {
                    tempAllTeachersSearchResult2[index2].permission = "READ";
                }
                let index3 = tempAlreadyShared.findIndex(x => x.id === id);
                if (index3 !== -1) {
                    tempAlreadyShared[index3].permission = "READ";
                }
            }
            else {
                let index1 = tempStateNew.findIndex(x => x.id === id);
                if (index1 !== -1) {
                    tempStateNew[index1].permission = "EMPTY";
                }
                let index2 = tempAllTeachersSearchResult2.findIndex(x => x.id === id);
                if (index2 !== -1) {
                    tempAllTeachersSearchResult2[index2].permission = "EMPTY";
                }
                let index3 = tempAlreadyShared.findIndex(x => x.id === id);
                if (index3 !== -1) {
                    tempAlreadyShared[index3].permission = "EMPTY";
                }
            }
            this.setState({ sharedWith: tempStateNew, allTeachersSearchResult: tempAllTeachersSearchResult2, allSharedWithTeachers: tempAlreadyShared });
        }
    }

    toggleSelectAll(event) {

        let tempAllTeacherSearchResult = this.state.allTeachersSearchResult;
        let tempAllTeacherAlreadySyared = this.state.allSharedWithTeachers;
        let sharedWith = this.state.sharedWith;
        if (event.target.checked) {

            if (event.target.id === 'rdSelectAll') {
                for (let i = 0; i < tempAllTeacherSearchResult.length; i++) {
                    if (tempAllTeacherSearchResult[i].permission !== 'WRITE') {
                        tempAllTeacherSearchResult[i].permission = 'READ';
                        if (sharedWith.findIndex(x => x.id === tempAllTeacherSearchResult[i].id) === -1) {
                            sharedWith.push(tempAllTeacherSearchResult[i]);
                        }
                    }
                }
            }
            else {
                let readCheckId = '#rdSelectAll';
                $(readCheckId).prop('checked', true);
                $(readCheckId).prop('disabled', true);

                for (let k = 0; k < tempAllTeacherSearchResult.length; k++) {
                    tempAllTeacherSearchResult[k].permission = 'WRITE';
                    if (sharedWith.findIndex(x => x.id === tempAllTeacherSearchResult[k].id) === -1) {
                        sharedWith.push(tempAllTeacherSearchResult[k]);
                    }
                }
            }
            this.setState({ allTeachersSearchResult: tempAllTeacherSearchResult, sharedWith: sharedWith });


            if (event.target.id === 'rdSelectAll_') {

                for (let i = 0; i < tempAllTeacherAlreadySyared.length; i++) {
                    if (tempAllTeacherAlreadySyared[i].permission !== 'WRITE') {
                        tempAllTeacherAlreadySyared[i].permission = 'READ';
                        if (sharedWith.findIndex(x => x.id === tempAllTeacherAlreadySyared[i].id) === -1) {
                            sharedWith.push(tempAllTeacherAlreadySyared[i]);
                        }
                    }
                }
            }
            else {
                let readCheckId = '#rdSelectAll_';
                $(readCheckId).prop('checked', true);
                $(readCheckId).prop('disabled', true);

                for (let k = 0; k < tempAllTeacherAlreadySyared.length; k++) {
                    tempAllTeacherAlreadySyared[k].permission = 'WRITE';
                    if (sharedWith.findIndex(x => x.id === tempAllTeacherAlreadySyared[k].id) === -1) {
                        sharedWith.push(tempAllTeacherAlreadySyared[k]);
                    }
                }
            }
            this.setState({ allSharedWithTeachers: tempAllTeacherAlreadySyared, sharedWith: sharedWith });
        }
        else {

            let tempSharedWith = this.state.sharedWith;
            let tempAllTeachersSearchResult = this.state.allTeachersSearchResult;
            if (event.target.id === "wrtSelectAll") {
                let readCheckId = '#rdSelectAll';
                $(readCheckId).prop('checked', true);
                $(readCheckId).prop('disabled', false);

                for (let a = 0; a < tempAllTeachersSearchResult.length; a++) {
                    tempAllTeachersSearchResult[a].permission = 'READ';
                    let index = tempSharedWith.findIndex(x => x.id === tempAllTeachersSearchResult[a].id);
                    if (index !== -1) {
                        tempSharedWith[index].permission = 'READ';
                    }
                }
            }
            else {
                for (let j = 0; j < tempAllTeachersSearchResult.length; j++) {
                    if (tempAllTeacherSearchResult[j].permission && tempAllTeacherSearchResult[j].permission !== 'WRITE') {
                        tempAllTeachersSearchResult[j].permission = 'EMPTY';
                        let index = tempSharedWith.findIndex(x => x.id === tempAllTeachersSearchResult[j].id);
                        if (index !== -1) {
                            tempSharedWith[index].permission = 'EMPTY';
                        }
                    }
                    else {
                        let index = tempSharedWith.findIndex(x => x.id === tempAllTeachersSearchResult[j].id);
                        if (index !== -1) {
                            tempSharedWith.splice(index, 1);
                        }
                    }
                }
            }
            this.setState({ allTeachersSearchResult: tempAllTeachersSearchResult, sharedWith: tempSharedWith });

            if (event.target.id === "wrtSelectAll_") {
                let readCheckId = '#rdSelectAll_';
                $(readCheckId).prop('checked', true);
                $(readCheckId).prop('disabled', false);

                for (let a = 0; a < tempAllTeacherAlreadySyared.length; a++) {
                    tempAllTeacherAlreadySyared[a].permission = 'READ';
                    let index = tempSharedWith.findIndex(x => x.id === tempAllTeacherAlreadySyared[a].id);
                    if (index !== -1) {
                        tempSharedWith[index].permission = 'READ';
                    }
                }
            }
            else {
                for (let j = 0; j < tempAllTeacherAlreadySyared.length; j++) {
                    if (tempAllTeacherAlreadySyared[j].permission && tempAllTeacherAlreadySyared[j].permission !== 'WRITE') {
                        tempAllTeacherAlreadySyared[j].permission = 'EMPTY';
                        let index = tempSharedWith.findIndex(x => x.id === tempAllTeacherAlreadySyared[j].id);
                        if (index !== -1) {
                            tempSharedWith[index].permission = 'EMPTY';
                        }
                    }
                    else {
                        let index = tempSharedWith.findIndex(x => x.id === tempAllTeacherAlreadySyared[j].id);
                        if (index !== -1) {
                            tempSharedWith.splice(index, 1);
                        }
                    }
                }
            }
            this.setState({ allSharedWithTeachers: tempAllTeacherAlreadySyared, sharedWith: tempSharedWith });
        }
    }

    getShareCourseDialog() {
        let teachersNameRowHtml = [];
        let tempTeacher = this.state.allTeachersSearchResult;

        if (tempTeacher && tempTeacher.length > 0) {
            for (let i = 0; i < tempTeacher.length; i++) {
                if (tempTeacher[i].id === this.props.auth.user.userName) {
                    continue;
                }
                let readPermission = false;
                let writePermission = false;
                let isDisabled = false;

                if (tempTeacher[i].permission === "WRITE") {
                    readPermission = true;
                    writePermission = true;
                    isDisabled = true;
                }
                else if (tempTeacher[i].permission === "READ") {
                    readPermission = true;
                    writePermission = false;
                }
                teachersNameRowHtml.push(
                    <div className="blockBox">
                        <div className="tNamePan">{tempTeacher[i].name}</div>
                        <div className="readCheck">
                            <input type="checkbox" disabled={isDisabled} checked={readPermission} onChange={this.toggleSelectTeacher} value={tempTeacher[i].id + ":" + tempTeacher[i].department} id={"rd" + i} className="css-checkbox" name={tempTeacher[i].name} />
                            <label htmlFor={"rd" + i} className="pay_label css-label1" />
                        </div>
                        <div className="writeCheck">
                            <input type="checkbox" checked={writePermission} onChange={this.toggleSelectTeacher} value={tempTeacher[i].id + ":" + tempTeacher[i].department} id={"wrt" + i} className="css-checkbox" name={tempTeacher[i].name} />
                            <label htmlFor={"wrt" + i} className="pay_label css-label1" />
                        </div>
                    </div>
                );
            }
        }

        let teachersNamesAlreadySHaredHtml = [];
        let temp2 = this.state.allSharedWithTeachers;
        if (temp2 && temp2.length > 0) {
            for (let u = 0; u < temp2.length; u++) {
                if (temp2[u].id === this.props.auth.user.userName) {
                    continue;
                }

                let readPermission1 = false;
                let writePermission1 = false;
                let isDisabled = false;
                if (temp2[u].permission === "WRITE") {
                    readPermission1 = true;
                    writePermission1 = true;
                    isDisabled = true;
                }
                else if (temp2[u].permission === "READ") {
                    readPermission1 = true;
                    writePermission1 = false;
                }
                teachersNamesAlreadySHaredHtml.push(
                    <div className="blockBox">
                        <div className="tNamePan">{temp2[u].name}</div>
                        <div className="readCheck">
                            <input type="checkbox" id={"rd_" + u} disabled={isDisabled} defaultChecked={readPermission1} onChange={this.toggleSelectTeacher} value={temp2[u].id + ":" + temp2[u].department} className="css-checkbox" name={temp2[u].name} />
                            <label htmlFor={"rd_" + u} className="pay_label css-label1" />
                        </div>
                        <div className="writeCheck">
                            <input type="checkbox" id={"wrt_" + u} defaultChecked={writePermission1} onChange={this.toggleSelectTeacher} value={temp2[u].id + ":" + temp2[u].department} className="css-checkbox" name={temp2[u].name} />
                            <label htmlFor={"wrt_" + u} className="pay_label css-label1" />
                        </div>
                    </div>
                );
            }
        }

        let body = <div id="shareListDialog" className="tabbable">
            <ul className="nav nav-tabs">
                <li id="collaborateTabLi" className="shareTiltleTop active" onClick={this.selectActiveTab}><a id="collaborateTab">Collaborate & Share</a></li>
                <li id="sharedWithTabLi" className="shareTiltleTop" onClick={this.selectActiveTab}><a id="sharedWithTab">Currently Shared With</a></li>
            </ul>
            <div className="tab-content">
                <div className="tab-pane CSWTab active" id="tab1">
                    <div className="posRel fieldBg selectWidth">
                        <SelectFieldGroup
                            isDisabled={false}
                            field="departments"
                            value={this.state.selectedDepartment}
                            options={this.state.allDepartments}
                            placeholder="Select Department"
                            onChange={this.onDepartmentSelect}
                        />
                        <TextFieldGroup
                            value={this.state.searchTeacherName}
                            id="searchTextBox"
                            isDisabled={false}
                            field="teacherName"
                            placeholder="Search Teacher Name"
                            onChange={this.searchTeacher}
                        />
                    </div>
                    {tempTeacher && tempTeacher.length > 0 &&
                        <div>
                            <h4>Select teacher and assign access</h4>
                            <div className="curSharTabPan">
                                <div className="CSWCheckPan">
                                    <div className="stipHeading">
                                        <div className="blockBox">
                                            <div className="tNamePan">Teacher Name</div>
                                            <div className="readCheck">
                                                <input type="checkbox" id={"rdSelectAll"} onChange={this.toggleSelectAll} className="css-checkbox" />
                                                <label htmlFor={"rdSelectAll"} className="pay_label css-label1">Read</label>
                                            </div>
                                            <div className="writeCheck">
                                                <input type="checkbox" id={"wrtSelectAll"} onChange={this.toggleSelectAll} className="css-checkbox" />
                                                <label htmlFor={"wrtSelectAll"} className="pay_label css-label1">Write</label>
                                            </div>
                                        </div>
                                    </div>
                                    {teachersNameRowHtml}
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className="tab-pane CSWTab" id="tab2">
                    {temp2 && temp2.length > 0 &&
                        <div>
                            <h4>Currently shared with following teachers</h4>
                            <div className="curSharTabPan">
                                <div className="CSWCheckPan">
                                    <div className="stipHeading">
                                        <div className="blockBox">
                                            <div className="tNamePan">Teacher Name</div>
                                            <div className="readCheck">
                                                <input type="checkbox" id={"rdSelectAll_"} onChange={this.toggleSelectAll} className="css-checkbox" />
                                                <label htmlFor={"rdSelectAll_"} className="pay_label css-label1">Read</label>
                                            </div>
                                            <div className="writeCheck">
                                                <input type="checkbox" id={"wrtSelectAll_"} onChange={this.toggleSelectAll} className="css-checkbox" />
                                                <label htmlFor={"wrtSelectAll_"} className="pay_label css-label1">Write</label>
                                            </div>
                                        </div>
                                    </div>
                                    {teachersNamesAlreadySHaredHtml}
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>;
        return <Modal id="ShareCourseDialog"
            backdrop="static"
            show={this.state.showShareCourseDialog}
            onHide={() => {
                this.setState({
                    showShareCourseDialog: false
                });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Share Course</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    this.setState({
                        showShareCourseDialog: false
                    });
                }}>Cancel</Button>
                <Button onClick={() => {
                    this.onSubmitShareCourse();
                }} bsStyle='primary'>Update Share Settings</Button>
            </Modal.Footer>
        </Modal>;
    }

    onSubmitShareCourse() {

        this.showIndicator();
        let tempState = this.state;
        let tempSharedWith = tempState.sharedWith;
        let body = {
            courseID: this.state.courseID,
            ownerName: this.props.auth.user.userName,
            sharedWith: this.state.sharedWith
        };
        let that = this;
        apiClient.invokeApi({}, '/course/share', 'PUT', {}, body, "2").then(function (result) {
            ClevertapReact.event("Share Course", { 'userName': that.props.auth.user.userName });
            that.hideIndicator();

            that.setState({ showShareCourseDialog: false, alertText: result.data, alertStyle: "success", showAlert: true, alertDisplayed: false });
            setTimeout(() => {
                that.setState({ showAlert: false, alertDisplayed: true });
            }, 5000);
            if (that.state.fromManageCourse) {
                that.setState({ fromManageCourse: false });
            }
            else {
                that.viewCourse(tempState.currentRecord);
            }

        }).catch(function (result) {
            that.hideIndicator();
            that.setState({ showShareCourseDialog: false, alertText: result.data, alertStyle: "success", showAlert: true, alertDisplayed: false });
            setTimeout(() => {
                that.setState({ showAlert: false, alertDisplayed: true });
            }, 5000);
        });
    }

    shareCourse() {
        this.getAllDepartmentsAndTeachers();
        this.setState({ showShareCourseDialog: true, selectedDepartment: '', allTeachersSearchResult: [] })
    }

    editCourse(selectedRecord) {
        let record = null;
        if (selectedRecord === undefined || selectedRecord === null || selectedRecord.target) {
            record = this.state.currentRecord;
        }
        else {
            this.setState({ currentRecord: selectedRecord });
            record = selectedRecord;
        }
        let that = this;
        if (record.id) {
            this.showIndicator();
            apiClient.invokeApi({ courseID: record.id }, '/course/{courseID}', 'GET', {}, {}).then(function (result) {
                if (result.data.lockStatus) {
                    if (result.data.lockedBy !== that.props.auth.user.userName && result.data.ownerName === that.props.auth.user.userName) {
                        //This course is locked by result.data.lockedBy, I can unlock it
                        that.hideIndicator();
                        that.setState({
                            ableToUnlock: true,
                            unlockMessage: 'This course is locked by ' + result.data.lockedBy + '. Do you want to unlock and edit it?',
                            showEditLockDialog: true, editCourseData: result.data
                        });
                    }
                    else if (result.data.lockedBy === that.props.auth.user.userName) {
                        that.setState({ editCourseData: result.data }, that.lockAndEditCourse);
                    }
                    else {
                        //This course is locked by result.data.lockedBy. I can't unlock it
                        that.hideIndicator();
                        that.setState({
                            ableToUnlock: false, unlockMessage: 'This course is locked by ' + result.data.lockedBy, showEditLockDialog: true
                        });
                    }
                }
                else {
                    that.setState({ editCourseData: result.data }, that.lockAndEditCourse);
                }
            });
        }
    }

    lockAndEditCourse() {
        let that = this;
        let body = {
            lockStatus: true,
            ownerName: that.props.auth.user.userName,
            courseID: that.state.editCourseData.courseID
        };
        apiClient.invokeApi({}, '/course/lock', 'PUT', {}, body, "2").then(function (result) {
            that.hideIndicator();
            let editCourseData = that.state.editCourseData;
            const { dispatch } = that.props;
            ManageCourseActions.isEditCourseFunc(dispatch, true, editCourseData, function () {
                browserHistory.push('/courses/create-course');
            });
        }).catch(function (result) {
        });
    }

    getEditCourseConfirmationDialog() {
        let body =
            <h4>
                {this.state.unlockMessage}
            </h4>;

        return <Modal
            backdrop="static"
            show={this.state.showEditLockDialog}
            onHide={() => {
                this.setState({
                    showEditLockDialog: false
                });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title>Course Locked</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                {<Button onClick={() => {
                    this.setState({
                        showEditLockDialog: false
                    });
                }}>Cancel</Button> && this.state.ableToUnlock && <Button onClick={() => {
                    this.setState({
                        showEditLockDialog: false
                    });
                    this.lockAndEditCourse();
                }}>Unlock</Button>}

                {!this.state.ableToUnlock && <Button onClick={() => {
                    this.setState({
                        showEditLockDialog: false
                    });
                }}>OK</Button>}
            </Modal.Footer>
        </Modal>;
    }

    viewCourseOnRowClick(selectedRecord, event) {
        const { screenWidth } = this.state;
        const isTab = screenWidth <= 1199;
        if (isTab) {
            this.viewCourse(selectedRecord);
        }
    }

    viewActivity(event) {
        this.viewQuestionPanel = false;
        this.viewURLPanel = false;
        this.viewPDFPanel = false;
        this.viewVIDEOPanel = false;
        this.viewFlashcardsPanel = false;
        this.isShowFullQuestion = false;

        this.showIndicator();
        let that = this;
        apiClient.invokeApi({ activityID: event.target.id }, '/activity/{activityID}', 'GET', {}, {}).then(function (result) {
            that.hideIndicator();
            switch (result.data.activityType) {
                case 'flashcards':
                    that.viewFlashcardsPanel = true;
                    break;
                case 'question':
                    that.viewQuestionPanel = true;
                    break;
                case 'learning':
                    if (result.data.documentType === 'URL') {
                        that.viewURLPanel = true;
                    }
                    else if (result.data.documentType === 'PDF') {
                        that.viewPDFPanel = true;
                    }
                    else if (result.data.documentType.toLowerCase() === 'video') {
                        that.viewVIDEOPanel = true;
                    }
                    break;
            }
            that.setState({ viewActivityData: result.data, showViewActivityDialog: true });
        }).catch(function (result) {
            //handle error
        });
    }
    showFullQuestion() {

    }
    closeViewActivity() {
        this.setState({ showViewActivityDialog: false, viewActivityData: {} })
    }

    onChangeCreateCourse(e) {
        if (e.target.name === 'selectedSubject') {
            this.setState({ [e.target.name]: e.target.value });
        }
        const { dispatch } = this.props;
        let tempCourseObj = this.props.manageCourses.courseObj;
        tempCourseObj[e.target.name] = e.target.value;
        ManageCourseActions.setCreateCourseData(dispatch, tempCourseObj, false, null);
    }
    getViewActivityDialog() {
        let tempPropHolder = this.state.viewActivityData;
        let sideViewPanel = null;
        if (this.viewQuestionPanel) {
            let questions = "";
            if (tempPropHolder.questions) {
                for (let i = 1; i < tempPropHolder.questions.length + 1; i++) {
                    let quesText = "";
                    let choicesText = "";
                    let showChoices = true;
                    if (tempPropHolder.questions[i - 1]) {
                        quesText = i + ")  " + `<span dir lang = "${this.props.auth.user.prefLanguage}">` + tempPropHolder.questions[i - 1].question + `</span>`;
                        showChoices = quesText.indexOf('LP_ANSWERS') < 0;
                        if (tempPropHolder.questions[i - 1] && tempPropHolder.questions[i - 1].choices) {
                            for (let j = 1; j < tempPropHolder.questions[i - 1].choices.length + 1; j++) {
                                choicesText += "<div class='fieldBoxfieldBox'><label>" + j + ")  " + `<span dir lang = "${this.props.auth.user.prefLanguage}">` + tempPropHolder.questions[i - 1].choices[j - 1] + `</span>` + "</label></div>"
                            }
                        }
                        quesText = quesText.replace('[[[LP_ANSWERS]]]', choicesText);

                        if (showChoices) {
                            quesText += choicesText;
                        }
                        questions += `<div dir lang = ${this.props.auth.user.prefLanguage}` + " class='QuestionChoiceBox  width_100' key='question-preview-" + i + "'><p class='quesText'  id=" + tempPropHolder.questions[i - 1].id + ">" + quesText + "</p><div class='row'></div></div>";
                    }
                }
            }

            let panel = null;
            let htmlToReactParser = new Parser();
            panel = <div className="FrontQuestionBox">
                <div className="questionBlock">
                    <h3 className="noteHeading">Question Activity: {tempPropHolder.questions.length} Questions</h3>
                    <div className="listNumQuestion">
                        {htmlToReactParser.parse(questions)}
                    </div>
                </div>
            </div>
            sideViewPanel = <div className="topRowHaedingAnch">
                <span className="scrollBar" />
                {panel}
            </div>
        }
        else if (this.viewURLPanel) {
            sideViewPanel =
                <div className="learningSlideTab ">
                    <div className="FrontQuestionBox">
                        <div className="questionBlock NiceScroll">
                            <h3 className="noteHeading">URL Activity</h3>
                            <div className="listNumQuestion">
                                <h4>URL</h4>
                                <a target="_blank" href={tempPropHolder.documentLink}>
                                    {tempPropHolder.documentLink}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
        }
        else if (this.viewPDFPanel) {
            sideViewPanel =
                <div className="learningSlideTab ">
                    <div className="FrontQuestionBox">
                        <div className="questionBlock NiceScroll">
                            <h3 className="noteHeading">PDF Activity</h3>
                            <div className="listNumQuestion">
                                <h4>PDF</h4>
                                <a href={tempPropHolder.documentLink} target="_blank"
                                    className="LinkLeaning">
                                    {tempPropHolder.fileName}
                                    <br />
                                    <ReactPDF
                                        file={tempPropHolder.documentLink}
                                        scale={0.4}
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
        }
        else if (this.viewVIDEOPanel) {
            sideViewPanel =
                <div className="learningSlideTab ">
                    <div className="FrontQuestionBox">
                        <div className="questionBlock NiceScroll">
                            <h3 className="noteHeading">Video Activity</h3>
                            <div className="listNumQuestion">
                                <h4>Video</h4>
                                <a href={tempPropHolder.documentLink} target="_blank"
                                    className="LinkLeaning">
                                    {tempPropHolder.fileName}
                                    <br />
                                    <ReactPlayer width="340px" height="auto"
                                        playing={false}
                                        controls={true}
                                        url={tempPropHolder.documentLink}
                                        scale={0.4}
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
        }
        else if (this.viewFlashcardsPanel) {
            let flashcardsArray = [];
            for (let j = 0; j < tempPropHolder.words.length; j++) {
                flashcardsArray.push(
                    <div key={'question-' + j} className="listNumQuestion">
                        <h4><span dir lang={this.props.auth.user.prefLanguage}>{tempPropHolder.words[j].translatedWord ? tempPropHolder.words[j].translatedWord : tempPropHolder.words[j].word}</span></h4>
                        <p><span dir lang={this.props.auth.user.prefLanguage}>{tempPropHolder.words[j].definition}</span></p>
                    </div>
                );
            }
            sideViewPanel = <div className="topRowHaedingAnch">
                <span className="scrollBar" />
                <div className="FrontQuestionBox">
                    <div className="questionBlock NiceScroll">
                        <h3 className="noteHeading">Flashcard Activity</h3>
                        {flashcardsArray}
                    </div>
                </div>
            </div>
        }

        let body =
            <div className="activityLogBlock DivShow">
                {sideViewPanel}
            </div>;

        return <Modal id="ActivityViewFromCourse"
            show={this.state.showViewActivityDialog}
            onHide={() => {
                this.setState({
                    showViewActivityDialog: false
                });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title>{this.state.viewActivityData.activityName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div id="questionPreviewBox">
                    {body}
                    <div className="row"></div>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    this.setState({
                        showViewActivityDialog: false
                    });
                }}>Ok</Button>
            </Modal.Footer>
        </Modal>;
    }
    getCreateCourseDialog() {
        const { errors } = this.state;
        let selectedSubject = this.state.selectedSubject;

        let body =
            <div className="mainFormBox">
                <div className="col-lg-12 col-md-12" style={{ width: '92%' }}>
                    <TextFieldGroup
                        label={'Course Name:'}
                        field="courseName"
                        error={errors.courseName}
                        value={this.props.manageCourses.courseObj.courseName}
                        placeholder="Enter the name for your course"
                        onChange={this.onChangeCreateCourse}
                    />
                    <div className="form-group">
                        <label htmlFor="selectUser">Description:</label>
                        <div className="posRel  selectWidth">
                            <textarea
                                className="form-control editTextArea textEditTop"
                                rows="3"
                                name="courseDesc"
                                onChange={this.onChangeCreateCourse}
                                placeholder="Enter a description for your course"
                                value={this.props.manageCourses.courseObj.courseDesc}
                                style={{ height: 'auto' }}
                            />
                        </div>
                    </div>

                    <SelectFieldGroup
                        error={errors.selectedSubject}
                        field="selectedSubject"
                        value={selectedSubject}
                        options={this.state.allSubjects}
                        placeholder="Associate with Subject"
                        onChange={this.onChangeCreateCourse}
                        label="Select Subject:"
                        toolTip="Selecting a subject groups this course with other courses within the selected subject.  This will help you to search for this course in the course listing window."
                    />

                </div>
                <div className="row" />
            </div>;
        return <Modal
            backdrop="static"
            keyboard={false}
            show={this.state.showCreateCourseDialogBox}
            onHide={() => {
                this.setState({
                    showCreateCourseDialogBox: false, isLandingPage: true
                });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title>Create Course</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    this.setState({ isLandingPage: true });
                    this.cancelCreateCourse();
                }} bsStyle="saveBtn">Cancel</Button>
                {<Button bsStyle='primary' onClick={() => {
                    this.createCourse();
                }}>Next</Button>}
            </Modal.Footer>
        </Modal>;
    }
    isValid() {

        let allRecords = this.getAllCourses() ? this.getAllCourses() : [];
        const { errors, isValid } = validateInput(this.props.manageCourses.courseObj, allRecords, this.props.auth.user.userName, this.props.manageCourses.isEditCourse);
        this.setState({ errors });
        return isValid;
    }
    getAllCourses() {
        return this.props.manageCourses.results;
    }
    cancelCreateCourse() {
        this.setState({ showCreateCourseDialogBox: false });
    }

    getAllSubjects() {
        let sortedSubjects = []
        this.props.auth.user.subjects.forEach(sub => {
            sortedSubjects.push({ "id": sub, "name": sub });
        })
        sortedSubjects.sort(function (a, b) {
            return a.id > b.id;
        });
        this.setState({ allSubjects: sortedSubjects });
    }

    showCreateCourseDialog() {
        this.getAllSubjects();
        const { dispatch } = this.props;
        ManageCourseActions.setCreateCourseData(dispatch, { units: [], selectedSubject: '', courseState: "In Progress", courseName: '' }, false, null);
        this.setState({ showCreateCourseDialogBox: true, selectedSubject: '', errors: {} });
    }

    render() {
        let showActions = true;

        let actionButtons = [];
        let backButton = null;
        let showCreateNewButton = true;

        if (this.state.isFromAssignment) {
            showCreateNewButton = false;
            showActions = false;
            backButton = {
                label: '< Back To Assignment', instructions: 'Choose a course to assign', action: () => {
                    browserHistory.push('/assignments');
                }
            };
            actionButtons = [
                {
                    linkLabel: 'View', action: (courseData, evt) => {
                        this.viewCourse(courseData);
                    }
                },
                {
                    linkLabel: 'Assign', action: (courseData, evt) => {
                        this.showIndicator();
                        let that = this;
                        apiClient.invokeApi({ courseID: courseData.id }, '/course/{courseID}', 'GET', {}, {}).then(function (result) {
                            that.hideIndicator();
                            browserHistory.push({
                                pathname: '/assignments/assign',
                                state: { isCourseAssignment: true, course: result.data, isCourseLoaded: true, view: 'course' }
                            });
                        });
                    }
                }
            ];
        }

        const { screenWidth } = this.state;
        const isMobile = screenWidth < 768;
        this.props.manageCourses.results.forEach(row => {
            switch (row.subject && row.subject.toLowerCase()) {
                case 'algebra 1':
                case 'algebra':
                    row.className = 'subAlgebra';
                    break;
                case 'u.s. history and government':
                case 'us history':
                case 'u.s. history':
                    row.className = 'subUSHistory';
                    break;
                case 'global history and geography':
                    row.className = 'subGlobHistory';
                    break;
                case 'cc geometry':
                    row.className = 'subGeometry';
                    break;
                case 'earth science':
                    row.className = 'subErthSci';
                    break;
                case 'living environment':
                    row.className = 'subLivEnvi';
                    break;
                case 'high school english':
                    row.className = 'highSchoolEnglish';
                    break;
                default:
                    row.className = 'customSubject';
                    break;
            }
        });
        let that = this;
        let esAttr = this.props.elasticSearchAttributes.map.get('courses');
        let maintenanceTableData = {
            emptyMessage: 'No records found.',
            showActionMenu: showActions,
            actionButtons: actionButtons,
            actions: [
                {
                    linkLabel: 'View', action: (courseData, evt) => {
                        this.viewCourse(courseData);
                    },
                    isConditional: false,
                },
                {
                    linkLabel: 'Edit', action: (selectedRecord) => {
                        this.editCourse(selectedRecord);
                    },
                    isConditional: true,
                    conditionExpression: (currentRecord) => {
                        if (currentRecord.sharedWith && currentRecord.sharedWith.length > 0) {
                            let index = currentRecord.sharedWith.findIndex(x => x.id === that.props.auth.user.userName);
                            if (index === -1) {
                                return (!isMobile && (
                                    (that.props.auth.user.userType.toLowerCase() === "admin" && that.props.auth.user.schoolName === "LearnerPal") ||
                                    (currentRecord.ownerName === that.props.auth.user.userName) ||
                                    (currentRecord.originalOwner === that.props.auth.user.userName) ||
                                    (this.props.auth.user.linkedAccounts && currentRecord.originalOwner === this.props.auth.user.linkedAccounts.learnerPal) ||
                                    (this.props.auth.user.linkedAccounts && currentRecord.ownerName === this.props.auth.user.linkedAccounts.learnerPal)
                                    &&
                                    (currentRecord.courseState && currentRecord.courseState.toLowerCase() !== 'published')
                                ));
                            }
                            else {
                                return (currentRecord.sharedWith[index].permission === 'WRITE')
                            }
                        }
                        else {
                            return (!isMobile && (
                                (that.props.auth.user.userType.toLowerCase() === "admin" && that.props.auth.user.schoolName === "LearnerPal") ||
                                (currentRecord.ownerName === that.props.auth.user.userName) ||
                                (currentRecord.originalOwner === that.props.auth.user.userName) ||
                                (this.props.auth.user.linkedAccounts && currentRecord.originalOwner === this.props.auth.user.linkedAccounts.learnerPal) ||
                                (this.props.auth.user.linkedAccounts && currentRecord.ownerName === this.props.auth.user.linkedAccounts.learnerPal) &&
                                (currentRecord.courseState && currentRecord.courseState.toLowerCase() !== 'published')
                            ));
                        }
                    }
                },
                {
                    linkLabel: 'Assign',
                    action: (selectedRecord) => {
                        browserHistory.push({
                            pathname: '/assignments/assign',
                            state: { isCourseAssignment: true, course: selectedRecord, view: 'course', goBackToManageCourse: true }
                        });
                    },
                    isConditional: false,
                },
                {
                    linkLabel: 'Duplicate', action: (selectedRecord, evt) => {
                        this.duplicateCourse(selectedRecord);
                    },
                    isConditional: false,
                },
                {
                    linkLabel: 'Share', action: (selectedRecord) => {
                        let that = this;
                        that.showIndicator();
                        apiClient.invokeApi({ courseID: selectedRecord.id }, '/course/{courseID}', 'GET', {}, {}).then(function (result) {
                            that.hideIndicator();
                            let tempShare = [];
                            let courseID = selectedRecord.id;
                            for (let i = 0; i < result.data.sharedWith.length; i++) {
                                let temp = result.data.sharedWith[i];
                                if (result.data.sharedWith[i].permission === 'WRITE' || result.data.sharedWith[i].permission === 'READ') {
                                    tempShare.push(temp);
                                }
                            }
                            that.setState({ fromManageCourse: true, course: result.data, viewCoursePageSuccess: true, allSharedWithTeachers: tempShare, sharedWith: tempShare, courseID: courseID }, that.shareCourse);
                        });
                    },
                    isConditional: true,
                    conditionExpression: (course) => {
                        return (
                            (this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName === "LearnerPal") ||
                            (course.originalOwner === this.props.auth.user.userName)
                            &&
                            (course.courseState && course.courseState.toLowerCase() !== 'published')
                        );
                    }
                },
                {
                    linkLabel: 'Student View', action: (selectedRecord) => {
                        browserHistory.push("/courses/preview/" + selectedRecord.id);
                    }
                    ,
                    isConditional: false,
                    conditionExpression: (currentRecord) => {
                        // return (currentRecord.ownerName === this.props.auth.user.userName ||
                        // this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName === "LearnerPal" ||
                        // currentRecord.originalOwner === this.props.auth.user.userName);
                    }
                },
                {
                    linkLabel: 'Delete', action: (selectedRecord) => {
                        this.showDeleteDialog(selectedRecord);
                    },
                    isConditional: true,
                    conditionExpression: (currentRecord) => {
                        return (currentRecord.ownerName === this.props.auth.user.userName ||
                            this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName === "LearnerPal" ||
                            (currentRecord.originalOwner === this.props.auth.user.userName) ||
                            (currentRecord.originalOwner === this.props.auth.user.userName) ||
                            (this.props.auth.user.linkedAccounts && currentRecord.originalOwner === this.props.auth.user.linkedAccounts.learnerPal) ||
                            (this.props.auth.user.linkedAccounts && currentRecord.ownerName === this.props.auth.user.linkedAccounts.learnerPal)
                        );
                    }
                },
            ],
            columns: [
                {
                    name: 'courseName', type: 'text', label: 'Course Name', options: {
                        sortable: true, customDom: (record) => { // customDom is not applicable to array type attributes, ex: subjects in activity.
                            return (
                                <td width="30%">
                                    <span className="typeIcon"> </span>
                                    <span className="contentType ">
                                        <div style={{ fontStyle: 'bold', color: "#0f6fb4", 'width': '100%', 'white-space': 'nowrap', 'overflow': 'hidden', 'text-overflow': 'ellipsis' }} title={record.courseName}><strong>{record.courseName}</strong></div>
                                        <p>By <strong>{record.ownerName}</strong></p>
                                    </span>
                                </td>
                            );
                        }
                    }
                },
                { name: 'description', type: 'text', label: 'Description', options: { sortable: false, width: '12', trimText: true, trimSize: 250 } },
                { name: 'subject', type: 'text', label: 'Subject', options: { sortable: true, width: '15%' } },
                {
                    name: 'createdAt', type: 'date', label: 'created On', options: {
                        sortable: true, customDom: (record) => {
                            let dateValue = new Date(parseFloat(record["createdAt"])).toLocaleDateString();
                            return (<td width="12%"><div title={dateValue} >{dateValue}</div></td>);
                        }
                    }
                }
            ],
            items: this.props.manageCourses.results,
            pageable: true,
            rowClickAction: this.viewCourseOnRowClick,
            totalRecords: this.props.manageCourses.totalRecords,
            pagination: {
                pageSize: esAttr && esAttr.pageSize ? esAttr.pageSize : 50,
                activePage: 1,
                showPageInput: true,
                showFirstButton: true,
                showLastButton: true,
                showNextButton: true,
                showPreviousButton: true,
                firstButtonLabel: '<<',
                lastButtonLabel: '>>',
                previousButtonLabel: '<',
                nextButtonLabel: '>',
                showPageInfo: true,
                showGotoInput: false,
                gotoInputLabel: 'Goto',
                pageLinkSize: 5
            }
        };
        let viewQuestionPage = null;
        let context = this;
        let sideViewPanel;
        if (this.state.viewCoursePage) {
            if (this.state.viewCoursePageSuccess) {
                let course = this.state.course;
                let allUnitsHtmlArray = [];
                let actionBlocks;
                if (course.units && course.units.length > 0) {
                    let tempUnits = course.units;
                    for (let i = 0; i < tempUnits.length; i++) {
                        let allActivitiesHtmlArray = [];
                        if (tempUnits[i].activities && tempUnits[i].activities.length > 0) {
                            let tempActivities = tempUnits[i].activities;
                            for (let j = 0; j < tempActivities.length; j++) {
                                switch (tempActivities[j].activityType.toLowerCase()) {
                                    case 'learning':
                                        tempActivities[j].className = ' col-lg-5 col-md-5 col-sm-6 col-xs-12 respWd30 learning';
                                        tempActivities[j].activityTypeDisplay = 'Learning';
                                        break;
                                    case 'flashcards':
                                    case 'flashcard':
                                        tempActivities[j].className = 'col-lg-5 col-md-5 col-sm-6 col-xs-12 respWd30 flashCard';
                                        tempActivities[j].activityTypeDisplay = 'Flashcards';
                                        break;
                                    case 'question':
                                        tempActivities[j].className = 'col-lg-5 col-md-5 col-sm-6 col-xs-12 respWd30 questionRow';
                                        tempActivities[j].activityTypeDisplay = 'Questions';
                                        tempActivities[j].totalQuestions = tempActivities[j].questions.length;
                                        break;
                                    default:
                                        tempActivities[j].className = 'col-lg-5 col-md-5 col-sm-6 col-xs-12 respWd30 learning';
                                        tempActivities[j].activityTypeDisplay = tempActivities[j].activityType.toUpperCase();
                                        break;
                                }
                                allActivitiesHtmlArray.push(
                                    <div className="row activityRow" >
                                        <div className={tempActivities[j].className} style={{ display: "inline-flex" }}>
                                            <span className="typeIcon" style={{ width: '20%' }} > </span>
                                            {
                                                tempActivities[j].activityType.toLowerCase() === 'question' ?
                                                    <span style={{ display: 'inline-block', position: 'relative', width: '80%' }}>
                                                        <p>{tempActivities[j].activityName}</p>
                                                        <p style={{ top: '15px', position: 'absolute', fontStyle: 'italic' }}>({tempActivities[j].totalQuestions} questions)
                                                        </p>
                                                    </span>
                                                    : <span style={{ display: 'inline-block', position: 'absolute', marginLeft: '75px' }}>
                                                        <p>{tempActivities[j].activityName}</p>
                                                    </span>
                                            }
                                        </div>
                                        <div className="col-lg-2  col-md-2 col-sm-3 col-xs-6  respWd70">
                                            <p className="lableValue">
                                                <span>{tempActivities[j].activityTypeDisplay}</span>
                                            </p>
                                        </div>
                                        {
                                            tempActivities[j].activityType.toLowerCase() === "question" && <div className="col-lg-3  col-md-3 col-sm-3 col-xs-8  respWd70">
                                                <p className="lableValue">
                                                    {tempActivities[j].activityType.toLowerCase() === "question" && <span>{tempActivities[j].questionActivityType === "practice" ? "Practice Activity" : "Assessment Activity"}</span>}
                                                </p>
                                            </div>
                                        }
                                        {
                                            tempActivities[j].activityType.toLowerCase() != "question" && <div className="col-lg-3  col-md-3 col-sm-3 col-xs-8  respWd70" style={{ 'height': '0px' }}>
                                                <p className="lableValue">
                                                </p>
                                            </div>
                                        }
                                        < div className="col-lg-2  col-md-2 col-sm-2 col-xs-4  respWd70" >
                                            <p className="lableValue pull-right">
                                                <a href="javascript:void 0" id={tempActivities[j].activityID} onClick={this.viewActivity} style={{
                                                    border: '1px solid',
                                                    borderRadius: '10px',
                                                    'padding': ' 0px', 'padding-left': '10px', 'padding-right': '10px'
                                                }}>View</a>
                                            </p>
                                        </div >
                                    </div >
                                );
                            }
                        }
                        allUnitsHtmlArray.push(
                            <div className="col-md-12">
                                <div className="panelBox">
                                    <h3>{tempUnits[i].unitName}</h3>
                                    <div className="row">
                                    </div>
                                    {allActivitiesHtmlArray}
                                </div>
                            </div>
                        );
                    }
                }

                if (!isMobile && (
                    (this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName === "LearnerPal") ||
                    (course.ownerName === this.props.auth.user.userName)
                    &&
                    (course.courseState && course.courseState.toLowerCase() !== 'published')
                )) {
                    actionBlocks = <div className="actionBlockDiv">
                        <ActionIcons iconType={ActionIconTypes.Share} action={function () {
                            context.shareCourse();
                        }} />
                        <ActionIcons iconType={ActionIconTypes.Edit} action={function () {
                            context.editCourse();
                        }} />
                        <ActionIcons iconType={ActionIconTypes.Duplicate} action={function () {
                            context.duplicateCourse();
                        }} />
                        <ActionIcons iconType={ActionIconTypes.Delete} action={function () {
                            context.showDeleteDialog();
                        }} />
                    </div>;
                } else if (isMobile && (course.ownerName === this.props.auth.user.userName ||
                    this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName === "LearnerPal")) {
                    actionBlocks = <div className="actionBlockDiv">
                        <ActionIcons iconType={ActionIconTypes.Share} action={function () {
                            context.shareCourse();
                        }} />
                        <ActionIcons iconType={ActionIconTypes.Duplicate} action={function () {
                            context.duplicateCourse();
                        }} />
                        <ActionIcons iconType={ActionIconTypes.Delete} action={function () {
                            context.showDeleteDialog();
                        }} />
                    </div>;
                } else if ((course.sharedWith)) {
                    let index = course.sharedWith.findIndex(x => x.id === this.props.auth.user.userName);
                    if (index === -1) {
                        actionBlocks = <div className="actionBlockDiv">
                            <ActionIcons iconType={ActionIconTypes.Duplicate} action={function () {
                                context.duplicateCourse();
                            }} />
                        </div>;
                    }
                    else {
                        if (course.sharedWith[index].permission === 'WRITE') {
                            actionBlocks = <div className="actionBlockDiv">
                                <ActionIcons iconType={ActionIconTypes.Duplicate} action={function () {
                                    context.duplicateCourse();
                                }} />
                                <ActionIcons iconType={ActionIconTypes.Edit} action={function () {
                                    context.editCourse();
                                }} />
                            </div>;
                        }
                        else {
                            actionBlocks = <div className="actionBlockDiv">
                                <ActionIcons iconType={ActionIconTypes.Duplicate} action={function () {
                                    context.duplicateCourse();
                                }} />
                            </div>;
                        }
                    }
                }
                else {
                    actionBlocks = <div className="actionBlockDiv">
                        <ActionIcons iconType={ActionIconTypes.Duplicate} action={function () {
                            context.duplicateCourse();
                        }} />
                    </div>;
                }
                viewQuestionPage = <div>
                    <Breadcrumb
                        breadcrumbsData={
                            [
                                {
                                    name: 'Manage Courses', action: () => {
                                        this.closeViewQuestionPage();
                                    }
                                },
                                {
                                    name: 'View Course', action: () => {
                                    }
                                }
                            ]
                        }
                    />
                    <div className="container-fluid grey-background CourseContainer">
                        <div className="CourseView">
                            <div className="row">
                                <div className="col-md-4 col-lg-4 col-sm-0">

                                </div>
                                <div className="col-md-8 col-lg-8 col-sm-12 text-right">
                                    <div className="respFixBot">
                                        {actionBlocks}
                                        <a href={"/courses/preview/" + course.courseID} target="_blank" className="courseBtnStd">Student View</a>
                                        <a href="javascript:void 0" className="courseBtnAssign" onClick={() => {
                                            browserHistory.push({
                                                pathname: '/assignments/assign',
                                                state: { goBackToManageCourse: true, isCourseAssignment: true, course: course, isCourseLoaded: true, view: 'course' }
                                            });
                                        }}>Assign</a>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-sm-4 courseInfo">
                                    <h1 style={{ color: '#0f6fb4' }}>{course.courseName}</h1>
                                    <h4 style={{ color: '#0f6fb4' }}>{course.subject}</h4>
                                    <p style={{ color: '#0f6fb4', 'padding-top': '0px' }}>{course.description}</p>
                                </div>
                                {allUnitsHtmlArray}
                            </div>
                        </div>
                    </div>
                </div>
            } else {
                viewQuestionPage = <div style={{ marginLeft: "30px" }}>
                    <h3>Loading... Please wait</h3>
                </div>
            }
        }
        else if (this.state.viewHelpText) {
            sideViewPanel = <div className="topRowHaedingAnch">
                <span className="scrollBar" />
                <div className="FrontQuestionBox">
                    <div className="questionBlock NiceScroll">
                        <span className="cross" onClick={this.closeHelpText} />
                        <div className="infoIcon" style={{
                            width: '25' +
                                '0px',
                            position: 'relative',
                            marginRight: '100px',
                            float: 'left'
                        }}>
                            <div className="booksRow" style={{ float: 'left' }}>
                                <span className="typeIcon" />
                            </div>
                            <h1>Manage Courses</h1>
                        </div>
                    </div>
                    <div style={{ marginTop: '30px' }}>
                        <div className="DropDivBlock" style={{ display: 'inline' }}>
                            <span className="bullets" />
                            <span className="bullets" />
                            <span className="bullets" />
                        </div>
                        <div className="listNumQuestion" style={{ marginLeft: '20px', display: 'inline' }}>
                            <h3 style={{ color: '#337ab7' }}>View Course</h3>
                            <p style={{ margin: '0px 44px' }}>View your courses, or courses that were shared with you</p>
                        </div>
                    </div>
                    <div style={{ marginTop: '7px' }}>
                        <div className="DropDivBlock" style={{ display: 'inline' }}>
                            <span className="bullets" />
                            <span className="bullets" />
                            <span className="bullets" />
                        </div>
                        <div className="listNumQuestion" style={{ marginLeft: '20px', display: 'inline' }}>
                            <h3 style={{ color: '#337ab7' }}>Edit Courses</h3>
                            <p style={{ margin: '0px 44px' }}>Modify your course -add, remove, reorder units/activities</p>
                        </div>
                    </div>
                    <div style={{ marginTop: '7px' }}>
                        <div className="DropDivBlock" style={{ display: 'inline' }}>
                            <span className="bullets" />
                            <span className="bullets" />
                            <span className="bullets" />
                        </div>
                        <div className="listNumQuestion" style={{ marginLeft: '20px', display: 'inline' }}>
                            <h3 style={{ color: '#337ab7' }}>Assign Courses</h3>
                            <p style={{ margin: '0px 44px' }}>Assign a course, or a par of a course to your class/students</p>
                        </div>
                    </div>
                    <div style={{ marginTop: '7px' }}>
                        <div className="DropDivBlock" style={{ display: 'inline' }}>
                            <span className="bullets" />
                            <span className="bullets" />
                            <span className="bullets" />
                        </div>
                        <div className="listNumQuestion" style={{ marginLeft: '20px', display: 'inline' }}>
                            <h3 style={{ color: '#337ab7' }}>Duplicate Activities</h3>
                            <p style={{ margin: '0px 44px' }}>Copy a course and do "save as" -make changes to new course and keep the original</p>
                        </div>
                    </div>
                </div>
            </div>
        }

        let visibility = '(public OR "' + this.props.auth.user.userName + '"';
        if (this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName === "LearnerPal") {
            visibility += " OR LPAdmin";
        }
        if ((this.props.auth.user.linkedAccounts && this.props.auth.user.linkedAccounts.learnerPal) && this.props.auth.user.federatedIdentity) {
            visibility += ` OR \"${this.props.auth.user.linkedAccounts.learnerPal}\"`
        }
        visibility += ')';
        let subjectsToSearch = "(";
        if (this.props.auth.user.subjects) {
            this.props.auth.user.subjects.forEach(sub => {
                subjectsToSearch += "\"" + sub + "\" OR ";
            })
        }
        subjectsToSearch = subjectsToSearch.substring(0, subjectsToSearch.length - 3);
        let query = "visibleTo : " + visibility + " AND subject : " + subjectsToSearch + ")";


        let styleForMobileVIew = {};
        if (isMobile) {
            styleForMobileVIew = { "width": "100%", "height": "calc(100% - 100px)" };
        }
        let icon = icon2;
        if(this.props.appSettings.theme === 'stemLabs'){
            icon = stemIcon2;
        } else if(this.props.appSettings.theme === 'virtual'){
            icon = virtualIcon2;
        }
        return (
            <div>
                {
                    this.state.isLandingPage &&
                    <div>
                        <Heading
                            showFilterButtons="false"
                            heading="Courses"
                        />
                        <div className="col-md-2 hidden-xs hidden-sm"></div>
                        <div className="mainFormBox col-md-8 col-sm-12 col-xs-12" style={{ margin: '15px 0px 80px' }}>
                            <div className="row">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <h1 style={{ textAlign: 'center', marginTop: '-5px' }}>
                                        <strong style={{ fontSize: 'x-large' }}>Course Management</strong>
                                    </h1>
                                    <div style={{ textAlign: 'center', fontStyle: 'italic' }}>
                                        <strong style={{ fontSize: '14px' }}>(Organized, Structured Bundles of Online Activities)</strong>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                            </div>
                            <div className="row">
                                <div className="col-md-1 hidden-sm hidden-xs"></div>
                                <div className="col-md-10 col-sm-12 col-xs-12">
                                    <div className="mainFormBox" style={{ minHeight: '90px', cursor: 'pointer' }} onClick={() => {
                                        this.setState({ isLandingPage: false });
                                    }}>
                                        <div className="" style={{
                                            width: '0px',
                                            position: 'relative',
                                            marginRight: '75px',
                                            marginTop: '-7px',
                                            float: 'left'
                                        }}>
                                            <img src={icon} style={{ height: '65px' }} />
                                        </div>
                                        <strong style={{ fontSize: 'larger' }}>Manage Courses</strong>
                                        <div>View, edit, duplicate, share or assign LearnerPal stock courses or your custom courses</div>
                                    </div>
                                </div>
                                <div className="col-md-1 hidden-sm hidden-xs"></div>
                            </div>
                            <div className="row">
                                <div className="col-md-1 hidden-sm hidden-xs"></div>
                                <div className="col-md-10 col-sm-12 col-xs-12">
                                    <div className="mainFormBox" style={{ minHeight: '90px', cursor: 'pointer' }} onClick={() => {
                                        this.setState({ isLandingPage: false });
                                        this.showCreateCourseDialog();
                                    }}>
                                        <div style={{ float: 'left', marginTop: '-7px', marginRight: "12px" }}><span style={{ color: '#55a4f0' }} className="custom-color-icon" ><MaterialIcon icon='note_add' size={60} /></span></div>
                                        <strong style={{ fontSize: 'larger' }}>Create a New Course</strong>
                                        <div>Create and build a custom-personalized course</div>
                                    </div>
                                </div>
                                <div className="col-md-1 hidden-sm hidden-xs"></div>
                            </div>
                        </div>
                        <div className="col-md-2 hidden-xs hidden-sm"></div>

                    </div>
                }
                {
                    !this.state.isLandingPage &&
                    <div>
                        <Heading
                            location="courses"
                            actionName={RECEIVED_COURSE_RESULTS}
                            isHideHeading={this.state.viewCoursePage}
                            allButtons={[
                                <button type="button" className="btn btn-primary crtBtn"
                                    onClick={this.showCreateCourseDialog}
                                    key="CreateButton"><span> </span> Create New</button>
                            ]}
                            isCreateButton={showCreateNewButton}
                            heading="Manage Courses"
                            isShowButton={true}
                            isSearch={true}
                            backButton={backButton}
                            screenNameForElasticSearch={ElasticSearchTypes.Course}
                            allColumnsForElasticSearch={ElasticSearchColumns.Course}
                            customQuery={query}
                            breadcrumbsData={
                                [
                                    {
                                        name: 'Courses', action: () => {
                                            this.setState({ isLandingPage: true });
                                        }
                                    },
                                    {
                                        name: 'Manage Courses', action: () => {
                                        }
                                    }
                                ]
                            }
                        />
                        <div id="tableFiltersAndSideView">
                            <div className="container-fluid noPad listMainBoxWrapper">
                                <Filter
                                    customQuery={query}
                                    actionName={RECEIVED_COURSE_RESULTS}
                                    viewActivityCssClass={this.filterComponentCssClassName}
                                    clearFilters={'manageCourses.clearFilters'}
                                    filterColumns={[
                                        { name: 'subject', type: 'text', label: 'Subjects' },
                                        { name: 'ownerName', type: 'text', label: 'Owner Name' }
                                    ]}
                                    screenNameForElasticSearch={ElasticSearchTypes.Course}
                                    allColumnsForElasticSearch={ElasticSearchColumns.Course}
                                    location="courses"
                                />
                                <div className={this.tableCssClassName}>
                                    <div className="listToBesslide NiceScroll">
                                        <div className="blockForlist ">
                                            <MaintenanceTable
                                                customQuery={query}
                                                location="courses"
                                                actionName={RECEIVED_COURSE_RESULTS}
                                                screenNameForElasticSearch={ElasticSearchTypes.Course}
                                                allColumnsForElasticSearch={ElasticSearchColumns.Course}
                                                options={maintenanceTableData}
                                                sortAction={{
                                                    action: RECEIVED_COURSE_RESULTS,
                                                    attributes: {
                                                        skipAlterForAllRecords: true
                                                    },
                                                    dataPath: 'data'
                                                }}
                                            />
                                            <CourseMaintenanceTableMobile items={this.props.manageCourses.results}
                                                emptyMessage={maintenanceTableData.emptyMessage}
                                                columns={maintenanceTableData.columns}
                                                viewCourseMobile={this.viewCourse}
                                            />
                                            <ReactScrollPagination
                                                fetchFunc={this.getNextPage}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={this.viewActivityCssClassName} id='sidePanelContainer'
                                style={styleForMobileVIew}>
                                {sideViewPanel}
                            </div>
                        </div>
                    </div>
                }
                <div>
                    {viewQuestionPage}
                </div>
                {this.state.showDeleteDialog && this.getDeleteDialog()}
                {this.state.showDuplicateDialog && this.getDuplicateDialog()}
                {this.state.showShareCourseDialog && this.getShareCourseDialog()}
                <ProgressIndicator show={this.state.loading} />
                {((this.state.showAlert && !this.state.alertDisplayed) &&
                    <div className="row">
                        <div className="col-md-3 col-xs-3" />
                        <div className="col-md-6 col-xs-3">
                            <Alert bsStyle={this.state.alertStyle}
                                onDismiss={this.handleAlertDismiss}>
                                {this.state.alertText}
                            </Alert>
                        </div>
                        <div className="col-md-3 col-xs-3" />
                    </div>) || ((this.props.manageCourses.showAlert) &&
                        <div className="row">
                            <div className="col-md-3 col-xs-3" />
                            <div className="col-md-6 col-xs-3">
                                <Alert bsStyle={this.props.manageCourses.alertStyle}
                                    onDismiss={this.handleAlertDismiss}>
                                    {this.props.manageCourses.alertText}
                                </Alert>
                            </div>
                            <div className="col-md-3 col-xs-3" />
                        </div>)
                }
                {this.state.showViewActivityDialog && this.getViewActivityDialog()}
                {this.state.showEditLockDialog && this.getEditCourseConfirmationDialog()}
                {this.state.showCreateCourseDialogBox && this.getCreateCourseDialog()}
            </div>
        );
    }
}


function mapStateToProps(state) {
    return { ...state };
}

export default connect(mapStateToProps)(ManageCourses);
