import React, {Component} from 'react';
import Utility from './utility';
export default class TableHeaderColumn extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let columnHeader = null;
        let showCaret = false;
        if (this.props.header.label) {
            columnHeader = Utility.ucFirst(this.props.header.label);
            showCaret = true;
        } else {
            columnHeader = Utility.ucFirst(this.props.header.name);
        }
        let header = null;
        if (this.props.header.options.sortable) {
            let dataType = null;
            let dynamoTyped = false;
            let name = this.props.header.name;
            let type = this.props.header.type;
            if (this.props.header.type.toLowerCase() === 'array') {
                // expect dataType in options. if column type is array then dataType in options is a must.
                dataType = this.props.header.options.dataType;
                dynamoTyped = this.props.header.options.dynamoTyped;
            }
            let sortRecords = this.props.sortRecords;
            header = <th onClick={
                function (evt) {
                    let targetNode;
                    if (evt.target.nodeName.toLowerCase() === 'span') {
                        targetNode = evt.target.parentElement;
                    } else {
                        targetNode = evt.target;
                    }
                    if (!targetNode.hasAttribute('sortOrder')) {
                        targetNode.setAttribute('sortOrder', 'asc');
                        targetNode.setAttribute('class', 'dropup');
                    } else {
                        targetNode.getAttribute('sortOrder') === 'asc' ? targetNode.setAttribute('class', 'dropup') : targetNode.setAttribute('class', 'dropdown');
                    }

                    sortRecords(evt, evt.target.getAttribute('sortOrder'), name, type, dataType, dynamoTyped);
                }
            }>{columnHeader} {showCaret && <span className="caret caret-close"/>}</th>;
        } else {
            header = <th>{columnHeader}</th>;
        }
        return (
            header
        );
    }
}