import {
    SEARCH_RESULT,
    FETCH_ALL_STUDENT_ASSIGNMENTS,
    FETCH_STUDENT_ASSIGNMENT_FAILED,
    RECEIVED_STUDENT_ASSIGNMENT_RESULTS,
    RECEIVED_STUDENT_GLOSSARY_RESULTS,
    RECEIVED_STUDENT_GLOSSARY_WORDS_RESULTS,
    RECEIVED_STUDENT_ASSIGNMENT_DASHBOARD_RESULTS,
    RECEIVED_STUDENT_GLOSSARY_OVERVIEW_RESULT
} from "../actions/types";

export default function studentPortal(state = {assignments:{results: []}, glossary:{results: []}, words:{results:[]}, dashboard:{results:[]}}, action) {
    switch (action.type) {
        case SEARCH_RESULT:
            return {
                ...state,
                assignments: {
                    ...state.assignments,
                    results: action.results,
                    searchResults: action.results,
                    clearFilters: true
                }
            };
        case FETCH_ALL_STUDENT_ASSIGNMENTS:
            return {
                ...state,
                assignments: {
                    isFetching: true,
                    results: []
                }
            };
        case FETCH_STUDENT_ASSIGNMENT_FAILED:
            return {
                ...state,
                assignments: {
                    isFetching: true,
                    results: []
                }
            };
        case RECEIVED_STUDENT_ASSIGNMENT_RESULTS:
            if (action.skipAlterForAllRecords) {
                return {
                    ...state,
                    assignments: {
                        ...state.assignments,
                        isFetching: false,
                        results: action.data,
                        filteredRecords: action.data,
                        totalRecords:action.totalRecords
                    }
                };
            } else {
                return {
                    ...state,
                    assignments: {
                        isFetching: false,
                        results: action.data,
                        allRecords: action.data,
                        filteredRecords: action.data,
                        searchResults: action.data,
                        totalRecords:action.totalRecords
                    }
                };
            }
        case RECEIVED_STUDENT_GLOSSARY_RESULTS:
            return {
                ...state,
                glossary: {
                    ...state.glossary,
                    isFetching: false,
                    results: action.data
                }
            };
        case RECEIVED_STUDENT_GLOSSARY_WORDS_RESULTS:
            return {
                ...state,
                words: {
                    ...state.words,
                    isFetching: false,
                    results: action.data,
                    totalRecords:action.totalRecords
                }
            };
        case RECEIVED_STUDENT_GLOSSARY_OVERVIEW_RESULT:
            
            return {
                ...state,
                words: {
                    ...state.words,
                    allFlashcards: action.data
                }
            };
        case RECEIVED_STUDENT_ASSIGNMENT_DASHBOARD_RESULTS:
                return {
                    ...state,
                    dashboard: {
                        ...state.dashboard,
                        isFetching: false,
                        results: action.data,
                        totalRecords: action.totalRecords
                    }
                };
        default:
            return state
    }
};
