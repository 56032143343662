/**
 * Created by ashahab on 8/18/2017.
 */
import React, {Component} from "react";
import {connect} from "react-redux";
import {Alert, Modal, Button} from "react-bootstrap";
import "./custom.css"
import MaintenanceTable from "../../maintenance-table/maintenance-table";
import Filter from "../../filter/filter";
import ProgressIndicator from "../../progress-indicator/progress-indicator";
import Heading from "../../heading/heading";
import {RECEIVED_STUDENT_GLOSSARY_RESULTS,ElasticSearchTypes, ElasticSearchColumns,RECEIVED_STUDENT_GLOSSARY_WORDS_RESULTS} from "../../../actions/types";
import classnames from "classnames";
import $ from "jquery";
import {browserHistory} from "react-router";
import ClevertapReact from "clevertap-react";
import ElasticSearchQuery from "../../../actions/elastic-search-query";
import ElasticSearchActions from "../../../actions/elastic-search-actions";
import config from "../../../config"
import LoginUtils from "../../common/login-utils";
import SelectFieldGroup from "../../forms/select-field-group";
import TextFieldGroup from "../../forms/text-field-group";
import TextAreaFieldGroup from "../../forms/text-area-field-group";
import apiClient from "../../../actions/api-client";

class GlossaryListing extends Component {
    constructor(props) {
        super(props);

        this.changeScreen = this.changeScreen.bind(this);
        this.createNewWord = this.createNewWord.bind(this);
        this.onChange = this.onChange.bind(this);
        this.getAllSubjects = this.getAllSubjects.bind(this);
        this.getDeleteDialog = this.getDeleteDialog.bind(this);
        this.deleteWord = this.deleteWord.bind(this);
        this.showDeleteDialog = this.showDeleteDialog.bind(this);
        this.saveWord = this.saveWord.bind(this);

        this.state = {screenWidth: window.innerWidth,loadingNextPage: 0};
        this.filterComponentCssClassName = 'filterBlock NiceScroll';
        this.tableCssClassName = 'listViewBlock ';

        this.filterColumns = [
            {name: 'category', type: 'text', label: 'Category'}
        ];

        this.state = {allFlashcards:[], showCreateEditDialog:false, errors: {}, isEditVocabulary:false, showDeleteDialog:false, currentRecord:null, saveAndAddMore:false}

        this.query = '';
    }

    getAllSubjects() {
        let context = this;
        context.showIndicator();
        let schoolName = this.props.auth.user.schoolName ? this.props.auth.user.schoolName : 'trial';
        let params = {
            schoolName: schoolName,
            ownerName: this.props.auth.user.userName,
        };
        let pathTemplate = '/subjects/ownerName/{ownerName}/schoolName/{schoolName}';
        let method = 'GET';
        let additionalParams = {};
        let body = {};
        apiClient.invokeApi(params, pathTemplate, method, additionalParams, body).then(function (result) {
            let sortedSubjects = [];
            let { subjects } = context.props.auth.user;
            result.data.forEach(x => {
                let subjectName = x.name;
                if (!subjects.find(y => y == subjectName))
                    return;
                let cat = [{id: 'My Words', name: 'My Words'}];
                cat.push(...x.categories.map(obj=>{ return {id: obj.category, name: obj.category}}));
                sortedSubjects.push({ "id": x.id, "name": subjectName, categories: cat });
            });
    
            sortedSubjects.sort(function (a, b) {
                return a.id.localeCompare(b.id, undefined, {
                    numeric: true,
                    sensitivity: 'base'
                });
            });
            context.setState({allSubjects: sortedSubjects, loading: false});
        }).catch(function (error) {
            context.hideIndicator();
        });
    }

    componentDidMount()
    {
        LoginUtils.isAuthenticated(this.props, browserHistory, '/student/glossary', '/');
        this.getAllSubjects();
        const {dispatch} = this.props;
        let context = this;
        ElasticSearchActions.setElasticSearchAttributes(dispatch,"studentGlossaryListing",'', 10, 1 ,{_score:{order: "desc"}}, []);

        let elasticSearchQuery  = new ElasticSearchQuery();

        const isLPAdmin = this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName === "LearnerPal";
        let visibility = '(public OR "' + this.props.auth.user.userName + '"';
        visibility += ')';
        this.query = "visibleTo : "+visibility;
        if(isLPAdmin){
            this.query='visibleTo : *';
        }

        if (this.props.location.state)
        {
            this.setState({...this.props.location.state});
            elasticSearchQuery.query(ElasticSearchTypes.StudentGlossary+config.stage, ElasticSearchColumns.StudentGlossary, this.props,
                '', 10, 1 ,{_score:{order: "desc"}}, [{"terms":{"subjects":[this.props.location.state.selectedSubject]}},{"terms":{"category":[this.props.location.state.selectedCategory]}}], function (results, totalRecords) {

                    dispatch({
                        type: RECEIVED_STUDENT_GLOSSARY_WORDS_RESULTS,
                        data: JSON.parse(JSON.stringify(results)),
                        totalRecords:totalRecords
                    });
                }, true, context.query);
        }
        else
        {
            elasticSearchQuery.query(ElasticSearchTypes.StudentGlossary+config.stage, ElasticSearchColumns.StudentGlossary, this.props,
                '', 10, 1 ,{_score:{order: "desc"}}, [], function (results, totalRecords) {

                    dispatch({
                        type: RECEIVED_STUDENT_GLOSSARY_WORDS_RESULTS,
                        data: JSON.parse(JSON.stringify(results)),
                        totalRecords:totalRecords
                    });
                }, true, context.query);
        }
    }

    changeScreen(evt)
    {

        if (evt.target.id === "overview")
        {
            let selector = '#' + 'screen2';
            $(selector).addClass('removeDisplay');
            let selector1 = '#' + 'screen1';
            $(selector1).removeClass('removeDisplay');

            let selector2 = '#' + 'tab1';
            $(selector2).addClass('active');
            let selector3 = '#' + 'tab2';
            $(selector3).removeClass('active');
        }
        else
        {
            let selector = '#' + 'screen1';
            $(selector).addClass('removeDisplay');
            let selector1 = '#' + 'screen2';
            $(selector1).removeClass('removeDisplay');

            let selector2 = '#' + 'tab2';
            $(selector2).addClass('active');
            let selector3 = '#' + 'tab1';
            $(selector3).removeClass('active');
        }
    }

    onChange(e) {
        this.setState({[e.target.name] : e.target.value});
        if(e.target.name == 'subject'){
            let allSubjects = this.state.allSubjects;
            const sub = allSubjects.find(obj=> obj.id == e.target.value);
            if(sub.categories){
                this.setState({categoriesOptions: sub.categories})
            } else {
              this.setState({ categoriesOptions: [] });
            }
        }
    }
    createNewWord() {

        this.setState({showCreateEditDialog: true,isEditVocabulary:false, subject:'', definition:'', word:''});
    }

    getCreateWordDialog() {
        let title = this.state.saveAndAddMore ? "word successfully added, add another": "Create Word";
        let buttonName = "Save and Exit";
        let allSubjects = this.state.allSubjects;
        let errors = this.state.errors;
        const isLPAdmin = this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName === "LearnerPal";
        return <Modal
            show={this.state.showCreateEditDialog}
            onHide={()=> {
                this.setState({showCreateEditDialog: false, errors:{}});
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="mainFormBox">
                    <div className="col-lg-12 col-md-12">
                        <SelectFieldGroup
                            dropDownId="subjectDropDownId"
                            error={errors.subject}
                            label="Subject:"
                            field="subject"
                            options={allSubjects}
                            value={this.state.subject}
                            placeholder="Associate with Subjects"
                            onChange={this.onChange}
                        />
                        {isLPAdmin && (<SelectFieldGroup
                            isDisabled={!this.state.subject}
                            dropDownId="categoryDropDownId"
                            error={errors.category}
                            label="Category:"
                            field="category"
                            options={this.state.categoriesOptions}
                            value={this.state.category }
                            placeholder="Select Category"
                            onChange={this.onChange}
                        />)}
                        <TextFieldGroup
                            isDisabled={this.state.isEditVocabulary}
                            error={errors.word}
                            label="Flashcard Word:"
                            field="word"
                            value={this.state.word}
                            placeholder="Enter a Word."
                            toolTip="Please enter a Word."
                            onChange={this.onChange}
                        />
                        <p style={{color:'maroon'}}>Words can be viewed when creating a new Flashcard Deck or within the Vocabulary page "My Words"</p>
                        <TextAreaFieldGroup
                            error={errors.definition}
                            label="Flashcard Definition:"
                            field="definition"
                            value={this.state.definition}
                            placeholder="Enter a definition for the word."
                            toolTip="Please enter a definition for the word."
                            onChange={this.onChange}
                        />
                    </div>
                    <div className="row"/>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={()=> {
                    this.setState({showCreateEditDialog: false, errors:{}});
                }}>Cancel</Button>
                <Button bsStyle='primary' onClick={()=>{ this.saveWord();this.setState({saveAndAddMore:false})}}>{buttonName}</Button>
                <Button bsStyle='primary' onClick={()=>{ this.saveWord();this.setState({saveAndAddMore:true})}}>Save and Add More</Button>
            </Modal.Footer>
        </Modal>
    }

    showDeleteDialog(currentRecord)
    {
        this.setState({currentRecord:currentRecord, showDeleteDialog:true});
    }

    getDeleteDialog() {
        let body = <div id="deleteDialogContent">
            <p>
                Are you sure you want to delete the word?
            </p>
        </div>;
        let that = this;
        return <Modal
            backdrop="static"
            show={this.state.showDeleteDialog}
            onHide={()=> {
                this.setState({showDeleteDialog: false, currentRecord: null});
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Delete Word</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={()=> {
                    that.setState({showDeleteDialog: false, currentRecord: null});
                }}>Cancel</Button>
                <Button onClick={()=> {
                    that.deleteWord(that.state.currentRecord);
                }} bsStyle='primary'>Delete</Button>
            </Modal.Footer>
        </Modal>;
    }

    deleteWord(currentRecord)
    {
        this.showIndicator();
        let context = this;
        const {dispatch} = this.props;
        let elasticSearchQuery  = new ElasticSearchQuery();

        apiClient.invokeApi({}, '/word/'+currentRecord.word+'/ownername/'+this.props.auth.user.userName, 'DELETE', {}, {}, '4').then(function (result) {

            ClevertapReact.event("Delete Word", {'userName' : context.props.auth.user.userName});
            context.setState({loading: false, showAlert:true, alertText:result.data, alertStyle:'success', showDeleteDialog:false});
            setTimeout(()=>{
                elasticSearchQuery.query(ElasticSearchTypes.StudentGlossary+config.stage, ElasticSearchColumns.StudentGlossary, context.props,
                    '', 10, 1 ,{_score:{order: "desc"}}, [], function (results, totalRecords) {

                        dispatch({
                            type: RECEIVED_STUDENT_GLOSSARY_WORDS_RESULTS,
                            data: JSON.parse(JSON.stringify(results)),
                            totalRecords:totalRecords
                        });
                    }, true, context.query);
            }, 1000);
            setTimeout(()=>{
                context.setState({showAlert: false});
            }, 5000);
        }).catch(function (error) {

            context.hideIndicator();
        });
    }

    saveWord() {

        let context = this;
        const {dispatch} = this.props;
        let elasticSearchQuery  = new ElasticSearchQuery();
        const isLPAdmin = this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName === "LearnerPal";

        let error = {};
        let payload = {};
        if (!context.state.word) {
            error.word = 'Word is required to create a flashcard.';
        }
        if (!context.state.definition) {
            error.definition = 'Definition is required to create a flashcard.';
        }
        if (!context.state.subject) {
            error.subject = 'Subject is required to create a flashcard.';
        }
        if (JSON.stringify(error).replace('{}', "")) {
            context.setState({errors: error});
        } else {
            context.setState({showCreateEditDialog:false, errors:{}});
            payload.definition = context.state.definition;
            payload.subjects = context.state.subject;
            payload.ownerName = context.props.auth.user.userName;
            payload.word = context.state.word;
            payload.category = isLPAdmin && context.state.category ? context.state.category: 'My Words';
            if(isLPAdmin){
                payload.learnerPalOwner = 'Public';
            }
            context.showIndicator();
            let pathTemplate = this.state.isEditVocabulary ? '/word/update' : '/word';
            let method = this.state.isEditVocabulary ? 'PUT' : 'POST';
            let serverless = this.state.isEditVocabulary ? '4' : '3';
            apiClient.invokeApi({}, pathTemplate, method, {}, payload, serverless).then(function (result) {
                if (context.state.isEditVocabulary)
                {
                    ClevertapReact.event("Update Word", {'userName' : context.props.auth.user.userName});
                }
                else
                {
                    ClevertapReact.event("Create Create", {'userName' : context.props.auth.user.userName});
                }

                context.hideIndicator();
                if (context.state.saveAndAddMore)
                {
                    context.setState({showCreateEditDialog:true, definition:'', word:'', subject:'', errors:{}});
                }
                else
                {
                    context.setState({loading: false, showAlert:true, alertText:result.data, alertStyle:'success', definition:'', word:'', subject:'', errors:{}});
                    setTimeout(()=>{
                        context.setState({showAlert: false});
                    }, 5000);
                }
                setTimeout(()=>{
                    elasticSearchQuery.query(ElasticSearchTypes.StudentGlossary+config.stage, ElasticSearchColumns.StudentGlossary, context.props,
                        '', 10, 1 ,{_score:{order: "desc"}}, [], function (results, totalRecords) {

                            dispatch({
                                type: RECEIVED_STUDENT_GLOSSARY_WORDS_RESULTS,
                                data: JSON.parse(JSON.stringify(results)),
                                totalRecords:totalRecords
                            });
                        }, true, context.query);
                }, 1000);
            }).catch(function (error) {

                context.hideIndicator();
            });
        }
    }

    editVocabulary(selectedRecord)
    {
        this.setState({isEditVocabulary:true, showCreateEditDialog:true, definition:selectedRecord.definition, word:selectedRecord.word, subject:selectedRecord.subjects[0]});
    }

    render() {
        let allFlashcards = this.state.allFlashcards;
        let allFlashcardsHtml = [];
        if (allFlashcards.length > 0)
        {
            for (let i=0; i<allFlashcards.length; i++)
            {
                allFlashcardsHtml.push(
                    <div key={'boxes'+i} className="col-lg-3 col-md-3 col-sm-4 col-xs-12  ">
                        <div className="pacboxcol collghtbg">
                            <div className="limitFilters" style={{margin:'0px'}}>
                                <span className="facesmile"/>
                                <span className="contentTypefd">
                                  <span className="headsp ">Launch Flashcards <span className="caretss caret-close"/></span>
                                </span>
                            </div>
                            <h2 style={{marginTop: '10px'}}>
                                {allFlashcards[i].name}
                            </h2>
                            <span style={{fontStyle: 'italics'}}>{allFlashcards[i].count} Words</span>
                            <div style={{clear: 'both'}}/>
                        </div>
                    </div>
                );
            }
        }
        let esAttr = this.props.elasticSearchAttributes.map.get('studentGlossaryListing');
        let maintenanceTableData = {
            emptyMessage: 'No records found.',
            showActionMenu: true,
            actions: [
                {
                    linkLabel: 'Edit', action: (selectedRecord) => {
                    this.editVocabulary(selectedRecord, false)
                },
                    isConditional: true,
                    conditionExpression: (currentRecord) => {
                        return (currentRecord.originalOwner === this.props.auth.user.userName);
                    }
                },
                {
                    linkLabel: 'Delete', action: (selectedRecord) => {
                    this.showDeleteDialog(selectedRecord);
                },
                    isConditional: true,
                    conditionExpression: (currentRecord) => {
                        return (currentRecord.originalOwner === this.props.auth.user.userName);
                    }
                },
            ],
            columns: [
                {name: 'word', type: 'text', label: 'Words', options: {sortable: true, customDom: (record) => { // customDom is not applicable to array type attributes, ex: subjects in activity.
                    return (
                        <td width="15 %">
                            <p style={{color:'green'}}><span dir lang = {this.props.auth.user.prefLanguage}>{record.translatedWord ? record.translatedWord : record.word}</span></p>
                        </td>
                    );
                }}},
                {name: 'definition', type: 'text', label: 'Definition', options:{sortable: true, customDom: (record) => { // customDom is not applicable to array type attributes, ex: subjects in activity.
                    return (
                        <td width="70%">
                            <p><span dir lang = {this.props.auth.user.prefLanguage}>{record.definition}</span></p>
                        </td>
                    );
                }}}
            ],
            items: this.props.studentPortal.words.results,
            pageable: true,
            rowClickAction: this.viewCourseOnRowClick,
            totalRecords: this.props.studentPortal.words.totalRecords,
            pagination: {
                pageSize: esAttr && esAttr.pageSize?esAttr.pageSize:10,
                activePage: 1,
                showPageInput: true,
                showFirstButton: true,
                showLastButton: true,
                showNextButton: true,
                showPreviousButton: true,
                firstButtonLabel: '<<',
                lastButtonLabel: '>>',
                previousButtonLabel: '<',
                nextButtonLabel: '>',
                showPageInfo: true,
                showGotoInput: false,
                gotoInputLabel: 'Goto',
                pageLinkSize:5
            }
        };
        const isLPAdmin = this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName === "LearnerPal";
        let visibility = '(public OR "' + this.props.auth.user.userName + '"';
        visibility += ')';
        let query = "visibleTo : "+visibility;
        if(isLPAdmin){
            query='visibleTo : *';
        }


        return (
            <div>
                <Heading
                    customQuery={query}
                    isOverRideStage={true}
                    location = "studentGlossaryListing"
                    actionName = {RECEIVED_STUDENT_GLOSSARY_WORDS_RESULTS}
                    heading="Vocabulary"
                    isSearch={true}
                    screenNameForElasticSearch = {ElasticSearchTypes.StudentGlossary}
                    allColumnsForElasticSearch = {ElasticSearchColumns.StudentGlossary}
                />
                {this.state.showAlert &&
                <div className="row">
                    <div className="col-md-3 col-xs-3"/>
                    <div className="col-md-6 col-xs-3">
                        <Alert bsStyle={this.state.alertStyle}
                               onDismiss={this.handleAlertDismiss}>
                            {this.state.alertText}
                        </Alert>
                    </div>
                    <div className="col-md-3 col-xs-3"/>
                </div>
                }
                <div id="tableFiltersAndSideView">
                    <div className={classnames("container-fluid noPad listMainBoxWrapper")}>
                        <Filter
                            isOverRideStage={true}
                            isQuery={true}
                            customQuery={query}
                            location = "studentGlossaryListing"
                            actionName={RECEIVED_STUDENT_GLOSSARY_WORDS_RESULTS}
                            viewActivityCssClass={this.filterComponentCssClassName}
                            filterColumns={[
                                {name: 'ownerName', type: 'text', label: 'Owner Name'},
                                {name: 'subjects', type: 'array', label: 'Subject', dependency: 'category'},
                                {name: 'category', type: 'text', label: 'Category', dependent: true}
                            ]}
                            screenNameForElasticSearch = {ElasticSearchTypes.StudentGlossary}
                            allColumnsForElasticSearch = {ElasticSearchColumns.StudentGlossary}
                        />
                        <div className="listViewBlock">
                            <div className="listToBesslide NiceScroll ">
                                <div className="eleRow">
                                    <div className="col-lg-6 col-md-4 col-sm-6 col-xs-12 xs-margin paddnone">
                                        <ul className="glossarytab">
                                            <li className="active"><a href="glossary-overview">Words overview</a></li>
                                            <li><a href="glossary-listing">Words Listing</a></li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-6 col-md-8 col-sm-6 col-xs-12 text-alignlftdesk paddnone">
                                        <a className="blubtnpops margnnone" href="#" style={{margin: '0px'}} onClick={()=>{this.createNewWord();this.setState({saveAndAddMore:false})}}>Create New Word</a>
                                    </div>
                                </div>
                                <table className="table table-hover">
                                    <div className="eleRow " style={{paddingLeft: '0px', paddingRight: '0px'}}>
                                        <div className="col-lg-9 col-md-8 col-sm-8 col-xs-12 text-alignlft">
                                            <div className="filterBlockun NiceScroll">
                                                <div className="overFlowScroll-sm bordern">
                                                    <div className="SubjectBox">
                                                        <div className="limitFilter">
                                                            <div className="fieldBox">
                                                                <input value="8" id="subject8" className="css-checkbox" name="authorize" type="checkbox"/>
                                                                <label htmlFor="subject8" className="pay_label css-label8">Good</label>
                                                            </div>
                                                            <div className="fieldBox">
                                                                <input value="6" id="subject6" className="css-checkbox" name="authorize" type="checkbox"/>
                                                                <label htmlFor="subject6" className="pay_label css-label6">Attention</label>
                                                            </div>
                                                            <div className="fieldBox">
                                                                <input value="7" id="subject7" className="css-checkbox" name="authorize" type="checkbox"/>
                                                                <label htmlFor="subject7" className="pay_label css-label7">Struggling</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </table>
                                <div dir lang = { this.props.auth.user.prefLanguage } id ="screen2">
                                    <MaintenanceTable
                                        customQuery={query}
                                        isOverRideStage={true}
                                        isQuery={true}
                                        location = "studentGlossaryListing"
                                        actionName = {RECEIVED_STUDENT_GLOSSARY_WORDS_RESULTS}
                                        screenNameForElasticSearch = {ElasticSearchTypes.StudentGlossary}
                                        allColumnsForElasticSearch = {ElasticSearchColumns.StudentGlossary}
                                        options={maintenanceTableData}
                                        sortAction={{
                                            action: RECEIVED_STUDENT_GLOSSARY_WORDS_RESULTS,
                                            attributes: {
                                                skipAlterForAllRecords: true
                                            },
                                            dataPath: 'data'
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ProgressIndicator show={this.state.loading}/>
                {this.state.showCreateEditDialog && this.getCreateWordDialog()}
                {this.state.showDeleteDialog && this.getDeleteDialog()}

            </div>
        );
    }

    showIndicator() {
        this.setState({
            ...this.state,
            loading: true
        });
    }

    hideIndicator() {
        this.setState({
            ...this.state,
            loading: false
        });
    }
}

function mapStateToProps(state) {
    return {
        ...state
    }
}

export default connect(mapStateToProps)(GlossaryListing);
