import React from "react";
import { connect } from "react-redux";
import ProgressIndicator from "../../progress-indicator/progress-indicator";
import Heading from "../../heading/heading";
import { Alert, Button, Modal } from "react-bootstrap";
import apiClient from "../../../actions/api-client";
import SelectFieldGroup from "../../forms/select-field-group";
import { browserHistory } from "react-router";
import classnames from "classnames";
import ElasticSearchQuery from "../../../actions/elastic-search-query";
import { ElasticSearchTypes, ElasticSearchColumns } from "../../../actions/types";
import config from "../../../config";
import $ from "jquery";
import MaterialIcon from "react-google-material-icons";
import "./admin-report.css"
import { resolve } from "url";
import excelIcon from "../microsoft-excel.png";
import excelIconHover from "../microsoft-excebl.png";
import { ActionIcons } from "../../actionIcons/action-Icons";
import { ActionIconTypes } from "../../../actions/types";
import Progress from 'react-progressbar';
import {
    parseHours,
    parseMinutes,
    parseSeconds
} from '../../../utils/timeParser';

class AdminReport extends React.Component {

    constructor(props) {
        super(props);

        this.dataHandler = this.dataHandler.bind(this)

        this.state = {
            allTeachersCachedData: [], allStudentsCachedData: [], byTeacherStudentsCachedData: [], showAlert: false, selectedClass: "all", selectedTeacherFromDropDown: 'all', teachersView: true, allStudentsView: false, studentsViewByTeacher: false, NoOfTotalStudents: 0, NoOfTotalTeachers: 0, teachersList: [{ id: 'all', name: 'All Teachers' }]

            //teachersData: [{ name: 'Teacher Name1', students: "200", activitiesAssigned: "54", avgScore: "73%", atRiskStudents: "10(5%)", avgTimeByStudents: "0.05.29" }],

            //studentsData: [{ studentName: "Student Name1", regWithTeacher: "Ahmer", activitiesAssigned: 54, completedActivities: 54, avgScore: "73%", avgTime: "0.05.38" }]

            , loadingReport: false, intervalcount: 0
        }


        let subjects = this.props.auth.user.subjects;
        let nodes = [];

        if (subjects) {
            subjects.forEach(sub => {
                nodes.push({ id: sub, name: sub });
            });
        }

        this.state.userSubjects = nodes;

        if (this.props.location.state) {
            this.state.selectedSubject = this.props.location.state.selectedSubject
            this.state.backToTeacher = this.props.location.state.backToTeacher
            this.state.fromAllStudents = this.props.location.state.fromAllStudents
        }
        this.state.schoolID = this.props.auth.user.schoolID
        this.state.schoolName = this.props.auth.user.schoolName
        this.state.department = this.props.auth.user.department
        this.state.isPrincipal = this.props.auth.user.isPrincipal

        this.countFrom123 = 1;
        this.i = 0;
        this.localLoaded = false;
        this.Loadingfunction = this.Loadingfunction.bind(this);
        this.showIndicator = this.showIndicator.bind(this)
        this.hideIndicator = this.hideIndicator.bind(this)

        console.log("STATES-->", this.props)
        console.log("STATES-->", this.state)
    }

    componentDidMount() {


        // this.showIndicator(true);
        // setTimeout(() => {
        //     this.hideIndicator(true)
        // }, 30000)

        if(this.state.backToTeacher) {
            this.setState({ teachersView: false, allStudentsView: false, studentsViewByTeacher: true, selectedTeacher: { id: this.state.backToTeacher.id, name: this.state.backToTeacher.name } }, () => {
                this.getCourseNameForTeacher(this.state.backToTeacher.id, true);
                this.getPeriodsByTeacher(this.state.backToTeacher.id);
            });
            this.getStudents(this.state.backToTeacher.id, this.state.selectedSubject, "perTeacher")
        } else if (this.state.fromAllStudents == true) {
            this.setState({ teachersView: false, allStudentsView: true, studentsViewByTeacher: false, selectedTeacher: undefined }, () => {
                this.getStudents(null, this.state.selectedSubject, 'all', (dataReceived) => {
                    if(dataReceived == true){
                        this.getAllTeachers(this.state.selectedSubject, true);
                    }
                })
            })
        } else if (this.state.fromAllStudents) {
            this.setState({ teachersView: false, allStudentsView: true, studentsViewByTeacher: false, selectedTeacher: undefined }, () => {
                this.getStudents(null, this.state.selectedSubject, 'all', (dataReceived) => {
                    if(dataReceived == true){
                        this.getAllTeachers(this.state.selectedSubject, true);


                        let filterStudentsData = [];
                        this.state.studentsData.forEach(std => {
                            if (std.teacher_id == this.state.fromAllStudents.id) {
                                filterStudentsData.push(std)
                            }
                        })
                        this.state.selectedTeacher = this.state.fromAllStudents;

                    this.setState({
                        stopLoading: true, selectedTeacherFromDropDown: this.state.fromAllStudents.id, selectedClassFromDropDown: 'all', studentsDataFiltered: filterStudentsData
                        // , teachersView: false, allStudentsView: false, studentsViewByTeacher: true, selectedTeacher: value
                    }, () => {
                            this.getCourseNameForTeacher(this.state.fromAllStudents.id, true);
                            this.getPeriodsByTeacher(this.state.fromAllStudents.id)
                    });



                    }
                })
            })
        } else {
            this.getAllTeachers(this.state.selectedSubject);
        }


    }

    Loadingfunction() {
        let context = this;
        setTimeout(() => {
            let tempNum = Math.floor(Math.random() * 5) + 3;
            context.i += tempNum;
            if (context.localLoaded) {
                context.i = 101;
            }
            if (context.i < 100) {
                if (context.countFrom123 == 1) {
                    context.countFrom123 = 2
                } else if (context.countFrom123 == 2) {
                    context.countFrom123 = 3
                } else if (context.countFrom123 == 3) {
                    context.countFrom123 = 1
                }
                context.setState({ intervalcount: context.i })
                context.Loadingfunction();
            }
        }, 1000)
    }

    // custom callback
    getStudents(teacher, subject, shouldCache, callback) {
        let context = this;

        this.showIndicator(true);

        let params = {
            schoolName: this.state.schoolID,
            department: this.state.department,
            subject: subject,
            teacher: teacher ? teacher : "null"
        };
        let pathTemplate = '/admin_report/students/{schoolName}/{department}/{subject}/{teacher}';
        let method = 'GET';
        let additionalParams = { queryParams : { isPrincipal: this.state.isPrincipal } };
        let body = {};
        apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, '5').then(function (result) {

            if (shouldCache == 'all') {
                context.state.allStudentsCachedData[subject] = result.data
            } else if (shouldCache == 'perTeacher') {
                context.state.byTeacherStudentsCachedData[subject + '_$_' + teacher] = result.data
            }

            context.setState({ studentsData: result.data, studentsDataFiltered: result.data }, () => {
                if(callback) {
                    callback(true)
                    return;
                }
                context.hideIndicator(true);
            })

        }, (err) => {
            context.setState({
                showAlert: true,
                alertStyle: 'danger',
                alertText: err.response.data.errors.map(e => e.message).join(', '),
                studentsData: [],
                studentsDataFiltered: []
            });
            setTimeout(() => {
                context.setState({
                    showAlert: false,
                    alertStyle: '',
                    alertText: ''
                })
            }, 4000);
            context.hideIndicator(true);
        }).catch(function (error) {
            context.setState({
                showAlert: true,
                alertStyle: 'danger',
                alertText: 'Unable to load data, please contact administrator. Error: ' + error,
                studentsData: [],
                studentsDataFiltered: []
            });
            setTimeout(() => {
                context.setState({
                    showAlert: false,
                    alertStyle: '',
                    alertText: ''
                })
            }, 4000);
            context.hideIndicator(true);
        });
    }

    getAllTeachers(subject, doNotLoad) {
        let context = this;

        if(!doNotLoad) {
            this.showIndicator(true);
        }

        let params = {
            schoolName: this.state.schoolID,
            department: this.state.department,
            subject: subject
        };
        let pathTemplate = '/admin_report/teachers/{schoolName}/{department}/{subject}';
        let method = 'GET';
        let additionalParams = { queryParams : { isPrincipal: this.state.isPrincipal } };
        let body = {};
        apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, '5').then(function (result) {

            result.data.data.sort((a,b) => a.name.localeCompare(b.name));

            let teachersList = [{ id: 'all', name: 'All Teachers' }]

            result.data.data.forEach(data => {
                teachersList.push({ id: data.id, name: data.name })
            })

            context.state.allTeachersCachedData[subject] = { teachersList, teachersData: result.data.data, NoOfTotalStudents: result.data.totalStudents, NoOfTotalTeachers: result.data.totalTeachers }

            context.setState({ teachersList, teachersData: result.data.data, NoOfTotalStudents: result.data.totalStudents, NoOfTotalTeachers: result.data.totalTeachers })

            context.hideIndicator(true);

        }).catch(function (error) {
            context.setState({
                showAlert: true,
                alertStyle: 'danger',
                alertText: 'Unable to load data, please contact administrator. Error: ' + error
            });
            setTimeout(() => {
                context.setState({
                    showAlert: false,
                    alertStyle: '',
                    alertText: ''
                })
            }, 4000);

            context.hideIndicator(true);
        });

    }

    getAllTeachersWithPromise(subject) {

        let context = this;

        this.showIndicator(true);

        return new Promise((resolve, reject) => {

            let params = {
                schoolName: this.state.schoolID,
                department: this.state.department,
                subject: subject
            };
            let pathTemplate = '/admin_report/teachers/{schoolName}/{department}/{subject}';
            let method = 'GET';
            let additionalParams = { queryParams : { isPrincipal: this.state.isPrincipal } };
            let body = {};
            apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, '5').then(function (result) {

                result.data.data.sort((a,b) => a.name.localeCompare(b.name));

                let teachersList = [{ id: 'all', name: 'All Teachers' }]

                result.data.data.forEach(data => {
                    teachersList.push({ id: data.id, name: data.name })
                })

                // context.setState({ teachersList, teachersData: result.data.data, NoOfTotalStudents: result.data.totalStudents, NoOfTotalTeachers: result.data.totalTeachers })

                context.hideIndicator(true);

                let data = { teachersList, teachersData: result.data.data, NoOfTotalStudents: result.data.totalStudents, NoOfTotalTeachers: result.data.totalTeachers }

                resolve(data)

            }).catch(function (error) {
                context.setState({
                    showAlert: true,
                    alertStyle: 'danger',
                    alertText: 'Unable to load data, please contact administrator. Error: ' + error
                });
                setTimeout(() => {
                    context.setState({
                        showAlert: false,
                        alertStyle: '',
                        alertText: ''
                    })
                }, 4000);
                context.hideIndicator(true);
                reject(error)
            });
        })
    }


    render() {

        let context = this;

        let breadcrumbs = [
            {
                name: 'Reports', action: () => {
                    browserHistory.push('/reports');
                }
            },
            {
                name: 'Admin Report'
            }
        ];

        let onChange = function (evt) {
            let name = evt.target.name;
            let value = evt.target.value;
            let filterStudentsData = []
            if (value == 'all') {
                let teacher = this.state.selectedTeacher;
                this.state.studentsData.forEach(std => {
                    if (std.teacher_id == teacher.id) {
                        filterStudentsData.push(std)
                    }
                })

            } else {
                this.state.studentsData.forEach(std => {
                    if (std.className == value) {
                        filterStudentsData.push(std)
                    }
                })
            }

            this.setState({
                [name]: value, studentsDataFiltered: filterStudentsData
            })
        }.bind(this);

        let actRows = [];

        if (this.state.teachersData && this.state.teachersData.length != 0) {

            //teachersData: [{ name: 'Teacher Name1', students: "200", activitiesAssigned: "54", avgScore: "73%", atRiskStudents: "10(5%)", avgTimeByStudents: "0.05.29" }],

            // for(let i = 0; i < 100; i++) {
            //     this.state.teachersData.push({ id: 'some', name: 'Teacher Name1', students: 100, activitiesAssigned: 54, avgScore: 73, atRiskStudents: 40, avgTimeByStudents: 526 });
            // }

            for (var dataItem of this.state.teachersData) {

                let { id, name, students, activitiesAssigned, avgScore, atRiskStudents, avgTimeByStudents } = dataItem;

                actRows.push(
                    <tr key={Math.random()} id={Math.random() * 1000} style={{ textAlign: "center" }}>
                        <td /*style={{ width: '15%' }}*/ style={{ textAlign: "left" }}>
                            <div className='makeMeBolder' style={{ fontStyle: 'bold'/*THIS IS A MISTAKE*/, color: "#0f6fb4", 'width': '100%', 'white-space': 'nowrap', 'overflow': 'hidden', 'text-overflow': 'ellipsis', textTransform: 'capitalize ' }} title={name}>
                                {name}
                            </div>
                        </td>
                        <td /*style={{ width: '12.5%' }}*/>
                            {students}
                        </td>
                        <td /*style={{ width: '15%' }}*/>
                            {activitiesAssigned}
                        </td>
                        <td /*style={{ width: '12.5%' }}*/>
                            {avgScore < 65 ? <span style={{ color: 'red', fontWeight: "bold" }}>{avgScore.toFixed()}%</span> : <span style={{ color: 'green', fontWeight: "bold" }}>{avgScore.toFixed()}%</span>}
                        </td>
                        <td /*style={{ width: '15%' }}*/>
                            <span style={{ fontWeight: "bold" }}>{atRiskStudents}</span> ({students > 0 ? ((atRiskStudents / students) * 100).toFixed() : 0}%)
                    </td>
                        <td /*style={{ width: '20%' }}*/>
                            {avgTimeByStudents ?
                                `${parseHours(avgTimeByStudents)}:${parseMinutes(avgTimeByStudents)}:${parseSeconds(avgTimeByStudents)}` /* ` */
                                : "0:0:0"}
                        </td>
                        <td /*style={{ width: '10%' }}*/ className="viewUserDetLink">
                            <a href="javascript:void(0)" onClick={
                                () => {
                                    // alert(id)
                                    if (this.state.allStudentsCachedData[this.state.selectedSubject]) {
                                        let data = this.state.allStudentsCachedData[this.state.selectedSubject];
                                        data = data.filter(stdData => stdData.teacher_id == id)
                                        this.setState({
                                            studentsData: this.state.allStudentsCachedData[this.state.selectedSubject], studentsDataFiltered: data, stopLoading: true,
                                            teachersView: false, allStudentsView: false, studentsViewByTeacher: true, selectedTeacher: { id: id, name: name }
                                        }, () => {
                                            this.getCourseNameForTeacher(id);
                                            this.getPeriodsByTeacher(id);
                                        })
                                    } else if (this.state.byTeacherStudentsCachedData[this.state.selectedSubject + '_$_' + id]) {
                                        let data = this.state.byTeacherStudentsCachedData[this.state.selectedSubject + '_$_' + id]
                                        this.setState({
                                            studentsData: data, studentsDataFiltered: data, stopLoading: true,
                                            teachersView: false, allStudentsView: false, studentsViewByTeacher: true, selectedTeacher: { id: id, name: name }
                                        }, () => {
                                            this.getCourseNameForTeacher(id);
                                            this.getPeriodsByTeacher(id);
                                        })
                                    } else {
                                        this.setState({ teachersView: false, allStudentsView: false, studentsViewByTeacher: true, selectedTeacher: { id: id, name: name } }, () => {
                                            this.getCourseNameForTeacher(id, true);
                                            this.getPeriodsByTeacher(id);
                                        });
                                        this.getStudents(id, this.state.selectedSubject, "perTeacher")
                                    }
                                }
                            }>
                                <MaterialIcon icon="search" size={24} />
                            </a>
                        </td>
                    </tr>
                );
            }
        } else /* if (this.state.teachersData && this.state.teachersData.length == 0) */ {
            actRows.push(
                <tr key={Math.random()} id={Math.random() * 1000}>
                    <td colSpan='6' style={{ textAlign: 'center', color: "#555" }}>
                        No Data Found
                    </td>
                </tr>
            );
        }


        let studentActRows = [];
        if (this.state.studentsDataFiltered && this.state.studentsDataFiltered.length != 0) {

            for (var dataItem of this.state.studentsDataFiltered) {

                let { studentID, teacher_id, teacher_name, studentName, activitiesAssigned, completedActivities, avgScore, completedAvgScore, avgTime } = dataItem;
                let regWithTeacher = dataItem.teacher_name;

                studentActRows.push(
                    <tr key={Math.random()} id={Math.random() * 1000} style={{ textAlign: "center" }}>
                        <td /*style={{ width: '15%' }}*/ style={{ textAlign: "left" }}>
                            <div className='makeMeBolder' style={{ fontStyle: 'bold', /*color: "#0f6fb4",*/ 'width': '100%', 'white-space': 'nowrap', 'overflow': 'hidden', 'text-overflow': 'ellipsis', textTransform: 'capitalize ' }} title={studentName}>
                                {studentName}
                            </div>
                        </td>
                        {this.state.allStudentsView && this.state.selectedTeacherFromDropDown == 'all' &&
                            <td /*style={{ width: '15%' }}*/>
                                <span style={{ fontWeight: "bold" , color: "#0f6fb4" }}>{regWithTeacher}</span>
                            </td>
                        }
                        <td /*style={{ width: '15%' }}*/>
                            {activitiesAssigned}
                        </td>
                        <td /*style={{ width: '15%' }}*/>
                            {completedActivities}
                        </td>
                        <td /*style={{ width: '15%' }}*/>
                            {avgScore < 65 ? <span style={{ color: 'red', fontWeight: "bold" }}>{avgScore.toFixed()}%</span> : <span style={{ color: 'green', fontWeight: "bold" }}>{avgScore.toFixed()}%</span>}
                        </td>
                        <td /*style={{ width: '15%' }}*/>
                            {completedAvgScore < 65 ? <span style={{ color: 'red', fontWeight: "bold" }}>{completedAvgScore.toFixed()}%</span> : <span style={{ color: 'green', fontWeight: "bold" }}>{completedAvgScore.toFixed()}%</span>}
                        </td>
                        <td /*style={{ width: '15%' }}*/>
                            {avgTime ?
                                `${parseHours(avgTime)}:${parseMinutes(avgTime)}:${parseSeconds(avgTime)}` /* ` */
                                 : "0:0:0"}
                        </td>
                        <td /*style={{ width: '10%' }}*/ className="viewUserDetLink">
                            <a href="javascript:void(0)" onClick={
                                () => {
                                    // alert(teacher_name)
                                    // this.setState({ teachersView: false, allStudentsView: false, studentsViewByTeacher: true });
                                    // alert('Grade Tracker and Student Report' + " " + studentID + " " + teacher_id)

                                    let fromAllStudents = !this.state.selectedTeacher ? (this.state.allStudentsView ? true : false) : this.state.allStudentsView ? this.state.selectedTeacher : false;

                                    browserHistory.push({
                                        pathname: '/reports/students/' + this.state.selectedSubject,
                                        state: { forAdminReportForSingleStudent: true, studentIDforAdminReportForSingleStudent: studentID, teacherIDforAdminReportForSingleStudent: teacher_id, teacherNameforAdminReportForSingleStudent: teacher_name, fromAllStudents }
                                    });
                                }
                            }>
                                <MaterialIcon icon="search" size={24} />
                            </a>
                        </td>
                    </tr>
                );
            }
        } else /* if (this.state.studentsDataFiltered && this.state.studentsDataFiltered.length == 0) */ {
            studentActRows.push(
                <tr key={Math.random()} id={Math.random() * 1000}>

                    <td colSpan='6' style={{ textAlign: 'center', color: "#555" }}>
                        No Data Found
                    </td>

                </tr>
            );
        }


        let tableData =
            <div id="admin-report-teacher" className="table-responsive">
                <table className="table table-hover table-bordered fixed_headers" id="student-attempt-history">
                    <thead>
                        <tr>
                            <th data-activity-id='teacherName' data-order="asc" onClick={function (evt) {
                                let allData = this.state.teachersData;
                                let order = evt.target.getAttribute('data-order');
                                if (order === 'asc') {
                                    evt.target.setAttribute('data-order', 'dsc');
                                    evt.target.setAttribute('class', 'dropup');
                                    allData.sort(function (a, b) {
                                        return (a.name.localeCompare(b.name));
                                    });
                                } else {
                                    evt.target.setAttribute('data-order', 'asc');
                                    evt.target.setAttribute('class', 'dropdown');
                                    allData.sort(function (a, b) {
                                        return (b.name.localeCompare(a.name));
                                    });
                                }
                                context.setState({ teachersData: allData });
                            }.bind(this)}>
                                Teacher Name <span className="caret caret-close" />
                            </th>
                            <th data-activity-id='students' data-order="asc" onClick={function (evt) {
                                let allData = this.state.teachersData;
                                let order = evt.target.getAttribute('data-order');
                                if (order === 'asc') {
                                    evt.target.setAttribute('data-order', 'dsc');
                                    evt.target.setAttribute('class', 'dropup');
                                    allData.sort(function (a, b) {
                                        return (a.students - b.students);
                                    });
                                } else {
                                    evt.target.setAttribute('data-order', 'asc');
                                    evt.target.setAttribute('class', 'dropdown');
                                    allData.sort(function (a, b) {
                                        return (b.students - a.students);
                                    });
                                }
                                context.setState({ teachersData: allData });
                            }.bind(this)}>
                                Students <span className="caret caret-close" />
                            </th>
                            <th data-activity-id='activitiesAssigned' data-order="asc" onClick={function (evt) {
                                let allData = this.state.teachersData;
                                let order = evt.target.getAttribute('data-order');
                                if (order === 'asc') {
                                    evt.target.setAttribute('data-order', 'dsc');
                                    evt.target.setAttribute('class', 'dropup');
                                    allData.sort(function (a, b) {
                                        return (a.activitiesAssigned - b.activitiesAssigned);
                                    });
                                } else {
                                    evt.target.setAttribute('data-order', 'asc');
                                    evt.target.setAttribute('class', 'dropdown');
                                    allData.sort(function (a, b) {
                                        return (b.activitiesAssigned - a.activitiesAssigned);
                                    });
                                }
                                context.setState({ teachersData: allData });
                            }.bind(this)}>
                                Activities Assigned <span className="caret caret-close" />
                            </th>
                            <th data-activity-id='avgScore' data-order="asc" onClick={function (evt) {
                                let allData = this.state.teachersData;
                                let order = evt.target.getAttribute('data-order');
                                if (order === 'asc') {
                                    evt.target.setAttribute('data-order', 'dsc');
                                    evt.target.setAttribute('class', 'dropup');
                                    allData.sort(function (a, b) {
                                        return (a.avgScore - b.avgScore);
                                    });
                                } else {
                                    evt.target.setAttribute('data-order', 'asc');
                                    evt.target.setAttribute('class', 'dropdown');
                                    allData.sort(function (a, b) {
                                        return (b.avgScore - a.avgScore);
                                    });
                                }
                                context.setState({ teachersData: allData });
                            }.bind(this)}>
                                Average <span className="caret caret-close" />
                            </th>
                            <th data-activity-id='atRiskStudents' data-order="asc" onClick={function (evt) {
                                let allData = this.state.teachersData;
                                let order = evt.target.getAttribute('data-order');
                                if (order === 'asc') {
                                    evt.target.setAttribute('data-order', 'dsc');
                                    evt.target.setAttribute('class', 'dropup');
                                    allData.sort(function (a, b) {
                                        return (a.atRiskStudents - b.atRiskStudents);
                                    });
                                } else {
                                    evt.target.setAttribute('data-order', 'asc');
                                    evt.target.setAttribute('class', 'dropdown');
                                    allData.sort(function (a, b) {
                                        return (b.atRiskStudents - a.atRiskStudents);
                                    });
                                }
                                context.setState({ teachersData: allData });
                            }.bind(this)}>
                                At-Risk Students <span className="caret caret-close" />
                            </th>
                            <th data-activity-id='avgTimeByStudents' data-order="asc" onClick={function (evt) {
                                let allData = this.state.teachersData;
                                let order = evt.target.getAttribute('data-order');
                                if (order === 'asc') {
                                    evt.target.setAttribute('data-order', 'dsc');
                                    evt.target.setAttribute('class', 'dropup');
                                    allData.sort(function (a, b) {
                                        return (a.avgTimeByStudents - b.avgTimeByStudents);
                                    });
                                } else {
                                    evt.target.setAttribute('data-order', 'asc');
                                    evt.target.setAttribute('class', 'dropdown');
                                    allData.sort(function (a, b) {
                                        return (b.avgTimeByStudents - a.avgTimeByStudents);
                                    });
                                }
                                context.setState({ teachersData: allData });
                            }.bind(this)}>
                                {/*Avg. Time By Students*/}
                                Total Time By Students <span className="caret caret-close" />
                            </th>
                            <th>
                                View Details
                            </th>
                        </tr>
                    </thead>
                    <tbody>

                        {actRows}

                    </tbody>
                </table>
            </div>;

        let studentTableData =
            <div id="admin-report-student" className="table-responsive">
                <table className="table table-hover table-bordered studentTable_fixed_headers" id="student-attempt-history">
                    <thead>
                        <tr>
                            <th data-activity-id='studentName' data-order="asc" onClick={function (evt) {
                                let allData = this.state.studentsDataFiltered;
                                let order = evt.target.getAttribute('data-order');
                                if (order === 'asc') {
                                    evt.target.setAttribute('data-order', 'dsc');
                                    evt.target.setAttribute('class', 'dropup');
                                    allData.sort(function (a, b) {
                                        return (a.studentName.localeCompare(b.studentName));
                                    });
                                } else {
                                    evt.target.setAttribute('data-order', 'asc');
                                    evt.target.setAttribute('class', 'dropdown');
                                    allData.sort(function (a, b) {
                                        return (b.studentName.localeCompare(a.studentName));
                                    });
                                }
                                context.setState({ studentsDataFiltered: allData });
                            }.bind(this)}>
                                Student Name <span className="caret caret-close" />
                            </th>
                            {this.state.allStudentsView && this.state.selectedTeacherFromDropDown == 'all' &&
                                <th data-activity-id='teacher_name' data-order="asc" onClick={function (evt) {
                                    let allData = this.state.studentsDataFiltered;
                                    let order = evt.target.getAttribute('data-order');
                                    if (order === 'asc') {
                                        evt.target.setAttribute('data-order', 'dsc');
                                        evt.target.setAttribute('class', 'dropup');
                                        allData.sort(function (a, b) {
                                            return (a.teacher_name.localeCompare(b.teacher_name));
                                        });
                                    } else {
                                        evt.target.setAttribute('data-order', 'asc');
                                        evt.target.setAttribute('class', 'dropdown');
                                        allData.sort(function (a, b) {
                                            return (b.teacher_name.localeCompare(a.teacher_name));
                                        });
                                    }
                                    context.setState({ studentsDataFiltered: allData });
                                }.bind(this)}>
                                    {/*Registered With*/} Teacher <span className="caret caret-close" />
                                </th>
                            }
                            <th data-activity-id='activitiesAssigned' data-order="asc" onClick={function (evt) {
                                let allData = this.state.studentsDataFiltered;
                                let order = evt.target.getAttribute('data-order');
                                if (order === 'asc') {
                                    evt.target.setAttribute('data-order', 'dsc');
                                    evt.target.setAttribute('class', 'dropup');
                                    allData.sort(function (a, b) {
                                        return (a.activitiesAssigned - b.activitiesAssigned);
                                    });
                                } else {
                                    evt.target.setAttribute('data-order', 'asc');
                                    evt.target.setAttribute('class', 'dropdown');
                                    allData.sort(function (a, b) {
                                        return (b.activitiesAssigned - a.activitiesAssigned);
                                    });
                                }
                                context.setState({ studentsDataFiltered: allData });
                            }.bind(this)}>
                                Activities Assigned <span className="caret caret-close" />
                            </th>
                            <th data-activity-id='completedActivities' data-order="asc" onClick={function (evt) {
                                let allData = this.state.studentsDataFiltered;
                                let order = evt.target.getAttribute('data-order');
                                if (order === 'asc') {
                                    evt.target.setAttribute('data-order', 'dsc');
                                    evt.target.setAttribute('class', 'dropup');
                                    allData.sort(function (a, b) {
                                        return (a.completedActivities - b.completedActivities);
                                    });
                                } else {
                                    evt.target.setAttribute('data-order', 'asc');
                                    evt.target.setAttribute('class', 'dropdown');
                                    allData.sort(function (a, b) {
                                        return (b.completedActivities - a.completedActivities);
                                    });
                                }
                                context.setState({ studentsDataFiltered: allData });
                            }.bind(this)}>
                                Completed Activities <span className="caret caret-close" />
                            </th>
                            <th data-activity-id='avgScore' data-order="asc" onClick={function (evt) {
                                let allData = this.state.studentsDataFiltered;
                                let order = evt.target.getAttribute('data-order');
                                if (order === 'asc') {
                                    evt.target.setAttribute('data-order', 'dsc');
                                    evt.target.setAttribute('class', 'dropup');
                                    allData.sort(function (a, b) {
                                        return (a.avgScore - b.avgScore);
                                    });
                                } else {
                                    evt.target.setAttribute('data-order', 'asc');
                                    evt.target.setAttribute('class', 'dropdown');
                                    allData.sort(function (a, b) {
                                        return (b.avgScore - a.avgScore);
                                    });
                                }
                                context.setState({ studentsDataFiltered: allData });
                            }.bind(this)}>
                                Average <span className="caret caret-close" />
                            </th>
                            <th data-activity-id='completedAvgScore' data-order="asc" onClick={function (evt) {
                                let allData = this.state.studentsDataFiltered;
                                let order = evt.target.getAttribute('data-order');
                                if (order === 'asc') {
                                    evt.target.setAttribute('data-order', 'dsc');
                                    evt.target.setAttribute('class', 'dropup');
                                    allData.sort(function (a, b) {
                                        return (a.completedAvgScore - b.completedAvgScore);
                                    });
                                } else {
                                    evt.target.setAttribute('data-order', 'asc');
                                    evt.target.setAttribute('class', 'dropdown');
                                    allData.sort(function (a, b) {
                                        return (b.completedAvgScore - a.completedAvgScore);
                                    });
                                }
                                context.setState({ studentsDataFiltered: allData });
                            }.bind(this)}>
                                Completed Average <span className="caret caret-close" />
                            </th>
                            <th data-activity-id='avgTime' data-order="asc" onClick={function (evt) {
                                let allData = this.state.studentsDataFiltered;
                                let order = evt.target.getAttribute('data-order');
                                if (order === 'asc') {
                                    evt.target.setAttribute('data-order', 'dsc');
                                    evt.target.setAttribute('class', 'dropup');
                                    allData.sort(function (a, b) {
                                        return (a.avgTime - b.avgTime);
                                    });
                                } else {
                                    evt.target.setAttribute('data-order', 'asc');
                                    evt.target.setAttribute('class', 'dropdown');
                                    allData.sort(function (a, b) {
                                        return (b.avgTime - a.avgTime);
                                    });
                                }
                                context.setState({ studentsDataFiltered: allData });
                            }.bind(this)}>
                                {/*Average Time*/}
                                Total Time <span className="caret caret-close" />
                            </th>
                            <th>
                                View Details
                            </th>
                        </tr>
                    </thead>
                    <tbody>

                        {studentActRows}

                    </tbody>
                </table>
            </div>;


        let teacherTable = <div id="student-activity-analysis" className="row teacherTable"> {/*user-activity-analysis*/}

            <div className="col-xs-12 col-sm-3 col-sm-offset-9 col-md-3 col-md-offset-9 col-lg-3 col-lg-offset-9" style={{ zIndex: "1", marginTop: '-50px', "text-align": "right", marginBottom: "15px" }}>
                {/* <a className="boardheadblff noHover">Students Analysis of Course: {this.params.id.slice(0, this.params.id.indexOf('_'))}</a> */}
                <span onMouseOver={(event) => { event.target.firstElementChild.src = excelIconHover; }} onMouseOut={(event) => { event.target.firstElementChild.src = excelIcon; }}
                    className="detailPrintForReport" data-toggle="tooltip" title="Export Report To Excel" data-placement="bottom" data-original-title="Print"
                    onClick={() => {
                        this.exportToExcel('admin-report-teacher', true, "All Teachers - " + this.state.selectedSubject + " - " + this.state.schoolName);
                        // this.exportToExcel('admin-report-teacher');
                    }}>
                    <img src={excelIcon} style={{ width: '43px' }} />
                </span>
                <ActionIcons iconType={ActionIconTypes.Print} action={() => {
                    context.printTable('admin-report-teacher', true, "All Teachers - " + this.state.selectedSubject + " - " + this.state.schoolName);
                    // context.printTable('admin-report-teacher');
                }} />
            </div>

            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <p style={{ overflow: 'auto' }}>
                    {tableData}
                </p>
            </div>
        </div>;


        let studentTable = <div id="student-activity-analysis" className="row"> {/*user-activity-analysis*/}

            <div className="col-xs-12 col-sm-3 col-sm-offset-9 col-md-3 col-md-offset-9 col-lg-3 col-lg-offset-9" style={{ zIndex: "1", marginTop: '0px', "text-align": "right", marginBottom: "-60px" }}>
                {/* <a className="boardheadblff noHover">Students Analysis of Course: {this.params.id.slice(0, this.params.id.indexOf('_'))}</a> */}
                <span onMouseOver={(event) => { event.target.firstElementChild.src = excelIconHover; }} onMouseOut={(event) => { event.target.firstElementChild.src = excelIcon; }}
                    className="detailPrintForReport" data-toggle="tooltip" title="Export Report To Excel" data-placement="bottom" data-original-title="Print"
                    onClick={() => {
                        this.state.selectedTeacher ? this.exportToExcel('admin-report-student', true, "All Students of " + this.state.selectedTeacher.name + this.state.selectedSubject + " - " + this.state.schoolName) : this.exportToExcel('admin-report-student', true, "All Students - " + this.state.selectedSubject + " - " + this.state.schoolName);
                        // this.exportToExcel('admin-report-student');
                    }}>
                    <img src={excelIcon} style={{ width: '43px' }} />
                </span>
                <ActionIcons iconType={ActionIconTypes.Print} action={() => {
                    this.state.selectedTeacher ? context.printTable('admin-report-student', true, "All Students of " + this.state.selectedTeacher.name + " - " + this.state.selectedSubject + " - " + this.state.schoolName) : context.printTable('admin-report-student', true, "All Students" + " - " + this.state.selectedSubject + " - " + this.state.schoolName);
                    // context.printTable('admin-report-student');
                }} />
            </div>

            {/* Remove this non-sence workaround when working for mobile devices */}
            <div className="col-xs-12 hidden-sm hidden-md hidden-lg" style={{ height: "60px" }}></div>

            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                {this.state.studentsViewByTeacher && this.state.selectedTeacher &&
                    <div className='tableUpperHeadingSettings'>
                        {/* <span className="reportHeading">{this.state.selectedTeacher.name}</span> */}
                        <div className="selectSubjectDropdown classesDropDownSetting">
                            <SelectFieldGroup
                                field="selectedClass"
                                value={this.state.selectedClass}
                                options={this.state.periods ? [{ id: 'all', name: 'All Classes' }, ...this.state.periods] : [{ id: 'all', name: 'All Classes' }]}
                                placeholder="Select Period"
                                onChange={onChange}
                            // onChange={function (event) {
                            //     let value = event.target.value; alert(value);
                            //     context.setState({ selectedClass: value }, function () {
                            //         console.log("Will do something with it")
                            //     });
                            // }.bind(this)}

                            />
                        </div>
                    </div>}
                {this.state.allStudentsView &&
                    <div className='tableUpperHeadingSettings'>
                        <div className="selectSubjectDropdown teachersDropDownSetting">
                            <SelectFieldGroup
                                field="selectedTeacherFromDropDown"
                                value={this.state.selectedTeacherFromDropDown}
                                options={this.state.teachersList}
                                placeholder="Select By Teacher"
                                onChange={function (event) {

                                    let value = event.target.value;

                                    // let filteredTeacher = []
                                    // this.state.teachersData.forEach(teacher => {
                                    //     if (teacher.id == value) {
                                    //         filteredTeacher.push(teacher)
                                    //     }
                                    // })


                                    let filterStudentsData = []
                                    if (value == 'all') {
                                        filterStudentsData = this.state.studentsData;
                                        this.state.selectedTeacher = undefined;
                                    } else {
                                        this.state.studentsData.forEach(std => {
                                            if (std.teacher_id == value) {
                                                filterStudentsData.push(std)
                                            }
                                        })
                                        this.state.selectedTeacher = { id: event.target.value, name: event.target.selectedOptions[0].text };
                                    }

                                    context.setState({
                                        stopLoading: true, selectedTeacherFromDropDown: this.state.selectedTeacher ? this.state.selectedTeacher.id : "all" /*value*/, selectedClassFromDropDown: 'all', studentsDataFiltered: filterStudentsData
                                        // , teachersView: false, allStudentsView: false, studentsViewByTeacher: true, selectedTeacher: value
                                    }, () => {
                                        if (value != 'all') {
                                            this.getCourseNameForTeacher(value);
                                            this.getPeriodsByTeacher(value)
                                        }
                                    });
                                }.bind(this)}

                            />
                        </div>
                        <div className="selectSubjectDropdown classesDropDownSetting">
                            <SelectFieldGroup
                                field="selectedClassFromDropDown"
                                isDisabled={this.state.selectedTeacherFromDropDown == 'all' ? 'true' : ''}
                                value={this.state.selectedClassFromDropDown}
                                options={this.state.periods ? [{ id: 'all', name: 'All Classes' }, ...this.state.periods] : [{ id: 'all', name: 'All Classes' }]}
                                placeholder="Select Period"
                                onChange={onChange}
                            // onChange={function (event) {
                            //     let value = event.target.value;
                            //     context.setState({ selectedClassFromDropDown: value }, function () {
                            //         console.log("Will do something with it")
                            //     });
                            // }.bind(this)}

                            />
                        </div>
                    </div>}
                <p style={{ overflow: 'auto' }}>
                    {studentTableData}
                </p>
            </div>
        </div>;


        return (
            <div id="TeacherStudentReport">
                <div id="forAdminReport">
                    <div className="container-fluid noPad listMainBoxWrapper " id="activity-analysis" style={{ overflowX: 'hidden' }}>
                        <Heading
                            isQuery={false}
                            customURL={"admin-report"}
                            location="admin-report"
                            actionName={"ADMIN_REPORT"}
                            isSearch={false}
                            isShowButton={false}
                            screenNameForElasticSearch={this.props.auth.user.userName}
                            allColumnsForElasticSearch={['name', 'students', 'activitiesAssigned', 'aveScore', 'atRiskStudents', 'avergaeTimeByStudent']}
                            breadcrumbsData={breadcrumbs}
                        />
                        {this.state.showAlert &&
                            <div className="row">
                                <div className="col-md-3 col-xs-3" />
                                <div className="col-md-6 col-xs-3">
                                    <Alert bsStyle={this.state.alertStyle}
                                        onDismiss={this.handleAlertDismiss.bind(this)}>
                                        {this.state.alertText}
                                    </Alert>
                                </div>
                                <div className="col-md-3 col-xs-3" />
                            </div>
                        }

                        <div id="tableFiltersAndSideView">
                            <div className={classnames("container-fluid noPad listMainBoxWrapper")}>

                                <div id="submission" className="tab-pane fade active in">



                                    {this.state.studentsViewByTeacher && this.state.selectedTeacher && <div className="backButtonDiv"><span className="backButton btn btn-default" onClick={() => {
                                        if (!this.state.teachersView) {
                                            this.setState({ teachersView: true, allStudentsView: false, studentsViewByTeacher: false, selectedTeacher: undefined }, () => {
                                                if(!this.state.allTeachersCachedData[this.state.selectedSubject]) {
                                                    this.getAllTeachers(this.state.selectedSubject);
                                                }
                                            })
                                        }
                                    }}><MaterialIcon icon="arrow_back" size={17} /> back</span></div>}



                                    <div id="contentPanel" style={{
                                        margin: '1.5% 10.5%',
                                        width: '80%',
                                        padding: '35px',
                                        border: '1px solid rgb(204, 204, 204)',
                                        float: "none",
                                        marginBottom: "70px",
                                        marginTop: "25px"
                                    }}>



                                        {/* <span className="mainHeading">Registered Teachers</span> */}
                                        {this.state.studentsViewByTeacher && this.state.selectedTeacher &&
                                            <div>
                                                <span className="mainHeading">{this.state.selectedTeacher.name}</span>
                                                <br />
                                                <span className="mainSubHeading">{this.state.selectedSubject}</span>
                                            </div>
                                        }

                                        <div className='row'>
                                            {!this.state.studentsViewByTeacher ?
                                                <div className="col-xs-12   col-sm-3    col-md-3    col-lg-3">
                                                    <h4>Subjects</h4>
                                                    <div className="selectSubjectDropdown subjectsDropDownSetting">
                                                        <SelectFieldGroup
                                                            field="selectBySubject"
                                                            value={this.state.selectedSubject}
                                                            // options={[{ id: 'algebra 1', name: 'Algebra' }, { id: 'someSubject', name: 'More Subjects' }]}
                                                            options={this.state.userSubjects}
                                                            placeholder="Select Subject"
                                                            onChange={this.dataHandler}
                                                        // {function (event) {
                                                        //     let value = event.target.value;
                                                        //     context.setState({ selectedSubject: value, teachersView: true, allStudentsView: false, studentsViewByTeacher: false }, function () {
                                                        //         context.getAllTeachers(value)
                                                        //     });
                                                        // }.bind(this)}
                                                        />
                                                    </div>
                                                </div> :
                                                <div className={false && this.state.studentsViewByTeacher ? "col-xs-12 col-sm-2 col-md-2 col-lg-2" : "col-xs-12 col-sm-6 col-md-6 col-lg-6"}>
                                                    {this.valuesAggregation()}
                                                </div>
                                            }

                                            <div className={!this.state.studentsViewByTeacher ? "col-xs-12 col-sm-9 col-md-9 col-lg-9" : "col-xs-12 col-sm-6 col-md-6 col-lg-6"}>
                                                {!this.state.studentsViewByTeacher &&
                                                    <div title="View all teachers and their activity. Select View Details&#013;for additional data on their students and classes" className={classnames("col-xs-12 col-sm-3 col-md-3 col-lg-3 report-box", { selected: this.state.teachersView })} style={{ textAlign: "center" }}>
                                                        <span className="report-box-icon custom-color-icon" style={{ color: "rgb(85, 164, 240)" /*'#4d6573'*/ }} onClick={() => {
                                                            if (!this.state.teachersView) {
                                                                this.setState({ teachersView: true, allStudentsView: false, studentsViewByTeacher: false, selectedTeacher: undefined }, () => {
                                                                    if(!this.state.allTeachersCachedData[this.state.selectedSubject]) {
                                                                        this.getAllTeachers(this.state.selectedSubject);
                                                                    }
                                                                })
                                                            }
                                                        }}>
                                                            <MaterialIcon icon="account_circle" size={48} />
                                                        </span>
                                                        <br />
                                                        <span className="report-box-text">{this.state.NoOfTotalTeachers} Registered Teacher{this.state.NoOfTotalTeachers > 1 ? "s" : "" }</span>
                                                    </div>
                                                }
                                                {!this.state.studentsViewByTeacher &&
                                                    <div title="View all students and their activity. Select View Details for additional data" className={classnames("col-xs-12 col-sm-3 col-md-3 col-lg-3 report-box", { selected: this.state.allStudentsView })} style={{ textAlign: "center" }}>
                                                        <span className="report-box-icon" style={{ color: '#55a4f0' }} onClick={() => {
                                                            if (!this.state.allStudentsView) {
                                                                this.setState({ teachersView: false, allStudentsView: true, studentsViewByTeacher: false, selectedTeacher: undefined, selectedTeacherFromDropDown: 'all' })
                                                                if (this.state.allStudentsCachedData[this.state.selectedSubject]) {
                                                                    context.setState({ studentsData: this.state.allStudentsCachedData[this.state.selectedSubject], studentsDataFiltered: this.state.allStudentsCachedData[this.state.selectedSubject] })
                                                                } else {
                                                                    this.getStudents(null, this.state.selectedSubject, 'all')
                                                                }
                                                            }
                                                        }}>
                                                            <MaterialIcon icon="supervised_user_circle" size={48} />
                                                        </span>
                                                        <br />
                                                        <span className="report-box-text">{this.state.NoOfTotalStudents} Registered Student{this.state.NoOfTotalStudents > 1 ? "s" : "" }</span>
                                                    </div>
                                                }
                                                <div title={this.state.studentsViewByTeacher ? "Analyze strengths and weaknesses of students by category and subcategory" : "Analyze strengths and weaknesses of students by category and subcategory.\nView data by an aggregation of all teachers and students\nor filter by individual teacher, class or students"} className={classnames("col-xs-12 col-sm-3 col-md-3 col-lg-3 report-box"/*, { disabled: this.state.selectedTeacher ? true : false }*/)} style={{ textAlign: "center" }}>
                                                    <span className="report-box-icon" style={{ color: '#66af47' }} onClick={() => {
                                                        if (!this.state.selectedTeacher) {
                                                            browserHistory.push({
                                                                pathname: '/reports/students/' + this.state.selectedSubject,
                                                                state: { forAdminReport: true, fromAllStudents: this.state.allStudentsView ? true : false, allTeachersList: this.state.teachersList }
                                                            });
                                                        } else {
                                                            browserHistory.push({
                                                                pathname: '/reports/students/' + this.state.selectedSubject,
                                                                state: { forAdminReportForSingleTeacher: true, teacherForAdminReportForSingleTeacher: this.state.selectedTeacher, fromAllStudents:  this.state.allStudentsView ? this.state.selectedTeacher : false, allTeachersList: this.state.teachersList }
                                                            });
                                                        }
                                                    }}>
                                                        <MaterialIcon icon="supervisor_account" size={48} />
                                                    </span>
                                                    <br />
                                                    <span className="report-box-text">Grade Tracker</span>
                                                </div>
                                                <div title="View course data by classes, student or activity" className={classnames("col-xs-12 col-sm-3 col-md-3 col-lg-3 report-box", { disabled: this.state.selectedTeacher ? false : true })} style={this.state.selectedTeacher ? { textAlign: "center", display: "block" } : { textAlign: "center", display: "none" }}>
                                                    <span className="report-box-icon" style={{ color: '#66af47' }} onClick={() => {
                                                        if (this.state.selectedTeacher) {
                                                            this.setState({
                                                                showCourseDialog: true
                                                            })
                                                        }
                                                        else {
                                                            context.setState({
                                                                showAlert: true,
                                                                alertStyle: 'info',
                                                                alertText: 'Select a teacher first'
                                                            });
                                                            setTimeout(() => {
                                                                context.setState({
                                                                    showAlert: false,
                                                                    alertStyle: '',
                                                                    alertText: ''
                                                                })
                                                            }, 3000);
                                                        }
                                                    }}>
                                                        <MaterialIcon icon="dashboard" size={48} />
                                                    </span>
                                                    <br />
                                                    <span className="report-box-text">Course Report</span>
                                                </div>
                                            </div>
                                        </div>

                                        {this.state.teachersView && teacherTable}

                                        {(this.state.studentsViewByTeacher || this.state.allStudentsView) && studentTable}

                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.showCourseDialog && this.getCoursesDialog()}


                        {(this.state.loadingReport) && <div>
                            <div className="modal-backdrop fade in"></div>
                            <div className="sk-fading-circle-main">
                                <div className="sk-fading-bar">

                                    <h1>
                                        {this.state.intervalcount + '%'}
                                    </h1>

                                    <div id="myProgress">

                                        <Progress completed={this.state.intervalcount} />

                                    </div>

                                    <pre style={{
                                        display: "block",
                                        padding: "unset",
                                        margin: "unset",
                                        // fontSize: "unset",
                                        lineHeight: "unset",
                                        // color: "unset",
                                        wordBreak: "unset",
                                        wordWrap: "unset",
                                        backgroundColor: "unset",
                                        border: "unset",
                                        borderRadius: "unset",


                                        textAlign: "center",
                                        color: "whitesmoke",
                                        marginTop: "5px",
                                        fontSize: "1.2em",
                                    }}>
                                        Loading please wait
                                    {(this.countFrom123 == 1) ?
                                            <span style={{ position: "absolute" }}> . </span>
                                            : (this.countFrom123 == 2) ?
                                                <span style={{ position: "absolute" }}> . . </span>
                                                :
                                                <span style={{ position: "absolute" }}> . . . </span>
                                        }
                                    </pre>

                                </div>
                            </div>

                        </div>}


                        <ProgressIndicator show={this.state.loading} />
                    </div>
                </div>
            </div>
        )
    }

    valuesAggregation() {
        if (this.state.studentsDataFiltered && this.state.studentsDataFiltered.length != 0) {

            const totalStudents = this.state.studentsDataFiltered.length;
            let avgScore = 0;
            let avgTime = 0;
            let completedAvgScore = 0;
            let totalCompletedStudent = 0

            this.state.studentsDataFiltered.forEach(std => {
                avgScore += std.avgScore ? std.avgScore : 0
                avgTime += std.avgTime ? std.avgTime : 0
                completedAvgScore += std.completedAvgScore ? std.completedAvgScore : 0
                totalCompletedStudent += std.completedActivities > 0 ? 1 : 0
            })

            const avgStudentsScore = (avgScore / totalStudents).toFixed();
            const completedAvgStudentsScore = totalCompletedStudent ? (completedAvgScore / totalCompletedStudent).toFixed() : 0;
            const avgTimePerStudent = (avgTime / totalStudents);


            return (<table className="table table-striped table-bordered ">
                <tbody>
                    <tr className="upperBoxRows">
                        <td><MaterialIcon icon="assignment" size={24} /> Average <span style={{ fontWeight: "normal" }}>(Assigned Activities)</span>:<a className = "Activity-toolTip toolTipCus tooltip-pos"><span className = "tooltiptext"style={{ fontWeight: 'normal' }}>This score is based on the average of all the scores of all assigned activities. Incomplete or un-started activities count towards this average as 0%</span></a></td>
                        {/* <td>Average Students Score:</td> */}
                        <td>{avgStudentsScore < 65 ? <span style={{ color: 'red' }}>{avgStudentsScore}%</span> : <span>{avgStudentsScore}%</span>}</td>
                    </tr>
                    <tr className="upperBoxRows">
                        <td><MaterialIcon icon="assessment" size={24} /> Completed Average <span style = {{ fontWeight: "normal" }}>(Completed Activities)</span>:<a className = "Activity-toolTip toolTipCus tooltip-pos"><span className = "tooltiptext"style={{ fontWeight: 'normal' }}>This score is based on only activities that were completed</span></a></td>
                        {/* <td>Completed Average Students Score:</td> */}
                        <td>{completedAvgStudentsScore < 65 ? <span style={{ color: 'red' }}>{completedAvgStudentsScore}%</span> : <span>{completedAvgStudentsScore}%</span>}
                        </td>
                    </tr>

                    <tr className="upperBoxRows">
                        <td><MaterialIcon icon="alarm" size={24} /> Average Time Per Student:</td>
                        {/* <td>Average Time/Student:</td> */}
                        <td><span>{avgTimePerStudent ?
                            `${parseHours(avgTimePerStudent)}:${parseMinutes(avgTimePerStudent)}:${parseSeconds(avgTimePerStudent)}` /* ` */
                            : "0:0:0"}</span></td>
                    </tr>
                </tbody>
            </table>);
        }

        return (<table className="table table-striped table-bordered table-hover">
            <tbody>
                <tr className="upperBoxRows">
                    <td><MaterialIcon icon="assignment" size={24} /> Average <span style={{ fontWeight: "normal" }}>(Assigned Activities)</span>:</td>
                    {/* <td>Average Students Score:</td> */}
                    <td className="text-muted" style={{ fontStyle: "italic" }}>N/A</td>
                </tr>
                <tr className="upperBoxRows">
                    <td><MaterialIcon icon="assessment" size={24} /> Completed Average <span style={{ fontWeight: 'normal' }}>(Completed Activities)</span>:</td>
                    {/* <td>Completed Average Students Score:</td> */}
                    <td className="text-muted" style={{ fontStyle: "italic" }}>N/A</td>
                </tr>
                <tr className="upperBoxRows">
                    <td><MaterialIcon icon="alarm" size={24} /> Average Time Per Student:</td>
                    {/* <td>Average Time/Student:</td> */}
                    <td className="text-muted" style={{ fontStyle: "italic" }}>N/A</td>
                </tr>
            </tbody>
        </table>);
    }

    dataHandler(event) {
        // let context = this;
        let value = event.target.value;

        if (this.state.allTeachersCachedData[value]) {
            this.setState({
                teachersList: this.state.allTeachersCachedData[value].teachersList,
                teachersData: this.state.allTeachersCachedData[value].teachersData,
                NoOfTotalStudents: this.state.allTeachersCachedData[value].NoOfTotalStudents,
                NoOfTotalTeachers: this.state.allTeachersCachedData[value].NoOfTotalTeachers,

                selectedSubject: value, teachersView: true, allStudentsView: false, studentsViewByTeacher: false, selectedTeacher: undefined
            });
        } else {
            this.getAllTeachersWithPromise(value).then((data) => {
                let allTeachersCachedData = this.state.allTeachersCachedData;
                allTeachersCachedData[value] = { teachersList: data.teachersList, teachersData: data.teachersData, NoOfTotalStudents: data.NoOfTotalStudents, NoOfTotalTeachers: data.NoOfTotalTeachers }
                this.setState({
                    allTeachersCachedData,
                    teachersList: allTeachersCachedData[value].teachersList,
                    teachersData: allTeachersCachedData[value].teachersData,
                    NoOfTotalStudents: allTeachersCachedData[value].NoOfTotalStudents,
                    NoOfTotalTeachers: allTeachersCachedData[value].NoOfTotalTeachers,

                    selectedSubject: value, teachersView: true, allStudentsView: false, studentsViewByTeacher: false, selectedTeacher: undefined
                });

            }, (error) => {
                console.error(error);
            });
        }
    }

    getCourseNameForTeacher(teacherID, noLoading) {

        if (!noLoading) {
            this.showIndicator();
        }

        let visibility = '(public OR "' + teacherID + '"';//this.props.auth.user.userName;
        if (this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName === "LearnerPal") {
            visibility += " OR LPAdmin";
        }
        visibility += ')';
        let subjectsToSearch = "(";
        if (this.props.auth.user.subjects) {
            this.props.auth.user.subjects.forEach(sub => {
                subjectsToSearch += "\"" + sub + "\" OR ";
            })
        }
        subjectsToSearch = subjectsToSearch.substring(0, subjectsToSearch.length - 3);

        let query = "visibleTo : " + visibility + " AND subject : " + subjectsToSearch + ")";

        //LoginUtils.isAuthenticated(this.props, browserHistory, '/courses', '/');
        //const {dispatch} = this.props;
        //ElasticSearchActions.setElasticSearchAttributes(dispatch,"courses",'', parseInt($('#pageSizeDropDown').val())?parseInt($('#pageSizeDropDown').val()):1000, 1 ,{_score:{order: "desc"}}, []);
        let elasticSearchQuery = new ElasticSearchQuery();
        elasticSearchQuery.query(ElasticSearchTypes.Course + config.stage, ElasticSearchColumns.Course, this.props,
            '', parseInt($('#pageSizeDropDown').val()) ? parseInt($('#pageSizeDropDown').val()) : 1000, 1, { _score: { order: "desc" } }, [], (results, totalRecords) => {

                let courses = results;

                let learnerPalCourses = []
                let sharedCourses = []
                let customSubjects = []

                if (courses) {
                    courses.sort((a, b) => {
                        if (a.id < b.id) return -1;
                        if (a.id > b.id) return 1;
                        return 0;
                    })

                    courses.forEach(course => {

                        if(course.subject != this.state.selectedSubject) {
                            return;
                        }
                        if (course.ownerName == "LearnerPal") {
                            learnerPalCourses.push(course)
                        } else if (course.sharedWith.length > 0) {
                            sharedCourses.push(course)
                        } else {
                            customSubjects.push(course)
                        }
                    })
                }


                let nodes = [];
                let headingStyles = {
                    color: "#0f6fb4",
                    fontWeight: "bolder",
                    fontSize: "1.3em"
                }, itemStyles = {
                    fontStyle: "italic",
                    color: "#777"
                }

                if (courses) {
                    nodes.push({ categoriesCourses: true, id: "LearnerPal", name: "LearnerPal Shared Courses", disabled: "disabled", style: headingStyles })
                    if (learnerPalCourses.length > 0) {
                        learnerPalCourses.forEach(sub => {
                            nodes.push({ categoriesCourses: true, id: sub.id, name: sub.courseName });
                        })
                    } else {
                        nodes.push({ categoriesCourses: true, id: "---", name: "No course" + " for " + this.state.selectedSubject, disabled: "disabled", style: itemStyles });
                    }
                    nodes.push({ categoriesCourses: true, id: "Shared", name: "Department Shared Courses", disabled: "disabled", style: headingStyles })
                    if (sharedCourses.length > 0) {
                        sharedCourses.forEach(sub => {
                            nodes.push({ categoriesCourses: true, id: sub.id, name: sub.courseName });
                        })
                    } else {
                        nodes.push({ categoriesCourses: true, id: "---", name: "No course" + " for " + this.state.selectedSubject, disabled: "disabled", style: itemStyles });
                    }
                    nodes.push({ categoriesCourses: true, id: "Custom", name: "Teacher Courses", disabled: "disabled", style: headingStyles })
                    if (customSubjects.length > 0) {
                        customSubjects.forEach(sub => {
                            nodes.push({ categoriesCourses: true, id: sub.id, name: sub.courseName });
                        })
                    } else {
                        nodes.push({ categoriesCourses: true, id: "---", name: "No course" + " for " + this.state.selectedSubject, disabled: "disabled", style: itemStyles });
                    }
                }

                this.setState({ nodes }, () => {
                    if (this.state.stopLoading) {
                        this.state.stopLoading = false;
                        this.hideIndicator();
                    }
                });

            }, true, query);
    }

    getCoursesDialog() {

        let context = this;
        let body = <div>
            <label style={{ padding: '10px' }}><span style={{ color: "#0f6fb4" }}>{this.state.selectedTeacher.name}</span> courses:</label>
            <SelectFieldGroup
                field="selectedCourse"
                value={this.state.selectedCourse ? this.state.selectedCourse.id : null}
                options={this.state.nodes}
                placeholder="Select a Course"
                onChange={(evt) => {
                    context.setState({
                        selectedCourse: { id: evt.target.value, name: evt.target.selectedOptions[0].innerText.trim() }
                    })
                }}
            />
        </div>;

        return <Modal id="hint_modal"
            show={this.state.showCourseDialog}
            onHide={() => {
                this.setState({ showCourseDialog: false, selectedCourse: undefined });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Select course you would like to view</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {

                    if (this.state.selectedCourse && this.state.selectedCourse !== 'none') {
                        this.setState({ showCourseDialog: false });

                        browserHistory.push({ pathname: '/reports/course-report-students/' + this.state.selectedCourse.id, state: { courseName: this.state.selectedCourse.name, teacher: this.state.selectedTeacher, allTeachers: this.state.teachersList.slice(1), forAdminReport: true, courseForAdminReport: this.state.selectedCourse, subjectForAdminReport: this.state.selectedSubject,
                            fromAllStudents: this.state.allStudentsView ? this.state.selectedTeacher : false } });

                    }
                }} bsStyle='primary'>Run</Button>
            </Modal.Footer>
        </Modal>;
    }

    getPeriodsByTeacher(teacher) {
        // this.showIndicator();
        let that = this;
        let params = {
            userName: teacher
        };
        let pathTemplate = '/class/{userName}';
        let method = 'GET';
        let additionalParams = {};
        let body = {};
        apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, "2").then(function (result) {
            let periods = result.data.allClasses;
            let periodList = []
            periods.forEach(period => {
                periodList.push({ id: period.period, name: period.period })
            })
            that.setState({ 'periods': periodList });
            // that.hideIndicator();
        }).catch(function (result) {
            that.hideIndicator();
            that.hideIndicator(true);
        });
    }













    exportToExcel(tableID, removeLastHeader, heading) {
        //EXPORT TO EXCEL
        let data_type = 'data:application/vnd.ms-excel';
        let table_div = document.getElementById(tableID).cloneNode(true);

        if (removeLastHeader) {
            table_div.firstElementChild.firstElementChild.firstElementChild.removeChild(table_div.firstElementChild.firstElementChild.firstElementChild.lastChild);
        }
        for (let i = 0; i < table_div.firstElementChild.lastChild.children.length; i++) {
            for (let j = 0; j < table_div.firstElementChild.lastChild.children[i].children.length; j++) {
                if (table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild &&
                    table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild.firstElementChild) {
                    table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild.removeChild(table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild.firstElementChild);

                    // to remove last divs
                    table_div.firstElementChild.lastChild.children[i].removeChild(table_div.firstElementChild.lastChild.children[i].children[j]);
                }
            }
        }
        table_div.firstElementChild.createCaption().innerHTML = '<h2>' + "Admin Report" + ' - ' + heading + '</h2>';
        let table_html = table_div.outerHTML.replace(/ /g, '%20');
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.href = data_type + ', ' + table_html;
        // a.download = 'Student Activity Analysis-' + this.state.currentActivity.activityName + '.xls';
        a.download = 'Admin Report - ' + heading + '.xls';
        a.click();
    }

    printTable(tableID, removeLastHeader, heading) {
        //PRINT TABLE
        let table_div = document.getElementById(tableID) ? document.getElementById(tableID).cloneNode(true) : null;
        if (table_div) {
            if (removeLastHeader) {
                table_div.firstElementChild.firstElementChild.firstElementChild.removeChild(table_div.firstElementChild.firstElementChild.firstElementChild.lastChild);
            }
            for (let i = 0; i < table_div.firstElementChild.lastChild.children.length; i++) {
                for (let j = 0; j < table_div.firstElementChild.lastChild.children[i].children.length; j++) {
                    if (table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild &&
                        table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild.firstElementChild) {
                        table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild.removeChild(table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild.firstElementChild);
                    }
                }
            }
            let myWindow = window.open('', 'PRINT', 'height=600,width=800');
            myWindow.document.write('<html><head><title>' + 'Learner Pal' + '</title>');
            myWindow.document.write('<h1>' + 'Admin Report - ' + heading + '</h1>');
            myWindow.document.write(table_div.outerHTML);
            myWindow.document.write('</body></html>');
            myWindow.focus(); // necessary for IE >= 10*/
            setTimeout(function () {
                myWindow.print();
                myWindow.document.close(); // necessary for IE >= 10
                myWindow.close();
            }, 1000);
        }
        else {
            this.setState({
                showAlert: true,
                alertStyle: 'danger',
                alertText: 'Content not available, can not print. Please contact administrator.'
            });
            setTimeout(() => {
                this.setState({
                    showAlert: false,
                    alertStyle: '',
                    alertText: ''
                })
            }, 5000);
        }
    }











    // exportToExcel(tableID)
    // {
    //     //EXPORT TO EXCEL
    //     let data_type = 'data:application/vnd.ms-excel';
    //     let table_div = document.getElementById(tableID).cloneNode(true);

    //     console.dir(table_div)

    //     table_div.firstElementChild.firstElementChild.firstElementChild.lastElementChild.remove()


    //     for(let i=0; i<table_div.firstElementChild.lastChild.children.length; i++)
    //     {
    //         for (let j=0; j<table_div.firstElementChild.lastChild.children[i].children.length; j++)
    //         {
    //             if (table_div.firstElementChild.lastChild.children[i].children[j] && table_div.firstElementChild.lastChild.children[i].children[j].className && table_div.firstElementChild.lastChild.children[i].children[j].className == "viewUserDetLink")
    //                 table_div.firstElementChild.lastChild.children[i].removeChild(table_div.firstElementChild.lastChild.children[i].children[j])

    //             if (table_div.firstElementChild.lastChild.children[i].children[j] && table_div.firstElementChild.lastChild.children[i].children[j].colSpan && table_div.firstElementChild.lastChild.children[i].children[j].colSpan == 6)
    //                 table_div.firstElementChild.lastChild.children[i].children[j].colSpan = 5;

    //         }
    //     }

    //     // for(let i=0; i<table_div.firstElementChild.lastChild.children.length; i++)
    //     // {
    //     //     for (let j=0; j<table_div.firstElementChild.lastChild.children[i].children.length; j++)
    //     //     {
    //     //         if (table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild &&
    //     //             table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild.firstElementChild)
    //     //         {
    //     //             table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild.removeChild(table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild.firstElementChild);
    //     //         }
    //     //     }
    //     // }


    //     let table_html = table_div.outerHTML.replace(/ /g, '%20');
    //     let a = document.createElement('a');
    //     document.body.appendChild(a);
    //     a.href = data_type + ', ' + table_html;
    //     // a.download = 'Student Activity Analysis-' + this.state.currentActivity.activityName + '.xls';
    //     a.download = 'Admin Report - ' + '.xls';
    //     a.click();
    // }

    // printTable(tableID)
    // {
    //     //PRINT TABLE
    //     let table_div = document.getElementById(tableID)?document.getElementById(tableID).cloneNode(true):null;
    //     if (table_div)
    //     {
    //         table_div.firstElementChild.firstElementChild.firstElementChild.lastElementChild.remove()

    //         for (let i = 0; i < table_div.firstElementChild.lastChild.children.length; i++) {
    //             for (let j = 0; j < table_div.firstElementChild.lastChild.children[i].children.length; j++) {
    //                 if (table_div.firstElementChild.lastChild.children[i].children[j] && table_div.firstElementChild.lastChild.children[i].children[j].className && table_div.firstElementChild.lastChild.children[i].children[j].className == "viewUserDetLink")
    //                     table_div.firstElementChild.lastChild.children[i].removeChild(table_div.firstElementChild.lastChild.children[i].children[j])

    //                 if (table_div.firstElementChild.lastChild.children[i].children[j] && table_div.firstElementChild.lastChild.children[i].children[j].colSpan && table_div.firstElementChild.lastChild.children[i].children[j].colSpan == 6)
    //                     table_div.firstElementChild.lastChild.children[i].children[j].colSpan = 5;

    //             }
    //         }

    //         let myWindow = window.open('', 'PRINT', 'height=600,width=800');
    //         myWindow.document.write('<html><head><title>' + 'Learner Pal' + '</title>');
    //         myWindow.document.write('<h1>' + 'Course Report - ' + '</h1>');
    //         myWindow.document.write(table_div.outerHTML);
    //         myWindow.document.write('</body></html>');
    //         myWindow.focus(); // necessary for IE >= 10*/
    //         setTimeout(function () {
    //             myWindow.print();
    //             myWindow.document.close(); // necessary for IE >= 10
    //             myWindow.close();
    //         }, 1000);
    //     }
    //     else
    //     {
    //         this.setState({
    //             showAlert: true,
    //             alertStyle: 'danger',
    //             alertText: 'Content not available, can not print. Please contact administrator.'
    //         });
    //         setTimeout(()=> {
    //             this.setState({
    //                 showAlert: false,
    //                 alertStyle: '',
    //                 alertText: ''
    //             })
    //         }, 5000);
    //     }
    // }








    // exportToExcel(tableID, removeLastHeader)
    // {
    //     let actName = this.state.currentActivity ? this.state.currentActivity.activityName : null;

    //     //EXPORT TO EXCEL
    //     let data_type = 'data:application/vnd.ms-excel';
    //     let table_div = document.getElementById(tableID).cloneNode(true);

    //     console.dir(table_div)

    //     if(removeLastHeader)
    //     {
    //         table_div.firstElementChild.firstElementChild.firstElementChild.removeChild(table_div.firstElementChild.firstElementChild.firstElementChild.lastChild);
    //     }

    //     for(let i=0; i<table_div.firstElementChild.lastChild.children.length; i++)
    //     {
    //         for (let j=0, length = table_div.firstElementChild.lastChild.children[i].children.length; j < length; j++)
    //         {
    //             if (table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild &&
    //                 table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild.firstElementChild)
    //             {
    //                 table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild.removeChild(table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild.firstElementChild);

    //                 if(j == length-1 && actName)
    //                     table_div.firstElementChild.lastChild.children[i].removeChild(table_div.firstElementChild.lastChild.children[i].children[j]);
    //             }
    //         }
    //     }

    //     table_div.firstElementChild.createCaption().innerHTML = '<h2>' + this.state.assignment.assignmentName + ' - ' + this.state.assignment.periodName + `${actName ? " - " + actName : ''}` + '</h2>';

    //     let table_html = table_div.outerHTML.replace(/ /g, '%20');
    //     let a = document.createElement('a');
    //     document.body.appendChild(a);
    //     a.href = data_type + ', ' + table_html;
    //     a.download = this.state.assignment.assignmentName + ' - ' + this.state.assignment.periodName + `${actName ? " - " + actName : ''}` + '.xls';
    //     a.click();
    // }

    // printTable(tableID, removeLastHeader)
    // {

    //     let actName = this.state.currentActivity ? this.state.currentActivity.activityName : null;

    //     //PRINT TABLE

    //     let table_div = document.getElementById(tableID)?document.getElementById(tableID).cloneNode(true):null;
    //     if (table_div)
    //     {
    //         if(removeLastHeader)
    //         {
    //             table_div.firstElementChild.firstElementChild.firstElementChild.removeChild(table_div.firstElementChild.firstElementChild.firstElementChild.lastChild);
    //         }
    //         for(let i=0; i<table_div.firstElementChild.lastChild.children.length; i++)
    //         {
    //             for (let j=0; j<table_div.firstElementChild.lastChild.children[i].children.length; j++)
    //             {
    //                 if (table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild &&
    //                     table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild.firstElementChild)
    //                 {
    //                     table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild.removeChild(table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild.firstElementChild);
    //                 }
    //             }
    //         }
    //         let myWindow = window.open('', 'PRINT', 'height=600,width=800');
    //         myWindow.document.write('<html><head><title>' + 'Learner Pal' + '</title>');
    //         myWindow.document.write('<h1>' + 'Assignment Report - ' + this.state.assignment.assignmentName + ' - ' + this.state.assignment.periodName + `${actName ? " - " + actName : ''}` + '</h1>');
    //         myWindow.document.write(table_div.outerHTML);
    //         myWindow.document.write('</body></html>');
    //         myWindow.focus(); // necessary for IE >= 10*/
    //         setTimeout(function () {
    //             myWindow.print();
    //             myWindow.document.close(); // necessary for IE >= 10
    //             myWindow.close();
    //         }, 1000);
    //     }
    //     else
    //     {
    //         this.setState({
    //             showAlert: true,
    //             alertStyle: 'danger',
    //             alertText: 'Content not available, can not print. Please contact administrator.'
    //         });
    //         setTimeout(()=> {
    //             this.setState({
    //                 showAlert: false,
    //                 alertStyle: '',
    //                 alertText: ''
    //             })
    //         }, 5000);
    //     }
    // }













    handleAlertDismiss(e) {
        this.setState({ 'showAlert': false });
    }

    showIndicator(percentageLoader) {
        if (percentageLoader) {
            this.i = 0;
            this.localLoaded = false;
            this.setState({ loadingReport: true });
            this.Loadingfunction();
        } else {
            this.setState({
                loading: true
            });
        }
    }

    hideIndicator(percentageLoader) {
        if (percentageLoader) {
            this.setState({
                intervalcount: 100
            });
            // this.i = 0;
            this.localLoaded = true;
            setTimeout(() => {
                this.setState({ loadingReport: false, intervalcount: 0 });
            }, 100);
        } else {
            this.setState({
                loading: false
            });
        }

    }

}

function mapStateToProps(state) {
    return {
        ...state
    }
}

export default connect(mapStateToProps)(AdminReport);
