import React from "react";
import { connect } from "react-redux";
import { Alert, Button, Modal } from "react-bootstrap";
import ProgressIndicator from "../../progress-indicator/progress-indicator";
import Heading from "../../heading/heading";
import "./view-report.css";
import $ from "jquery";
import tick from "../../../assets/images/tick.png";
import { browserHistory } from "react-router";
import ElasticSearchStore from "../../../actions/elastic-search-store";
import MaterialIcon from "react-google-material-icons";
import apiClient from "../../../actions/api-client";
import { Parser } from "html-to-react";
import excelIcon from "../microsoft-excel.png";
import excelIconHover from "../microsoft-excebl.png";
import { ActionIcons } from "../../actionIcons/action-Icons";
import { ActionIconTypes } from "../../../actions/types";
import SelectFieldGroup from "../../forms/select-field-group";
import {
    parseHours,
    parseMinutes,
    parseSeconds
} from '../../../utils/timeParser';

class ViewReport extends React.Component {
    constructor(props) {
        super(props);
        this.getAssignmentReportData = this.getAssignmentReportData.bind(this);
        this.getCourseSidePanel = this.getCourseSidePanel.bind(this);
        this.getMainContentTopHeading = this.getMainContentTopHeading.bind(this);
        this.generateStudentAnalysis = this.generateStudentAnalysis.bind(this);
        this.print = this.print.bind(this);
        this.expandCollapseSideBar = this.expandCollapseSideBar.bind(this);
        this.unitHeadingToggle = this.unitHeadingToggle.bind(this);
        this.activityAnalysis = this.activityAnalysis.bind(this);
        this.studentAnalysis = this.studentAnalysis.bind(this);
        this.getChoiceDialog = this.getChoiceDialog.bind(this);
        this.viewStudentDetails = this.viewStudentDetails.bind(this);
        this.loadStudentDetails = this.loadStudentDetails.bind(this);
        this.getUserDetailsQuestionDetailDialog = this.getUserDetailsQuestionDetailDialog.bind(this);
        this.generateAssignmentAnalysis = this.generateAssignmentAnalysis.bind(this);
        this.sortByActivity = this.sortByActivity.bind(this);
        this.sortByAttributePath = this.sortByAttributePath.bind(this);
        this.getActivityClickDialog = this.getActivityClickDialog.bind(this);
        this.exportToExcel = this.exportToExcel.bind(this);
        this.printTable = this.printTable.bind(this);
        this.handleAlertDismiss = this.handleAlertDismiss.bind(this);

        this.params = this.props.params;
        let contentView = 'student';
        this.estore = new ElasticSearchStore();
        this.state = {
            contentView: contentView,
            view: 'assignment',  // activity | assignment
            currentActivity: null,
            activePanel: 'learnerpal',
            deleteError: false,
            loading: false,
            showChoiceDialog: false,
            record: null,
            showUserDetailsDialog: false,
            userRecord: null,
            showUserDetailsQuestionDetailsDialog: false,
            userRecordQuestion: null,
            showActivityClickDialog: false
        };
    }

    exportToExcel(tableID, removeLastHeader) {
        let actName = this.state.currentActivity ? this.state.currentActivity.activityName : null;

        //EXPORT TO EXCEL
        let data_type = 'data:application/vnd.ms-excel';
        let table_div = document.getElementById(tableID).cloneNode(true);

        console.dir(table_div)

        if (removeLastHeader) {
            table_div.firstElementChild.firstElementChild.firstElementChild.removeChild(table_div.firstElementChild.firstElementChild.firstElementChild.lastChild);
        }

        for (let i = 0; i < table_div.firstElementChild.lastChild.children.length; i++) {
            for (let j = 0, length = table_div.firstElementChild.lastChild.children[i].children.length; j < length; j++) {
                if (table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild &&
                    table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild.firstElementChild) {
                    table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild.removeChild(table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild.firstElementChild);

                    if (j == length - 1 && actName)
                        table_div.firstElementChild.lastChild.children[i].removeChild(table_div.firstElementChild.lastChild.children[i].children[j]);
                }
            }
        }

        table_div.firstElementChild.createCaption().innerHTML = '<h2>' + this.state.assignment.assignmentName + ' - ' + this.state.assignment.periodName + `${actName ? " - " + actName : ''}` + '</h2>';

        let table_html = table_div.outerHTML.replace(/ /g, '%20');
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.href = data_type + ', ' + table_html;
        a.download = this.state.assignment.assignmentName + ' - ' + this.state.assignment.periodName + `${actName ? " - " + actName : ''}` + '.xls';
        a.click();
    }

    printTable(tableID, removeLastHeader) {

        let actName = this.state.currentActivity ? this.state.currentActivity.activityName : null;

        //PRINT TABLE

        let table_div = document.getElementById(tableID) ? document.getElementById(tableID).cloneNode(true) : null;
        if (table_div) {
            if (removeLastHeader) {
                table_div.firstElementChild.firstElementChild.firstElementChild.removeChild(table_div.firstElementChild.firstElementChild.firstElementChild.lastChild);
            }
            for (let i = 0; i < table_div.firstElementChild.lastChild.children.length; i++) {
                for (let j = 0; j < table_div.firstElementChild.lastChild.children[i].children.length; j++) {
                    if (table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild &&
                        table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild.firstElementChild) {
                        table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild.removeChild(table_div.firstElementChild.lastChild.children[i].children[j].firstElementChild.firstElementChild);
                    }
                }
            }
            let myWindow = window.open('', 'PRINT', 'height=600,width=800');
            myWindow.document.write('<html><head><title>' + 'Learner Pal' + '</title>');
            myWindow.document.write('<h1>' + 'Assignment Report - ' + this.state.assignment.assignmentName + ' - ' + this.state.assignment.periodName + `${actName ? " - " + actName : ''}` + '</h1>');
            myWindow.document.write(table_div.outerHTML);
            myWindow.document.write('</body></html>');
            myWindow.focus(); // necessary for IE >= 10*/
            setTimeout(function () {
                myWindow.print();
                myWindow.document.close(); // necessary for IE >= 10
                myWindow.close();
            }, 1000);
        }
        else {
            this.setState({
                showAlert: true,
                alertStyle: 'danger',
                alertText: 'Content not available, can not print. Please contact administrator.'
            });
            setTimeout(() => {
                this.setState({
                    showAlert: false,
                    alertStyle: '',
                    alertText: ''
                })
            }, 5000);
        }
    }

    componentDidMount() {
        let context = this;
        this.showIndicator();

        this.getAssignmentReportData(context.params.id).then(result => {
            context.hideIndicator();
            let activities = [];
            let sorted = [];
            if (result.activities) {
                activities = result.activities;
            }
            sorted = activities.sort((a, b) => {
                return a.activityName.localeCompare(b.activityName);
            });

            let assignment = result;
            let data = assignment.students;
            assignment.students = context.sortByAttributePath('studentName', 'asc', data);
            let state = {
                ...context.state,
                activities: sorted,
                assignment: assignment,
                loading: false
            };
            context.setState(state, function () {
                if (activities.length === 1) {
                    context.unitHeadingToggle(activities[0].activityID);
                }
            })
        }).catch(error => {
            context.hideIndicator();
            context.setState({
                showAlert: true,
                alertStyle: 'danger',
                alertText: 'Assignment cannot be loaded please contact administrator.'
            });
            setTimeout(() => {
                context.setState({
                    showAlert: false,
                    alertStyle: '',
                    alertText: ''
                })
            }, 4000);
        });
    }

    getSidePanelUnitHeadingActivities(subCategories, category) {
        let context = this;
        let result = [];
        if (subCategories) {
            result = subCategories.map(subCat => {
                return <div className="fieldBox" key={subCat} id={subCat} onClick={(evt) => {

                    context.setState({
                        showCountDialog: true,
                        showCountCategory: category,
                        showCountSubCategory: subCat
                    });
                }} data-category={category}>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-alignlft paddnone fieldBoxFullWidth">
                        <label htmlFor="subject2" className="pay_label css-label1"><a
                            href="javascript:void 0">{subCat}</a> </label>
                        {  /* Completed status */
                            context.isActivityComplete(subCat) && context.state.isPractice !== true &&
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-alignlft" style={{ float: 'right' }}>
                                <div className="maincolgreen"><img src={tick} /> Completed</div>
                            </div>
                        }
                        { /* Resume button only if its a practice course */
                            context.isResumeable(subCat) &&
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-alignlft paddnone">
                                <div className="maincolblus" onClick={context.resumeActivity(subCat, category)}>Resume
                                </div>
                            </div>
                        }
                    </div>
                </div>;
            });
        }
        return result;
    }

    unitHeadingToggle(activityID) {
        let context = this;
        this.showIndicator();
        this.getActivityReport(activityID).then(result => {
            result.totalStudents = result.students.length;
            let st = {
                currentActivity: result,
                view: 'activity'
            };
            if (!localStorage.getItem(this.props.auth.user.userName + '-dont-show-info-dialog')) {
                st.showActivityClickDialog = true;
            }
            context.setState(st);
            this.hideIndicator();
        }).catch(error => {
            context.setState({
                showAlert: true,
                alertStyle: 'danger',
                alertText: error.message
            });
            setTimeout(() => {
                context.setState({
                    showAlert: false,
                    alertStyle: '',
                    alertText: ''
                })
            }, 4000);
        });
    }

    getSidePanelUnitHeadings(activities) {
        let context = this;
        let results = [
        ];
        if (activities) {
            let unitCount = 1;
            activities.forEach(activity => {
                if (activity) {
                    let activityTypeDisplay = "";
                    if (activity.activityType) {
                        switch (activity.activityType.toLowerCase()) {
                            case "learning":
                                if (activity.documentType && activity.documentType.toLowerCase() === 'url') {
                                    activityTypeDisplay = "URL Activity";
                                } else if (activity.documentType && activity.documentType.toLowerCase() === 'pdf') {
                                    activityTypeDisplay = "PDF Activity";
                                } else if (activity.documentType && activity.documentType.toLowerCase() === 'video') {
                                    activityTypeDisplay = "Video Activity";
                                }
                                break;
                            case "url":
                                activityTypeDisplay = "URL Activity";
                                break;
                            case "pdf":
                                activityTypeDisplay = "PDF Activity";
                                break;
                            case "video":
                                activityTypeDisplay = "Video Activity";
                                break;
                            case "flashcards":
                                activityTypeDisplay = "Flashcard Activity";
                                break;
                            case "question":
                                if (activity.assessmentType && activity.assessmentType.toLowerCase() === "assessment") {
                                    activityTypeDisplay = "Assessment Question Activity"
                                } else {
                                    activityTypeDisplay = "Practice Question Activity"
                                }
                                break;
                            default:
                        }
                    }

                    let classForActive = context.state.currentActivity && context.state.currentActivity.activityID.toString() === activity.activityID.toString() ? "categoryItem tabActive" : "categoryItem";
                    results.push(
                        <button type="button" className="collapsed" key={'trigger-unit-' + activity.activityID}
                            id={'trigger-unit-' + activity.activityID}
                            onClick={function (evt) {
                                evt.persist();
                                context.unitHeadingToggle(activity.activityID);
                            }.bind(this)}><span
                                className={classForActive}>{activity.activityName} {context.state.currentActivity && context.state.currentActivity.activityID.toString() === activity.activityID.toString() ? <MaterialIcon icon="drafts" size={24} /> : <MaterialIcon icon="mail" size={24} />}<br /><span className="actType">{activityTypeDisplay}</span></span>
                            {context.state.contentView === 'question' && <span className="caret caret-close" />}
                        </button>
                    );
                }
                unitCount++;
            });
        }
        return <div className="overFlowScroll-sm overflww" id={'all-units'}>{results}</div>;
    }

    expandCollapseSideBar(evt) {
        console.log(evt, 'Expand Collapse called.');
        let sidePanel = $("#sidePanel");
        let contentPanel = $("#contentPanel");
        sidePanel.toggleClass('leftBoxWidthMinimize');
        contentPanel.toggleClass('rightBoxWidthMinimize');
    }

    getCategorySidePanel(categories) {
        let context = this;
        if (categories) {
            let subjectName = this.state.assignment.assignmentName;
            let periodName = this.state.assignment.periodName;
            let rawDate = new Date(parseFloat(this.state.assignment.dueDate));
            let dueDate = (rawDate.getUTCMonth() + 1) + '/' + rawDate.getUTCDate() + '/' + rawDate.getUTCFullYear();

            return <div className="anchorLeftBox filterBlockss" id="sidePanel">
                <div className="ArrowDivCollapse "><span className="ArrowExpand resmain" onClick={context.expandCollapseSideBar} /></div>

                {/* Course Heading and collapse arrow */}
                <div className="SubjectBox" style={{background:"rgb(15, 111, 180)", borderBottom: '1px solid #ccc', cursor: 'pointer' }} onClick={() => { this.setState({ view: 'assignment', currentActivity: null }) }}>
                    <div className="limitFilters">
                        {/*<span className="typeIcons"/>*/}
                        <span className="contentType ">
                            <h4>{subjectName} - {periodName}</h4>
                            <h5>{periodName}, Due: {dueDate}</h5>
                        </span>
                    </div>
                </div>
                {/* Course Unit Headings */}
                {context.state.activePanel === 'learnerpal' && context.getSidePanelUnitHeadings(categories)}
            </div>;
        }
    }

    getCourseSidePanel(course) {
        let context = this;

        if (course) {
            let courseName = course.courseName;
            let dueDate = context.state.assignment ? context.state.assignment.dueDate : '';

            let units = course.units ? course.units : [];
            return <div className="anchorLeftBox filterBlockss" id="sidePanel">
                <div className="ArrowDivCollapse "><span className="ArrowExpand resmain"
                    onClick={context.expandCollapseSideBar} /></div>

                {/* Course Heading and collapse arrow */}
                <div className="SubjectBox">
                    <div className="limitFilters">
                        <span className="typeIcons" />
                        <span className="contentType ">
                            <h4>{courseName}</h4>
                            <div className="">{dueDate}</div>
                        </span>
                    </div>
                </div>
                {/* Course Unit Headings */}
                {context.getSidePanelUnitHeadings(units)}

            </div>;
        }
    }

    getActivitySidePanel(act) {
        let context = this;
        if (act) {

            let courseName = context.state.assignment && context.state.assignment.assignedActivities && context.state.assignment.assignedActivities.activityName ? context.state.assignment.assignedActivities.activityName : '';
            let isPractice = context.state.assignment && context.state.assignment.assignedActivities && context.state.assignment.assignedActivities.assessmentType ? context.state.assignment.assignedActivities.assessmentType : '';
            let count = context.state.assignment && context.state.assignment.assignedActivities && context.state.assignment.assignedActivities.count ? context.state.assignment.assignedActivities.count : 0;
            let dueDate = context.state.assignment ? context.state.assignment.dueDate : '';

            return <div className="anchorLeftBox filterBlockss" id="sidePanel">

                <div className="ArrowDivCollapse "><span className="ArrowExpand resmain"
                    onClick={context.expandCollapseSideBar} /></div>

                {/* Course Heading and collapse arrow */}

                <div className="SubjectBox">
                    {isPractice.toLowerCase() === 'practice' && <div className="maincolblusr" style={{
                        float: 'right',
                        marginRight: '80px',
                        display: 'block'
                    }}>{count}</div>}
                    <div className="limitFilters">
                        <span className="typeIcons" />
                        <span className="contentType ">
                            <h4>{courseName}</h4>
                            <div className="">{dueDate}</div>
                        </span>
                    </div>
                </div>
            </div>;
        }
    }

    loadStudentDetails(assignmentID, studentID, activityID) {
        return new Promise((resolve, reject) => {
            let context = this;
            let params = {
                assignmentID: assignmentID,
                studentID: studentID,
                activityID: activityID
            };
            let pathTemplate = '/assignment/report/assignmentID/{assignmentID}/activity/{activityID}/student/{studentID}';
            let method = 'GET';
            let additionalParams = {};
            let body = {};
            apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, '3').then(function (result) {
                resolve(result.data);
            }).catch(function (error) {
                context.setState({
                    showAlert: true,
                    alertStyle: 'danger',
                    alertText: 'Unable to load data, please contact administrator.'
                });
                setTimeout(() => {
                    context.setState({
                        showAlert: false,
                        alertStyle: '',
                        alertText: ''
                    })
                }, 4000);
                reject(error);
            });
        });
    }

    viewStudentDetails(student, fromAssignmentScreen, activityID) {
        let context = this;
        context.showIndicator();
        if (!activityID) {
            activityID = this.state.currentActivity.activityID;
        }
        this.loadStudentDetails(this.state.assignment.assignmentID, student.studentID, activityID).then(result => {
            let allData = result;
            if (allData.length > 0) {
                allData.sort(function (a, b) {
                    return (new Date(b.completionDate) - new Date(a.completionDate));
                });

                let attemptsArray = [];
                let index = 0;
                allData.forEach(attempt => {
                    attemptsArray.push({ id: index, name: new Date(attempt.completionDate).toLocaleString() });
                    index++;
                });
                if (allData[0].completionDate !== 'N/A') {
                    context.setState({
                        showUserDetailsDialog: true,
                        userRecord: allData,
                        attemptsArray: attemptsArray,
                        currentAttempt: 0,
                        userRecordFromAssignmentScreen: fromAssignmentScreen,
                        userRecordActivityID: activityID
                    });
                }
                else {
                    context.setState({
                        showAlert: true,
                        alertStyle: 'danger',
                        alertText: student.studentName + ' has not started/completed the activity'
                    });
                    setTimeout(() => {
                        context.setState({
                            showAlert: false,
                            alertStyle: '',
                            alertText: ''
                        })
                    }, 4000);
                }

            }
            context.hideIndicator();
        }).catch(error => {
            console.error(error);
            context.hideIndicator();
            context.setState({
                showAlert: true,
                alertStyle: 'danger',
                alertText: 'Unable to load student data, please contact administrator.'
            });
            setTimeout(() => {
                context.setState({
                    showAlert: false,
                    alertStyle: '',
                    alertText: ''
                })
            }, 4000);
        })
    }

    generateStudentAnalysis(activity) {
        let context = this;
        let subjectName = this.state.assignment.assignmentName;
        let periodName = this.state.assignment.periodName;
        let activityStats = <table className="table" id="act-stat">
            <tbody>
                <tr>
                    <td><MaterialIcon icon="assignment" size={24} /> Activity Name:</td>
                    <td>{activity.activityName}</td>
                    <td width="35%"><MaterialIcon icon="supervisor_account" size={24} /><strong>Total # of students:</strong></td>
                    <td width="10%">{activity.totalStudents}</td>
                </tr>
                <tr>
                    <td><MaterialIcon icon="timer" size={24} /> Period:</td>
                    <td>{periodName}</td>
                    <td width="35%"><MaterialIcon icon="done" size={24} /><strong># of passed students:</strong></td>
                    <td width="10%">{activity.passedStudents}</td>
                </tr>
                <tr>
                    <td><MaterialIcon icon="assessment" size={24} /> Avg Score:</td>
                    <td>{activity.avgScore ? activity.avgScore.toFixed(0) : 0}{'%'}</td>
                    <td width="35%"><MaterialIcon icon="highlight_off" size={24} /><strong># of failed students:</strong></td>
                    <td width="10%">{activity.failedStudents}</td>
                </tr>
                <tr>
                    <td><MaterialIcon icon="alarm" size={24} /> Avg Time In:</td>
                    <td>
                        {(activity.avgTimer ? parseHours(activity.avgTimer) : 0)}{' hours '}
                        {(activity.avgTimer ? parseMinutes(activity.avgTimer) : 0)}{' mins '}
                        {(activity.avgTimer ? parseSeconds(activity.avgTimer) : 0)}{' seconds'}
                    </td>
                    <td width="35%"><MaterialIcon icon="eject" size={24} /><strong># of incomplete students:</strong></td>
                    <td width="10%">{activity.incompleteStudents}</td>
                </tr>
            </tbody>
        </table>;
        let actType = null;
        if (this.state.currentActivity) {
            actType = this.state.assignment.activities.filter((act) => { return act.activityID === this.state.currentActivity.activityID })[0].activityType;
        }

        let rows = null;
        let rowCount = 0;
        if (activity.students) {
            // let sortedRows = activity.students.sort(function (a, b) {
            //     return (a.studentName.localeCompare(b.studentName))
            // });
            rows = activity.students.map(student => {
                rowCount++;
                return <tr className="" id={"row-" + rowCount} key={"row-" + student.studentID}>
                    <td>
                        {student.studentName}
                    </td>
                    <td>
                        {student.progress ? student.progress.toFixed(0) : 0}{'%'}
                    </td>
                    <td>
                        {student.score ? student.score.toFixed(0) : 0}{'%'}
                    </td>
                    <td>{(student.timeIn ? parseHours(student.timeIn) : 0)}{' hours '}{(student.timeIn ? parseMinutes(student.timeIn) : 0)}{' mins '}{(student.timeIn ? parseSeconds(student.timeIn) : 0)}{' seconds'}</td>
                    {actType !== "learning" && <td>
                        <a href="javascript:void 0" onClick={function () {
                            this.viewStudentDetails(student)
                        }.bind(this)}>
                            <MaterialIcon icon="search" size={24} />
                        </a>
                    </td>}
                </tr>;
            })
        }

        let studentStats =
            <div id="studentActivityAnalysisWrapper">
                <table className="table" id="student-stat">
                    <thead>
                        <tr>
                            <th data-activity-id='studentName' data-order="dsc" onClick={function (evt) {
                                let assignment = this.state.assignment;
                                console.log(activity);
                                let order = evt.target.getAttribute('data-order');
                                let data = activity.students;
                                if (order === 'asc') {
                                    evt.target.setAttribute('data-order', 'dsc');
                                } else {
                                    evt.target.setAttribute('data-order', 'asc');
                                }
                                activity.students = context.sortByAttributePath(evt.target.getAttribute('data-activity-id'), order, data);
                                for (let i = 0; i < assignment.activities.length; i++) {
                                    if (assignment.activities[i].activityID === activity.activityID) {
                                        assignment.activities[i] = activity;
                                        break;
                                    }
                                }
                                context.setState({
                                    assignment: assignment
                                });
                            }.bind(this)}>
                                Student<span className="caret caret-close" />
                            </th>
                            <th data-activity-id='progress' data-order="asc" onClick={function (evt) {
                                let assignment = this.state.assignment;
                                console.log(activity);
                                let order = evt.target.getAttribute('data-order');
                                let data = activity.students;
                                if (order === 'asc') {
                                    evt.target.setAttribute('data-order', 'dsc');
                                } else {
                                    evt.target.setAttribute('data-order', 'asc');
                                }
                                activity.students = context.sortByAttributePath(evt.target.getAttribute('data-activity-id'), order, data);
                                for (let i = 0; i < assignment.activities.length; i++) {
                                    if (assignment.activities[i].activityID === activity.activityID) {
                                        assignment.activities[i] = activity;
                                        break;
                                    }
                                }
                                context.setState({
                                    assignment: assignment
                                });
                            }.bind(this)}>
                                Progress<span className="caret caret-close" />
                            </th>
                            <th data-activity-id='score' data-order="asc" onClick={function (evt) {
                                let assignment = this.state.assignment;
                                console.log(activity);
                                let order = evt.target.getAttribute('data-order');
                                let data = activity.students;
                                if (order === 'asc') {
                                    evt.target.setAttribute('data-order', 'dsc');
                                } else {
                                    evt.target.setAttribute('data-order', 'asc');
                                }
                                activity.students = context.sortByAttributePath(evt.target.getAttribute('data-activity-id'), order, data);
                                for (let i = 0; i < assignment.activities.length; i++) {
                                    if (assignment.activities[i].activityID === activity.activityID) {
                                        assignment.activities[i] = activity;
                                        break;
                                    }
                                }
                                context.setState({
                                    assignment: assignment
                                });
                            }.bind(this)}>
                                Score<span className="caret caret-close" />
                            </th>
                            <th data-activity-id='timeIn' data-order="asc" onClick={function (evt) {
                                let assignment = this.state.assignment;
                                console.log(activity);
                                let order = evt.target.getAttribute('data-order');
                                let data = activity.students;
                                if (order === 'asc') {
                                    evt.target.setAttribute('data-order', 'dsc');
                                } else {
                                    evt.target.setAttribute('data-order', 'asc');
                                }
                                activity.students = context.sortByAttributePath(evt.target.getAttribute('data-activity-id'), order, data);
                                for (let i = 0; i < assignment.activities.length; i++) {
                                    if (assignment.activities[i].activityID === activity.activityID) {
                                        assignment.activities[i] = activity;
                                        break;
                                    }
                                }
                                context.setState({
                                    assignment: assignment
                                });
                            }.bind(this)}>
                                Time In<span className="caret caret-close" />
                            </th>
                            {actType !== "learning" && <th>
                                View Details
                        </th>}
                        </tr>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                </table>
            </div>;

        return (
            <div id="activity-analysis" className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <a className="boardheadblff noHover">Activity Overview </a>
                    <p style={{ overflow: 'auto' }}>
                        {activityStats}
                    </p>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <a className="boardheadblff noHover">Student Activity Analysis </a>
                    <span onMouseOver={(event) => { event.target.firstElementChild.src = excelIconHover; }} onMouseOut={(event) => { event.target.firstElementChild.src = excelIcon; }}
                        className="detailPrintForReport" data-toggle="tooltip" title="Export Report To Excel" data-placement="bottom" data-original-title="Print"
                        onClick={() => {
                            this.exportToExcel('studentActivityAnalysisWrapper', true);
                        }}>
                        <img src={excelIcon} />
                    </span>
                    <ActionIcons iconType={ActionIconTypes.Print} action={function () {
                        context.printTable('studentActivityAnalysisWrapper', true);
                    }} />
                    <p style={{ overflow: 'auto' }}>
                        {studentStats}
                    </p>
                </div>
            </div>
        )
    }

    expandCollapse(evt) {
        let table = null;
        let icon = null;
        if (evt.target.nodeName === 'I') {
            table = evt.target.parentElement.nextElementSibling;
            icon = evt.target;
        } else {
            table = evt.target.nextElementSibling;
            icon = evt.target.firstElementChild;
        }
        if (table.style.display === 'none') {
            table.style.display = 'block';
        } else {
            table.style.display = 'none';
        }
        if (icon && icon.innerHTML === 'add') {
            icon.innerHTML = 'remove';
        } else {
            if (icon) {
                icon.innerHTML = 'add';
            }
        }
    }

    sortByActivity(activityId, order) {
        let assignment = this.state.assignment;
        let id = activityId;
        let sortOrder = order;
        let sorted = assignment.students.sort((a, b) => {

            let aAct = a.activities.filter(act => {
                return act.activityID.toString() === id
            }
            )[0];
            let bAct = b.activities.filter(act => {
                return act.activityID.toString() === id
            }
            )[0];
            if (sortOrder === 'asc') {
                return aAct.score > bAct.score;
            } else {
                return aAct.score < bAct.score;
            }
        });
        assignment.students = sorted;
        this.setState({
            assignment: assignment
        })
    }

    sortByAttributePath(path, order, dataArray) {

        let key = path;
        let sortOrder = order;
        return dataArray.sort((a, b) => {
            let aVal = null;
            key.split('.').forEach(level => {
                if (aVal) {
                    aVal = aVal[level]
                } else {
                    aVal = a[level]
                }
            });
            let bVal = null;
            key.split('.').forEach(level => {
                if (bVal) {
                    bVal = bVal[level]
                } else {
                    bVal = b[level]
                }
            });
            if (sortOrder === 'asc') {
                if (typeof aVal === 'number') {
                    return aVal > bVal;
                } else {
                    return aVal.localeCompare(bVal);
                }
            } else {
                if (typeof aVal === 'number') {
                    return aVal < bVal;
                } else {
                    return bVal.localeCompare(aVal);
                }
            }
        });
    }

    generateAssignmentAnalysis(assignment) {
        let rows = null;
        let rowCount = 0;
        if (assignment.students) {
            rows = assignment.students.map(student => {
                rowCount++;
                let activityColumns = null;
                if (student.activities) {
                    let count = 0;

                    let acts = student.activities.sort(function (a, b) { return a.activityName.localeCompare(b.activityName) });

                    activityColumns = acts.map(act => {
                        count++;

                        return <td data-student-id={student.studentID} data-activity-id={act.activityID} key={assignment.assignmentID + '-' + act.activityID + '-' + count}>
                            <span className="spanWidth">{(act.score ? act.score.toFixed(0) : 0)}%
                                <a id="searchIcon" href="javascript:void 0" onClick={
                                    function (evt) {
                                        this.viewStudentDetails(student, true, act.activityID)
                                    }.bind(this)
                                }><MaterialIcon icon="search" size={24} /></a></span>
                        </td>;
                    })
                }
                return <tr className="" id={assignment.assignmentID + '-' + "row-" + rowCount}
                    key={"row-" + student.studentID}>
                    <td >
                        {student.studentName}
                    </td>
                    <td >
                        {student.avgProgress ? student.avgProgress.toFixed(0) : 0}{'%'}
                    </td>
                    <td >
                        {student.avgScore ? student.avgScore.toFixed(0) : 0}{'%'}
                    </td>
                    {activityColumns}
                </tr>;
            });
        }

        let headers = null;
        if (assignment.students[0].activities) {
            let count = 0;
            let headerActs = assignment.students[0].activities.sort(function (a, b) { return a.activityName.localeCompare(b.activityName) });
            headers = headerActs.map(act => {
                count++;
                return <th key={'th-' + assignment.assignmentID + '-' + act.activityID + '-' + count}
                    data-activity-id={act.activityID} data-order="asc" onClick={function (evt) {
                        let order = evt.target.getAttribute('data-order');
                        if (order === 'asc') {
                            evt.target.setAttribute('data-order', 'dsc');
                        } else {
                            evt.target.setAttribute('data-order', 'asc');
                        }
                        this.sortByActivity(evt.target.getAttribute('data-activity-id'), order);
                    }.bind(this)}>
                    {act.activityName}<span className="caret caret-close" />
                </th>;
            })
        }

        let context = this;
        let studentStats =
            <div id="assignmentReportTable">
                <table className="table" id="assignment-student-stat">
                    <thead>
                        <tr>
                            <th data-activity-id='studentName' data-order="dsc" onClick={function (evt) {
                                let order = evt.target.getAttribute('data-order');
                                let assignment = context.state.assignment;
                                let data = assignment.students;
                                if (order === 'asc') {
                                    evt.target.setAttribute('data-order', 'dsc');
                                } else {
                                    evt.target.setAttribute('data-order', 'asc');
                                }
                                assignment.students = context.sortByAttributePath(evt.target.getAttribute('data-activity-id'), order, data);
                                context.setState({
                                    assignment: assignment
                                });
                            }.bind(this)}>
                                Student<span className="caret caret-close" />
                            </th>
                            <th data-activity-id='avgProgress' data-order="asc" onClick={function (evt) {
                                let assignment = context.state.assignment;
                                let data = assignment.students;
                                let order = evt.target.getAttribute('data-order');
                                if (order === 'asc') {
                                    evt.target.setAttribute('data-order', 'dsc');
                                } else {
                                    evt.target.setAttribute('data-order', 'asc');
                                }
                                assignment.students = context.sortByAttributePath(evt.target.getAttribute('data-activity-id'), order, data);
                                context.setState({
                                    assignment: assignment
                                });
                            }.bind(this)}>
                                Progress<span className="caret caret-close" />
                            </th>
                            <th data-activity-id='avgScore' data-order="asc" onClick={function (evt) {
                                let assignment = context.state.assignment;
                                let data = assignment.students;
                                let order = evt.target.getAttribute('data-order');
                                if (order === 'asc') {
                                    evt.target.setAttribute('data-order', 'dsc');
                                } else {
                                    evt.target.setAttribute('data-order', 'asc');
                                }
                                assignment.students = context.sortByAttributePath(evt.target.getAttribute('data-activity-id'), order, data);
                                context.setState({
                                    assignment: assignment
                                });
                            }.bind(this)}>
                                Assignment Avg.<span className="caret caret-close" />
                            </th>
                            {headers}
                        </tr>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                </table>
            </div>;

        return (
            <div id="assignment-analysis" className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div>
                        <a className="boardheadblff noHover">Assignment Report</a>
                        <span onMouseOver={(event) => {
                            event.target.firstElementChild.src = excelIconHover;
                        }} onMouseOut={(event) => {
                            event.target.firstElementChild.src = excelIcon;
                        }} className="detailPrintForReport" data-toggle="tooltip" title="Export Report To Excel" data-placement="bottom" data-original-title="Print" onClick={() => {
                            this.exportToExcel('assignmentReportTable');
                        }}>
                            <img src={excelIcon} />
                        </span>
                        <ActionIcons iconType={ActionIconTypes.Print} action={function () {
                            context.printTable('assignmentReportTable');
                        }} />
                    </div>
                    <div style={{ overflow: 'auto' }}>
                        {studentStats}
                    </div>
                </div>
            </div>
        )
    }

    questionClick(question) {
        this.setState({
            record: question,
            showChoiceDialog: true
        })
    }

    generateActivityAnalysis(activity) {
        let htmlParser = new Parser();
        let categories = null;
        let catCount = 0;
        let context = this;
        if (activity.categoryStats) {
            categories = activity.categoryStats.map(cat => {
                catCount++;
                let label = cat.category + (cat.subCategory ? ' - ' + cat.subCategory : '');
                return <tr key={cat.category + '-' + catCount} id={cat.category + '-' + catCount}>
                    <td>{label}:</td>
                    <td>
                        {'  ' + (cat.score ? cat.score.toFixed(0) : 0) + '%'}
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12   paddnone barchrtlghtbl"
                            style={{ marginRight: '10px', marginTop: '5px', background: 'white' }}>
                            <div className="filled" style={{ width: (cat.score ? cat.score : 0) + '%', background: (cat.score && cat.score > 64 ? 'green' : 'red') }} />
                        </div>
                    </td>
                </tr>;
            })
        }
        let subjectName = this.state.assignment.assignmentName;
        let periodName = this.state.assignment.periodName;
        let activityStats = <table className="table" id="act-stats">
            <tbody>
                <tr>
                    <td><MaterialIcon icon="assignment" size={24} /> Activity Name:</td>
                    <td>{activity.activityName}</td>
                    <td width="35%"><MaterialIcon icon="supervisor_account" size={24} /><strong>Total # of students:</strong></td>
                    <td width="10%">{activity.totalStudents}</td>
                </tr>
                <tr>
                    <td><MaterialIcon icon="timer" size={24} /> Period:</td>
                    <td>{periodName}</td>
                    <td width="35%"><MaterialIcon icon="done" size={24} /><strong># of passed students:</strong></td>
                    <td width="10%">{activity.passedStudents}</td>
                </tr>
                <tr>
                    <td><MaterialIcon icon="assessment" size={24} /> Avg Score:</td>
                    <td>{activity.avgScore ? activity.avgScore.toFixed(0) : 0}{'%'}</td>
                    <td width="35%"><MaterialIcon icon="highlight_off" size={24} /><strong># of failed students:</strong></td>
                    <td width="10%">{activity.failedStudents}</td>
                </tr>
                <tr>
                    <td><MaterialIcon icon="alarm" size={24} /> Avg Time In:</td>
                    <td>
                        {(activity.avgTimer ? parseHours(activity.avgTimer) : 0)}{' hours '}
                        {(activity.avgTimer ? parseMinutes(activity.avgTimer) : 0)}{' mins '}
                        {(activity.avgTimer ? parseSeconds(activity.avgTimer) : 0)}{' seconds'}
                    </td>
                    <td width="35%"><MaterialIcon icon="eject" size={24} /><strong># of incomplete students:</strong></td>
                    <td width="10%">{activity.incompleteStudents}</td>
                </tr>
            </tbody>
        </table>;

        let actStatsTable = <table className="table" id="act-cat-stats">
            <tbody>
                {categories}
            </tbody>
        </table>;

        let actType = null;
        if (this.state.currentActivity) {
            actType = this.state.assignment.activities.filter((act) => { return act.activityID === this.state.currentActivity.activityID })[0].activityType;
        }

        let rows = null;
        let rowCount = 0;
        if (activity.questionStats) {
            rows = activity.questionStats.map(question => {
                rowCount++;
                let choiceCount = 0;
                let correctChoice = null;
                let choices = question.choiceStats.map(ch => {
                    choiceCount++;
                    if (ch.isCorrect) {
                        correctChoice = ch;
                    }
                    return <li key={ch.choice} style={{ padding: '15px' }}><strong>{choiceCount + '. '}</strong>{htmlParser.parse(ch.choice)} <span className={ch.isCorrect ? 'correctChoice' : 'wrongChoice'} style={{ float: 'right', marginRight: '20px' }}>{ch.answerCount} Answers</span></li>;
                });

                return <tr dir lang={this.props.auth.user.prefLanguage} className="" id={rowCount + "-row-" + rowCount} key={rowCount + "-row-" + question.questionID}>
                    <td className="questionTD">
                        <div className="correctAnswerStatement">
                            <span>{correctChoice && correctChoice.answerCount ? correctChoice.answerCount : 0} students answered correctly</span>
                        </div>
                        <strong style={{ float: 'left', position: 'absolute', marginTop: '28px' }}>{rowCount + '. '}</strong><span style={{ display: 'block', marginLeft: '20px', marginTop: '28px' }}>{htmlParser.parse("" + question.question.replace('[[[LP_ANSWERS]]]', ''))}</span>
                        <br />
                        <p>
                            <ol>
                                {choices}
                            </ol>
                        </p>
                    </td>
                </tr>;
            })
        }

        let studentStats = <table className="table" id="student-stat">
            <thead>
                <tr style={{ background: '#f7f8f9' }}>
                    <th style={{ border: '1px solid #555555' }} data-activity-id='question' width="70%" data-order="asc" onClick={function (evt) {

                        let assignment = this.state.assignment;
                        console.log(activity);
                        let order = evt.target.getAttribute('data-order');
                        let data = activity.questionStats;
                        if (order === 'asc') {
                            evt.target.setAttribute('data-order', 'dsc');
                        } else {
                            evt.target.setAttribute('data-order', 'asc');
                        }
                        activity.questionStats = context.sortByAttributePath(evt.target.getAttribute('data-activity-id'), order, data);
                        for (let i = 0; i < assignment.activities.length; i++) {
                            if (assignment.activities[i].activityID === activity.activityID) {
                                assignment.activities[i] = activity;
                                break;
                            }
                        }
                        context.setState({
                            assignment: assignment
                        });
                    }.bind(this)}>
                        Question
                </th>
                </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </table>;

        return (
            <div id="activity-analysis" className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="icondeshead">
                        <a className="boardheadblf noHover">Activity Overview</a>
                    </div>
                    <p style={{ overflow: 'auto' }}>
                        {activityStats}
                    </p>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="icondeshead">
                        <a className="boardheadblf noHover">Category Analysis </a>
                    </div>
                    <p style={{ overflow: 'auto' }}>
                        {actStatsTable}
                    </p>
                </div>
                {actType === 'question' &&
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="icondeshead">
                            <a className="boardheadblf noHover">Class Question Analysis</a>
                        </div>
                        <p style={{ overflow: 'auto' }}>
                            {studentStats}
                        </p>
                    </div>}
            </div>
        )
    }

    getMainContent(contentView, view) {
        if (view === 'activity') {
            if (contentView === 'student') {

                return (
                    this.generateStudentAnalysis(this.state.currentActivity)
                )
            } else if (contentView === 'activity') {
                return (
                    this.generateActivityAnalysis(this.state.currentActivity)
                )
            }
        } else if (view === 'assignment') {

            if (this.state.assignment && this.state.activities) {
                return (
                    this.generateAssignmentAnalysis(this.state.assignment)
                )
            }
        }
    }

    handleAlertDismiss(e) {
        this.setState({ 'showAlert': false });
    }

    getMainContentTopHeading() {
        // set heading, timer and breadcrumbs. for type questions or flashcard or learning.

        let currentActivity = this.state.currentActivity ? this.state.currentActivity : null;
        let currentUnit = this.state.currentUnit ? this.state.currentUnit : null;
        let isPractice = currentActivity && currentActivity.assessmentType && currentActivity.assessmentType.toLowerCase() === 'practice';
        let course = this.state.assignment && this.state.assignment.assignedCourses ? this.state.assignment.assignedCourses : null;

        if (this.state.view === 'activity') {
            return this.state.currentActivityComplete === false ? <div className="eleRow hidden-xs">

                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6 text-left">
                    <h3>{this.params.id} </h3>
                </div>

                {this.state.contentView === 'question' &&
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6 text-center">
                        <h3>Current Question:
                        <span style={{ color: '#0f6fb4' }}>{this.state.currentQuestionIndex + 1}
                                / {this.state.unansweredQuestions.length}</span>
                        </h3>
                    </div>}

            </div> : null;
        } else {
            return this.state.currentActivityComplete === false ? <div className="eleRow hidden-xs">

                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6 text-left">
                    <h3>{currentActivity.activityName}
                    </h3>
                </div>
            </div> : null;
        }
    }

    print() {
        console.log('Print called - only for question activity.');

    }

    studentAnalysis() {
        this.setState({
            contentView: 'student'
        })
    }

    activityAnalysis() {
        this.setState({
            contentView: 'activity'
        })
    }

    render() {
        let context = this;
        let activities = context.state.activities;
        let content = null;
        if (context.state.currentActivity) {
            content = this.getMainContent(context.state.contentView, context.state.view);
        }
        if (context.state.assignment && context.state.view === 'assignment') {
            content = this.getMainContent(context.state.contentView, context.state.view);
        }
        let text = 'Please select an activity.';
        return (
            <div className="container-fluid noPad listMainBoxWrapper " id="report-view">
                <Heading
                    isHideHeading={false}
                    allButtons={[]}
                    isCreateButton={false}
                    dataPath={'studentPortal.assignments.results'}
                    allRecords={'studentPortal.assignments.allRecords'}
                    actionName={'SEARCH_RESULT'}
                    filteredData={'studentPortal.assignments.filteredRecords'}
                    listPage={'activities'}
                    heading="Assignment"
                    isShowButton={false}
                    isSearch={false}
                    breadcrumbsData={[
                        {
                            name: 'Reports', action: () => {
                                browserHistory.push('/reports');
                            }
                        },
                        {
                            name: 'Assignment Reports', action: () => {
                                browserHistory.push('/reports/assignments');
                            }
                        },
                        {
                            name: 'View Assignment Report'
                        }
                    ]}
                    showFilterButtons={'false'}
                />
                {this.state.showAlert &&
                    <div className="row">
                        <div className="col-md-3 col-xs-3" />
                        <div className="col-md-6 col-xs-3">
                            <Alert bsStyle={this.state.alertStyle}
                                onDismiss={this.handleAlertDismiss}>
                                {this.state.alertText}
                            </Alert>
                        </div>
                        <div className="col-md-3 col-xs-3" />
                    </div>
                }
                <div id="submission" className="tab-pane fade active in">

                    {/* Side panel for Course view */}
                    {activities && this.getCategorySidePanel(activities)}
                    {/* Side panel for Activity view */}
                    <div className="mainActivBoxRight" id="contentPanel">
                        {/* Tabs. */}
                        {this.state.view === 'activity' &&
                            <div className="col-md-4 col-xs-3" style={{ marginTop: '10px', marginLeft: '30px' }}>
                                <SelectFieldGroup
                                    isDisabled={false}
                                    field="departments"
                                    value={this.state.contentView}
                                    options={[{ id: 'student', name: 'Student Analysis' }, {
                                        id: 'activity',
                                        name: 'Class Analysis'
                                    }]}
                                    onChange={(event) => {
                                        let value = event.target.value;
                                        this.setState({ contentView: value }, function () {
                                            if (value === 'activity') {
                                                context.activityAnalysis();
                                            }
                                            else {
                                                context.studentAnalysis();
                                            }
                                        });
                                    }}
                                    isColored={true}
                                /></div>
                        }
                        {/*<div id="flashcard-btns">*/}
                        {/*<div id="flashcard-nav">*/}
                        {/*<a href="javascript:void 0" onClick={this.studentAnalysis} className={this.state.contentView==='student'?'active':''}><MaterialIcon icon="person" size={24}/> Student Analysis</a>*/}
                        {/*<span> | </span>*/}
                        {/*<a href="javascript:void 0" onClick={this.activityAnalysis} className={this.state.contentView==='activity'?'active':''}><MaterialIcon icon="people" size={24}/> Class Analysis</a>*/}
                        {/*</div>*/}
                        {/*</div>*/}
                        {/* Main content heading and breadcrumbs */}

                        {context.state.contentView === 'question' && this.getMainContentTopHeading()}

                        {/* content for current activity */}
                        {this.state.currentActivity && <div className="" id="gray-box-container">
                            <div className="container-fluid questionMainBlock" style={{ margin: '0 20px 108px 20px' }}>
                                <div className="quesAndIconStudent">
                                    {content}
                                </div>
                            </div>
                        </div>}
                        {this.state.view === 'assignment' && this.state.assignment && <div className="" id="gray-box-container">
                            <div className="container-fluid questionMainBlock" style={{ margin: '0 20px 108px 20px' }}>
                                <div className="quesAndIconStudent">
                                    {content}
                                </div>
                            </div>
                        </div>}
                    </div>

                </div>
                <ProgressIndicator show={this.state.loading} />
                {this.state.showChoiceDialog && this.getChoiceDialog()}
                {this.state.showActivityClickDialog && this.getActivityClickDialog()}
                {this.state.showUserDetailsDialog && this.getUserDetailsDialog()}
                {this.state.showUserDetailsQuestionDetailsDialog && this.getUserDetailsQuestionDetailDialog()}
            </div>
        );
    }

    showIndicator() {
        this.setState({
            ...this.state,
            loading: true
        });
    }

    hideIndicator() {
        this.setState({
            ...this.state,
            loading: false
        });
    }

    getAssignmentReportData(id) {
        let context = this;
        context.showIndicator();
        return new Promise((resolve, reject) => {
            let params = {
                assignmentID: id
                // studentID: this.props.auth.user.userName
            };
            let pathTemplate = '/assignment/report/assignmentID/{assignmentID}';
            let method = 'GET';
            let additionalParams = {};
            let body = {};
            apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, '3').then(function (result) {

                resolve(result.data);
            }).catch(function (error) {
                context.setState({
                    showAlert: true,
                    alertStyle: 'danger',
                    alertText: 'Unable to load data, please contact administrator.'
                });
                setTimeout(() => {
                    context.setState({
                        showAlert: false,
                        alertStyle: '',
                        alertText: ''
                    })
                }, 4000);
                reject(error);
            });
        });
    }

    getActivityReport(activityID) {
        let context = this;
        context.showIndicator();
        return new Promise((resolve, reject) => {

            let params = {
                assignmentID: this.state.assignment.assignmentID,
                activityID: activityID
            };
            let pathTemplate = '/assignment/report/assignmentID/{assignmentID}/activity/{activityID}';

            apiClient.invokeApi(params, pathTemplate, 'GET', {}, {}, '3').then(function (result) {
                let data = result.data.students;
                result.data.students = context.sortByAttributePath('studentName', 'asc', data);
                resolve(result.data);
            }).catch(function (error) {
                context.setState({
                    showAlert: true,
                    alertStyle: 'danger',
                    alertText: 'Unable to load data, please contact administrator.',
                    loading: false
                });
                setTimeout(() => {
                    context.setState({
                        showAlert: false,
                        alertStyle: '',
                        alertText: ''
                    })
                }, 4000);
                reject(error);
            });

        });
    }

    getChoiceDialog() {
        let record = this.state.record;
        let rows = null;
        let rowCount = 0;
        if (record) {
            rows = record.choiceStats.map(choice => {
                rowCount++;
                return <tr className="" id={choice.choice + "row-" + rowCount} key={choice.choice + "row-" + rowCount}>
                    <td>
                        {choice.choice}
                    </td>
                    <td>
                        <a href="javascript:void 0"
                            className={choice.isCorrect === true ? 'choice-green' : 'choice-red'}>{choice.score} {choice.isCorrect === true ? ' Correct Answered' : ' Answered'}</a>
                    </td>
                </tr>;
            })
        }
        let table = <table className="table" id="act-stats-choices">
            <thead>
                <th>
                    Question Choices
            </th>
                <th>
                    Score
            </th>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </table>;

        return <Modal id="choice-dialog"
            show={this.state.showChoiceDialog}
            onHide={() => {
                this.setState({ showChoiceDialog: false });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Question Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div id="choice-dialog-content">
                    {table}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    this.setState({ showChoiceDialog: false });
                }}>Close</Button>
            </Modal.Footer>
        </Modal>
    }

    getActivityClickDialog() {

        return <Modal id="choice-dialog"
            show={this.state.showActivityClickDialog}
            onHide={() => {
                this.setState({ showActivityClickDialog: false });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Activity Report</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div id="activity-click-dialog-content">
                    <p>
                        Select student analysis or class analysis at top for a detailed analysis on this activity.
                    </p>
                    <hr />
                    <p>
                        <strong>Student Analysis: </strong>view individual student details.
                    </p>
                    <p>
                        <strong>Class Analysis: </strong>view your class performance and totals by questions and answer.
                    </p>
                    <p>
                        <div className="fieldBox" style={{ paddingLeft: '29px' }}>

                        </div>
                    </p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <input type="checkbox" value='dont-show' id="activity-click-dialog-dont-show-again"
                    className="css-checkbox" />
                <label htmlFor="activity-click-dialog-dont-show-again" className="pay_label css-label1">Don&apos;t show again.</label>
                <Button onClick={() => {
                    if (document.getElementById("activity-click-dialog-dont-show-again").checked) {
                        localStorage.setItem(this.props.auth.user.userName + '-dont-show-info-dialog', "true");
                    }
                    this.setState({ showActivityClickDialog: false });
                }}>Ok</Button>
            </Modal.Footer>
        </Modal>
    }

    userQuestionDetails(question) {
        this.setState({
            userRecordQuestion: question,
            showUserDetailsQuestionDetailsDialog: true
        })
    }

    getUserDetailsDialog() {

        let record = this.state.userRecord[this.state.currentAttempt];
        let userRecordFromAssignmentScreen = this.state.userRecordFromAssignmentScreen;
        let userRecordActivityID = this.state.userRecordActivityID;
        let dialogViewActivity = null;
        let actType = null;
        if (userRecordFromAssignmentScreen) {
            // from main assignment snapshot screen.
            dialogViewActivity = this.state.assignment.activities.filter(act => { return act.activityID === userRecordActivityID })[0];
            actType = dialogViewActivity.activityType;
        } else {
            // from inner student details table.
            dialogViewActivity = this.state.currentActivity;
            actType = this.state.assignment.activities.filter(act => { return act.activityID === this.state.currentActivity.activityID })[0].activityType;
        }
        let categories = null;
        let catCount = 0;
        if (record.categoryStats) {
            categories = record.categoryStats.map(cat => {
                catCount++;
                let label = cat.category + (cat.subCategory ? ' - ' + cat.subCategory : '');
                return <tr key={cat.category + '-' + catCount} id={cat.category + '-' + catCount}>
                    <td>{label}:</td>
                    <td>
                        {/*{cat.correctAnswers + ' out of ' + cat.total + ' questions correct (' +(cat.score?cat.score.toFixed(0):0) + '%)'}*/}
                        {(cat.score ? cat.score.toFixed(0) : 0) + '% (' + cat.correctAnswers + ' out of ' + cat.total + ' questions correct)'}
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12   paddnone barchrtlghtbl"
                            style={{ marginRight: '10px', marginTop: '5px', background: 'white' }}>
                            <div className="filled" style={{ width: (cat.score ? cat.score : 0) + '%', background: (cat.score && cat.score > 64 ? 'green' : 'red') }} />
                        </div>
                    </td>
                </tr>;
            })
        }

        let catStats = <table className="table" id="act-cat-stats-user">
            <tbody>
                {categories}
            </tbody>
        </table>;

        let rows = null;
        let correctNumbers = 0;
        let rowCount = 0;
        let htmlToReactParser = new Parser();
        if (record.questionStats) {
            rows = record.questionStats.map(question => {
                rowCount++;

                if (question.essayType && question.essayType == "true") {
                    if (question.essayScore != "N/A") {
                        question.questionResult = question.essayScore < 65 ? false : true
                    }
                    if (!question.essayComments && question.essayScore && question.essayScore != "N/A") {
                        question.essayComments = "<i>No Comments</i>"
                    }
                }

                if (question.questionResult) {
                    correctNumbers += 1;
                }

                let answerString = undefined;

                if (actType === 'question') {
                    answerString = (question.studentAnswer.trim() !== 'empty-result' && question.studentAnswer.trim() !== 'empty-answer') ? question.studentAnswer : 'No Answer Submitted';
                }
                return <tr dir lang={this.props.auth.user.prefLanguage} className="" id={rowCount + "-user-row-" + rowCount}
                    key={rowCount + "-user-row-" + question.questionID}>
                    <td style={{ border: '1px solid #555555' }}>
                        <p className="QuestionNoBorder">
                            <strong style={{ float: 'left' }}>{rowCount + '. '} {question.questionResult ? <span style={{ color: 'green' }}><MaterialIcon icon="check" size={32} /></span> : <span style={{ color: 'red' }}><MaterialIcon icon="close" size={32} /></span>}</strong>{htmlToReactParser.parse(`<span dir lang = ${this.props.auth.user.prefLanguage}>` + question.question.replace('[[[LP_ANSWERS]]]', '') + '</span>')}
                        </p>
                        <br />

                        {question.answer && <div>
                            <div className="std-q-heading">Correct Answer</div>
                            <p>
                                {htmlToReactParser.parse(`<span dir lang = ${this.props.auth.user.prefLanguage}>` + question.answer + '</span>')}
                            </p>
                        </div>
                        }

                        <div className="std-q-heading">Student&apos;s Response</div>
                        <p style={{ whiteSpace: "pre-line", textAlign: "justify" }}>
                            {htmlToReactParser.parse(`<span dir lang = ${this.props.auth.user.prefLanguage}>` + answerString + '</span>')}
                        </p>

                        {question.essayType && question.essayType == "true" && question.essayComments ? <div>
                            <div className="std-q-heading">Teacher&apos;s Comment</div>
                            <p>
                                {htmlToReactParser.parse(`<span dir lang = ${this.props.auth.user.prefLanguage}>` + question.essayComments + '</span>')}
                            </p>
                        </div> : ""
                        }

                        {question.essayType && question.essayType == "true" && question.essayScore ? <div>
                            <div className="std-q-heading">Score</div>
                            <p>
                                {question.essayScore == "N/A" ? htmlToReactParser.parse('<span>' + question.essayScore + '</span>') : (question.essayScore < 65 ? (htmlToReactParser.parse('<span style=" color: red; ">' + question.essayScore + '</span>')) : (htmlToReactParser.parse('<span style=" color: green; ">' + question.essayScore + '</span>')))}
                            </p>
                        </div> : ""
                        }

                    </td>

                    {false && dialogViewActivity.activityType === 'question' &&
                        <td>
                            <a href="javascript:void 0" onClick={function () {
                                this.userQuestionDetails(question)
                            }.bind(this)}><MaterialIcon icon="search" size={24} /></a>
                        </td>
                    }
                </tr>;
            })
        }

        let activityStats = <table className="table" id="act-stats-user">
            <tbody>
                <tr>
                    <td><MaterialIcon icon="assignment" size={24} /> Activity Name:</td>
                    <td>{dialogViewActivity.activityName}</td>
                </tr>
                <tr>
                    <td><MaterialIcon icon="accessibility" size={24} /> Student Name:</td>
                    <td>{record.studentName}</td>
                </tr>
                <tr>
                    <td><MaterialIcon icon="assessment" size={24} /> Score:</td>
                    <td>{(record.score ? record.score.toFixed(0) : 0) + '% (' + correctNumbers + ' out of ' + (record.questionStats ? record.questionStats.length : 0) + ' questions correct)'}</td>
                </tr>
                <tr>
                    <td><MaterialIcon icon="alarm" size={24} /> Time In:</td>
                    <td>{(record.timeIn ? parseHours(record.timeIn) : 0)}{' hours '}{(record.timeIn ? parseMinutes(record.timeIn) : 0)}{' mins '}{(record.timeIn ? parseSeconds(record.timeIn) : 0)}{' seconds'}</td>
                </tr>
                <tr>
                    <td><MaterialIcon icon="date_range" size={24} /> Completion Date:</td>
                    {record.completionDate != "N/A" && <td>{new Date(record.completionDate).toLocaleString()}</td>}
                    {record.completionDate === "N/A" && <td>{record.completionDate}</td>}
                </tr>
            </tbody>
        </table>;

        let studentStats = <table className="table" id="student-stat">
            <thead>
                <tr>
                    <th style={{ background: '#f7f8f9', border: '1px solid #555555' }}>
                        Questions
                </th>
                    {dialogViewActivity.activityType === 'question' && false && <th>
                    </th>}
                </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </table>;

        let table = <div id="user-activity-analysis" className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <h1 className="reportHeading">Student Details </h1>
                <p style={{ overflow: 'auto' }}>
                    {activityStats}
                </p>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <h1 className="reportHeading"  >Category Analysis </h1>
                <p style={{ overflow: 'auto' }}>
                    {catStats}
                </p>
            </div>
            {actType === 'question' &&
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <h1 className="reportHeading">Student Question Analysis </h1>
                    <p style={{ overflow: 'auto' }}>
                        {studentStats}
                    </p>
                </div>}
        </div>;
        return <Modal id="user-detail-dialog"
            show={this.state.showUserDetailsDialog}
            onHide={() => {
                this.setState({ showUserDetailsDialog: false, userRecord: null, userRecordFromAssignmentScreen: null, userRecordActivityID: null });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Student Details</Modal.Title>
                {this.state.attemptsArray.length > 1 && <div className="col-md-4" style={{ marginTop: '20px' }}>
                    <SelectFieldGroup
                        field="currentAttempt"
                        value={this.state.currentAttempt}
                        options={this.state.attemptsArray}
                        onChange={(event) => {
                            this.setState({ currentAttempt: event.target.value });
                        }}
                    />
                </div>}
            </Modal.Header>
            <Modal.Body>
                <div id="choice-dialog-content">
                    {table}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    this.setState({ showUserDetailsDialog: false, userRecord: null, userRecordFromAssignmentScreen: null, userRecordActivityID: null });
                }}>Close</Button>
            </Modal.Footer>
        </Modal>
    }

    getUserDetailsQuestionDetailDialog() {

        let record = this.state.userRecordQuestion;

        let rows = null;
        let rowCount = 0;
        if (record.choices) {
            rows = record.choices.map(choice => {
                rowCount++;
                return <tr className="" id={rowCount + "-user-question-row-" + choice.choice}
                    key={rowCount + "-user-row-" + record.questionID + + choice.choice}>
                    <td>
                        {choice.choice}
                    </td>
                    <td>
                        {choice.isCorrect && <a href="javascript:void 0" className={choice.isCorrect === true ? 'choice-green' : 'choice-red'}>{choice.isCorrect === true ? ' Correct Answer' : ' Wrong Answer'}</a>}
                        {choice.studentSelection && <a href="javascript:void 0" className={choice.studentSelection === true && choice.isCorrect ? 'choice-green' : 'choice-red'}>Student Selection</a>}
                        {!choice.isCorrect && <a href="javascript:void 0" className='choice-red'>Wrong Answer</a>}
                    </td>
                </tr>;
            })
        }

        let questionStats = <table className="table" id="student-question-stat">
            <thead>
                <tr>
                    <th>
                        Choices
                </th>
                    <th>
                        Result
                </th>
                </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </table>;


        let table = <div id="user-activity-analysis" className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <p style={{ overflow: 'auto' }}>
                    {questionStats}
                </p>
            </div>
        </div>;

        return <Modal id="user-detail-question-details-dialog"
            show={this.state.showUserDetailsQuestionDetailsDialog}
            onHide={() => {
                this.setState({ showUserDetailsQuestionDetailsDialog: false, userRecordQuestion: null });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Question Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div id="question-choice-dialog-content">
                    {table}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    this.setState({ showUserDetailsQuestionDetailsDialog: false, userRecordQuestion: null });
                }}>Close</Button>
            </Modal.Footer>
        </Modal>
    }
}

function mapStateToProps(state) {
    return {
        ...state
    }
}

export default connect(mapStateToProps)(ViewReport);
