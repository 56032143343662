/**
 * Created by ashahab on 8/24/2017.
 */

import React, {Component} from "react";
import {connect} from "react-redux";
import SelectFieldGroup from "../../../forms/select-field-group";
import {browserHistory} from "react-router";

class WidgetOpenAssignments extends Component {

    constructor(props) {
        super(props);

        this.headinText = props.headinText;
        
        this.state = {selectedFilter:'',sortByDropDown:''};
    }

    getAssignmentClass(record)
    {
        let className = 'minnine ';
        // let assignType = record.assignmentType ? record.assignmentType.toLowerCase() : 'learning';
        // if (assignType === 'course') {
        //
        //     className += ' booksRow';
        // } else if (assignType === 'activity') {
        let type = record.activityType ? record.activityType.toLowerCase() : '';
        
        switch (type) {
            case 'url':
            case 'pdf':
            case 'learning':
                className += 'learning';
                break;
            case 'question':
            case 'questions':
                className += 'questionRow';
                break;
            case 'flashcard':
            case 'flashcards':
                className += 'flashCard';
                break;
            case 'course':
                className += 'booksRow';
        }
        // }
        // else {
        //     className += 'learning';
        // }
        return className;
    }

    gotoManageAssignment(data)
    {
        browserHistory.push({
            pathname: '/assignments',
            state: {assignmentFromDashboard:data}
        });
    }

    render() {
        

        // let allFilters = this.props.dataForFilter ? this.props.dataForFilter:[];
        // if (allFilters.length>0)
        // {
        //     allFilters.forEach(filter=>{
        //         delete filter.count;
        //         filter.id = filter.name;
        //     });
        // }
        let that = this;
        let allDataHtml=[];
        let allData = this.props.data;
        if (allData && allData.length>0)
        {
            allData.forEach(data=>
            {
                let rawDate = new Date (parseFloat(data.dueDate));
                let dueDate = (rawDate.getUTCMonth()+1)+'/'+rawDate.getUTCDate()+'/'+rawDate.getUTCFullYear();

                // let progress = data.progress.toString().split('%');
                allDataHtml.push(
                    <div>
                        <a className="boardheadgrey" onClick={()=>{browserHistory.push('/reports/view/' + data.id)}} target="_blank">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12  "  style={{cursor:'pointer'}}>
                                <div className="icondes">
                                    <div className="limitFiltersas">
                                        <span className={this.getAssignmentClass(data)}/>
                                    </div>
                                </div>
                                <div className="icondeshead">
                                    <a className="boardhead">{data.assignmentName}</a>
                                    <a className="boardheadgrey">{Math.round(parseInt(data.assignmentProgress))}% Complete</a>
                                    <a className="boardheadgrey"><b>Due:</b> {dueDate}</a>
                                    <div className="col-lg-5 col-md-6 col-sm-6 col-xs-6 text-alignlft paddnone"/>
                                </div>
                                <div className="icondesheadsel">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                                        <a className="boardheadgrey"><b>Class:</b> {data.periodName}</a>
                                        <a className="boardheadgrey"><b>Status:</b> {data.assignstatus}</a>
                                    </div>
                                </div>
                                {data.essayInReview && data.essayInReview.toLowerCase() === 'true' && <span className="reviewPend">Essay waiting for review</span>}
                            </div>
                            <div  className="brdrds"/></a>
                    </div>
                );
            });
        }
        return (

            <div  className="dasboxss col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{marginBottom:'0px', paddingBottom:'0px'}}>
                <div  className="col-lg-12 col-md-12 col-sm-12 col-xs-12  pacboxdes" style={{marginBottom:'0px' }}>
                    <div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12  ">
                            <div className="icondes">
                                <div className="limitFiltersas">
                                    <span className="typeIconsorn minnine"/>
                                </div>
                            </div>
                            <div className="icondeshead">
                                <a className="boardheadblf noHover"href="#">{that.headinText}<br /><span>(assigned activities or courses)</span></a>
                                <div style={{marginLeft:'-47px'}}/>
                            </div>
                            <div className="float-lft">
                                <SelectFieldGroup
                                    isDisabled={false}
                                    field="sortByDropDown"
                                    value={this.state.sortByDropDown}
                                    options={[{id:'dueDate', name:'Due Date'},{id:'assignDate', name:'Date Assigned'}]}
                                    onChange={(evt)=>{
                                        this.setState({sortByDropDown:evt.target.value});
                                        this.props.onFilterChange(evt);
                                    }}
                                />
                            </div>
                        </div>
                        < div  className="brdrds"/>
                    </div>
                    <div className="dashboardWidgetContainer">
                        {that.props.data && allDataHtml }
                    </div>
                    <div>
                        <a className="lsthed" href="#" onClick={that.props.onClickViewAll}>View Past Assignments</a>
                    </div>
                </div>
            </div>
        );
    }
}


function mapStateToProps(state = {}) {
    return {...state};
}

export default connect(mapStateToProps)(WidgetOpenAssignments);
