import React, { Component } from "react";
import Heading from "../heading/heading";
import Filter from "../filter/filter";
import { connect } from "react-redux";
import ElasticSearchActions from "../../actions/elastic-search-actions";
import ManageActivityActions from "../../actions/manage-activities-actions";
import MaintenanceTable from "../maintenance-table/maintenance-table";
import ActivityMaintenanceTableMobile from "../activity-maintenance-table-mobile/activity-maintenance-table-mobile";
import apiClient from "../../actions/api-client";
import { detect } from 'detect-browser'
import {
    RECEIVED_ACTIVITY_RESULTS,
    RCEIVED_VIEW_ACTIVITY,
    ActionIconTypes,
    FAILED_VIEW_ACTIVITY,
    SHOW_FULL_QUESTION,
    START_VIEW_ACTIVITY,
    CREATE_QUESTION_SET_INIT_INFO,
    CREATE_FLASHCARD_SET_INIT_INFO,
    ElasticSearchTypes,
    ElasticSearchColumns
} from "../../actions/types";
import $ from "jquery";
import { Modal, Button, Alert } from "react-bootstrap";
import { ActionIcons } from "../actionIcons/action-Icons";
import SelectFieldGroup from "../forms/select-field-group";
import TextFieldGroup from "../forms/text-field-group";
import validateInput from "../../validations/create-activity";
import ReactPDF from "react-pdf";
import { browserHistory } from "react-router";
import LoginUtils from "../common/login-utils";
import Breadcrumb from "../breadcrumb/breadcrumb";
import ProgressIndicator from "../progress-indicator/progress-indicator";
import config from "../../config";
import classnames from "classnames";
import { s3Upload } from "../activities/uploader/s3-uploader";
import ManageCourseActions from "../../actions/manage-courses-actions";
import { Parser } from "html-to-react";
import ReactScrollPagination from 'react-scroll-pagination'
import ElasticSearchQuery from "../../actions/elastic-search-query";
// import axios from "axios";
import logo from '../../assets/images/check_file1600.png'
import icon from '../../assets/images/icon.png'
import icon1 from '../../assets/images/icon1.png'
import icon3 from '../../assets/images/icon3.png'
import icon4 from '../../assets/images/check_file1600.png'
import activity_question from '../../assets/images/activity-question.png'
import activity_flashcard from '../../assets/images/activity-flashcard.png'
import activity_pdf from '../../assets/images/activity-pdf.png'
import activity_viedo from '../../assets/images/activity-video.png'
import activity_url from '../../assets/images/activity-url.png'
import ClevertapReact from "clevertap-react";
import MaterialIcon from "react-google-material-icons";
import ReactPlayer from 'react-player';
import GooglePicker from 'react-google-picker';
import googleIcon from './drive.png';
class ManageActivities extends Component {

    constructor(props) {
        super(props);
        // This binding is necessary to make `this` work in the callback
        this.searchBarChangeHandler = this.searchBarChangeHandler.bind(this);
        this.viewActivity = this.viewActivity.bind(this);
        this.getHtmlForViewActivity = this.getHtmlForViewActivity.bind(this);
        this.showFullQuestion = this.showFullQuestion.bind(this);
        this.closeViewActivity = this.closeViewActivity.bind(this);
        this.hideFullQuestion = this.hideFullQuestion.bind(this);
        this.getDeleteDialog = this.getDeleteDialog.bind(this);
        this.deleteActivity = this.deleteActivity.bind(this);
        this.getDuplicateDialog = this.getDuplicateDialog.bind(this);
        this.duplicateActivity = this.duplicateActivity.bind(this);
        this.getValidationState = this.getValidationState.bind(this);
        this.handleDuplicateInputChange = this.handleDuplicateInputChange.bind(this);
        this.editActivity = this.editActivity.bind(this);
        this.setKeywordsHtml = this.setKeywordsHtml.bind(this);
        this.setSubjectsHtml = this.setSubjectsHtml.bind(this);
        this.createActivity = this.createActivity.bind(this);
        this.inputTags = this.inputTags.bind(this);
        this.deleteTags = this.deleteTags.bind(this);
        this.checkEnterKeyPress = this.checkEnterKeyPress.bind(this);
        this.getAllSubjects = this.getAllSubjects.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.nextTab = this.nextTab.bind(this);
        this.handleUploadButtons = this.handleUploadButtons.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onClassInputBlur = this.onClassInputBlur.bind(this);
        this.onActivityTypeSelect = this.onActivityTypeSelect.bind(this);
        this.uploadPDFFile = this.uploadPDFFile.bind(this);
        this.uploadDriveFile = this.uploadDriveFile.bind(this);
        this.uploadVideoFile = this.uploadVideoFile.bind(this);
        this.uploadDriveVideo = this.uploadDriveVideo.bind(this);
        this.emptyState = this.emptyState.bind(this);
        this.showViewDetailsPage = this.showViewDetailsPage.bind(this);
        this.hideViewDetailsPage = this.hideViewDetailsPage.bind(this);
        this.hideViewDetailsPageAndCloseViewActivity = this.hideViewDetailsPageAndCloseViewActivity.bind(this);
        this.setIndexNumberForQuestionsIncrement = this.setIndexNumberForQuestionsIncrement.bind(this);
        this.setIndexNumberForQuestionsDecrement = this.setIndexNumberForQuestionsDecrement.bind(this);
        this.dispatchCreateQuestionFields = this.dispatchCreateQuestionFields.bind(this);
        this.dispatchCreateFlashCardFields = this.dispatchCreateFlashCardFields.bind(this);
        this.viewActivityOnRowClick = this.viewActivityOnRowClick.bind(this);
        this.showIndicator = this.showIndicator.bind(this);
        this.hideIndicator = this.hideIndicator.bind(this);
        this.selectEditActivity = this.selectEditActivity.bind(this);
        this.getHtmlForTagSubjects = this.getHtmlForTagSubjects.bind(this);
        this.getHtmlForTag = this.getHtmlForTag.bind(this);
        this.handleAlertDismiss = this.handleAlertDismiss.bind(this);
        this.addToUnit = this.addToUnit.bind(this);
        this.getSaveAndAssignDialog = this.getSaveAndAssignDialog.bind(this);
        this.onCourseChange = this.onCourseChange.bind(this);
        this.getCourse = this.getCourse.bind(this);
        this.onUnitChange = this.onUnitChange.bind(this);
        this.onAssessmentChange = this.onAssessmentChange.bind(this);
        this.getActivityAddingConfirmationDialogForCourses = this.getActivityAddingConfirmationDialogForCourses.bind(this);
        this.getConfirmationMessage = this.getConfirmationMessage.bind(this);
        this.fetchAllActivitiesCommonFunction = this.fetchAllActivitiesCommonFunction.bind(this);
        this.getNextPage = this.getNextPage.bind(this);
        this.printContent = this.printContent.bind(this);
        this.printAnswerContent = this.printAnswerContent.bind(this);

        this.viewActivityCssClassName = "activityLogBlock questionActivityMainBox";
        this.tableCssClassName = 'listViewBlock ';
        this.filterComponentCssClassName = 'filterBlock NiceScroll';
        this.viewQuestionPanel = false;
        this.viewURLPanel = false;
        this.viewPDFPanel = false;
        this.viewVIDEOPanel = false;
        this.viewFlashcardsPanel = false;
        this.isShowFullQuestion = false;
        this.viewHelpText = false;
        this.fullQuestion = null;
        this.isEditActivity = false;
        // initial state for delete/duplicate dialog.
        this.state = {
            showDeleteDialog: false, deleteError: false, deleteErrorMessage: '',
            duplicateDialogInput: '', showDuplicateDialog: false, duplicateError: false, duplicateErrorMessage: '',
            showSaveAndAssignDialog: false, selectedCourse: null,

            showCreateEditDialog: false, showSuccessDialog: false, taggedKeywordsHtml: [], taggedKeywords: [], taggedSubjectsHtml: [], taggedSubjects: [],
            allSubjects: [], createActivityTab: 1, activityID: "", showActivityAddingConfirmationDialog: false,
            activityType: "", activityName: "", externalURL: "", documentType: "", documentLink: "", keyName: "", base64: "",
            errors: {}, showLearningFields: false, fileLink: "", fileName: "", showAssignDialog: false, activityState: "In Progress",

            viewQuestionDetailsPage: false, indexNumberForQuestions: 0,
            loading: false,

            selectedRow: "", screenWidth: window.innerWidth,

            alertText: "This is a sample alert", alertStyle: "danger", showAlert: false,

            isFromCourse: false, isFromAssignment: false, isFromAssignmentToAssignActivity: false, showAddToCourse: false, isMandotary: false,
            loadingNextPage: 0,

            isLandingPage: true, isActivityTypeLandingPage: false
        };
    }

    getNextPage() {

        const { screenWidth } = this.state;
        const isMobile = screenWidth <= 767;

        if (isMobile & this.state.loadingNextPage === 0) {
            let myID = Math.random() * 10000000000000000000;
            this.setState({ 'loadingNextPage': myID });
            console.log("getting more activities");
            if (this.state.loadingNextPage === myID) {
                let esAttr = this.props.elasticSearchAttributes.map.get('activities');
                const { dispatch } = this.props;
                ElasticSearchActions.setElasticSearchAttributes(dispatch, 'activities', esAttr.searchTerm, esAttr.pageSize + 50, esAttr.activePage, esAttr.sortObj, esAttr.filterValues);

                let elasticSearchQuery = new ElasticSearchQuery();
                elasticSearchQuery.query(ElasticSearchTypes.Activity + config.stage, ElasticSearchColumns.Activity, this.props,
                    esAttr.searchTerm, esAttr.pageSize + 50, esAttr.activePage, esAttr.sortObj, esAttr.filterValues, function (results, totalRecords) {
                        dispatch({
                            type: RECEIVED_ACTIVITY_RESULTS,
                            status: 200,
                            data: JSON.parse(JSON.stringify(results)),
                            alertText: '',
                            alertStyle: '',
                            showAlert: false,
                            totalRecords: totalRecords
                        });

                    });
                this.setState({ 'loadingNextPage': 0 });
            }

        }
    }
    keepOnPage(e) {
        let message = 'Unsaved changes will be lost';
        e.returnValue = message;
        return message;
    }
    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.keepOnPage);
        window.addEventListener('resize', this.handleWindowSizeChange);
    }
    componentDidMount() {
        LoginUtils.isAuthenticated(this.props, browserHistory, '/activities', '/');

        if (this.props.manageCourses.isFromCourse) {
            this.setState({ isFromCourse: true, isLandingPage: false });
            window.addEventListener('beforeunload', this.keepOnPage);
        }
        else {
            const browser = detect();
            if (browser.os.toLocaleLowerCase() !== 'ios') {
                // Opening Help Text
                // let selector = '#' + 'sidePanelContainer';
                // $(selector).addClass('DivShow');
                // $(selector).removeClass('questionActivityMainBox');
                //
                // this.viewActivityCssClassName = "activityLogBlock DivShow";
                // this.tableCssClassName= 'listViewBlock pulLeftClass';
                // this.filterComponentCssClassName = 'filterBlock NiceScroll collapsableDiv';
                // this.viewHelpText = true;
            }
        }

        const { dispatch } = this.props;
        ;
        let visibility = '( \"' + this.props.auth.user.userName + '\" )';
        if ((this.props.auth.user.linkedAccounts && this.props.auth.user.linkedAccounts.learnerPal) && this.props.auth.user.federatedIdentity) {
            visibility += ` OR (\"${this.props.auth.user.linkedAccounts.learnerPal}\")`
            // visibility += " OR (" + this.props.auth.user.linkedAccounts.learnerPal + ")"
        }
        let subjectsToSearch = "(";
        if (this.props.auth.user.subjects) {
            this.props.auth.user.subjects.forEach(sub => {
                subjectsToSearch += "\"" + sub + "\" OR ";
            })
        }
        subjectsToSearch = subjectsToSearch.substring(0, subjectsToSearch.length - 3);
        subjectsToSearch += ")";
        ManageCourseActions.fetchAllCourses(dispatch, this.props.auth.user.userName, 'course' + config.stage, {
            'query_string': {
                'fields': ['visibleTo', 'subject'],
                'query': 'visibleTo : ' + visibility + ' AND subject : ' + subjectsToSearch
            }
        });

        delete this.props.manageActivities.createActivityActiveOwnerFilter;

        ElasticSearchActions.setElasticSearchAttributes(dispatch, 'activities'.toString(), '', parseInt($('#pageSizeDropDown').val()) ? parseInt($('#pageSizeDropDown').val()) : 50, 1, { createdAt: { order: "desc" } }/*{_score:{order: "desc"}}*/, []);
        let elasticSearchQuery = new ElasticSearchQuery();

        elasticSearchQuery.query(ElasticSearchTypes.Activity + config.stage, ElasticSearchColumns.Activity, this.props,
            '', parseInt($('#pageSizeDropDown').val()) ? parseInt($('#pageSizeDropDown').val()) : 50, 1, { createdAt: { order: "desc" } }/*{_score:{order: "desc"}}*/, [], function (results, totalRecords) {
                dispatch({
                    type: RECEIVED_ACTIVITY_RESULTS,
                    status: 200,
                    data: JSON.parse(JSON.stringify(results)),
                    alertText: '',
                    alertStyle: '',
                    showAlert: false,
                    totalRecords: totalRecords
                });
            });

        if (this.props.manageActivities.showAlert) {
            this.setState({ alertText: this.props.manageActivities.alertText, alertStyle: this.props.manageActivities.alertStyle, showAlert: this.props.manageActivities.showAlert });
            setTimeout(() => {
                this.setState({ ...this.state, showAlert: false });
            }, 5000);
            this.setState({ isLandingPage: false });
        }


        this.getAllSubjects();

        if (this.props.location.state) {

            this.setState({ ...this.props.location.state });
            // this.setState({isActivityTypeLandingPage:this.props.location.state.isActivityTypeLandingPage})
        }


    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ screenWidth: window.innerWidth });
    };

    handleAlertDismiss(e) {
        this.setState({ 'showAlert': false });
    }

    showIndicator() {
        this.setState({
            ...this.state,
            loading: true
        });
    }

    hideIndicator() {
        this.setState({
            ...this.state,
            loading: false
        });
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    onClassInputBlur(e) {

    }
    uploadDriveFile = (data) => {
        console.log(data)
        this.state.errors.fileName = null;
        if (data.docs) {
            this.showIndicator();

            if (data.docs[0] && data.docs[0].mimeType !== 'application/pdf') {
                this.state.errors.fileName = "Only PDF files are allowed";
                this.hideIndicator();
                return;
            }

            if (data.docs[0] && data.docs[0].sizeBytes > 20971520) {
                this.state.errors.fileName = "Only files less than 20 MB are allowed";
                this.hideIndicator();
                return;
            }
            this.setState({ showCreateEditDialog: false });
            var additionalParams = {};
            var pathTemplate = '/s3DriveUpload';
            var method = 'POST';
            var additionalParams = { 'accessToken': localStorage.getItem('accessToken') };
            var body = { "id": data.docs[0].id, "name": data.docs[0].name, "mimeType": data.docs[0].mimeType };

            var params = {

            };
            let that = this
            let fileName = data.docs[0].name;
            apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, "7")
                .then(function (result) {
                    console.log("result " + result)

                    if (result.data) {
                        that.setState({ 'fileName': fileName, 'fileLink': result.data });
                    } else {
                        that.setState({ 'fileName': '', 'fileLink': '' });
                    }
                    that.setState({ showCreateEditDialog: true });
                    that.hideIndicator();
                })
                .catch(function (err) {
                    console.log(err)
                });
        }


    }

    uploadDriveVideo = (data) => {
        console.log(data)
        this.state.errors.fileName = null;
        if (data.docs) {
            this.showIndicator();

            if (data.docs[0].name.toLowerCase().includes('.mov')) {
                data.docs[0].name = data.docs[0].name.toLowerCase().replace(".mov", ".mp4")
            }

            if (data.docs[0] && !(data.docs[0].mimeType === 'video/webm' || data.docs[0].mimeType === 'video/ogg' || data.docs[0].mimeType === 'video/mp4' || data.docs[0].mimeType === 'video/ogv' || data.docs[0].mimeType === 'video/multiple' || data.docs[0].mimeType === 'video/m3u8' || data.docs[0].mimeType === 'video/mpd' || data.docs[0].mimeType === 'video/quicktime')) {
                this.state.errors.fileName = "Only Video(webm/ogg/mp4/ogv/multiple/m3u8/video) files are allowed";
                this.hideIndicator();
                return;
            }

            if (data.docs[0] && data.docs[0].sizeBytes > 524288000) {
                this.state.errors.fileName = "Only files less than 500 MB are allowed";
                this.hideIndicator();
                return;
            }
            this.setState({ showCreateEditDialog: false });
            var additionalParams = {};
            var pathTemplate = '/s3DriveUpload';
            var method = 'POST';
            var additionalParams = { 'accessToken': localStorage.getItem('accessToken') };
            var body = { "id": data.docs[0].id, "name": data.docs[0].name, "mimeType": data.docs[0].mimeType };

            var params = {

            };
            let that = this
            let fileName = data.docs[0].name;
            apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, "7")
                .then(function (result) {
                    console.log("result " + result)

                    if (result.data) {
                        that.setState({ 'fileName': fileName, 'fileLink': result.data });
                    } else {
                        that.setState({ 'fileName': '', 'fileLink': '' });
                    }
                    that.setState({ showCreateEditDialog: true });
                    that.hideIndicator();
                })
                .catch(function (err) {
                    console.log(err)
                });
        }


    }

    uploadPDFFile = async (e) => {
        // e.preventDefault();
        this.showIndicator();

        if (e.target.files[0] && e.target.files[0].type !== 'application/pdf') {
            this.state.errors.fileName = "Only PDF files are allowed";
            this.hideIndicator();
            return;
        }

        if (e.target.files[0] && e.target.files[0].size > 20971520) {
            this.state.errors.fileName = "Only files less than 20 MB are allowed";
            this.hideIndicator();
            return;
        }
        this.setState({ showCreateEditDialog: false });
        let fileName = e.target.files[0].name;
        try {
            const uploadedFilename = (e.target.files[0])
                ? (await s3Upload(e.target.files[0], this.props.auth.user.userName)).Location
                : null;

            if (uploadedFilename) {
                this.setState({ 'fileName': fileName, 'fileLink': uploadedFilename });
            } else {
                this.setState({ 'fileName': '', 'fileLink': '' });
            }
            console.log(uploadedFilename);
            this.state.errors.fileName = "";
        }
        catch (err) {
            console.log(err);
            this.state.errors.fileName = err.message;
            setTimeout(() => {
                this.setState({ ...this.state, showAlert: false });
            }, 5000);
        }
        this.setState({ showCreateEditDialog: true });
        this.hideIndicator();
    }

    uploadVideoFile = async (e) => {
        this.showIndicator();
        this.setState({ showCreateEditDialog: false });
        if (e.target.files[0].name.toLowerCase().includes('.mov')) {

            if (e.target.files[0] && e.target.files[0].size > 524288000) {
                this.state.errors.fileName = "Only files less than 500 MB are allowed";
                this.hideIndicator();
                return;
            }

            const file = e.target.files[0];

            var previous_file = new File([file], file.name.replace(".mov", ".mp4"));

            try {
                const uploadedFilename = (previous_file)
                    ? (await s3Upload(previous_file, this.props.auth.user.userName)).Location
                    : null;

                if (uploadedFilename) {
                    this.setState({ 'fileName': previous_file.name, 'fileLink': uploadedFilename });
                } else {
                    this.setState({ 'fileName': '', 'fileLink': '' });
                }
                console.log(uploadedFilename);
                this.state.errors.fileName = "";
            }
            catch (err) {
                console.log(err);
                this.state.errors.fileName = err.message;
                setTimeout(() => {
                    this.setState({ ...this.state, showAlert: false });
                }, 5000);
            }
            this.setState({ showCreateEditDialog: true });
            this.hideIndicator();
        } else {
            if (e.target.files[0] && !(e.target.files[0].type === 'video/webm' || e.target.files[0].type === 'video/ogg' || e.target.files[0].type === 'video/mp4' || e.target.files[0].type === 'video/ogv' || e.target.files[0].type === 'video/multiple' || e.target.files[0].type === 'video/m3u8' || e.target.files[0].type === 'video/mpd')) {
                this.state.errors.fileName = "Only Video(webm/ogg/mp4/ogv/multiple/m3u8/video) files are allowed";
                this.hideIndicator();
                return;
            }

            if (e.target.files[0] && e.target.files[0].size > 524288000) {
                this.state.errors.fileName = "Only files less than 500 MB are allowed";
                this.hideIndicator();
                return;
            }

            let fileName = e.target.files[0].name;
            try {
                const uploadedFilename = (e.target.files[0])
                    ? (await s3Upload(e.target.files[0], this.props.auth.user.userName)).Location
                    : null;

                if (uploadedFilename) {
                    this.setState({ 'fileName': fileName, 'fileLink': uploadedFilename });
                } else {
                    this.setState({ 'fileName': '', 'fileLink': '' });
                }
                console.log(uploadedFilename);
                this.state.errors.fileName = "";
            }
            catch (err) {
                console.log(err);
                this.state.errors.fileName = err.message;
                setTimeout(() => {
                    this.setState({ ...this.state, showAlert: false });
                }, 5000);
            }
            this.setState({ showCreateEditDialog: true });
            this.hideIndicator();
        }
    }

    onActivityTypeSelect(e) {
        this.setState({ activityType: e.target.value });
        if (e.target.value.startsWith('learning')) {
            if (e.target.value === 'learningURL') {
                this.setState({ showLearningFields: true, showURL: true, showFile: false, showVideo: false });
            }
            else if (e.target.value === 'learningPDF') {
                this.setState({ showLearningFields: true, showURL: false, showFile: true, showVideo: false });
            }
            else if (e.target.value === 'learningVideo') {
                this.setState({ showLearningFields: true, showURL: false, showFile: false, showVideo: true });
            }

        }
        else {
            this.setState({ showLearningFields: false });
        }
    }



    viewActivity(activityData) {
        this.setState({ selectedRow: activityData, viewQuestionDetailsPage: false });
        let selector = '#' + 'sidePanelContainer';
        $(selector).addClass('DivShow');
        $(selector).removeClass('questionActivityMainBox');

        this.viewActivityCssClassName = "activityLogBlock DivShow";
        this.tableCssClassName = 'listViewBlock pulLeftClass';
        this.filterComponentCssClassName = 'filterBlock NiceScroll collapsableDiv';
        this.viewQuestionPanel = false;
        this.viewURLPanel = false;
        this.viewPDFPanel = false;
        this.viewVIDEOPanel = false;
        this.viewHelpText = false;
        this.viewFlashcardsPanel = false;
        this.isShowFullQuestion = false;
        const { dispatch } = this.props;
        ManageActivityActions.viewActivity(dispatch, START_VIEW_ACTIVITY);

        let that = this;
        let params = {
            activityID: activityData.id,
        };
        let pathTemplate = '/activity/{activityID}';
        let method = 'GET';
        let additionalParams = {};
        let body = {};
        apiClient.invokeApi(params, pathTemplate, method, additionalParams, body).then(function (result) {
            switch (result.data.activityType) {
                case 'flashcards':
                    that.viewFlashcardsPanel = true;
                    break;
                case 'question':
                    that.viewQuestionPanel = true;
                    break;
                case 'learning':
                    if (result.data.documentType === 'URL') {
                        that.viewURLPanel = true;
                    }
                    else if (result.data.documentType === 'PDF') {
                        that.viewPDFPanel = true;
                    } else if (result.data.documentType && result.data.documentType.toLowerCase() === 'video') {
                        that.viewVIDEOPanel = true;
                    }
                default:
                    break;
            }
            ManageActivityActions.viewActivitySuccess(dispatch, RCEIVED_VIEW_ACTIVITY, result.data);
        }).catch(function (result) {
            ManageActivityActions.viewActivityFailure(dispatch, FAILED_VIEW_ACTIVITY);
        });
    }

    getHtmlForViewActivity(message) {
        return (<div className="topRowHaedingAnch">
            <span className="scrollBar" />
            <div className="FrontQuestionBox">
                <div className="fixIconDiv">
                    <span className="cross" onClick={this.closeViewActivity} />
                    <h3>View Activities</h3>
                    <div className="actionBlockDiv">
                        <h4>{message}</h4>
                    </div>
                </div>
            </div>
        </div>)
    }

    closeViewActivity() {
        this.viewActivityCssClassName = "activityLogBlock questionActivityMainBox";
        this.tableCssClassName = 'listViewBlock';
        this.filterComponentCssClassName = 'filterBlock NiceScroll';
        this.viewQuestionPanel = false;
        this.viewURLPanel = false;
        this.viewPDFPanel = false;
        this.viewVIDEOPanel = false;
        this.viewHelpText = false;
        this.viewFlashcardsPanel = false;
        this.isShowFullQuestion = false;
        this.setState({ selectedRow: "", viewQuestionDetailsPage: false });
    }

    showFullQuestion(index, event) {
        let tempPropHolder = this.props.manageActivities.viewActivityData;
        let htmlToReactParser = new Parser();
        for (let i = 0; i < tempPropHolder.questions.length; i++) {
            if (index === i) {
                let choicesHtml = [];
                let quesText = "";
                let choicesText = "";
                let showChoices = true;
                if (tempPropHolder.questions[i] && tempPropHolder.questions[i].question) {
                    quesText = tempPropHolder.questions[i].question;
                    showChoices = quesText.indexOf('LP_ANSWERS') < 0;
                    if (tempPropHolder.questions[i].choices) {
                        for (let j = 1; j < tempPropHolder.questions[i].choices.length + 1; j++) {
                            if (tempPropHolder.questions[i].choices[j - 1] === tempPropHolder.questions[i].answer) {
                                choicesText +=
                                    // "<div class='fieldBox'><input type='radio' class='css-checkbox css-label1 bc_info card_side' value='choice"+j+"' name='choice"+j+"' checked/><label htmlFor='choice"+j+"' class='css-label radGroup1' >" + j + ") &nbsp; "+tempPropHolder.questions[i].choices[j-1] +"</label></div>"
                                    "<div class='fieldBox spacer'><input type='radio' class='css-checkbox css-label1 bc_info card_side' value='choice" + j + "' name='choice" + j + "' checked/><label htmlFor='choice" + j + "' class='css-label c-handler radGroup1' >" + j + ") &nbsp;</label>" + `<span class='text-fix' dir lang=${this.props.auth.user.prefLanguage} >${tempPropHolder.questions[i].choices[j - 1]}</span>` + "</div>"
                            } else {
                                choicesText +=
                                    // "<div class='fieldBox'><input type='radio' class='css-checkbox css-label1 bc_info card_side' value='choice"+j+"' name='choice"+j+"' /><label htmlFor='choice"+j+"' class='css-label radGroup1' >"  + j + ") &nbsp; " +tempPropHolder.questions[i].choices[j-1] +"</label></div>"
                                    "<div class='fieldBox spacer'><input type='radio' class='css-checkbox css-label1 bc_info card_side' value='choice" + j + "' name='choice" + j + "' /><label htmlFor='choice" + j + "' class='css-label c-handler radGroup1' >" + j + ") &nbsp;</label>" + `<span class='text-fix' dir lang=${this.props.auth.user.prefLanguage} >${tempPropHolder.questions[i].choices[j - 1]}</span>` + "</div>"

                            }
                        }
                    }
                    quesText = quesText.replace('[[[LP_ANSWERS]]]', choicesText);
                }

                this.fullQuestion = <div className="innerQuestionBox NiceScroll" style={{ display: 'block' }}>
                    <h3> <span className="backArrow" onClick={this.hideFullQuestion} /> Question</h3>
                    <h4>Question:</h4>
                    <p><span dir lang={this.props.auth.user.prefLanguage}>{htmlToReactParser.parse(quesText)}</span></p>
                    {showChoices &&
                        <p><span dir lang={this.props.auth.user.prefLanguage}>{htmlToReactParser.parse(choicesText)}</span></p>
                    }
                    <h4>Hint</h4>
                    <p><span dir lang={this.props.auth.user.prefLanguage}>{htmlToReactParser.parse(tempPropHolder.questions[i].hint)}</span></p>
                    {tempPropHolder.questions[i].explanation &&
                        <div>
                            <h4>Explanation: </h4>
                            <p><span dir lang={this.props.auth.user.prefLanguage}> {htmlToReactParser.parse(tempPropHolder.questions[i].explanation.replace('autoplay', ''))}</span></p>
                        </div>
                    }
                </div>;
                break;
            }
        }
        const { dispatch } = this.props;
        ManageActivityActions.showFullQuestion(dispatch, SHOW_FULL_QUESTION, this.fullQuestion);
        this.isShowFullQuestion = true;
    }

    hideFullQuestion() {
        this.isShowFullQuestion = false;
        const { dispatch } = this.props;
        ManageActivityActions.viewActivitySuccess(dispatch, RCEIVED_VIEW_ACTIVITY, this.props.manageActivities.viewActivityData);
    }

    getAllSubjects() {
        let sortedSubjects = []
        this.props.auth.user.subjects.forEach(sub => {
            sortedSubjects.push({ "id": sub, "name": sub });
        })
        sortedSubjects.sort(function (a, b) {
            return a.id > b.id;
        });
        this.setState({ allSubjects: sortedSubjects });
    }

    handleUploadButtons(event) {
        if (event.target.value.length > 0) {
            document.getElementById("fileUpload").disabled = true;
        }
        else {
            document.getElementById("fileUpload").disabled = false;
        }
        this.setState({ 'externalURL': event.target.value });
    }
    checkEnterKeyPress(e) {
        if (e.key === 'Enter' && e.target.name !== 'duplicateDialogInput') {
            this.inputTags(e);
        }
        else if (e.key === 'Enter' && e.target.name === 'duplicateDialogInput'
            && this.state.duplicateDialogInput && this.state.duplicateDialogInput.trim() !== "") {
            let context = this;
            this.duplicateActivity(this.state.duplicateRecord).then(result => {
                context.emptyState(function () {
                    context.closeViewActivity();
                    setTimeout(function () {
                        context.fetchAllActivitiesCommonFunction();
                    }, 1000);
                });
            }).catch(error => {
                context.hideIndicator();
                context.setState({ duplicateError: true, duplicateErrorMessage: error.statusMessage });
            });
        }
    }

    setKeywordsHtml(value) {
        let tempStateKeywords = this.state.taggedKeywords;

        for (let i = 0; i < tempStateKeywords.length; i++) {
            if (tempStateKeywords[i] === value) {
                return;
            }
        }
        this.setState({
            taggedKeywordsHtml: this.state.taggedKeywordsHtml.concat(
                this.getHtmlForTag(value)),
            taggedKeywords: this.state.taggedKeywords.concat(value)
        });
    }

    getHtmlForTag(tag) {
        return <span id="keywordsSpan" className="keywordTag" key={tag}>
            <input type="hidden" name={"keyWord" + tag} value={tag} /> {tag}
            <span className="text-cross-icon" onClick={this.deleteTags}>x</span>
        </span>
    }

    setSubjectsHtml(keyword) {
        let tempStateSubjects = this.state.taggedSubjects;

        for (let i = 0; i < tempStateSubjects.length; i++) {
            if (tempStateSubjects[i] === keyword) {
                return;
            }
        }
        this.setState({
            taggedSubjectsHtml: this.state.taggedSubjectsHtml.concat(
                this.getHtmlForTagSubjects(keyword)
            ), taggedSubjects: this.state.taggedSubjects.concat(keyword)
        });
    }

    getHtmlForTagSubjects(tags) {
        return <span id="subjectsSpan" className="keywordTag" key={tags}>
            <input type="hidden" name={"keyWord" + tags} value={tags} /> {tags}
            <span className="text-cross-icon" onClick={this.deleteTags}>x</span>
        </span>
    }

    inputTags(event) {
        if (event.target.id === 'keywords' && event.target.value !== '') {
            let value = event.target.value;
            this.setKeywordsHtml(value);
            event.target.value = "";
        }
        else if (event.target.name === 'keywordsDrop') {
            let keyword = '';
            if (event.target.selectedOptions && event.target.selectedOptions[0]) {
                keyword = event.target.selectedOptions[0].value;
            } else {
                keyword = event.nativeEvent.srcElement.value;
            }

            if (keyword !== '' && keyword !== 'Associate with Subjects') {
                this.setSubjectsHtml(keyword);
            }
        }
    }

    deleteTags(event) {
        if (event.target.parentNode.id === "subjectsSpan") {
            $('#subjectDropDownId').prop('selectedIndex', 0);
            let tempTaggedSubjectsHtml = this.state.taggedSubjectsHtml;
            let tempTaggedSubjects = this.state.taggedSubjects;
            for (let a = 0; a < tempTaggedSubjectsHtml.length; a++) {
                if (tempTaggedSubjectsHtml[a].key === event.target.parentNode.firstElementChild.value) {
                    tempTaggedSubjectsHtml.splice(a, 1);
                    tempTaggedSubjects.splice(a, 1);
                }
            }
            this.setState({ taggedSubjectsHtml: tempTaggedSubjectsHtml, taggedSubjects: tempTaggedSubjects });
        }
        else {
            let tempTaggedKeywordsHtml = this.state.taggedKeywordsHtml;
            let tempTaggedKeywords = this.state.taggedKeywords;
            for (let a = 0; a < tempTaggedKeywordsHtml.length; a++) {
                if (tempTaggedKeywordsHtml[a].key === event.target.parentNode.firstElementChild.value) {
                    tempTaggedKeywordsHtml.splice(a, 1);
                    tempTaggedKeywords.splice(a, 1);
                }
            }
            this.setState({ taggedKeywordsHtml: tempTaggedKeywordsHtml, taggedKeywords: tempTaggedKeywords });
        }
    }

    createActivity() {
        this.isEditActivity = false;
        this.emptyState(function () {
            this.setState({ showCreateEditDialog: true, 'showAddToCourse': true, 'showSaveAndAssignDialog': false, SaveAndAssign: false, isMandotary: false, selectedCourse: '', selectedUnit: '' });
        });
    }

    isValid() {
        const { errors, isValid } = validateInput(this.state, this.props.manageActivities.allRecords, this.props.auth.user.userName);
        if (!isValid) {
            this.setState({ errors });
        }
        return isValid;
    }

    fetchAllActivitiesCommonFunction() {

        const { dispatch } = this.props;
        let elasticSearchQuery = new ElasticSearchQuery();
        elasticSearchQuery.query(ElasticSearchTypes.Activity + config.stage, ElasticSearchColumns.Activity, this.props,
            '', parseInt($('#pageSizeDropDown').val()) ? parseInt($('#pageSizeDropDown').val()) : 50, 1, { createdAt: { order: "desc" } }/*{_score:{order: "desc"}}*/, [], function (results, totalRecords) {
                dispatch({
                    type: RECEIVED_ACTIVITY_RESULTS,
                    status: 200,
                    data: JSON.parse(JSON.stringify(results)),
                    alertText: '',
                    alertStyle: '',
                    showAlert: false,
                    totalRecords: totalRecords
                });
            });
    }

    isNotDuplicatedName() {
        // let axiosInstance = axios.create({
        //     baseURL: 'https://search-learnerpal-eimkio3umgx5fnpuy7ypsrrsri.us-east-1.es.amazonaws.com/learnerpal',
        //     timeout: 60000,
        //     headers: {'Content-Type': 'application/json'},
        //     responseType: 'json',
        //     transformResponse: {function (data) {
        //         return data;
        //     }}
        // });
        // return axiosInstance({
        //     method: 'GET',
        //     url: '/' + ElasticSearchTypes.Activity+config.stage + '/' +  this.state.activityName.replace(/\s|\W|[#$%^&*()'.]/g, "") + "_" + this.props.auth.user.userName,
        // })
        return apiClient.invokeApi({}, '/getesdata', 'POST', {}, { id: "/" + this.state.activityName.replace(/\s|\W|[#$%^&*()'.]/g, "").toLocaleLowerCase() + "_" + this.props.auth.user.userName, index: 'learnerpal/', esDocumentType: ElasticSearchTypes.Activity + config.stage }, '5');

    }

    submitForm() {
        let that = this;
        return new Promise((resolve, reject) => {
            if (that.isValid()) {
                if (that.isEditActivity) {

                    let body;
                    let state = that.state;
                    let activityType = that.state.activityType;

                    if (activityType === 'learningURL' || activityType === 'learningPDF' || activityType === "learningVideo") {
                        activityType = 'learning';
                    }

                    that.showIndicator();

                    if (that.state.fileName) {
                        if (that.state.fileName.includes("pdf") || that.state.fileName.includes("png")) {
                            body = {
                                schoolName: that.props.auth.user.schoolName,
                                ownerName: that.props.auth.user.userName,
                                activityType: activityType,
                                activityName: state.activityName,
                                orderBy: 1,
                                subjects: state.taggedSubjects.sort(),
                                documentType: 'PDF',
                                keyName: that.state.fileName,
                                fileLink: that.state.fileLink,
                                tags: state.taggedKeywords,
                                activityState: state.activityState
                            };
                            if (state.description) {
                                body.description = state.description;
                            }
                            let params = {};

                            let pathTemplate = '/activity';
                            let method = 'POST';

                            if (that.isEditActivity) {
                                method = 'PUT';
                                body = {
                                    activityID: that.state.activityID,
                                    schoolName: that.props.auth.user.schoolName,
                                    ownerName: that.props.auth.user.userName,
                                    activityType: activityType,
                                    activityName: state.activityName,
                                    orderBy: 1,
                                    subjects: state.taggedSubjects.sort(),
                                    documentType: 'PDF',
                                    keyName: that.state.fileName,
                                    fileLink: that.state.fileLink,
                                    tags: state.taggedKeywords,
                                    activityState: state.activityState
                                };
                                if (state.description) {
                                    body.description = state.description;
                                }
                            }
                            let additionalParams = {};
                            apiClient.invokeApi(params, pathTemplate, method, additionalParams, body).then(function (result) {
                                if (method === 'POST') {
                                    ClevertapReact.event("Create Activity", { 'userName': that.props.auth.user.userName });
                                }
                                else {
                                    ClevertapReact.event("Update Activity", { 'userName': that.props.auth.user.userName });
                                }
                                that.emptyState(function () {

                                    let message = !that.isEditActivity ? result.data.message : result.data;
                                    that.setState({ showAlert: true, alertText: message, alertStyle: "success", results: [] }, function () {

                                    });
                                    setTimeout(() => {

                                        that.setState({ ...that.state, showAlert: false });
                                    }, 5000);
                                    that.hideIndicator();
                                    setTimeout(function () {

                                        that.fetchAllActivitiesCommonFunction();
                                    }, 1000);
                                    if (!that.isEditActivity) {
                                        resolve({ saved: true, status: 'Success', data: result.data });
                                    } else {
                                        reject({ saved: false, status: 'Error', data: result.data });
                                    }
                                });
                            }).catch(function (result) {
                                that.setState({ showAlert: true, alertText: result.message, alertStyle: "danger" });
                                setTimeout(() => {
                                    that.setState({ ...that.state, showAlert: false });
                                }, 5000);
                                that.hideIndicator();

                                reject({ saved: false, status: 'Error' });
                            });
                        } else {
                            body = {
                                schoolName: that.props.auth.user.schoolName,
                                ownerName: that.props.auth.user.userName,
                                activityType: activityType,
                                activityName: state.activityName,
                                orderBy: 1,
                                subjects: state.taggedSubjects.sort(),
                                documentType: 'Video',
                                keyName: that.state.fileName,
                                fileLink: that.state.fileLink,
                                tags: state.taggedKeywords,
                                activityState: state.activityState
                            };
                            if (state.description) {
                                body.description = state.description;
                            }
                            let params = {};

                            let pathTemplate = '/activity';
                            let method = 'POST';

                            if (that.isEditActivity) {
                                method = 'PUT';
                                body = {
                                    activityID: that.state.activityID,
                                    schoolName: that.props.auth.user.schoolName,
                                    ownerName: that.props.auth.user.userName,
                                    activityType: activityType,
                                    activityName: state.activityName,
                                    orderBy: 1,
                                    subjects: state.taggedSubjects.sort(),
                                    documentType: 'Video',
                                    keyName: that.state.fileName,
                                    fileLink: that.state.fileLink,
                                    tags: state.taggedKeywords,
                                    activityState: state.activityState
                                };
                                if (state.description) {
                                    body.description = state.description;
                                }
                            }
                            let additionalParams = {};
                            apiClient.invokeApi(params, pathTemplate, method, additionalParams, body).then(function (result) {
                                if (method === 'POST') {
                                    ClevertapReact.event("Create Activity", { 'userName': that.props.auth.user.userName });
                                }
                                else {
                                    ClevertapReact.event("Update Activity", { 'userName': that.props.auth.user.userName });
                                }
                                that.emptyState(function () {

                                    let message = !that.isEditActivity ? result.data.message : result.data;
                                    that.setState({ showAlert: true, alertText: message, alertStyle: "success", results: [] }, function () {

                                    });
                                    setTimeout(() => {

                                        that.setState({ ...that.state, showAlert: false });
                                    }, 5000);
                                    that.hideIndicator();
                                    setTimeout(function () {

                                        that.fetchAllActivitiesCommonFunction();
                                    }, 1000);
                                    if (!that.isEditActivity) {
                                        resolve({ saved: true, status: 'Success', data: result.data });
                                    } else {
                                        reject({ saved: false, status: 'Error', data: result.data });
                                    }
                                });
                            }).catch(function (result) {
                                that.setState({ showAlert: true, alertText: result.message, alertStyle: "danger" });
                                setTimeout(() => {
                                    that.setState({ ...that.state, showAlert: false });
                                }, 5000);
                                that.hideIndicator();

                                reject({ saved: false, status: 'Error' });
                            });
                        }
                    }
                    else {
                        let httpStart = 'http://';
                        let url = state.externalURL;
                        if (!state.externalURL.trim().startsWith('http')) {
                            url = httpStart.concat(state.externalURL);
                        }
                        body = {
                            orderBy: 1,
                            subjects: state.taggedSubjects.sort(),
                            activityType: activityType,
                            activityName: state.activityName,
                            schoolName: that.props.auth.user.schoolName,
                            ownerName: that.props.auth.user.userName,
                            documentType: "URL",
                            documentLink: url,
                            tags: state.taggedKeywords,
                            activityState: state.activityState
                        };
                        if (state.description) {
                            body.description = state.description;
                        }

                        let params = {};
                        let pathTemplate = '/activity';
                        let method = 'POST';
                        let additionalParams = {};

                        if (that.isEditActivity) {
                            method = 'PUT';
                            body = {
                                activityID: that.state.activityID,
                                orderBy: 1,
                                subjects: state.taggedSubjects.sort(),
                                activityType: activityType,
                                activityName: state.activityName,
                                schoolName: that.props.auth.user.schoolName,
                                ownerName: that.props.auth.user.userName,
                                documentType: "URL",
                                documentLink: url,
                                tags: state.taggedKeywords,
                                activityState: state.activityState
                            };
                            if (state.description) {
                                body.description = state.description;
                            }

                        }
                        console.log(JSON.stringify(body));
                        apiClient.invokeApi(params, pathTemplate, method, additionalParams, body).then(function (result) {

                            if (method === 'POST') {
                                ClevertapReact.event("Create Activity", { 'userName': that.props.auth.user.userName });
                            }
                            else {
                                ClevertapReact.event("Update Activity", { 'userName': that.props.auth.user.userName });
                            }
                            that.emptyState(function () {

                                that.hideIndicator();
                                setTimeout(function () {

                                    that.fetchAllActivitiesCommonFunction();
                                }, 1000);
                                if (!that.isEditActivity) {
                                    resolve({ saved: true, status: 'Success', data: result.data });
                                } else {
                                    reject({ saved: false, status: 'Error', data: result.data });
                                }
                            });
                        }).catch(function (result) {

                            that.setState({ showAlert: true, alertText: result.message, alertStyle: "danger" });
                            setTimeout(() => {
                                that.setState({ ...that.state, showAlert: false });
                            }, 5000);
                            that.hideIndicator();

                            reject({ saved: false, status: 'Error' });
                        });
                    }
                    that.setState({ showCreateEditDialog: false })
                }
                else {
                    that.isNotDuplicatedName().then(response => {

                        if (response.data.found) {
                            let errors = {};
                            errors.activityName = 'Activity with the same name already exists';
                            that.setState({ errors });
                        }
                    }
                    ).catch(response => {
                        if (!response.response.data.found) {
                            let body;
                            let state = that.state;
                            let activityType = that.state.activityType;

                            if (activityType === 'learningURL' || activityType === 'learningPDF' || activityType === 'learningVideo') {
                                activityType = 'learning';
                            }
                            console.log(activityType);
                            that.showIndicator();

                            if (that.state.fileName) {
                                if (that.state.fileName.includes("pdf")) {
                                    body = {
                                        schoolName: that.props.auth.user.schoolName,
                                        ownerName: that.props.auth.user.userName,
                                        activityType: activityType,
                                        activityName: that.state.activityName,
                                        orderBy: 1,
                                        subjects: state.taggedSubjects.sort(),
                                        documentType: 'PDF',
                                        keyName: that.state.fileName,
                                        fileLink: that.state.fileLink,
                                        tags: that.state.taggedKeywords,
                                        activityState: that.state.activityState
                                    };
                                    if (state.description) {
                                        body.description = that.state.description;
                                    }
                                    let params = {};

                                    let pathTemplate = '/activity';
                                    let method = 'POST';

                                    if (that.isEditActivity) {

                                        method = 'PUT';
                                        body = {
                                            activityID: that.state.activityID,
                                            schoolName: that.props.auth.user.schoolName,
                                            ownerName: that.props.auth.user.userName,
                                            activityType: activityType,
                                            activityName: that.state.activityName,
                                            orderBy: 1,
                                            subjects: state.taggedSubjects.sort(),
                                            documentType: 'PDF',
                                            keyName: that.state.fileName,
                                            fileLink: that.state.fileLink,
                                            tags: that.state.taggedKeywords,
                                            activityState: that.state.activityState
                                        };
                                        if (state.description) {
                                            body.description = that.state.description;
                                        }

                                    }
                                    let additionalParams = {};
                                    apiClient.invokeApi(params, pathTemplate, method, additionalParams, body).then(function (result) {
                                        if (method === 'POST') {
                                            ClevertapReact.event("Create Activity", { 'userName': that.props.auth.user.userName });
                                        }
                                        else {
                                            ClevertapReact.event("Update Activity", { 'userName': that.props.auth.user.userName });
                                        }
                                        that.emptyState(function () {

                                            let message = !that.isEditActivity ? result.data.message : result.data;
                                            that.setState({ showAlert: true, alertText: message, alertStyle: "success", results: [] }, function () {

                                            });
                                            setTimeout(() => {

                                                that.setState({ ...that.state, showAlert: false });
                                            }, 5000);
                                            that.hideIndicator();
                                            setTimeout(function () {

                                                that.fetchAllActivitiesCommonFunction();
                                            }, 1000);
                                            if (!that.isEditActivity) {
                                                resolve({ saved: true, status: 'Success', data: result.data });
                                            } else {
                                                reject({ saved: false, status: 'Error', data: result.data });
                                            }
                                        });
                                    }).catch(function (result) {
                                        that.setState({ showAlert: true, alertText: result.message, alertStyle: "danger" });
                                        setTimeout(() => {
                                            that.setState({ ...that.state, showAlert: false });
                                        }, 5000);
                                        that.hideIndicator();

                                        reject({ saved: false, status: 'Error' });
                                    });

                                }
                                else {
                                    body = {
                                        schoolName: that.props.auth.user.schoolName,
                                        ownerName: that.props.auth.user.userName,
                                        activityType: activityType,
                                        activityName: state.activityName,
                                        orderBy: 1,
                                        subjects: state.taggedSubjects.sort(),
                                        documentType: 'Video',
                                        keyName: that.state.fileName,
                                        fileLink: that.state.fileLink,
                                        tags: state.taggedKeywords,
                                        activityState: state.activityState
                                    };
                                    if (state.description) {
                                        body.description = state.description;
                                    }
                                    let params = {};

                                    let pathTemplate = '/activity';
                                    let method = 'POST';

                                    if (that.isEditActivity) {

                                        method = 'PUT';
                                        body = {
                                            activityID: that.state.activityID,
                                            schoolName: that.props.auth.user.schoolName,
                                            ownerName: that.props.auth.user.userName,
                                            activityType: activityType,
                                            activityName: state.activityName,
                                            orderBy: 1,
                                            subjects: state.taggedSubjects.sort(),
                                            documentType: 'Video',
                                            keyName: that.state.fileName,
                                            fileLink: that.state.fileLink,
                                            tags: state.taggedKeywords,
                                            activityState: state.activityState
                                        };
                                        if (state.description) {
                                            body.description = state.description;
                                        }

                                    }
                                    let additionalParams = {};
                                    apiClient.invokeApi(params, pathTemplate, method, additionalParams, body).then(function (result) {
                                        if (method === 'POST') {
                                            ClevertapReact.event("Create Activity", { 'userName': that.props.auth.user.userName });
                                        }
                                        else {
                                            ClevertapReact.event("Update Activity", { 'userName': that.props.auth.user.userName });
                                        }
                                        that.emptyState(function () {

                                            let message = !that.isEditActivity ? result.data.message : result.data;
                                            that.setState({ showAlert: true, alertText: message, alertStyle: "success", results: [] }, function () {

                                            });
                                            setTimeout(() => {

                                                that.setState({ ...that.state, showAlert: false });
                                            }, 5000);
                                            that.hideIndicator();
                                            setTimeout(function () {

                                                that.fetchAllActivitiesCommonFunction();
                                            }, 1000);
                                            if (!that.isEditActivity) {
                                                resolve({ saved: true, status: 'Success', data: result.data });
                                            } else {
                                                reject({ saved: false, status: 'Error', data: result.data });
                                            }
                                        });
                                    }).catch(function (result) {
                                        that.setState({ showAlert: true, alertText: result.message, alertStyle: "danger" });
                                        setTimeout(() => {
                                            that.setState({ ...that.state, showAlert: false });
                                        }, 5000);
                                        that.hideIndicator();

                                        reject({ saved: false, status: 'Error' });
                                    });
                                }
                            }
                            else {
                                let httpStart = 'http://';
                                let url = state.externalURL;
                                if (!state.externalURL.trim().startsWith('http')) {
                                    url = httpStart.concat(state.externalURL);
                                }
                                body = {
                                    orderBy: 1,
                                    subjects: state.taggedSubjects.sort(),
                                    activityType: activityType,
                                    activityName: state.activityName,
                                    schoolName: that.props.auth.user.schoolName,
                                    ownerName: that.props.auth.user.userName,
                                    documentType: "URL",
                                    documentLink: url,
                                    tags: state.taggedKeywords,
                                    activityState: state.activityState
                                };
                                if (state.description) {
                                    body.description = state.description;
                                }

                                let params = {};
                                let pathTemplate = '/activity';
                                let method = 'POST';
                                let additionalParams = {};

                                if (that.isEditActivity) {
                                    method = 'PUT';
                                    body = {
                                        activityID: that.state.activityID,
                                        orderBy: 1,
                                        subjects: state.taggedSubjects.sort(),
                                        activityType: activityType,
                                        activityName: state.activityName,
                                        schoolName: that.props.auth.user.schoolName,
                                        ownerName: that.props.auth.user.userName,
                                        documentType: "URL",
                                        documentLink: url,
                                        tags: state.taggedKeywords,
                                        activityState: state.activityState
                                    };
                                    if (state.description) {
                                        body.description = state.description;
                                    }

                                }
                                console.log(JSON.stringify(body));
                                apiClient.invokeApi(params, pathTemplate, method, additionalParams, body).then(function (result) {

                                    if (method === 'POST') {
                                        ClevertapReact.event("Create Activity", { 'userName': that.props.auth.user.userName });
                                    }
                                    else {
                                        ClevertapReact.event("Update Activity", { 'userName': that.props.auth.user.userName });
                                    }
                                    that.emptyState(function () {

                                        that.hideIndicator();
                                        setTimeout(function () {

                                            that.fetchAllActivitiesCommonFunction();
                                        }, 1000);
                                        if (!that.isEditActivity) {
                                            resolve({ saved: true, status: 'Success', data: result.data });
                                        } else {
                                            reject({ saved: false, status: 'Error', data: result.data });
                                        }
                                    });
                                }).catch(function (result) {

                                    that.setState({ showAlert: true, alertText: result.message, alertStyle: "danger" });
                                    setTimeout(() => {
                                        that.setState({ ...that.state, showAlert: false });
                                    }, 5000);
                                    that.hideIndicator();

                                    reject({ saved: false, status: 'Error' });
                                });
                            }
                            that.setState({ showCreateEditDialog: false })
                        }
                    });
                }
            }
        });

    }

    nextTab() {
        let that = this;
        // let activityType = document.getElementById('activityType').value;
        let activityType = this.state.activityType;
        if (activityType === 'learning') {
            this.setState({
                activityType: activityType,
                activityName: this.state.activityName,
                createActivityTab: 2
            });
        } else if (activityType.toLowerCase() === 'question' || activityType.toLowerCase() === 'questions') {
            if (this.isValid()) {
                this.isNotDuplicatedName().then(response => {
                    if (response.data.found) {
                        if (response.data.found) {
                            let errors = {};
                            errors.activityName = 'Activity with the same name already exists';
                            that.setState({ errors });
                        }
                    }
                }).catch(response => {
                    if (!response.response.data.found) {
                        this.dispatchCreateQuestionFields();
                        this.setState({ showCreateEditDialog: false });
                        browserHistory.push('/activities/question/new');
                    }
                });
            }
        } else if (activityType.toLowerCase() === 'flashcard' || activityType.toLowerCase() === 'flashcards') {
            if (this.isValid()) {
                this.isNotDuplicatedName().then(response => {
                    if (response.data.found) {
                        if (response.data.found) {
                            let errors = {};
                            errors.activityName = 'Activity with the same name already exists';
                            that.setState({ errors });
                        }
                    }
                }).catch(response => {
                    if (!response.response.data.found) {
                        this.dispatchCreateFlashCardFields();
                        this.setState({ showCreateEditDialog: false });
                        browserHistory.push('/activities/flashcard/new');
                    }
                });
            }
        }
    }

    dispatchCreateQuestionFields() {
        let { dispatch } = this.props;
        dispatch({
            type: CREATE_QUESTION_SET_INIT_INFO,
            taggedKeywordsHtml: this.state.taggedKeywordsHtml,
            taggedKeywords: this.state.taggedKeywords,
            taggedSubjectsHtml: this.state.taggedSubjectsHtml,
            taggedSubjects: this.state.taggedSubjects,
            allSubjects: this.state.allSubjects,
            activityType: this.state.activityType,
            activityName: this.state.activityName,
            activityState: this.state.activityState,
            description: this.state.description
        })
    }

    dispatchCreateFlashCardFields() {
        let { dispatch } = this.props;
        dispatch({
            type: CREATE_FLASHCARD_SET_INIT_INFO,
            taggedKeywordsHtml: this.state.taggedKeywordsHtml,
            taggedKeywords: this.state.taggedKeywords,
            taggedSubjectsHtml: this.state.taggedSubjectsHtml,
            taggedSubjects: this.state.taggedSubjects,
            allSubjects: this.state.allSubjects,
            activityType: this.state.activityType,
            activityName: this.state.activityName,
            activityState: this.state.activityState,
            description: this.state.description
        })
    }

    viewActivityOnRowClick(selectedRecord, event) {
        const { screenWidth } = this.state;
        const isTab = screenWidth <= 1199;
        if (isTab) {
            this.viewActivity(selectedRecord);
        }
    }

    getCreateEditDialog() {
        const { errors, activityType, activityName, externalURL, documentType, documentLink, keyName, base64 } = this.state;
        let title = "";
        let buttonName = "";
        let titleInitial = "";
        let isDisable = false;

        if (this.isEditActivity) {
            isDisable = true;
            title = "Edit Activity";
            buttonName = "Update";
        }
        else {
            title = "Create New Activity";
            buttonName = "Save and Exit";
        }
        if (this.state.activityType) {
            isDisable = true;
        }
        if (this.state.createActivityTab === 1) {
            let allSubjects = this.state.allSubjects;

            let activityTypes = [
                {
                    "id": "question",
                    "name": "Question Activity"
                },
                {
                    "id": "flashcard",
                    "name": "Flashcard Activity"
                },
                {
                    "id": "learningURL",
                    "name": "URL Activity"
                },
                {
                    "id": "learningPDF",
                    "name": "PDF Activity"
                },
                {
                    "id": "learningVideo",
                    "name": "Video Activity"
                },
            ];

            let activityStates = [
                {
                    "id": "In Progress",
                    "name": "In Progress"
                },
                {
                    "id": "In Review",
                    "name": "In Review"
                }
            ];

            if (this.props.auth.user.userType.toLowerCase() === "admin") {
                activityStates.push({
                    "id": "Published",
                    "name": "Published"
                })
            }
            return <Modal
                show={this.state.showCreateEditDialog}
                backdrop="static"
                onHide={() => {
                    this.setState({ isLandingPage: true, showCreateEditDialog: false, showLearningFields: false, showURL: false, showFile: false, showVideo: false, activityType: '' });
                    if (this.state.isFromAssignment) {
                        browserHistory.push('/assignments');
                    }
                }}
                container={this}
                aria-labelledby="contained-modal-title">
                <Modal.Header closeButton>
                    <Modal.Title id="delete-activity-modal">{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mainFormBox">
                        <div className="col-lg-12 col-md-12" style={{ width: '92%' }}>
                            <SelectFieldGroup
                                isDisabled={isDisable}
                                error={errors.activityType}
                                label="Activity Type:"
                                field="activityType"
                                value={this.state.activityType === "flashcard" && activityTypes[1].id || this.state.activityType === "question" && activityTypes[0].id || this.state.activityType === "learningURL" && activityTypes[2].id || this.state.activityType === "learningPDF" && activityTypes[3].id || this.state.activityType === "learningVideo" && activityTypes[4].id}
                                options={activityTypes}
                                placeholder="Select Activity Type"
                                onChange={this.onActivityTypeSelect}
                                toolTip=""
                            />
                            
                            <TextFieldGroup
                                isDisabled={this.isEditActivity}
                                error={errors.activityName}
                                label="Activity Name:"
                                field="activityName"
                                value={this.state.activityName}
                                placeholder="Enter Activity Name"
                                onChange={this.onChange}
                                toolTip="Name your activity - (e.g: Landmark Supreme Court Cases)"
                            />
                            <div className="form-group">
                                <label htmlFor="description">Description:</label>
                                <div className="posRel selectWidth">
                                    <div className="ui-widget">
                                        <textarea id="description" name="description" className="form-control commentareaField"
                                            placeholder="Enter Description" onChange={this.onChange} value={this.state.description} />
                                    </div>
                                </div>
                                <a className="Activity-toolTip toolTipCus">
                                    <span className="tooltiptext">
                                        Enter descriptive keywords for your activity to help you search for this activity later on when you need it, for example when you are creating assignments.
                                    </span>
                                </a>
                            </div>
                            <SelectFieldGroup
                                dropDownId="subjectDropDownId"
                                error={errors.taggedSubjects}
                                label="Subject:"
                                field="keywordsDrop"
                                //value={this.state.keywordsDrop}
                                options={allSubjects}
                                placeholder="Associate with Subjects"
                                onChange={this.inputTags}
                                multiSelect={this.state.taggedSubjectsHtml}
                                toolTip="Select the subject that this activity will be used in.  This will help you to search and find this activity easier when you need, for example when you create assignments."
                            />
                            {this.props.auth.user.schoolName === "LearnerPal" &&
                                <SelectFieldGroup
                                    error={errors.activityState}
                                    label="Activity State:"
                                    field="activityState"
                                    value={this.state.activityState}
                                    options={activityStates}
                                    onChange={this.onChange}
                                />

                            }
                            {this.state.showLearningFields && this.state.showURL &&
                                <TextFieldGroup
                                    error={errors.externalURL}
                                    label="External URL:"
                                    field="externalURL"
                                    value={this.state.externalURL}
                                    placeholder="Enter URL"
                                    toolTip="Please enter a URL or a PDF file to create a learning activity"
                                    onChange={this.onChange}
                                />
                            }
                            {this.state.showLearningFields && this.state.showFile &&
                                <div className={classnames("form-group", { 'has-error': errors.fileName })}>
                                    <label className="control-label">File:</label>
                                    <div className="posRel selectWidth">
                                        <div>{this.state.fileName}</div>
                                        <div className="fileContainer"> Upload File
                                        <input type="file" onChange={this.uploadPDFFile} />
                                        </div>
                                        {this.props.auth.user.federatedIdentity && <GooglePicker clientId={'754948257149-fi7jp1c5rts842om29pls7urbu9kb39v.apps.googleusercontent.com'}
                                            developerKey={'AIzaSyAL_KlE1ZdTSSPrl7JP4oMY-IN6tzPcLNA'}
                                            scope={['https://www.googleapis.com/auth/drive.readonly']}
                                            onChange={data => this.uploadDriveFile(data)}
                                            onAuthenticate={token => console.log('oauth token:', token)}
                                            onAuthFailed={data => console.log('on auth failed:', data)}
                                            multiselect={true}
                                            navHidden={true}
                                            authImmediate={false}
                                            mimeTypes={['image/png', 'image/jpeg', 'image/jpg']}
                                            query={''}
                                            viewId={'DOCS', 'FOLDERS'}>
                                            <div>
                                                <button type="button" id="googleSync" className="googleSynBtn">
                                                    <img className="googleSyncImg" src={googleIcon} />
                                                    <span className="syncBtnText">Drive</span></button>
                                            </div>
                                        </GooglePicker>}
                                    </div>
                                    <a className="Activity-toolTip toolTipCus">
                                        <span className="tooltiptext">
                                            Please enter a URL or a PDF file to create a learning activity
                                    </span>
                                    </a>

                                    {errors.fileName && <div><label className="control-label" /><div className="posRel selectWidth"><span className="help-block posRel">{errors.fileName}</span></div></div>}
                                </div>
                            }
                            {this.state.showLearningFields && this.state.showVideo &&
                                <div className={classnames("form-group", { 'has-error': errors.fileName })}>
                                    <label className="control-label">Video:</label>
                                    <div className="posRel selectWidth">
                                        <div>{this.state.fileName}</div>
                                        <div className="fileContainer"> Upload Video
                                        <input type="file" onChange={this.uploadVideoFile} />

                                        </div>
                                        {this.props.auth.user.federatedIdentity && <GooglePicker clientId={'754948257149-fi7jp1c5rts842om29pls7urbu9kb39v.apps.googleusercontent.com'}
                                            developerKey={'AIzaSyAL_KlE1ZdTSSPrl7JP4oMY-IN6tzPcLNA'}
                                            scope={['https://www.googleapis.com/auth/drive.readonly']}
                                            onChange={data => this.uploadDriveVideo(data)}
                                            onAuthenticate={token => console.log('oauth token:', token)}
                                            onAuthFailed={data => console.log('on auth failed:', data)}
                                            multiselect={true}
                                            navHidden={true}
                                            authImmediate={false}
                                            mimeTypes={['image/png', 'image/jpeg', 'image/jpg']}
                                            query={''}
                                            viewId={'DOCS', 'FOLDERS'}>
                                            <div>
                                                <button type="button" id="googleSync" className="googleSynBtn">
                                                    <img className="googleSyncImg" src={googleIcon} />
                                                    <span className="syncBtnText">Drive</span></button>
                                            </div>
                                        </GooglePicker>}
                                    </div>
                                    <a className="Activity-toolTip toolTipCus">
                                        <span className="tooltiptext">
                                            Please Upload a Video File to create a learning activity
                                    </span>
                                    </a>
                                    {errors.fileName && <div><label className="control-label" /><div className="posRel selectWidth"><span className="help-block posRel">{errors.fileName}</span></div></div>}
                                </div>
                            }
                        </div>
                        <div className="row" />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => {
                        this.setState({ isLandingPage: true, showCreateEditDialog: false, showLearningFields: false, showURL: false, showFile: false, showVideo: false, activityType: '' });
                        if (this.state.isFromAssignment) {
                            browserHistory.push('/assignments');
                        }
                    }}>Cancel</Button>
                    {
                        this.state.showLearningFields && !this.state.isFromAssignment &&
                        <Button bsStyle='primary' onClick={
                            () => {
                                let context = this;
                                context.submitForm().then(result => {
                                    delete result.data['message'];
                                    console.debug(result);
                                    context.setState({
                                        ...context.state,
                                        showSaveAndAssignDialog: true,
                                        showAddToCourse: false,
                                        SaveAndAssign: false,
                                        payloadForAssignCourse: {
                                            activity: result.data,
                                            courseID: null,
                                            unitID: null,
                                            assessmentType: null
                                        }
                                    })
                                }).catch(result => {
                                    delete result.data['message'];
                                    console.debug(result);
                                    context.setState({
                                        ...context.state,
                                        showSaveAndAssignDialog: true,
                                        showAddToCourse: false,
                                        SaveAndAssign: false,
                                        payloadForAssignCourse: {
                                            activity: result.data,
                                            courseID: null,
                                            unitID: null,
                                            assessmentType: null
                                        }
                                    })
                                });
                            }}>{buttonName}</Button>
                    }
                    {
                        this.state.showLearningFields && <Button bsStyle='primary' onClick={
                            () => {
                                let context = this;

                                context.submitForm().then(result => {
                                    delete result.data['message'];
                                    console.debug(result);
                                    context.setState({
                                        ...context.state,
                                        showSaveAndAssignDialog: true,
                                        showAddToCourse: false,
                                        SaveAndAssign: true,
                                        payloadForAssignCourse: {
                                            activity: result.data,
                                            courseID: null,
                                            unitID: null,
                                            assessmentType: null
                                        }
                                    })
                                }).catch(result => {
                                    delete result.data['message'];
                                    console.debug(result);
                                    context.setState({
                                        ...context.state,
                                        showSaveAndAssignDialog: true,
                                        showAddToCourse: false,
                                        SaveAndAssign: true,
                                        payloadForAssignCourse: {
                                            activity: result.data,
                                            courseID: null,
                                            unitID: null,
                                            assessmentType: null
                                        }
                                    })
                                });
                            }}>Save and Assign</Button>
                    }
                    {!this.state.showLearningFields && <Button onClick={() => this.nextTab()} disabled={!this.state.activityType} bsStyle='primary'>Next</Button>}

                </Modal.Footer>
            </Modal>
        }
    }

    showViewDetailsPage() {
        this.setState({ viewQuestionDetailsPage: true, indexNumberForQuestions: 0 });

        let selector = '#' + 'tableFiltersAndSideView';
        $(selector).addClass('removeDisplay');
    }

    hideViewDetailsPage() {
        this.setState({ viewQuestionDetailsPage: false, indexNumberForQuestions: 0 });

        let selector = '#' + 'tableFiltersAndSideView';
        $(selector).removeClass('removeDisplay');
    }

    hideViewDetailsPageAndCloseViewActivity() {
        this.hideViewDetailsPage();
        this.closeViewActivity();
    }

    setIndexNumberForQuestionsIncrement() {
        if (this.state.indexNumberForQuestions < this.props.manageActivities.viewActivityData.questions.length - 1) {
            let temp = this.state.indexNumberForQuestions;
            temp++;
            this.setState({ indexNumberForQuestions: temp });
        }
    }
    setIndexNumberForQuestionsDecrement() {
        if (this.state.indexNumberForQuestions > 0) {
            let temp = this.state.indexNumberForQuestions;
            temp--;
            this.setState({ indexNumberForQuestions: temp });
        }
    }

    selectEditActivity(selectedRecord) {

        if (selectedRecord.activityType.toLowerCase() === 'questions' || selectedRecord.activityType.toLowerCase() === 'question') {
            browserHistory.push('/activities/question/edit/' + selectedRecord.id);
        } else if (selectedRecord.activityType.toLowerCase() === 'flashcards' || selectedRecord.activityType.toLowerCase() === 'flashcard') {
            browserHistory.push('/activities/flashcard/edit/' + selectedRecord.id);
        } else {
            this.editActivity(selectedRecord);
        }
    }

    addToUnit(activityData) {


        let courseObj = this.props.manageCourses.courseObj;
        let selectedUnitId = this.props.manageCourses.selectedUnit;

        let index = courseObj.units.findIndex(x => x.ID === selectedUnitId);

        if (index === -1) {
            //error
        }
        else {
            let activityObj = {};
            if (courseObj.units[index].activities && courseObj.units[index].activities.length > 0) {
                for (let i = 0; i < courseObj.units[index].activities.length; i++) {
                    if (courseObj.units[index].activities[i].activityID === activityData.id) {
                        this.setState({ showActivityAddingConfirmationDialog: true });
                        for (let i = 0; i < this.props.manageActivities.searchResults.length; i++) {
                            if (this.props.manageActivities.searchResults[i].id === activityData.id) {
                                this.props.manageActivities.searchResults[i].disabled = true;
                            }
                        };
                        return;
                    }
                }

                let id = Math.random() * 10000000000000000000;
                activityObj.ID = "activity_" + id;
                activityObj.activityID = activityData.id;
                activityObj.name = "activity_" + id; //needed for sorting (drag n drop)
                activityObj.activityName = activityData.name;
                activityObj.activityType = activityData.activityType;
                activityObj.onDeleteActivity = courseObj.units[index].activityOnDeleteActivity;
                activityObj.onActivityOptionsChange = courseObj.units[index].activityOnActivityOptionsChange;
                activityObj.unitID = courseObj.units[index].ID;
                activityObj.onChange = courseObj.units[index].activityOnChange;
                activityObj.questionActivityType = 'practice';

                courseObj.units[index].activities.push(activityObj);
            }
            else {
                courseObj.units[index].activities = [];
                let id = Math.random() * 10000000000000000000;
                activityObj.ID = "activity_" + id;
                activityObj.name = "activity_" + id; //needed for sorting (drag n drop)
                activityObj.onDeleteActivity = courseObj.units[index].activityOnDeleteActivity;
                activityObj.onActivityOptionsChange = courseObj.units[index].activityOnActivityOptionsChange;
                activityObj.unitID = courseObj.units[index].ID;
                activityObj.onChange = courseObj.units[index].activityOnChange;
                activityObj.questionActivityType = 'practice';
                activityObj.activityID = activityData.id;
                activityObj.activityName = activityData.name;
                activityObj.activityType = activityData.activityType;
                courseObj.units[index].activities.push(activityObj);
            }
        }
        const { dispatch } = this.props;
        ManageCourseActions.setCreateCourseData(dispatch, courseObj, true, selectedUnitId);

        for (let i = 0; i < this.props.manageActivities.searchResults.length; i++) {
            if (this.props.manageActivities.searchResults[i].id === activityData.id) {
                this.props.manageActivities.searchResults[i].disabled = true;
            }
        };
        this.setState({ showActivityAddingConfirmationDialog: true });
    }

    getActivityAddingConfirmationDialogForCourses() {
        return <Modal
            show={this.state.showActivityAddingConfirmationDialog}
            onHide={() => {
                this.setState({ showActivityAddingConfirmationDialog: false });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Activity Added! Do you want to add another activity to this course?</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <Button onClick={() => {
                    browserHistory.push('/courses/create-course');
                }}>No</Button>
                <Button onClick={() => {
                    this.setState({ showActivityAddingConfirmationDialog: false });
                }} bsStyle='primary'>Yes</Button>
            </Modal.Footer>
        </Modal>;
    }

    getConfirmationMessage() {
        return <Modal
            show={this.state.showConfirmationMessage}
            onHide={() => {
                this.setState({ showConfirmationMessage: false });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Body>
                <h3>This activity has been assigned to students and can not be modified or deleted anymore. You can duplicate this activity and modify it.</h3>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => { this.setState({ showConfirmationMessage: false }) }}>Ok</Button>
            </Modal.Footer>
        </Modal>
    }

    render() {
        let tempAllResults = this.props.manageActivities.results;
        const { screenWidth } = this.state;
        const isMobile = screenWidth < 768;


        for (let q = 0; q < tempAllResults.length; q++) {
            if (tempAllResults[q].activityType) {
                switch (tempAllResults[q].activityType.toLowerCase()) {
                    case 'learning':
                        tempAllResults[q].className = 'learning';
                        break;
                    case 'video':
                        tempAllResults[q].className = 'video';
                        break;
                    case 'flashcards':
                    case 'flashcard':
                        tempAllResults[q].className = 'flashCard';
                        break;
                    case 'question':
                        tempAllResults[q].className = 'questionRow';
                        break;
                    default:
                        tempAllResults[q].className = 'learning';
                        break;
                }
            }

            if (tempAllResults[q].id === this.state.selectedRow.id) {
                tempAllResults[q].className += " highlightRow";
            }
        }
        let actions = [
            {
                linkLabel: 'View', action: (activityData, evt) => {

                    this.viewActivity(activityData);
                },
                isConditional: false,
                conditionExpression: (currentRecord) => {
                    // this function must return true or false. if it doesn't return true, link will not be displayed.
                    return currentRecord.activityType.toLowerCase() !== 'pdf';
                }
            },
            {
                linkLabel: 'Edit', action: (selectedRecord) => {

                    let that = this;
                    let params = {
                        activityID: selectedRecord.id,
                    };
                    let pathTemplate = '/activity/{activityID}';
                    let method = 'GET';
                    let additionalParams = {};
                    let body = {};
                    this.showIndicator();
                    apiClient.invokeApi(params, pathTemplate, method, additionalParams, body).then(function (result) {
                        that.hideIndicator();

                        if ((result.data.isEditable) || (!result.data.isEditable && that.props.auth.user.userType.toLowerCase() === 'admin' && that.props.auth.user.schoolName === 'LearnerPal')) {
                            that.selectEditActivity(selectedRecord)
                        }
                        else {
                            that.setState({ showConfirmationMessage: true });
                        }
                    }).catch(function (result) {

                    });
                },
                isConditional: true,
                conditionExpression: (currentRecord) => {
                    return (!isMobile && (
                        (this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName === "LearnerPal") ||
                        (currentRecord.ownerName === this.props.auth.user.userName) ||
                        (currentRecord.originalOwner === this.props.auth.user.userName) ||
                        (this.props.auth.user.linkedAccounts && currentRecord.originalOwner === this.props.auth.user.linkedAccounts.learnerPal) ||
                        (this.props.auth.user.linkedAccounts && currentRecord.ownerName === this.props.auth.user.linkedAccounts.learnerPal) &&
                        (currentRecord.activityState && currentRecord.activityState.toLowerCase() !== 'published')
                    ));
                }
            },
            {
                linkLabel: 'Duplicate', action: (selectedRecord) => {
                    this.setState({ showDuplicateDialog: true, duplicateRecord: selectedRecord, duplicateDialogInput: '' });
                },
                isConditional: false
            },
            {
                linkLabel: 'Assign', action: (selectedRecord) => {
                    this.showIndicator();
                    let that = this;
                    apiClient.invokeApi({ activityID: selectedRecord.id }, '/activity/{activityID}', 'GET', {}, {}).then(function (result) {
                        that.hideIndicator();
                        if (result.data.activityType === 'question') {
                            browserHistory.push({
                                pathname: '/assignments/assign',
                                state: { isFromListScreen: true, currentActivityObj: result.data, currentActivity: result.data.activityID, view: 'activity' }
                            });
                        }
                        else {
                            browserHistory.push({
                                pathname: '/assignments/assign',
                                state: { currentActivityObj: result.data, currentActivity: result.data.activityID, view: 'activity' }
                            });
                        }

                    }).catch(function (result) {

                    });
                },
                isConditional: false
            },
            {
                linkLabel: 'Delete', action: (selectedRecord) => {
                    let params = {
                        activityID: selectedRecord.id,
                    };
                    let pathTemplate = '/activity/{activityID}';
                    let method = 'GET';
                    let additionalParams = {};
                    let body = {};
                    this.showIndicator();
                    let context = this;
                    apiClient.invokeApi(params, pathTemplate, method, additionalParams, body).then(function (result) {
                        context.hideIndicator();

                        if ((result.data.isEditable) || (!result.data.isEditable && context.props.auth.user.userType.toLowerCase() === 'admin' && context.props.auth.user.schoolName === 'LearnerPal')) {
                            context.setState({ showDeleteDialog: true, record: selectedRecord });
                        }
                        else {
                            context.setState({ showConfirmationMessage: true });
                        }
                    });
                },
                isConditional: true,
                conditionExpression: (currentRecord) => {
                    return (currentRecord.ownerName === this.props.auth.user.userName
                        || (this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName === "LearnerPal")
                        || (currentRecord.originalOwner === this.props.auth.user.userName) ||
                        (currentRecord.originalOwner === this.props.auth.user.userName) ||
                        (this.props.auth.user.linkedAccounts && currentRecord.originalOwner === this.props.auth.user.linkedAccounts.learnerPal) ||
                        (this.props.auth.user.linkedAccounts && currentRecord.ownerName === this.props.auth.user.linkedAccounts.learnerPal)
                    );
                }
            },
            {
                linkLabel: 'Print', action: (selectedRecord, evt) => {
                    this.printContent(selectedRecord);
                },
                isConditional: true,
                conditionExpression: (currentRecord) => {
                    // this function must return true or false. if it doesn't return true, link will not be displayed.
                    if (currentRecord.activityType) {
                        return currentRecord.activityType.toLowerCase() === 'question';
                    }

                }
            },
            {
                linkLabel: 'Print Answer Sheet', action: (selectedRecord, evt) => {
                    this.printAnswerContent(selectedRecord);
                },
                isConditional: true,
                conditionExpression: (currentRecord) => {
                    // this function must return true or false. if it doesn't return true, link will not be displayed.
                    // check whether this user is a teacher or student. if student dont show this link.
                    if (currentRecord.activityType) {
                        return currentRecord.activityType.toLowerCase() === 'question';
                    }

                }
            }

        ];
        let showActionMenu = true;
        let actionButtons = [];

        if (this.state.isFromAssignmentToAssignActivity) {
            actionButtons = [
                {
                    linkLabel: 'View', action: (activityData, evt) => {
                        this.viewActivity(activityData);
                    }
                },
                {
                    linkLabel: 'Assign', action: (activityData, evt) => {

                        browserHistory.push({
                            pathname: '/assignments/assign',
                            state: { currentActivityObj: activityData, currentActivity: activityData.id, view: 'activity' }
                        });
                        // }).catch( function(result){
                        // });

                    }
                }
            ];
        }
        else if (this.state.isFromCourse) {
            actionButtons = [
                {
                    linkLabel: 'View', action: (activityData, evt) => {
                        this.viewActivity(activityData);
                    }
                },
                {
                    linkLabel: 'Add', action: (activityData, evt) => {
                        this.addToUnit(activityData);
                    }, isConditional: true,
                    conditionExpression: (currentRecord) => {
                        // this function must return true or false. if it doesn't return true, link will not be displayed.
                        // check whether this user is a teacher or student. if student dont show this link.
                        return !currentRecord.disabled;
                    }
                }
            ];
        }


        if (this.state.isFromCourse || this.state.isFromAssignmentToAssignActivity) {
            showActionMenu = false;
        }

        let esAttr = this.props.elasticSearchAttributes.map.get('activities');
        let maintenanceTableData = {
            emptyMessage: 'No records found.',
            showActionMenu: showActionMenu,
            actionButtons: actionButtons,
            actions: actions,
            columns: [
                {
                    name: 'name', type: 'text', label: 'Activity', options: {
                        sortable: true, customDom: (record) => { // customDom is not applicable to array type attributes, ex: subjects in activity.
                            return (
                                <td width="37%">
                                    <span className="typeIcon"> </span>
                                    <span className="contentType ">
                                        <div style={{ fontStyle: 'bold', color: "#0f6fb4", 'width': '100%', 'white-space': 'nowrap', 'overflow': 'hidden', 'text-overflow': 'ellipsis' }} title={record.name}><strong>{record.name}</strong></div>
                                        <p>By <strong>{record.ownerName}</strong></p>
                                    </span>
                                </td>
                            );
                        }
                    }
                },
                {
                    name: 'subjects',
                    type: 'array',
                    label: 'Subjects',
                    options: { sortable: true, dataType: 'text', dynamoTyped: false, width: '22%' }
                },
                { name: 'activityType', type: 'text', label: 'type', options: { sortable: true, width: '10%' } },
                { name: 'createdAt', type: 'date', label: 'created On', options: { sortable: true, width: '12%' } }
            ],
            items: this.props.manageActivities.results,
            pageable: true,
            rowClickAction: this.viewActivityOnRowClick,
            totalRecords: this.props.manageActivities.totalRecords,
            pagination: {
                pageSize: esAttr && esAttr.pageSize ? esAttr.pageSize : 50,
                activePage: 1,
                showPageInput: true,
                showFirstButton: true,
                showLastButton: true,
                showNextButton: true,
                showPreviousButton: true,
                firstButtonLabel: '<<',
                lastButtonLabel: '>>',
                previousButtonLabel: '<',
                nextButtonLabel: '>',
                showPageInfo: true,
                showGotoInput: false,
                gotoInputLabel: 'Goto',
                pageLinkSize: 5
            },
        };

        let sideViewPanel = null;

        if (this.props.manageActivities.isFetchingViewActivity && !this.props.manageActivities.viewActivityFetchingFailed) {
            sideViewPanel = this.getHtmlForViewActivity('Fetching data, please wait');
        }
        else if (this.props.manageActivities.viewActivityFetchingFailed && this.props.manageActivities.isFetchingViewActivity) {
            sideViewPanel = this.getHtmlForViewActivity('There was an error retrieving the activity. Please try again later. If the error persists, please contact us at info@learnerpal.com');
        }

        if (this.viewQuestionPanel) {
            let allQuestions = [];
            let questionsHtmlArray = [];
            let tempPropHolder = this.props.manageActivities.viewActivityData;
            let parser = new DOMParser();
            for (let i = 0; i < tempPropHolder.questions.length; i++) {
                let question = tempPropHolder.questions[i];
                let questionToDisplay = question.question.replace('&nbsp;', '');
                let htmlToReactParser = new Parser();
                questionToDisplay = questionToDisplay.replace(/&nbsp;/g, " ");
                questionToDisplay = questionToDisplay.replace(/<br>/g, " ");
                questionToDisplay = questionToDisplay.replace(/<br\/>/g, " ");
                questionToDisplay = questionToDisplay.replace('[[[LP_ANSWERS]]]', '');
                // questionToDisplay = questionToDisplay.replace(/<img .*?>/g,"");
                questionToDisplay = questionToDisplay.replace(/<p>/g, "");
                questionToDisplay = questionToDisplay.replace(/<\/p>/g, "");
                while (questionToDisplay.indexOf("size=\"4\"") >= 0) {
                    questionToDisplay = questionToDisplay.replace("size=\"4\"", "");
                }
                while (questionToDisplay.indexOf("size=\"3\"") >= 0) {
                    questionToDisplay = questionToDisplay.replace("size=\"3\"", "");
                }
                while (questionToDisplay.indexOf("size='4'") >= 0) {
                    questionToDisplay = questionToDisplay.replace("size='4'", "");
                }
                while (questionToDisplay.indexOf("size='3'") >= 0) {
                    questionToDisplay = questionToDisplay.replace("size='3'", "");
                }
                let text = htmlToReactParser.parse('<span>' + (i + 1) + ")  " + questionToDisplay + '</span>');

                questionsHtmlArray.push(
                    <div key={'question-' + i} dir lang={this.props.auth.user.prefLanguage} className="listNumQuestion" onClick={(event) => {
                        this.showFullQuestion(i, event);
                    }}>
                        <p><span dir lang={this.props.auth.user.prefLanguage}>{text}</span></p>
                        <span className="DirectionArrow" />
                    </div>
                );
            }
            let panel = null;
            let context = this;
            let that = this;
            if (this.isShowFullQuestion === false) {
                panel = <div className="FrontQuestionBox">
                    <div className="fixIconDiv">
                        <span className="cross" onClick={this.closeViewActivity} />
                        <h3>{this.props.manageActivities.viewActivityData.activityName}</h3>
                        <a href="#" className="seDeatil" onClick={this.showViewDetailsPage}>View Details</a>
                        
                        <div className="actionBlockDiv">
                            {
                                (!isMobile && !this.state.isFromCourse && !this.state.isFromAssignmentToAssignActivity && (
                                    (this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName === "LearnerPal") ||
                                    (this.props.manageActivities.viewActivityData.ownerName === this.props.auth.user.userName) ||
                                    (this.props.manageActivities.viewActivityData.originalOwner === this.props.auth.user.userName) &&
                                    (this.props.manageActivities.viewActivityData.activityState && this.props.manageActivities.viewActivityData.activityState.toLowerCase() !== 'published')
                                ))
                                &&
                                <ActionIcons iconType={ActionIconTypes.Edit} action={function () {
                                    let params = {
                                        activityID: that.state.selectedRow.id,
                                    };
                                    let pathTemplate = '/activity/{activityID}';
                                    let method = 'GET';
                                    let additionalParams = {};
                                    let body = {};
                                    context.showIndicator();
                                    apiClient.invokeApi(params, pathTemplate, method, additionalParams, body).then(function (result) {
                                        context.hideIndicator();

                                        if ((result.data.isEditable) || (!result.data.isEditable && context.props.auth.user.userType.toLowerCase() === 'admin' && context.props.auth.user.schoolName === 'LearnerPal')) {
                                            context.selectEditActivity(context.state.selectedRow);
                                        }
                                        else {
                                            context.setState({ showConfirmationMessage: true });
                                        }
                                    }).catch(function (result) {

                                    });
                                }} />
                            }
                            {(this.state.isFromCourse && !this.state.isFromAssignmentToAssignActivity &&
                                <div> <ActionIcons iconType={ActionIconTypes.Add} action={function () {
                                    context.addToUnit(context.state.selectedRow);
                                    context.closeViewActivity();
                                }} /><p>Add to Course</p>
                                </div>)
                            }

                            {(!this.state.isFromCourse && this.state.isFromAssignmentToAssignActivity && <ActionIcons iconType={ActionIconTypes.Assign} action={function () {
                                // context.addToUnit(context.state.selectedRow);
                                context.closeViewActivity();
                            }} />)}

                            {(!this.state.isFromCourse && !this.state.isFromAssignmentToAssignActivity && <ActionIcons iconType={ActionIconTypes.Print} action={function () {
                                context.printContent({ id: context.state.selectedRow.id });
                            }} />)}
                            {(!this.state.isFromCourse && !this.state.isFromAssignmentToAssignActivity &&
                                <ActionIcons iconType={ActionIconTypes.Duplicate} action={function () {
                                    context.setState({
                                        showDuplicateDialog: true,
                                        duplicateRecord: { ...context.state.selectedRow, id: context.state.selectedRow.id }, duplicateDialogInput: ''
                                    });
                                }} />)}
                            {!this.state.isFromCourse && !this.state.isFromAssignmentToAssignActivity && (this.props.manageActivities.viewActivityData.ownerName === this.props.auth.user.userName
                                || (this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName === "LearnerPal")
                                || this.props.manageActivities.viewActivityData.originalOwner === this.props.auth.user.userName) &&
                                <ActionIcons iconType={ActionIconTypes.Delete} action={function () {
                                    let params = {
                                        activityID: context.state.selectedRow.id,
                                    };
                                    let pathTemplate = '/activity/{activityID}';
                                    let method = 'GET';
                                    let additionalParams = {};
                                    let body = {};
                                    context.showIndicator();
                                    apiClient.invokeApi(params, pathTemplate, method, additionalParams, body).then(function (result) {
                                        context.hideIndicator();

                                        if ((result.data.isEditable) || (!result.data.isEditable && context.props.auth.user.userType.toLowerCase() === 'admin' && context.props.auth.user.schoolName === 'LearnerPal')) {
                                            context.setState({
                                                showDeleteDialog: true,
                                                record: { ...context.state.selectedRow, id: context.state.selectedRow.id }
                                            });
                                        }
                                        else {
                                            context.setState({ showConfirmationMessage: true });
                                        }
                                    }).catch(function (result) {

                                    });

                                }} />}
                            
                        </div>
                                {isMobile && <a href="#" style={{fontSize : "17px"}} onClick={() => {
                                    this.showIndicator();
                                    let that = this;
                                    apiClient.invokeApi({ activityID: that.state.selectedRow.id }, '/activity/{activityID}', 'GET', {}, {}).then(function (result) {
                                        that.hideIndicator();
                                        if (result.data.activityType === 'question') {
                                            browserHistory.push({
                                                pathname: '/assignments/assign',
                                                state: { isFromListScreen: true, currentActivityObj: result.data, currentActivity: result.data.activityID, view: 'activity' }
                                            });
                                        }
                                        else {
                                            browserHistory.push({
                                                pathname: '/assignments/assign',
                                                state: { currentActivityObj: result.data, currentActivity: result.data.activityID, view: 'activity' }
                                            });
                                        }
            
                                    }).catch(function (result) {
            
                                    });
                                }}><font className="pull right" style={{fontSize : "19px"}}>Assign</font></a>}
                   
                    </div>

                    <div className="questionBlock NiceScroll">
                        <h3 className="noteHeading">Question Activity</h3>
                        <p>Click on the question to view the explanation</p>
                        {questionsHtmlArray}
                    </div>
                </div>
            }
            else {
                panel = this.props.manageActivities.fullQuestion;
            }
            sideViewPanel = <div className="topRowHaedingAnch">
                <span className="scrollBar" />
                {panel}
            </div>
        }
        else if (this.viewURLPanel) {
            let tempPropHolder = this.props.manageActivities.viewActivityData;
            let context = this;
            sideViewPanel =
                <div className="learningSlideTab "><span className="cross" onClick={this.closeViewActivity} />
                    <div className="FrontQuestionBox">
                        <h3>{this.props.manageActivities.viewActivityData.activityName}</h3>
                        <div className="actionBlockDiv">
                            {(!isMobile && !this.state.isFromCourse && !this.state.isFromAssignmentToAssignActivity && (
                                (this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName === "LearnerPal") ||
                                (this.props.manageActivities.viewActivityData.ownerName === this.props.auth.user.userName) ||
                                (this.props.manageActivities.viewActivityData.originalOwner === this.props.auth.user.userName) &&
                                (this.props.manageActivities.viewActivityData.activityState && this.props.manageActivities.viewActivityData.activityState.toLowerCase() !== 'published')
                            )) &&
                                <ActionIcons iconType={ActionIconTypes.Edit} action={function () {
                                    let params = {
                                        activityID: context.state.selectedRow.id,
                                    };
                                    let pathTemplate = '/activity/{activityID}';
                                    let method = 'GET';
                                    let additionalParams = {};
                                    let body = {};
                                    context.showIndicator();
                                    apiClient.invokeApi(params, pathTemplate, method, additionalParams, body).then(function (result) {
                                        context.hideIndicator();

                                        if ((result.data.isEditable) || (!result.data.isEditable && context.props.auth.user.userType.toLowerCase() === 'admin' && context.props.auth.user.schoolName === 'LearnerPal')) {
                                            context.selectEditActivity(context.state.selectedRow);
                                        }
                                        else {
                                            context.setState({ showConfirmationMessage: true });
                                        }
                                    }).catch(function (result) {

                                    });
                                }} />
                            }
                            {(this.state.isFromCourse && !this.state.isFromAssignmentToAssignActivity && <ActionIcons iconType={ActionIconTypes.Add} action={function () {
                                context.addToUnit(context.state.selectedRow);
                                context.closeViewActivity();
                            }} />)}

                            {(!this.state.isFromCourse && this.state.isFromAssignmentToAssignActivity && <ActionIcons iconType={ActionIconTypes.Assign} action={function () {
                                // context.addToUnit(context.state.selectedRow);
                                context.closeViewActivity();
                            }} />)}
                            {(!this.state.isFromCourse && !this.state.isFromAssignmentToAssignActivity &&
                                <ActionIcons iconType={ActionIconTypes.Duplicate} action={function () {
                                    context.setState({
                                        showDuplicateDialog: true,
                                        duplicateRecord: { ...context.state.selectedRow, id: context.state.selectedRow.id }, duplicateDialogInput: ''
                                    });
                                }} />)}
                            {!this.state.isFromCourse && !this.state.isFromAssignmentToAssignActivity && (this.props.manageActivities.viewActivityData.ownerName === this.props.auth.user.userName
                                || (this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName === "LearnerPal")
                                || this.props.manageActivities.viewActivityData.originalOwner === this.props.auth.user.userName) &&
                                <ActionIcons iconType={ActionIconTypes.Delete} action={function () {
                                    let params = {
                                        activityID: context.state.selectedRow.id,
                                    };
                                    let pathTemplate = '/activity/{activityID}';
                                    let method = 'GET';
                                    let additionalParams = {};
                                    let body = {};
                                    context.showIndicator();
                                    apiClient.invokeApi(params, pathTemplate, method, additionalParams, body).then(function (result) {
                                        context.hideIndicator();

                                        if ((result.data.isEditable) || (!result.data.isEditable && context.props.auth.user.userType.toLowerCase() === 'admin' && context.props.auth.user.schoolName === 'LearnerPal')) {
                                            context.setState({
                                                showDeleteDialog: true,
                                                record: { ...context.state.selectedRow, id: context.state.selectedRow.id }
                                            });
                                        }
                                        else {
                                            context.setState({ showConfirmationMessage: true });
                                        }
                                    }).catch(function (result) {

                                    });
                                }} />
                            }
                        </div>
                        <div className="questionBlock NiceScroll">
                            <h3 className="noteHeading">URL Activity</h3>
                            <div className="listNumQuestion">
                                <h4>Description</h4>
                                <p>{this.props.manageActivities.viewActivityData.description}</p>
                                <h4>URL</h4>
                                <a href={this.props.manageActivities.viewActivityData.documentLink}
                                    className="LinkLeaning"
                                    target="_blank">
                                    {this.props.manageActivities.viewActivityData.documentLink}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
        }
        else if (this.viewPDFPanel) {
            let tempPropHolder = this.props.manageActivities.viewActivityData;
            let context = this;
            sideViewPanel =
                <div className="learningSlideTab "><span className="cross" onClick={this.closeViewActivity} />
                    <div className="FrontQuestionBox">
                        <h3>{this.props.manageActivities.viewActivityData.activityName}</h3>
                        <div className="actionBlockDiv">
                            {(!isMobile && !this.state.isFromCourse && !this.state.isFromAssignmentToAssignActivity && (
                                (this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName === "LearnerPal") ||
                                (this.props.manageActivities.viewActivityData.ownerName === this.props.auth.user.userName) ||
                                (this.props.manageActivities.viewActivityData.originalOwner === this.props.auth.user.userName) &&
                                (this.props.manageActivities.viewActivityData.activityState && this.props.manageActivities.viewActivityData.activityState.toLowerCase() !== 'published')
                            )) &&
                                <ActionIcons iconType={ActionIconTypes.Edit} action={function () {

                                    let params = {
                                        activityID: context.state.selectedRow.id,
                                    };
                                    let pathTemplate = '/activity/{activityID}';
                                    let method = 'GET';
                                    let additionalParams = {};
                                    let body = {};
                                    context.showIndicator();
                                    apiClient.invokeApi(params, pathTemplate, method, additionalParams, body).then(function (result) {
                                        context.hideIndicator();

                                        if ((result.data.isEditable) || (!result.data.isEditable && context.props.auth.user.userType.toLowerCase() === 'admin' && context.props.auth.user.schoolName === 'LearnerPal')) {
                                            context.selectEditActivity(context.state.selectedRow);
                                        }
                                        else {
                                            context.setState({ showConfirmationMessage: true });
                                        }
                                    }).catch(function (result) {

                                    });
                                }} />
                            }
                            {(this.state.isFromCourse && !this.state.isFromAssignmentToAssignActivity && <ActionIcons iconType={ActionIconTypes.Add} action={function () {
                                context.addToUnit(context.state.selectedRow);
                                context.closeViewActivity();
                            }} />)}

                            {(!this.state.isFromCourse && this.state.isFromAssignmentToAssignActivity && <ActionIcons iconType={ActionIconTypes.Assign} action={function () {
                                // context.addToUnit(context.state.selectedRow);
                                context.closeViewActivity();
                            }} />)}
                            {(!this.state.isFromCourse && !this.state.isFromAssignmentToAssignActivity &&
                                <ActionIcons iconType={ActionIconTypes.Duplicate} action={function () {
                                    context.setState({
                                        showDuplicateDialog: true,
                                        duplicateRecord: { ...context.state.selectedRow, id: context.state.selectedRow.id }, duplicateDialogInput: ''
                                    });
                                }} />)}
                            {!this.state.isFromCourse && !this.state.isFromAssignmentToAssignActivity && (this.props.manageActivities.viewActivityData.ownerName === this.props.auth.user.userName
                                || (this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName === "LearnerPal")
                                || this.props.manageActivities.viewActivityData.originalOwner === this.props.auth.user.userName) &&
                                <ActionIcons iconType={ActionIconTypes.Delete} action={function () {
                                    let params = {
                                        activityID: context.state.selectedRow.id,
                                    };
                                    let pathTemplate = '/activity/{activityID}';
                                    let method = 'GET';
                                    let additionalParams = {};
                                    let body = {};
                                    context.showIndicator();
                                    apiClient.invokeApi(params, pathTemplate, method, additionalParams, body).then(function (result) {
                                        context.hideIndicator();

                                        if ((result.data.isEditable) || (!result.data.isEditable && context.props.auth.user.userType.toLowerCase() === 'admin' && context.props.auth.user.schoolName === 'LearnerPal')) {
                                            context.setState({
                                                showDeleteDialog: true,
                                                record: { ...context.state.selectedRow, id: context.state.selectedRow.id }
                                            });
                                        }
                                        else {
                                            context.setState({ showConfirmationMessage: true });
                                        }
                                    }).catch(function (result) {

                                    });;
                                }} />
                            }
                        </div>
                        <div className="questionBlock NiceScroll">
                            <h3 className="noteHeading">PDF Activity</h3>
                            <div className="listNumQuestion">
                                <h4>PDF</h4>
                                <a href={this.props.manageActivities.viewActivityData.documentLink} target="_blank"
                                    className="LinkLeaning">
                                    {this.props.manageActivities.viewActivityData.fileName}
                                    <br />
                                    <ReactPDF
                                        file={this.props.manageActivities.viewActivityData.documentLink}
                                        scale={0.4}
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
        }
        else if (this.viewVIDEOPanel) {
            let tempPropHolder = this.props.manageActivities.viewActivityData;
            let context = this;
            sideViewPanel =
                <div className="learningSlideTab "><span className="cross" onClick={this.closeViewActivity} />
                    <div className="FrontQuestionBox">
                        <h3>{this.props.manageActivities.viewActivityData.activityName}</h3>
                        <div className="actionBlockDiv">
                            {(!isMobile && !this.state.isFromCourse && !this.state.isFromAssignmentToAssignActivity && (
                                (this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName === "LearnerPal") ||
                                (this.props.manageActivities.viewActivityData.ownerName === this.props.auth.user.userName) ||
                                (this.props.manageActivities.viewActivityData.originalOwner === this.props.auth.user.userName) &&
                                (this.props.manageActivities.viewActivityData.activityState && this.props.manageActivities.viewActivityData.activityState.toLowerCase() !== 'published')
                            )) &&
                                <ActionIcons iconType={ActionIconTypes.Edit} action={function () {


                                    let params = {
                                        activityID: context.state.selectedRow.id,
                                    };
                                    let pathTemplate = '/activity/{activityID}';
                                    let method = 'GET';
                                    let additionalParams = {};
                                    let body = {};
                                    context.showIndicator();
                                    apiClient.invokeApi(params, pathTemplate, method, additionalParams, body).then(function (result) {
                                        context.hideIndicator();
                                        ;
                                        if ((result.data.isEditable) || (!result.data.isEditable && context.props.auth.user.userType.toLowerCase() === 'admin' && context.props.auth.user.schoolName === 'LearnerPal')) {
                                            context.selectEditActivity(context.state.selectedRow);
                                        }
                                        else {
                                            context.setState({ showConfirmationMessage: true });
                                        }
                                    }).catch(function (result) {

                                    });
                                }} />
                            }
                            {(this.state.isFromCourse && !this.state.isFromAssignmentToAssignActivity && <ActionIcons iconType={ActionIconTypes.Add} action={function () {
                                context.addToUnit(context.state.selectedRow);
                                context.closeViewActivity();
                            }} />)}

                            {(!this.state.isFromCourse && this.state.isFromAssignmentToAssignActivity && <ActionIcons iconType={ActionIconTypes.Assign} action={function () {
                                // context.addToUnit(context.state.selectedRow);
                                context.closeViewActivity();
                            }} />)}
                            {(!this.state.isFromCourse && !this.state.isFromAssignmentToAssignActivity &&
                                <ActionIcons iconType={ActionIconTypes.Duplicate} action={function () {
                                    context.setState({
                                        showDuplicateDialog: true,
                                        duplicateRecord: { ...context.state.selectedRow, id: context.state.selectedRow.id }, duplicateDialogInput: ''
                                    });
                                }} />)}
                            {!this.state.isFromCourse && !this.state.isFromAssignmentToAssignActivity && (this.props.manageActivities.viewActivityData.ownerName === this.props.auth.user.userName
                                || (this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName === "LearnerPal")
                                || this.props.manageActivities.viewActivityData.originalOwner === this.props.auth.user.userName) &&
                                <ActionIcons iconType={ActionIconTypes.Delete} action={function () {
                                    let params = {
                                        activityID: context.state.selectedRow.id,
                                    };
                                    let pathTemplate = '/activity/{activityID}';
                                    let method = 'GET';
                                    let additionalParams = {};
                                    let body = {};
                                    context.showIndicator();
                                    apiClient.invokeApi(params, pathTemplate, method, additionalParams, body).then(function (result) {
                                        context.hideIndicator();

                                        if ((result.data.isEditable) || (!result.data.isEditable && context.props.auth.user.userType.toLowerCase() === 'admin' && context.props.auth.user.schoolName === 'LearnerPal')) {
                                            context.setState({
                                                showDeleteDialog: true,
                                                record: { ...context.state.selectedRow, id: context.state.selectedRow.id }
                                            });
                                        }
                                        else {
                                            context.setState({ showConfirmationMessage: true });
                                        }
                                    }).catch(function (result) {

                                    });;
                                }} />
                            }
                        </div>
                        <div className="questionBlock NiceScroll">
                            <h3 className="noteHeading">Video Activity</h3>
                            <div className="listNumQuestion">
                                <h4>Video</h4>
                                <a href={this.props.manageActivities.viewActivityData.documentLink} target="_blank"
                                    className="LinkLeaning">
                                    {this.props.manageActivities.viewActivityData.fileName}
                                    <br />
                                    <ReactPlayer width="340px" height="auto"
                                        playing={false}
                                        url={this.props.manageActivities.viewActivityData.documentLink}
                                        controls={true}
                                        scale={0.4}
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
        }
        else if (this.viewFlashcardsPanel) {
            let flashcardsArray = [];
            let tempPropHolder = this.props.manageActivities.viewActivityData;
            let context = this;
            for (let j = 0; j < tempPropHolder.words.length; j++) {
                flashcardsArray.push(
                    <div dir lang={this.props.auth.user.prefLanguage} key={'question-' + j} className="listNumQuestion">
                        <h4><span dir lang={this.props.auth.user.prefLanguage}>{tempPropHolder.words[j].translatedWord ? tempPropHolder.words[j].translatedWord : tempPropHolder.words[j].word}</span></h4>
                        <p><span dir lang={this.props.auth.user.prefLanguage}>{tempPropHolder.words[j].definition}</span></p>
                    </div>
                );
            }
            sideViewPanel = <div className="topRowHaedingAnch">
                <span className="scrollBar" />
                <div className="FrontQuestionBox">
                    <div className="fixIconDiv">
                        <span className="cross" onClick={this.closeViewActivity} />
                        <h3>{this.props.manageActivities.viewActivityData.activityName}</h3>
                        <div className="actionBlockDiv">
                            {(!isMobile && !this.state.isFromCourse && !this.state.isFromAssignmentToAssignActivity && (
                                (this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName === "LearnerPal") ||
                                (this.props.manageActivities.viewActivityData.ownerName === this.props.auth.user.userName) ||
                                (this.props.manageActivities.viewActivityData.originalOwner === this.props.auth.user.userName) &&
                                (this.props.manageActivities.viewActivityData.activityState && this.props.manageActivities.viewActivityData.activityState.toLowerCase() !== 'published')
                            )) &&
                                <ActionIcons iconType={ActionIconTypes.Edit} action={function () {
                                    let params = {
                                        activityID: context.state.selectedRow.id,
                                    };
                                    let pathTemplate = '/activity/{activityID}';
                                    let method = 'GET';
                                    let additionalParams = {};
                                    let body = {};
                                    context.showIndicator();
                                    apiClient.invokeApi(params, pathTemplate, method, additionalParams, body).then(function (result) {
                                        context.hideIndicator();

                                        if ((result.data.isEditable) || (!result.data.isEditable && context.props.auth.user.userType.toLowerCase() === 'admin' && context.props.auth.user.schoolName === 'LearnerPal')) {
                                            context.selectEditActivity(context.state.selectedRow);
                                        }
                                        else {
                                            context.setState({ showConfirmationMessage: true });
                                        }
                                    }).catch(function (result) {

                                    });
                                }} />
                            }
                            {(this.state.isFromCourse && !this.state.isFromAssignmentToAssignActivity && <ActionIcons iconType={ActionIconTypes.Add} action={function () {
                                context.addToUnit(context.state.selectedRow);
                                context.closeViewActivity();
                            }} />)}

                            {(!this.state.isFromCourse && this.state.isFromAssignmentToAssignActivity && <ActionIcons iconType={ActionIconTypes.Assign} action={function () {
                                // context.addToUnit(context.state.selectedRow);
                                context.closeViewActivity();
                            }} />)}
                            {(!this.state.isFromCourse && !this.state.isFromAssignmentToAssignActivity && <ActionIcons iconType={ActionIconTypes.Duplicate} action={function () {
                                context.setState({
                                    showDuplicateDialog: true,
                                    duplicateRecord: { ...context.state.selectedRow, id: context.state.selectedRow.id }, duplicateDialogInput: ''
                                });
                            }} />)}
                            {!this.state.isFromCourse && !this.state.isFromAssignmentToAssignActivity && (this.props.manageActivities.viewActivityData.ownerName === this.props.auth.user.userName
                                || (this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName === "LearnerPal")
                                || this.props.manageActivities.viewActivityData.originalOwner === this.props.auth.user.userName) &&
                                <ActionIcons iconType={ActionIconTypes.Delete} action={function () {
                                    let params = {
                                        activityID: context.state.selectedRow.id,
                                    };
                                    let pathTemplate = '/activity/{activityID}';
                                    let method = 'GET';
                                    let additionalParams = {};
                                    let body = {};
                                    context.showIndicator();
                                    apiClient.invokeApi(params, pathTemplate, method, additionalParams, body).then(function (result) {
                                        context.hideIndicator();

                                        if ((result.data.isEditable) || (!result.data.isEditable && context.props.auth.user.userType.toLowerCase() === 'admin' && context.props.auth.user.schoolName === 'LearnerPal')) {
                                            context.setState({
                                                showDeleteDialog: true,
                                                record: { ...context.state.selectedRow, id: context.state.selectedRow.id }
                                            });
                                        }
                                        else {
                                            context.setState({ showConfirmationMessage: true });
                                        }
                                    }).catch(function (result) {

                                    });
                                }} />
                            }
                        </div>
                    </div>
                    <div className="questionBlock NiceScroll">
                        <h3 className="noteHeading">Flashcard Activity</h3>
                        {flashcardsArray}
                    </div>
                </div>
            </div>
        }
        else if (this.viewHelpText) {
            sideViewPanel = <div className="topRowHaedingAnch">
                <span className="scrollBar" />
                <div className="FrontQuestionBox">
                    <div className="questionBlock NiceScroll">
                        <span className="cross" onClick={this.closeViewActivity} />
                        <div className="infoIcon" style={{
                            width: '0px',
                            position: 'relative',
                            marginRight: '100px',
                            float: 'left'
                        }}>
                            <img src={logo} style={{ height: '70px' }} />
                        </div>
                        <h1>Manage Activities</h1>
                    </div>
                    <div style={{ marginTop: '30px' }}>
                        <div className="DropDivBlock" style={{ display: 'inline' }}>
                            <span className="bullets" />
                            <span className="bullets" />
                            <span className="bullets" />
                        </div>
                        <div className="listNumQuestion" style={{ marginLeft: '20px', display: 'inline' }}>
                            <h3 style={{ color: '#337ab7' }}>View Activities</h3>
                            <p style={{ margin: '0px 44px' }}>You can view activities that you created or that were shared with you</p>
                        </div>
                    </div>
                    <div style={{ marginTop: '7px' }}>
                        <div className="DropDivBlock" style={{ display: 'inline' }}>
                            <span className="bullets" />
                            <span className="bullets" />
                            <span className="bullets" />
                        </div>
                        <div className="listNumQuestion" style={{ marginLeft: '20px', display: 'inline' }}>
                            <h3 style={{ color: '#337ab7' }}>Edit Activities</h3>
                            <p style={{ margin: '0px 44px' }}>You can edit your activities to make any needed changes</p>
                        </div>
                    </div>
                    <div style={{ marginTop: '7px' }}>
                        <div className="DropDivBlock" style={{ display: 'inline' }}>
                            <span className="bullets" />
                            <span className="bullets" />
                            <span className="bullets" />
                        </div>
                        <div className="listNumQuestion" style={{ marginLeft: '20px', display: 'inline' }}>
                            <h3 style={{ color: '#337ab7' }}>Assign Activities</h3>
                            <p style={{ margin: '0px 44px' }}>You can assign activities to individual students or classes with just a few clicks</p>
                        </div>
                    </div>
                    <div style={{ marginTop: '7px' }}>
                        <div className="DropDivBlock" style={{ display: 'inline' }}>
                            <span className="bullets" />
                            <span className="bullets" />
                            <span className="bullets" />
                        </div>
                        <div className="listNumQuestion" style={{ marginLeft: '20px', display: 'inline' }}>
                            <h3 style={{ color: '#337ab7' }}>Duplicate Activities</h3>
                            <p style={{ margin: '0px 44px' }}>You can make a copy of an existing activity and do a "save as"</p>
                        </div>
                    </div>
                    <div style={{ marginTop: '7px' }}>
                        <div className="DropDivBlock" style={{ display: 'inline' }}>
                            <span className="bullets" />
                            <span className="bullets" />
                            <span className="bullets" />
                        </div>
                        <div className="listNumQuestion" style={{ marginLeft: '20px', display: 'inline' }}>
                            <h3 style={{ color: '#337ab7' }}>Print Activities</h3>
                            <p style={{ margin: '0px 44px' }}>You can print question activities, as well as print the Answer Sheets</p>
                        </div>
                    </div>
                </div>
            </div>
        }

        let viewQuestionDetailsPage = null;
        let htmlToReactParser = new Parser();

        if (this.state.viewQuestionDetailsPage) {
            let tempQuestionsHolder = this.props.manageActivities.viewActivityData;
            let context = this;
            for (let z = 0; z < tempQuestionsHolder.questions.length; z++) {
                if (z === this.state.indexNumberForQuestions) {
                    let choicesHtml = [];
                    let quesText = "";
                    let choicesText = "";
                    let showChoices = true;
                    if (tempQuestionsHolder.questions[z] && tempQuestionsHolder.questions[z].question) {
                        quesText = `<span dir lang = ${this.props.auth.user.prefLanguage}>` + tempQuestionsHolder.questions[z].question + `</span>`;
                        showChoices = quesText.indexOf('LP_ANSWERS') < 0;
                        if (tempQuestionsHolder.questions[z].choices) {
                            for (let j = 1; j < tempQuestionsHolder.questions[z].choices.length + 1; j++) {
                                if (tempQuestionsHolder.questions[z].choices[j - 1] === tempQuestionsHolder.questions[z].answer) {
                                    choicesText += "<div class='fieldBox'><input type='radio' class='css-checkbox css-label1 bc_info card_side' value='choice" + j + "' name='choice" + j + "' checked/><label htmlFor='choice" + j + "' class='css-label radGroup1' >" + j + ") &nbsp; " + `<span dir lang = ${this.props.auth.user.prefLanguage}>` + tempQuestionsHolder.questions[z].choices[j - 1] + `</span>` + "</label></div>"
                                } else {
                                    choicesText += "<div class='fieldBox'><input type='radio' class='css-checkbox css-label1 bc_info card_side' value='choice" + j + "' name='choice" + j + "'/><label htmlFor='choice" + j + "' class='css-label radGroup1' >" + j + ") &nbsp; " + `<span dir lang = ${this.props.auth.user.prefLanguage}>` + tempQuestionsHolder.questions[z].choices[j - 1] + `</span>` + "</label></div>"
                                }
                            }
                        }
                        quesText = quesText.replace('[[[LP_ANSWERS]]]', choicesText);
                    }

                    viewQuestionDetailsPage =
                        <div id="viewDetailsPage">
                            <Breadcrumb
                                breadcrumbsData={
                                    [
                                        {
                                            name: 'Manage Activities', action: () => {
                                                this.hideViewDetailsPageAndCloseViewActivity();
                                            }
                                        },
                                        {
                                            name: 'View Activities', action: () => {
                                                this.hideViewDetailsPage();
                                            }
                                        },
                                        {
                                            name: 'View Details', action: () => {

                                            }
                                        }
                                    ]
                                }
                            />
                            <div className="container-fluid grey-background">
                                <div className="container-fluid questionMainBlock">
                                    <div className="quesAndIcon">
                                        <div className="col-md-6 col-sm-6 col-xs-12">
                                            <h3>{"Question " + (z + 1)}</h3>
                                        </div>
                                        <div className="col-md-6  col-sm-6 col-xs-6 hidden-xs text-right">
                                            <div className="actionBlockDiv">
                                                {
                                                    (!isMobile && !this.state.isFromCourse && !this.state.isFromAssignmentToAssignActivity && (
                                                        (this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName === "LearnerPal") ||
                                                        (this.props.manageActivities.viewActivityData.ownerName === this.props.auth.user.userName) ||
                                                        (this.props.manageActivities.viewActivityData.originalOwner === this.props.auth.user.userName) &&
                                                        (this.props.manageActivities.viewActivityData.activityState && this.props.manageActivities.viewActivityData.activityState.toLowerCase() !== 'published')
                                                    )) &&
                                                    <ActionIcons iconType={ActionIconTypes.Edit} action={function () {


                                                        let params = {
                                                            activityID: context.state.selectedRow.id,
                                                        };
                                                        let pathTemplate = '/activity/{activityID}';
                                                        let method = 'GET';
                                                        let additionalParams = {};
                                                        let body = {};
                                                        context.showIndicator();
                                                        apiClient.invokeApi(params, pathTemplate, method, additionalParams, body).then(function (result) {
                                                            context.hideIndicator();

                                                            if ((result.data.isEditable) || (!result.data.isEditable && context.props.auth.user.userType.toLowerCase() === 'admin' && context.props.auth.user.schoolName === 'LearnerPal')) {
                                                                context.selectEditActivity(context.state.selectedRow);
                                                            }
                                                            else {
                                                                context.setState({ showConfirmationMessage: true });
                                                            }
                                                        }).catch(function (result) {

                                                        });
                                                    }} />
                                                }
                                                {(this.state.isFromCourse && !this.state.isFromAssignmentToAssignActivity && <ActionIcons iconType={ActionIconTypes.Add} action={function () {
                                                    context.addToUnit(context.state.selectedRow);
                                                    context.closeViewActivity();
                                                }} />)}

                                                {(!this.state.isFromCourse && this.state.isFromAssignmentToAssignActivity && <ActionIcons iconType={ActionIconTypes.Assign} action={function () {
                                                    // context.addToUnit(context.state.selectedRow);
                                                    context.closeViewActivity();
                                                }} />)}
                                                {(!this.state.isFromCourse && !this.state.isFromAssignmentToAssignActivity && <ActionIcons iconType={ActionIconTypes.Print} action={function () {
                                                    context.printContent({ id: context.state.selectedRow.id });
                                                }} />)}
                                                {(!this.state.isFromCourse && !this.state.isFromAssignmentToAssignActivity &&
                                                    <ActionIcons iconType={ActionIconTypes.Duplicate} action={function () {
                                                        context.setState({
                                                            showDuplicateDialog: true,
                                                            duplicateRecord: { ...context.state.selectedRow, id: context.state.selectedRow.id }, duplicateDialogInput: ''
                                                        });
                                                    }} />)}
                                                {!this.state.isFromCourse && !this.state.isFromAssignmentToAssignActivity && (this.props.manageActivities.viewActivityData.ownerName === this.props.auth.user.userName
                                                    || (this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName === "LearnerPal")
                                                    || this.props.manageActivities.viewActivityData.originalOwner === this.props.auth.user.userName) &&
                                                    <ActionIcons iconType={ActionIconTypes.Delete} action={function () {
                                                        let params = {
                                                            activityID: context.state.selectedRow.id,
                                                        };
                                                        let pathTemplate = '/activity/{activityID}';
                                                        let method = 'GET';
                                                        let additionalParams = {};
                                                        let body = {};
                                                        context.showIndicator();
                                                        apiClient.invokeApi(params, pathTemplate, method, additionalParams, body).then(function (result) {
                                                            context.hideIndicator();

                                                            if ((result.data.isEditable) || (!result.data.isEditable && context.props.auth.user.userType.toLowerCase() === 'admin' && context.props.auth.user.schoolName === 'LearnerPal')) {
                                                                context.setState({
                                                                    showDeleteDialog: true,
                                                                    record: { ...context.state.selectedRow, id: context.state.selectedRow.id }
                                                                });
                                                            }
                                                            else {
                                                                context.setState({ showConfirmationMessage: true });
                                                            }
                                                        }).catch(function (result) {

                                                        });
                                                    }} />
                                                }
                                            </div>
                                        </div>
                                        <div dir lang={this.props.auth.user.prefLanguage} className="col-md-12">
                                            <p>{htmlToReactParser.parse(quesText)}</p>
                                        </div>
                                        {showChoices &&
                                            <div dir lang={this.props.auth.user.prefLanguage} className="col-md-12">
                                                {htmlToReactParser.parse(choicesText)}
                                            </div>
                                        }
                                        {
                                            tempQuestionsHolder.questions[z].explanation &&
                                            <div className="col-md-12">
                                                <h3>Explanation: </h3>
                                                <span dir lang={this.props.auth.user.prefLanguage}><p> {htmlToReactParser.parse(tempQuestionsHolder.questions[z].explanation.replace('autoplay', ''))}</p></span>
                                            </div>
                                        }
                                        <div className="col-md-12">
                                            <h3>Hint</h3>
                                            <span dir lang={this.props.auth.user.prefLanguage}><p>{htmlToReactParser.parse(tempQuestionsHolder.questions[z].hint)}</p></span>
                                        </div>
                                        <div className="row">
                                            <div className=" lastQuesRedirect">
                                                <div className="col-md-4 col-sm-4 col-xs-4">
                                                    {this.state.indexNumberForQuestions > 0 &&
                                                        <a href="javascript:;" className="prevQues"
                                                            onClick={this.setIndexNumberForQuestionsDecrement}>
                                                            <span className="caret" />Previous Question</a>
                                                    }
                                                </div>
                                                <div className="col-md-4 col-sm-4 col-xs-4 text-center ">
                                                    <ul className="hidden-xs">
                                                        <li className="num ">{this.state.indexNumberForQuestions + 1}</li>
                                                        <li> |</li>
                                                        <li> {tempQuestionsHolder.questions.length}</li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-4 col-sm-4 col-xs-4">
                                                    {tempQuestionsHolder.questions.length - this.state.indexNumberForQuestions > 1 &&
                                                        <a href="javascript:;" className="nextQues"
                                                            onClick={this.setIndexNumberForQuestionsIncrement}> Next Question <span
                                                                className="caret" />
                                                        </a>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>;
                }
            }
        }

        let hideHeading = this.state.viewQuestionDetailsPage;

        let styleForMobileVIew = {};
        if (isMobile) {
            styleForMobileVIew = { "width": "100%", "height": "calc(100% - 100px)" };
            if (this.viewQuestionPanel || this.viewURLPanel || this.viewPDFPanel || this.viewVIDEOPanel || this.viewFlashcardsPanel || this.state.viewQuestionDetailsPage) {
                hideHeading = true;
            }
        }
        let backButton = null;
        let createOrEdit = 'Create';
        let isCreateButton = true;
        if (this.props.manageCourses.isEditCourse) {
            createOrEdit = 'Edit';
        }
        if (this.state.isFromCourse) {
            backButton = {
                label: '< Back To Course', instructions: 'Choose activities to add to the course', action: () => {
                    browserHistory.push('/courses/create-course');
                }
            };
            isCreateButton = false;
        }
        if (this.state.isFromAssignmentToAssignActivity) {
            backButton = {
                label: '< Back To Assignment', instructions: 'Choose an activity to assign', action: () => {
                    browserHistory.push('/assignments');
                }
            };
            isCreateButton = false;
        }
        return (
            <div>
                {!this.state.isLandingPage && this.state.showLearningFields &&
                    <Heading
                        breadcrumbsData={
                            [
                                {
                                    name: 'Activities', action: () => {
                                        this.setState({ showLearningFields: false, isLandingPage: true });
                                    }
                                },
                                {
                                    name: 'Create Activity', action: () => {
                                    }
                                }
                            ]
                        }
                    />
                }
                {this.state.isLandingPage &&
                    <div>
                        {
                            !this.state.isActivityTypeLandingPage &&
                            <Heading
                                heading="Activities"
                                showFilterButtons="false"
                            />
                        }
                        {
                            this.state.isActivityTypeLandingPage &&
                            <Heading
                                showFilterButtons="false"
                                breadcrumbsData={
                                    [
                                        {
                                            name: 'Activities', action: () => {
                                                this.setState({ isActivityTypeLandingPage: false });
                                            }
                                        },
                                        {
                                            name: 'Create Activity', action: () => {
                                            }
                                        }
                                    ]
                                }
                            />
                        }
                        <div className="col-md-2 hidden-xs hidden-sm"></div>
                        <div className="mainFormBox col-md-8 col-sm-12 col-xs-12" style={{ margin: '15px auto 80px' }}>
                            {
                                !this.state.isActivityTypeLandingPage &&
                                <div className="row">
                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                        <h1 style={{ textAlign: 'center', marginTop: '-5px' }}>
                                            <strong style={{ fontSize: 'x-large' }}>Activity Management</strong>
                                        </h1>
                                        <div style={{ textAlign: 'center', fontStyle: 'italic' }}>
                                            <strong style={{ fontSize: '14px' }}>(Online activities to help your students study, prep and learn)</strong>
                                        </div>
                                    </div>
                                </div>

                            }
                            {
                                this.state.isActivityTypeLandingPage &&
                                <div className="row">
                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                        <h1 style={{ textAlign: 'center', marginTop: '-5px' }}>
                                            <strong style={{ fontSize: 'x-large' }}>Select the activity you would like to create</strong>
                                        </h1>
                                    </div>
                                </div>
                            }
                            <div className="row">
                            </div>
                            {
                                !this.state.isActivityTypeLandingPage &&
                                <div>
                                    <div className="row">
                                        <div className="col-md-1 hidden-sm hidden-xs"></div>
                                        <div className="col-md-10 col-sm-12 col-xs-12">
                                            <div className="mainFormBox" style={{ minHeight: '90px', cursor: 'pointer' }} onClick={() => {
                                                this.setState({ isLandingPage: false });
                                            }}>
                                                <div className="" style={{
                                                    width: '0px',
                                                    position: 'relative',
                                                    marginRight: '75px',
                                                    marginTop: '-7px',
                                                    float: 'left'
                                                }}>
                                                    <img src={icon4} style={{ height: '67px' }} />
                                                </div>
                                                <strong style={{ fontSize: 'larger' }}>Manage Activities</strong>
                                                <div>View, edit, duplicate, print and assign LearnerPal stock activities or your custom activities</div>
                                            </div>
                                        </div>
                                        <div className="col-md-1 hidden-sm hidden-xs"></div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-1 hidden-sm hidden-xs"></div>
                                        <div className="col-md-10 col-sm-12 col-xs-12">
                                            <div className="mainFormBox" style={{ minHeight: '90px', cursor: 'pointer' }} onClick={() => {
                                                this.setState({ isActivityTypeLandingPage: true });
                                            }}>
                                                <div style={{ float: 'left', marginTop: '-7px', marginRight: "12px" }}><span style={{ color: '#55a4f0' }} className="custom-color-icon" ><MaterialIcon icon='note_add' size={60} /></span></div>
                                                <strong style={{ fontSize: 'larger' }}>Create a New Activity</strong>
                                                <div>Create a new question activity, flashcard activity, PDF activity, or URL activity (websites, videos etc.)</div>
                                            </div>
                                        </div>
                                        <div className="col-md-1 hidden-sm hidden-xs"></div>
                                    </div>
                                </div>
                            }
                            {
                                this.state.isActivityTypeLandingPage &&
                                <div>
                                    <div className="row">
                                        <div className="col-md-1 hidden-sm hidden-xs"></div>
                                        <div className="col-md-10 col-sm-12 col-xs-12">
                                            <div className="mainFormBox" style={{ minHeight: '105px', cursor: 'pointer' }} onClick={() => {
                                                this.dispatchCreateQuestionFields();
                                                browserHistory.push('/activities/question/new');
                                            }}>
                                                <div className="" style={{
                                                    width: '0px',
                                                    position: 'relative',
                                                    marginRight: '80px',
                                                    float: 'left'
                                                }}>
                                                    <img src={activity_question} style={{ height: '65px' }} />
                                                </div>
                                                <strong style={{ fontSize: 'medium' }}>Create a New Question Activity</strong>
                                                <div>Create a question activity using LearnerPal stock questions or your own questions that you have created.
                                            Question activities can be used as a practice activity (instant feedback) or an assessment (no feedback)</div>
                                            </div>
                                        </div>
                                        <div className="col-md-1 hidden-sm hidden-xs"></div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-1 hidden-sm hidden-xs"></div>
                                        <div className="col-md-10 col-sm-12 col-xs-12">
                                            <div className="mainFormBox" style={{ minHeight: '105px', cursor: 'pointer' }} onClick={() => {
                                                this.dispatchCreateFlashCardFields();
                                                browserHistory.push('/activities/flashcard/new');
                                            }}>
                                                <div className="" style={{
                                                    width: '0px',
                                                    position: 'relative',
                                                    marginRight: '80px',
                                                    float: 'left'
                                                }}>
                                                    <img src={activity_flashcard} style={{ height: '65px' }} />
                                                </div>
                                                <strong style={{ fontSize: 'medium' }}>Create a New Flashcard Activity</strong>
                                                <div>Create a flashcard activity from LearnerPal&apos;s glossary.
                                                Flashcard activities are interactive and allow students to
                                            practice and study important vocabulary items.</div>
                                            </div>
                                        </div>
                                        <div className="col-md-1 hidden-sm hidden-xs"></div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-1 hidden-sm hidden-xs"></div>
                                        <div className="col-md-10 col-sm-12 col-xs-12">
                                            <div className="mainFormBox" style={{ minHeight: '105px', cursor: 'pointer' }} onClick={() => {
                                                this.setState({ isLandingPage: false, showCreateEditDialog: true, showLearningFields: true, showURL: false, showFile: true, showVideo: false, activityType: 'learningPDF' });
                                            }}>
                                                <div className="" style={{
                                                    width: '0px',
                                                    position: 'relative',
                                                    marginRight: '80px',
                                                    float: 'left'
                                                }}>
                                                    <img src={activity_pdf} style={{ height: '65px' }} />
                                                </div>
                                                <strong style={{ fontSize: 'medium' }}>Create a New PDF Document Activity</strong>
                                                <div>Upload and create a PDF activity that can be used
                                            with your students for instructional purposes.</div>
                                            </div>
                                            <div className="mainFormBox" style={{ minHeight: '105px', cursor: 'pointer' }} onClick={() => {
                                                this.setState({ isLandingPage: false, showCreateEditDialog: true, showLearningFields: true, showURL: false, showFile: false, showVideo: true, activityType: 'learningVideo' });
                                            }}>
                                                <div className="" style={{
                                                    width: '0px',
                                                    position: 'relative',
                                                    marginRight: '80px',
                                                    float: 'left'
                                                }}>
                                                    <img src={activity_viedo} style={{ height: '65px' }} />
                                                </div>
                                                <strong style={{ fontSize: 'medium' }}>Create a New Video Activity</strong>
                                                <div>Upload and create a Video Activity</div>
                                            </div>
                                        </div>
                                        <div className="col-md-1 hidden-sm hidden-xs"></div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-1 hidden-sm hidden-xs"></div>
                                        <div className="col-md-10 col-sm-12 col-xs-12">
                                            <div className="mainFormBox" style={{ minHeight: '105px', cursor: 'pointer' }} onClick={() => {
                                                this.setState({ isLandingPage: false, showCreateEditDialog: true, showLearningFields: true, showURL: true, showFile: false, showVideo: false, activityType: 'learningURL' });
                                            }}>
                                                <div className="" style={{
                                                    width: '0px',
                                                    position: 'relative',
                                                    marginRight: '80px',
                                                    float: 'left'
                                                }}>
                                                    <img src={activity_url} style={{ height: '65px' }} />
                                                </div>
                                                <strong style={{ fontSize: 'medium' }}>Create a New URL Activity</strong>
                                                <div>Upload and create a URL activity. A URL link or a website can be used
                                            with your students fot instructional purposes</div>
                                            </div>
                                        </div>
                                        <div className="col-md-1 hidden-sm hidden-xs"></div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="col-md-2 hidden-xs hidden-sm"></div>
                    </div>
                }
                {!this.state.isLandingPage && !this.state.showLearningFields &&
                    <div>
                        <Heading
                            location="activities"
                            actionName={RECEIVED_ACTIVITY_RESULTS}
                            isHideHeading={hideHeading}
                            allButtons={[
                                <button type="button" className="btn btn-primary crtBtn" onClick={this.createActivity}
                                    key="CreateButton"><span> </span> Create New</button>
                            ]}
                            isCreateButton={isCreateButton}
                            isShowButton={true}
                            isSearch={true}
                            breadcrumbsData={
                                [
                                    {
                                        name: 'Activities', action: () => {
                                            this.setState({ isActivityTypeLandingPage: false, isLandingPage: true });
                                        }
                                    },
                                    {
                                        name: 'Manage Activity', action: () => {
                                        }
                                    }
                                ]
                            }
                            backButton={backButton}
                            screenNameForElasticSearch={ElasticSearchTypes.Activity}
                            allColumnsForElasticSearch={ElasticSearchColumns.Activity}
                        />
                        <div id="tableFiltersAndSideView" >
                            <div className={classnames("container-fluid noPad listMainBoxWrapper", { "removeDisplay": hideHeading })}>
                                <Filter
                                    location="activities"
                                    actionName={RECEIVED_ACTIVITY_RESULTS}
                                    viewActivityCssClass={this.filterComponentCssClassName}
                                    closeViewActivity={this.closeViewActivity}
                                    clearFilters={'manageActivities.clearFilters'}
                                    screenNameForElasticSearch={ElasticSearchTypes.Activity}
                                    allColumnsForElasticSearch={ElasticSearchColumns.Activity}
                                    filterColumns={[
                                        // {name: 'subjects', type: 'array', label: 'Subject', dependency: 'activityType', dependent:true},
                                        // {name: 'activityType', type: 'text', label: 'Activity Type', sort: 'desc', dependent:true},
                                        // {name: 'ownerName', type: 'text', label: 'Owner Name', dependency:'subjects'}
                                        { name: 'subjects', type: 'array', label: 'Subject' },
                                        { name: 'activityType', type: 'text', label: 'Activity Type', sort: 'desc' },
                                        { name: 'ownerName', type: 'text', label: 'Owner Name' }
                                    ]} />
                                <div className={this.tableCssClassName}>
                                    <div className="listToBesslide NiceScroll  ">
                                        <div className="blockForlist ">
                                            {!isMobile && <MaintenanceTable
                                                location="activities"
                                                actionName={RECEIVED_ACTIVITY_RESULTS}
                                                screenNameForElasticSearch={ElasticSearchTypes.Activity}
                                                allColumnsForElasticSearch={ElasticSearchColumns.Activity}
                                                options={maintenanceTableData}
                                                sortAction={{
                                                    action: RECEIVED_ACTIVITY_RESULTS,
                                                    attributes: {
                                                        skipAlterForAllRecords: true
                                                    },
                                                    dataPath: 'data'
                                                }}
                                            />}
                                            {isMobile && <ActivityMaintenanceTableMobile items={this.props.manageActivities.results}
                                                emptyMessage={maintenanceTableData.emptyMessage}
                                                columns={maintenanceTableData.columns}
                                                viewActivityMobile={this.viewActivity}
                                            />}
                                            <ReactScrollPagination
                                                fetchFunc={this.getNextPage}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={this.viewActivityCssClassName} id='sidePanelContainer' style={styleForMobileVIew}>
                                {sideViewPanel}
                            </div>
                        </div>
                    </div>
                }

                {this.state.showAlert &&
                    <div className="row">
                        <div className="col-md-3 col-xs-3" />
                        <div className="col-md-6 col-xs-3">
                            <Alert bsStyle={this.state.alertStyle}
                                onDismiss={this.handleAlertDismiss}>
                                {this.state.alertText}
                            </Alert>
                        </div>
                        <div className="col-md-3 col-xs-3" />
                    </div>
                }

                <div>
                    {viewQuestionDetailsPage}
                </div>
                {this.state.showDeleteDialog && this.getDeleteDialog()}
                {this.state.showDuplicateDialog && this.getDuplicateDialog()}
                {this.state.showCreateEditDialog && this.getCreateEditDialog()}

                {this.state.showSaveAndAssignDialog && this.getSaveAndAssignDialog()}

                <ProgressIndicator show={this.state.loading} />
                {this.state.showActivityAddingConfirmationDialog && this.getActivityAddingConfirmationDialogForCourses()}
                {this.state.showConfirmationMessage && this.getConfirmationMessage()}
            </div>
        );
    }

    searchBarChangeHandler() {
        const { dispatch } = this.props;
        if (document.getElementById("searchBar").value) {
            let opts = {
                "match": {
                    "keywords": document.getElementById("searchBar").value
                }
            };
            ElasticSearchActions.query(dispatch, 'activity', opts);
        } else {
            ElasticSearchActions.query(dispatch);
        }
    }

    getDeleteDialog() {
        let body = <div id="deleteDialogContent">
            <p>
                Are you sure you want to delete the activity? All reporting data will be lost.
            </p>
            {this.state.deleteError ? <Alert bsStyle="danger">
                {this.state.deleteErrorMessage}
            </Alert> : ''}
        </div>;
        return <Modal
            show={this.state.showDeleteDialog}
            backdrop="static"
            onHide={() => {
                this.setState({ showDeleteDialog: false, record: null, deleteError: false });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Delete Activity</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    this.setState({ showDeleteDialog: false, record: null, deleteError: false });
                }}>Cancel</Button>
                <Button onClick={() => {
                    let context = this;
                    this.deleteActivity(this.state.record).then(result => {
                        context.clearFilters();
                        context.setState({ showDeleteDialog: false, record: null, deleteError: false });
                        context.hideViewDetailsPageAndCloseViewActivity();
                    }).catch(error => {
                        context.setState({ deleteError: true, deleteErrorMessage: error.statusMessage });
                    });
                }} bsStyle='primary'>Delete</Button>
            </Modal.Footer>
        </Modal>;
    }

    deleteActivity(selectedRecord) {
        let rejectDuplicate = (reject, error) => reject({
            status: 400,
            statusMessage: error.message
        });
        let currentRecord = selectedRecord;
        return new Promise((resolve, reject) => {
            this.showIndicator();
            let pathTemplate = '/activity/' + currentRecord.id + '/ownername/' + this.props.auth.user.userName;
            let method = 'DELETE';
            let that = this;
            apiClient.invokeApi(null, pathTemplate, method, {}, {}).then(function (result) {
                ClevertapReact.event("Delete Activity", { 'userName': that.props.auth.user.userName });
                that.setState({ showAlert: true, alertText: result.data, alertStyle: "success" });
                setTimeout(() => {
                    that.setState({ ...that.state, showAlert: false });
                }, 5000);
                that.hideIndicator();
                resolve({
                    status: result.status,
                    statusMessage: result.data
                });
                setTimeout(function () {
                    that.fetchAllActivitiesCommonFunction();
                }, 1000);
            }).catch(function (result) {
                that.setState({ showAlert: true, alertText: result.message, alertStyle: "danger" });
                setTimeout(() => {
                    that.setState({ ...that.state, showAlert: false });
                }, 5000);
                that.hideIndicator();
                rejectDuplicate(reject, { message: 'Internal error.' });
            });
        });
    }

    getDuplicateDialog(data = {}) {
        let validationState = this.getValidationState();
        let body = <div id="deleteDialogContent">
            <p>
                Please enter new activity name below:
            </p>
            <TextFieldGroup
                error={validationState ? validationState : this.state.errors.duplicateDialogInput}
                field="duplicateDialogInput"
                value={this.state.duplicateDialogInput}
                placeholder="Enter Activity Name"
                onChange={this.onChange}
                onKeyUp={this.checkEnterKeyPress}
            />
        </div>;
        return <Modal
            show={this.state.showDuplicateDialog}
            backdrop="static"
            onHide={() => {
                this.setState({
                    showDuplicateDialog: false,
                    duplicateRecord: null,
                    duplicateError: false,
                    duplicateErrorMessage: ''
                });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Duplicate Activity</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    this.setState({
                        showDuplicateDialog: false,
                        duplicateRecord: null,
                        duplicateError: false,
                        duplicateErrorMessage: ''
                    });
                }}>Cancel</Button>
                <Button disabled={!this.state.duplicateDialogInput || this.state.duplicateDialogInput.trim() === ""}
                    onClick={() => {
                        let context = this;
                        this.duplicateActivity(this.state.duplicateRecord).then(result => {
                            context.setState({
                                showDuplicateDialog: false,
                                duplicateRecord: null,
                                duplicateError: false,
                                duplicateDialogInput: '',
                                duplicateErrorMessage: ''
                            });
                            this.hideViewDetailsPageAndCloseViewActivity();
                            let that = this;
                            setTimeout(function () {
                                that.fetchAllActivitiesCommonFunction();
                                that.clearFilters();
                            }, 1000);
                        }).catch(error => {
                            context.hideIndicator();
                            context.setState({ duplicateError: true, duplicateErrorMessage: error.statusMessage });
                        });
                    }} bsStyle='primary'>Duplicate</Button>
            </Modal.Footer>
        </Modal>;
    }

    onCourseChange(evt) {
        let value = evt.target.value;
        let that = this;
        this.setState({ isMandotary: true }, function () {
            that.getCourse(value);
        });
    }

    getCourse(id) {
        let context = this;

        context.showIndicator();
        if (id) {
            let params = {
                courseID: id,
            };
            let pathTemplate = '/course/{courseID}';
            let method = 'GET';
            let additionalParams = {};
            let body = {};

            apiClient.invokeApi(params, pathTemplate, method, additionalParams, body).then(function (result) {

                context.setState({
                    ...context.state,
                    selectedCourse: result.data,
                    loading: false
                });
            }).catch(function (error) {
                context.hideIndicator();
            });
        }
    }

    onUnitChange(evt) {

        if (evt.target.value) {
            let selectedUnit = this.state.selectedCourse.units.filter(unit => unit.unitID === evt.target.value)[0];
            this.setState({ ...this.state, selectedUnit: selectedUnit });
        }
    }

    onAssessmentChange(evt) {

        if (evt.target.value) {
            this.setState({ ...this.state, selectedAssessment: evt.target.value });
        }
    }

    getSaveAndAssignDialog() {
        let courses = this.props.manageCourses.results;
        let courseList = courses.map(function (c) {
            return { name: c.courseName, id: c.id };
        });
        let selectedCourse = this.state.selectedCourse ? this.state.selectedCourse.courseID : '';
        let unitList = this.state.selectedCourse && this.state.selectedCourse.units ? this.state.selectedCourse.units.map(function (c) {
            return { name: c.unitName, id: c.unitID };
        }) : [];
        let selectedUnit = this.state.selectedUnit ? this.state.selectedUnit.unitID : '';
        let assessmentList = [{ name: 'Assessment', value: 'Assessment' }, { name: 'Practice', value: 'Practice' }];
        let selectedAssessment = this.state.selectedAssessment;

        let validationState = this.getValidationState();
        let body = <div id="saveAndAssignDialogContent">
            {this.state.courseAssignError &&
                <p style={{ color: 'red' }}>Please select a course and unit. For question activity type, Assessment is
                required.</p>}

            {courseList.length === 0 &&
                <div>
                    Please create a new course or duplicate an existing course to be able to add activities to the course from this screen.
            </div>
            }
            {courseList.length > 0 &&
                <div className="mainFormBox">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <SelectFieldGroup
                                error={this.state.errors.selectedCourse}
                                field="selectedCourse"
                                value={selectedCourse}
                                options={courseList}
                                label="Select Course in the box:"
                                placeholder="Select Course to Assign"
                                onChange={this.onCourseChange}
                            />
                        </div>
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <SelectFieldGroup
                                error={this.state.errors.selectedUnit}
                                field="selectedUnit"
                                value={selectedUnit}
                                options={unitList}
                                label="Select Unit:"
                                placeholder="Select Unit"
                                onChange={this.onUnitChange}
                            />
                        </div>
                    </div>
                </div>
            }
        </div>;
        return <Modal
            show={this.state.showSaveAndAssignDialog}
            onHide={() => {
                this.setState({
                    showSaveAndAssignDialog: false
                });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                {this.state.SaveAndAssign && <Modal.Title id="delete-activity-modal">Do you want to add this activity to a course? (optional)</Modal.Title>}
                {!this.state.SaveAndAssign && <Modal.Title id="delete-activity-modal">Activity saved, you can now access this activity under Manage Activities</Modal.Title>}
            </Modal.Header>
            {this.state.showAddToCourse &&
                <Modal.Body>
                    {body}
                </Modal.Body>
            }
            {!this.state.showAddToCourse && !this.state.SaveAndAssign &&
                <Modal.Body>
                    <p>Do you want to add this activity to a course? (optional)</p>
                </Modal.Body>
            }
            <Modal.Footer>
                {!this.state.showAddToCourse && <Button className="rightMarginForModelButtons" onClick={() => {
                    if (this.state.SaveAndAssign) {
                        browserHistory.push({
                            pathname: '/assignments/assign',
                            state: { currentActivityObj: this.state.payloadForAssignCourse.activity, currentActivity: this.state.payloadForAssignCourse.activity.activityID, view: 'activity' }
                        });
                    }
                    else {
                        let context = this;
                        this.setState({ showAlert: true, alertText: 'Activity has been created successfully', alertStyle: 'success', 'showAddToCourse': true, 'showSaveAndAssignDialog': false, SaveAndAssign: false, isMandotary: false, selectedCourse: '', selectedUnit: '' });
                        setTimeout(() => {
                            context.setState({ ...context.state, showAlert: false });
                        }, 5000);
                    }
                }}>No</Button>}
                {this.state.showAddToCourse && <Button className="rightMarginForModelButtons" onClick={() => {
                    // this.setState({'showAddToCourse': true, 'showSaveAndAssignDialog':false,SaveAndAssign:false, isMandotary:false,selectedCourse:'',selectedUnit:''});
                    if (this.state.SaveAndAssign) {
                        browserHistory.push({
                            pathname: '/assignments/assign',
                            state: { currentActivityObj: this.state.payloadForAssignCourse.activity, currentActivity: this.state.payloadForAssignCourse.activity.activityID, view: 'activity' }
                        });
                    }
                    else {
                        let context = this;
                        this.setState({ showAlert: true, alertText: 'Activity has been created successfully', alertStyle: 'success', 'showAddToCourse': true, 'showSaveAndAssignDialog': false, SaveAndAssign: false, isMandotary: false, selectedCourse: '', selectedUnit: '' });
                        setTimeout(() => {
                            context.setState({ ...context.state, showAlert: false });
                        }, 5000);
                    }
                }}>Cancel</Button>}
                {!this.state.showAddToCourse &&
                    <Button className="rightMarginForModelButtons" onClick={() => {
                        this.setState({ 'showAddToCourse': true });
                    }} bsStyle='primary'>Yes</Button>
                }
                {this.state.showAddToCourse &&
                    <Button className="rightMarginForModelButtons" disabled={!this.state.payloadForAssignCourse}
                        onClick={() => {
                            let context = this;
                            let payloadForAssignCourse = context.state.payloadForAssignCourse;

                            if (!context.state.isMandotary) {
                                if (context.state.SaveAndAssign) {
                                    browserHistory.push({
                                        pathname: '/assignments/assign',
                                        state: { currentActivityObj: this.state.payloadForAssignCourse.activity, currentActivity: this.state.payloadForAssignCourse.activity.activityID, view: 'activity' }
                                    });
                                }
                                else {
                                    this.setState({ showAlert: true, alertText: 'Activity has been created successfully', alertStyle: 'success', 'showAddToCourse': false, 'showSaveAndAssignDialog': false, SaveAndAssign: false, isMandotary: false, selectedCourse: '', selectedUnit: '' });
                                    setTimeout(() => {
                                        context.setState({ ...context.state, showAlert: false });
                                    }, 5000);
                                }
                            }
                            else {
                                if (!context.state.selectedCourse ||
                                    !context.state.selectedUnit ||
                                    ((payloadForAssignCourse.activity.activityType.toLowerCase() === 'question' ||
                                        payloadForAssignCourse.activity.activityType.toLowerCase() === 'questions') && !context.state.selectedAssessment)) {
                                    context.setState({ courseAssignError: true });
                                }
                                else {
                                    context.showIndicator();
                                    context.setState({ courseAssignError: false });
                                    payloadForAssignCourse.courseID = context.state.selectedCourse.courseID;
                                    payloadForAssignCourse.unitID = context.state.selectedUnit.unitID;
                                    payloadForAssignCourse.assessmentType = context.state.selectedAssessment;
                                    payloadForAssignCourse.ownerName = context.props.auth.user.userName;

                                    let pathTemplate = '/course/assignment';
                                    let method = 'PUT';
                                    let additionalParams = {};
                                    apiClient.invokeApi(null, pathTemplate, method, additionalParams, payloadForAssignCourse, '2').then(function (result) {
                                        context.hideIndicator();
                                        if (context.state.SaveAndAssign) {
                                            browserHistory.push({
                                                pathname: '/assignments/assign',
                                                state: { currentActivityObj: context.state.payloadForAssignCourse.activity, currentActivity: context.state.payloadForAssignCourse.activity.activityID, view: 'activity' }
                                            });
                                        }
                                        else {
                                            context.setState({ showAlert: true, alertText: result.data, alertStyle: "success", 'showAddToCourse': false, 'showSaveAndAssignDialog': false, SaveAndAssign: false, isMandotary: false, selectedCourse: '', selectedUnit: '' });
                                            setTimeout(() => {
                                                context.setState({ ...context.state, showAlert: false });
                                            }, 5000);
                                        }
                                    }).catch(function (result) {
                                        context.setState({ showAlert: true, alertText: result.data, alertStyle: "danger", 'showAddToCourse': true, 'showSaveAndAssignDialog': false });
                                        setTimeout(() => {
                                            context.setState({ ...context.state, showAlert: false });
                                        }, 5000);
                                        context.hideIndicator();
                                    });
                                }
                            }
                        }} bsStyle='primary'>{this.state.isMandotary && !this.state.SaveAndAssign && 'Add to Course and Exit'} {!this.state.isMandotary && !this.state.SaveAndAssign && 'Exit'} {this.state.isMandotary && this.state.SaveAndAssign && 'Add To Course And Assign'} {!this.state.isMandotary && this.state.SaveAndAssign && 'Assign'}
                    </Button>
                }
            </Modal.Footer>
        </Modal>;
    }

    clearFilters() {
        setTimeout(() => {
            let selectedFilters = $('#tableFiltersAndSideView')[0];
            selectedFilters = [...selectedFilters.querySelectorAll('input[type=checkbox]:checked')];
            for (var i = 0; i < selectedFilters.length; i++) {
                selectedFilters[i].checked = false
            }
        }, 1500);
    }

    isNotDuplicatedNameForDuplicate() {
        // let axiosInstance = axios.create({
        //     baseURL: 'https://search-learnerpal-eimkio3umgx5fnpuy7ypsrrsri.us-east-1.es.amazonaws.com/learnerpal',
        //     timeout: 60000,
        //     headers: {'Content-Type': 'application/json'},
        //     responseType: 'json',
        //     transformResponse: {function (data) {
        //         return data;
        //     }}
        // });
        // return axiosInstance({
        //     method: 'GET',
        //     url: '/' + ElasticSearchTypes.Activity+config.stage + '/' +  this.state.duplicateDialogInput.replace(/\s|\W|[#$%^&*()'.]/g, "") + "_" + this.props.auth.user.userName,
        // })
        return apiClient.invokeApi({}, '/getesdata', 'POST', {}, { id: "/" + this.state.duplicateDialogInput.replace(/\s|\W|[#$%^&*()'.]/g, "").toLocaleLowerCase() + "_" + this.props.auth.user.userName, index: 'learnerpal/', esDocumentType: ElasticSearchTypes.Activity + config.stage }, '5');

    }

    duplicateActivity(selectedRecord) {

        let context = this;
        this.showIndicator();
        let currentRecord = selectedRecord;
        return new Promise((resolve, reject) => {
            let rejectDuplicate = (reject, error) => reject({
                status: 400,
                statusMessage: error.message
            });
            if (!this.getValidationState() && this.state.duplicateDialogInput) {
                this.isNotDuplicatedNameForDuplicate().then(response => {
                    if (response.data.found) {
                        context.hideIndicator();
                        let errors = {};
                        errors.duplicateDialogInput = 'Activity with the same name already exists';
                        context.setState({ errors });
                    }
                }).catch(response => {
                    if (!response.response.data.found) {
                        if (context.props.auth.isAuthenticated && context.props.auth.user && context.props.auth.user.hasOwnProperty('userName')) {
                            let pathTemplate = '/activity/clone';
                            let method = 'POST';
                            let additionalParams = {};
                            let body = {
                                activityID: currentRecord.id,
                                activityName: context.state.duplicateDialogInput,
                                schoolName: context.props.auth.user.schoolName,
                                ownerName: context.props.auth.user.userName
                            };
                            apiClient.invokeApi(null, pathTemplate, method, additionalParams, body).then(function (result) {
                                ClevertapReact.event("Duplicate Activity", { 'userName': context.props.auth.user.userName });
                                context.setState({ showAlert: true, alertText: result.data, alertStyle: "success" });
                                setTimeout(() => {
                                    context.setState({ ...context.state, showAlert: false });
                                    context.fetchAllActivitiesCommonFunction();
                                }, 5000);
                                context.hideIndicator();
                                resolve({
                                    status: result.status,
                                    statusMessage: result.data
                                });
                            }).catch(function (result) {
                                context.setState({ showAlert: true, alertText: result.message, alertStyle: "danger" });
                                setTimeout(() => {
                                    context.setState({ ...context.state, showAlert: false });
                                }, 5000);
                                context.hideIndicator();
                                rejectDuplicate(reject, { message: 'Internal error.' });
                            });
                        }
                        else {
                            context.hideIndicator();
                            rejectDuplicate(reject, { message: 'Please provide a valid new activity name.' });
                        }
                    }
                });
            }
            else {
                context.hideIndicator();
                rejectDuplicate(reject, { message: 'New name cannot be empty and must not match any existing activity.' });
            }
        });
    }

    getValidationState() {
        var id = this.state.duplicateDialogInput.trim().toLowerCase().replace(/\s|\W|[#$%^&*()'.]/g, "") + "_" + this.props.auth.user.userName.toLowerCase();

        if (this.state.duplicateDialogInput && this.props.manageActivities.allRecords.filter(function (record) {
            return (record.id.trim().toLowerCase() === id);
        }).length > 0) {
            return 'Activity with the same name already exists';
        } else {
            return null;
        }
    }

    handleDuplicateInputChange(e) {
        this.state.duplicateDialogInput = e.target.value;
    }

    emptyState(callback) {
        this.setState({
            taggedKeywordsHtml: [], taggedKeywords: [], taggedSubjectsHtml: [], taggedSubjects: [],
            createActivityTab: 1, showDuplicateDialog: false,
            duplicateRecord: null,
            duplicateError: false,
            duplicateDialogInput: '',
            duplicateErrorMessage: '',
            activityType: "", activityName: "", externalURL: "", documentType: "", documentLink: "", keyName: "", base64: "", description: '',
            errors: {}, showLearningFields: false, fileUpload: "", fileName: "", showAssignDialog: false
        }, callback);
    }
    editActivity(selectedRecord) {

        this.isEditActivity = true;

        this.emptyState(function () {

            if (selectedRecord.activityType === 'PDF' || selectedRecord.activityType === 'URL' || selectedRecord.activityType === 'Video' || selectedRecord.activityType === 'learning') {
                if (selectedRecord.activityType === 'PDF') {
                    this.setState({ fileName: selectedRecord.fileName, activityType: 'learning', showLearningFields: true, showFile: true });
                }
                else if (selectedRecord.activityType === 'Video') {
                    this.setState({ fileName: selectedRecord.fileName, activityType: 'learning', showLearningFields: true, showVideo: true });
                }
                else if (selectedRecord.activityType === 'URL') {
                    this.setState({ externalURL: selectedRecord.documentLink, activityType: 'learning', showLearningFields: true, showURL: true });
                }
            }
            let tempTags = [];
            if (selectedRecord.tags) {
                for (let a = 0; a < selectedRecord.tags.length; a++) {
                    tempTags.push(this.getHtmlForTag(selectedRecord.tags[a]));
                }
            }
            let tempSubjects = [];
            for (let b = 0; b < selectedRecord.subjects.length; b++) {
                tempSubjects.push(this.getHtmlForTagSubjects(selectedRecord.subjects[b]));
            }

            this.setState({
                taggedKeywordsHtml: tempTags, taggedKeywords: selectedRecord.tags, showCreateEditDialog: true, activityName: selectedRecord.name,
                taggedSubjectsHtml: tempSubjects, taggedSubjects: selectedRecord.subjects, activityID: selectedRecord.id, activityState: selectedRecord.activityState,
                description: selectedRecord.description
            });
        });
    }

    printContent(record) {
        let params = {
            activityID: record.id,
        };
        let pathTemplate = '/activity/{activityID}';
        let method = 'GET';
        let additionalParams = {};
        let body = {};
        let that = this;
        apiClient.invokeApi(params, pathTemplate, method, additionalParams, body).then(function (result) {
            record = result.data;
            let count = 0;
            let printNodes = [];
            if (record.questions) {
                let directionStyle = that.props.auth.user.prefLanguage === 'ar' ? "direction:rtl;text-align:left;display:inline-block" : "";

                record.questions.forEach(question => {
                    let questionText = question.question.replace('[[[LP_ANSWERS]]]', '');
                    let questionText2 = "";
                    if (that.props.auth.user.prefLanguage === 'ar' && questionText.indexOf('<div') === 0) {
                        questionText2 = questionText.substring(0, questionText.indexOf('>'));
                        questionText2 += " style='" + directionStyle + "' ";
                        questionText2 += questionText.substring(questionText.indexOf('>'));
                    } else {
                        questionText2 = "<p style='" + directionStyle + "'> " + questionText + "</p>";
                    }

                    let choicesHtml = [];
                    let choiceCount = 0;
                    if (question.choices && question.choices.length > 0) {
                        question.choices.forEach(choice => {
                            choicesHtml.push("<div className='fieldBox' style='direction:ltr' id=" + question.activityID + choiceCount + ">" + "<span>" + (choiceCount + 1) + ". </span>" + "<input type='checkbox' className='css-checkbox css-label1 bc_info card_side'  id=" + "choice" + choiceCount + " name='choice' />" +
                                "<label className='css-label radGroup1' for='choice'" + choiceCount + "'></label><div style='" + directionStyle + " ; display : inline-block'>" + choice + "</div></div>");
                            choiceCount++;
                        });
                    }
                    printNodes.push("<div> <h3> Question " + (count + 1) + "</h3> <p>" + questionText2 + "</p> <p>" + choicesHtml.join(" ") + "</p></div><hr />");
                    count++;
                });
                let myWindow = window.open('', 'PRINT', 'height=600,width=800');
                myWindow.document.write('<html><head><title>' + record.activityName + '</title>');
                myWindow.document.write('<h1>' + record.activityName + '</h1>');
                myWindow.document.write(printNodes.join(" "));
                myWindow.document.write('</body></html>');
                myWindow.focus(); // necessary for IE >= 10*/
                setTimeout(function () {
                    myWindow.print();
                    myWindow.document.close(); // necessary for IE >= 10
                    myWindow.close();
                }, 1000);
                return false;
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    printAnswerContent(record) {
        let params = {
            activityID: record.id,
        };
        let pathTemplate = '/activity/{activityID}';
        let method = 'GET';
        let additionalParams = {};
        let body = {};
        let that = this;
        apiClient.invokeApi(params, pathTemplate, method, additionalParams, body).then(function (result) {
            record = result.data;
            let count = 0;
            let printNodes = [];
            if (record.questions) {
                let directionStyle = that.props.auth.user.prefLanguage === 'ar' ? "direction:rtl;text-align:left;display:inline-block" : "";

                record.questions.forEach(question => {
                    let questionText = question.question.replace('[[[LP_ANSWERS]]]', '');
                    let questionText2 = "";
                    if (that.props.auth.user.prefLanguage === 'ar' && questionText.indexOf('<div') === 0) {
                        questionText2 = questionText.substring(0, questionText.indexOf('>'));
                        questionText2 += " style='" + directionStyle + "' ";
                        questionText2 += questionText.substring(questionText.indexOf('>'));
                    } else {
                        questionText2 = "<p style='" + directionStyle + "'> " + questionText + "</p>";
                    }

                    let choicesHtml = [];
                    if (question.answer) {
                        choicesHtml.push("<div className='fieldBox' style='direction:ltr' id=" + question.activityID + ">" +
                            "<label className='css-label radGroup1'>" + question.answer + "</label></div>");
                    }
                    printNodes.push("<div> <h3>Question " + (count + 1) + "</h3> <p>" + questionText2 + "</p> <p><h3>Answer</h3><div style='" + directionStyle + "'> " + choicesHtml.join(" ") + "</div></p></div><hr />");
                    count++;
                });
                let myWindow = window.open('', 'PRINT', 'height=600,width=800');
                myWindow.document.write('<html><head><title>' + record.activityName + '</title>');
                myWindow.document.write('<h1>' + record.activityName + '</h1>');
                myWindow.document.write(printNodes.join(" "));
                myWindow.document.write('</body></html>');
                myWindow.focus(); // necessary for IE >= 10*/
                setTimeout(function () {
                    myWindow.print();
                    myWindow.document.close(); // necessary for IE >= 10
                    myWindow.close();
                }, 1000);
                return false;
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

function mapStateToProps(state) {
    return { ...state };
}
export default connect(mapStateToProps)(ManageActivities);
