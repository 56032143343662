/**
 * Created by ashahab on 8/23/2017.
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import Heading from "../../heading/heading";
import ElasticSearchQuery from "../../../actions/elastic-search-query";
import {
    RECEIVED_STUDENT_ASSIGNMENT_DASHBOARD_RESULTS, ElasticSearchTypes,
    ElasticSearchColumns
} from "../../../actions/types";
import apiClient from "../../../actions/api-client";
import { browserHistory } from "react-router";
import WidgetOpenAssignments from "./widgets/Widget-OpenAssignmentsTeacher";
import CreateNewAssignment from './widgets/Widget-CreateNewAssignment'
import WidgetActivityLog from './widgets/Widget-ActivityLog';
import WidgetAlert from './widgets/Widget-Alert';
import config from "../../../config";
import logo from '../../../assets/images/logo2.png'
import stemLabsLogo2 from '../../../assets/images/stem-labs-logo2.png'
import virtualLogo from '../../../assets/images/virtual-logo2.png'
import { Button, Modal, Alert } from "react-bootstrap";
import $ from "jquery";
import TextFieldGroup from "../../forms/text-field-group";
import {
    CognitoUserPool,
    AuthenticationDetails,
    CognitoUser
} from 'amazon-cognito-identity-js';
import ProgressIndicator from "../../progress-indicator/progress-indicator";
import Validator from 'validator';
import isEmpty from 'lodash/isEmpty';
import setCurrentUser from '../../../actions/login-action';
import WidgetCourseStudentView from "./widgets/Widget-CoursesStudentView";
import MaterialIcon from "react-google-material-icons";
import icon from '../../../assets/images/check_file1600.png'
import icon1 from '../../../assets/images/people_teaching_class-01.png'
import icon2 from '../../../assets/images/icon2.png'
import virtualIcon2 from '../../../assets/images/virtual-icon2.png'
import stemIcon2 from '../../../assets/images/stem-icon-2.png'
import icon4 from '../../../assets/images/Assignment-Icon.png'
import icon5 from '../../../assets/images/report-icon.png'
import icon6 from '../../../assets/images/mq.png';

class ManageDashboardTeacher extends Component {

    constructor(props) {
        super(props);
        this.getAllAssignments = this.getAllAssignments.bind(this);
        this.getAllActivityLog = this.getAllActivityLog.bind(this);
        this.getAllClasses = this.getAllClasses.bind(this);
        this.onChange = this.onChange.bind(this);
        this.getverificationCodeDialog = this.getverificationCodeDialog.bind(this);
        this.verifyUser = this.verifyUser.bind(this);
        this.getCode = this.getCode.bind(this);
        this.handleAlertDismiss = this.handleAlertDismiss.bind(this);
        this.gotIthandleAlertDismiss = this.gotIthandleAlertDismiss.bind(this);
        this.isValid = this.isValid.bind(this);
        this.getValidationState = this.getValidationState.bind(this);
        this.showProfile = this.showProfile.bind(this);

        this.state = {
            allFilters: [],
            subjects: [],
            noClasses: false,
            showWelcomeDialog: false,
            emailAddressForVerification: '',
            showVerificationCodeDialog: false,
            codeSent: false, errors: {},
            codeForVerification: '', showAlert: false, alertText: '', alertStyle: '',
            isLinked: this.props.auth.user.linkedAccounts && this.props.auth.user.linkedAccounts.gmail ? true : false
        };

    }

    showProfile() {
        if (this.props.auth.user.userType.toLowerCase() === "student") {
            browserHistory.push('/student/profile');
        } else {
            browserHistory.push('/profile');
        }
    }

    componentDidMount() {
        this.getAllAssignments();
        this.getAllActivityLog();
        if (this.props.auth.user.federatedIdentity) {
            var additionalParams = {};
            var pathTemplate = '/googleClassroom/getClasses';
            var method = 'POST';
            var additionalParams = { 'accessToken': localStorage.getItem('accessToken') };
            var body = { "userName": this.props.auth.user.userName };

            var params = {

            };
            let that = this
            let count = 0
            let classCount = 0
            apiClient.invokeApi({}, '/class/' + this.props.auth.user.userName, 'GET', {}, {}, '2').then(function (result) {

                let allClasses = result.data.allClasses;
                let occupiedPeriods = [];
                classCount = allClasses.length
                for (let p = 0; p < allClasses.length; p++) {
                    if (allClasses[p] && !allClasses[p].courseId) {
                        count++
                    }
                }

                if (count !== classCount) {
                    apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, "7")
                        .then(function (result) {
                            console.log("result " + result)
                        })
                        .catch(function (err) {
                            console.log(err)
                        });
                } else {
                    if (count === 0 && classCount === 0) {
                        apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, "7")
                            .then(function (result) {
                                console.log("result " + result)
                            })
                            .catch(function (err) {
                                console.log(err)
                            });
                    } else {
                        console.log("delete classes before syncing")
                    }

                }

            }).catch(function (result) {
                console.log(result.data)
            });

        }

        this.getAllClasses();
        if (!localStorage.getItem("dontShowAgainWelcomeNote" + this.props.auth.user.userName)) {
            this.setState({ showWelcomeDialog: true });
        }

        let a = <a style={{ margin: "0" }} title="Click here" href='javascript:void 0' onClick={this.showProfile}>profile</a>
        let userObj = JSON.parse(localStorage.getItem('userObj'));
        if (userObj.linkedAccounts && userObj.linkedAccounts.gmail) {
            this.setState({
                gotIt_showAlert: true,
                gotIt_alertStyle: 'warning',
                // gotIt_closeLabel: "Got it!",
                gotIt_alertText: <span id="spanGotIt">You can only view your past Reports and Classes, to create assignments please use your google linked account</span>,
                gotIt_alertID: "gotItAlert"
            }, () => {
                // let alertBox = document.getElementById("gotItAlert");
                // let span = document.getElementById("spanGotIt");
                // let message = document.getElementsByClassName("doNotShowMeAgain")[0];
                // let closeButton = document.getElementsByClassName("close")[0];
                // closeButton.firstElementChild.remove();
                // closeButton.firstElementChild.setAttribute("aria-hidden", true)
                // closeButton.firstElementChild.classList.remove("sr-only")
                // closeButton.firstElementChild.className = "gotItButton"

                // alertBox.firstElementChild.firstElementChild.remove()
                // alertBox.firstElementChild.firstElementChild.setAttribute("aria-hidden", true)
                // alertBox.firstElementChild.firstElementChild.classList.remove("sr-only")
                // alertBox.firstElementChild.firstElementChild.className = "gotItButton"
                // alertBox.insertBefore(alertBox.firstElementChild.nextElementSibling, alertBox.firstElementChild)

                // alertBox.insertBefore(message, closeButton)
            });

        } else {

            if (!localStorage.getItem("doesShowGotItMessage-" + this.props.auth.user.userName)) {
                this.setState({
                    gotIt_showAlert: true,
                    gotIt_alertStyle: 'warning',
                    // gotIt_closeLabel: "Got it!",
                    gotIt_alertText:  <span id="spanGotIt">Please note your google classes may take up to one minute to load</span>,
                    gotIt_alertID: "gotItAlert"
                }, () => {
                    // let alertBox = document.getElementById("gotItAlert");
                    // let span = document.getElementById("spanGotIt");
                    // let message = document.getElementsByClassName("doNotShowMeAgain")[0];
                    // let closeButton = document.getElementsByClassName("close")[0];
                    // closeButton.firstElementChild.remove();
                    // closeButton.firstElementChild.setAttribute("aria-hidden", true)
                    // closeButton.firstElementChild.classList.remove("sr-only")
                    // closeButton.firstElementChild.className = "gotItButton"

                    // alertBox.firstElementChild.firstElementChild.remove()
                    // alertBox.firstElementChild.firstElementChild.setAttribute("aria-hidden", true)
                    // alertBox.firstElementChild.firstElementChild.classList.remove("sr-only")
                    // alertBox.firstElementChild.firstElementChild.className = "gotItButton"
                    // alertBox.insertBefore(alertBox.firstElementChild.nextElementSibling, alertBox.firstElementChild)

                    // alertBox.insertBefore(message, closeButton)
                });
            }

        }

    }

    getAllClasses() {
        let that = this;
        apiClient.invokeApi({}, '/class/' + this.props.auth.user.userName, 'GET', {}, {}, '2').then(function (result) {
            that.setState({ noClasses: result.data.allClasses.length === 0 });
        }).catch(function (result) {
        });
    }

    getAllActivityLog() {
        let context = this;
        let params = {};
        let pathTemplate = '/user/audit/' + this.props.auth.user.userName;
        apiClient.invokeApi(params, pathTemplate, 'GET', {}, {}, '3').then(function (result) {
            context.setState({ activityLog: result.data.result });
        }).catch(function (error) {

        });
    }

    getAllAssignments() {
        let that = this;
        let elasticSearchQuery = new ElasticSearchQuery();
        elasticSearchQuery.query(ElasticSearchTypes.Assignment + config.stage, ElasticSearchColumns.Assignment, this.props,
            '', 50, 1, { dueDate: { order: "asc" } }, [], function (results, totalRecords) {

                that.setState({ data: results });
            }, true, 'originalOwner : ("' + this.props.auth.user.userName + '") AND assignstatus : (Open)');
    }

    getWelcomeDialog() {

        let logo2 = logo;
        if(this.props.appSettings.theme === 'stemLabs'){
            logo2 = stemLabsLogo2;
        } else if(this.props.appSettings.theme === 'virtual'){
            logo2 = virtualLogo;
        }

        let body =
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="col-md-6 col-lg-6 col-sm-6">
                    <p><strong style={{ color: '#34b3dc', fontSize: '25px' }}>1</strong> The first step is to setup your classes under the<a href="/students">classes.</a> For each class you teach, enter the period or class name and an <strong>Enrollment Code</strong> will automatically be generated</p>
                    <br />
                    <p><strong style={{ color: '#34b3dc', fontSize: '25px' }}>2</strong> The next step is to distribute the Enrollment Codes that were generated for each class to the students in that class. Instruct to enter the code during their registration</p>
                </div>
                <div style={{ borderLeft: '#d4d4d4', borderLeftWidth: '1px', borderLeftStyle: 'solid' }} className="col-md-6 col-lg-6 col-sm-6">
                    <div>
                        <span style={{ color: '#ee9400' }}><MaterialIcon icon='assignment' size={43} /></span>
                        <div style={{ display: 'inline', lineHeight: '43px', marginLeft: '15px' }}>
                            You are now ready to assign online activities and courses!
                        </div>
                    </div>
                    <br />
                    <div>
                        <span style={{ color: '#4db6ac' }}><MaterialIcon icon='content_paste' size={43} /></span>
                        <div style={{ display: 'inline', lineHeight: '43px', marginLeft: '15px' }}>
                            Assign your own activities or choose from LearnerPal’s!
                        </div>
                    </div>
                    <br />
                    <div>
                        <span style={{ color: '#37bd17' }}><MaterialIcon icon='pie_chart_outlined' size={43} /></span>
                        <div style={{ display: 'inline', lineHeight: '43px', marginLeft: '15px' }}>
                            Analyze Student progress with comprehensive reports!
                        </div>
                    </div>
                </div>
                {/*<div className="col-md-2 col-lg-2 col-sm-2"/>*/}
                {/*<div className="col-md-8 col-lg-8 col-sm-8 col-xs-12">*/}
                {/*<p><strong style={{color:'#34b3dc', fontSize:'25px'}}>3</strong> You are now ready to assign online activities and courses!</p>*/}
                {/*</div>*/}
            </div>;

        return <Modal id="welcome_modal"
            show={this.state.showWelcomeDialog}
            onHide={() => {
                this.setState({ showWelcomeDialog: false });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <div>
                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                        <div className="infoIcon" style={{
                            width: '75px',
                            position: 'relative',
                            marginRight: '100px',
                            float: 'left'
                        }}>
                            <img src={logo2} style={{ height: '70px' }} />
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-8 col-xs-9" /*"col-lg-9 col-md-9 col-sm-9 col-xs-9"*/ style={{ textAlign: 'center' }}>
                        <div style={{ textAlign: 'center', display: 'inline-block', paddingTop: '13px' }}>
                            <strong style={{ fontSize: '20px' }}>Welcome to LearnerPal</strong>
                            <strong style={{
                                fontStyle: 'italic',
                                display: 'block'
                            }}>Let&apos;s get started...</strong>
                        </div>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Modal.Footer>
                    <div style={{ marginTop: '180px' }}>

                        <input type="checkbox" id='searchtipBoxCheckBox' className="css-checkbox" />
                        <label htmlFor='searchtipBoxCheckBox' className="pay_label css-label1">Do not show again</label>

                        <div style={{ textAlign: "center" }}>
                            <span style={{ color: '#4d6573', cursor: 'pointer' }} onClick={() => { window.open("https://youtu.be/hYBphuyCQK4", '_blank') }}><MaterialIcon icon='play_circle_outline' size={55} /></span>
                            <div style={{ display: 'inline', lineHeight: '55px' }}>
                                <a href="https://youtu.be/hYBphuyCQK4" target="_blank">Take a quick tour with an overview video</a>
                            </div>
                        </div>
                        <Button id="welcomeOkButton" className="btn btn-primary crtBtn" onClick={() => {
                            this.setState({ showWelcomeDialog: false });
                            this.saveWelcomeNotInfo();
                        }}>Ok</Button>
                    </div>
                </Modal.Footer>
            </Modal.Footer>
        </Modal>;
    }

    onChange(event) {
        this.setState({ [event.target.id]: event.target.value });
    }

    getverificationCodeDialog() {
        let body =
            <div>
                <p>Please enter the code sent via email</p>
                <TextFieldGroup
                    error={this.state.errors.codeForVerification}
                    field="codeForVerification"
                    id="codeForVerification"
                    value={this.state.codeForVerification}
                    placeholder="Enter Code"
                    onChange={this.onChange}
                />
            </div>;

        return <Modal id="welcome_modal"
            show={this.state.showVerificationCodeDialog}
            onHide={() => {
                this.setState({ showVerificationCodeDialog: false });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <div>
                    <h3>
                        Email Verification
                    </h3>
                </div>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Modal.Footer>
                    <div>
                        <Button className="btn btn-primary crtBtn" onClick={() => {
                            this.verifyUser()
                        }}>Verify</Button>
                        <Button className="btn btn-primary crtBtn" onClick={() => {
                            this.getCode();
                        }}>Resend Code</Button>
                    </div>
                </Modal.Footer>
            </Modal.Footer>
        </Modal>;
    }

    getCode() {

        let that = this;
        let userPool = new CognitoUserPool({
            UserPoolId: config.userPoolId,
            ClientId: config.clientId
        });
        let cognitoUser = userPool.getCurrentUser();
        cognitoUser.getSession(function (err, session) {
            if (session) {
                cognitoUser.getAttributeVerificationCode('email',
                    {
                        onSuccess: function (result) {

                        },
                        onFailure: function (err) {
                            that.setState({ showVerificationCodeDialog: false, showAlert: true, alertText: 'Unable to send code, Please try again later', alertStyle: 'danger' });
                            setTimeout(() => {
                                that.setState({ ...that.state, showAlert: false });
                            }, 5000);
                        },
                        inputVerificationCode: null
                    });
            }
        });
    }
    getValidationState() {
        let errors = {};

        if (Validator.isEmpty(this.state.codeForVerification)) {
            errors.codeForVerification = 'This field is required';
        }
        if (this.state.codeForVerification.trim().indexOf(" ") > -1) {
            errors.codeForVerification = 'The code cannot contain spaces';
        }
        if (this.state.codeForVerification.search(/[!@#$%^&*]/) > -1) {
            errors.codeForVerification = 'Special characters are not allowed in the code';
        }

        return {
            errors,
            isValid: isEmpty(errors)
        }
    }
    isValid() {
        const { errors, isValid } = this.getValidationState();
        if (!isValid) {
            this.setState({ errors });
        }
        return isValid;
    }

    verifyUser() {
        if (this.isValid()) {

            const { dispatch } = this.props;
            let that = this;
            let userPool = new CognitoUserPool({
                UserPoolId: config.userPoolId,
                ClientId: config.clientId
            });
            let cognitoUser = userPool.getCurrentUser();
            cognitoUser.getSession(function (err, session) {
                if (session) {

                    cognitoUser.verifyAttribute('email', that.state.codeForVerification, {
                        onSuccess: function (result) {
                            that.setState({
                                showVerificationCodeDialog: false,
                                showAlert: true,
                                alertStyle: 'success',
                                alertText: 'Your email address has been verified successfully'
                            });
                            let userObj = JSON.parse(localStorage.getItem('userObj'));
                            userObj.emailVerified = true;
                            localStorage.setItem('userObj', JSON.stringify(userObj));
                            dispatch({
                                type: 'SET_CURRENT_USER',
                                user: userObj
                            });
                            setTimeout(() => {
                                that.setState({ ...that.state, showAlert: false });
                            }, 5000);
                        },
                        onFailure: function (err) {
                            let errors = {};
                            errors.codeForVerification = err.message;
                            this.setState({ errors });
                        }
                    });
                }
            });
        }
    }

    saveWelcomeNotInfo() {

        let check = $('#searchtipBoxCheckBox').prop('checked');
        if (check === true) {
            localStorage.setItem("dontShowAgainWelcomeNote" + this.props.auth.user.userName, 'true');
        }
    }
    doesShowGotItMessage() {
        localStorage.setItem("doesShowGotItMessage-" + this.props.auth.user.userName, 'true');
    }
    handleAlertDismiss() {
        this.setState({ 'showAlert': false });
    }
    gotIthandleAlertDismiss() {
        this.setState({ 'gotIt_showAlert': false });
    }
    render() {
        let context = this;
        let data = this.state.data;

        let logs = this.state.activityLog;

        
        let icon = icon2;
        if(this.props.appSettings.theme === 'stemLabs'){
            icon = stemIcon2;
        } else if(this.props.appSettings.theme === 'virtual'){
            icon = virtualIcon2;
        }

        return (
            <div>
                <span>
                    <div className="searchCrtBar">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-xs-8">
                                    <h3 style={{ fontWeight: "bold", paddingTop: '13px' }}>{"Welcome back, " + this.props.auth.user.firstName + ' ' + this.props.auth.user.lastName + '!'}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </span>
                {/*<div  className="container-fluid noPad listMainBoxWrapper">*/}
                {/*<div id="submission"  className="tab-pane fade active in">*/}
                {/*<div  className="quesAndIcon filterBlockss bordern" style={{background:'#f7f8fa'}}>*/}
                {/*<div  className="row xlxont">*/}
                {/*<div  className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center grey-background" style={{paddingBottom: '50px'}}>*/}
                {/*{this.state.noClasses &&*/}
                {/*<WidgetAlert*/}
                {/*message="Reminder, You cannot create assignments until your classes are set up <a href='/students'>Setup Here</a>"*/}
                {/*color="#fbf8e5"*/}
                {/*// border="2px solid #f8ebcf"*/}
                {/*/>*/}
                {/*}*/}
                {/*{!this.props.auth.user.emailVerified &&*/}
                {/*<WidgetAlert*/}
                {/*message="Reminder, your email address is not verified. You will not be able to reset your password if lost or forgotten."*/}
                {/*color="#fbf8e5"*/}
                {/*extraLink = {<a href='#' onClick={()=>{this.setState({showVerificationCodeDialog:true})}} >Verify Here</a>}*/}
                {/*// border="2px solid #f8ebcf"*/}
                {/*/>*/}
                {/*}*/}

                {/*<div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">*/}
                {/*<CreateNewAssignment*/}
                {/*headinText="Create New Assignment"*/}
                {/*/>*/}
                {/*<br />*/}
                {/*{logs && <WidgetActivityLog*/}
                {/*data = {logs}*/}
                {/*/>}*/}
                {/*</div>*/}

                {/*<div className="col-lg-5 col-md-5 col-sm-6 col-xs-12">*/}
                {/*<WidgetOpenAssignments*/}
                {/*headinText="View Open Assignments"*/}
                {/*data = {data}*/}
                {/*onClickViewAll={function () {*/}
                {/*browserHistory.push('/assignments')*/}
                {/*}}*/}
                {/*onFilterChange = {function (evt) {*/}
                {/**/}
                {/*let allData = context.state.data;*/}
                {/*if (evt.target.value === 'dueDate')*/}
                {/*{*/}
                {/*allData.sort(function (a,b) {*/}
                {/*return (new Date((parseFloat(a.dueDate)) - new Date((parseFloat(b.dueDate)))))*/}
                {/*});*/}
                {/*}*/}
                {/*else*/}
                {/*{*/}
                {/*allData.sort(function (a,b) {*/}
                {/*return (new Date((parseFloat(b.createdAt)) - new Date((parseFloat(a.createdAt)))))*/}
                {/*});*/}
                {/*}*/}
                {/*context.setState({data:allData});*/}
                {/*}}*/}
                {/*/>*/}
                {/*</div>*/}
                {/*<div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">*/}
                {/*<WidgetCourseStudentView/>*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*</div>*/}
                <div className="col-md-2 hidden-xs hidden-sm">&nbsp;</div>
                <div className="mainFormBox col-md-8 col-sm-12 col-xs-12" style={{ margin: '15px 0px 80px' }}>
                    <div>
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <h1>
                                        <strong style={{ fontSize: 'x-large' }}>What would you like to do?</strong>
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-sm-12 col-xs-12" style={{ marginTop: '30px', marginBottom: '20px' }}>
                        <div className="row">
                            <div className="col-md-6 col-sm-12 col-xs-12">
                                <div className="mainFormBox" style={{ minHeight: '100px', margin: '15px 0px', cursor: 'pointer', padding: '20px 20px 20px 20px' }} onClick={() => {
                                    browserHistory.push('/students');
                                }}>
                                    <div className="" style={{
                                        width: '0px',
                                        position: 'relative',
                                        marginRight: '75px',
                                        float: 'left'
                                    }}>
                                        <img src={icon1} style={{ height: '65px' }} />
                                    </div>
                                    <strong style={{ fontSize: 'larger' }}>Classes</strong>
                                    <div>Create and Manage Classes</div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12 col-xs-12">
                                <div className="mainFormBox" style={{ minHeight: '100px', margin: '15px 0px', cursor: 'pointer', padding: '20px 20px 20px 20px' }} onClick={() => {
                                    browserHistory.push('/reports');
                                }}>
                                    <div className="" style={{
                                        width: '0px',
                                        position: 'relative',
                                        marginRight: '75px',
                                        float: 'left',
                                        left: "4px",
                                        top: "-4px"

                                    }}>
                                        <img src={icon5} style={{ height: '65px' }} />
                                    </div>
                                    <strong style={{ fontSize: 'larger' }}>Reports</strong>
                                    <div>Generate and View Student Reports</div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {!this.state.isLinked &&
                                <div className="col-md-6 col-sm-12 col-xs-12">
                                    <div className="mainFormBox" style={{ minHeight: '100px', margin: '15px 0px', cursor: 'pointer', padding: '20px 20px 20px 20px' }} onClick={() => {
                                        browserHistory.push('/activities');
                                    }}>
                                        <div className="" style={{
                                            width: '0px',
                                            position: 'relative',
                                            marginRight: '75px',
                                            marginTop: '-5px',
                                            float: 'left'
                                        }}>
                                            <img src={icon} style={{ height: '70px' }} />
                                        </div>
                                        <strong style={{ fontSize: 'larger' }}>Activities</strong>
                                        <div>Create, Manage or Assign Online Activities</div>
                                    </div>
                                </div>
                            }
                            {!this.state.isLinked &&
                                <div className="col-md-6 col-sm-12 col-xs-12">
                                    <div className="mainFormBox" style={{ minHeight: '100px', margin: '15px 0px', cursor: 'pointer', padding: '20px 20px 20px 20px' }} onClick={() => {
                                        browserHistory.push('/courses');
                                    }}>
                                        <div className="" style={{
                                            width: '0px',
                                            position: 'relative',
                                            marginRight: '75px',
                                            marginTop: '-4px',
                                            float: 'left'
                                        }}>
                                            <img src={icon} style={{ height: '65px' }} />
                                        </div>
                                        <strong style={{ fontSize: 'larger' }}>Courses</strong>
                                        <div>Create, Manage or Assign Online Courses</div>
                                    </div>
                                </div>}
                        </div>
                        <div className="row">
                            {!this.state.isLinked &&
                                <div className="col-md-6 col-sm-12 col-xs-12">
                                    <div className="mainFormBox" style={{ minHeight: '100px', margin: '15px 0px', cursor: 'pointer', padding: '20px 20px 20px 20px' }} onClick={() => {
                                        browserHistory.push('/assignments');
                                    }}>
                                        <div className="" style={{
                                            width: '0px',
                                            position: 'relative',
                                            marginRight: '80px',
                                            marginLeft: '-7px',
                                            top: "-7px",
                                            float: 'left'
                                        }}>
                                            <img src={icon4} style={{ height: '72px' }} />
                                        </div>
                                        <strong style={{ fontSize: 'larger' }}>Assignments</strong>
                                        <div>View and Manage Student Assignments</div>
                                    </div>
                                </div>}
                            {!this.state.isLinked &&
                                <div className="col-md-6 col-sm-12 col-xs-12">
                                    <div className="mainFormBox" style={{ minHeight: '100px', margin: '15px 0px', cursor: 'pointer', padding: '20px 20px 20px 20px' }} onClick={() => {
                                        browserHistory.push('/questions');
                                    }}>
                                        <div className="" style={{
                                            width: '0px',
                                            position: 'relative',
                                            marginRight: '75px',
                                            float: 'left',
                                            left: "-17px",
                                            top: "-5px"
                                        }}>
                                            <img src={icon6} style={{ height: '67px' }} />
                                        </div>
                                        <strong style={{ fontSize: 'larger' }}>Question Editor</strong>
                                        <div>Create or Manage Existing Questions.</div>
                                    </div>
                                </div>}
                        </div>
                    </div>
                </div>
                <div className="col-md-2 hidden-xs hidden-sm"></div>
                {this.state.showWelcomeDialog && this.getWelcomeDialog()}
                {this.state.showVerificationCodeDialog && this.getverificationCodeDialog()}
                <ProgressIndicator show={this.state.loading} />
                {this.state.showAlert &&
                    <div className="row">
                        <div className="col-md-3 col-xs-3" />
                        <div className="col-md-6 col-xs-3">
                            <Alert bsStyle={this.state.alertStyle}
                                onDismiss={this.handleAlertDismiss}>
                                {this.state.alertText}
                            </Alert>
                        </div>
                        <div className="col-md-3 col-xs-3" />
                    </div>
                }
                {this.state.gotIt_showAlert && this.props.auth.user.federatedIdentity &&
                    <div className="row">
                        <div className="col-md-3 col-xs-3" />
                        <div className="col-md-6 col-xs-3">
                            <style>{"\
                .gotItButton{\
                //   color:#d9534f !important;\
                }\
                .gotItButton:hover{\
                //   color:#333 !important;\
                  }\
                #gotItAlert button.close{\
                //   padding-right: 40px;\
                }\
                #gotItAlert {\
                //   padding-right: 20px;\
                    }\
                .doNotShowMeAgain {\
                //   padding: 0;\
                //   float: right;\
                    font-size: 12px;\
                    margin-top: 5px;\
                    padding: 0;\
                    color: #555;\
                    line-height: 1;\
                }\
                .doNotShowMeAgain:hover {\
                    color: black;\
                }\
                .buttonSpan{\
                    display: block;\
                }\
              "}</style>
                            <Alert bsStyle={this.state.gotIt_alertStyle}
                                onDismiss={this.gotIthandleAlertDismiss}
                                // closeLabel={this.state.gotIt_closeLabel}
                                id={this.state.gotIt_alertID}>
                                {/* <h4>Note:</h4> */}
                                {/* {this.state.gotIt_alertText1}{this.state.gotIt_a}{this.state.gotIt_alertText2} */}
                                {this.state.gotIt_alertText}
                                &nbsp; &nbsp;
                                {/* <span className="buttonSpan"><Button bsStyle="link" className="doNotShowMeAgain" onClick={() => {
                                    this.doesShowGotItMessage();
                                    this.setState({ gotIt_showAlert: false })
                                }}>Do not show me again</Button></span> */}
                            </Alert>
                            {setTimeout(() => {
                                this.setState({ 'gotIt_showAlert': false });

                            }, 30000)}
                        </div>
                        <div className="col-md-3 col-xs-3" />
                    </div>
                }
            </div>
        );
    }
}


function mapStateToProps(state) {
    return { ...state };
}

export default connect(mapStateToProps)(ManageDashboardTeacher);
