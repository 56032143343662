import {combineReducers} from 'redux';
import searchBar from './search-bar-reducer';
import manageActivities from './manage-activities-reducer';
import auth from './auth'
import headingReducer from './heading-reducer';
import manageCourses from './manage-courses-reducer';
import manageQuestions from './manage-questions-reducer';
import manageAssignments from './manage-assignments-reducer';
import studentPortal from './student-portal-reducer';
import manageSchools from './manage-schools-reducer';
import elasticSearchAttributes from  './elastic-search-reducer';
import manageUsers from  './manage-users-reducer';
import lpAdminReport from './lp-admin-report-reducer';
import appSettings from './appSettings'

const rootReducer = combineReducers({
    searchBar,
    manageActivities,
    auth,
    headingReducer,
    manageCourses,
    manageQuestions,
    manageAssignments,
    studentPortal,
    manageSchools,
    elasticSearchAttributes,
    manageUsers,
    lpAdminReport,
    appSettings
});

export default rootReducer;
