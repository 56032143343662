export default class Utility {

    static ucFirst(text) {
        if (typeof text === "string") {
            if (text === undefined || text === "" || text === '' || text === null)
            {
                return '';
            }
            else
            {
                return text.charAt(0).toUpperCase() + text.substr(1);
            }
        } else {
          return text;
        }

    }

    static getDataSet(path) {
        var levels = path.split(".");
        var records = this.props[levels[0]];
        for (let i = 1; i < levels.length; i++) {
            records = records[levels[i]];
        }
        if (records && records.length > 0) {
            return records;
        } else {
            return [];
        }
    }
}
