/**
 * Created by ashahab on 5/31/2017.
 */
import React from 'react';
import LPlogo from '../../assets/images/logo2.png'
import './powered-by.css'

export const PoweredByLearnerPal = () =>(
            <div className='leave-footer-space'>
                <div className="footer text-center">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="text-center">
                            <p>
                                Powered by LearnerPal
                            </p>
                            <img src={LPlogo} alt="-" className="footer-logo" height='25px'/>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
);