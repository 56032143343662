import React, { Component } from "react";
import { connect } from "react-redux";
import Breadcrumb from "../../../breadcrumb/breadcrumb";
import TextFieldGroup from "../../../forms/text-field-group";
import SelectFieldGroup from "../../../forms/select-field-group";
import apiClient from "../../../../actions/api-client";
import Filter from "../../../filter/filter";
import $ from "jquery";
import LoginUtils from "../../../common/login-utils";
import ElasticSearchStore from "../../../../actions/elastic-search-store";
import {
    CREATE_ACTIVITY_FETCH_QUESTION_SUCCESS,
    CREATE_ACTIVITY_FETCH_QUESTION_FAILED,
    CREATE_ACTIVITY_MOVE_SELECTED_TO_QUESTIONS,
    CREATE_ACTIVITY_MOVE_QUESTION,
    CREATE_QUESTION_SET_INIT_INFO,
    ElasticSearchTypes, ElasticSearchColumns
} from "../../../../actions/types";
import { Modal, Alert, Button } from "react-bootstrap";
import { browserHistory, withRouter } from "react-router";
import validateInput from "../../../../validations/create-question-activity";
import ManageActivityActions from "../../../../actions/manage-activities-actions";
import ManageCourseActions from "../../../../actions/manage-courses-actions";
import ProgressIndicator from "../../../progress-indicator/progress-indicator";
import Validator from "validator";
import config from "../../../../config";
import { Parser } from "html-to-react";
import ElasticSearchActions from "../../../../actions/elastic-search-actions";
import ElasticSearchQuery from "../../../../actions/elastic-search-query";
import ReactScrollPagination from 'react-scroll-pagination'
import ReactDOM from 'react-dom';
// import axios from "axios";
import ClevertapReact from "clevertap-react";
import classnames from "classnames";
import MaterialIcon from "react-google-material-icons";



class CreateQuestionActivity extends Component {

    tableName = 'question' + config.stage;
    activityTableName = 'activity' + config.stage;

    constructor(props) {

        super(props);
        this.questions = props.questions;
        this.getAllSubjects = this.getAllSubjects.bind(this);
        this.onActivityNameChange = this.onActivityNameChange.bind(this);
        this.onActivityStateChange = this.onActivityStateChange.bind(this);
        this.checkEnterKeyPress = this.checkEnterKeyPress.bind(this);
        this.inputTags = this.inputTags.bind(this);
        this.deleteTags = this.deleteTags.bind(this);
        this.selectQuestionLearnerPalOnClickHandler = this.selectQuestionLearnerPalOnClickHandler.bind(this);
        this.selectQuestionMyQuestionOnClickHandler = this.selectQuestionMyQuestionOnClickHandler.bind(this);
        this.selectQuestionSearchOnChangeHandler = this.selectQuestionSearchOnChangeHandler.bind(this);
        this.selectedQuestionClearAllOnClickHandler = this.selectedQuestionClearAllOnClickHandler.bind(this);
        this.selectedQuestionPreviewOnClickHandler = this.selectedQuestionPreviewOnClickHandler.bind(this);
        this.questionPreviewOnClickHandler = this.questionPreviewOnClickHandler.bind(this);
        this.saveAndExitClickHandler = this.saveAndExitClickHandler.bind(this);
        this.saveAndAssignClickHandler = this.saveAndAssignClickHandler.bind(this);
        this.generateTagHtml = this.generateTagHtml.bind(this);
        this.showIndicator = this.showIndicator.bind(this);
        this.hideIndicator = this.hideIndicator.bind(this);
        this.selectRandomQuestions = this.selectRandomQuestions.bind(this);
        this.openSelected = this.openSelected.bind(this);
        this.openQuestions = this.openQuestions.bind(this);
        this.cancelCreate = this.cancelCreate.bind(this);
        this.getRandomQuestionBoxDialog = this.getRandomQuestionBoxDialog.bind(this);
        this.showRandomQuestionDialog = this.showRandomQuestionDialog.bind(this);
        this.getSearchTipBox = this.getSearchTipBox.bind(this);
        this.saveSearchTipBoxInfo = this.saveSearchTipBoxInfo.bind(this);
        this.getSaveAndAssignDialog = this.getSaveAndAssignDialog.bind(this);
        this.onCourseChange = this.onCourseChange.bind(this);
        this.getCourse = this.getCourse.bind(this);
        this.onUnitChange = this.onUnitChange.bind(this);
        this.onAssessmentChange = this.onAssessmentChange.bind(this);
        this.getCreateActivityInfoDialog = this.getCreateActivityInfoDialog.bind(this);
        this.showCreateActivityDialog = this.showCreateActivityDialog.bind(this);
        this.saveAndExit = this.saveAndExit.bind(this);
        this.dispatchCreateQuestionFields = this.dispatchCreateQuestionFields.bind(this);
        this.getNextPage = this.getNextPage.bind(this);
        this.onActivityDescriptionChange = this.onActivityDescriptionChange.bind(this);
        this.toggleTranslate = this.toggleTranslate.bind(this);
        this.keepOnPage = this.keepOnPage.bind(this);
        this.route = this.props.route;
        this.router = this.props.router;
        this.onChange = this.onChange.bind(this);

        this.ElasticSearchStore = new ElasticSearchStore();

        this.state = {
            leave: false,
            errors: {},
            activeOwnerFilter: 'learnerpal',
            showPreviewDialog: false,
            showQuestionErrorDialog: false,
            loading: false,
            showRandomQuesionDialogBox: false,
            showSearchTipBox: false,
            showQuestionPreview: false,
            selectedQuestion: {},
            showSaveAndAssignDialog: false,
            selectedCourse: null,
            showCreateInfoDialog: true,
            showAddToCourse: false,
            isMandotary: false,
            loadingNextPage: 0,
            errorMessage: '',
            allSubjects: [],
            createActivityQuestionActivityName: this.props.manageActivities.createActivityQuestionActivityName,
            seeEnglish: "",
            seeTranslation: "",
            isTranslated: false
        }

        this.perPageLimit = 50;
    }

    scrollToLastQuestion = () => /* window.scrollTo(0, this.refs.lastQuestion.offsetTop); */ this.refs && this.refs.lastQuestion && this.refs.lastQuestion.scrollIntoView(false)

    getNextPage() {
        //const asd = ReactDOM.findDOMNode(this.refs.questionSelectionBoxRef);

        // if ((asd.scrollHeight % (asd.scrollTop+400)) < 50 )
        // {
        //     if (this.state.loadingNextPage === 0) {
        //         console.log("scrolling");
        //         let myID = Math.random() * 10000000000000000000;
        //         this.setState({'loadingNextPage': myID});
        // if (this.state.loadingNextPage === myID) {

        this.showIndicator();
        let that = this;
        let esAttr = this.props.elasticSearchAttributes.map.get("create-question-activity");
        const pageSize = esAttr.pageSize + this.perPageLimit;
        const { dispatch } = this.props;
        ElasticSearchActions.setElasticSearchAttributes(dispatch, "create-question-activity", esAttr.searchTerm, pageSize, esAttr.activePage, esAttr.sortObj, esAttr.filterValues);
        let elasticSearchQuery = new ElasticSearchQuery();
        elasticSearchQuery.query(ElasticSearchTypes.Question + config.stage, ElasticSearchColumns.Question, this.props,
            esAttr.searchTerm, pageSize, esAttr.activePage, esAttr.sortObj, esAttr.filterValues, function (results, totalRecords) {

                if (results.length == totalRecords) {
                    that.setState({ allQuestionsAreLoaded: true })
                }

                dispatch({
                    type: CREATE_ACTIVITY_FETCH_QUESTION_SUCCESS,
                    status: 200,
                    data: JSON.parse(JSON.stringify(results)),
                    alertText: '',
                    alertStyle: '',
                    showAlert: false,
                    totalRecords: totalRecords
                });
                that.scrollToLastQuestion();
                that.hideIndicator();
            }, undefined, undefined, undefined, undefined, this.props.manageActivities.createActivityActiveOwnerFilter === 'LearnerPal' ? `questionState2: "Published" ` : '');
        // this.setState({'loadingNextPage': 0});
        // }
        // }
        // }
    }
    cancelCreate() {
        let that = this;
        setTimeout(() => {
            const { dispatch } = that.props;
            ManageActivityActions.setMessage(dispatch, false, '', 'success', function () {
                that.hideIndicator();
                browserHistory.push('/activities');
            });
        }, 100);
    }

    showRandomQuestionDialog() {

        this.setState({ showRandomQuesionDialogBox: true });
    }

    generateTagHtml(tags, tagType) {
        let context = this;
        let html = [];
        tags.forEach(tag => {
            html.push(<span className="keywordTag" key={"keyword" + tag}>
                <input type="hidden" name={"keyWord" + tag} value={tag} /> {tag}
                <span className="text-cross-icon" onClick={evt => {
                    context.deleteTags(evt, tagType, tag);
                }}>x</span>
            </span>)
        });
        return html;
    }
    // componentWillUnmount() {
    //     const list = ReactDOM.findDOMNode(this.refs.questionSelectionBoxRef);
    //     list.removeEventListener('scroll', this.getNextPage);
    // }

    keepOnPage(e) {
        let message = 'Unsaved changes will be lost';
        e.returnValue = message;
        if (this.state.leave) {
            return null;
        } else {
            return message;
        }
    }

    componentDidMount() {
        let context = this;

        LoginUtils.isAuthenticated(context.props, browserHistory, '/activities/question/new', '/');
        this.router.setRouteLeaveHook(this.route, this.keepOnPage);

        // const list = ReactDOM.findDOMNode(this.refs.questionSelectionBoxRef);
        // list.addEventListener('scroll', this.getNextPage);

        const { dispatch } = this.props;
        ElasticSearchActions.setElasticSearchAttributes(dispatch, "create-question-activity", '', this.perPageLimit, 1, {}  /* {subcategory:{order: "asc"}} */, []);

        let visibility = '( "' + this.props.auth.user.userName + '" )';
        let subjectsToSearch = "(";
        if (this.props.auth.user.subjects) {
            this.props.auth.user.subjects.forEach(sub => {
                subjectsToSearch += "\"" + sub + "\" OR ";
            })
        }
        subjectsToSearch = subjectsToSearch.substring(0, subjectsToSearch.length - 3);
        subjectsToSearch += ")";
        ManageCourseActions.fetchAllCourses(dispatch, this.props.auth.user.userName, 'course' + config.stage, {
            'query_string': {
                'fields': ['visibleTo', 'subject'],
                'query': 'visibleTo : ' + visibility + ' AND subject : ' + subjectsToSearch
            }
        });
        context.openSelected();
        context.selectQuestionLearnerPalOnClickHandler(true, true);
        context.showIndicator();
        if (context.props.params && context.props.params.id) {
            let params = {
                activityID: context.props.params.id,
            };
            let pathTemplate = '/activity/{activityID}';
            let { dispatch } = context.props;
            apiClient.invokeApi(params, pathTemplate, 'GET', {}, {}).then(function (result) {

                let activity = result.data;

                //handle edit record data.
                let subHtml = [], wordHtml = [];
                if (activity.subjects) {
                    subHtml = context.generateTagHtml(result.data.subjects, 'subject');
                }
                if (activity.keywords) {
                    wordHtml = context.generateTagHtml(result.data.keywords, 'keyword');
                }

                dispatch({
                    type: CREATE_ACTIVITY_MOVE_QUESTION,
                    createActivityQuestionTaggedKeywordsHtml: wordHtml,
                    createActivityQuestionTaggedKeywords: result.data.keywords ? result.data.keywords : [],
                    createActivityQuestionTaggedSubjectsHtml: subHtml,
                    createActivityQuestionTaggedSubjects: activity.subjects ? activity.subjects.sort() : [],
                    createActivityQuestionActivityType: activity.activityType,
                    createActivityQuestionDescription: activity.description,
                    createActivityQuestionActivityName: activity.activityName,
                    createActivityQuestionActivityState: activity.activityState,
                    createActivitySelectedQuestions: activity.questions,
                    createActivityQuestions: [],
                    createActivityCurrentActivity: activity,
                });
                context.hideIndicator();
            }).catch(function (result) {
                context.hideIndicator();
                console.error(result);
            });
        }
        else {
            dispatch({
                type: CREATE_ACTIVITY_FETCH_QUESTION_SUCCESS,
                status: 200,
                alertText: '',
                alertStyle: '',
                showAlert: false
            });
        }

        if (!(context.props.manageActivities && context.props.manageActivities.createActivityQuestionAllSubjects && context.props.manageActivities.createActivityQuestionAllSubjects.length === 0)) {
            context.getAllSubjects();
        }
        let searchTipInfo = localStorage.getItem("dontShowAgain" + this.props.auth.user.userName);
        if (!searchTipInfo) {
            this.setState({ showSearchTipBox: true });
        }
        context.hideIndicator();
        setTimeout(() => {
            this.child.filterRecords(this.child.appliedFilters)
        }, 1000);
        window.addEventListener('beforeunload', this.keepOnPage);

    }
    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.keepOnPage);
    }
    getAllQuestions(callback) {

        let context = this;
        let { dispatch } = this.props;

        ElasticSearchActions.setElasticSearchAttributes(dispatch, 'question'.toString(), '', this.perPageLimit, 1, { subcategory: { order: "asc" } }, []);
        let elasticSearchQuery = new ElasticSearchQuery();
        elasticSearchQuery.query(ElasticSearchTypes.Question + config.stage, ElasticSearchColumns.Question, this.props,
            '', this.perPageLimit, 1, { subcategory: { order: "asc" } }, [], function (results, totalRecords) {
                dispatch({
                    type: CREATE_ACTIVITY_FETCH_QUESTION_SUCCESS,
                    data: results,
                    createActivitySelectedQuestions: context.props.manageActivities && context.props.manageActivities.createActivitySelectedQuestions ? context.props.manageActivities.createActivitySelectedQuestions : [],
                    createActivityActiveOwnerFilter: 'LearnerPal',
                    skipAlterForAllRecords: false
                });
                if (callback && typeof callback === 'function') {
                    callback(results);
                }
            });


    }

    getAllSubjects() {
        let sortedSubjects = [];
        this.props.auth.user.subjects.forEach(sub => {
            sortedSubjects.push({ "id": sub, "name": sub });
        });
        sortedSubjects.sort(function (a, b) {
            return a.id.localeCompare(b.id, undefined, {
                numeric: true,
                sensitivity: 'base'
            });
        });
        let that = this;
        this.setState({ allSubjects: sortedSubjects });
    }

    selectQuestionSearchOnChangeHandler(evt) {

        let term = evt.nativeEvent.target.value;
        let esAttr = this.props.elasticSearchAttributes.map.get("create-question-activity");
        const { dispatch } = this.props;
        let elasticSearchQuery = new ElasticSearchQuery();
        ElasticSearchActions.setElasticSearchAttributes(dispatch, "create-question-activity", term, esAttr.pageSize, esAttr.activePage, esAttr.sortObj, esAttr.filterValues);
        elasticSearchQuery.query(ElasticSearchTypes.Question + config.stage, ElasticSearchColumns.Question, this.props,
            term, esAttr.pageSize, esAttr.activePage, esAttr.sortObj, [], function (results, totalRecords) {
                dispatch({
                    type: CREATE_ACTIVITY_FETCH_QUESTION_SUCCESS,
                    status: 200,
                    data: JSON.parse(JSON.stringify(results)),
                    alertText: '',
                    alertStyle: '',
                    showAlert: false,
                    totalRecords: totalRecords,
                    createActivityQuestionClearFilters: true,
                    createActivityQuestionClearFiltersForSearch: true,
                });
            }, undefined, undefined, undefined, undefined, this.props.manageActivities.createActivityActiveOwnerFilter === 'LearnerPal' ? `questionState2: "Published" ` : '');
    }

    resetSearchTerm(attr) {
        this.refs.searchRef.value = '';
        let esAttr = this.props.elasticSearchAttributes.map.get("create-question-activity");
        if (esAttr.searchTerm) {
            const { dispatch } = this.props;
            ElasticSearchActions.setElasticSearchAttributes(dispatch, "create-question-activity", undefined, esAttr.pageSize, esAttr.activePage, esAttr.sortObj, esAttr.filterValues);
        }
    }

    selectQuestionLearnerPalOnClickHandler(doNotRefreshFilters, ForFilters) {
        if (this.props.manageActivities.createActivityActiveOwnerFilter !== 'LearnerPal') {
            // this.resetSearchTerm();
            this.state.allQuestionsAreLoaded = false;
            !ForFilters && sessionStorage.setItem("restoreFilter", true);
            let { dispatch } = this.props;
            dispatch({
                type: CREATE_ACTIVITY_MOVE_QUESTION,
                createActivityActiveOwnerFilter: 'LearnerPal',
                // createActivityQuestionClearFilters: true
                refreshFilters: doNotRefreshFilters === true ? false : true
            });
        }
    }

    selectQuestionMyQuestionOnClickHandler() {
        if (this.props.manageActivities.createActivityActiveOwnerFilter === 'LearnerPal') {
            // this.resetSearchTerm();
            this.state.allQuestionsAreLoaded = false;
            sessionStorage.setItem("restoreFilter", true);
            let { dispatch } = this.props;
            dispatch({
                type: CREATE_ACTIVITY_MOVE_QUESTION,
                createActivityActiveOwnerFilter: this.props.auth.user.userName,
                // createActivityQuestionClearFilters: true
                refreshFilters: true
            });
        }
    }

    selectedQuestionClearAllOnClickHandler() {
        let { dispatch } = this.props;
        let questions = this.props.manageActivities && this.props.manageActivities.createActivityQuestions ? this.props.manageActivities.createActivityQuestions : [];
        let selectedQuestions = this.props.manageActivities && this.props.manageActivities.createActivitySelectedQuestions ? this.props.manageActivities.createActivitySelectedQuestions : [];
        questions = questions.concat(selectedQuestions);
        dispatch({
            type: CREATE_ACTIVITY_MOVE_QUESTION,
            createActivityQuestions: questions,
            createActivitySelectedQuestions: []
        });
    }

    selectedQuestionPreviewOnClickHandler() {
        this.setState({
            showPreviewDialog: true
        })
    }

    questionPreviewOnClickHandler(e) {
        let id = e.target.id.split(':');
        let selectedQuestion = [];
        if (id[1] === "selectedQuestions") {
            let index = this.props.manageActivities.createActivitySelectedQuestions.findIndex(x => x.id === id[0] || x.questionID === id[0]);
            selectedQuestion.push(this.props.manageActivities.createActivitySelectedQuestions[index]);
            // selectedQuestion = this.props.manageActivities.createActivitySelectedQuestions.filter(function(ques) {
            //     return ques.id ?ques.id:ques.questionID === id[0];
            // });
        }
        else {
            let index = this.props.manageActivities.createActivityQuestions.findIndex(x => x.id === id[0] || x.questionID === id[0]);
            selectedQuestion.push(this.props.manageActivities.createActivityQuestions[index]);

            // selectedQuestion = this.props.manageActivities.createActivityQuestions.filter(function(ques) {
            //     return ques.id ?ques.id:ques.questionID === id[0];
            // });
        }

        this.setState({
            showQuestionPreview: true,
            selectedQuestion: selectedQuestion
        })
    }
    isValid(data) {
        let allRecords = this.props.manageActivities.allRecords ? this.props.manageActivities.allRecords : [];
        const { errors, isValid } = validateInput(data, allRecords, this.props.auth.user.userName, this.props.params && this.props.params.id ? this.props.params.id : null);
        if (!isValid) {
            this.setState({ errors });
        }
        return isValid;
    }


    showIndicator() {
        this.setState({
            ...this.state,
            loading: true
        });
    }

    hideIndicator() {
        this.setState({
            ...this.state,
            loading: false
        });
    }

    onCourseChange(evt) {
        let value = evt.target.value;
        let that = this;
        this.setState({ isMandotary: true }, function () {
            that.getCourse(value);
        });
    }

    getCourse(id) {
        let context = this;

        context.showIndicator();
        if (id) {
            let params = {
                courseID: id,
            };
            let pathTemplate = '/course/{courseID}';
            let method = 'GET';
            let additionalParams = {};
            let body = {};

            apiClient.invokeApi(params, pathTemplate, method, additionalParams, body).then(function (result) {

                context.setState({
                    ...context.state,
                    selectedCourse: result.data,
                    loading: false
                });
            }).catch(function (error) {
                context.hideIndicator();
            });
        }
    }

    onUnitChange(evt) {

        if (evt.target.value) {
            let selectedUnit = this.state.selectedCourse.units.filter(unit => unit.unitID === evt.target.value)[0];
            this.setState({ ...this.state, selectedUnit: selectedUnit });
        }
    }

    onAssessmentChange(evt) {

        if (evt.target.value) {
            this.setState({ selectedAssessment: evt.target.value });
        }
    }

    getSaveAndAssignDialog() {
        let courses = this.props.manageCourses.results;
        let courseList = courses.map(function (c) {
            return { name: c.courseName, id: c.id };
        });
        let selectedCourse = this.state.selectedCourse ? this.state.selectedCourse.courseID : '';
        let unitList = this.state.selectedCourse && this.state.selectedCourse.units ? this.state.selectedCourse.units.map(function (c) {
            return { name: c.unitName, id: c.unitID };
        }) : [];
        let selectedUnit = this.state.selectedUnit ? this.state.selectedUnit.unitID : '';
        let assessmentList = [{ "id": "assessment", "name": "Assessment" }, { "id": "practice", "name": "Practice" }];
        let selectedAssessment = this.state.selectedAssessment;

        let body = <div id="saveAndAssignDialogContent">
            {this.state.courseAssignError &&
                <p style={{ color: 'red' }}>{this.state.errorMessage}</p>}
            {courseList.length === 0 &&
                <div>
                    Please create a new course or duplicate an existing course to be able to add activities to the course from this screen.
                </div>
            }
            {courseList.length > 0 &&
                <div className="mainFormBox">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <SelectFieldGroup
                                error={this.state.errors.selectedCourse}
                                field="selectedCourse"
                                value={selectedCourse}
                                options={courseList}
                                label="Select Course in the box:"
                                placeholder="Select Course to Assign"
                                onChange={this.onCourseChange}
                            />
                        </div>
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <SelectFieldGroup
                                error={this.state.errors.selectedUnit}
                                field="selectedUnit"
                                value={selectedUnit}
                                options={unitList}
                                label="Select Unit:"
                                placeholder="Select Unit"
                                onChange={this.onUnitChange}
                            />
                        </div>
                        {this.state.saveAndExit &&
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <SelectFieldGroup
                                    error={this.state.errors.selectedAssessment}
                                    field="selectedAssessment"
                                    value={selectedAssessment}
                                    options={assessmentList}
                                    label="Question Activity Type:"
                                    placeholder="Select Question Activity Type"
                                    onChange={this.onAssessmentChange}
                                />
                            </div>}
                    </div>
                </div>
            }
        </div>;
        return <Modal
            backdrop="static"
            show={this.state.showSaveAndAssignDialog}
            onHide={() => {
                this.setState({
                    showSaveAndAssignDialog: false
                });
                browserHistory.push('/activities');
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            {/*<Modal.Header closeButton>*/}
            {/*<Modal.Title id="delete-activity-modal">Do you want to add this activity to a course?</Modal.Title>*/}
            {/*</Modal.Header>*/}
            <Modal.Header>
                {!this.state.saveAndExit && <Modal.Title>Do you want to add this activity to a course? (optional)</Modal.Title>}
                {this.state.saveAndExit && !this.props.params.id && <Modal.Title>Activity saved, you can access this activity under Manage Activities</Modal.Title>}
                {this.state.saveAndExit && this.props.params.id && <Modal.Title>Activity has been modified, you can access this activity under Manage Activities</Modal.Title>}
            </Modal.Header>
            {this.state.showAddToCourse && <Modal.Body>
                {body}
            </Modal.Body>
            }
            {!this.state.showAddToCourse && this.state.saveAndExit &&
                <Modal.Body>
                    <p>Do you want to add this activity to a course? (optional)</p>
                </Modal.Body>
            }
            <Modal.Footer>
                {!this.state.showAddToCourse && <Button className="rightMarginForModelButtons" onClick={() => {
                    if (this.state.saveAndExit) {
                        const { dispatch } = this.props;
                        ManageActivityActions.setMessage(dispatch, true, 'Activity has been created successfully', 'success', function () {

                            browserHistory.push('/activities');
                        });
                    }
                    else {
                        browserHistory.push({
                            pathname: '/assignments/assign',
                            state: { currentActivityObj: this.state.payloadForAssignCourse.activity, currentActivity: this.state.payloadForAssignCourse.activity.activityID, view: 'activity' }
                        })
                    }
                }}>No</Button>}
                {this.state.showAddToCourse && <Button className="rightMarginForModelButtons" onClick={() => {
                    if (this.state.saveAndExit) {
                        const { dispatch } = this.props;
                        ManageActivityActions.setMessage(dispatch, true, 'Activity has been created successfully', 'success', function () {

                            browserHistory.push('/activities');
                        });
                    }
                    else {
                        browserHistory.push({
                            pathname: '/assignments/assign',
                            state: { currentActivityObj: this.state.payloadForAssignCourse.activity, currentActivity: this.state.payloadForAssignCourse.activity.activityID, view: 'activity' }
                        })
                    }
                }}>Cancel</Button>}
                {!this.state.showAddToCourse &&
                    <Button className="rightMarginForModelButtons" onClick={() => {
                        this.setState({ 'showAddToCourse': true });
                    }} bsStyle='primary'>Yes</Button>
                }
                {this.state.showAddToCourse &&
                    <Button className="rightMarginForModelButtons" disabled={!this.state.payloadForAssignCourse}
                        onClick={() => {


                            let context = this;
                            let payloadForAssignCourse = context.state.payloadForAssignCourse;

                            if (!this.state.isMandotary) {
                                if (this.state.saveAndExit) {

                                    const { dispatch } = this.props;
                                    ManageActivityActions.setMessage(dispatch, true, 'Activity has been created successfully', 'success', function () {

                                        browserHistory.push('/activities');
                                    });
                                }
                                else {
                                    if (!this.state.selectedAssessment) {
                                        let errors = {};
                                        errors.selectedAssessment = 'This field is mandatory';
                                        this.setState({ errors });
                                    }
                                    else {
                                        browserHistory.push({
                                            pathname: '/assignments/assign',
                                            state: { isFromListScreen: true, assessmentType: context.state.selectedAssessment, currentActivityObj: context.state.payloadForAssignCourse.activity, currentActivity: context.state.payloadForAssignCourse.activity.activityID, view: 'activity' }
                                        });
                                    }
                                    // browserHistory.push({
                                    //     pathname: '/assignments/assign',
                                    //     state: {isFromListScreen:true, currentActivityObj: context.state.payloadForAssignCourse.activity, currentActivity: context.state.payloadForAssignCourse.activity.activityID, view:'activity'}
                                    // });
                                }
                            }
                            else {
                                if (context.state.saveAndExit) {
                                    //this.setState({'showAddToCourse': true, 'showSaveAndAssignDialog':false,saveAndExit:false, isMandotary:false,selectedCourse:'',selectedUnit:''});
                                    if ((!context.state.selectedCourse) &&
                                        ((payloadForAssignCourse.activity.activityType.toLowerCase() === 'question' ||
                                            payloadForAssignCourse.activity.activityType.toLowerCase() === 'questions'))) {
                                        context.setState({ courseAssignError: true, errorMessage: 'Course is required' });
                                    }
                                    else if ((!context.state.selectedUnit) &&
                                        ((payloadForAssignCourse.activity.activityType.toLowerCase() === 'question' ||
                                            payloadForAssignCourse.activity.activityType.toLowerCase() === 'questions'))) {
                                        context.setState({ courseAssignError: true, errorMessage: 'Unit is required' });
                                    }
                                    else if ((!context.state.selectedAssessment) &&
                                        ((payloadForAssignCourse.activity.activityType.toLowerCase() === 'question' ||
                                            payloadForAssignCourse.activity.activityType.toLowerCase() === 'questions'))) {
                                        context.setState({ courseAssignError: true, errorMessage: 'Please select a Question Activity Type' });
                                    }
                                    else {
                                        context.showIndicator();
                                        context.setState({ courseAssignError: false });
                                        payloadForAssignCourse.courseID = context.state.selectedCourse.courseID;
                                        payloadForAssignCourse.unitID = context.state.selectedUnit.unitID;
                                        payloadForAssignCourse.ownerName = context.props.auth.user.userName;
                                        payloadForAssignCourse.activity.questionActivityType = context.state.selectedAssessment;
                                        let pathTemplate = '/course/assignment';
                                        let method = 'PUT';
                                        let additionalParams = {};
                                        apiClient.invokeApi(null, pathTemplate, method, additionalParams, payloadForAssignCourse, '2').then(function (result) {
                                            const { dispatch } = context.props;
                                            ManageActivityActions.setMessage(dispatch, true, result.data, 'success', function () {
                                                browserHistory.push('/activities');
                                            });
                                            context.hideIndicator();
                                        }).catch(function (result) {
                                            const { dispatch } = context.props;
                                            ManageActivityActions.setMessage(dispatch, true, result.data, 'success', function () {
                                                browserHistory.push('/activities');
                                            });
                                        });
                                    }
                                }
                                else {

                                    //this.setState({'showAddToCourse': true, 'showSaveAndAssignDialog':false,saveAndExit:false, isMandotary:false,selectedCourse:'',selectedUnit:''});
                                    if ((!context.state.selectedCourse ||
                                        !context.state.selectedUnit) &&
                                        ((payloadForAssignCourse.activity.activityType.toLowerCase() === 'question' ||
                                            payloadForAssignCourse.activity.activityType.toLowerCase() === 'questions'))) {
                                        context.setState({ courseAssignError: true });
                                    }
                                    else {
                                        context.showIndicator();
                                        context.setState({ courseAssignError: false });
                                        payloadForAssignCourse.courseID = context.state.selectedCourse.courseID;
                                        payloadForAssignCourse.unitID = context.state.selectedUnit.unitID;
                                        payloadForAssignCourse.ownerName = context.props.auth.user.userName;

                                        let pathTemplate = '/course/assignment';
                                        let method = 'PUT';
                                        let additionalParams = {};
                                        apiClient.invokeApi(null, pathTemplate, method, additionalParams, payloadForAssignCourse, '2').then(function (result) {
                                            browserHistory.push({
                                                pathname: '/assignments/assign',
                                                state: { isFromListScreen: true, currentActivityObj: context.state.payloadForAssignCourse.activity, currentActivity: context.state.payloadForAssignCourse.activity.activityID, view: 'activity' }
                                            });
                                            context.hideIndicator();
                                        }).catch(function (result) {
                                            browserHistory.push({
                                                pathname: '/assignments/assign',
                                                state: { isFromListScreen: true, currentActivityObj: context.state.payloadForAssignCourse.activity, currentActivity: context.state.payloadForAssignCourse.activity.activityID, view: 'activity' }
                                            });
                                        });
                                    }
                                }
                            }
                        }} bsStyle='primary'>{!this.state.isMandotary && this.state.saveAndExit && 'Exit'} {this.state.isMandotary && this.state.saveAndExit && 'Add to Course and Exit'} {this.state.isMandotary && !this.state.saveAndExit && 'Add to Course and Assign'} {!this.state.isMandotary && !this.state.saveAndExit && 'Assign'} </Button>}
            </Modal.Footer>
        </Modal>;
    }

    dispatchCreateQuestionFields() {
        let { dispatch } = this.props;
        dispatch({
            type: CREATE_QUESTION_SET_INIT_INFO,
            taggedKeywordsHtml: [],
            taggedKeywords: [],
            taggedSubjectsHtml: [],
            taggedSubjects: [],
            allSubjects: this.state.allSubjects,
            activityType: '',
            activityName: '',
            selected: []
        })
    }

    isNotDuplicatedName() {
        // let axiosInstance = axios.create({
        //     baseURL: 'https://search-learnerpal-eimkio3umgx5fnpuy7ypsrrsri.us-east-1.es.amazonaws.com/learnerpal',
        //     timeout: 60000,
        //     headers: {'Content-Type': 'application/json'},
        //     responseType: 'json',
        //     transformResponse: {function (data) {
        //         return data;
        //     }}
        // });
        // return axiosInstance({
        //     method: 'GET',
        //     url: '/' + ElasticSearchTypes.Activity+config.stage + '/' +  this.props.manageActivities.createActivityQuestionActivityName.replace(/\s|\W|[#$%^&*()'.]/g, "") + "_" + this.props.auth.user.userName,
        // })
        return apiClient.invokeApi({}, '/getesdata', 'POST', {}, { id: "/" + this.props.manageActivities.createActivityQuestionActivityName.replace(/\s|\W|[#$%^&*()'.]/g, "").toLocaleLowerCase() + "_" + this.props.auth.user.userName, index: 'learnerpal/', esDocumentType: ElasticSearchTypes.Activity + config.stage }, '5');

    }

    saveAndExitClickHandler() {
        let that = this;
        return new Promise((resolve, reject) => {
            let questions = this.props.manageActivities.createActivitySelectedQuestions ? this.props.manageActivities.createActivitySelectedQuestions : [];
            let qIds = [];
            questions.forEach(q => {
                qIds.push(q.id ? q.id : q.questionID);
            });
            let data = {
                orderBy: '1',
                schoolName: this.props.auth.user.schoolName,
                ownerName: this.props.auth.user.userName,
                activityType: 'question',
                activityName: this.props.manageActivities.createActivityQuestionActivityName ? this.props.manageActivities.createActivityQuestionActivityName : '',
                tags: this.props.manageActivities.createActivityQuestionTaggedKeywords ? this.props.manageActivities.createActivityQuestionTaggedKeywords : [],
                subjects: this.props.manageActivities.createActivityQuestionTaggedSubjects ? this.props.manageActivities.createActivityQuestionTaggedSubjects.sort() : [],
                activityState: this.props.manageActivities.createActivityQuestionActivityState ? this.props.manageActivities.createActivityQuestionActivityState : 'In Progress',
                questions: qIds
            };
            if (that.props.manageActivities.createActivityQuestionDescription) {
                data.description = this.props.manageActivities.createActivityQuestionDescription;
            }

            if (that.props.params.id) {
                data.activityID = this.props.params.id;
            }
            if (that.isValid(data)) {
                if (that.props.params && that.props.params.id) {
                    let params = {};
                    let pathTemplate = '/activity';
                    let method = that.props.params.id ? 'PUT' : 'POST';
                    let additionalParams = {};
                    let body = data;
                    that.showIndicator();
                    apiClient.invokeApi(params, pathTemplate, method, additionalParams, body).then(function (result) {
                        if (method === 'POST') {
                            ClevertapReact.event("Create Activity", { 'userName': that.props.auth.user.userName });
                        }
                        else {
                            ClevertapReact.event("Update Activity", { 'userName': that.props.auth.user.userName });
                        }
                        //  that.dispatchCreateQuestionFields();
                        that.setState({ leave: true }, that.dispatchCreateQuestionFields());
                        if (method !== 'PUT') {
                            resolve({ saved: true, status: 'Success', data: result.data });
                        } else {
                            reject({ saved: false, status: 'Error', data: result.data });
                        }
                    }).catch(function (error) {
                        that.hideIndicator();
                        reject({ saved: false, status: 'Error' });
                    });
                }
                else {
                    that.isNotDuplicatedName().then(response => {

                        if (response.data.found) {
                            that.hideIndicator();
                            if (response.data.found) {
                                let errors = {};
                                errors.activityName = 'Activity with the same name already exists';
                                that.setState({ errors });
                                that.setState({ showCreateInfoDialog: true });
                            }
                        }
                    }).catch(response => {

                        if (!response.response.data.found) {
                            let params = {};
                            let pathTemplate = '/activity';
                            let method = that.props.params.id ? 'PUT' : 'POST';
                            let additionalParams = {};
                            let body = data;
                            that.showIndicator();
                            apiClient.invokeApi(params, pathTemplate, method, additionalParams, body).then(function (result) {
                                //  that.dispatchCreateQuestionFields();
                                that.setState({ leave: true }, that.dispatchCreateQuestionFields());
                                if (method !== 'PUT') {
                                    resolve({ saved: true, status: 'Success', data: result.data });
                                } else {
                                    reject({ saved: false, status: 'Error', data: result.data });
                                }
                            }).catch(function (error) {
                                that.hideIndicator();
                                reject({ saved: false, status: 'Error' });
                            });
                        }
                    });
                }
            }
            else {
                that.hideIndicator();
                let errors = {};
                errors.activityName = 'Activity with the same name already exists';
                that.setState({ errors });
                that.setState({ showCreateInfoDialog: true });
            }
        });
    }

    saveAndExit() {
        let that = this;
        this.saveAndExitClickHandler().then(result => {
            that.hideIndicator();
            setTimeout(() => {
                const { dispatch } = that.props;
                ManageActivityActions.setMessage(dispatch, true, result.data.message, 'success', function () {
                    that.hideIndicator();
                    browserHistory.push('/activities');
                });
            }, 2000);
        }).catch(result => {
            setTimeout(() => {

                const { dispatch } = that.props;
                ManageActivityActions.setMessage(dispatch, true, result.data, 'success', function () {
                    that.hideIndicator();
                    browserHistory.push('/activities');
                });
            }, 2000);
        });
    }
    saveAndAssignClickHandler() {

        let context = this;
        context.showIndicator();
        context.saveAndExitClickHandler().then(result => {
            context.hideIndicator();
            delete result.data['message'];
            console.debug(result);
            context.setState({
                ...context.state,
                showSaveAndAssignDialog: true,
                showAddToCourse: false,
                payloadForAssignCourse: {
                    activity: result.data,
                    courseID: null,
                    unitID: null,
                    assessmentType: null
                }
            })
        }).catch(result => {
            context.hideIndicator();
            delete result.data['message'];
            console.debug(result);
            context.setState({
                ...context.state,
                showSaveAndAssignDialog: true,
                showAddToCourse: false,
                payloadForAssignCourse: {
                    activity: result.data,
                    courseID: null,
                    unitID: null,
                    assessmentType: null
                }
            })
        });
    }

    moveToSelectedQuestion(question) {

        let { dispatch } = this.props;
        let selected = this.props.manageActivities && this.props.manageActivities.createActivitySelectedQuestions ? this.props.manageActivities.createActivitySelectedQuestions : [];
        let questions = this.props.manageActivities && this.props.manageActivities.createActivityQuestions ? this.props.manageActivities.createActivityQuestions : [];
        let error = false;
        for (let i = 0; i < selected.length; i++) {
            if (question.questionType !== selected[i].questionType) {
                error = true;
                break;
            }
        }
        if (error) {
            this.setState({
                ...this.state,
                showQuestionErrorDialog: true
            });
            setTimeout(() => {
                this.setState({
                    ...this.state,
                    showQuestionErrorDialog: false
                });
            }, 5000);

        } else {
            let newQuestions = [];
            questions.forEach(Q => {
                if ((Q.id ? Q.id : Q.questionID) !== (question.id ? question.id : question.questionID)) {
                    newQuestions.push(Q);
                }
            });
            selected.push(question);
            dispatch({
                type: CREATE_ACTIVITY_MOVE_QUESTION,
                createActivityQuestions: newQuestions,
                createActivitySelectedQuestions: selected
            });
        }

        $('.openSelBox').html('View Selected Questions');
    }

    moveToQuestion(question) {
        let { dispatch } = this.props;
        let questions = this.props.manageActivities && this.props.manageActivities.createActivityQuestions ? this.props.manageActivities.createActivityQuestions : [];
        let selectedQuestions = this.props.manageActivities && this.props.manageActivities.createActivitySelectedQuestions ? this.props.manageActivities.createActivitySelectedQuestions : [];
        let newSelectedQuestions = [];
        selectedQuestions.forEach(Q => {
            if ((Q.id ? Q.id : Q.questionID) !== (question.id ? question.id : question.questionID)) {
                newSelectedQuestions.push(Q);
            }
        });
        questions.push(question);
        dispatch({
            type: CREATE_ACTIVITY_MOVE_QUESTION,
            createActivityQuestions: questions,
            createActivitySelectedQuestions: newSelectedQuestions
        });
        if (selectedQuestions.length === 0) {
            $('.openSelBox').html('View Selected Questions');
        }
    }

    viewFullParaQuestion(evt) {
        evt.persist();
        $(evt.target).parent('.quesText').toggleClass('seeFullQues', 100);
        setTimeout(function () {
            if ($(evt.target).html().trim() === 'View More')
                $(evt.target).html('View Less');
            else
                $(evt.target).html('View More');
        }, 100);
    }

    onActivityNameChange(e) {
        let context = this;
        let { dispatch } = context.props;
        this.setState({ createActivityQuestionActivityName: e.target.value });

        // console.log("debug->", this.props.manageActivities.createActivityQuestionActivityName);

        dispatch({
            type: CREATE_ACTIVITY_MOVE_QUESTION,
            createActivityQuestions: context.props.manageActivities.createActivityQuestions,
            createActivitySelectedQuestions: context.props.manageActivities.createActivitySelectedQuestions,
            createActivityQuestionActivityName: e.target.value
        });

        // this.props.manageActivities.createActivityQuestionActivityName = e.target.value;

        // console.log("debug->", this.props.manageActivities.createActivityQuestionActivityName);

    }
    onActivityDescriptionChange(e) {
        let context = this;
        let { dispatch } = context.props;
        dispatch({
            type: CREATE_ACTIVITY_MOVE_QUESTION,
            createActivityQuestions: context.props.manageActivities.createActivityQuestions,
            createActivitySelectedQuestions: context.props.manageActivities.createActivitySelectedQuestions,
            createActivityQuestionDescription: [e.target.value],
        });

    }

    onChange(e) {
        this.setState({ [e.target.id]: e.target.value });
    }

    onActivityStateChange(e) {
        let context = this;
        let { dispatch } = context.props;
        dispatch({
            type: CREATE_ACTIVITY_MOVE_QUESTION,
            createActivityQuestions: context.props.manageActivities.createActivityQuestions,
            createActivitySelectedQuestions: context.props.manageActivities.createActivitySelectedQuestions,
            createActivityQuestionActivityState: e.target.value
        });
    }

    checkEnterKeyPress(e) {
        if (e.key === 'Enter') {
            if (e.target.id === 'keywords') {
                this.inputTags(e);
            } else {
                this.selectQuestionSearchOnChangeHandler(e);
            }

        }
    }

    inputTags(event) {

        let context = this;
        let { dispatch } = context.props;
        let taggedSubjectsHtml = context.props.manageActivities && context.props.manageActivities.createActivityQuestionTaggedSubjectsHtml ? context.props.manageActivities.createActivityQuestionTaggedSubjectsHtml : [];
        let taggedKeywordsHtml = context.props.manageActivities && context.props.manageActivities.createActivityQuestionTaggedKeywordsHtml ? context.props.manageActivities.createActivityQuestionTaggedKeywordsHtml : [];
        let taggedKeywords = context.props.manageActivities && context.props.manageActivities.createActivityQuestionTaggedKeywords ? context.props.manageActivities.createActivityQuestionTaggedKeywords : [];
        let taggedSubjects = context.props.manageActivities && context.props.manageActivities.createActivityQuestionTaggedSubjects ? context.props.manageActivities.createActivityQuestionTaggedSubjects : [];


        if (event.target.id === 'keywords' && event.target.value !== '') {
            let value = event.target.value;
            let uniqueKeyForTag = "keyword" + value + "_" + Math.random() * 10000000000000000000;
            if (!taggedKeywords.includes(value)) {
                dispatch({
                    type: CREATE_ACTIVITY_MOVE_QUESTION,
                    createActivityQuestionTaggedKeywordsHtml: taggedKeywordsHtml.concat(
                        <span className="keywordTag" key={uniqueKeyForTag}>
                            <input type="hidden" name={"keyWord" + value} value={value} /> {value}
                            <span className="text-cross-icon" onClick={evt => {
                                context.deleteTags(evt, 'keyword', value);
                            }}>x</span>
                        </span>),
                    createActivityQuestionTaggedKeywords: taggedKeywords.concat(value)
                });
            }
            event.target.value = "";
        }
        else if (event.target.name === 'keywordsDrop') {
            let keyword = '';
            if (event.target.selectedOptions && event.target.selectedOptions[0]) {
                keyword = event.target.selectedOptions[0].value;
            } else {
                keyword = event.nativeEvent.srcElement.value;
            }           // let keyword = event.nativeEvent.srcElement.value;
            let uniqueKeyForSubjectTag = "keyword" + keyword + "_" + Math.random() * 10000000000000000000;
            if (keyword !== '' && keyword !== 'Select Subject') {
                if (!taggedSubjects.includes(keyword)) {
                    dispatch({
                        type: CREATE_ACTIVITY_MOVE_QUESTION,
                        createActivityQuestionTaggedSubjectsHtml: taggedSubjectsHtml.concat(
                            <span className="keywordTag" key={uniqueKeyForSubjectTag}>
                                <input type="hidden" name={"keyWord" + keyword} value={keyword} /> {keyword}
                                <span className="text-cross-icon" onClick={evt => {
                                    context.deleteTags(evt, 'subject', keyword)
                                }}>x</span>
                            </span>),
                        createActivityQuestionTaggedSubjects: taggedSubjects.concat(keyword)
                    });
                }
            }
        }
    }

    deleteTags(event, tagType, value) {

        let { dispatch } = this.props;
        if (tagType === 'keyword') {
            let records = this.props.manageActivities.createActivityQuestionTaggedKeywords;
            let htmls = this.props.manageActivities.createActivityQuestionTaggedKeywordsHtml;
            let newKeywords = [];
            let newHtmls = [];
            records.forEach(record => {
                if (value.trim().toLowerCase() !== record.trim().toLowerCase()) {
                    newKeywords.push(record);
                }
            });
            htmls.forEach(html => {
                if (html.props.children[0].props.value.trim().toLowerCase() !== value.trim().toLowerCase()) {
                    newHtmls.push(html)
                }
            });
            dispatch({
                type: CREATE_ACTIVITY_MOVE_QUESTION,
                createActivityQuestionTaggedKeywords: newKeywords,
                createActivityQuestionTaggedKeywordsHtml: newHtmls
            });
        } else {
            let records = this.props.manageActivities.createActivityQuestionTaggedSubjects;
            let htmls = this.props.manageActivities.createActivityQuestionTaggedSubjectsHtml;
            let newKeywords = [];
            let newHtmls = [];
            records.forEach(record => {
                if (value.trim().toLowerCase() !== record.trim().toLowerCase()) {
                    newKeywords.push(record);
                }
            });
            htmls.forEach(html => {
                if (html.props.children[0].props.value.trim().toLowerCase() !== value.trim().toLowerCase()) {
                    newHtmls.push(html)
                }
            });
            dispatch({
                type: CREATE_ACTIVITY_MOVE_QUESTION,
                createActivityQuestionTaggedSubjects: newKeywords,
                createActivityQuestionTaggedSubjectsHtml: newHtmls
            });
        }
    }

    getTranslationButton(currentQuestion) {
        return (<span className={classnames("toggle-en btn btn-primary", { "hidden": !this.props.auth.user.multiLingualLicense || !this.props.auth.user.prefLanguage || (this.props.auth.user.prefLanguage && this.props.auth.user.prefLanguage === 'en') })} onClick={() => { this.toggleTranslate(currentQuestion) }}> <MaterialIcon icon="translate" size={16} /> {!this.state.isTranslated ? "See English" : "See Translation"}</span>)
    }

    getQuestionPreviewDialog() {
        let context = this;
        let choicesHtml = [];

        let quesText = "";
        let choicesText = "";
        let showChoices = true;

        if (this.state.selectedQuestion[0]) {
            quesText = `<span dir lang=${this.state.isTranslated ? 'en' : this.props.auth.user.prefLanguage}>${this.state.selectedQuestion[0].question}</span>`;
            showChoices = quesText.indexOf('LP_ANSWERS') < 0;
            if (this.state.selectedQuestion[0] && this.state.selectedQuestion[0].choices) {
                for (let i = 1; i < this.state.selectedQuestion[0].choices.length + 1; i++) {
                    if (this.state.selectedQuestion[0].answer.toLowerCase() === this.state.selectedQuestion[0].choices[i - 1].toLowerCase()) {
                        choicesText += "<div class='fieldBox spacer'><input type='radio' class='css-checkbox css-label1 bc_info card_side' value='choice" + i + "' name='choice" + i + "' checked/><label htmlFor='choice" + i + "' class='css-label c-handler radGroup1' >" + i + ") &nbsp;</label>" + `<span class='text-fix' dir lang=${this.state.isTranslated ? 'en' : this.props.auth.user.prefLanguage} >${this.state.selectedQuestion[0].choices[i - 1]}</span>` + "</div>"
                        //  "<div class='fieldBox'><input type='radio' class='css-checkbox css-label1 bc_info card_side' value='both' name='choice"+i+"' checked/><label htmlFor='choice" + i + "' class='css-label radGroup1' >" + i + ") &nbsp; "+ `<span dir lang=${this.state.isTranslated ? 'en' : this.props.auth.user.prefLanguage}>${this.state.selectedQuestion[0].choices[i-1]}</span>` +"</label></div>"
                    } else {
                        choicesText += "<div class='fieldBox spacer'><input type='radio' class='css-checkbox css-label1 bc_info card_side' value='choice" + i + "' name='choice" + i + "' /><label htmlFor='choice" + i + "' class='css-label c-handler radGroup1' >" + i + ") &nbsp;</label>" + `<span class='text-fix' dir lang=${this.state.isTranslated ? 'en' : this.props.auth.user.prefLanguage} >${this.state.selectedQuestion[0].choices[i - 1]}</span>` + "</div>"
                        // choicesText += "<div class='fieldBox spacer'><input type='radio' class='css-checkbox css-label1 bc_info card_side' value='both' name='choice"+i+"'/><label htmlFor='choice" + i + "' class='css-label radGroup1' >" + i + ") &nbsp; "+ `<span dir lang=${this.state.isTranslated ? 'en' : this.props.auth.user.prefLanguage}>${this.state.selectedQuestion[0].choices[i-1]}</span>` +"</label></div>"
                    }
                }
            }
            quesText = quesText.replace('[[[LP_ANSWERS]]]', choicesText);
        }
        let htmlToReactParser = new Parser();

        return <Modal id="QuestionPreviewModal"
            show={this.state.showQuestionPreview}
            onHide={() => {
                let that = context;
                if (context.state.seeTranslation && context.state.seeTranslation.question) {
                    context.state.selectedQuestion[0].question = context.state.seeTranslation.question;
                    that.setState({ seeTranslation: null, seeEnglish: null, isTranslated: false })
                } else {
                    // context.state.selectedQuestion[0].question;
                    // context.state.selectedQuestion[0];
                }
                this.setState({
                    showQuestionPreview: false
                })
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title>Preview Question</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div dir lang={this.state.isTranslated ? "en" : this.props.auth.user.prefLanguage} id="questionPreviewBox">
                    {this.state.selectedQuestion[0] && htmlToReactParser.parse(quesText)}
                    <br /><br />
                    {showChoices && htmlToReactParser.parse(choicesText)}
                    <div className="row">
                        {this.getTranslationButton(this.state.selectedQuestion[0], this.state.isTranslated)}
                    </div>
                </div>
            </Modal.Body>
        </Modal>;
    }


    toggleTranslate(ques) {
        if (this.state.seeTranslation && this.state.seeEnglish) {
            if (this.state.isTranslated) {
                // $(".langConfigured").prop('lang', `${this.props.auth.user.prefLanguage}`);
                ques.choices = this.state.seeTranslation.choices
                ques.question = this.state.seeTranslation.question
            } else {
                // $(".langConfigured").prop('lang', 'en');
                ques.choices = this.state.seeEnglish.choices
                ques.question = this.state.seeEnglish.question
            }
            this.setState({ isTranslated: !this.state.isTranslated });
        } else {
            this.showIndicator();
            this.setState({ 'seeTranslation': JSON.parse(JSON.stringify(ques)) }, () => {
                const params = {
                    questionid: ques.id,
                    isAssessment: !this.state.isPractice
                };
                const method = 'GET';
                const pathTemplate = '/getQuestionById/{questionid}/{isAssessment}';
                apiClient.invokeApi(params, pathTemplate, method, {}, {}, 6).then((result) => {
                    // $(`:lang(${this.props.auth.user.prefLanguage})`).addClass('langConfigured').prop('lang', 'en');
                    const { question, explanation, answer, hint, choices } = result.data;
                    ques.question = question ? question : "Translation not avaliable"//ques.question;
                    ques.choices = choices ? choices : "Translation not avaliable"//ques.choices;
                    this.setState({ seeEnglish: result.data, isTranslated: true });
                    this.hideIndicator();
                }, (err) => {
                    console.error(err);
                    this.setState({
                        showAlert: true,
                        alertStyle: 'danger',
                        alertText: 'Cannot translate at the moment. Please try again later.'
                    });
                    setTimeout(() => {
                        this.setState({ showAlert: false });
                    }, 5000);
                    this.hideIndicator();
                });
            });
        }
    }

    getPreviewDialog() {

        let questions = "";
        let kCount = 0;
        let sCount = 0;
        let qCount = 0;
        let htmlToReactParser = new Parser();
        if (this.props.manageActivities && this.props.manageActivities.createActivitySelectedQuestions) {
            for (let i = 1; i < this.props.manageActivities.createActivitySelectedQuestions.length + 1; i++) {
                let parser = new DOMParser();
                let quesText = "";
                let choicesText = "";
                let showChoices = true;
                if (this.props.manageActivities.createActivitySelectedQuestions[i - 1]) {
                    quesText = i + ")  " + `<span dir lang=${this.state.isTranslated ? 'en' : this.props.auth.user.prefLanguage}>${this.props.manageActivities.createActivitySelectedQuestions[i - 1].question}</span>`
                    showChoices = quesText.indexOf('LP_ANSWERS') < 0;
                    if (this.props.manageActivities.createActivitySelectedQuestions[i - 1] && this.props.manageActivities.createActivitySelectedQuestions[i - 1].choices) {
                        for (let j = 1; j < this.props.manageActivities.createActivitySelectedQuestions[i - 1].choices.length + 1; j++) {
                            choicesText += "<div class='fieldBox'><label>" + j + ") &nbsp; " + `<span dir lang=${this.state.isTranslated ? 'en' : this.props.auth.user.prefLanguage}>${this.props.manageActivities.createActivitySelectedQuestions[i - 1].choices[j - 1]}</span>` + "</label></div>"
                        }
                    }
                    quesText = quesText.replace('[[[LP_ANSWERS]]]', choicesText);

                    if (showChoices) {
                        quesText += choicesText;
                    }

                    questions += "<div class='QuestionChoiceBox  width_100' key='question-preview-" + i + "'><p class='quesText'  id=" + this.props.manageActivities.createActivitySelectedQuestions[i - 1].id + ">" + quesText + "</p><div class='row'></div></div>";
                }

                qCount++;
            }
        }
        let name = this.props.manageActivities && this.props.manageActivities.createActivityQuestionActivityName ? this.props.manageActivities.createActivityQuestionActivityName : '';

        return <Modal id="PreviewModal"
            show={this.state.showPreviewDialog}
            onHide={() => {
                this.setState({
                    showPreviewDialog: false
                })
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title
                    id="delete-activity-modal">{name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div dir lang={this.state.isTranslated ? "en" : this.props.auth.user.prefLanguage} id="questionPreviewBox">
                    {htmlToReactParser.parse(questions)}
                    <div className="row"></div>
                </div>
            </Modal.Body>
        </Modal>;
    }

    selectRandomQuestions(count, type) {

        let esAttr = this.props.elasticSearchAttributes.map.get("create-question-activity");
        let elasticSearchQuery = new ElasticSearchQuery();
        let that = this;
        elasticSearchQuery.random(ElasticSearchTypes.Question + config.stage, ElasticSearchColumns.Question, this.props,
            esAttr.searchTerm, count, esAttr.filterValues, type, function (results, totalRecords) {
                let selected = JSON.parse(JSON.stringify(results));
                for (let i = 0; i < selected.length; i++) {
                    setTimeout(() => {
                        that.moveToSelectedQuestion(selected[i]);
                    }, 200);
                }
            });
    }

    openSelected() {
        let ctx = this;
        $('.openSelBox').click(function (evt) {
            $('.questionSelectedBox').addClass('showSelQuesTab');
            $(evt.target).addClass('closeSelBox');
            $(evt.target).html('Select Questions');
            if ($('.openSelBox').hasClass('closeSelBox'))
                ctx.openQuestions();
        });

    }

    openQuestions() {
        let ctx = this;
        $('.closeSelBox').click(function (evt) {
            $('.questionSelectedBox').removeClass('showSelQuesTab');
            $(evt.target).removeClass('closeSelBox');
            $(evt.target).html('View Selected Questions');
            ctx.openSelected();
        })
    }

    getRandomQuestionBoxDialog() {
        let body = <div className="randomSelection">
            <p>Select Question Type and the number of random questions to add to the activity</p>
            <div className="inputs">
                <select id="randomQuestionSelect">
                    <option value='Multiple Choice'>Multiple Choice</option>
                    <option value='Essay'>Essay</option>
                </select>
                <input className="form-control" type="text" id="randomQuestionInput" maxLength="4" />
            </div>
        </div>;
        let that = this;
        return <Modal
            show={this.state.showRandomQuesionDialogBox}
            onHide={() => {
                this.setState({ showRandomQuesionDialogBox: false });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Select Random Questions</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    that.setState({ showRandomQuesionDialogBox: false });
                }}>Cancel</Button>
                <Button className="btn btn-primary crtBtn" onClick={() => {
                    this.setState({ showRandomQuesionDialogBox: false });
                    let count = $("#randomQuestionInput").val();
                    let type = $("#randomQuestionSelect").val();
                    if (count && type && Validator.isInt(count)) {
                        this.selectRandomQuestions(count, type);
                    }
                }}>Select Questions</Button>
            </Modal.Footer>
        </Modal>;
    }

    getSearchTipBox() {
        let body = <div>
            <div className="row">
                <div className="col-md-4 col-lg-4 col-sm-4">
                    <div className="subjectTip" />
                </div>
                <div className="col-md-4 col-lg-4 col-sm-4">
                    <div className="catTip" />
                </div>
                <div className="col-md-4 col-lg-4 col-sm-4">
                    <div className="subcatTip" />
                </div>
            </div>
            <div className="row">
                <div className="col-md-4 col-lg-4 col-sm-4 text-center">
                    Select the subject you want to view questions from
                </div>
                <div className="col-md-4 col-lg-4 col-sm-4 text-center">
                    Narrow your results by selecting one or more categories under Refine Search
                </div>
                <div className="col-md-4 col-lg-4 col-sm-4 text-center">
                    Under selected categories, select your subcategories
                </div>
            </div>


        </div>;
        return <Modal id="SearchTipModal"
            show={this.state.showSearchTipBox}
            onHide={() => {
                this.setState({ showSearchTipBox: false });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Search Tips</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <input type="checkbox" id='searchtipBoxCheckBox' className="css-checkbox" />
                <label htmlFor='searchtipBoxCheckBox' className="pay_label css-label1">Do not show again</label>
                <Button className="btn btn-primary crtBtn" onClick={() => {
                    this.setState({ showSearchTipBox: false });
                    this.saveSearchTipBoxInfo();
                }}>Ok</Button>
            </Modal.Footer>
        </Modal>;
    }

    getCreateActivityInfoDialog() {
        const { errors } = this.state;
        let generatedKeywords = this.generateTagHtml(this.props.manageActivities && this.props.manageActivities.createActivityQuestionTaggedKeywords ? this.props.manageActivities.createActivityQuestionTaggedKeywords : [], 'keyword');
        let generatedSubjects = this.generateTagHtml(this.props.manageActivities && this.props.manageActivities.createActivityQuestionTaggedSubjects ? this.props.manageActivities.createActivityQuestionTaggedSubjects : [], 'subject');
        let allSubjects = [];
        this.props.auth.user.subjects.forEach(sub => {
            allSubjects.push({ "id": sub, "name": sub });
        });
        allSubjects.sort(function (a, b) {
            return a.id.localeCompare(b.id, undefined, {
                numeric: true,
                sensitivity: 'base'
            });
        });

        let activityStates = [
            {
                "id": "In Progress",
                "name": "In Progress"
            },
            {
                "id": "In Review",
                "name": "In Review"
            }
        ];
        if (this.props.auth.user.userType.toLowerCase() === "admin") {
            activityStates.push({
                "id": "Published",
                "name": "Published"
            })
        }
        let body = <div className="mainFormBox">
            <div className="col-lg-12 col-md-12">
                <TextFieldGroup
                    error={errors.activityName}
                    isDisabled={this.props.params.id}
                    label="Activity Name:"
                    field="activityName"
                    value={this.state.createActivityQuestionActivityName || this.props.manageActivities.createActivityQuestionActivityName}
                    placeholder="Enter Activity Name"
                    onChange={this.onActivityNameChange}
                />
                <div className="form-group">
                    <label htmlFor="description">Description:</label>
                    <div className="posRel selectWidth">
                        <div className="ui-widget">
                            <textarea id="description" name="description" className="form-control commentareaField"
                                placeholder="Enter Description" onChange={this.onActivityDescriptionChange} value={this.props.manageActivities.createActivityQuestionDescription} />
                        </div>
                    </div>
                </div>
                {/*<div className="form-group">*/}
                {/*<label htmlFor="selectUser">Keywords:</label>*/}
                {/*<div className="posRel  selectWidth ">*/}
                {/*<div className="ui-widget">*/}
                {/*<input id="keywords" className="fieldBg keyWords form-control"*/}
                {/*onKeyUp={this.checkEnterKeyPress} onBlur={this.inputTags}*/}
                {/*data-input-feild="tags" placeholder="Enter Keyword"/>*/}
                {/*<div className="appendDiv">*/}
                {/*{generatedKeywords}*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*</div>*/}
                <SelectFieldGroup
                    error={errors.subjectError}
                    label="Subject:"
                    field="keywordsDrop"
                    value={this.state.keywordsDrop}
                    options={allSubjects}
                    placeholder="Associate with Subjects"
                    onChange={this.inputTags}
                    multiSelect={generatedSubjects}
                />
                {this.props.auth.user.schoolName === "LearnerPal" &&
                    <SelectFieldGroup
                        error={errors.activityState}
                        label="Activity State:"
                        field="activityState"
                        value={this.props.manageActivities.createActivityQuestionActivityState}
                        options={activityStates}
                        onChange={this.onActivityStateChange}
                    />
                }
            </div>
            <div className="row" />
        </div>;
        return <Modal
            show={this.state.showCreateInfoDialog}
            onHide={() => {
                this.setState({ showCreateInfoDialog: false }, function () {
                    browserHistory.push({
                        pathname: '/activities',
                        state: { isActivityTypeLandingPage: true }
                    });
                });
            }}
            keyboard={false}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Activity Info</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    this.setState({ showCreateInfoDialog: false }, function () {
                        browserHistory.push({
                            pathname: '/activities',
                            state: { isActivityTypeLandingPage: true }
                        });
                    });
                }}>Cancel</Button>
                <Button className="btn btn-primary crtBtn" onClick={() => {
                    let that = this;
                    let questions = this.props.manageActivities.createActivitySelectedQuestions ? this.props.manageActivities.createActivitySelectedQuestions : [];
                    let qIds = [];
                    questions.forEach(q => {
                        qIds.push(q.id ? q.id : q.questionID);
                    });
                    let data = {
                        orderBy: '1',
                        schoolName: this.props.auth.user.schoolName,
                        ownerName: this.props.auth.user.userName,
                        activityType: 'question',
                        activityName: this.props.manageActivities.createActivityQuestionActivityName ? this.props.manageActivities.createActivityQuestionActivityName : '',
                        tags: this.props.manageActivities.createActivityQuestionTaggedKeywords ? this.props.manageActivities.createActivityQuestionTaggedKeywords : [],
                        subjects: this.props.manageActivities.createActivityQuestionTaggedSubjects ? this.props.manageActivities.createActivityQuestionTaggedSubjects.sort() : [],
                        activityState: this.props.manageActivities.createActivityQuestionActivityState ? this.props.manageActivities.createActivityQuestionActivityState : 'In Progress',
                        questions: qIds
                    };
                    if (that.props.manageActivities.createActivityQuestionDescription) {
                        data.description = this.props.manageActivities.createActivityQuestionDescription;
                    }

                    if (that.props.params.id) {
                        data.activityID = this.props.params.id;
                    }
                    if (that.isValid(data)) {
                        this.setState({ showCreateInfoDialog: false });
                    }
                }}>Next</Button>
            </Modal.Footer>
        </Modal>;

    }

    showCreateActivityDialog() {
        this.setState({ showCreateInfoDialog: true });
    }

    saveSearchTipBoxInfo() {

        let check = $('#searchtipBoxCheckBox').prop('checked');
        if (check === true) {
            localStorage.setItem("dontShowAgain" + this.props.auth.user.userName, 'true');
        }
    }
    render() {
        let parser = new DOMParser();
        this.filterComponentCssClassName = 'filterBlock custScroll default-skin';

        let questions = [];
        let selectedQuestions = [];
        let context = this;
        if (this.props.manageActivities && this.props.manageActivities.createActivityQuestions) {
            let activitiesQuestion = this.props.manageActivities.createActivityQuestions;
            // activitiesQuestion.sort((a,b) => b.id - a.id)
            let skipQuestions = this.props.manageActivities && this.props.manageActivities.createActivitySelectedQuestions ? this.props.manageActivities.createActivitySelectedQuestions : [];
            for (let i = 0; i < activitiesQuestion.length; i++) {
                let question = activitiesQuestion[i];


                if (context.props.manageActivities.createActivitySelectedQuestions && context.props.manageActivities.createActivitySelectedQuestions.filter(sq => sq.id == question.id).length != 0) {
                    continue;
                }


                let questionToDisplay = question.question.replace('&nbsp;', '');
                while (questionToDisplay.indexOf('<br>') >= 0) {
                    questionToDisplay = questionToDisplay.replace('<br>', '');
                }
                let choicesText = "";
                if (question.choices) {
                    for (let i = 1; i < question.choices.length + 1; i++) {
                        if (question.answer && question.answer.toLowerCase() === question.choices[i - 1].toLowerCase()) {
                            choicesText += "<div class='fieldBox answer'>" + question.choices[i - 1] + "</div>"
                        } else {
                            choicesText += "<div class='fieldBox'>" + question.choices[i - 1] + "</div>";
                        }
                    }
                }
                let htmlToReactParser = new Parser();
                questionToDisplay = questionToDisplay.replace(/&nbsp;/g, " ");
                questionToDisplay = questionToDisplay.replace(/<br>/g, " ");
                questionToDisplay = questionToDisplay.replace(/<br\/>/g, " ");
                questionToDisplay = questionToDisplay.replace('[[[LP_ANSWERS]]]', '');
                questionToDisplay = questionToDisplay.replace(/<img .*?>/g, "");
                questionToDisplay = questionToDisplay.replace(/<img .*?\/>/g, "");
                questionToDisplay = questionToDisplay.replace(/<IMG .*?>/g, "");
                questionToDisplay = questionToDisplay.replace(/<IMG .*?\/>/g, "");
                questionToDisplay = questionToDisplay.replace(/<div id=\"IFRAME_DIV\">/g, "");
                questionToDisplay = questionToDisplay.replace(/<\/div>/g, "");
                questionToDisplay = questionToDisplay.replace(/<span .*?>/g, "");
                questionToDisplay = questionToDisplay.replace(/<\/span>/g, "");
                questionToDisplay = questionToDisplay.replace(/<strong>/g, "");
                questionToDisplay = questionToDisplay.replace(/<\/strong>/g, "");
                questionToDisplay = questionToDisplay.replace(/<h1>/g, "");
                questionToDisplay = questionToDisplay.replace(/<\/h1>/g, "");
                questionToDisplay = questionToDisplay.replace(/<h2.*?>/g, "");
                questionToDisplay = questionToDisplay.replace(/<\/h2>/g, "");
                questionToDisplay = questionToDisplay.replace(/<h5>/g, "");
                questionToDisplay = questionToDisplay.replace(/<\/h5>/g, "");
                questionToDisplay = questionToDisplay.replace(/<p .*?>/g, "");
                questionToDisplay = questionToDisplay.replace(/<\/p>/g, "");

                while (questionToDisplay.indexOf("size=\"4\"") >= 0) {
                    questionToDisplay = questionToDisplay.replace("size=\"4\"", "");
                }
                while (questionToDisplay.indexOf("size=\"3\"") >= 0) {
                    questionToDisplay = questionToDisplay.replace("size=\"3\"", "");
                }
                while (questionToDisplay.indexOf("size='4'") >= 0) {
                    questionToDisplay = questionToDisplay.replace("size='4'", "");
                }
                while (questionToDisplay.indexOf("size='3'") >= 0) {
                    questionToDisplay = questionToDisplay.replace("size='3'", "");
                }

                let startTagIndex = questionToDisplay.indexOf('<div id="IFRAME_DIV"');
                if (startTagIndex >= 0) {
                    let endTagIndex = questionToDisplay.indexOf('>', startTagIndex)
                    questionToDisplay = questionToDisplay.substring(0, startTagIndex) + '<div id="IFRAME_DIV">' + questionToDisplay.substring(endTagIndex + 1);
                }

                questionToDisplay = questionToDisplay.replace(/<div id="IFRAME_DIV" style="maxheight: 300px; height: 300px; vertical-align: top; overflow: auto; border-style: solid; border-width: 1px; display: inline-block; width: 95%; margin: 2px ;">/g, "<div id=\"IFRAME_DIV\" >");

                if (questionToDisplay === "") {
                    questionToDisplay = "<font>&nbsp;</font>"
                }

                let text = htmlToReactParser.parse('<span>' + questionToDisplay + '</span>');
                let notes = question.notes ? htmlToReactParser.parse('<span>' + question.notes + '</span>') : question.notes;
                let chkbox = "çheckbox-id-" + i;

                let tags = [];
                if (question.tags) {
                    question.tags.forEach(tag => {
                        tags.push(<li key={tag + Math.random()}>{tag}</li>)
                    });
                }

                //if learnerpal teacher .. show all the questions with all the states ..
                //let canShowQuestion = question.owner === context.props.auth.user.userName;

                let ownerName = question.ownerName.trim().toLowerCase();
                let activityOwnerFilter = context.props.manageActivities.createActivityActiveOwnerFilter.trim().toLowerCase();
                //let canShowQuestion = question.ownerName && (ownerName === activityOwnerFilter || ownerName === context.props.auth.user.userName);

                let canShowQuestion = question.visibleTo && (question.visibleTo.includes("public") || question.visibleTo.includes(context.props.auth.user.userName));

                if (context.props.auth.user.schoolName === "LearnerPal") {
                    canShowQuestion = true;
                }

                if (!skipQuestions.includes(question) && canShowQuestion) {

                    let subjects = [];
                    if (question.subjects) {
                        question.subjects.forEach(function (sub) {
                            subjects.push(<li key={sub}>{sub}</li>);
                        })
                    }
                    let randomQid = Math.ceil(Math.random() * 10000);
                    // const spreadRef = (i === activitiesQuestion.length-1) ? { ref: 'lastQuestion' } : {};
                    const spreadRef = (i === activitiesQuestion.length - this.perPageLimit) ? { ref: 'lastQuestion' } : {};
                    questions.push(
                        <div className="fieldBox QuestionChoiceBox width_100" key={'question-id-' + randomQid + (question.id ? question.id : question.questionID)} {...spreadRef}>
                            <input type="checkbox" id={chkbox} className="css-checkbox select_question"
                                name="authorize" onClick={function () {
                                    context.moveToSelectedQuestion(question, this);
                                }} />
                            <label htmlFor={chkbox} className="pay_label css-label1" />
                            <p lang={this.state.isTranslated ? 'en' : this.props.auth.user.prefLanguage} className="quesText" id={(question.id ? question.id : question.questionID)}>{text ? <h5 id={(question.id ? question.id : question.questionID)}>{text}</h5> : <h5 id={(question.id ? question.id : question.questionID)} style={{ 'fontStyle': 'italic' }}>No preview available for the question. Click to view the question</h5>}
                            </p>
                            <p className="text-right"><span className="choices" id={(question.id ? question.id : question.questionID)} onClick={this.questionPreviewOnClickHandler} /></p>
                            <ul className="keyCourse">
                                <li>{question.questionType}</li>
                                {subjects}
                                <li>{question.category}</li>
                                {tags}
                                <ul>
                                    {notes && <li>{notes}</li>}
                                    <li>{"ID#" + (question.id ? question.id : question.questionID)}</li>
                                </ul>

                            </ul>

                        </div>
                    )
                }
            }
            !this.state.allQuestionsAreLoaded && (questions.length > 0 ? questions.push(<div key={'Load More'} style={{ textAlign: 'center', paddingTop: '22px', display: 'inline-block', width: '100%' }}>
                <a href="javascript:void 0" onClick={function () { context.getNextPage() }} style={{
                    border: '1px solid',
                    borderRadius: '3px',
                    'padding': ' 5px 30px'
                }}>Load More</a>
            </div>) : questions.push(<div className='fieldBox QuestionChoiceBox width_100' key={'No Data Found'} style={{ textAlign: 'center' }}>No Data Found</div>)
            );
        }

        if (this.props.manageActivities && this.props.manageActivities.createActivitySelectedQuestions) {

            for (let i = 0; i < this.props.manageActivities.createActivitySelectedQuestions.length; i++) {

                let question = this.props.manageActivities.createActivitySelectedQuestions[i];

                let questionToDisplay = question.question.replace('&nbsp;', '');
                while (questionToDisplay.indexOf('<br>') >= 0) {
                    questionToDisplay = questionToDisplay.replace('<br>', '');
                }
                let choicesText = "";
                if (question.choices) {
                    for (let i = 1; i < question.choices.length + 1; i++) {
                        if (question.answer.toLowerCase() === question.choices[i - 1].toLowerCase()) {
                            choicesText += "<div class='fieldBox answer'>" + question.choices[i - 1] + "</div>"
                        } else {
                            choicesText += "<div class='fieldBox'>" + question.choices[i - 1] + "</div>";
                        }
                    }
                }
                let htmlToReactParser = new Parser();
                questionToDisplay = questionToDisplay.replace(/&nbsp;/g, " ");
                questionToDisplay = questionToDisplay.replace(/<br>/g, " ");
                questionToDisplay = questionToDisplay.replace(/<br\/>/g, " ");
                questionToDisplay = questionToDisplay.replace('[[[LP_ANSWERS]]]', '');
                questionToDisplay = questionToDisplay.replace(/<img .*?>/g, "");
                questionToDisplay = questionToDisplay.replace(/<img .*?\/>/g, "");
                questionToDisplay = questionToDisplay.replace(/<IMG .*?>/g, "");
                questionToDisplay = questionToDisplay.replace(/<IMG .*?\/>/g, "");
                questionToDisplay = questionToDisplay.replace(/<div id=\"IFRAME_DIV\">/g, "");
                questionToDisplay = questionToDisplay.replace(/<\/div>/g, "");
                questionToDisplay = questionToDisplay.replace(/<span .*?>/g, "");
                questionToDisplay = questionToDisplay.replace(/<\/span>/g, "");
                questionToDisplay = questionToDisplay.replace(/<strong>/g, "");
                questionToDisplay = questionToDisplay.replace(/<\/strong>/g, "");
                questionToDisplay = questionToDisplay.replace(/<h1>/g, "");
                questionToDisplay = questionToDisplay.replace(/<\/h1>/g, "");
                questionToDisplay = questionToDisplay.replace(/<h2.*?>/g, "");
                questionToDisplay = questionToDisplay.replace(/<\/h2>/g, "");
                questionToDisplay = questionToDisplay.replace(/<h5>/g, "");
                questionToDisplay = questionToDisplay.replace(/<\/h5>/g, "");
                questionToDisplay = questionToDisplay.replace(/<p .*?>/g, "");
                questionToDisplay = questionToDisplay.replace(/<\/p>/g, "");
                while (questionToDisplay.indexOf("size=\"4\"") >= 0) {
                    questionToDisplay = questionToDisplay.replace("size=\"4\"", "");
                }
                while (questionToDisplay.indexOf("size=\"3\"") >= 0) {
                    questionToDisplay = questionToDisplay.replace("size=\"3\"", "");
                }
                while (questionToDisplay.indexOf("size='4'") >= 0) {
                    questionToDisplay = questionToDisplay.replace("size='4'", "");
                }
                while (questionToDisplay.indexOf("size='3'") >= 0) {
                    questionToDisplay = questionToDisplay.replace("size='3'", "");
                }

                let startTagIndex = questionToDisplay.indexOf('<div id="IFRAME_DIV"');
                if (startTagIndex >= 0) {
                    let endTagIndex = questionToDisplay.indexOf('>', startTagIndex)
                    questionToDisplay = questionToDisplay.substring(0, startTagIndex) + '<div id="IFRAME_DIV">' + questionToDisplay.substring(endTagIndex + 1);
                }

                questionToDisplay = questionToDisplay.replace(/<div id="IFRAME_DIV" style="maxheight: 300px; height: 300px; vertical-align: top; overflow: auto; border-style: solid; border-width: 1px; display: inline-block; width: 95%; margin: 2px ;">/g, "<div id=\"IFRAME_DIV\" >");

                if (questionToDisplay === "") {
                    questionToDisplay = "<font>&nbsp;</font>"
                }

                let text = htmlToReactParser.parse('<span>' + questionToDisplay + '</span>');
                let notes = question.notes ? htmlToReactParser.parse('<span>' + question.notes + '</span>') : question.notes;

                let chkbox = "çheckbox-selected-id-" + i;

                let tags = [];

                if (question.tags) {
                    question.tags.forEach(tag => {
                        tags.push(<li key={tag + Math.random()}>{tag}</li>)
                    });
                }
                let subjects = []
                if (question.subjects) {
                    question.subjects.forEach(function (sub) {
                        subjects.push(<li key={sub}>{sub}</li>);
                    })
                }
                selectedQuestions.push(
                    <div className="fieldBox QuestionChoiceBox width_100" key={'selected-question-id-' + (question.id ? question.id : question.questionID)}>
                        <input defaultChecked type="checkbox" id={chkbox} className="css-checkbox select_question"
                            name="authorize" onClick={function () {
                                context.moveToQuestion(question);
                            }}
                        />
                        <label htmlFor={chkbox} className="pay_label css-label1" />
                        <p lang={this.props.auth.user.prefLanguage} className="quesText" id={(question.id ? question.id : question.questionID)}>
                            {text ? <h5 id={(question.id ? question.id : question.questionID)}>{text}</h5>
                                : <h5 id={(question.id ? question.id : question.questionID)} style={{ 'font-style': 'italic' }}>
                                    No preview available for the question. Click to view the question</h5>}
                        </p>
                        <p className="text-right"><span className="choices" id={(question.id ? question.id : question.questionID) + ":selectedQuestions"} onClick={this.questionPreviewOnClickHandler} /></p>
                        <ul className="keyCourse">
                            <li>{question.questionType}</li>
                            {subjects}
                            <li>{question.category}</li>
                            {tags}
                            <ul>
                                {notes && <li>{notes}</li>}
                                <li>{"ID#" + (question.id ? question.id : question.questionID)}</li>
                            </ul>

                        </ul>
                    </div>
                )
            }
        }

        let selectedCount = this.props.manageActivities && this.props.manageActivities.createActivitySelectedQuestions ? this.props.manageActivities.createActivitySelectedQuestions.length : 0;
        let createActivityQuestionActivityName = this.props.manageActivities.createActivityQuestionActivityName;

        let readonlyField = <div className="form-group"><label className="control-label">Activity Name:</label>
            <div
                className="posRel fieldBg selectWidth">{this.props.manageActivities.createActivityQuestionActivityName}</div>
        </div>;
        let randomSelectionCount = selectedQuestions.length;// === 0 ? # : '';

        if (randomSelectionCount === 0 && this.props.manageActivities.createActivityQuestions) {
            //   $('#randomQuestionSelectionButton').removeClass('disableLink');
        }
        else {
            //  $('#randomQuestionSelectionButton').addClass('disableLink');
        }
        return (
            <div>
                {this.getSearchTipBox()}
                {this.getRandomQuestionBoxDialog()}
                <Breadcrumb
                    breadcrumbsData={
                        [
                            {
                                name: 'Activities', action: () => {
                                    browserHistory.push('/activities');
                                }
                            },
                            {
                                name: 'Create Activity', action: () => {
                                    browserHistory.push({
                                        pathname: '/activities',
                                        state: { isActivityTypeLandingPage: true }
                                    });
                                }
                            },
                            {
                                name: this.props.params && this.props.params.id ? 'Edit Question Activity' : 'Create Question Activity', action: () => {
                                }
                            }
                        ]
                    }
                />
                <span id="expColBtn" className="glyphicon btn-lg collapseBtn glyphicon-menu-down" aria-hidden="true" onClick={this.showCreateActivityDialog} />
                <div className="addQuestionBlock" style={{ display: 'block' }}>
                    <div className="row hidden-md hidden-lg sercFilBtnBox">
                        <div className="col-xs-6 noPad">
                            <button className="ViewFilter-sm" onClick={function () {
                                $('.filterBlock').addClass('TopZero');
                                $('html').css({ 'overflow': 'hidden', 'position': 'fixed' });
                                $('.footer ').hide();
                            }}><span>Filter</span></button>
                        </div>
                        <div className="col-xs-6 noPad">
                            <button className="ViewSearch-sm" onClick={function () {
                                $('.search-sm-Box').addClass('TopZero');
                                $('html').css({
                                    'overflow': 'hidden',
                                    'position': 'fixed',
                                    'width': '100%',
                                    'height': '100%'
                                });
                            }}><span>Search</span></button>
                        </div>
                        <div className="search-sm-Box">
                            <span className="srcHideBox" onClick={function () {
                                $('.search-sm-Box').removeClass('TopZero');
                                $('html').css({ 'overflow': 'auto', 'position': 'inherit' });
                            }} />
                            <div className="input-group">
                                <input type="text" className="form-control searchBar" placeholder="Search"
                                    id="searchBarIdX" />
                                <span className="input-group-btn">
                                    <button className="btn btn-primary" type="button" onClick={function () {
                                        context.selectQuestionSearchOnChangeHandler({ nativeEvent: { target: { value: $("#searchBarIdX").val() } } });
                                        $('.search-sm-Box').removeClass('TopZero');
                                        $('html').css({ 'overflow': 'auto', 'position': 'inherit' });
                                    }}><span className="searchBtn" /></button>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="filterBlock custScroll default-skin" id="default-skin-demo">
                        <Filter
                            setInstance={(instance)=> { this.child = instance; }}
                            location="create-question-activity"
                            actionName={CREATE_ACTIVITY_FETCH_QUESTION_SUCCESS}
                            viewActivityCssClass={this.filterComponentCssClassName}
                            clearFilters={'manageActivities.createActivityQuestionClearFilters'}
                            clearFiltersForSearch={'manageActivities.createActivityQuestionClearFiltersForSearch'}
                            refreshFilters={'manageActivities.refreshFilters'}
                            showLoader={this.showIndicator}
                            hideLoader={this.hideIndicator}
                            screenNameForElasticSearch={ElasticSearchTypes.Question}
                            allColumnsForElasticSearch={ElasticSearchColumns.Question}
                            filterColumns={[
                                { name: 'subjects', type: 'array', label: 'Subject', dependency: 'category' },
                                { name: 'category', type: 'text', label: 'Category', dependency: 'subcategory', dependent: true, refreshFilter: true },
                                { name: 'subcategory', type: 'text', label: 'SubCategory', dependent: true },
                                { name: 'questionType', type: 'text', label: 'Question Type', sort: 'desc' }
                            ]}
                            onlyPublishedQuestion
                        />
                    </div>
                    <div className="selectQuestionBox custScroll default-skin">
                        <div className="select-question-heading-container">
                            <h2 className="select-question-search-h2">Select Questions</h2>
                            <div className="input-group filterSearchLeft select-question-search">
                                <input type="text" className="form-control" placeholder="Search" ref={'searchRef'}
                                    onKeyPress={this.checkEnterKeyPress} onChange={(evt) => {
                                        if (evt.target.value === '') {
                                            this.selectQuestionSearchOnChangeHandler(evt);
                                        }
                                    }} />
                                <span className="input-group-btn">
                                </span>
                            </div>
                        </div>
                        <ul className="quesBcrumb">
                            <style>{`
                            .makeMeBold {
                                color: #0f6fb4 !important;
                                font-weight: bold !important;
                                text-decoration: underline !important;
                            }
                            `}</style>
                            <li><a href="javascript:void 0" className={this.props.manageActivities.createActivityActiveOwnerFilter === 'LearnerPal' ? 'makeMeBold' : {}} onClick={this.selectQuestionLearnerPalOnClickHandler}>
                                LearnerPal Questions</a></li>
                            <li><a href="javascript:void 0" className={this.props.manageActivities.createActivityActiveOwnerFilter !== 'LearnerPal' ? 'makeMeBold' : {}} onClick={this.selectQuestionMyQuestionOnClickHandler}>My
                                Questions</a></li>
                            {this.props.manageActivities && this.props.manageActivities.createActivityQuestions &&
                                <li style={{ float: 'right' }}><a id="randomQuestionSelectionButton" href="javascript:void 0" onClick={this.showRandomQuestionDialog} style={{
                                    border: '1px solid',
                                    borderRadius: '3px',
                                    'padding': ' 5px'
                                }}>Select Random Questions</a></li>
                            }
                        </ul>
                        {this.props.manageActivities && (this.props.manageActivities.createActivityQuestions || this.props.manageActivities.createActivitySelectedQuestions) &&
                            <div className="boxForQuestionSelect" id="sel_before" ref="questionSelectionBoxRef">
                                {questions}
                                {/*<ReactScrollPagination*/}
                                {/*fetchFunc={this.getNextPage}*/}
                                {/*/>*/}

                            </div>
                        }
                        {this.props.manageActivities && !this.props.manageActivities.createActivityQuestions && !this.props.manageActivities.createActivitySelectedQuestions &&
                            <div className=" boxForQuestionSelect" id="sel_before" ref="questionSelectionBoxRef">
                                <p className="ques_act_start_message">
                                    Browse for questions to add to your activity
                                    <br /><br />
                                    Search by keyword<br />
                                    Or<br />
                                    Select a Subject, and refine by Category and Subcategory<br /><br />

                                </p>
                            </div>
                        }
                        <div className="lastBlockEmptyLeft">
                            <div className="pull-right">
                            </div>
                        </div>
                    </div>
                    <div className="questionSelectedBox custScroll default-skin">
                        <h2 className="select-question-search-h2">Questions Selected <span className="countResFix">
                            <a className="openSelBox">View Selected Questions</a>

                            <span className="selectedCount">{selectedCount}</span>
                        </span>
                        </h2>
                        <div className="selQuestions">
                            <h4 className="selectedHeadingActivity">{createActivityQuestionActivityName}</h4>
                            <ul className="quesBcrumb">
                                <li style={{ float: 'right' }}><a href="javascript:void 0" data-toggle="modal" data-target="#PreviewModal" onClick={this.selectedQuestionPreviewOnClickHandler} style={{
                                    border: '1px solid',
                                    borderRadius: '3px',
                                    'padding': ' 5px'
                                }}>Preview</a></li>
                                <li style={{ float: 'right' }}><a href="javascript:void 0" id="clearAllQues" onClick={this.selectedQuestionClearAllOnClickHandler}>Clear All</a></li>
                            </ul>
                        </div>
                        <div className="boxForQuestionSelect" id="sel_after">
                            {selectedQuestions}
                        </div>
                        <div className="lastBlockEmptyLeft text-right">
                            <Button onClick={this.cancelCreate}
                                bsStyle="cancel">
                                Cancel
                            </Button>
                            <Button onClick={() => {
                                this.setState({ saveAndExit: true }, this.saveAndAssignClickHandler);
                            }}
                                bsStyle='saveExitBtn'
                                title={(!this.props.manageActivities.createActivitySelectedQuestions
                                    || this.props.manageActivities.createActivitySelectedQuestions.length === 0) ? 'Please add questions to the activity' : 'Save & Exit'}
                                disabled={!this.props.manageActivities.createActivitySelectedQuestions
                                    || this.props.manageActivities.createActivitySelectedQuestions.length === 0}>
                                Save &amp; Exit
                            </Button>
                            <Button onClick={() => {
                                this.setState({ saveAndExit: false }, this.saveAndAssignClickHandler);
                            }}
                                bsStyle='saveAssign'
                                title={(!this.props.manageActivities.createActivitySelectedQuestions
                                    || this.props.manageActivities.createActivitySelectedQuestions.length === 0) ? 'Please add questions to the activity' : 'Save & Assign'}
                                disabled={!this.props.manageActivities.createActivitySelectedQuestions
                                    || this.props.manageActivities.createActivitySelectedQuestions.length === 0}>
                                Save &amp; Assign
                            </Button>
                        </div>
                    </div>

                </div>
                {this.state.showQuestionErrorDialog &&
                    <div className="row">
                        <div className="col-md-3 col-xs-3" />
                        <div className="col-md-6 col-xs-3">
                            <Alert bsStyle="danger">
                                All selected questions must be of same type
                        </Alert>
                        </div>
                        <div className="col-md-3 col-xs-3" />
                    </div>
                }
                {this.getPreviewDialog()}
                {this.getQuestionPreviewDialog()}
                {this.getSaveAndAssignDialog()}
                <ProgressIndicator show={this.state.loading} />
                {this.getCreateActivityInfoDialog()}

            </div>
        )
    }
}
function mapStateToProps(state) {
    return { ...state };
}
export default connect(mapStateToProps)(withRouter(CreateQuestionActivity));
