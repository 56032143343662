import React, {Component} from "react";
import Utility from "./utility";
export default class TableColumn extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let record = this.props.record;
        let column = this.props.column;
        let td = null;
        let tdWidth = column.options.width ? column.options.width : '';
        if (column.type.toLowerCase() === 'array') {
            if (column.options.dynamoTyped) {
                // process list as it comes from dynamoDB. expect L and S in data.
                let array = record[column.name];
                let tdText = '';
                if (array && array) {
                    array.forEach(obj => {
                        tdText += Utility.ucFirst(obj.trim()) + ', ';
                    });
                }
                if (tdText.length > 2) {
                    tdText = tdText.substr(0, tdText.length - 2);
                }

                // check if trim is required, do it.
                if (column.options.trimText === true && column.options.trimSize > 0 && tdText.length > column.options.trimSize) {
                    tdText = tdText.substr(0, column.options.trimSize) + '...';
                }

                td = <td width={tdWidth}><div title={tdText} >{tdText}</div></td>;
            } else {
                // normal array.
                let array = record[column.name];
                let tdText = '';
                if (array && array.length > 0) {
                    array.forEach(obj => {
                        tdText += Utility.ucFirst(obj.toString()) + ', ';
                    });
                }
                if (tdText.length > 2) {
                    tdText = tdText.substr(0, tdText.length - 2);
                }

                // check if trim is required, do it.
                if (column.options.trimText === true && column.options.trimSize > 0 && tdText.length > column.options.trimSize) {
                    tdText = tdText.substr(0, column.options.trimSize) + '...';
                }

                td = <td width={tdWidth}><div title={tdText} >{tdText}</div></td>;
            }
        } else if (column.type.toLowerCase() === 'number' || column.type.toLowerCase() === 'integer') {
            if (column.options.customDom && typeof column.options.customDom === "function") {
                td = column.options.customDom(record);
            } else {
                td = <td width={tdWidth}><div title={parseInt(record[column.name])} >{parseInt(record[column.name])}</div></td>;
            }
        } else if (column.type.toLowerCase() === 'float' || column.type.toLowerCase() === 'decimal') {
            if (column.options.customDom && typeof column.options.customDom === "function") {
                td = column.options.customDom(record);
            } else {
                td = <td width={tdWidth}><div title={parseFloat(record[column.name])} >{parseFloat(record[column.name])}</div></td>;
            }
        } else if (column.type.toLowerCase() === 'text') {
            if (column.options.customDom && typeof column.options.customDom === "function") {
                td = column.options.customDom(record);
            } else {
                let tdText = Utility.ucFirst(record[column.name]);
                // check if trim is required, do it.
                if (column.options.trimText === true && column.options.trimSize > 0 && tdText && tdText.length > column.options.trimSize) {
                    tdText = tdText.substr(0, column.options.trimSize) + '...';
                }

                td = <td width={tdWidth}><div title={Utility.ucFirst(record[column.name])}>{tdText}</div></td>;
            }
        } else if (column.type.toLowerCase() === 'date') {
            if (column.options.customDom && typeof column.options.customDom === "function") {
                td = column.options.customDom(record);
            } else {
                let dateValue = new Date(record[column.name]).toLocaleDateString() === "Invalid Date" ? '' : new Date(record[column.name]).toLocaleDateString();
                if (!dateValue) {
                    dateValue = new Date(parseFloat(record[column.name])).toLocaleDateString() === "Invalid Date" ? '' : new Date(parseFloat(record[column.name])).toLocaleDateString();
                }
                td = <td width={tdWidth}><div title={dateValue} >{dateValue}</div></td>;
            }
        }
        return (td);
    }
}
