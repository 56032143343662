/**
 * Created by ashahab on 11/7/2017.
 */
import  {QUESTIONS_SEARCH_RESULT, FETCH_ALL_QUESTIONS, RECEIVED_LP_ADMIN_REPORT_RESULT, FETCH_QUESTIONS_FAILED,} from '../actions/types';

export default function lpAdminReport(state = {results: []}, action) {
    switch (action.type) {
        case QUESTIONS_SEARCH_RESULT:
            
            return {
                ...state,
                results: action.results,
                searchResults: action.results,
                clearFilters: true
            };
        case FETCH_ALL_QUESTIONS:
            return {
                ...state,
                isFetching: true,
                results: []
            };
        case RECEIVED_LP_ADMIN_REPORT_RESULT:
            if (action.skipAlterForAllRecords) {
                return {
                    ...state,
                    isFetching: false,
                    results: action.data,
                    filteredRecords: action.data,
                    alertText: action.alertText,
                    alertStyle: action.alertStyle,
                    showAlert: action.showAlert,
                    clearFilters: action.clearFilters,
                    totalRecords:action.totalRecords
                };
            } else {
                return {
                    ...state,
                    isFetching: false,
                    results: action.data,
                    allRecords: action.data,
                    searchResults: action.data,
                    filteredRecords: action.data,
                    alertText: action.alertText,
                    alertStyle: action.alertStyle,
                    showAlert: action.showAlert,
                    clearFilters: action.clearFilters,
                    totalRecords:action.totalRecords
                };
            }
        case FETCH_QUESTIONS_FAILED:
            const status = action.data && action.data.response && action.data.response.status ? action.data.response.status : '';
            const statusText = action.data && action.data.response && action.data.response.statusText ? action.data.response.statusText : '';
            return {
                ...state,
                isFetching: false,
                error: action.data.message,
                status: status,
                statusText: statusText,
                alertText: action.alertText,
                alertStyle: action.alertStyle,
                showAlert: action.showAlert
            };
        default:
            return state
    }
};
