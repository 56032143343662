/**
 * Created by ashahab on 5/31/2017.
 */
import { gapi } from 'gapi-script';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import SearchAction from '../../actions/search-actions';
import Breadcrumb from "../breadcrumb/breadcrumb";
import $ from 'jquery';
import ElasticSearchQuery from "../../actions/elastic-search-query";
import ElasticSearchActions from "../../actions/elastic-search-actions";
import config from "../../config";
import AWS from 'aws-sdk';
import './style.css';
import {
    CognitoUserPool,
    AuthenticationDetails,
    CognitoUser
} from 'amazon-cognito-identity-js';

class Heading extends Component {

    constructor(props) {
        super(props);
        this.changePageSize = this.changePageSize.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.onChange = this.onChange.bind(this);
        this.checkEnterKeyPress = this.checkEnterKeyPress.bind(this);
        this.callElasticSearch = this.callElasticSearch.bind(this);
        this.getAwsCredentials = this.getAwsCredentials.bind(this);
        this.refreshIdentityToken = this.refreshIdentityToken.bind(this);
        this.isCreateButton = props.isCreateButton;
        this.isDeleteButton = props.isDeleteButton;
        this.isSyncButton = props.isSyncButton;
        this.headingClass = props.headingClass;
        this.heading = props.heading;
        this.heading1 = props.heading1;
        this.isSearch = props.isSearch;
        this.isShowButton = props.isShowButton;
        this.showFilterButtons = props.showFilterButtons;
        this.actionName = props.actionName;
        this.screenNameForElasticSearch = props.screenNameForElasticSearch;
        this.allColumnsForElasticSearch = props.allColumnsForElasticSearch;
        this.customSearch = props.customSearch;
        this.appliedFilters = new Set();
        if (!this.headingClass) {
            this.headingClass = "";
        }
    }

    onChange(e) {
        let searchTerm = e.target.value.toLowerCase().replace(/\|\W|[#$%^&*()\-'.]/g, "");
        const { dispatch } = this.props;
        let esAttr = this.props.elasticSearchAttributes.map.get(this.props.location);
        ElasticSearchActions.setElasticSearchAttributes(dispatch, this.props.location, searchTerm ? searchTerm : "", esAttr.pageSize, esAttr.activePage, esAttr.sortObj, esAttr.filterValues, esAttr.dateRange);
    }

    checkEnterKeyPress(e) {
        if (this.customSearch && e.key === 'Enter') {
            let esAttr = this.props.elasticSearchAttributes.map.get(this.props.location);
            const { dispatch } = this.props;
            ElasticSearchActions.setElasticSearchAttributes(dispatch, this.props.location, esAttr.searchTerm, esAttr.pageSize, 1, esAttr.sortObj, esAttr.filterValues, esAttr.dateRange);
            this.customSearch(esAttr.searchTerm);
        }
        else if (e.key === 'Enter') {
            this.handleSearch();
        }
    }

    componentDidMount() {
        if (!this.props.auth.user.federatedIdentity) {
            this.getAwsCredentials();
        } else {
            window.gapi.load('auth2', () => {
                this.auth2 = gapi.auth2.init({
                    client_id: config.googleClientId,
                    // Scopes to request in addition to 'profile' and 'email'
                    //scope: 'additional_scope'
                })

                this.auth2.then(() => {

                    console.log('on init');
                    let currentUser = this.auth2.currentUser.get();
                    currentUser.reloadAuthResponse().then(
                        (result) => {
                            this.refreshIdentityToken(result);
                        },
                        (err) => {
                            console.log("Could Not refresh Idenity Token", err);
                        }
                    );

                });
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {

        }
    }

    handleSearch() {
        this.callElasticSearch();
    }

    callElasticSearch() {
        let esAttr = this.props.elasticSearchAttributes.map.get(this.props.location);
        const { dispatch } = this.props;
        let elasticSearchQuery = new ElasticSearchQuery();
        let that = this;
        ElasticSearchActions.setElasticSearchAttributes(dispatch, this.props.location, esAttr.searchTerm, esAttr.pageSize, 1, esAttr.sortObj, esAttr.filterValues, esAttr.dateRange);
        let isQuery = this.props.isQuery;
        let stage = this.props.auth.user.userType.toLowerCase() === 'student' ? '' : config.stage;

        if (this.props.customQuery) {
            elasticSearchQuery.query(this.screenNameForElasticSearch + (this.props.isOverRideStage ? config.stage : stage), this.allColumnsForElasticSearch, this.props,
                esAttr.searchTerm, esAttr.pageSize, 1, esAttr.sortObj, [], function (results, totalRecords) {
                    dispatch({
                        type: that.actionName,
                        status: 200,
                        data: JSON.parse(JSON.stringify(results)),
                        alertText: '',
                        alertStyle: '',
                        showAlert: false,
                        totalRecords: totalRecords,
                        clearFilters: true
                    });
                }, isQuery, this.props.customQuery, this.props.customURL);
        }
        else {
            elasticSearchQuery.query(this.screenNameForElasticSearch + (this.props.isOverRideStage ? config.stage : stage), this.allColumnsForElasticSearch, this.props,
                esAttr.searchTerm, esAttr.pageSize, 1, esAttr.sortObj, [], function (results, totalRecords) {

                    dispatch({
                        type: that.actionName,
                        status: 200,
                        data: JSON.parse(JSON.stringify(results)),
                        alertText: '',
                        alertStyle: '',
                        showAlert: false,
                        totalRecords: totalRecords,
                        clearFilters: true
                    });
                }, isQuery, '', this.props.customURL);
        }

    }

    changePageSize(event) {
        let val = event.target.options[event.target.selectedIndex].value;
        val = parseInt(val);
        let stage = this.props.auth.user.userType.toLowerCase() === 'student' ? '' : config.stage;

        const { dispatch } = this.props;
        let esAttr = this.props.elasticSearchAttributes.map.get(this.props.location);
        let that = this;
        ElasticSearchActions.setElasticSearchAttributes(dispatch, this.props.location, esAttr.searchTerm, val, esAttr.activePage, esAttr.sortObj, esAttr.filterValues, esAttr.dateRange);
        let elasticSearchQuery = new ElasticSearchQuery();
        let isQuery = this.props.isQuery;
        if (this.props.customQuery) {
            elasticSearchQuery.query(this.screenNameForElasticSearch + (this.props.isOverRideStage ? config.stage : stage), this.allColumnsForElasticSearch, this.props,
                esAttr.searchTerm, val, esAttr.activePage, esAttr.sortObj, esAttr.filterValues, function (results, totalRecords) {

                    dispatch({
                        type: that.actionName,
                        status: 200,
                        data: JSON.parse(JSON.stringify(results)),
                        alertText: '',
                        alertStyle: '',
                        showAlert: false,
                        totalRecords: totalRecords
                    });
                }, isQuery, this.props.customQuery, this.props.customURL);
        }
        else {
            elasticSearchQuery.query(this.screenNameForElasticSearch + (this.props.isOverRideStage ? config.stage : stage), this.allColumnsForElasticSearch, this.props,
                esAttr.searchTerm, val, esAttr.activePage, esAttr.sortObj, esAttr.filterValues, function (results, totalRecords) {

                    dispatch({
                        type: that.actionName,
                        status: 200,
                        data: JSON.parse(JSON.stringify(results)),
                        alertText: '',
                        alertStyle: '',
                        showAlert: false,
                        totalRecords: totalRecords
                    });
                }, isQuery, '', this.props.customURL);
        }

    }

    refreshIdentityToken(response) {

        const token = response['id_token'];
        const access_token = response['access_token'];
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: config.identityPoolId,
            Logins: {
                'accounts.google.com': token
            }
        });
        AWS.config.region = config.region;
        AWS.config.credentials.clearCachedId();
        AWS.config.credentials.get(function (err, data) {
            if (err) {
                console.log("Error: " + err);
            }
            localStorage.setItem('accessToken', access_token);
            localStorage.setItem('identityToken', token);
            localStorage.setItem('accessKeyId', AWS.config.credentials.accessKeyId);
            localStorage.setItem('secretAccessKey', AWS.config.credentials.secretAccessKey);
            localStorage.setItem('sessionToken', AWS.config.credentials.sessionToken);
        });

        return AWS.config.credentials.getPromise();
    }


    getAwsCredentials() {
        AWS.config.update({ region: 'us-east-1' });

        let userPool = new CognitoUserPool({
            UserPoolId: config.userPoolId,
            ClientId: config.clientId
        });
        let cognitoUser = userPool.getCurrentUser();
        cognitoUser.getSession(function (err, session) {
            if (session) {
                console.log('session validity: ' + session.isValid());
                localStorage.setItem('token', session.getIdToken().getJwtToken());
            }
        });

        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: config.identityPoolId,
            Logins: {
                [config.cognitoURL]: localStorage.getItem('token')
            }
        });

        // AWS.config.credentials.clearCachedId();
        // We can set the get method of the Credentials object to retrieve
        // the unique identifier for the end user (identityId) once the provider
        // has refreshed itself
        AWS.config.credentials.clearCachedId();
        AWS.config.credentials.get(function (err) {
            if (err) {
                console.log("Error: " + err);
            }
            // localStorage.setItem('token', result.getIdToken().getJwtToken());
            localStorage.setItem('accessKeyId', AWS.config.credentials.accessKeyId);
            localStorage.setItem('secretAccessKey', AWS.config.credentials.secretAccessKey);
            localStorage.setItem('sessionToken', AWS.config.credentials.sessionToken);
        });


        return AWS.config.credentials.getPromise();
    }

    render() {
        let heading = null;
        let ctx = this;
        let headingOrBreadCrumbs = null;

        if (this.props.breadcrumbsData && this.props.breadcrumbsData.length > 0) {
            headingOrBreadCrumbs = <Breadcrumb
                breadcrumbsData={this.props.breadcrumbsData}
                backColor={this.props.backColor}
            />
        } else if (this.props.backButton) {
            headingOrBreadCrumbs = <div><button type="button" className="btn btn-primary backBtn" onClick={this.props.backButton.action}
                key="CreateButton"><span> </span>{this.props.backButton.label}</button><h3>{this.props.backButton.instructions}</h3></div>;
        } else if (this.heading) {
            headingOrBreadCrumbs = <h1 style={{ width: "1000px", marginTop: '17px', fontWeight: '300', color: "white" }} className={this.headingClass}>{this.heading}</h1>;
        }
        else if (this.heading1) {
            headingOrBreadCrumbs = <h1 style={{ marginTop: '-16px', fontWeight: '700' }} className={this.headingClass}>{this.heading1}</h1>;
        }

        if (!this.props.isHideHeading) {
            heading = <span>
                <div className="searchCrtBar" style={{ background: this.props.backColor }}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-xs-12">
                                {headingOrBreadCrumbs}
                            </div>
                            <div className="col-lg-8 col-md-8 col-xs-12 pull-right">
                                {this.props.isCreateButton && <div className="col-lg-2 col-md-4 col-xs-5 noPad crtBtnCol hidden-xs pull-right ">
                                    {this.props.allButtons}
                                </div>
                                }
                                {this.props.isDeleteButton ? <div className="col-lg-2 col-md-4 col-xs-5 noPad crtBtnCol hidden-xs ">
                                    {this.props.deleteButtons}
                                </div> : <div></div>
                                }
                                {!this.props.isCreateButton && <div></div>}
                                {this.props.auth.user.federatedIdentity && this.props.isSyncButton ? <div className="col-lg-2 col-md-4 col-xs-5 noPad crtBtnCol hidden-xs pull-right ">
                                    {this.props.syncButtons}
                                </div> : <div></div>
                                }

                                {this.isShowButton && <div style={{ width: "27%", marginTop: "8px", marginRight: "20px" }} className="col-lg-3 col-md-4 col-xs-7 slctCol hidden-xs  pull-right">
                                    <div>
                                        <label className="showLabel">Show:</label>
                                        <div className="BoxSelMain">
                                            <select id="pageSizeDropDown" className="form-control selctBox" onChange={this.changePageSize}>
                                                <option value={10}>10</option>
                                                <option value={15}>15</option>
                                                <option value={20}>20</option>
                                                <option selected value={50}>50</option>
                                                <option value={100}>100</option>
                                                <option value={200}>200</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>}
                                {!this.isShowButton && <div></div>}

                                {this.isSearch && <div style={{ marginLeft: "-95px", width: "40%", marginTop: "10px" }} className="col-lg-6 col-md-4 srcFieldCol HiddenLessLap  pull-right">
                                    <div className="input-group" style={{height: 'fit-content'}}>
                                        <input type="text" className="form-control" value={this.props.elasticSearchAttributes.searchTerm} placeholder="Search" id="searchBox" onChange={this.onChange} onKeyPress={this.checkEnterKeyPress}/>
                                        <span className="input-group-btn">
                                            <div className="btn btn-primary" type="button"><span className="searchBtn" onClick={this.handleSearch}> </span></div>
                                        </span>
                                    </div>
                                </div>}
                                {!this.isSearch && <div></div>}
                                {
                                    this.customSearch &&
                                    <div style={{ marginLeft: "-95px", width: "40%", marginTop: "10px" }} className="col-lg-6 col-md-4 srcFieldCol HiddenLessLap  pull-right">
                                        <div className="input-group">
                                            <input type="text" className="form-control" value={this.props.elasticSearchAttributes.searchTerm} placeholder="Search" id="searchBox" onChange={this.onChange} onKeyPress={this.checkEnterKeyPress} />
                                            <span className="input-group-btn">
                                                <div className="btn btn-primary" type="button"><span className="searchBtn" onClick={this.customSearch}> </span></div>
                                            </span>
                                        </div>
                                    </div>
                                }
                                <div className="pull-right hidden-sm hidden-xs">{this.props.pickList}</div>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    this.showFilterButtons !== "false" &&
                    <div className="container-fluid">
                        {this.showFilterButtons !== "false" &&
                            <div className="row hidden-md hidden-lg sercFilBtnBox">
                                <div className="col-xs-6 noPad">
                                    <button className="ViewFilter-sm" onClick={this.filterButtonOnClick}><span>Filter</span> </button>
                                </div>
                                <div className="col-xs-6 noPad">
                                    <button className="ViewSearch-sm" onClick={function (evt) {
                                        $('.search-sm-Box').addClass('TopZero');
                                        $('html').css({ 'overflow': 'hidden', 'position': 'fixed', 'width': '100%', 'height': '100%' });
                                    }.bind(this)}><span>Search</span> </button>
                                </div>
                                <div className="search-sm-Box"> <span className="srcHideBox" onClick={function (evt) {
                                    $('.search-sm-Box').removeClass('TopZero');
                                    $('html').css({ 'overflow': 'auto', 'position': 'inherit' });
                                }.bind(this)} />
                                    <div className="input-group">
                                        <input type="text" className="form-control searchBar" placeholder="Search" id="searchBarId"
                                            onChange={this.onChange} onKeyPress={this.checkEnterKeyPress} />
                                        <span className="input-group-btn">
                                            <button className="btn btn-primary" type="button" onClick={function (evt) {
                                                $('.search-sm-Box').removeClass('TopZero');
                                                $('html').css({ 'overflow': 'auto', 'position': 'inherit' });
                                                ctx.handleSearch();
                                            }.bind(this)}><span className="searchBtn" /></button>
                                        </span> </div>
                                </div>
                            </div>
                        }
                    </div>
                }
            </span >
        }
        return (
            <div>
                {heading}
            </div>

        );
    }

    filterButtonOnClick(evt) {
        $('.filterBlock').addClass('TopZero');
        $('html').css({ 'overflow': 'hidden', 'position': 'fixed' });
        $('.footer ').hide();
    }
}
function mapStateToProps(state) {

    return { ...state };
}

Heading.defaultProps = {
    isQuery: true
}

export default connect(mapStateToProps)(Heading);
