/**
 * Created by ashahab on 8/9/2017.
 */
import React, {Component} from 'react';
import calicon from './calicon.png';

export default class CustomDatePicker extends Component {

    render () {
        return (
            <div style={{ position:'relative !important',}}>
            <input style={{border: '1px solid lightgray', padding: '6px',
                borderRadius: '4px', marginLeft: '15px', marginRight: '10px',
                paddingTop: '5px', width:'110px'}}
              value={this.props.value ? this.props.value : this.props.placeholder}/>
            <button className="btn btn-primary" style={{borderRadius: '2px', float:'left'}}
                onClick={this.props.onClick}>
                <img src={calicon} style={{width: '20px'}}/>
            </button>
            </div>
        )
    }
}

CustomDatePicker.propTypes = {
    onClick: React.PropTypes.func,
    value: React.PropTypes.string
};
