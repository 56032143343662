import React from "react";
import {connect} from "react-redux";
import ProgressIndicator from "../../progress-indicator/progress-indicator";
import Heading from "../../heading/heading";
import {RECEIVED_STUDENT_ASSIGNMENT_RESULTS, ElasticSearchTypes, ElasticSearchColumns} from "../../../actions/types";
import classnames from "classnames";
import $ from "jquery";
import {browserHistory} from "react-router";
import ElasticSearchQuery from "../../../actions/elastic-search-query";
import ElasticSearchActions from "../../../actions/elastic-search-actions";
import ElasticSearchStore from "../../../actions/elastic-search-store";
import "./student-assignment.css";
import "../../reports/view/view-report.css";
import apiClient from "../../../actions/api-client";
import MaterialIcon from "react-google-material-icons";
import {Alert, Modal, Button} from "react-bootstrap";
import {Parser} from "html-to-react";
import SelectFieldGroup from "../../forms/select-field-group";
import Utility from "../../maintenance-table/utility";
import {
    parseHours,
    parseMinutes,
    parseSeconds
} from '../../../utils/timeParser';

// This will be chnaged as well
class StudentActivityReport extends React.Component {
    constructor(props) {

        super(props);
        this.getTeacherStudentReport = this.getTeacherStudentReport.bind(this);
        this.viewStudentDetails = this.viewStudentDetails.bind(this);
        this.loadStudentDetails = this.loadStudentDetails.bind(this);
        this.loadStudentReportCard = this.loadStudentReportCard.bind(this);
        this.loadAttemptDetails = this.loadAttemptDetails.bind(this);
        this.getPeriodsByTeacher = this.getPeriodsByTeacher.bind(this);
        this.getStudentsByTeacher = this.getStudentsByTeacher.bind(this);
        this.getStudentList = this.getStudentList.bind(this);
        this.getPeriodDropdown = this.getPeriodDropdown.bind(this);
        this.getAllSubjects = this.getAllSubjects.bind(this);
        this.loadAllStudentReportCard = this.loadAllStudentReportCard.bind(this);
        this.loadAllStudentDetails = this.loadAllStudentDetails.bind(this);

        this.loadAllStudentByPeriodReportCard = this.loadAllStudentByPeriodReportCard.bind(this);
        this.loadAllStudentByPeriodDetails = this.loadAllStudentByPeriodDetails.bind(this);

        this.state = {screenWidth: window.innerWidth,loadingNextPage: 0, selectedType:'assignment',allAttemptWiseActivities:[],studentActivities:[],
            cachedData:{}, currentReportName:'Select Subject', view:'activities', userRecord:[], currentAttempt:0};

        this.state.selectedSubject = this.props.params.subject;
        this.filterComponentCssClassName = 'filterBlock filterBlockBox NiceScroll';
        this.tableCssClassName = 'listViewBlock ';

        this.backToSubjectAggr = false;
        this.backToClassAggr = false;
        this.classSelected = false;

        if(this.props.location.state) {
            this.state.forAdminReportForSingleStudent = this.props.location.state.forAdminReportForSingleStudent;
            this.state.studentIDforAdminReportForSingleStudent = this.props.location.state.studentIDforAdminReportForSingleStudent;
            this.state.studentNameForAdminReportForSingleStudent = this.props.location.state.studentNameForAdminReportForSingleStudent;
            this.state.teacherIDforAdminReportForSingleStudent = this.props.location.state.teacherIDforAdminReportForSingleStudent;
            this.state.teacherNameforAdminReportForSingleStudent = this.props.location.state.teacherNameforAdminReportForSingleStudent;
            this.state.selectedSubject = this.props.location.state.subjectForAdminReportForSingleStudent;
        }

        if(this.state.forAdminReportForSingleStudent) {
            this.state.selectedReport = 'studentReport';
        }
    }

    componentDidMount() {
        if(this.state.forAdminReportForSingleStudent) {
            this.getStudentsByTeacher(this.state.teacherIDforAdminReportForSingleStudent);
        } else {

            this.getPeriodsByTeacher();
            // this.getTeacherStudentReport();
            this.getStudentsByTeacher();
            this.getAllSubjects();
            // this.loadAllStudentDetails();
        }
    }

    getAssignmentIDsByCourseNameAndByTeacher(studentID) {
        let that = this;
        return new Promise((resolve, reject) => {
            let params = {
                userName: that.state.teacherIDforAdminReportForSingleStudent ? that.state.teacherIDforAdminReportForSingleStudent : that.props.auth.user.userName,
                courseID: false,
                studentID,
                // wantAssignmentIDs: true
            };
            // let pathTemplate = '/course/assignmentIDsAndAllActivities/report/username/{userName}/courseID/{courseID}/wantAssignmentIDs/{wantAssignmentIDs}/studentID/{studentID}';
            let pathTemplate = '/course/assignmentIDsAndAllActivities/report/username/{userName}/courseID/{courseID}/studentID/{studentID}';
            let method = 'GET';
            let additionalParams = {};
            let body = {};
            let context = this;
            apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, '5').then(function (result) {
                resolve({activities: result.data.activities});
            }
            , (err) => {
                context.setState({
                    showAlert: true,
                    alertStyle: 'danger',
                    alertText: err.response.data.errors.map(e => e.message).join(', ')
                });
                setTimeout(() => {
                    context.setState({
                        showAlert: false,
                        alertStyle: '',
                        alertText: ''
                    })
                }, 4000);
                reject({activities: []});
            }
            ).catch(function (error) {
                context.setState({
                    showAlert: true,
                    alertStyle: 'danger',
                    alertText: 'Unable to load data, please contact administrator.'
                });
                setTimeout(() => {
                    context.setState({
                        showAlert: false,
                        alertStyle: '',
                        alertText: ''
                    })
                }, 4000);
                reject({ ...error, activities: []});
            });
        })
    }

    async getStudentActivities(studentID){
        let context = this;
        context.showIndicator();
        // let user = context.props.auth.user;
        // // let estore = new ElasticSearchStore({esIndexName:'lp-student'});
        // let teacher = user.firstName + ' ' + user.lastName;

        const assignmentActivityResult = await this.getAssignmentIDsByCourseNameAndByTeacher(studentID).catch(err => err);
        context.hideIndicator();

        // let elasticSearchQuery  = new ElasticSearchQuery();
        return new Promise((resolve, reject) => {
        //     elasticSearchQuery.query("stdassign", ElasticSearchColumns.StudentAssignment, context.props,
        //         "", 10000, 1, {dueDate: {order: "asc"}}, [], function (filterData, totalRecords) {
                    var activities = [];

                    if(assignmentActivityResult.activities) {
                        activities = assignmentActivityResult.activities;
                    }

                    // filterData.forEach((item) => {
                    //     if (item.assignedActivities) {
                    //         activities.push({
                    //             assignmentID: item.assignmentID,
                    //             assignmentName: item.assignmentName,
                    //             assignmentType: item.assignmentType,
                    //             studentID: item.studentID,
                    //             teacher: item.teacher,
                    //             activityID: item.assignedActivities.activityID,
                    //             activityType: item.assignedActivities.activityType ? item.assignedActivities.activityType : item.activityType,
                    //             activityName: item.assignedActivities.activityName,
                    //             completionDate: item.createdAt,
                    //             progress: item.progress,
                    //             timeIn: item.timeIn,
                    //             score: item.score,
                    //             attemptedDate: item.attemptedDate
                    //         });
                    //     } else if (item.assignedCourses) {
                    //         item.assignedCourses.units.forEach(unit => {
                    //             unit.activities.forEach(act => {
                    //                 activities.push({
                    //                     assignmentID: item.assignmentID,
                    //                     assignmentName: item.assignmentName,
                    //                     assignmentType: item.assignmentType,
                    //                     studentID: item.studentID,
                    //                     teacher: item.teacher,
                    //                     activityID: act.activityID,
                    //                     activityName: act.activityName,
                    //                     activityType: act.activityType,
                    //                     attempts: act.attempts,
                    //                     completionDate: act.lastUpdated,
                    //                     progress: act.progress,
                    //                     timeIn: act.timeIn,
                    //                     score: act.score,
                    //                     attemptedDate: act.attemptedDate
                    //                 });
                    //             });
                    //         });
                    //     }
                    // });
                    resolve(activities);
        //         }, true, 'teacher: (' + '"' + (this.state.teacherNameforAdminReportForSingleStudent ? this.state.teacherNameforAdminReportForSingleStudent : teacher) +'"' + ') AND studentID: (' + studentID + ')', 'lp-student/');
        });

        // return new Promise((resolve, reject) => {
        //     estore.query(null, "stdassign", {
        //         bool: {
        //             must:{
        //                 query_string:{
        //                     query:"teacher: ("+teacher+") AND " + "studentID: ("+studentID+")"
        //                 }
        //             }
        //         }
        //     }, ["studentID", "type", "ownerName", "dueDate", "assignmentStatus", "teacher", "assignmentName", "assignmentID", "assignmentType", "activityID", "activityType", "activityName", "progress", "createdAt", "assignedActivities", "score", "timeIn", "attemptedDate", "assignedCourses"], null, null, null, "10000").then((filterData) => {
        //
        //     }).catch(error=> {
        //         context.setState({
        //             showAlert: true,
        //             alertStyle: 'danger',
        //             alertText: 'Unable to perform search, please contact administrator.',
        //             loading:false
        //         });
        //         setTimeout(()=> {
        //             context.setState({
        //                 showAlert: false,
        //                 alertStyle: '',
        //                 alertText: ''
        //             })
        //         }, 4000);
        //         reject(error);
        //     })
        // });
    }

    loadAllStudentByPeriodReportCard(subject, periodName, teacherID) {
        let context = this;
        context.showIndicator();
        return new Promise((resolve, reject) => {
            let params = {
                subject: subject,
                userName: teacherID ? teacherID : this.props.auth.user.userName,
                periodName:periodName
            };
            let pathTemplate = '/assignment/analysis/subject/{subject}/teacher/{userName}/class/{periodName}';
            let method = 'GET';
            // let additionalParams = { queryParams : { subjects: this.props.auth.user.subjects } };
            let additionalParams = {};
            let body = {};
            apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, '4').then(function (result) {
                resolve(result.data);
                context.hideIndicator();
            }).catch(function (error) {
                context.hideIndicator();
                context.setState({
                    showAlert: true,
                    alertStyle: 'danger',
                    alertText: 'Unable to load data, please contact administrator.'
                });
                setTimeout(()=> {
                    context.setState({
                        showAlert: false,
                        alertStyle: '',
                        alertText: ''
                    })
                }, 4000);
                reject(error);
            });
        });
    }

    loadAllStudentReportCard(subject, teacherID) {
        let context = this;
        context.showIndicator();
        return new Promise((resolve, reject) => {
            let params = {
                subject: subject,
                userName: teacherID ? teacherID : this.props.auth.user.userName
            };
            let pathTemplate = '/assignment/analysis/subject/{subject}/teacher/{userName}';
            let method = 'GET';
            // let additionalParams = { queryParams : { subjects: this.props.auth.user.subjects } };
            let additionalParams = {};
            let body = {};
            apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, '4').then(function (result) {
                resolve(result.data);
            }).catch(function (error) {
                context.hideIndicator();
                context.setState({
                    showAlert: true,
                    alertStyle: 'danger',
                    alertText: 'Unable to load data, please contact administrator.'
                });
                setTimeout(()=> {
                    context.setState({
                        showAlert: false,
                        alertStyle: '',
                        alertText: ''
                    })
                }, 4000);
                reject(error);
            });
        });
    }

    loadStudentReportCard(studentID, subject, teacherID) {
        let context = this;
        context.showIndicator();
        return new Promise((resolve, reject) => {
            let context = this;
            let params = {
                studentID: studentID,
                subject: subject,
                userName: teacherID ? teacherID : this.props.auth.user.userName
            };
            let pathTemplate = '/assignment/analysis/student/{studentID}/subject/{subject}/teacher/{userName}';
            let method = 'GET';
            // let additionalParams = { queryParams : { subjects: this.props.auth.user.subjects } };
            let additionalParams = {};
            let body = {};
            apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, '4').then(function (result) {
                context.hideIndicator();
                resolve(result.data);
            }).catch(function (error) {
                context.hideIndicator();
                context.setState({
                    showAlert: true,
                    alertStyle: 'danger',
                    alertText: 'Unable to load data, please contact administrator.'
                });
                setTimeout(()=> {
                    context.setState({
                        showAlert: false,
                        alertStyle: '',
                        alertText: ''
                    })
                }, 4000);
                reject(error);
            });
        });
    }

    getPeriodsByTeacher(teacherID) {
        let that = this;
        let params = {
            userName: teacherID ? teacherID : that.props.auth.user.userName
        };
        let pathTemplate = '/class/{userName}';
        let method = 'GET';
        let additionalParams = {};
        let body = {};
        apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, "2").then(function (result) {
            let periods = result.data.allClasses;
            that.setState({'periods': periods});
        }).catch(function (result) {
            that.hideIndicator();
        });
    }

    loadAllStudentByPeriodDetails(className) {
        let context = this;
        context.backToClassAggr = false;
        context.classSelected = true;
        context.backToSubjectAggr = false;
        let periodName = '';
        if (className)
        {
            this.setState({periodName:className});
            periodName = className;
        }
        else if (this.state.periodName)
        {
            periodName = this.state.periodName;
        }
        else
        {
            return;
        }

        if (context.state.cachedData['byPeriod'+periodName])
        {
            context.setState({
                periodName:periodName,
                currentReportName: periodName+' Overview',
                cumulative: context.state.cachedData['byPeriod'+periodName].cumulative,
                practiceAssignment: context.state.cachedData['byPeriod'+periodName].practiceAssignment,
                assignment: context.state.cachedData['byPeriod'+periodName].assignment,
                view: 'report-card'
            }, context.hideIndicator);
        }
        else
        {
            if(this.state.selectedSubject){
                this.loadAllStudentByPeriodReportCard(this.state.selectedSubject, periodName, this.state.teacherIDforAdminReportForSingleStudent).then((result) => {
                    let cachedData = context.state.cachedData;
                    cachedData['byPeriod'+periodName] = result;
                    context.setState({
                        cachedData,
                        periodName:periodName,
                        currentReportName: periodName+' Overview',
                        cumulative: result.cumulative,
                        practiceAssignment: result.practiceAssignment,
                        assignment: result.assignment,
                        view: 'report-card'
                    });
                }, (error) => {
                    console.error(error);
                });
            }
        }
    }

    loadAllStudentDetails() {
        let context = this;
        context.showIndicator();
        if (context.state.cachedData[context.state.selectedSubject])
        {
            context.setState({
                currentReportName: 'All Students Overview',
                cumulative: context.state.cachedData[context.state.selectedSubject].cumulative,
                practiceAssignment: context.state.cachedData[context.state.selectedSubject].practiceAssignment,
                assignment: context.state.cachedData[context.state.selectedSubject].assignment,
                view: 'report-card'
            }, context.hideIndicator);
        }
        else
        {
            if(context.state.selectedSubject){
                context.loadAllStudentReportCard(context.state.selectedSubject, this.state.teacherIDforAdminReportForSingleStudent).then((result) => {
                    let cachedData = context.state.cachedData;
                    cachedData[context.state.selectedSubject] = result;
                    context.setState({
                        cachedData,
                        currentReportName: 'All Students Overview',
                        cumulative: result.cumulative,
                        practiceAssignment: result.practiceAssignment,
                        assignment: result.assignment,
                        view: 'report-card'
                    }, context.hideIndicator);
                }, (error) => {
                    console.error(error);
                });
            }
        }
    }

    loadSelectedStudentDetails(student, type) {

        let context = this;
        // load activities data.
        if (type === 'report-card') {
            if(this.state.selectedSubject)
            {
                if (context.state.cachedData['byStudent'+context.state.selectedSubject+student.userName])
                {
                    context.setState({
                        currentReportName: student.stdFirstName+' '+student.stdLastName,
                        cumulative: context.state.cachedData['byStudent'+context.state.selectedSubject+student.userName].cumulative,
                        practiceAssignment: context.state.cachedData['byStudent'+context.state.selectedSubject+student.userName].practiceAssignment,
                        assignment: context.state.cachedData['byStudent'+context.state.selectedSubject+student.userName].assignment,
                        selectedStudent: student,
                    });
                }
                else
                {
                    context.loadStudentReportCard(student.students, context.state.selectedSubject, this.state.teacherIDforAdminReportForSingleStudent).then((result) => {
                        let cachedData = context.state.cachedData;
                        cachedData['byStudent'+context.state.selectedSubject+student.userName]=result;
                        context.setState({
                            cachedData,
                            currentReportName: student.stdFirstName+' '+student.stdLastName,
                            cumulative: result.cumulative,
                            practiceAssignment: result.practiceAssignment,
                            assignment: result.assignment,
                            selectedStudent: student,
                        });
                    }, (error) => {
                        console.error(error);
                    });
                }
            }else{
                this.setState({
                    selectedStudent: student,
                });
            }
        }
        else
        {
            this.getStudentActivities(student.students).then(activities => {
                context.setState({
                    loading: false,
                    studentActivities: activities,
                    selectedStudent: student
                });
                let parentActivities = [];
                activities.forEach((activity) => {

                    let index = parentActivities.findIndex(x=> x.activityID === activity.activityID);
                    if (index === -1)
                    {
                        let childActivities = [];
                        childActivities.push(activity);
                        parentActivities.push({
                            activityID:activity.activityID,
                            activities:childActivities
                        });
                    }
                    else
                    {
                        parentActivities[index].activities.push(activity);
                    }
                });
                this.setState({allAttemptWiseActivities:parentActivities});
            });
        }

    }

    getStudentList(selectedPeriod) {

        let context = this;
        if (!context.state.search) {
            if (selectedPeriod && selectedPeriod !== 'All Students') {
                // filter students by period.
                if (context.state.students) {
                    let filtered = context.state.students.filter((std) => {
                        return std.className === selectedPeriod;
                    });
                    filtered.sort(function(a,b){
                        return a.stdFirstName.localeCompare(b.stdFirstName);
                    });
                    return filtered.map(student => {
                        return <li key={student.students+Math.random()} className={context.state.selectedStudent && context.state.selectedStudent.students === student.students ? 'selectedStudentClass':''}>
                            <div onClick={() => {
                                if (this.state.view === 'activities')
                                {
                                    context.loadSelectedStudentDetails(student, 'activities');
                                }
                                else
                                {
                                    context.loadSelectedStudentDetails(student, 'report-card');
                                }
                            }} className="studentNameLink">{student.stdFirstName + ' ' + student.stdLastName}</div>
                        </li>
                    });
                }
                return [];
            } else {
                // return all.
                if (context.state.students) {
                    return context.state.students.map(student => {
                        return <li key={student.students+Math.random()} className={context.state.selectedStudent && context.state.selectedStudent && context.state.selectedStudent.students === student.students ? 'selectedStudentClass':''}>
                            <div onClick={() => {
                                if (this.state.view === 'activities')
                                {
                                    context.loadSelectedStudentDetails(student, 'activities');
                                }
                                else
                                {
                                    context.loadSelectedStudentDetails(student, 'report-card');
                                }
                            }} className="studentNameLink">{student.stdFirstName + ' ' + student.stdLastName}</div>
                        </li>
                    });
                }
                return [];
            }
        } else {
            let searchTerm = this.state.search;
            if (this.state.students) {
                let filtered = this.state.students.filter((std) => {
                    return (std.stdFirstName + ' ' + std.stdLastName).indexOf(searchTerm) > -1;
                });
                return filtered.map(student => {
                    return <li key={student.students+Math.random()}>
                        <div>{student.stdFirstName + ' ' + student.stdLastName}</div>
                        <a onClick={() => {
                            this.loadSelectedStudentDetails(student, 'activities');
                        }
                        }>Activities</a>&nbsp;|&nbsp;
                        <a onClick={() => {
                            this.loadSelectedStudentDetails(student, 'report-card');
                        }
                        }>Report card</a>
                    </li>
                });
            }
            return [];
        }
    }

    getStudentsByTeacher(teacherID) {
        let context = this;

        // if(context.state.forAdminReportForSingleStudent) {
            context.showIndicator();
        // }

        let params = {
            userName: teacherID ? teacherID : this.props.auth.user.userName
        };
        let pathTemplate = '/user/students/{userName}';
        let method = 'GET';
        let additionalParams = {};
        let body = {};
        apiClient.invokeApi(params, pathTemplate, method, additionalParams, body).then(function (result) {

            if(result.data.students && result.data.students.length == 0) {
                context.setState({students: []}, () => {
                    context.hideIndicator();
                    context.setState({
                        showAlert: true,
                        alertStyle: 'danger',
                        alertText: 'No students found'
                    });
                    setTimeout(()=> {
                        context.setState({
                            showAlert: false,
                            alertStyle: '',
                            alertText: ''
                        })
                    }, 4000);
                });
                return;
            }

            result.data.sort(function(a,b){
                if(a.stdFirstName && b.stdFirstName)
                return a.stdFirstName.localeCompare(b.stdFirstName);
            });
            context.setState({students: result.data}, () => {
                if(context.state.forAdminReportForSingleStudent) {
                    // console.log("STATE->", context.state)
                    // console.log("data->", result.data)
                    let student = context.state.students.find(std => std.students == context.state.studentIDforAdminReportForSingleStudent); //result.data.students ? [] : result.data.filter(std => std.students == context.state.studentIDforAdminReportForSingleStudent)
                    // console.log(student);
                    context.loadSelectedStudentDetails(student, 'activities');
                }
            });

            if(!context.state.forAdminReportForSingleStudent) {
                context.hideIndicator();
            }

        }).catch(function (error) {
            context.hideIndicator();
            context.setState({
                showAlert: true,
                alertStyle: 'danger',
                alertText: 'Unable to load data, please contact administrator.'
            });
            setTimeout(()=> {
                context.setState({
                    showAlert: false,
                    alertStyle: '',
                    alertText: ''
                })
            }, 4000);
        });
    }

    render() {
        const {screenWidth} = this.state;
        let context = this;
        let categoryData = [];
        let activities = [];
        if(this.state.selectedType === 'cumulative'){
            categoryData = context.state.cumulative || [];
        }else if(this.state.selectedType === 'practice'){
            categoryData = context.state.practiceAssignment || [];
        }else if(this.state.selectedType === 'assignment'){
            categoryData = context.state.assignment || [];
        }
        if(this.state.allAttemptWiseActivities){
            activities = this.state.allAttemptWiseActivities;
        }
        let subjectScore = 0;
        let questions = 0;
        let correctAns = 0;

        categoryData.forEach((cat) => {
            questions += cat.totalQuestions;
            correctAns += cat.answeredCorrectly;
        });

        if (correctAns === 0 && questions === 0) {
            subjectScore = 0;
        } else {
            subjectScore = (correctAns / questions) * 100;
            subjectScore = subjectScore.toFixed(0);
        }

        categoryData = categoryData.sort((a,b) => {
            return a.category.localeCompare(b.category);
        });

        let rows = [];
        categoryData.forEach((category) =>
        {
            let average = 0;
            let color = 'black';

            average = category.average;

            if (average.toFixed(0) < 65)
            {
                color = 'red';
            }
            rows.push(<tr key={Math.random()} id={category.category}>
                <td data-parent-id={category.category} data-is-expanded={false} style={{width:'40%'}}>
                    <a href="javascript:void 0" onClick={function (evt) {
                        var parent = evt.target.parentElement.parentElement;
                        var rows = document.querySelectorAll('tr[id*=\"parent-' + parent.dataset.parentId + '\"]');
                        if (parent.dataset.isExpanded === 'false') {
                            parent.dataset.isExpanded = 'true';
                            parent.firstElementChild.lastElementChild.textContent = 'remove_circle_outline';
                            for (let i = 0; i < rows.length; i++) {
                                rows[i].style.display = '';
                            }
                        } else {
                            parent.firstElementChild.lastElementChild.textContent = 'add_circle_outline';
                            parent.dataset.isExpanded = 'false';
                            for (let i = 0; i < rows.length; i++) {
                                rows[i].style.display = 'none';
                            }
                        }
                    }.bind(this)} data-parent-id={category.category}><strong>{category.category}</strong><MaterialIcon icon="add_circle_outline" size={24}/></a>
                </td>
                <td style={{width:'15%', color:color}}>
                    {average ? average.toFixed(0):0}{'%'}
                </td>
                <td style={{width:'15%'}}>
                    {category.totalQuestions}
                </td>
                <td style={{width:'15%'}}>
                    {category.answeredCorrectly}
                </td>
                <td style={{width:'15%'}}>
                    {category.answeredIncorrectly}
                </td>
            </tr>);

            category.subCategories.forEach(subCat => {
                let average = 0;
                let color = 'black';

                average = subCat.average

                if (average.toFixed(0) < 65)
                {
                    color = 'red';
                }

                rows.push(<tr key={Math.random()} id={'parent-' + category.category + subCat.category}
                              data-category-parent-id={category.category} style={{display:'none'}}>
                    <td className="indentSubCat">
                        {subCat.category}
                    </td>
                    <td style={{color:color}}>
                        {average.toFixed(0)}{'%'}
                    </td>
                    <td>
                        {subCat.totalQuestions}
                    </td>
                    <td>
                        {subCat.answeredCorrectly}
                    </td>
                    <td>
                        {subCat.answeredIncorrectly}
                    </td>
                </tr>);
            });
        });

        let actRows = [];
        activities.forEach((activity) => {
            // let index = actRows.findIndex(x=> x.props.id === activity.activityID);
            activity.activities.sort(function (a, b) {
                return (new Date(b.attemptedDate) - new Date(a.attemptedDate));
            });
            let currentActivity = activity.activities[0];
            // if (index === -1)
            // {
            let averageScore =currentActivity.score;
            let averageProgress =0;
            let averageTimeIn =0;
            let numberOfAttempts=activity.activities.length;
            for (let q = 0; q < activity.activities.length; q++)
            {
                // averageScore += activity.activities[q].score?activity.activities[q].score:0;
                averageProgress += activity.activities[q].progress ? parseInt(activity.activities[q].progress) : 0;
                averageTimeIn += activity.activities[q].timeIn ? parseFloat(activity.activities[q].timeIn) : 0;
            }
            // averageScore = averageScore/activity.activities.length;
            averageTimeIn = averageTimeIn/activity.activities.length;
            averageProgress = averageProgress/activity.activities.length;

            let assignedClass = '';
            if (currentActivity.assignTo && currentActivity.assignTo.length > 0)
            {
                for(let a=0; a<currentActivity.assignTo.length; a++)
                {
                    if (a === currentActivity.assignTo.length-1)
                    {
                        assignedClass += currentActivity.assignTo[a];
                    }
                    else
                    {
                        assignedClass += currentActivity.assignTo[a]+', ';
                    }
                }
            }
            actRows.push(
                <tr key={Math.random()} id={currentActivity.activityID}>
                    <td style={{width:'30%'}}>
                        <div style={{fontStyle:'bold', color:"#0f6fb4" ,'width': '100%', 'white-space': 'nowrap', 'overflow': 'hidden', 'text-overflow': 'ellipsis'}} title={activity.activityName}>
                            <strong>{currentActivity.activityName}</strong>
                        </div>
                    </td>
                    <td style={{width:'15%'}}>
                        {Utility.ucFirst(currentActivity.activityType)}
                    </td>
                    <td style={{width:'15%'}}>
                        {averageProgress? parseFloat(averageProgress).toFixed(0): 0}{'%'}
                    </td>
                    <td style={{width:'15%'}}>
                        {averageScore ? averageScore.toFixed(0):0}{'%'}
                    </td>
                    {/*<td style={{width:'15%'}}>*/}
                    {/*{assignedClass}*/}
                    {/*</td>*/}
                    <td style={{width:'15%'}}>
                        {averageTimeIn ? (parseHours(averageTimeIn) + ':' + parseMinutes(averageTimeIn) + ':' + parseSeconds(averageTimeIn)):'0:0:0'}
                    </td>
                    {/*<td style={{width:'15%'}}>*/}
                    {/*{numberOfAttempts}*/}
                    {/*</td>*/}
                    <td style={{width:'10%'}} className="viewUserDetLink">
                    <style>{"\
                            i{\
                            float:none !important;\
                            }\
                        "}</style>
                        <a href="javascript:void(0)" onClick={
                            function(){
                                this.viewStudentDetails(activity.activities, this.state.selectedStudent)
                            }.bind(this)
                        }>
                            <MaterialIcon icon="search" size={24}/>
                        </a>
                    </td>
                </tr>
            );
            // }
        });
        let studentStats = <table className="table" id="student-attempt-history">
            <thead>
            <tr>
                <th>
                </th>
                <th>
                    Average
                </th>
                <th>
                    Total Questions
                </th>
                <th>
                    Answered Correctly
                </th>
                <th>
                    Answered Incorrectly
                </th>
            </tr>
            </thead>
            <tbody>
            {rows}
            </tbody>
        </table>;

        let activityStats = <table className="table" id="student-attempt-history">
            <thead>
            <tr>
                <th>
                    Activity Name
                </th>
                <th>
                    Activity Type
                </th>
                <th>
                    Progress
                </th>
                <th>
                    Score
                </th>
                <th>
                    Time In
                </th>
                {/*<th>*/}
                {/*Attempts*/}
                {/*</th>*/}
                <th>
                    View Details
                </th>
            </tr>
            </thead>
            <tbody>
            {actRows}
            </tbody>
        </table>;

        let table = <div id="user-activity-analysis" className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                {
                    this.state.selectedSubject &&
                    <p className="subjectScoreHeading" style={{width:'25%'}}>
                        {this.state.selectedSubject} : <span style={{color:subjectScore < 65 ? 'red':'green'}} className="subjectScoreHeadingSpan">{subjectScore===NaN ? 0:subjectScore}{'%'}</span>
                    </p>
                }
                {
                    !this.state.selectedSubject && <p className="subjectScoreHeading" style={{width:'25%'}}>
                        Select a Subject
                    </p>
                }
                <p style={{marginLeft:'50px', marginTop: '-30px', fontStyle:'italic'}}>
                    {this.state.selectedStudent?this.state.selectedStudent.stdFirstName + ' ' + this.state.selectedStudent.stdLastName:this.state.currentReportName}
                </p>
                <p style={{overflow: 'auto'}}>
                    {studentStats}
                </p>
            </div>
        </div>;
        let activityTable = <div id="user-activity-analysis" className="row">
            <span className="studentNameHeading">{this.state.studentNameForAdminReportForSingleStudent}</span><br/>
            <span className="teacherNameHeading">{this.state.teacherNameforAdminReportForSingleStudent}</span>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12" style={{ marginTop: "12px"}}>
                <p style={{overflow: 'auto'}}>
                    {activityStats}
                </p>
            </div>
        </div>;

        let onReportChange = function (evt) {
            if (evt.target.value == 'gradeTracker') {
                browserHistory.push({
                    pathname: '/reports/students/' + this.state.selectedSubject,
                    state: { forAdminReportForSingleStudent: true, studentIDforAdminReportForSingleStudent: this.state.studentIDforAdminReportForSingleStudent, teacherIDforAdminReportForSingleStudent: this.state.teacherIDforAdminReportForSingleStudent, teacherNameforAdminReportForSingleStudent: this.state.teacherNameforAdminReportForSingleStudent }
                });
            }
            else {
                this.setState({
                    selectedReport: evt.target.value,
                })
            }
        }.bind(context);

        let onChange = function (evt) {
            this.setState({
                selectedType: evt.target.value
            })
        }.bind(context);

        let subjects = this.state.subjects;
        let nodes = [];
        if(subjects){
            subjects.forEach(sub => {
                nodes .push({id:sub.id, name:sub.name});
            });
        }

        return (
            <div id="TeacherStudentReport">
                <Heading
                    isQuery={false}
                    customURL={ElasticSearchTypes.StudentAssignment}
                    location = "studentAssignment"
                    actionName = {RECEIVED_STUDENT_ASSIGNMENT_RESULTS}
                    heading="Assignment Reports"
                    isSearch={false}
                    isShowButton={false}
                    screenNameForElasticSearch = {this.props.auth.user.userName}
                    allColumnsForElasticSearch = {ElasticSearchColumns.StudentAssignment}
                    breadcrumbsData={this.state.forAdminReportForSingleStudent ? [
                        {
                            name: 'Reports', action: () => {
                                browserHistory.push('/reports');
                            }
                        },
                        {
                            name: "Admin Report", action: () => {
                                browserHistory.push({
                                    pathname: '/reports/admin-report',
                                    state: { selectedSubject: this.state.selectedSubject }
                                })
                            }
                        },
                        {
                            name: "Grade Tracker", action: () => {
                                browserHistory.push({
                                    pathname: '/reports/students/' + this.state.selectedSubject,
                                    state: { forAdminReportForSingleStudent: true, studentIDforAdminReportForSingleStudent: this.state.studentIDforAdminReportForSingleStudent, teacherIDforAdminReportForSingleStudent: this.state.teacherIDforAdminReportForSingleStudent, teacherNameforAdminReportForSingleStudent: this.state.teacherNameforAdminReportForSingleStudent }
                                });
                            }
                        },
                        {
                            name: "Student Activity Report"
                        },
                    ] : [
                            {
                                name: 'Reports', action: () => {
                                    browserHistory.push('/reports');
                                }
                            },
                            {
                                name: 'Student Activity Report'
                            }
                        ]}
                />
                {this.state.showAlert &&
                <div className="row">
                    <div className="col-md-3 col-xs-3"/>
                    <div className="col-md-6 col-xs-3">
                        <Alert bsStyle={this.state.alertStyle}
                               onDismiss={this.handleAlertDismiss}>
                            {this.state.alertText}
                        </Alert>
                    </div>
                    <div className="col-md-3 col-xs-3"/>
                </div>
                }
                <div id="tableFiltersAndSideView">
                    <div className={classnames("container-fluid noPad listMainBoxWrapper")}>




                        {this.state.forAdminReportForSingleStudent && <div className="backButtonDiv" style={{ marginTop: "21px" }}>

                            {/* <span className="backButton btn btn-default" onClick={() => {
                                browserHistory.push({
                                    pathname: '/reports/admin-report',
                                    state: { selectedSubject: this.state.selectedSubject }
                                })

                            }}><MaterialIcon icon="arrow_back" size={17} /> Admin Report</span>
                             */}

                            <span style={{ /*marginTop: "40px"*/ }} className="backButton btn btn-default" onClick={() => {
                                browserHistory.push({
                                    pathname: '/reports/students/' + this.state.selectedSubject,
                                    state: { forAdminReportForSingleStudent: true, studentIDforAdminReportForSingleStudent: this.state.studentIDforAdminReportForSingleStudent, teacherIDforAdminReportForSingleStudent: this.state.teacherIDforAdminReportForSingleStudent, teacherNameforAdminReportForSingleStudent: this.state.teacherNameforAdminReportForSingleStudent }
                                });
                            }}><MaterialIcon icon="arrow_back" size={17} /> back</span></div>}





                        { !this.state.forAdminReportForSingleStudent && <div className="filterBlock filterBlockBox NiceScroll" id="filterInnerContainer">
                            <div className="smText hidden-lg">
                                <h3>Filter</h3>
                                <span className="HideCross" onClick={function (evt) {
                                    $('.filterBlock').removeClass('TopZero');
                                    $('html').css({'overflow': 'auto', 'position': 'inherit'});
                                    $('.footer ').show();
                                }.bind(this)}/>
                            </div>

                            <div className="filterBoxStyle overFlowScroll-sm">
                                {this.state.view === 'activities' && this.getPeriodDropdown()}
                                <hr />
                                <div className="studentList" style={{ textTransform : "capitalize" }}>
                                    <ul>
                                        {this.getStudentList(this.state.selectedPeriod)}
                                    </ul>
                                </div>
                            </div>

                            <button className="ApplyFilter hidden-md hidden-lg" onClick={(evt)=> {
                                $('.filterBlock').removeClass('TopZero');
                                $('html').css({'overflow': 'auto', 'position': 'inherit'});
                                $('.footer ').show();
                            }}> Apply
                            </button>
                        </div> }
                        <div className={this.tableCssClassName} style={ !this.state.forAdminReportForSingleStudent ? {background:'#f7f8f9', width:'75%', marginLeft:'23%', paddingBottom: "0px"} : {background:'#f7f8f9', width:'100%', marginLeft:'0%', width: 'auto', margin: "21px 10.5% 70px", float: "none", paddingBottom: "0px"}}>
                            <div className="listToBesslide NiceScroll tableBoxStyle" style={this.state.forAdminReportForSingleStudent ? {margin: "20px 0px"} : {margin: "20px"}}>
                                <div className="blockForlist ">
                                    {this.state.view === 'report-card' &&
                                    <div className="selectSubjectDropdown">
                                        <SelectFieldGroup
                                            field="selectedType"
                                            value={this.state.selectedType}
                                            options={[{id:'assignment', name:'Teacher Assignments'}, {id:'practice', name:'Self Study'} ,{id:'cumulative', name:'Cumulative'}]}
                                            placeholder="Select Type"
                                            onChange={onChange}
                                        />
                                    </div>}
                                    {this.state.view === 'report-card' &&
                                    <div className="selectSubjectDropdown">
                                        <SelectFieldGroup
                                            field="selectedSubject"
                                            value={this.state.selectedSubject}
                                            options={nodes}
                                            placeholder="Select a Subject"
                                            onChange={(evt) => {
                                                context.backToClassAggr = false;
                                                context.backToSubjectAggr = false;
                                                context.classSelected = false;
                                                let subject = evt.target.value;
                                                if(evt.target.value && evt.target.value !== 'none'){
                                                    context.setState({selectedSubject:subject}, function () {
                                                        context.loadSelectedStudentDetails(this.state.selectedStudent, 'report-card');
                                                    });
                                                    // this.loadStudentReportCard(this.state.selectedStudent.students, subject).then((result) => {
                                                    //     context.setState({
                                                    //         cumulative: result.cumulative,
                                                    //         practiceAssignment: result.practiceAssignment,
                                                    //         assignment: result.assignment,
                                                    //         selectedSubject: subject
                                                    //     });
                                                    // }, (error) => {
                                                    //     console.error(error);
                                                    // });
                                                }
                                            }}
                                        />
                                    </div>
                                    }
                                    {!this.state.forAdminReportForSingleStudent && this.state.selectedStudent && <div className="selectSubjectDropdown">
                                        <SelectFieldGroup
                                            field="selectedReportType"
                                            value={this.state.view}
                                            options={[{id:'activities', name:'Activities'}, {id:'report-card', name:'Grade Tracker'}]}
                                            placeholder="Select Report Type"
                                            onChange={function (event) {
                                                let value = event.target.value;
                                                context.setState({view:value}, function () {
                                                    if (context.state.selectedStudent && context.state.selectedSubject) {
                                                        if (context.state.view === 'activities')
                                                        {
                                                            context.loadSelectedStudentDetails(context.state.selectedStudent, 'activities');
                                                        }
                                                        else
                                                        {
                                                            context.loadSelectedStudentDetails(context.state.selectedStudent, 'report-card');
                                                        }
                                                    }
                                                });
                                            }.bind(this)}
                                        />
                                    </div>}
                                    {(this.state.forAdminReportForSingleStudent) &&
                                        <div className="selectSubjectDropdown">
                                            <SelectFieldGroup
                                                field="selectedReport"
                                                value={this.state.selectedReport}
                                                options={[{ id: 'gradeTracker', name: 'Grade Tracker' }, { id: 'studentReport', name: 'Student Report' }]}
                                                placeholder="Select Report"
                                                onChange={onReportChange}
                                            />
                                        </div>}
                                    {this.state.view === 'report-card' && table}
                                    {this.state.view === 'activities' && activityTable}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ProgressIndicator show={this.state.loading}/>
                {this.state.showUserDetailsDialog && this.getUserDetailsDialog()}
            </div>
        );
    }

    getAllSubjects() {
        let context = this;
        let subjects = [];
        this.props.auth.user.subjects.forEach(sub => {
            subjects.push({
                id: sub,
                name: sub
            });
        });
        context.setState({
            subjects: subjects
        });
    }

    loadAttemptDetails(assignmentID, studentID, activityID){
        let context = this;
        context.showIndicator();
        return new Promise((resolve, reject) => {
            let context = this;

            let params = {
                assignmentID: assignmentID,
                studentID: studentID,
                activityID: activityID
            };
            let pathTemplate = '/assignment/history/{assignmentID}/student/{studentID}/activity/{activityID}';
            let method = 'GET';
            let additionalParams = {};
            let body = {};
            apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, '4').then(function (result) {
                context.hideIndicator();
                resolve(result.data);
            }).catch(function (error) {
                context.hideIndicator();
                context.setState({
                    showAlert: true,
                    alertStyle: 'danger',
                    alertText: 'Unable to load data, please contact administrator.'
                });
                setTimeout(()=> {
                    context.setState({
                        showAlert: false,
                        alertStyle: '',
                        alertText: ''
                    })
                }, 4000);
                reject(error);
            });
        });
    }

    viewStudentDetails(allActivites ,student, index=0) {

        let context = this;
        this.showIndicator();
        this.loadStudentDetails(allActivites[index].assignmentID, student.students, allActivites[index].activityID).then(result => {
            context.hideIndicator();

            let userRecord = context.state.userRecord;
            for (let z=0; z<result.length; z++)
            {
                if (result[z].completionDate !== 'N/A')
                {
                    userRecord.push(result[z]);
                }
            }
            context.setState({
                userRecord: userRecord,
                userRecordActivityID: allActivites[index].activityID,
                userRecordActivityName: allActivites[index].activityName,
                userRecordActivityType: allActivites[index].activityType,
                currentActivity:allActivites[index]
            }, function () {
                index++;
                if (index<allActivites.length)
                {
                    context.viewStudentDetails(allActivites, student, index);
                }
                else
                {
                    if (context.state.userRecord.length>0)
                    {
                        context.setState({showUserDetailsDialog: true});
                    }
                    else
                    {
                        context.setState({
                            showAlert: true,
                            alertStyle: 'danger',
                            alertText: student.stdFirstName + ' ' + student.stdLastName +  ' has not started/completed this activity'
                        });
                        setTimeout(()=> {
                            context.setState({
                                showAlert: false,
                                alertStyle: '',
                                alertText: ''
                            })
                        }, 4000);
                    }
                }
            });
        }).catch(error=> {
            console.error(error);
            context.setState({
                showAlert: true,
                alertStyle: 'danger',
                alertText: 'Unable to load student data, please contact administrator.'
            });
            context.hideIndicator();
            setTimeout(()=> {
                context.setState({
                    showAlert: false,
                    alertStyle: '',
                    alertText: ''
                })
            }, 4000);
        })
    }

    loadStudentDetails(assignmentID, studentID, activityID){

        return new Promise((resolve, reject) => {
            let context = this;
            let params = {
                studentID: studentID,
                activityID: activityID,
                assignmentID:assignmentID
            };
            let pathTemplate = '/assignment/report/assignmentID/{assignmentID}/activity/{activityID}/student/{studentID}';
            let method = 'GET';
            let additionalParams = {};
            let body = {};
            apiClient.invokeApi(params, pathTemplate, method, additionalParams, body, '3').then(function (result) {
                resolve(result.data);
            }).catch(function (error) {
                context.hideIndicator();
                context.setState({
                    showAlert: true,
                    alertStyle: 'danger',
                    alertText: 'Unable to load data, please contact administrator.'
                });
                setTimeout(()=> {
                    context.setState({
                        showAlert: false,
                        alertStyle: '',
                        alertText: ''
                    })
                }, 4000);
                reject(error);
            });
        });
    }

    getUserDetailsDialog() {
        let context = this;
        let record = this.state.userRecord[this.state.currentAttempt];
        let userRecordActivityID = this.state.userRecordActivityID;
        let userRecordActivityName = this.state.userRecordActivityName;
        let actType = this.state.userRecordActivityType;

        let categories = null;
        let catCount = 0;
        if (record.categoryStats) {
            categories = record.categoryStats.map(cat => {
                catCount++;
                let label = cat.category + (cat.subCategory ? ' - ' + cat.subCategory : '');
                return <tr key={cat.category + '-' + catCount} id={cat.category + '-' + catCount}>
                    <td>{label}:</td>
                    <td>
                        {/*{cat.correctAnswers + ' out of ' + cat.total + ' questions correct (' + cat.score.toFixed(0) + '%)'}*/}
                        {cat.score.toFixed(0)+'% ('+cat.correctAnswers+' out of '+cat.total+' questions correct)'}
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12   paddnone barchrtlghtbl"
                             style={{marginRight: '10px', marginTop: '5px', background:'white', width:'100%'}}>
                            <div className="filled" style={{width: (cat.score ? cat.score : 0) + '%', background:(cat.score && cat.score > 64 ? 'green' : 'red'), height: '9px', borderRadius: '5px'}}/>
                        </div>
                    </td>
                </tr>;
            })
        }

        let catStats = <table className="table" id="act-cat-stats-user">
            <tbody>
            {categories}
            </tbody>
        </table>;

        let rows = null;
        let correctNumbers = 0;
        let rowCount = 0;
        let htmlToReactParser = new Parser();
        if (record.questionStats) {
            rows = record.questionStats.map(question => {
                rowCount++;

                if (question.essayType && question.essayType == "true") {
                    if (question.essayScore != "N/A") {
                        question.questionResult = question.essayScore < 65 ? false : true
                    }
                    if (!question.essayComments && question.essayScore && question.essayScore != "N/A") {
                        question.essayComments = "<i>No Comments</i>"
                    }
                }

                if (question.questionResult) {
                    correctNumbers += 1;
                }

                let answerString = undefined;

                if (actType === 'question') {
                    answerString = (question.studentAnswer.trim() !== 'empty-result' && question.studentAnswer.trim() !== 'empty-answer') ? question.studentAnswer : 'No Answer Submitted';
                }
                return <tr dir lang = { this.props.auth.user.prefLanguage } className="" id={rowCount + "-user-row-" + rowCount}
                    key={rowCount + "-user-row-" + question.questionID}>
                    <td style={{ border: '1px solid #555555' }}>
                        <p className="QuestionNoBorder">
                            <strong style={{ float: 'left' }}>{rowCount + '. '} {question.questionResult ? <span style={{ color: 'green' }}><MaterialIcon icon="check" size={32} /></span> : <span style={{ color: 'red' }}><MaterialIcon icon="close" size={32} /></span>}</strong>{htmlToReactParser.parse(`<span dir lang = ${this.props.auth.user.prefLanguage}>` + question.question.replace('[[[LP_ANSWERS]]]', '') + '</span>')}
                        </p>
                        <br />

                        {question.answer && <div>
                            <div className="std-q-heading">Correct Answer</div>
                            <p>
                                {htmlToReactParser.parse(`<span dir lang = ${this.props.auth.user.prefLanguage}>` + question.answer + '</span>')}
                            </p>
                        </div>
                        }

                        <div className="std-q-heading">Student&apos;s Response</div>
                        <p style={{ whiteSpace: "pre-line", textAlign: "justify" }}>
                            {htmlToReactParser.parse(`<span dir lang = ${this.props.auth.user.prefLanguage}>` + answerString + '</span>')}
                        </p>

                        {question.essayType && question.essayType == "true" && question.essayComments ? <div>
                            <div className="std-q-heading">Teacher&apos;s Comment</div>
                            <p>
                                {htmlToReactParser.parse(`<span dir lang = ${this.props.auth.user.prefLanguage}>` + question.essayComments + '</span>')}
                            </p>
                        </div> : ""
                        }

                        {question.essayType && question.essayType == "true" && question.essayScore ? <div>
                            <div className="std-q-heading">Score</div>
                            <p>
                                {question.essayScore == "N/A" ? htmlToReactParser.parse('<span>' + question.essayScore + '</span>') : ( question.essayScore < 65 ? (htmlToReactParser.parse('<span style=" color: red; ">' + question.essayScore + '</span>')) : (htmlToReactParser.parse('<span style=" color: green; ">' + question.essayScore + '</span>')) )}
                            </p>
                        </div> : ""
                        }

                    </td>
                </tr>;
            })
        }

        let activityStats = <table className="table" id="act-stats-user">
            <tbody>
            <tr>
                <td><MaterialIcon icon="assignment" size={24}/> Activity Name:</td>
                <td>{userRecordActivityName}</td>
            </tr>
            <tr>
                <td><MaterialIcon icon="accessibility" size={24}/> Student Name:</td>
                <td>{record.studentName}</td>
            </tr>
            <tr>
                <td><MaterialIcon icon="assessment" size={24}/> Score:</td>
                {/*<td>{correctNumbers + ' out of ' + (record.questionStats? record.questionStats.length:0)  + ' questions correct (' +record.score.toFixed(0)}{'%)'}</td>*/}
                <td>{record.score.toFixed(0)+'% (' + correctNumbers + ' out of ' + (record.questionStats? record.questionStats.length:0)+ ' questions correct)'}</td>
            </tr>
            <tr>
                <td><MaterialIcon icon="alarm" size={24}/> Time In:</td>
                <td>{parseHours(record.timeIn)}{' hours '}{parseMinutes(record.timeIn)}{' mins '}{parseSeconds(record.timeIn)}{' seconds'}</td>
            </tr>
            <tr>
                <td><MaterialIcon icon="date_range" size={24}/> Completion Date:</td>
                {record.completionDate !== "N/A" && <td>{new Date(record.completionDate).toLocaleString()}</td>}
                {record.completionDate === "N/A" && <td>{record.completionDate}</td>}
            </tr>
            </tbody>
        </table>;

        let studentStats = <table className="table" id="student-stat">
            <thead>
            <tr>
                <th style={{background: '#f7f8f9', border:'1px solid #555555'}}>
                    Questions
                </th>
                {actType === 'question' && false && <th>
                </th>}
            </tr>
            </thead>
            <tbody>
            {rows}
            </tbody>
        </table>;

        let table = <div id="user-activity-analysis" className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <h1 className="reportHeading">Student Details </h1>
                <p style={{overflow:'auto'}}>
                    {activityStats}
                </p>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <h1 className="reportHeading"  >Category Analysis </h1>
                <p style={{overflow:'auto'}}>
                    {catStats}
                </p>
            </div>
            {actType === 'question' &&
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <h1 className="reportHeading">Student Question Analysis </h1>
                <p style={{overflow: 'auto'}}>
                    {studentStats}
                </p>
            </div>}
        </div>;

        // let context = this;
        // let attemptsArray = [];
        // let allAttemptWiseActivities = this.state.allAttemptWiseActivities;
        //
        // let index = allAttemptWiseActivities.findIndex(x=> x.activityID === this.state.userRecordActivityID);
        //
        // allAttemptWiseActivities[index].activities.sort(function (a, b) {
        //     return (new Date(b.attemptedDate) - new Date(a.attemptedDate))
        // });
        // if (index !== -1)
        // {
        //     allAttemptWiseActivities[index].activities.forEach(attempt=>{
        //         attemptsArray.push({id:attempt.assignmentID+':'+attempt.studentID, name:new Date(attempt.attemptedDate).toLocaleString()})
        //     });
        // }
        let attemptsArray = [];
        let allStudentsData = this.state.userRecord;

        allStudentsData.sort(function (a,b) {
            return new Date(b.completionDate) - new Date(a.completionDate);
        });
        let counter=0;

        allStudentsData.forEach(attempt=>{
            attemptsArray.push({id:counter, name:new Date(attempt.completionDate).toLocaleString()});
            counter++;
        });
        return <Modal id="user-detail-dialog"
                      show={this.state.showUserDetailsDialog}
                      onHide={()=> {
                          this.setState({showUserDetailsDialog: false, userRecord: [], userRecordActivityID:null});
                      }}
                      container={this}
                      aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Student Details</Modal.Title>
                {attemptsArray.length > 1 && <div className="col-md-4" style={{marginTop:'20px'}}>
                    <SelectFieldGroup
                        field="currentAttempt"
                        value={this.state.currentAttempt}
                        options={attemptsArray}
                        onChange={(event)=>{
                            context.setState({currentAttempt:event.target.value});
                            // context.setState({showUserDetailsDialog:false,currentAttempt:value}, function () {
                            //     let rawData = value.split(':');
                            //     let assignmentID = rawData[0];
                            //     let studentID = rawData[1];
                            //     context.getAttemptWiseAnalysis(assignmentID, studentID, context.state.currentActivity.activityID);
                            // });
                        }}
                    />
                </div>}
            </Modal.Header>
            <Modal.Body>
                <div id="choice-dialog-content">
                    {table}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={()=> {
                    this.setState({showUserDetailsDialog: false, userRecord: [], userRecordActivityID:null});
                }}>Close</Button>
            </Modal.Footer>
        </Modal>
    }

    getPeriodDropdown() {
        let context = this;
        let periods = [];
        if (context.state.periods) {
            let sorted = context.state.periods.sort((a, b) => {
                return a.period > b.period;
            });
            periods.push({id:'All Students', name:'All Students'});
            sorted.forEach(period => {
                periods.push({id:period.period, name:period.period});
            });
        }
        return <SelectFieldGroup
            id="periodSelect"
            field="periodSelect"
            value={this.state.selectedPeriod}
            options={periods}
            onChange={(evt)=> {

                let value = evt.target.value;
                let text = evt.target.selectedOptions[0].innerText;
                context.setState({
                    selectedPeriod: value
                }, function () {

                    // if (text !== 'All Students')
                    // {
                    //     context.loadAllStudentByPeriodDetails(text)
                    // }
                    // else
                    // {
                    //     context.loadAllStudentDetails();
                    //     this.backToSubjectAggr = false;
                    //     this.backToClassAggr = false;
                    //     this.classSelected = false;
                    //     this.setState({selectedStudent:null});
                    // }
                });
            }}
        />
    }

    showIndicator() {
        this.setState({
            ...this.state,
            loading: true
        });
    }

    hideIndicator() {
        this.setState({
            ...this.state,
            loading: false
        });
    }

    getTeacherStudentReport() {
        let {dispatch}= this.props;
        let context = this;
        ElasticSearchActions.setElasticSearchAttributes(dispatch,'studentAssignment'.toString(),'', parseInt($('#pageSizeDropDown').val())?parseInt($('#pageSizeDropDown').val()):10, 1 ,{createdAt:{order: "desc"}}, []);
        let elasticSearchQuery  = new ElasticSearchQuery();
        elasticSearchQuery.query("stdassign", ElasticSearchColumns.StudentAssignment, this.props,
            '', parseInt($('#pageSizeDropDown').val())?parseInt($('#pageSizeDropDown').val()):10, 1 ,{createdAt:{order: "desc"}}, [], function (results, totalRecords) {
                dispatch({
                    type: RECEIVED_STUDENT_ASSIGNMENT_RESULTS,
                    status: 200,
                    data: JSON.parse(JSON.stringify(results)),
                    alertText: '',
                    alertStyle: '',
                    showAlert: false,
                    totalRecords:totalRecords
                });
            }, true, 'studentID: ("'+ (context.state.teacherIDforAdminReportForSingleStudent ? context.state.teacherIDforAdminReportForSingleStudent : context.props.auth.user.userName) +'")', 'lp-student/');
    }
}

function mapStateToProps(state) {
    return {
        ...state
    }
}

export default connect(mapStateToProps)(StudentActivityReport);
