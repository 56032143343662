/**
 * Created by ashahab on 6/18/2017.
 */

import React from 'react';
import PropTypes from 'prop-types';


export const RadioButton = (props) =>
    (
        <div className="fieldBox">
        <input type="radio" className="css-checkbox css-label1 bc_info card_side" value="both" id={props.id} name="choice" checked={props.isChecked}/>
        <label className="css-label radGroup1" htmlFor={props.id}>{props.lableValue}</label>
    </div>
    );


RadioButton.propTypes = {
    id: PropTypes.string.isRequired,
    isChecked: PropTypes.bool,
    lableValue: PropTypes.string.isRequired
};
