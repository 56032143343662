import React, { Component } from 'react';
import TextFieldGroup from "../forms/text-field-group";
import SelectFieldGroup from "../forms/select-field-group";
import AutoCompleteGroup from "../forms/auto-complete-group";
import { Button, Modal, Alert } from "react-bootstrap";
import { getIdentityProfile } from '../../actions/login-action';
import ProgressIndicator from "../progress-indicator/progress-indicator";
import apiClient from "../../actions/api-client";
import Validator from 'validator';
import isEmpty from 'lodash/isEmpty';
import $ from "jquery";
import { browserHistory } from 'react-router';
import ClevertapReact, { event } from "clevertap-react";
import Recaptcha from 'react-gcaptcha';
import { detect } from 'detect-browser';
import ElasticSearchQuery from "../../actions/elastic-search-query";
import { connect } from "react-redux";
import virtualLogo from '../../assets/images/virtual-logo.png'
import stemLabsLogo from '../../assets/images/stem-labs.png'
import "./custom.css";

class SignupForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            userName: '',
            firstName: '',
            lastName: '',
            password: '',
            confirmPassword: '',
            emailAddress: '',
            confirmEmail: '',
            isTeacherFields: false,
            department: '',
            allDepartments: [],
            schoolName: '',
            teacherLicense: '',
            allSubjects: [],
            selectedSubjects: [],
            registrationTypeSelected: false,
            universalCode: '',
            adminLicense: '',
            alertText: "",
            alertStyle: "",
            showAlert: false,
            showTeacherConfirmationDialog: false,
            isStemSelected: false
        };

        this.onChange = this.onChange.bind(this);
        this.verifyCode = this.verifyCode.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.getAllDepartments = this.getAllDepartments.bind(this);
        this.getAllSubjects = this.getAllSubjects.bind(this);
        this.getValidationState = this.getValidationState.bind(this);
        this.setSubjectsHtml = this.setSubjectsHtml.bind(this);
        this.deleteTags = this.deleteTags.bind(this);
        this.isValid = this.isValid.bind(this);
        this.onChangeDepartment = this.onChangeDepartment.bind(this);

    }

    onChange(e) {
        // if (e.target.name === "teacherCode" && e.target.value != '' && !this.props.auth.isAuthenticated) { this.state.errorGoogle = 'Google Classroom classes should be entered in google classroom'; } else { this.state.errorGoogle = ''; }
        this.setState({ [e.target.name]: e.target.value });
    }

    verifyCode(code) {
        if(!code)
            return;
        let that = this;
        let params = {
        type: this.state.registrationType == 'principal'? 'admin': this.state.registrationType,
        code: code,
        };
        let pathTemplate = "/subjects/{type}/{code}";
        apiClient
        .invokeApi(params, pathTemplate, "GET", {}, {})
        .then(function (result) {
            let sortedSubjects = result.data;
            sortedSubjects.sort(function (a, b) {
            return a.id.localeCompare(b.id);
            });
            that.setState({ allSubjects: sortedSubjects });
        })
        .catch(function (result) {
            that.setState({ allSubjects: []});
        });
    }

    onChangeDepartment(e) {
        if (e.target.value == "") {
            this.setState({ [e.target.name]: e.target.value, selectedSubjects: [] });
        } else {
            this.setState({ [e.target.name]: e.target.value });
        }
    }

    componentDidMount() {
        let userObject = this.props.auth.user;
        if (!this.props.auth.isAuthenticated) {
            this.setState({ newUser: true })
        } else {
          const { userName, firstName, lastName, email } = userObject;
          this.setState({
            lastName: lastName,
            firstName: firstName,
            emailAddress: email,
            userName: userName,
          });
        }
        // this.getAllDepartments();
        this.getAllSubjects();
    }

    isValid() {
        const { errors, isValid } = this.getValidationState();
        if (!isValid) {
            this.setState({ errors });
        }
        return isValid;
    }

    getValidationState() {
        let errors = {};
        if (this.state.registrationType != "admin" && this.state.registrationType != "principal" && this.state.registrationType != "student" && this.state.registrationType != "teacher") {
            errors.registrationType = "Please select user type";
        }
        if (this.state.newUser && this.state.password.length < 6) {
            errors.password = 'Your password must be at least 6 characters';
        }
        if (this.state.newUser && (this.state.password !== this.state.confirmPassword)) {
            errors.password = 'Passwords do not match';
            errors.confirmPassword = 'Passwords do not match';
        }
        if (this.state.newUser && (Validator.isEmpty(this.state.password))) {
            errors.newPassword = 'This field is required';
        }
        if (this.state.newUser && (Validator.isEmpty(this.state.confirmPassword))) {
            errors.confirmPassword = 'This field is required';
        }
        if (this.state.newUser && (Validator.isEmpty(this.state.userName))) {
            errors.userName = 'This field is required';
        }
        if (this.state.newUser && (this.state.userName.trim().indexOf(" ") > -1)) {
            errors.userName = 'The username cannot contain spaces';
        }
        if (this.state.newUser && (this.state.userName.search(/[!@#$%^&*]/) > -1)) {
            errors.userName = 'Special characters are not allowed in the user name';
        }
        if (this.state.userName.startsWith('_')) {
            errors.userName = 'username can not start with _';
        }

        if (Validator.isEmpty(this.state.firstName)) {
            errors.firstName = 'This field is required';
        }
        if (Validator.isEmpty(this.state.lastName)) {
            errors.lastName = 'This field is required';
        }

        if (this.state.newUser && (this.state.confirmEmail.toLowerCase() !== this.state.emailAddress.toLowerCase())) {
            errors.emailAddress = 'Email addresses do not match';
            errors.confirmEmail = 'Email addresses do not match';
        }
        if (!/\S+@\S+\.\S+/.test(this.state.emailAddress)) {
            errors.emailAddress = 'Incorrect email address';
        }
        if (this.state.newUser && (Validator.isEmpty(this.state.emailAddress))) {
            errors.emailAddress = 'This field is required';
        }
        if (this.state.newUser && (Validator.isEmpty(this.state.confirmEmail))) {
            errors.confirmEmail = 'This field is required';
        }
        if (this.props.auth.isAuthenticated && !(Validator.isEmpty(this.state.teacherCode))) {
            errors.teacherCode = 'Google Classroom Enrollment code needs to be entered in Google Classrooms';
        }
        if (this.state.isTeacherFields) {
            if (this.state.selectedSubjects.length < 1) {
                errors.subjects = 'This field is required';
            }
            if (this.state.registrationType === "teacher" && !this.state.teacherLicense) {
                if (this.state.registrationType === "teacher" && Validator.isEmpty(this.state.schoolName)) {
                    errors.schoolName = 'This field is required';
                }
            }
        }
        if (this.state.isAdmin) {
            if (this.state.registrationType === "admin" && !this.state.adminLicense) {
                if (this.state.registrationType === "admin" && Validator.isEmpty(this.state.schoolName)) {
                    errors.schoolName = 'This field is required';
                }
            }
        }
        if (this.state.schoolName && typeof this.state.schoolName === 'string' && !this.state.schoolName.trim()) {
            errors.schoolName = 'This field is required';
        }
        
        return {
            errors,
            isValid: isEmpty(errors)
        }
    }

    onSubmit() {
        let state = this.state;

        if (this.isValid()) {
            let body = {
                userName: state.userName.toLowerCase().trim(),
                firstName: state.firstName.trim(),
                lastName: state.lastName.trim(),
                email: state.emailAddress.trim(),
                password: state.password,
                userType: state.registrationType == "principal" ? 'admin' : state.registrationType,
            };
            if (state.newUser) {
                body.password = state.password
            }

            if (state.department) {
                body.department = state.department;
            }
            if (state.selectedSubjects) {
                body.subjects = state.selectedSubjects;
            }
            if (state.teacherLicense) {
                body.licenseCode = state.teacherLicense.trim();
            }
            else if (state.schoolName) {
                body.trialSchoolName = state.schoolName;
            }

            if (state.teacherCode) {
                if (state.teacherCode.substring(state.teacherCode.length - 10).startsWith("student")) {
                    body.licenseCode = state.teacherCode.trim();
                }
                else {
                    body.enrollmentCode = state.teacherCode.trim();
                }
            }
            if (state.adminLicense) {
                body.licenseCode = state.adminLicense.trim();
            }

            if (state.registrationType == "principal") {
                body.isPrincipal = true;
            }

            let that = this;
            that.showIndicator();
            // apiClient.invokeApi({},'/user/registration', 'POST', {}, body).then((result) => {
            let additionParams = {};
            if (!that.state.newUser) {
                additionParams.headers = {};
                additionParams.headers.accessToken = localStorage.getItem('accessToken')
            }
            if(this.state.isStemSelected){
                body.schoolTheme = 'stemLabs'
            } else {
                body.schoolTheme = 'virtual'
            }
            apiClient.invokeApi({}, '/user/register', 'POST', additionParams, body, "5").then((result) => {
                that.showIndicator();
                ClevertapReact.event("User Signed Up", { 'userName': that.state.userName });
                that.setState({ 'username': that.state.userName.toLowerCase().trim() });
                if (!that.state.newUser) {
                    that.props.getIdentityProfile(that.state.userName).then(
                        (res) => {
                            that.hideIndicator();
                            browserHistory.push(res)
                        },
                        (err) => {
                            that.setState({ isLoading: false, errors: err });
                            that.hideIndicator()
                        }
                    )
                    // var params = {
                    //     userName: that.state.username,
                    // };

                    // var pathTemplate = '/user/{userName}';
                    // var method = 'GET';
                    // var additionalParams = {};
                    // var body = {};

                    // apiClient.invokeApi(params, pathTemplate, method, additionalParams, body)
                    //     .then(function (result) {
                    //         that.hideIndicator();
                    //         result.data.multiLingualLicense == false && (result.data.prefLanguage = 'en');
                    //         localStorage.setItem('userObj', JSON.stringify(result.data));
                    //         browserHistory.push(result.data.userType === "student" ? "/student/dashboard" : "/dashboard");
                    //     })
                    //     .catch(function (err) {
                    //         console.log(err);
                    //     });
                }
                else if (state.registrationType.toLowerCase() === 'teacher') {
                    that.setState({ showTeacherConfirmationDialog: true });
                    this.hideIndicator();
                } else {
                    that.props.userLoginRequest(that.state).then(
                        (res) => {

                            console.log("enrollmentCode--->" + state.teacherCode.trim());
                            if ((state.teacherCode) && (!state.teacherCode.substring(state.teacherCode.length - 10).startsWith("student"))) {


                                let body2 = {
                                    userName: state.userName.toLowerCase().trim(),
                                    enrollmentCode: state.teacherCode.trim(),
                                };

                                apiClient.invokeApi({}, '/assignment/student', 'POST', {}, body2, "5").then((result) => {


                                    let objBrowserPath = { pathname: res };
                                    if (result.data.assignmentCount > 0) {
                                        let message = "Please refresh the page momentarily as they will appear on your Student Dashboard under Open Assignments.";
                                        objBrowserPath.state = { showDialog: true, dialogTitle: "You Have Open Assignments!", dialogMessage: message };
                                    }


                                    browserHistory.push(objBrowserPath);
                                    console.log("Result--->" + JSON.stringify(result));
                                    that.hideIndicator();

                                });
                            }
                            else {
                                browserHistory.push(res);
                                that.hideIndicator();
                            }
                        },
                        (err) => {
                            that.setState({ isLoading: false, errors: err });
                            that.hideIndicator();
                        }
                    );

                }
            }, (error) => {

                let resErr = {};

                if (error.response) { resErr.message = error.response.data }
                else { resErr.message = error.message }

                that.setState({ isLoading: false, errors: resErr });
                that.hideIndicator();
            }).catch(function (result) {
                console.log(result);
                that.hideIndicator();
                let resErr = {
                    message: result.data
                };

                that.setState({ errors: resErr });
                // that.setState({alertText: result, alertStyle: "danger", showAlert: true});
                // setTimeout(()=>{
                //     that.setState({...that.state, showAlert: false});
                // }, 5000);


            });
        }
    }
    setSubjectsHtml(event) {
        let keyword = '';
        if (event.target.selectedOptions && event.target.selectedOptions[0]) {
            keyword = event.target.selectedOptions[0].value;
        } else {
            keyword = event.nativeEvent.srcElement.value;
        }
        let tempStateSubjects = this.state.allSubjects;

        for (let i = 0; i < tempStateSubjects.length; i++) {
            if (tempStateSubjects[i] === keyword) {
                return;
            }
        }
        this.setState({ selectedSubjects: this.state.selectedSubjects.concat(keyword) });
    }

    showIndicator() {
        this.setState({
            ...this.state,
            loading: true
        });
    }

    hideIndicator() {
        this.setState({
            ...this.state,
            loading: false
        });
    }

    getAllDepartments() {
        let that = this;
        apiClient.invokeApi({}, '/departments', 'GET', {}, {}).then(function (result) {
            let sortedDepartments = result.data;
            sortedDepartments.sort(function (a, b) {
                return a.id > b.id;
            });
            that.setState({ allDepartments: sortedDepartments });
        }).catch(function (result) {

        });
    }
    getAllSubjects() {
        let that = this;
        let params = {
            schoolName: "na",
            ownerName: "na",
        };
        let pathTemplate = '/subjects/ownerName/{ownerName}/schoolName/{schoolName}';
        apiClient.invokeApi(params, pathTemplate, 'GET', {}, {}).then(function (result) {
            let sortedSubjects = result.data;
            sortedSubjects.sort(function (a, b) {
                return a.id.localeCompare(b.id);
            });
            that.setState({ allSubjects: sortedSubjects });
        }).catch(function (result) {

        });
    }

    deleteTags(event) {

        $('#subjects').prop('selectedIndex', 0);
        let tempTaggedSubjects = this.state.selectedSubjects;
        for (let a = 0; a < tempTaggedSubjects.length; a++) {
            if (tempTaggedSubjects[a] === event.target.parentNode.firstElementChild.value) {
                tempTaggedSubjects.splice(a, 1);
            }
        }
        this.setState({ selectedSubjects: tempTaggedSubjects });
    }


    getTeacherConfirmationDialog() {
        let body = <div id="deleteDialogContent">
            <p>
                Do you want to create your classes now?
            </p>
        </div>;
        return <Modal
            show={this.state.showTeacherConfirmationDialog}
            onHide={() => {
                this.setState({ showTeacherConfirmationDialog: false });
                this.showIndicator();
                this.props.userLoginRequest(this.state).then(
                    (res) => {
                        browserHistory.push(res);
                        this.hideIndicator();
                    },
                    (err) => {
                        this.setState({ isLoading: false, errors: err });
                        this.hideIndicator();
                    }
                );
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Congratulations, you have successfully registered!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    this.setState({ showTeacherConfirmationDialog: false });
                    this.showIndicator();
                    this.props.userLoginRequest(this.state).then(
                        (res) => {
                            browserHistory.push(res);
                            this.hideIndicator();
                        },
                        (err) => {
                            this.setState({ isLoading: false, errors: err });
                            this.hideIndicator();
                        }
                    );
                }}>No</Button>
                <Button onClick={() => {
                    this.setState({ showTeacherConfirmationDialog: false });
                    this.showIndicator();
                    this.props.userLoginRequest(this.state).then(
                        (res) => {
                            browserHistory.push('/students');
                            this.hideIndicator();
                        },
                        (err) => {
                            this.setState({ isLoading: false, errors: err });
                            this.hideIndicator();
                        }
                    );
                }} bsStyle='primary'>Yes</Button>
            </Modal.Footer>
        </Modal>;
    }

    callback(key) {
        console.log(key);
    }

    loaded() {
        console.log('recaptcha Loaded');
    }

    render() {
        let that = this;
        let state = that.state;
        let errors = state.errors;
        let allSubjectsHtml = [];





        // if (state.isPrincipal) {
        //     state.selectedSubjects = [
        //         "Algebra 1",
        //         "CC Geometry",
        //         "Chemistry",
        //         "Earth Science",
        //         "Global History and Geography",
        //         "High School English",
        //         "Living Environment",
        //         "Order ",
        //         "U.S. History and Government"
        //     ];
        //     state.department = "Special Education";
        // } else {
        //     state.selectedSubjects = []
        //     state.department = "";
        // }


        if (state.isPrincipal) {
            state.department = ""
            let selectedSubjects = this.state.allSubjects.map(obj=> obj.id);
            // let selectedSubjects = this.state.relationship.filter(relation => relation.department == state.department);
            // selectedSubjects = selectedSubjects[0] ? selectedSubjects[0].subjects : [];
            // state.selectedSubjects = selectedSubjects[0] ? selectedSubjects[0].subjects : [];
            if (selectedSubjects.length != 0) {
                // issue because it uses actual object of relationship
                // selectedSubjects.splice(selectedSubjects.indexOf("Chemistry"), 1)
                // selectedSubjects.splice(selectedSubjects.indexOf("Order"), 1)

                // selectedSubjects = selectedSubjects.filter(subject => subject != 'Chemistry' && subject != 'Order')
            }

            state.selectedSubjects = selectedSubjects;

            console.log(selectedSubjects)
        }
        // else {
        //     state.selectedSubjects = []
        //     state.department = "";
        // }







        if (state.selectedSubjects && state.selectedSubjects.length > 0) {
            for (let i = 0; i < state.selectedSubjects.length; i++) {
                allSubjectsHtml.push(<span key={state.selectedSubjects[i]} className="keywordTag">
                    <input type="hidden" name="undefined[]" value={state.selectedSubjects[i]} />{state.selectedSubjects[i]}{this.editMode && <span className="text-cross-icon" onClick={this.deleteTags} >×</span>}
                </span>);
            }
        }
        const { isLoading } = this.state;

        const browser = detect();
        const themeChanged = (event) =>{
            this.setState({isStemSelected: !this.state.isStemSelected});
            console.log(this.state.isStemSelected);
        }
        return (
            <div className="form-signup">
                <style>{"\
                    select{\
                        height: 45px !important;\
                    }\
                "}</style>
                <h1 className="form-signin-heading">LearnerPal Registration</h1>
                <div>
                    <p><strong>Select the role that best describes you</strong> (Admin & Teacher roles are eligible for a 30-day trial)</p>
                    <SelectFieldGroup
                        field="registrationType"
                        error={errors.registrationType}
                        options={[{ id: 'admin', name: 'Admin' }, { id: "principal", name: "Principal" }, { id: 'teacher', name: 'Teacher' }, { id: 'student', name: 'Student' }]}
                        value={state.registrationType}
                        placeholder="Select User Type"
                        selectWidth={false}
                        onChange={function (event) {
                            that.setState({ teacherLicense: '', adminLicense: '', universalCode: '', teacherCode: '', selectedSubjects: [], department: '' });
                            if (event.target.value === 'teacher') {
                                that.setState({ registrationTypeSelected: true, isTeacherFields: true, isAdmin: false, isPrincipal: false, registrationType: event.target.value });
                            }
                            else if (event.target.value === 'student') {
                                that.setState({ registrationTypeSelected: true, isTeacherFields: false, isAdmin: false, isPrincipal: false, registrationType: event.target.value });
                            }
                            else if (event.target.value === 'principal') {
                                that.setState({ registrationTypeSelected: true, isTeacherFields: true, isAdmin: true, isPrincipal: true, registrationType: event.target.value });
                            }
                            else {
                                that.setState({ registrationTypeSelected: true, isTeacherFields: true, isAdmin: true, isPrincipal: false, registrationType: event.target.value });
                            }
                        }} />
                    {/*<Button onClick={()=>{}}>*/}
                    {/*Register as Teacher*/}
                    {/*</Button>*/}
                    {/*<Button onClick={()=>{this.setState({registrationTypeSelected:true,isTeacherFields:false});}}>*/}
                    {/*Register as Student*/}
                    {/*</Button>*/}
                </div>
                <div>
                    {state.newUser &&
                        <div>
                            {this.props.auth.isAuthenticated === false &&
                                <TextFieldGroup
                                    error={errors.userName}
                                    label="Username:"
                                    field="userName"
                                    value={state.userName}
                                    placeholder="Enter Username"
                                    onChange={this.onChange}
                                    selectWidth={false}
                                />}
                            <TextFieldGroup
                                type='password'
                                error={errors.password}
                                label="Password:"
                                field="password"
                                value={state.password}
                                placeholder="Enter Password"
                                onChange={this.onChange}
                                selectWidth={false}
                            />
                            <TextFieldGroup
                                type='password'
                                error={errors.confirmPassword}
                                field="confirmPassword"
                                label="Confirm Password:"
                                value={state.confirmPassword}
                                placeholder="Confirm New Password"
                                onChange={this.onChange}
                                selectWidth={false}
                            />
                            <TextFieldGroup
                                error={errors.firstName}
                                field="firstName"
                                label="First Name:"
                                value={state.firstName}
                                placeholder="Enter First Name"
                                onChange={this.onChange}
                                selectWidth={false}
                            />
                            <TextFieldGroup
                                error={errors.lastName}
                                field="lastName"
                                label="Last Name:"
                                value={state.lastName}
                                placeholder="Enter Last Name"
                                onChange={this.onChange}
                                selectWidth={false}
                            />
                            <TextFieldGroup
                                error={errors.emailAddress}
                                className={this.props.auth.isAuthenticated ? 'hide': ''}
                                field="emailAddress"
                                label="Email Address:"
                                value={state.emailAddress}
                                placeholder="Enter Email Address"
                                onChange={this.onChange}
                                selectWidth={false}
                            />
                            <TextFieldGroup
                                error={errors.confirmEmail}
                                className={this.props.auth.isAuthenticated ? 'hide': ''}
                                field="confirmEmail"
                                label="Confirm Email Address:"
                                value={state.confirmEmail}
                                placeholder="Confirm Email Address" wh
                                onChange={this.onChange}
                                selectWidth={false}
                            />
                        </div>
                    }
                    {!state.newUser &&
                        <div>
                            {this.props.auth.isAuthenticated === false &&
                                <TextFieldGroup
                                    error={errors.userName}
                                    label="Username"
                                    field="userName"
                                    value={state.userName}
                                    isDisabled={true}
                                    placeholder="Enter Username"
                                    onChange={this.onChange}
                                    selectWidth={false}
                                />}
                            <TextFieldGroup
                                error={errors.firstName}
                                field="firstName"
                                isDisabled={true}
                                label="First Name:"
                                value={state.firstName}
                                placeholder="Enter First Name"
                                onChange={this.onChange}
                                selectWidth={false}
                            />
                            <TextFieldGroup
                                error={errors.lastName}
                                field="lastName"
                                isDisabled={true}
                                label="Last Name:"
                                value={state.lastName}
                                placeholder="Enter Last Name"
                                onChange={this.onChange}
                                selectWidth={false}
                            />
                        </div>
                    }
                    {state.registrationTypeSelected && <div>
                        {state.isTeacherFields &&
                            <div>
                                {state.isTeacherFields && !state.isAdmin &&
                                    <div>
                                        <p><strong>School Code: (leave blank to begin 30-day free trial)</strong></p>
                                        <a className="Activity-toolTip toolTipCus pull-right">
                                            <span className="tooltiptext">
                                                School codes are provided to schools by LearnerPal. This code will be distributed to teachers by the school
                                            </span>
                                        </a>
                                        <div className='code-verify-div'>
                                            <TextFieldGroup
                                                error={errors.teacherLicense}
                                                field="teacherLicense"
                                                value={state.teacherLicense}
                                                placeholder="Enter School Code"
                                                onChange={this.onChange}
                                                onBlur={()=>this.verifyCode(state.teacherLicense)}
                                                selectWidth={false}
                                            />
                                            <div className="btn btn-primary code-verify-button" type="button" title='Verify code to get subjects' onClick={()=>this.verifyCode(state.teacherLicense)}>
                                                Verify
                                            </div>
                                        </div>
                                    </div>
                                }
                                {((state.isTeacherFields && !this.state.teacherLicense && !state.isAdmin) || (state.isAdmin && !this.state.adminLicense)) && <TextFieldGroup
                                    error={errors.schoolName}
                                    field="schoolName"
                                    label="School Name"
                                    value={state.schoolName}
                                    placeholder="Enter School Name"
                                    onChange={this.onChange}
                                    selectWidth={false}
                                />
                                }
                                {state.isAdmin &&
                                    <div>
                                        <p><strong>Admin Code: (leave blank to begin 30-day free trial)</strong></p>
                                        <a className="Activity-toolTip toolTipCus pull-right">
                                            <span className="tooltiptext">
                                                Admin codes are provided to your school by LearnerPal.  Admin codes, when entered, provide you with access to department level reporting
                                            </span>
                                        </a>
                                        <div className='code-verify-div'>
                                            <TextFieldGroup
                                                error={errors.adminLicense}
                                                field="adminLicense"
                                                value={state.adminLicense}
                                                placeholder="Enter Admin Code"
                                                onChange={this.onChange}
                                                onBlur={()=>this.verifyCode(state.adminLicense)}
                                                selectWidth={false}
                                            />
                                            <div className="btn btn-primary code-verify-button" type="button" title='Verify code to get subjects' onClick={()=>this.verifyCode(state.adminLicense)}>
                                                Verify
                                            </div>
                                        </div>
                                    </div>}
                                {/*<TextFieldGroup*/}
                                {/*error={errors.schoolName}*/}
                                {/*field="schoolName"*/}
                                {/*label="School Name:"*/}
                                {/*value={state.schoolName}*/}
                                {/*placeholder="Enter School Name"*/}
                                {/*onChange={this.onChange}*/}
                                {/*/>*/}
                                <div style={{display:'none'}}>
                                    <p><strong>Select Department</strong></p>
                                    <a className="Activity-toolTip toolTipCus pull-right">
                                        <span className="tooltiptext">
                                            Selecting your depatment allows you to collaborate and share courses with other teachers from your department
                                    </span>
                                    </a>
                                    {browser.name !== 'safari' && browser.os.toLowerCase() !== 'ios' && browser.name !== 'crios' && <AutoCompleteGroup
                                        dropDownId="department"
                                        field="department"
                                        value={state.isPrincipal ? "All departments" : state.department}
                                        options={state.allDepartments}
                                        placeholder="Select Department"
                                        onBlur={this.onChangeDepartment}
                                        selectWidth={false}
                                        isDisabled={state.isPrincipal ? true : false}
                                    />}
                                    {(browser.name === 'safari' || browser.os.toLowerCase() === 'ios' || browser.name === 'crios') && <SelectFieldGroup
                                        dropDownId="department"
                                        field="department"
                                        value={state.isPrincipal ? "All departments" : state.department}
                                        options={state.allDepartments}
                                        placeholder="Select Department"
                                        onChange={this.onChangeDepartment}
                                        selectWidth={false}
                                        isDisabled={state.isPrincipal ? true : false}
                                    />}
                                </div>
                                <div>
                                    <p><strong>Select Subjects (Select all the subjects that you would like access to)</strong></p>
                                    <a className="Activity-toolTip toolTipCus pull-right">
                                        <span className="tooltiptext">
                                            Selected subjects determines which content you have access to.  This can be changed at any time from your profile window
                                    </span>
                                    </a>
                                    <SelectFieldGroup
                                        dropDownId="subjects"
                                        field="subjects"
                                        options={state.allSubjects}
                                        placeholder={state.isPrincipal ? "All Subjects" : "Select Subjects"}
                                        onChange={this.setSubjectsHtml}
                                        selectWidth={false}
                                        error={errors.subjects}
                                        isDisabled={state.isPrincipal || state.allSubjects.length == 0 ? true : false}
                                        title={state.isPrincipal || state.allSubjects.length == 0 ? 'Verify code to get subjects': ''}
                                    />
                                    <div>
                                        {allSubjectsHtml}
                                    </div>
                                </div>
                            </div>}
                        {!state.isTeacherFields &&
                            <div>
                                <p><strong>Enrolment Code (optional)</strong></p>
                                <a className="Activity-toolTip toolTipCus pull-right">
                                    <span className="tooltiptext">
                                        Enrollment Codes are provided to students by teachers.  Once entered, you will be added to your teachers class
                                </span>
                                </a>
                                <TextFieldGroup
                                    error={errors.teacherCode}
                                    field="teacherCode"
                                    value={state.teacherCode}
                                    placeholder="Enter Enrolment Code"
                                    onChange={this.onChange}
                                />
                                {/*<TextFieldGroup*/}
                                {/*error={errors.universalCode}*/}
                                {/*field="universalCode"*/}
                                {/*label="Universal Student Registration Code: (Optional)"*/}
                                {/*value={state.universalCode}*/}
                                {/*placeholder="Enter Universal Student Registration Code"*/}
                                {/*onChange={this.onChange}*/}
                                {/*/>*/}
                            </div>
                        }
                    </div>
                    }
                    <div className="theme-selector">
                        <img src={virtualLogo} alt="-" className={this.state.isStemSelected ? 'logoClass' : 'logoClass selected'} />
                        <span className="switch">
                            <input id="switch-rounded" type="checkbox" checked={this.state.isStemSelected} onChange={themeChanged}/>
                            <label htmlFor="switch-rounded"></label>
                        </span>
                        <img src={stemLabsLogo} alt="-"  className={this.state.isStemSelected ? 'logoClass selected' : 'logoClass '} />
                    </div>
                    <ProgressIndicator show={this.state.loading} />
                    {this.state.showAlert &&
                        <div className="row">
                            <div className="col-md-3 col-xs-3" />
                            <div className="col-md-6 col-xs-3">
                                <Alert bsStyle={this.state.alertStyle}
                                    onDismiss={this.handleAlertDismiss}>
                                    {this.state.alertText}
                                </Alert>
                            </div>
                            <div className="col-md-3 col-xs-3" />
                        </div>
                    }
                    <Recaptcha
                        sitekey='6LfynS8UAAAAABGoEbhKb0OxUrBqMIKqENtarIlV'
                        onloadCallback={this.loaded}
                        verifyCallback={this.callback}
                    />
                </div>
                <br /><br />
                {errors && <span style={{ 'color': 'red', 'fontSize': '15px' }}>{errors.message}</span>}

                <div className="text-right">
                    {this.state.newUser && <button className="btn btn-lg btn-default btn-register" onClick={() => browserHistory.push("/")}>Cancel</button>}
                    <button className="btn btn-lg btn-primary btn-register" disabled={isLoading} onClick={this.onSubmit}>Register</button>
                </div>

                {this.state.showTeacherConfirmationDialog && this.getTeacherConfirmationDialog()}

            </div>
        );
    }
}

function mapStateToProps(state = {}) {
    return { ...state };
}

export default connect(mapStateToProps, { getIdentityProfile })(SignupForm);
