/**
 * Created by ashahab on 6/18/2017.
 */
import React from 'react'
import {ActionIconTypes} from '../../actions/types';
import PropTypes from 'prop-types';


export const ActionIcons = (props) =>
{
    let cssClassName;
    let tooltip;
    let actionName;

    switch (props.iconType)
    {
        case ActionIconTypes.Add:
            cssClassName="detailAdd";
            tooltip="Add to Unit";
            actionName="Add";
            break;
        case ActionIconTypes.Assign:
            cssClassName="detailAdd";
            tooltip="Assign";
            actionName="Assign";
            break;
        case ActionIconTypes.Edit:
            cssClassName="detailEdit";
            tooltip="Edit";
            actionName="Edit";
            break;
        case ActionIconTypes.Delete:
            cssClassName="detailDelete";
            tooltip="Delete";
            actionName="Delete";
            break;
        case ActionIconTypes.Duplicate:
            cssClassName="detailDuplicate";
            tooltip="Duplicate";
            actionName="Duplicate";
            break;
        case ActionIconTypes.Print:
        cssClassName="detailPrint";
        tooltip="Print";
        actionName="Print";
        break;
        case ActionIconTypes.Share:
            cssClassName="detailShare";
            tooltip="Share";
            actionName="Share";
            break;
    }


    return(
        <span className={cssClassName} data-toggle="tooltip" title={tooltip} data-placement="bottom" data-original-title={actionName} onClick={props.action} />
    );
};



ActionIcons.propTypes = {
    iconType: PropTypes.string.isRequired
};
