import React, {Component} from "react";
import TableHeaderColumn from "./table-header-column";
import TableRow from "./table-row";
import "./maintenance-table.css";
import {connect} from "react-redux";
import ElasticSearchQuery from "../../actions/elastic-search-query";
import config from "../../config"
import ElasticSearchActions from "../../actions/elastic-search-actions";

class MaintenanceTable extends Component {
    constructor(props) {
        super(props);
        this.options = props.options;
        this.pageSize = props.options.pagination.pageSize; // saving page for change detection.
        this.screenNameForElasticSearch = props.screenNameForElasticSearch;
        this.allColumnsForElasticSearch = props.allColumnsForElasticSearch;
        this.actionName = props.actionName;
        // using state for page maintenance it only resides in this component.
        props.options.pagination.selectedPageLink = 1;
        this.state = props.options.pagination;
        this.getTableHeaders = this.getTableHeaders.bind(this);
        this.sortRecords = this.sortRecords.bind(this);
        this.getTableRows = this.getTableRows.bind(this);
        this.gotoFirstPage = this.gotoFirstPage.bind(this);
        this.gotoLastPage = this.gotoLastPage.bind(this);
        this.gotoPage = this.gotoPage.bind(this);
        this.gotoPrevPage = this.gotoPrevPage.bind(this);
        this.gotoNextPage = this.gotoNextPage.bind(this);
        this.gotoInputEnter = this.gotoInputEnter.bind(this);
        this.sendSortedRecords = this.sendSortedRecords.bind(this);
        this.buildPaginationLinks = this.buildPaginationLinks.bind(this);
        this.getSubRowsForRecord = this.getSubRowsForRecord.bind(this);
        this.getSubRow = this.getSubRow.bind(this);
        this.callElasticSearch = this.callElasticSearch.bind(this);
    }

    getTableHeaders() {
        let columnHeaders = [];
        for (let columnIndex = 0; columnIndex < this.props.options.columns.length; columnIndex++) {
            let currentColumn = this.props.options.columns[columnIndex];
            columnHeaders.push(<TableHeaderColumn header={currentColumn} sortRecords={this.sortRecords}
                                                  key={'header-' + currentColumn.name+columnIndex }/>)
        }
        return columnHeaders;
    }

    compare(a, b, columnName, type, dynamoTyped) {
        let aVal = a[columnName] ? a[columnName] : '';
        let bVal = b[columnName] ? b[columnName] : '';
        if (type === 'array') {
            if (dynamoTyped) {
                if (aVal && aVal && aVal.length > 0 && bVal && bVal && bVal.length > 0) {
                    return aVal[0].localeCompare(bVal[0]);
                    return 0;
                } else if (aVal && aVal && aVal.length > 0) {
                    return 1;
                } else {
                    return -1;
                }
            } else {
                if (aVal && aVal.length > 0 && bVal && bVal.length > 0) {
                    return aVal[0].localeCompare(bVal[0]);
                    return 0;
                } else if (aVal && aVal.length > 0) {
                    return 1;
                } else {
                    return -1;
                }
            }
        } else {
            return aVal.toString().localeCompare(bVal.toString());
            return 0;
        }
    }

    sendSortedRecords(sorted) {
        const {dispatch} = this.props;
        let dispatchData = {};
        if (this.props.sortAction) {
            dispatchData.type = this.props.sortAction.action;
            if (this.props.sortAction.attributes) {
                for (let key in this.props.sortAction.attributes) {
                    let attrVal = this.props.sortAction.attributes[key];
                    dispatchData[key] = attrVal;
                }
            }
            if (this.props.sortAction.dataPath) {
                dispatchData[this.props.sortAction.dataPath] = sorted;
            }
            dispatch(dispatchData);
        }
    }

    sortRecords(evt, sortOrder, columnName, type = 'text', dynamoTyped = false)
    {
        if (sortOrder === 'asc')
        {
            evt.target.setAttribute('sortOrder', 'desc');
        }
        else
        {
            evt.target.setAttribute('sortOrder', 'asc');
        }
        let esAttr = this.props.elasticSearchAttributes.map.get(this.props.location);
        const {dispatch} = this.props;
        ElasticSearchActions.setElasticSearchAttributes(dispatch,this.props.location,esAttr.searchTerm?esAttr.searchTerm:"", esAttr.pageSize, esAttr.activePage,{[columnName] : {"order" : sortOrder}}, esAttr.filterValues, esAttr.dateRange);
        let elasticSearchQuery  = new ElasticSearchQuery();
        let that = this;
        let isQuery = this.props.isQuery;
        let stage = this.props.auth.user.userType.toLowerCase() === 'student' ? '' : config.stage;

        if (this.props.customQuery)
        {
            elasticSearchQuery.query(this.screenNameForElasticSearch+ (this.props.isOverRideStage? config.stage:stage), this.allColumnsForElasticSearch, this.props,
                esAttr.searchTerm, esAttr.pageSize, esAttr.activePage, {[columnName] : {"order" : sortOrder}},esAttr.filterValues, function (results,totalRecords) {
                    
                    dispatch({
                        type: that.actionName,
                        status: 200,
                        data: JSON.parse(JSON.stringify(results)),
                        alertText: '',
                        alertStyle: '',
                        showAlert: false,
                        totalRecords:totalRecords
                    });
                },isQuery, this.props.customQuery,this.props.customURL);
        }
        else
        {
            elasticSearchQuery.query(this.screenNameForElasticSearch+ (this.props.isOverRideStage? config.stage:stage), this.allColumnsForElasticSearch, this.props,
                esAttr.searchTerm, esAttr.pageSize, esAttr.activePage, {[columnName] : {"order" : sortOrder}},esAttr.filterValues, function (results,totalRecords) {
                    
                    dispatch({
                        type: that.actionName,
                        status: 200,
                        data: JSON.parse(JSON.stringify(results)),
                        alertText: '',
                        alertStyle: '',
                        showAlert: false,
                        totalRecords:totalRecords
                    });
                },isQuery,'',this.props.customURL);
        }

    }

    getSubRow(currentSubRowData, key,subRowClass, columns,actions,showActionMenu, rowClickAction, actionButtons, parentRecordId){
        currentSubRowData['className'] = subRowClass ? subRowClass:'';
        return <TableRow record={currentSubRowData} columns={columns}
                         key={key}
                         id={key}
                         showActionMenu={showActionMenu}
                         actionButtons={actionButtons}
                         actionMenuActions={actions}
                         rowClickAction = {rowClickAction}
                         parentRecordId = {parentRecordId}
        />
    }

    getSubRowsForRecord(record, parentRowId) {
        let expansionOptions = this.props.options.rowExpansion;
        let subRows = [];

        if(expansionOptions.expansionKeyInCurrentItem && record[expansionOptions.expansionKeyInCurrentItem] && record[expansionOptions.expansionKeyInCurrentItem].length > 0){
            let subRowsData = record[expansionOptions.expansionKeyInCurrentItem];

            for(let subRowCount = 0 ; subRowCount < subRowsData.length ; subRowCount++){
                let currentSubRowData = subRowsData[subRowCount];
                let subRowClass = expansionOptions.getSubRowClass(currentSubRowData);
                let key = parentRowId + '-child-row-' + subRowCount;
                subRows.push(this.getSubRow(currentSubRowData, key,subRowClass, expansionOptions.expansionItemColumns, expansionOptions.actions, expansionOptions.showActionMenu, expansionOptions.rowClickAction, expansionOptions.actionButtons,record.id));
            }

        }
        return subRows;
    }

    getTableRows(pageable) {
        let rows = [];
        if (pageable) {
            if (this.props.options.totalRecords > 0) {
                if(Math.ceil((this.props.options.totalRecords / this.props.options.pagination.pageSize)) < this.state.activePage){
                    this.state.activePage = 1;
                }
                let start = (this.props.options.pagination.pageSize * this.state.activePage) - this.props.options.pagination.pageSize;
                for (let recordCount = start; recordCount < this.props.options.pagination.pageSize * this.state.activePage; recordCount++) {
                    let record = this.props.options.items[recordCount];
                    if (record) {
                        let rowClass = this.props.options.itemClass && typeof this.props.options.itemClass === 'function' ? this.props.options.itemClass(record) : '';
                        let key = 'row-' + recordCount;
                        rows.push(<TableRow record={record} columns={this.props.options.columns}
                                            key={key}
                                            id={key}
                                            rowClass={rowClass}
                                            showActionMenu={this.props.options.showActionMenu}
                                            actionButtons={this.props.options.actionButtons}
                                            actionMenuActions={this.props.options.actions}
                                            rowClickAction = {this.props.options.rowClickAction}
                        />);

                        // checking for expansion if expansion condition is true then and only then create expansion row and add expand button to parent row.
                        let rowExpansion = this.props.options.rowExpansion;
                        if(rowExpansion && rowExpansion.expansionConditionExpression && typeof rowExpansion.expansionConditionExpression==='function' && rowExpansion.expansionConditionExpression(record)){
                            // now add extension to row.
                            let expRow = <tr style={{display: 'none', background:'#f1faf3'}}
                                             className="subDropRow subDropRowDesk showRow" id={key + '-child'}
                                             key={key + '-child'}>
                                <td colSpan={this.props.options.showActionMenu ? this.props.options.columns.length + 1 : this.props.options.columns.length} style={{paddingTop:'0px'}}>
                                    <table className="innerTableQues">
                                        <tbody>
                                        {this.getSubRowsForRecord(record, key)}
                                        </tbody>
                                    </table>
                                </td>
                            </tr>;

                            // Danger here. Don't mess with this if block.
                            rows.push(expRow);
                        }

                    }
                }
            }
        } else {
            let rowCount = 0;
            this.props.options.items.forEach(item => {
                rows.push(<TableRow record={item} columns={this.props.options.columns} key={'row-' + rowCount}/>);
                rowCount++;
            });
        }
        if (rows.length === 0) {
            rows.push(<tr key={'row-no-record'}>
                <td className="emptyMessage" rowSpan="2" colSpan={this.props.options.columns.length}>
                    {this.props.options.emptyMessage}
                </td>
            </tr>)
        }
        return rows;
    }

    callElasticSearch(activePage)
    {
        let esAttr = this.props.elasticSearchAttributes.map.get(this.props.location);
        const {dispatch} = this.props;
        let elasticSearchQuery  = new ElasticSearchQuery();
        let that = this;
        let isQuery = this.props.isQuery;
        let stage = this.props.auth.user.userType.toLowerCase() === 'student' ? '' : config.stage;

        if (this.props.customQuery)
        {
            elasticSearchQuery.query(this.screenNameForElasticSearch+ (this.props.isOverRideStage? config.stage:stage), this.allColumnsForElasticSearch, this.props,
                esAttr.searchTerm, esAttr.pageSize, activePage, esAttr.sortObj,esAttr.filterValues ,function (results,totalRecords) {
                    
                    dispatch({
                        type: that.actionName,
                        status: 200,
                        data: JSON.parse(JSON.stringify(results)),
                        alertText: '',
                        alertStyle: '',
                        showAlert: false,
                        totalRecords:totalRecords
                    });
                }, isQuery, this.props.customQuery,this.props.customURL);
        }
        else
        {
            elasticSearchQuery.query(this.screenNameForElasticSearch+ (this.props.isOverRideStage? config.stage:stage), this.allColumnsForElasticSearch, this.props,
                esAttr.searchTerm, esAttr.pageSize, activePage, esAttr.sortObj,esAttr.filterValues ,function (results,totalRecords) {
                    
                    dispatch({
                        type: that.actionName,
                        status: 200,
                        data: JSON.parse(JSON.stringify(results)),
                        alertText: '',
                        alertStyle: '',
                        showAlert: false,
                        totalRecords:totalRecords
                    });
                }, isQuery, '', this.props.customURL);
        }

    }

    gotoFirstPage() {
        this.setState({
            ...this.state,
            selectedPageLink: 1
        });
        const {dispatch} = this.props;
        let esAttr = this.props.elasticSearchAttributes.map.get(this.props.location);
        ElasticSearchActions.setElasticSearchAttributes(dispatch,this.props.location,esAttr.searchTerm?esAttr.searchTerm:"", esAttr.pageSize, 1,esAttr.sortObj, esAttr.filterValues, esAttr.dateRange);
        this.callElasticSearch(1);
    }

    gotoLastPage() {
        let activePage = Math.ceil(this.props.options.totalRecords / this.props.options.pagination.pageSize);
        this.setState({
            ...this.state,
            selectedPageLink: activePage
        });
        const {dispatch} = this.props;
        let esAttr = this.props.elasticSearchAttributes.map.get(this.props.location);
        ElasticSearchActions.setElasticSearchAttributes(dispatch,this.props.location,esAttr.searchTerm?esAttr.searchTerm:"", esAttr.pageSize, activePage,esAttr.sortObj, esAttr.filterValues, esAttr.dateRange);
        this.callElasticSearch(activePage);
    }

    gotoNextPage() {
        let activePage = this.state.selectedPageLink < Math.ceil(this.props.options.totalRecords / this.props.options.pagination.pageSize) ? this.state.selectedPageLink + 1 : this.state.selectedPageLink;
        this.setState({
            ...this.state,
            selectedPageLink: activePage
        });
        const {dispatch} = this.props;
        let esAttr = this.props.elasticSearchAttributes.map.get(this.props.location);
        ElasticSearchActions.setElasticSearchAttributes(dispatch,this.props.location,esAttr.searchTerm?esAttr.searchTerm:"", esAttr.pageSize, activePage,esAttr.sortObj, esAttr.filterValues, esAttr.dateRange);
        this.callElasticSearch(activePage);
    }

    gotoPrevPage() {
        let activePage = this.state.activePage > 1 ? this.state.activePage - 1 : this.state.activePage;
        this.setState({
            ...this.state,
            selectedPageLink: activePage
        });
        const {dispatch} = this.props;
        let esAttr = this.props.elasticSearchAttributes.map.get(this.props.location);
        ElasticSearchActions.setElasticSearchAttributes(dispatch,this.props.location,esAttr.searchTerm?esAttr.searchTerm:"", esAttr.pageSize, activePage,esAttr.sortObj, esAttr.filterValues, esAttr.dateRange);
        this.callElasticSearch(activePage);
    }

    gotoPage(pageNumber) {
        this.setState({
            ...this.state,
            selectedPageLink: pageNumber
        });
        const {dispatch} = this.props;
        let esAttr = this.props.elasticSearchAttributes.map.get(this.props.location);
        ElasticSearchActions.setElasticSearchAttributes(dispatch,this.props.location,esAttr.searchTerm?esAttr.searchTerm:"", esAttr.pageSize, pageNumber,esAttr.sortObj, esAttr.filterValues, esAttr.dateRange);
        this.callElasticSearch(pageNumber);
    }

    gotoInputEnter(evt) {
        if (!evt) evt = window.event;
        var keyCode = evt.keyCode || evt.which;
        if (keyCode == '13') {
            if (evt.target.value && evt.target.value >= 1 && evt.target.value <= Math.ceil(this.props.options.totalRecords / this.props.options.pagination.pageSize)) {
                this.gotoPage(evt.target.value);
            }
            return false;
        }
    }

    buildPaginationLinks(){
        let paginationDom = '';
        if (this.props.options.pageable && this.props.options.totalRecords > 0) {
            // if pageable is true then pagination properties are required.
            let paginationLinks = [];
            let totalPages = Math.ceil(this.props.options.totalRecords / this.state.pageSize);
            if (this.state.showFirstButton) {
                paginationLinks.push(<a className="pageLink" href="javascript:void 0" key={'first'} id='first-page'
                                        onClick={this.gotoFirstPage}>{this.state.firstButtonLabel}</a>);
            }
            if (this.state.showPreviousButton) {
                paginationLinks.push(<a className="pageLink" href="javascript:void 0" key={'prev'} id='prev-page'
                                        onClick={this.gotoPrevPage}>{this.state.previousButtonLabel}</a>);
            }
            let allLinks = [];
            let startIdx;
            for (let pageCount = 1; pageCount <= totalPages; pageCount++) {
                if (this.state.selectedPageLink === pageCount) {
                    startIdx = pageCount - 3;
                    if (startIdx < 0) {
                        startIdx = 0;
                    }
                }
                allLinks.push(<a key={pageCount} id={'page-'+pageCount}
                                 className={this.state.selectedPageLink === pageCount ? 'selectedPageLink' : 'pageLink'}
                                 href="javascript:void 0"
                                 onClick={()=> {
                                     this.gotoPage(pageCount);
                                 }}>{pageCount}</a>);

            }

            for (let pageDrawn = startIdx; pageDrawn < (startIdx + this.props.options.pagination.pageLinkSize); pageDrawn++) {
                if (allLinks[pageDrawn]) {
                    paginationLinks.push(allLinks[pageDrawn]);
                }
            }

            if (this.state.showNextButton) {
                paginationLinks.push(<a className="pageLink" key={'next'} id='next-page' href="javascript:void 0"
                                        onClick={this.gotoNextPage}>{this.state.nextButtonLabel}</a>);
            }
            if (this.state.showLastButton) {
                paginationLinks.push(<a className="pageLink" id='last-page' href="javascript:void 0" key={'last'}
                                        onClick={this.gotoLastPage}>{this.state.lastButtonLabel}</a>);
            }
            let pageInfo = '';
            if (this.state.showPageInfo) {
                pageInfo = <div className="paginationInfo">
                    {'{ Total records ' + (this.props.options.totalRecords ? this.props.options.totalRecords : 0) + ' }'}
                </div>;
            }

            let gotoInput = '';
            if (this.state.showGotoInput) {
                gotoInput = <div className="paginationGotoInput">
                    <label>{this.state.gotoInputLabel}&nbsp;</label>
                    <input type="number" min="1" max={totalPages}
                           onKeyPress={this.gotoInputEnter}/>
                </div>;
            }

            paginationDom = <div className="pagination hidden-xs">
                {pageInfo}
                {gotoInput}
                <div className="paginationLinks">
                    {paginationLinks}
                </div>
            </div>;
        }
        return paginationDom;
    }

    componentWillUpdate(nextProps, nextState) {
        if (nextState.pageSize && nextProps.options && nextProps.options.pagination && nextProps.options.pagination.pageSize && nextState.pageSize !== nextProps.options.pagination.pageSize) {
            nextState.pageSize = nextProps.options.pagination.pageSize;
            nextState.activePage = 1;
        }
    }

    render() {
        let paginationDom = this.buildPaginationLinks();
        let actionMenuHeader = '';
        if (this.props.options.showActionMenu) {
            actionMenuHeader = <th />;
        }
        return (
            <div id={this.props.tableId?this.props.tableId:""}>
                <table className="table table-hover hidden-xs" key={'maintenance-table-container-table'}>
                    <thead>
                    <tr>
                        {this.getTableHeaders()}
                        {actionMenuHeader}
                    </tr>
                    </thead>
                    <tbody>
                    {this.getTableRows(this.props.options.pageable)}

                    </tbody>
                </table>
                {this.props.options.totalRecords !== 0 ? paginationDom : ''}
            </div>
        );
    }
}


function mapStateToProps(state = {}) {
    return {...state};
}

MaintenanceTable.defaultProps = {
    isQuery: true
}

export default connect(mapStateToProps)(MaintenanceTable);
