import React, {Component} from "react";
import {connect} from "react-redux";
import CreateFlashCardActivity from "../new/create-flash-card-activity";

class EditFlashCardActivity extends Component {

    constructor(props) {
        super(props);
        
    }

    render() {
        return (<CreateFlashCardActivity {...this.props} />);
    }

}


function mapStateToProps(state) {
    return {...state};
}
export default connect(mapStateToProps)(EditFlashCardActivity);

